import { confirmAlert } from "react-confirm-alert";
import React from 'react';

export default class AsyncAlert {

    static async alert(title, message, successButtonText = 'Yes', denyButtonText = 'No') {
        return new Promise(resolve => {
            confirmAlert({
                title,
                message,
                buttons: [
                    {
                        label: successButtonText,
                        onClick: () => resolve(true),
                    },
                    {
                        label: denyButtonText,
                        onClick: () => resolve(false)
                    }
                ]
            });
        })
    }
    static confirmWithInput(title, successButtonText = 'Yes', denyButtonText = 'No') {
        // const [note, setNote] = useState("");
        return new Promise(resolve => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='react-confirm-alert-body'>
                            <h1>{title}</h1>
                            <div className="form-group">
                                <label htmlFor="note-area">Add a note for your manager:</label>
                                <textarea style={{ height: '80px' }} className="form-control" id="note-area"></textarea>
                            </div>
                            <div className="react-confirm-alert-button-group">
                                <button onClick={() => {
                                    resolve({ confirm: false });
                                    onClose();
                                }}>{denyButtonText}</button>
                                <button onClick={() => {
                                    const note = document.getElementById('note-area').value;
                                    resolve({ confirm: true, note });
                                    onClose();
                                }}>{successButtonText}</button>
                            </div>
                        </div>
                    );
                }
            })
        });
    }
}

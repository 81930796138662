import Axios from "axios";
import CrudPage from "../../components/CrudPage";
import StorageManager from "../../helpers/StorageManager";
import CrudService from "../../services/CrudService";

const earningCodesService = new CrudService("earning-codes");

earningCodesService.api = Axios.create({
  baseURL: process.env.REACT_APP_EARNING_CODES_API_GATEWAY_ENDPOINT,
  // baseURL: "http://localhost:3022",
  headers: {
    Authorization: `Bearer ${StorageManager.get("token")}`,
  },
});

earningCodesService.list = (page, search = undefined) => {
  return earningCodesService.api.get(`/${earningCodesService._uri}`, {
    params: {
      page,
      description: search,
    },
  });
};

export default class EarningCodes extends CrudPage {
  name = "Earning code";
  fields = [
    {
      name: "id",
      label: "ID",
      type: "text",
      showOnForm: false,
      showOnTable: false,
    },
    {
      name: "code",
      label: "Code",
      type: "text",
    },
    {
      name: "description",
      label: "Description",
      type: "text",
    },
    {
      name: "group",
      label: "Group",
      type: "select",
      options: [
        { name: "Regular", value: "regular" },
        { name: "Overtime", value: "overtime" },
        { name: "Double time", value: "double-time" },
        { name: "Flat amount", value: "flat-amount" },
      ],
    },
    {
      name: "rateFactor",
      label: "Rate factor",
      type: "double",
    },
    {
      name: "status",
      label: "Active",
      type: "toggle",
      defaultVal: false,
    },
  ];
  // enableImport = true;
  // importSampleLink =
  // "https://dxv8p7y79jw20.cloudfront.net/Departments+Sample.csv";

  // sampleData = departments;
  searchPlaceholder = "Search by description or code...";

  async fetch(page, search) {
    try {
      const res = await earningCodesService.list(page, search);

      if (res.data) {
        const { result: data, totalItems: total } = res.data;
        return {
          data: {
            currentPage: page,
            totalPages: Math.ceil(total / 10),
            total,
            data,
          },
        };
      }
      return { data: { data: [], currentPage: page, totalPages: 0, total: 0 } };
    } catch (e) {
      return { data: { data: [], currentPage: page, totalPages: 0, total: 0 } };
    }
  }

  create(data) {
    return earningCodesService.create(data);
  }

  update(data) {
    return earningCodesService.update(data);
  }

  delete(id) {
    return earningCodesService.remove(id);
  }

  // import(file) {
  //   return earningCodesService.importSample(file);
  // }
}

import React from "react";
import Modal from "react-modal";
import customModalStyles from "../../Modals/customStyle";
import PropTypes from "prop-types";
import ToggleButton from "react-toggle-button";
import CheckboxTree from "react-checkbox-tree";
import Select from "react-select";

class FiltersModal extends React.Component {
  constructor(props) {
    super();
    const {
      checked,
      expanded,
      selectedLocations,
      selectedLocationsLength,
      selectedRoles,
      selectedRolesLength,
      selectedZones,
      selectedZonesLength,
      selectedDepartments,
      selectedDepartmentsLength,
      selectedEventNames,
      selectedEventsLength,
      filter
    } = props;
    this.state = {
      name: filter ? filter.name : '',
      id: filter ? filter.id : 0,
      checked,
      expanded,
      selectedLocationsLength,
      selectedLocations,
      selectedRoles,
      selectedRolesLength,
      selectedZones,
      selectedZonesLength,
      selectedDepartments,
      selectedDepartmentsLength,
      selectedEventNames,
      selectedEventsLength,
      showSaveFilter: filter ? true : false,
    }
  }
  onCheck = (checked) => {
    const selectedLocations = checked.map((l) => parseInt(l, 10));
    this.setState({
      checked,
      selectedLocationsLength: checked.length,
      selectedTreeLocations: selectedLocations,
    });
  }
  onExpand = (expanded) => {
    this.setState({ expanded });
  }
  onSelectLocations = (values) => {
    this.setState({
      selectedLocations: values,
      selectedLocationsLength: values.length,
    });
  }

  onSelectRoles = (values) => {
    this.setState({
      selectedRoles: values,
      selectedRolesLength: values.length,
    });
  }
  onSelectZones = (values) => {
    this.setState({
      selectedZones: values,
      selectedZonesLength: values.length,
    });
  }
  onSelectDepartments = (values) => {
    this.setState({
      selectedDepartments: values,
      selectedDepartmentsLength: values.length,
    });
  }
  onSelectEventNames = (values) => {
    this.setState({
      selectedEventNames: values,
      selectedEventNamesLength: values.length,
    });
  }
  searchOnScheduler = () => {
    this.props.searchOnScheduler(this.state);
  }
  saveAndSearchOnScheduler = () => {
    this.setState({ showSaveFilter: true });
  }
  createNewFilter = () => {
    this.setState({
      name: '',
      id: 0,
      checked: [],
      expanded: [],
      selectedLocationsLength: 0,
      selectedLocations: [],
      selectedRoles: [],
      selectedRolesLength: 0,
      selectedZones: [],
      selectedZonesLength: 0,
      selectedDepartments: [],
      selectedDepartmentsLength: 0,
      selectedEventNames: [],
      selectedEventsLength: 0,
      showSaveFilter: false,
    });
  }
  render() {
    const {
      isOpen,
      closeModal,
      zones,
      roles,
      isResenting,
      departments,
      eventNames,
      displayLocationTree,
      showTree,
      displayTree,
      locationTree,
      locations,
    } = this.props;
    const {
      checked,
      expanded,
      selectedLocationsLength,
      selectedLocations,
      selectedRoles,
      selectedRolesLength,
      selectedZones,
      selectedZonesLength,
      selectedDepartments,
      selectedDepartmentsLength,
      selectedEventNames,
      selectedEventsLength,
      showSaveFilter,
      name,
      id
    } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Schedule Details"
        shouldCloseOnOverlayClick={false}
      >
        <div className="modal-header">
          {showSaveFilter && id ? (
            <h4>Update Filter</h4>) : (showSaveFilter ? (
              <h4>Save Filter</h4>) : <h4>Filters</h4>)}
        </div>

        <div className="modal-body col custom-height" style={{ maxWidth: 500 }}>
          {showSaveFilter && (
            <div className="mb-3">
              <div>Filter name:</div>
              <input className="form-control" autoFocus={true} value={name} onChange={(ev) => this.setState({ name: ev.target.value })} type="text"></input>
            </div>
          )}
          {roles.length && !isResenting ? (
            <React.Fragment>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className={displayLocationTree ? "" : "hidden"}>
                  Filter By region:
                </h4>

                <ToggleButton
                  value={showTree}
                  thumbStyle={{ borderRadius: 4 }}
                  trackStyle={{
                    borderRadius: 4
                  }}
                  colors={{
                    activeThumb: {
                      base: "#fff"
                    },
                    active: {
                      base: "#61c08d"
                    }
                  }}
                  onToggle={displayTree}
                />
              </div>

              <div
                className={`
                ${displayLocationTree && showTree ? "min-height-200" : "hidden"}
                `}
              >
                <CheckboxTree
                  nodes={locationTree}
                  checked={checked}
                  expanded={expanded}
                  onCheck={this.onCheck}
                  onExpand={this.onExpand}
                />
              </div>

              <div
                className={`
                  ${
                  !displayLocationTree || (displayLocationTree && !showTree)
                    ? ""
                    : "hidden"
                  } mb-3`}
              >
                <div>Selected Locations: {selectedLocationsLength}</div>
                <Select
                  getOptionLabel={o => o.name}
                  isMulti={true}
                  name="locations"
                  mode="input"
                  value={selectedLocations}
                  onChange={this.onSelectLocations}
                  maxMenuHeight={200}
                  options={locations}
                  placeholder="Locations"
                />
              </div>
            </React.Fragment>
          ) : null}

          {roles.length > 0 && !isResenting && (
            <div className="mb-3">
              <div>Selected Roles: {selectedRolesLength}</div>
              <Select
                menuPlacement={showTree ? "top" : "bottom"}
                maxMenuHeight={showTree ? 200 : 140}
                isMulti={true}
                getOptionLabel={o => o.name}
                name="roles"
                value={selectedRoles}
                options={roles}
                onChange={this.onSelectRoles}
                placeholder="Roles"
              />
            </div>
          )}

          {departments.length > 0 && !isResenting && (
            <div className="mb-3">
              <div>Selected Departments: {selectedDepartmentsLength}</div>
              <Select
                menuPlacement="top"
                isMulti={true}
                maxMenuHeight={showTree ? 200 : 170}
                getOptionLabel={o => o.name}
                name="department"
                mode="input"
                value={selectedDepartments}
                options={departments}
                onChange={this.onSelectDepartments}
                placeholder="Departments"
              />
            </div>
          )}

          {zones.length > 0 && !isResenting && (
            <div className="mb-3">
              <div>Selected Zones: {selectedZonesLength}</div>
              <Select
                menuPlacement={showTree ? "top" : "bottom"}
                maxMenuHeight={showTree ? 200 : 140}
                isMulti={true}
                getOptionLabel={o => o.name}
                name="roles"
                value={selectedZones}
                options={zones}
                onChange={this.onSelectZones}
                placeholder="Zones"
              />
            </div>
          )}
          {eventNames.length > 0 && !isResenting && (
            <div>
              <div>Selected Events: {selectedEventsLength}</div>
              <Select
                menuPlacement="top"
                maxMenuHeight={200}
                isMulti={true}
                name="events"
                mode="input"
                getOptionLabel={o => o.name}
                value={selectedEventNames}
                onChange={this.onSelectEventNames}
                options={eventNames}
                placeholder="Events"
              />
            </div>
          )}
        </div>

        <div className="modal-footer">
          {this._renderModalButtons()}
        </div>
      </Modal>
    );
  }
  _renderModalButtons() {
    const { clearFilters, closeModal, saveFilter, deleteFilter } = this.props;
    const {
      selectedLocationsLength,
      selectedRolesLength,
      selectedZonesLength,
      selectedDepartmentsLength,
      selectedEventsLength
    } = this.state;
    const { id, name, showSaveFilter, ...state } = this.state;
    const canSeeOtherButtons = selectedLocationsLength > 0 ||
      selectedEventsLength > 0 ||
      selectedZonesLength > 0 ||
      selectedDepartmentsLength > 0 ||
      selectedRolesLength > 0;
    return (
      <div className="pull-right">
        {canSeeOtherButtons && showSaveFilter && id === 0 ? (
          <React.Fragment>

            <button
              className="btn btn-primary mr-2"
              onClick={() => {
                saveFilter(state, name, id);
              }}
            >
              Save
            </button>
          </React.Fragment>
        ) : (canSeeOtherButtons && showSaveFilter && id !== 0 ? (
          <React.Fragment>
            <button
              className="btn btn-secondary mr-2"
              onClick={this.createNewFilter}
            >
              Create new filter
            </button>
            <button
              className="btn btn-danger mr-2"
              onClick={() => {
                deleteFilter(id);
              }}
            >
              Delete filter
            </button>
            <button
              className="btn btn-primary mr-2"
              onClick={() => {
                saveFilter(state, name, id);
              }}
            >
              Update filter
            </button>
          </React.Fragment>
        ) : (canSeeOtherButtons && !showSaveFilter && (
          <React.Fragment>
            <button className="btn btn-danger mr-2" onClick={clearFilters}>
              Reset
            </button>
            <button
              className="btn btn-primary mr-2"
              onClick={this.searchOnScheduler}
            >
              Search
            </button>
            <button
              className="btn btn-secondary mr-2"
              onClick={this.saveAndSearchOnScheduler}
            >
              Save filter
            </button>
          </React.Fragment>
        )
          ))}
        <button className="btn btn-light mr-2" onClick={closeModal}>
          Close
            </button>
      </div>

    )
  }
}

FiltersModal.defaultProps = {
  isOpen: false,
  roles: [],
  isResenting: false,
  departments: [],
  eventNames: [],
  displayLocationTree: false,
  showTree: false,
  locationTree: [],
  checked: [],
  expanded: [],
  selectedLocations: [],
  locations: [],
  selectedLocationsLength: 0,
  selectedRoles: [],
  selectedRolesLength: 0,
  selectedDepartments: [],
  selectedDepartmentsLength: 0,
  selectedEventNames: [],
  selectedEventsLength: 0,
  closeModal: () => { },

  clearFilters: () => { },

  searchOnScheduler: () => { },

  displayTree: () => { },

  onCheck: () => { },

  onExpand: () => { },

  onSelectLocations: () => { },

  onSelectRoles: () => { },

  onSelectDepartments: () => { },

  onSelectEventNames: () => { }
};

FiltersModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default FiltersModal;

import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import SelectSearch from "react-select";
import ReactSelect from "react-select/lib/Async";
import "./../../../general/assets/styles/react-select-search-style.css";
import ToggleButton from "react-toggle-button";
import SettingsService from "../../../services/SettingsService";
let timeoutInc = null;
let timeoutExc = null;

const AccessLevelForm = ({
  locationTree,
  editMode,
  onFormSubmit,
  departments,
  jobs,
  mapJobs,
  employees,
  mapDepartments,
  accessLevelObj: level,
  onCancel,
}) => {
  const [levelName, setLevelName] = useState("");
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [tree, setTree] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [includedEmployees, setIncludedEmployees] = useState([]);
  const [excludedEmployees, setExcludedEmployees] = useState([]);
  const [locationError, setLocationError] = useState(null);
  const [levelNameError, setLevelNameError] = useState(null);
  const [keepMyHierarchy, setKeepMyHierarchy] = useState(false);
  const [keepMyEmpHierarchy, setKeepMyEmpHierarchy] = useState(false);
  const [formIsSaving, setFormIsSaving] = useState(false);
  const [jobInputValue, setJobInputValue] = useState("");
  const [depInputValue, setdDepInputValue] = useState("");
  // const [incEmpInputValue, setIncEmpInputValue] = useState("");
  // const [excEmpInputValue, setExcEmpInputValue] = useState("");
  useEffect(() => {
    if (locationTree && departments.length && jobs.length) {
      if (!editMode && !level) {
        setTree(locationTree);
        setDepartmentOptions(departments);
        setJobOptions(jobs);
      }
    }
  }, [locationTree, departments.length, jobs.length]);
  // set edit Access Level
  useEffect(() => {
    if (level) {
      const levelDep = level.departments ? JSON.parse(level.departments) : [];
      const levelJob = level.jobs ? JSON.parse(level.jobs) : [];
      const levelLoc = level.locations ? JSON.parse(level.locations) : [];
      const incEmpl = level.employees ? JSON.parse(level.employees) : [];
      const excEmpl = level.employees_exclude
        ? JSON.parse(level.employees_exclude)
        : [];
      const keep_my_hierarchy = level.keep_my_hierarchy;
      const keep_my_employees = level.keep_my_employees;
      let selectedIncEmp = [];
      let selectedExcEmp = [];
      if (incEmpl.length) {
        selectedIncEmp = JSON.parse(JSON.stringify(employees))
          .filter((e) => incEmpl.includes(e.id))
          .map((e) => {
            return {
              name: e.name,
              value: e.id,
            };
          });
      }
      if (excEmpl.length) {
        selectedExcEmp = JSON.parse(JSON.stringify(employees))
          .filter((e) => excEmpl.includes(e.id))
          .map((e) => {
            return {
              name: e.name,
              value: e.id,
            };
          });
      }
      setChecked(levelLoc);
      setExpanded([]);
      setLevelName(level.name);
      setKeepMyHierarchy(keep_my_employees);
      setKeepMyEmpHierarchy(keep_my_hierarchy);
      setIncludedEmployees(selectedIncEmp);
      setExcludedEmployees(selectedExcEmp);
      filterOptions(levelLoc, levelDep, levelJob);
    } else {
      resetToInitial();
    }
  }, [level]);
  const resetToInitial = () => {
    setJobOptions(jobs);
    setDepartmentOptions(departments);
    setTree(locationTree);
    setChecked([]);
    setExpanded([]);
    setLevelName("");
    setSelectedDepartments([]);
    setSelectedJobs([]);
    setTotalEmployees(0);
    setKeepMyEmpHierarchy(false);
    setKeepMyHierarchy(false);
    setExcludedEmployees([]);
    setIncludedEmployees([]);
  };
  const filterOptions = (loc, dep, job) => {
    let filterDepartments = [],
      filterJobs = [],
      filterLocationTree = [],
      result,
      selectedDepartments = [],
      selectedJobs = [],
      selectedLocationId = [],
      filterEmployees = [],
      locationIds = loc.map((l) => l.toString());
    filterDepartments = JSON.parse(JSON.stringify(departments));
    filterJobs = JSON.parse(JSON.stringify(jobs));
    filterLocationTree = JSON.parse(JSON.stringify(locationTree));
    filterEmployees = JSON.parse(JSON.stringify(employees));

    selectedDepartments = filterDepartments.filter((d) =>
      dep.includes(1 * d.value)
    );
    selectedJobs = filterJobs.filter((j) => job.includes(1 * j.value));
    selectedLocationId = locationIds; //.filter((l) => loc.includes(l.toString()));
    filterEmployees = getFilterEmployee(
      filterEmployees,
      selectedLocationId,
      selectedDepartments.map((d) => d.value),
      selectedJobs.map((j) => j.value)
    );
    setTree(filterLocationTree);
    setChecked(selectedLocationId);
    setJobOptions(filterJobs);
    setDepartmentOptions(filterDepartments);
    setSelectedDepartments(selectedDepartments);
    setSelectedJobs(selectedJobs);
    setTotalEmployees(filterEmployees.length);
    if (filterEmployees.length === 0) {
      setKeepMyEmpHierarchy(false);
      setKeepMyHierarchy(false);
    }
  };
  const filterLocationTreeWithObj = (filterLocationTree, locationIds) => {
    const _filterLocationTree = [];
    let locIds = [];
    filterLocationTree.forEach((r) => {
      r.children = r.children
        .map((s) => {
          s.children = s.children.filter((l) => locationIds.includes(l.value));
          if (s.children.length) {
            locIds = locIds.concat(s.children.map((l) => l.value));
          }
          return s.children.length > 0 ? s : null;
        })
        .filter((s) => s !== null);
      if (r.children.length) {
        _filterLocationTree.push(r);
      }
    });
    return {
      filterLocationTree: _filterLocationTree,
      locationIds: [...new Set(locIds)],
    };
  };
  const getFilterEmployee = (
    filterEmployees,
    locationIds,
    departmentIds,
    jobIds
  ) => {
    return filterEmployees.filter((e) => {
      return (
        (locationIds.length === 0 ||
          locationIds.includes(e.locationId.toString())) &&
        (departmentIds.length === 0 ||
          departmentIds.includes(e.departmentId)) &&
        (jobIds.length === 0 ||
          e.jobs.filter((jobId) => jobIds.includes(jobId)).length > 0) &&
        (locationIds.length || departmentIds.length || jobIds.length)
      );
    });
  };
  const getDepartmentIds = () => {
    return selectedDepartments.map((d) => 1 * d.value);
  };
  const getJobIds = () => {
    return selectedJobs.map((j) => 1 * j.value);
  };
  const onCheck = (ch) => {
    setChecked(ch);
    filterOptions(ch, getDepartmentIds(), getJobIds(), "location");
  };
  const onExpand = (ex) => {
    setExpanded(ex);
  };
  const onChangeJobs = (sJ) => {
    setSelectedJobs(sJ);
    filterOptions(
      checked,
      getDepartmentIds(),
      sJ.map((d) => d.value),
      "job"
    );
  };
  const onChangeDepartments = (sD) => {
    setSelectedDepartments(sD);
    filterOptions(
      checked,
      sD.map((d) => d.value),
      getJobIds(),
      "department"
    );
  };

  const onChangeIncludeEmployees = (emp) => {
    setIncludedEmployees(emp);
    const emplIds = emp.map((e) => e.value);
    const exEmpl = excludedEmployees.filter((e) => !emplIds.includes(e.value));
    setExcludedEmployees(exEmpl);
  };

  const onChangeexcludeEmployees = (emp) => {
    setExcludedEmployees(emp);
    const emplIds = emp.map((e) => e.value);
    const incEmpl = includedEmployees.filter((e) => !emplIds.includes(e.value));
    setIncludedEmployees(incEmpl);
  };
  const submitForm = async () => {
    if (levelName.trim() === "") {
      setLevelNameError("Access level name is required");
      return;
    }

    setFormIsSaving(true);
    setLocationError(null);
    setLevelNameError(null);
    onFormSubmit({
      levelName,
      checked,
      selectedJobs,
      selectedDepartments,
      editMode,
      editLevelId: level && level.id ? level.id : 0,
      incEmpl: includedEmployees,
      excEmpl: excludedEmployees,
      keepMyHierarchy,
      keepMyEmpHierarchy,
    })
      .then(() => {
        setFormIsSaving(false);
        onCancel();
        resetToInitial();
      })
      .catch((err) => {
        setFormIsSaving(false);
        console.log(err);
        if (err.locationError) {
          setLocationError(err.locationError);
        } else {
          onCancel();
          resetToInitial();
        }
      });
  };
  const employeeTotal =
    totalEmployees + includedEmployees.length - excludedEmployees.length;
  return (
    <div>
      <div className="form-group row">
        <label className="col-md-12 col-form-label text-nowrap">
          Total employees:
          <span className="bg-primary ml-2 pr-2 pl-2">
            {employeeTotal}{" "}
            {keepMyHierarchy && !keepMyEmpHierarchy ? ` + user employees ` : ""}{" "}
            {keepMyEmpHierarchy ? " + user employees hierarchy" : ""}
          </span>
        </label>
      </div>
      <div className="form-group row">
        <label className="col-md-3 col-form-label text-nowrap">Name:</label>
        <div className="col-md-9">
          <input
            type="text"
            name="levelName"
            className="form-control"
            onChange={(event) => setLevelName(event.target.value)}
            value={levelName}
            disabled={formIsSaving}
          />
          {levelNameError && (
            <div className="input-validation-error px-3 py-1">
              {levelNameError}
            </div>
          )}
        </div>
      </div>
      {tree && tree.length > 0 ? (
        <div className="form-group row">
          <label className="col-md-12 col-form-label">Select locations:</label>
          <div className="col-md-12">
            <CheckboxTree
              nodes={tree}
              checked={checked}
              expanded={expanded}
              onCheck={onCheck}
              onExpand={onExpand}
            />
          </div>
        </div>
      ) : null}

      {departmentOptions && departmentOptions.length > 0 ? (
        <div className="form-group row">
          <label className="col-md-12 col-form-label">
            Select departments:
          </label>
          <div className="col-md-12">
            <SelectSearch
              inputValue={depInputValue}
              onInputChange={(inputValue, { action }) => {
                if (action !== "set-value") {
                  setdDepInputValue(inputValue);
                  return inputValue;
                }
                return depInputValue;
              }}
              closeMenuOnSelect={false}
              options={departmentOptions}
              onChange={onChangeDepartments}
              isMulti={true}
              getOptionLabel={(o) => o.name}
              value={selectedDepartments}
              name="departments"
              placeholder="Select Departments"
              fuse={{
                keys: ["name"],
                threshold: 0.3,
              }}
            />
          </div>
        </div>
      ) : null}
      {jobOptions && jobOptions.length > 0 ? (
        <div className="form-group row">
          <label className="col-md-12 col-form-label">Select jobs:</label>
          <div className="col-md-12">
            <SelectSearch
              inputValue={jobInputValue}
              onInputChange={(inputValue, { action }) => {
                if (action !== "set-value") {
                  setJobInputValue(inputValue);
                  return inputValue;
                }
                return jobInputValue;
              }}
              getOptionLabel={(o) => o.name}
              options={jobOptions}
              onChange={onChangeJobs}
              isMulti={true}
              closeMenuOnSelect={false}
              value={selectedJobs}
              name="jobs"
              placeholder="Select Jobs"
            />
          </div>
        </div>
      ) : null}
      <div className="form-group row">
        <label className="col-md-12 col-form-label">Include Employees:</label>
        <div className="col-md-12">
          <ReactSelect
            closeMenuOnSelect={false}
            onChange={onChangeIncludeEmployees}
            isMulti={true}
            getOptionLabel={(o) => o.name}
            value={includedEmployees}
            name="include-employees"
            placeholder="Search to include employee"
            fuse={{
              keys: ["name"],
              threshold: 0.3,
            }}
            disabled={formIsSaving}
            loadOptions={(search, callback) => {
              timeoutInc && clearTimeout(timeoutInc);

              timeoutInc = setTimeout(async () => {
                SettingsService.getEmployees({
                  locations: (selectedDepartments.length || selectedJobs.length )? [] : checked,
                  departments: selectedDepartments.map((d) => d.value),
                  jobs: selectedJobs.map((d) => d.value),
                  notIn: excludedEmployees.map((d) => d.value),
                  rule: "not-in",
                  search,
                }).then((response) => {
                  callback(response.data);
                });
              }, 300);
            }}
          />
        </div>
      </div>
      {checked.length > 0 ||
      selectedDepartments.length > 0 ||
      selectedJobs.length > 0 ? (
        <div className="form-group row">
          <label className="col-md-12 col-form-label">Exclude Employees:</label>
          <div className="col-md-12">
            <ReactSelect
              closeMenuOnSelect={false}
              onChange={onChangeexcludeEmployees}
              isMulti={true}
              getOptionLabel={(o) => o.name}
              value={excludedEmployees}
              name="exclude-employees"
              placeholder="Search to exclude employee"
              fuse={{
                keys: ["name"],
                threshold: 0.3,
              }}
              disabled={formIsSaving}
              loadOptions={(search, callback) => {
                timeoutExc && clearTimeout(timeoutExc);

                timeoutExc = setTimeout(async () => {
                  SettingsService.getEmployees({
                    locations: checked,
                    departments: selectedDepartments.map((d) => d.value),
                    jobs: selectedJobs.map((d) => d.value),
                    notIn: includedEmployees.map((d) => d.value),
                    rule: "in",
                    search,
                  }).then((response) => {
                    callback(response.data);
                  });
                }, 300);
              }}
            />
          </div>
        </div>
      ) : null}
      {employeeTotal > 0 ? (
        <div className="form-group row">
          <label className="col-md-10 col-form-label">
            Keep user hierarchy:
          </label>
          <div className="col-md-2 col-form-label">
            <ToggleButton
              thumbStyle={{ borderRadius: 4 }}
              trackStyle={{
                borderRadius: 4,
              }}
              colors={{
                activeThumb: {
                  base: "#fff",
                },
                active: {
                  base: "#61c08d",
                },
              }}
              value={keepMyHierarchy}
              onToggle={(value) => {
                setKeepMyHierarchy(!value);
                if (value) {
                  setKeepMyEmpHierarchy(false);
                }
              }}
            />
          </div>
        </div>
      ) : null}

      {keepMyHierarchy && employeeTotal > 0 ? (
        <div className="form-group row">
          <label className="col-md-10 col-form-label">
            Keep user employee hierarchy:
          </label>
          <div className="col-md-2 col-form-label">
            <ToggleButton
              thumbStyle={{ borderRadius: 4 }}
              trackStyle={{
                borderRadius: 4,
              }}
              colors={{
                activeThumb: {
                  base: "#fff",
                },
                active: {
                  base: "#61c08d",
                },
              }}
              value={keepMyEmpHierarchy}
              onToggle={(value) => {
                setKeepMyEmpHierarchy(!value);
              }}
            />
          </div>
        </div>
      ) : null}

      <div className="form-group row">
        {locationError && (
          <div className="input-validation-error px-3 py-1">
            {locationError}
          </div>
        )}
        {editMode ? (
          <div className="col-12 mt-4 text-right">
            <button
              onClick={submitForm}
              disabled={formIsSaving}
              className="btn btn-primary mr-2"
            >
              {formIsSaving ? "Updating..." : "Update"}
            </button>
            <button
              type="button"
              disabled={formIsSaving}
              onClick={onCancel}
              className="btn btn-default"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="col-12 mt-4 text-right">
            <button
              onClick={submitForm}
              disabled={formIsSaving}
              className="btn btn-info"
            >
              {formIsSaving ? "Creating..." : "Create"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccessLevelForm;

import { handleMultiSelect, onEventSave, submitEvents, isManager } from "../helpers/SchedulerHelper";
import { openModal } from "../Modals";
import { getColumns } from "../../../containers/Schedule/Columns";
import { renderLocation, renderResourceName, renderRole } from "../helpers/Renders";
import AvailableShift from "../AvailableShift";
import { handleResourceContextMenu } from "../helpers/Resource";
import { handleSelectionChange, onEventMove, onEventsAssign, pickEvents } from "../helpers/EventActions";
import { onEventDelete } from "../helpers/CUD_API";
import Collapsible from "react-collapsible";
import React from "react";

function renderMyAvailableView() {
  const {
    user,
    mode,
    selfResource,
    selfAvailable,
    config,
    availableEventsVersion,
    availableLocations,
    availableJobs,
    mapJobs,
    mapLocations,
    openSelfAvailableShift,
    scheduleConfigs
  } = this.state;
  const getProfile = resource => {
    this.setState({
      employeeCode: resource.record.data.code,
      showEmployeeProfileModal: true
    });
  };
  const copyEvents = async resource => {
    const { id } = resource.record.data;
    const events = this.state.mappedEvents[id] || [];
    this.call(handleMultiSelect, events);
    this.call(openModal, "copyModal");
  };
  const availableColumns = getColumns(
    user,
    (d) => this.call(renderRole, d),
    (d) => this.call(renderResourceName, d),
    (d) => this.call(renderLocation, d),
    getProfile,
    copyEvents,
    true
  );
  return (
    <Collapsible
      className="mb-3 mt-3 scheduler-collapse"
      openedClassName="mb-3 mt-3 scheduler-collapse"
      open={openSelfAvailableShift}
      onClosing={() => {
        this.setState({
          openSelfAvailableShift: false
        })
      }}
      onOpening={() => {
        this.setState({
          openSelfAvailableShift: true
        })
      }}
      trigger={(
        <span>
          Available Shifts ({selfAvailable.length} ) <span className="caret" />
        </span>
      )
      }
    >
      <AvailableShift
        loggedInUser={user}
        eventsVersion={availableEventsVersion}
        resourcesVersion={availableEventsVersion}
        jobs={availableJobs}
        mapJobs={mapJobs}
        mapLocations={mapLocations}
        locations={availableLocations}
        events={selfAvailable.map(e => {
          e.resizable = user.role === 'manager' ? e.resizable : false;
          e.draggable = user.role === 'manager' ? e.draggable : false;
          e.editabble = user.role === 'manager' ? (e.editabble || true) : false;
          e.readOnly = user.role === 'manager' ? false : true;
          return e;
        })}
        resources={[{ id: '0', name: '' }]}
        allResources={selfResource}
        startDate={config.startDate}
        endDate={config.endDate}
        viewPreset={config.viewPreset}
        scheduleMode={mode}
        weekConf={user.weekConf || 'week'}
        isManager={isManager(user)}
        // readOnly={!isManager(user)}
        submitOrPublish={"submit"}
        submitEvents={(e) => this.call(submitEvents, e)}
        handleResourceContextMenu={(ctx) => this.call(handleResourceContextMenu, ctx)}
        columns={availableColumns}
        useInitialAnimation="slide-from-left"
        onEventSelectionChange={(s) => this.call(handleSelectionChange, s)}
        onSelectEventsChange={(se) => this.call(handleMultiSelect, se)}
        copyEvents={() => this.call(openModal, 'copyModal')}
        onEventSave={(e, isA) => this.call(onEventSave, e, isA)}
        onEventDelete={(e, s, isM) => this.call(onEventDelete, e, s, isM)}
        onEventMove={(e) => this.call(onEventMove, e)}
        notifyForEvent={() => {
        }}
        onEventsAssign={(ids, ev, isM) => this.call(onEventsAssign, ids, ev, isM)}
        pickEvents={(ids) => this.call(pickEvents, ids)}
        scheduleConfigs={scheduleConfigs}
      />
    </Collapsible>
  )
}

export {
  renderMyAvailableView
}

import React, { Component } from "react";
import Toast from "../../components/Blocks/Toast/Toast";
import { ScheduleLogFilterForm } from "../../components/Forms/ReportsForm";
import ScheduleLogTable from "../../components/Tables/ReportsTables/ScheduleLogTable";
import reportService from "../../services/ReportService";
// import logs from "./mock";

// Styles
import "./assets/styles/Reports.css";
import "./assets/styles/Export.css";
import ReactPaginate from "react-paginate";

export default class ScheduleLoggerPage extends Component {
  state = {
    isLoading: false,
    successMessage: "",
    reportData: [],
    reportType: "",
    dateRangeType: "",
    startDate: "",
    endDate: "",
    logs: [],
    total: 0,
    filters: { perPage: 10, userIds: [] },
    page: 0,
    initiated: false
  };

  setPage = ({ selected }) => {
    if (!this.state.initiated) return;

    this.setState({ page: selected + 1, isLoading: true }, this.getLogs);
  };

  componentDidMount() {
    this.setState({ initiated: true });
  }

  async getLogs(refresh = false) {
    const { filters, page } = this.state;
    if (refresh) this.setState({ page: 1 });
    try {
      const logs = await reportService.getLogs({
        ...filters,
        userIds: JSON.stringify(filters.userIds),
        page: refresh ? 1 : page
      });
      this.setState({
        logs: logs.data.data,
        total: Math.ceil(logs.data.totalItems / filters.perPage)
      });
    } catch (e) {
      console.log(e);
    }

    this.setState({
      isLoading: false
    });
  }

  filter = newFilters => {
    return this.setState(
      ({ filters }) => ({
        filters: { ...filters, ...newFilters },
        isLoading: true
      }),
      () => this.getLogs(true)
    );
  };

  render() {
    const { successMessage, logs, total, page, isLoading } = this.state;
    const { user } = this.props;

    const successToast = successMessage ? (
      <Toast
        status="success"
        title="Schedule Logs"
        message={successMessage}
        dismiss={() => this.setState({ successMessage: null })}
      />
    ) : (
        ""
      );

    const Loader = () => <div className="text-muted my-4">Loading...</div>;

    return (
      <div className="e-panel card">
        <div className="card-header">
          <h4>Schedule Logs</h4>
        </div>
        <div className="col-12">
          {successToast}
          <ScheduleLogFilterForm filter={this.filter} user={user} />

          {isLoading ? <Loader /> : <ScheduleLogTable user={user} resources={logs} />}

          <div className="mb-4 d-flex justify-content-center">
            <ReactPaginate
              previousLabel={"‹"}
              nextLabel={"›"}
              breakLabel={"..."}
              breakLinkClassName={"page-link"}
              forcePage={page - 1}
              breakClassName={"page-item"}
              pageCount={total}
              initialPage={0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.setPage}
              containerClassName={"pagination mt-3 mb-0"}
              pageLinkClassName={"page-link"}
              pageClassName={"page-item"}
              activeClassName={"active"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
            />
          </div>
        </div>
      </div>
    );
  }
}

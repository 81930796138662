import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import AsyncSelect from "react-select/lib/Async";
import EmployeeService from "../../../services/EmployeeService";

let timeout = null;
// CreateLevelForm validations schema
const createLevelFormSchema = Yup.object().shape({
  groupName: Yup.string()
    .trim()
    .required("Group name is required"),
});
const prepareResults = (results) => {
  const preparedResults = [];

  results && results.forEach((result) => {
    preparedResults.push({
      value: result.id,
      label: `${result.first_name} ${result.last_name}`
    });
  });

  return preparedResults;
};
const loadOptions = (inputValue, callback) => {
  timeout && clearTimeout(timeout);

  timeout = setTimeout(async () => {
    inputValue = inputValue ? inputValue : 'a';
    const search = await EmployeeService.searchUsers(inputValue, true);

    return callback(prepareResults(search.data))
  }, 300);
};
export default class UserGroupForm extends Component {
  render() {
    const formValues = {
      groupName: this.props.editMode ? this.props.groupName : '',
      users: this.props.editMode ? this.props.users : [],
      editMode: this.props.editMode
    };
    const initialValues = {
      groupName: '',
      users: [],
      editMode: false,
    };

    return (
      <Formik
        initialValues={formValues}
        validationSchema={createLevelFormSchema}
        onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
          await this.props.onFormSubmit(values);
          resetForm(initialValues);
          setFieldValue("users", []);
          setSubmitting(false);
        }}
        enableReinitialize={true}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleSubmit, handleReset, handleBlur,
            setFieldValue }) => (
          <form className="form-horizontal" onReset={handleReset} onSubmit={handleSubmit}>
            <div className="form-group row">
              <label className="col-md-3 col-form-label text-nowrap">Name:</label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="groupName"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.groupName}
                  disabled={isSubmitting}
                />
                {errors.groupName && touched.groupName && <div className="input-validation-error px-3 py-1">{errors.groupName}</div>}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-12 col-form-label text-nowrap">Select users:</label>
              <div className="col-md-12">
                <AsyncSelect
                  value={values.users}
                  isMulti={true}
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions
                  onChange={(newValue) => {
                    setFieldValue("users", newValue)
                  }}
                />
                {this.props.usersError && <div className="input-validation-error px-3 py-1">{this.props.usersError}</div>}
              </div>
            </div>
            <div className="form-group row">
              {this.props.editMode ? (
                <div className="col-12 mt-4 text-right">
                  <button type="submit" disabled={this.props.formIsSaving} className="btn btn-success">{this.props.formIsSaving ? 'Updating...' : 'Update'}</button>
                  <button type="button" disabled={this.props.formIsSaving}  onClick={this.props.onCancel} className="btn btn-default">Cancel</button>
                </div>
              ) : (
                <div className="col-12 mt-4 text-right">
                  <button type="submit" disabled={this.props.formIsSaving}  className="btn btn-info">{this.props.formIsSaving ? 'Creating...' : 'Create'}</button>
                </div>
              )}

            </div>
          </form>
        )}
      </Formik>
    );
  }
}

import React, {Component} from "react";
import moment from "moment";
import Tooltip from "../Blocks/Tooltip";

export default class UpcomingLeavesList extends Component {
    render() {
        const {
            upcomingTimeOff,
            deleteLeave
        } = this.props;

        return (
            <div className="card">
                <div className="card-header">
                    <h4>Upcoming time off</h4>
                </div>

                <div className="card-body">
                    {!upcomingTimeOff.length ? (
                        <h6>You have no upcoming time off</h6>
                    ) : (
                        <div className="table-responsive">
                            <table className="table table-striped text-nowrap">
                                <thead>
                                <tr>
                                    <th/>
                                    <th>Start date</th>
                                    <th>End date</th>
                                    <th>Requested</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>

                                <tbody>
                                {upcomingTimeOff.map(
                                    (request, idx) => {
                                        return (
                                            <tr key={request.id}>
                                                <td>
                                                    {idx +
                                                    1}
                                                </td>
                                                <td>
                                                    {request.startDate.format(
                                                        "DD MMMM YYYY"
                                                    )}
                                                </td>
                                                <td>
                                                    {request.endDate.format(
                                                        "DD MMMM YYYY"
                                                    )}
                                                </td>
                                                <td>
                                                    {moment(
                                                        request.createdAt
                                                    ).fromNow()}
                                                </td>
                                              <td>
                                                <Tooltip placement="top" text="Remove your Leave">
                                                  <button
                                                    className="btn btn-outline-danger btn-sm mr-2"
                                                    onClick={() => deleteLeave(request.id)}
                                                  >
                                                    <i className="fas fa-trash" />
                                                  </button>
                                                </Tooltip>
                                              </td>
                                            </tr>
                                        );
                                    }
                                )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

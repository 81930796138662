import moment from "moment";

export default class Breaks {
    static breakIsBetweenStartTimeAndEndTime = (startTime, endTime, breakStartTime, breakEndTime) => {
        const brStartTime = breakStartTime.split(':');
        const brEndTime = breakEndTime.split(':');
        const stH = parseInt(startTime[0]);
        const stM = parseInt(startTime[1]);
        const endH = parseInt(endTime[0]);
        const endM = parseInt(endTime[1]);
        const brStH = parseInt(brStartTime[0]);
        const brStM = parseInt(brStartTime[1]);
        const brEndH = parseInt(brEndTime[0]);
        const brEndM = parseInt(brEndTime[1]);
        return (stH < brStH || (stH === brStH && stM < brStM)) &&
            (endH > brEndH || (endH === brEndH && endM > brEndM));
    };
    static constructBreakDate = (data) => {
        const {
            name,
            break1,
            break1StartTime,
            break1EndTime,
            break2,
            break2StartTime,
            break2EndTime,
            break3,
            break3StartTime,
            break3EndTime,
            break4,
            break4StartTime,
            break4EndTime,
            startDate,
            endDate,
        } = data;
        let breaks = 0;
        const breakData = [];
        if (name === 'Regular') {
            const startTime = moment(startDate).format('HH:mm').split(':');
            const endTime = moment(endDate).format('HH:mm').split(':');
            if (break1 && break1StartTime && break1EndTime && Breaks.breakIsBetweenStartTimeAndEndTime(startTime, endTime, break1StartTime, break1EndTime)) {
                breakData.push({
                    duration: break1,
                    start: break1StartTime,
                    end: break1EndTime
                });
                breaks += break1;
            }
            if (break2 && break2StartTime && break2EndTime && Breaks.breakIsBetweenStartTimeAndEndTime(startTime, endTime, break2StartTime, break2EndTime)) {
                breakData.push({
                    duration: break2,
                    start: break2StartTime,
                    end: break2EndTime
                });
                breaks += break2;
            }
            if (break3 && break3StartTime && break3EndTime && Breaks.breakIsBetweenStartTimeAndEndTime(startTime, endTime, break3StartTime, break3EndTime)) {
                breakData.push({
                    duration: break3,
                    start: break3StartTime,
                    end: break3EndTime
                });
                breaks += break3;
            }
            if (break4 && break4StartTime && break4EndTime && Breaks.breakIsBetweenStartTimeAndEndTime(startTime, endTime, break4StartTime, break4EndTime)) {
                breakData.push({
                    duration: break4,
                    start: break4StartTime,
                    end: break4EndTime
                });
                breaks += break4;
            }
        }
        return {breakData, breaks};
    };
}

import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Upload from "../../Upload";
import EmojiPicker from "../../EmojiPicker";

// Login validations schema
const loginSchema = Yup.object().shape({
  body: Yup.string()
    .trim()
    .required("Message is required!")
});

const initialValues = {
  body: ""
};

const ComposeMsgForm = props => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await props.submitMsg(values);
        setSubmitting(false);
        resetForm(initialValues);
      }}
      enableReinitialize={true}
    >
      {({
        errors,
        values,
        touched,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleSubmit,
        handleBlur,
        handleReset
      }) => {
        const onSelectEmoji = ({ emoji }) => {
          setFieldValue("body", values.body + emoji);
        };

        return (
          <form onReset={handleReset} onSubmit={handleSubmit} tabIndex="500">
            <div className="">
              <div className="form-group">
                <div className="row align-items-center">
                  <label className="col-xl-2 col-md-3">To</label>
                  <div className="col-xl-10 col-md-9">
                    <input
                      type="text"
                      readOnly
                      value={`${props.firstName} ${props.lastName}`}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row ">
                  <label className="col-xl-2 col-md-3">Message</label>
                  <div className="col-xl-10 col-md-9">
                    <EmojiPicker onSelectEmoji={onSelectEmoji} />

                    <textarea
                      rows="10"
                      name="body"
                      id="body"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.body}
                      placeholder="Type your message here..."
                      disabled={isSubmitting}
                    />
                    {errors.body && touched.body && (
                      <div className="input-validation-error px-3 py-1">
                        {errors.body}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-end align-items-center pl-3 pr-3">
              <div className="mr-lg-4 mb-3 mb-lg-0">
                <Upload
                  attachment={props.attachment}
                  onUploadFilesChanged={props.onUploadFilesChanged}
                  saveVideoModal={props.saveVideoModal}
                  removeAttachment={props.removeAttachment}
                />
              </div>

              <div className="mr-sm-2">
                <button
                  style={{ lineHeight: "19px" }}
                  type="button"
                  onClick={props.goBack}
                  className="btn btn-default btn-space mr-2"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  style={{ lineHeight: "19px" }}
                  type="reset"
                  className="btn btn-danger mr-2"
                  disabled={isSubmitting}
                >
                  Reset
                </button>
                <button
                  style={{ lineHeight: "19px" }}
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending message..." : "Send message"}
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ComposeMsgForm;

import React from "react";
import "./style.css";

const cdn = "https://dxv8p7y79jw20.cloudfront.net/";

export default function Avatar({ extraClass, size = 50, item, ...rest }) {
  return (
    <div
      className={`user-avatar ${item.isGroup ? "group" : ""} ${extraClass ||
        ""}`}
      style={{
        width: size,
        minWidth: size,
        maxWidth: size,
        height: size,
        minHeight: size,
        maxHeight: size,
        backgroundImage:
          (!!item.isGroup && item.users !== undefined ) || !item.avatar
            ? ""
            : `url(${cdn}${item.avatar})`
      }}
      {...rest}
    >
      {!!item.isGroup && item.users !== undefined ? (
        <React.Fragment>
          {item.users.slice(0, 2).map((i, idx) => (
            <Avatar key={idx} size={size * 0.8} item={i} />
          ))}
          {item.users.length > 3 && (
            <div className="badge">+{item.users.length - 3}</div>
          )}
        </React.Fragment>
      ) : (
        !item.avatar && (
          <div
            style={{ fontSize: `${size * 0.3}px` }}
          >{`${item.firstName[0]} ${item.lastName[0]}`}</div>
        )
      )}
    </div>
  );
}

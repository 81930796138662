import SettingsService from "../../../services/SettingsService";
import Breaks from "../../../containers/Schedule/Breaks";
import moment from "moment";
import { MYSQL_DATETIME_FORMAT, SCHEDULE_MODES, SCHEDULE_STATUSES, TABS } from "../../../containers/Schedule/Constant";
import ScheduleService from "../../../services/ScheduleService";
import ErrorToast from "../../BryntumScheduler/ErrorToast";
import COLORS from "../../BryntumScheduler/colors";
import { buildEventData, eventHasChanged, eventIsOnDates, processEventSave } from "./EventHelper";
import { calculateEstimation, calculateMyEstimation, canModifyShift, isManager } from "./SchedulerHelper";
import { saveSchedule } from "./CUD_API";
import AsyncAlert from "../../../helpers/AsyncAlert";
function clearSelectedSchedule() {
  try {
    if (this.refs.scheduler) {
      this.refs.scheduler.setSelectedEvents([]);
    }
    if (this.refs.calendarView) {
      this.refs.calendarView.setSelectedEvents([]);
    }
  } catch (e) {
    console.log(e);
  }


}
function notifyForEvent(eventRecord) {
  if (
    eventRecord.data &&
    eventRecord.data.id &&
    eventRecord.data.resourceId === this.state.user.id
  ) {
    SettingsService.getEventNotificationConfig(eventRecord.data.id)
      .then(response => {
        if (!response.data.scheduleId) {
          response.data = {
            rules: { enabled: true, minsBefore: 30 },
            viaEmail: 1,
            viaSms: 0,
            viaWeb: 0,
            viaPush: 0
          };
        }
        this.setState({
          eventId: eventRecord.data.id,
          eventNotification: response.data,
          openEventModal: true
        });
      })
      .catch(e => {
        console.log(e);
      });
  }
}
function onEventMove(event) {
    if (!event.eventRecords || !event.eventRecords[0]) return;
    const eventRecords = event.eventRecords[0];
    if (!canModifyShift(eventRecords.data, this.state.activeTab, this.state.user && isManager(this.state.user))) {
        this.setState({
            isLoading: false,
            eventsVersion: this.state.eventsVersion + 1,
            resourcesVersion: this.state.resourcesVersion + 1,
            availableEventsVersion: this.state.availableEventsVersion + 1,
        });
        return;
    }
    const {
        id,
        resourceId,
        name,
        currentDate,
        startDate,
        endDate,
        notes,
        locationId,
        jobId,
        sectionId = [],
        sectionData = [],
    } = eventRecords.data;
    const { config } = this.state;
    if (!eventHasChanged(eventRecords) || !eventIsOnDates(startDate, endDate, config.startDate, config.endDate)) {
        this.setState({
            isLoading: false,
            eventsVersion: this.state.eventsVersion + 1,
            resourcesVersion: this.state.resourcesVersion + 1,
            availableEventsVersion: this.state.availableEventsVersion + 1,
        });
        return;
    }
    const { breaks, breakData } = Breaks.constructBreakDate(eventRecords.data);

  const {
    resourceId: oldUserId,
    locationId: oldLocationId,
    jobId: oldJobId,
    startDate: oldStartDate,
    endDate: oldEndDate,
    currentDate: oldCurrentDate
  } = eventRecords.originalData;
  const data = {
    id,
    resourceId,
    name,
    startDate: moment(startDate).format(MYSQL_DATETIME_FORMAT) + ':00',
    endDate: moment(endDate).format(MYSQL_DATETIME_FORMAT) + ':00',
    currentDate: moment(currentDate).format(MYSQL_DATETIME_FORMAT) + ':00',
    breaks,
    breakData: JSON.stringify(breakData),
    notes,
    status: SCHEDULE_STATUSES.PENDING,
    isUnavailable: 0,
    oldUserId: 0,
    locationId,
    jobId,
    sectionId: JSON.stringify(sectionId),
    sectionData: JSON.stringify(sectionData),
  };
  if (parseInt(resourceId) === 0) {
    data.jobId = oldJobId;
    data.locationId = oldLocationId;
    data.oldUserId = oldUserId;
    data.status = SCHEDULE_STATUSES.APPROVED;
  } else {
    data.oldUserId = 0;
    data.status = SCHEDULE_STATUSES.PENDING;
  }
  let scheduleChanged = false;
  if (parseInt(resourceId) !== parseInt(oldUserId)) {
    if (parseInt(resourceId) === 0 || parseInt(oldUserId) === 0) {
      data.startDate = moment(oldStartDate).format(MYSQL_DATETIME_FORMAT) + ':00';
      data.endDate = moment(oldEndDate).format(MYSQL_DATETIME_FORMAT) + ':00';
      data.currentDate = moment(oldCurrentDate).format(MYSQL_DATETIME_FORMAT) + ':00';
    }

    scheduleChanged = true;
  }
  if (["Unavailability", "Scheduled Day Off"].includes(name)) {
    data.status = SCHEDULE_STATUSES.APPROVED;
    data.isUnavailable = 1;
  }
  this.setState({
    isLoading: true
  });
  const isAvailable = parseInt(resourceId.toString()) === 0;
  return ScheduleService.saveSchedule(data).then((response) => {
    const view = this.state.activeTab === TABS.MY_SHIFT ? (isAvailable ? "selfAvailable" : "selfEvents") : (isAvailable ? "available" : "events");
    const events = this.state[view];
    const state = {
      isLoading: false,
      eventsVersion: this.state.eventsVersion + 1,
      resourcesVersion: this.state.resourcesVersion + 1,
      availableEventsVersion: this.state.availableEventsVersion + 1,
    };
    if (response.data.message) {
      new ErrorToast(`Error: ${response.data.message}`);
      state[view] = events.filter(p => p.id !== id);
      this.setState(state);
    } else {
      let eventId =
        `${id}`.indexOf("generated") === -1
          ? id
          : response.data.id;
      const ind = events.findIndex(p => p.id === eventId);
      const eventData = {
        ...eventRecords.data,
        ...data,
        id: eventId,
        oldUserId: data.oldUserId ? data.oldUserId : 0,
        startDate: moment(data.startDate).toDate(),
        endDate: moment(data.endDate).toDate(),
        currentDate: moment(data.currentDate).toDate(),
        eventColor: COLORS[data.status],
        cls: data.status === 'approved' ? eventRecords.data.cls : '',
        resizable: this.state.mode === SCHEDULE_MODES.TODAY,
      };
      if (isAvailable) {
        eventData.eventStyle = "hollow";
      }
      if (ind >= 0) {
        events[ind] = eventData;
      } else {
        events.push(eventData);
      }
      state[view] = events;
      if (scheduleChanged) {
        const oldview = this.state.activeTab === TABS.MY_SHIFT ? (isAvailable ? "selfEvents" : "selfAvailable") : (isAvailable ? "events" : "available");
        const oldEvents = this.state[oldview];
        state[oldview] = oldEvents.filter(p => p.id !== id);
      }
      this.setState(state, async () => {
        if (this.state.activeTab === TABS.MY_EMPLOYEES) {
          await this.call(calculateEstimation)
        } else if (this.state.activeTab === TABS.MY_SHIFT) {
          await this.call(calculateMyEstimation);
        }
      });
    }
  }).catch(() => {
    this.setState({
      eventsVersion: this.state.eventsVersion + 1,
      resourcesVersion: this.state.resourcesVersion + 1,
      availableEventsVersion: this.state.availableEventsVersion + 1,
      isLoading: false,
    });
  });

}
function onEventsAssign(ids, evs, isMySchedule) {
  if (isMySchedule) {
    const { selfEvents, selfAvailable } = this.state;
    const newEvents = [...selfEvents, ...evs];
    const availableEv = selfAvailable.filter(
      ev => !ids.includes(ev.id)
    );
    this.setState(
      {
        selfAvailable: availableEv,
        selfEvents: newEvents,
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion:
          this.state.availableEventsVersion + 1
      },
      async () => {
        if (this.state.activeTab === TABS.MY_EMPLOYEES) {
          await this.call(calculateEstimation);
        } else if (this.state.activeTab === TABS.MY_SHIFT) {
          await this.call(calculateMyEstimation);
        }
      }
    );
  } else {
    const { events, available } = this.state;
    const newEvents = [...events, ...evs];
    const availableEv = available.filter(ev => !ids.includes(ev.id));
    this.setState(
      {
        available: availableEv,
        events: newEvents,
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion:
          this.state.availableEventsVersion + 1
      },
      async () => {
        if (this.state.activeTab === TABS.MY_EMPLOYEES) {
          await this.call(calculateEstimation);
        } else if (this.state.activeTab === TABS.MY_SHIFT) {
          await this.call(calculateMyEstimation);
        }
      }
    );
  }

}
async function changeToRegular(event) {
  this.setState({
    isLoading: true
  });
  return ScheduleService.convertToRegularEvents(event.id).then(() => {
    const { events } = this.state;
    const unEventInd = events.findIndex(ev => ev.id === event.id);
    if (unEventInd && events[unEventInd]) {
      const unEvent = events[unEventInd];
      unEvent.status = SCHEDULE_STATUSES.TO_REGULAR;
      events[unEventInd] = unEvent;
      this.call(clearSelectedSchedule);
      this.setState({
        events: events,
        isLoading: false,
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1
      }, async () => {
        if (this.state.activeTab === TABS.MY_EMPLOYEES) {
          await this.call(calculateEstimation)
        } else if (this.state.activeTab === TABS.MY_SHIFT) {
          await this.call(calculateMyEstimation);
        }
      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  }).catch((err) => {
    console.log(err);
    this.setState({
      isLoading: false
    });
  });


}
async function saveAvailableSchedule(event) {
  return new Promise((resolve, reject) => {
    const { saveData, breaks, breakData, newStartDate, newEndDate } = processEventSave({ eventRecord: event });
    saveData.oldUserId = event.originalData.resourceId;
    saveSchedule(saveData).then(response => {
      const eventData = buildEventData(saveData, saveData.id, breaks, breakData, newStartDate, newEndDate, event.data, true, this.state.mode, this.state.mode === SCHEDULE_MODES.THIS_MONTH);
      eventData.eventStyle = "hollow";
      resolve(eventData);
    }).catch(err => {
      reject(err);
    })
  });
}
async function makeShiftsAvailable(events) {
  if (events.length) {
    this.setState({
      isLoading: true,
      openAvailableShift: true
    });
    const savedEvents = events.map(ev => {
      ev.data.resourceId = 0;
      ev.resourceId = 0;
      return this.call(saveAvailableSchedule, ev);
    });
    await Promise.all(savedEvents).then(newEvents => {
      const ids = newEvents.map(ev => ev.id);
      const { events, available } = this.state;
      const newAvEvents = [...available, ...newEvents];
      const eventsEv = events.filter(ev => !ids.includes(ev.id));
      this.call(clearSelectedSchedule);
      this.setState(
        {
          available: newAvEvents,
          events: eventsEv,
          isLoading: false,
          eventsVersion: this.state.eventsVersion + 1,
          resourcesVersion: this.state.resourcesVersion + 1,
          availableEventsVersion:
            this.state.availableEventsVersion + 1
        },
        async () => {
          if (this.state.activeTab === TABS.MY_EMPLOYEES) {
            await this.call(calculateEstimation);
          } else if (this.state.activeTab === TABS.MY_SHIFT) {
            await this.call(calculateMyEstimation);
          }
        }
      );
    });
  }
}
async function releaseEvent(events) {
  if (events.length) {
    const ids = events.map(ev => {
      return ev.data.id;
    });
    const result = await AsyncAlert.confirmWithInput(
      `Coverage request for ${events.length || 1} event${
      events.length > 1 ? "s" : ""
      }`,
      'Request',
      'Abort'
    );
    if (result.confirm) {
      this.setState({
        isLoading: true
      });
      ScheduleService.releaseEvents(ids, result.note)
        .then(() => {
          const { selfEvents } = this.state;
          const newSelfEvents = selfEvents.map(ev => {
            if (ids.includes(ev.id)) {
              ev.cls = "released";
              ev.editable = false;
              ev.resizable = false;
              ev.draggable = false;
              ev.status = SCHEDULE_STATUSES.RELEASED;
              ev.eventColor = COLORS[SCHEDULE_STATUSES.RELEASED];
            } else if (ev.color) {
              ev.cls = ev.color
            }
            return ev;
          });
          this.call(clearSelectedSchedule);
          this.setState(
            {
              selfEvents: newSelfEvents,
              isLoading: false,
              eventsVersion: this.state.eventsVersion + 1,
              resourcesVersion: this.state.resourcesVersion + 1,
              availableEventsVersion:
                this.state.availableEventsVersion + 1
            },
            async () => {
              if (this.state.activeTab === TABS.MY_EMPLOYEES) {
                await this.call(calculateEstimation);
              } else if (this.state.activeTab === TABS.MY_SHIFT) {
                await this.call(calculateMyEstimation);
              }
            }
          );
        })
        .catch(err => {
          this.setState({
            isLoading: false,
            eventsVersion: this.state.eventsVersion + 1,
            resourcesVersion: this.state.resourcesVersion + 1,
            availableEventsVersion:
              this.state.availableEventsVersion + 1
          });
        });
    }

  }
}
async function pickEvents(ids) {
  if (ids.length) {
    const result = await AsyncAlert.confirmWithInput(
      `Request to pick ${ids.length || 1} event${
      ids.length > 1 ? "s" : ""
      }`,
      'Request',
      'Abort'
    );
    if (result.confirm) {
      this.setState({
        isLoading: true
      });
      ScheduleService.pickEvents(ids, result.note)
        .then(() => {
          const { selfAvailable, selfEvents, user } = this.state;
          const events = selfAvailable.filter(ev => ids.includes(ev.id))
          const newSelfAvailable = selfAvailable.filter(ev => !ids.includes(ev.id));
          const newSelfEvents = [...selfEvents, ...events.map(ev => {
            ev.cls = "released";
            ev.editable = false;
            ev.resizable = false;
            ev.draggable = false;
            ev.status = SCHEDULE_STATUSES.RELEASED;
            ev.eventColor = COLORS[SCHEDULE_STATUSES.RELEASED];
            ev.resourceId = user.id;
            ev.style = 'colored';
            return ev;
          })];
          this.call(clearSelectedSchedule);
          this.setState(
            {
              selfEvents: newSelfEvents,
              selfAvailable: newSelfAvailable,
              isLoading: false,
              eventsVersion: this.state.eventsVersion + 1,
              resourcesVersion: this.state.resourcesVersion + 1,
              availableEventsVersion:
                this.state.availableEventsVersion + 1
            },
            async () => {
              if (this.state.activeTab === TABS.MY_EMPLOYEES) {
                await this.call(calculateEstimation);
              } else if (this.state.activeTab === TABS.MY_SHIFT) {
                await this.call(calculateMyEstimation);
              }
            }
          );
        })
        .catch(err => {
          this.setState({
            isLoading: false,
            eventsVersion: this.state.eventsVersion + 1,
            resourcesVersion: this.state.resourcesVersion + 1,
            availableEventsVersion:
              this.state.availableEventsVersion + 1
          });
        });
    }

  }
}
function handleSelectionChange({ selected }) {
  this.setState({
    selectedEvent: (selected.length && selected[0].name) || ""
  });
}

export {
  notifyForEvent,
  clearSelectedSchedule,
  onEventMove,
  onEventsAssign,
  changeToRegular,
  makeShiftsAvailable,
  releaseEvent,
  handleSelectionChange,
  pickEvents
}

export default {
    pending: 'yellow',
    submitted: 'blue',
    approved: 'green',
    Regular: 'green',
    Vacation: 'pink',
    Sick: 'purple',
    Personal: 'teal',
    Offsite: 'orange',
    'Leave of Absence': 'cyan',
    Other: 'gray',
    rejected: 'red',
    Break: 'gray',
    Unavailability: 'gray',
    released: 'released',
    'Scheduled Day Off': 'red-lines',
    'to-regular': 'gray-lines'
};

import React from "react";
import enGB from "date-fns/locale/en-GB"
import enUS from "date-fns/locale/en-US"
import Select from "react-select";
import PropTypes from "prop-types";
import Modal from "react-modal";
import customModalStyles from "./customStyle";
import DatePicker, { registerLocale } from "react-datepicker";
import EmployeeService from "../../services/EmployeeService";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

class ShareEditEmployee extends React.Component {
  state = {
    error: null,
    success: null,
    selectLocation: undefined,
    startDate: undefined,
    endDate: undefined,
    managers: [],
    selectedManager: [],
    shareData: {
      locationId: "-1",
      startDate: "",
      endDate: ""
    },
    loading: false,
    //startMinDate: new Date(),
    startMaxDate: moment()
      .add(55, "days")
      .toDate(),
    endMinDate: moment()
      .add(1, "day")
      .toDate(),
    endMaxDate: moment()
      .add(60, "days")
      .toDate()
  };

  componentDidMount() {
    this.getShare();
  }

  getShare = () => {
    this.setState({
      loading: true
    });
    const { employeeId } = this.props.user;
    EmployeeService.getShareEmployee(employeeId)
      .then(response => {
        if (response.data) {
          if (response.data.success) {
            const { share, managers } = response.data;
            this.startDateValueChanged(new Date(share.startDate));
            if (share.endDate)
              this.endDateValueChanged(new Date(share.endDate));
            this.setState({
              shareData: share,
              selectLocation: share.locationId,
              selectedManager: share.supervisors,
              loading: false,
              managers
            });
          } else {
            const { employee } = response.data;
            this.props.employeeWasShared(
              this.props.user,
              employee && employee.shared ? 1 : 0
            );
          }
        } else {
          this.props.employeeWasShared(this.props.user, 1);
        }
      })
      .catch(err => console.log(err));
  };
  toastDismiss = () => {
    this.setState({
      error: null
    });
  };
  endDateValueChanged = date => {
    this.setState({
      endDate: date,
      startMaxDate: moment(date)
        .add(-1, "days")
        .toDate(),
      error: null
    });
  };
  startDateValueChanged = date => {
    this.setState({
      startDate: date,
      //startMinDate: moment(date).toDate(),
      startMaxDate: moment(date)
        .add(55, "days")
        .toDate(),
      endMinDate: moment(date)
        .add(1, "day")
        .toDate(),
      endMaxDate: moment(date)
        .add(60, "days")
        .toDate(),
      error: null
    });
  };
  clickOutside = () => {
    this.refs.startPicker.cancelFocusInput();
    this.refs.startPicker.setOpen(false);
  };
  clickOutsideEndPicker = () => {
    this.refs.endPicker.cancelFocusInput();
    this.refs.endPicker.setOpen(false);
  };
  onChangeLocation = value => {
    this.setState({
      selectLocation: value ? value.value : undefined,
      error: null,
    }, () => {
      this.getLocationManagers(null)
    });
  };
  onChangeManagers = value => {
    this.setState({
      selectedManager: value ? value : [],
      error: null,
    });
  };
  getLocationManagers = (search) => {
    if (this.state.selectLocation) {
      EmployeeService.fetchLocationManagers(this.state.selectLocation, search).then((response) => {
        this.setState({
          managers: [{ value: 'all', name: 'All Managers in Location' }, ...(response.data && response.data.employees ? response.data.employees : []).map(e => {
            return {
              name: `${e.firstName} ${e.lastName}`,
              value: e.id
            }
          })]
        })
      }).catch((e) => {
        this.setState({
          managers: []
        })
      })
    }
  }
  updateShareEmployee = () => {
    const { selectLocation, startDate, endDate, shareData, selectedManager } = this.state;
    if (!selectLocation) {
      this.setState({
        error: "Please select a location"
      });
      return;
    }
    if (!startDate) {
      this.setState({
        error: "Please select start date"
      });
      return;
    }
    if (endDate && moment(startDate) >= moment(endDate)) {
      this.setState({
        error: "End date should be greater than start date"
      });
      return;
    }
    const managers = selectedManager.filter(s => s.value !== 'all').map(s => s.value);
    const supervisorIds = shareData.supervisors ? shareData.supervisors.map(s => s.value) : [];
    if (
      parseInt(selectLocation) === shareData.locationId &&
      moment(startDate).format("YYYY-MM-DD") ===
      moment(shareData.startDate).format("YYYY-MM-DD") &&
      moment(endDate || null).format("YYYY-MM-DD") ===
      moment(shareData.endDate || null).format("YYYY-MM-DD") &&
      (managers.length === supervisorIds.length && managers.filter(m => supervisorIds.includes(m.value)).length === 0)
    ) {
      this.setState({
        error: "Nothing Has Changed"
      });
      return;
    }
    this.setState({ error: null, loading: true });
    EmployeeService.updateShareEmployee(
      selectLocation,
      shareData.id,
      startDate,
      endDate ? endDate : undefined,
      managers.length === 0 ? undefined : JSON.stringify(managers)
    )
      .then(res => {
        if (res.data.success) {
          this.props.setEmployeeAsShared(this.props.user, 2);
          this.setState({ loading: false });
        } else {
          this.props.employeeWasShared(this.props.user, res.data.shared);
          this.setState({ loading: false });
        }
      })
      .catch(err => console.log(err));
  };
  deleteShareEmployee = () => {
    confirmAlert({
      title: "Delete Employee Share",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const { shareData } = this.state;
            this.setState({ loading: true });
            EmployeeService.deleteShareEmployee(shareData.id)
              .then(res => {
                if (res.data.success) {
                  this.props.setEmployeeAsShared(this.props.user, 0);
                  this.setState({ loading: false });
                } else {
                  this.props.employeeWasShared(
                    this.props.user,
                    res.data.shared
                  );
                  this.setState({ loading: false });
                }
              })
              .catch(err => console.log(err));
          }
        },
        {
          label: "No"
        }
      ]
    });
  };

  render() {
    const { firstName, lastName, locationId } = this.props.user;
    const {
      startDate,
      endDate,
      /*startMinDate,*/ startMaxDate,
      endMinDate,
      endMaxDate,
      loading,
      error,
      selectLocation,
      selectedManager,
      managers,
    } = this.state;
    const locations = this.props.locations.filter(l => l.value !== locationId);
    const location = selectLocation ? locations.filter(l => l.value === selectLocation) : null;
    const { firstDayOfWeek } = this.props;
    if (firstDayOfWeek) {
      registerLocale('en-GB', enGB)
    } else {
      registerLocale('en-US', enUS)
    }
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        style={customModalStyles}
        contentLabel="Share Employee"
        shouldCloseOnOverlayClick={false}
        className="col-md-5 col-sm-12 card"
      >
        <div>
          <div className={loading ? "loader" : "hidden"}>
            <div id="spinner" />
          </div>
          <div className="modal-header">
            <h4>
              Edit Share for Employee:{" "}
              <span className="breadcrumb-item active">
                {firstName} {lastName}
              </span>
            </h4>
          </div>
          <div className="modal-body custom-height min-height-300">
            <div className="row">
              <Select
                className={`col-12 mr-3 ${error && !selectLocation ? 'error-border' : ''}`}
                placeholder="Select a Location"
                getOptionLabel={o => o.name}
                onChange={this.onChangeLocation}
                options={locations}
                value={location}
                isClearable={true}
              />
              {error && !selectLocation && (
                <div className="w-100 mt-2 text-center text-danger">
                  {error}
                </div>
              )}
            </div>
            <div className="row  mt-3">
              <div className="col-12">
                <label>Will report to?</label>
                <Select
                  isMulti={true}
                  className={`${error && !selectLocation ? 'error-border' : ''}`}
                  placeholder="Will Report to?"
                  getOptionLabel={o => o.name}
                  onChange={this.onChangeManagers}
                  options={managers}
                  isDisabled={loading || !location}
                  value={selectedManager}
                  isClearable={true}
                />
                {error && !selectLocation && (
                  <div className="w-100 mt-2 text-center text-danger">
                    {error}
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-6">
                <label>Start Date</label>
                <DatePicker
                  locale={firstDayOfWeek ? 'en-GB' : 'en-US'}
                  selected={startDate}
                  onChange={this.startDateValueChanged}
                  /*minDate={startMinDate}*/
                  maxDate={startMaxDate}
                  className={`${error && !startDate && selectLocation ? 'error-border' : ''}`}
                  dateFormat="MM/dd/yyyy"
                  ref="startPicker"
                  onClickOutside={this.clickOutside}
                />
                {error && !startDate && selectLocation && (
                  <div className="w-100 mt-2 text-center text-danger">
                    {error}
                  </div>
                )}
              </div>
              <div className="col-6">
                <label>End Date</label>
                <DatePicker
                  locale={firstDayOfWeek ? 'en-GB' : 'en-US'}
                  selected={endDate}
                  onChange={this.endDateValueChanged}
                  minDate={endMinDate}
                  maxDate={endMaxDate}
                  dateFormat="MM/dd/yyyy"
                  className={`${error && !endDate && selectLocation && startDate ? 'error-border' : ''}`}
                  ref="endPicker"
                  onClickOutside={this.clickOutsideEndPicker}
                />
              </div>
            </div>
            <div className="row mt-3">
              {error && startDate && selectLocation && (
                <div className="w-100 mt-2 text-center text-danger">
                  {error}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <div className="pull-right">
              <button
                className="btn btn-danger mr-2"
                onClick={this.deleteShareEmployee}
              >
                <i className="fas fa-trash" /> Delete
              </button>
              <button
                className="btn btn-primary mr-2"
                onClick={this.updateShareEmployee}
              >
                <i className="fas fa-share" /> Update
              </button>
              <button className="btn btn-light" onClick={this.props.closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ShareEditEmployee.defaultProps = {
  closeModal: () => { },
  isOpen: false
};
ShareEditEmployee.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
export default ShareEditEmployee;

import React from "react";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import scheduleService from "../../services/ScheduleService";
import "./assets/styles/Notifications.css";
import InfiniteScroll from "react-infinite-scroller";
import NotificationService from "../../services/NotificationService";
import StringHelper from "../../helpers/StringHelper";
import Tooltip from "../../components/Blocks/Tooltip";
import Modal from "react-modal";
import Duration from "../../helpers/Duration";
import ReactPaginate from "react-paginate";
import Avatar from '../../components/Blocks/Avatar'
import ReleasedRow from "./ReleasedRow";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

const NOTIFICATION_MODES = {
  BROWSE: "browse",
  FETCHING: "fetching"
};

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      user,
      page: 1,
      take: 10,
      sharePage: 0,
      shareTake: 10,
      sharePageCount: 0,
      schedules: [],
      totalRows: 0,
      shares: [],
      totalShares: 0,
      searchValue: "",
      selectedAll: false,
      allNotifications: [],
      hasMoreAllNotifications: true,
      hasMoreShareNotifications: true,
      mode: NOTIFICATION_MODES.BROWSE,
      modalSchedules: [],
      isModalOpen: false,
      released: [],
      totalReleasedRows: 0,
      releasedPageCount: 0,
      releasePage: 1,
      selectedAllReleased: false,
      isLoading: true,
    };
  }
  fetchEmployeeShares = page => {
    this.setState(
      {
        hasMoreShareNotifications: false
      });

    return NotificationService.fetchEmployeeSharesNotifications({
      start: page * this.state.take,
      take: this.state.take
    }).then(response => {
      if (response.data) {
        this.setState({
          mode: NOTIFICATION_MODES.BROWSE,
          shares: [...this.state.shares, ...response.data.shares],
          totalShares: response.data.totalRows,
          hasMoreShareNotifications:
            response.data.length === this.state.take
        });
      }
      return response;
    });
  };
  fetchSubmitNotifications = () => {
    this.setState({
      schedules: []
    });
    return NotificationService.fetchSubmitNotifications({
      start: (this.state.page - 1) * this.state.take,
      take: this.state.take
    }).then(response => {
      if (response.data) {
        this.setState({
          mode: NOTIFICATION_MODES.BROWSE,
          schedules: response.data.schedules,
          totalRows: response.data.totalRows,
          pageCount: response.data.totalRows
            ? Math.ceil(response.data.totalRows / this.state.take)
            : 0
        });
      }
      return response;
    });
  };
  fetchReleasedNotifications = () => {
    this.setState({
      released: []
    });
    return NotificationService.fetchReleasedNotifications({
      start: (this.state.releasePage - 1) * this.state.take,
      take: this.state.take
    }).then(response => {
      if (response.data) {
        this.setState({
          mode: NOTIFICATION_MODES.BROWSE,
          released: response.data.schedules,
          totalReleasedRows: response.data.totalRows,
          releasedPageCount: response.data.totalRows
            ? Math.ceil(response.data.totalRows / this.state.take)
            : 0
        });
      }
      return response;
    });
  };
  approveSchedule = (index, type = "submitted") => {
    const notifications =
      type === "released" ? this.state.released : this.state.schedules;
    const notification = notifications[index];
    const scheduleId = notification.schedule.map(item => item.id);
    const notificationId = [notification.id];
    this.setState({ isLoading: true });
    const { user } = this.state;
    if (type === "released") {
      scheduleService.approveReleasedSchedule({ scheduleId, notificationId }).then(() => {
        const { isMultiple, mgResponse } = notification;
        if (isMultiple && mgResponse && mgResponse[user.id]) {
          notification.mgResponse[user.id].status = 'approved';
        }
        this.setState({
          totalReleasedRows: this.state.totalReleasedRows - 1,
          released: [
            ...this.state.released.slice(0, index),
            { ...notification, statusChanged: "Approved" },
            ...this.state.released.slice(index + 1)
          ],
          isLoading: false
        });
      }).catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
    } else {
      scheduleService.approveSchedule({ scheduleId, notificationId }).then(() => {
        this.setState({
          totalRows: this.state.totalRows - 1,
          schedules: [
            ...this.state.schedules.slice(0, index),
            { ...notification, statusChanged: "Approved" },
            ...this.state.schedules.slice(index + 1)
          ],
          isLoading: false
        }).catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
      });
    }
  };
  rejectSchedule = (index, type = "submitted") => {
    const notifications =
      type === "released" ? this.state.released : this.state.schedules;
    const notification = notifications[index];
    const scheduleId = notification.schedule.map(item => item.id);
    const notificationId = [notification.id];
    const { user } = this.state;
    this.setState({ isLoading: true });
    if (type === "released") {
      scheduleService.rejectReleasedSchedule({ scheduleId, notificationId }).then(() => {
        const { isMultiple, mgResponse } = notification;
        if (isMultiple && mgResponse && mgResponse[user.id]) {
          notification.mgResponse[user.id].status = 'rejected';
        }
        this.setState({
          totalReleasedRows: this.state.totalReleasedRows - 1,
          released: [
            ...this.state.released.slice(0, index),
            { ...notification, statusChanged: "Rejected" },
            ...this.state.released.slice(index + 1)
          ],
          isLoading: false
        });
      }).catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
    } else {
      scheduleService.rejectSchedule({ scheduleId, notificationId }).then(() => {
        this.setState({
          totalRows: this.state.totalRows - 1,
          schedules: [
            ...this.state.schedules.slice(0, index),
            { ...notification, statusChanged: "Rejected" },
            ...this.state.schedules.slice(index + 1)
          ],
          isLoading: false
        });
      }).catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
    }
  };
  approveSelectedSchedule = (type = "submitted") => {
    const scheduleId = [];
    const notificationId = [];
    const notifications =
      type === "released" ? this.state.released : this.state.schedules;
    notifications
      .filter(
        item => !item.statusChanged && (item.selected || this.state.selectedAll)
      )
      .forEach(item => {
        item.schedule.forEach(schedule => scheduleId.push(schedule.id));
        notificationId.push(item.id);
      });
    let totalChanged = 0;
    const schedules = notifications.map(item => {
      if (item.selected && !item.statusChanged) {
        totalChanged += 1;
        return {
          ...item,
          statusChanged: "Approved"
        };
      }
      return item;
    });
    let state = {
      schedules,
      totalRows: this.state.totalRows - totalChanged,
      selectedAll: false
    };
    if (type === "released") {
      state = {
        released: schedules,
        totalReleasedRows: this.state.totalReleasedRows - totalChanged,
        selectedAllReleased: false
      };
    }
    if (type === "released") {
      scheduleService.approveReleasedSchedule({ scheduleId, notificationId }).then(() => {
        state.isLoading = false;
        this.setState(state);
      }).catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
    } else {
      scheduleService.approveSchedule({ scheduleId, notificationId }).then(() => {
        state.isLoading = false;
        this.setState(state);
      }).catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
    }
  };
  rejectSelectedSchedule = (type = "submitted") => {
    const scheduleId = [];
    const notificationId = [];
    const notifications =
      type === "released" ? this.state.released : this.state.schedules;
    notifications
      .filter(
        item => !item.statusChanged && (item.selected || this.state.selectedAll)
      )
      .forEach(item => {
        item.schedule.forEach(schedule => scheduleId.push(schedule.id));
        notificationId.push(item.id);
      });

    const schedules = notifications.map(item => {
      if (item.selected && !item.statusChanged) {
        return {
          ...item,
          statusChanged: "Rejected"
        };
      }
      return item;
    });

    let state = {
      schedules,
      selectedAll: false
    };
    if (type === "released") {
      state = {
        released: schedules,
        selectedAllReleased: false
      };
    }
    if (type === "released") {
      scheduleService.rejectReleasedSchedule({ scheduleId, notificationId }).then(() => {
        state.isLoading = false;
        this.setState(state);
      }).catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
    } else {
      scheduleService.rejectSchedule({ scheduleId, notificationId }).then(() => {
        state.isLoading = false;
        this.setState(state);
      }).catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
    }
  };
  setPage = (page, type = "submitted") => {
    const oldPage =
      type === "released" ? this.state.releasePage : this.state.page;
    if (page.selected + 1 !== oldPage) {
      if (type === "released") {
        this.setState(
          {
            releasePage: page.selected + 1,
            mode: NOTIFICATION_MODES.FETCHING
          },
          () => {
            this.fetchReleasedNotifications();
          }
        );
      } else {
        this.setState(
          {
            page: page.selected + 1,
            mode: NOTIFICATION_MODES.FETCHING
          },
          () => {
            this.fetchSubmitNotifications();
          }
        );
      }
    }
  };
  onSearchValueChange = event => {
    this.setState({
      searchValue: event.target.value.toLowerCase()
    });
  };
  onSelectAll = () => {
    this.setState({
      selectedAll: !this.state.selectedAll,
      schedules: this.state.schedules.map(item => ({
        ...item,
        selected: !this.state.selectedAll
      }))
    });
  };
  onReleasedSelectAll = () => {
    this.setState({
      selectedAllReleased: !this.state.selectedAllReleased,
      released: this.state.released.map(item => ({
        ...item,
        selected: !this.state.selectedAllReleased
      }))
    });
  };
  onCheckboxChange = index => {
    const selected =
      this.state.schedules[index] === undefined
        ? false
        : this.state.schedules[index].selected;
    this.setState(
      {
        schedules: [
          ...this.state.schedules.slice(0, index),
          { ...this.state.schedules[index], selected: !selected },
          ...this.state.schedules.slice(index + 1)
        ]
      },
      () => {
        this.setState({
          selectedAll: this.state.schedules.every(item => item.selected)
        });
      }
    );
  };
  validateActionButtons = (type = "submitted") => {
    return type === "released"
      ? !(
        this.state.selectedAllReleased ||
        this.state.released.filter(
          item => !item.statusChanged && item.selected
        ).length
      )
      : !(
        this.state.selectedAll ||
        this.state.schedules.filter(
          item => !item.statusChanged && item.selected
        ).length
      );
  };

  getAllNotifications = (page = 0) => {
    this.setState({
      hasMoreAllNotifications: false
    });
    return NotificationService.getAll(page, this.state.take).then(response => {
      if (response.data) {
        this.setState({
          mode: NOTIFICATION_MODES.BROWSE,
          allNotifications: [
            ...this.state.allNotifications,
            ...response.data
          ],
          hasMoreAllNotifications: response.data.length === this.state.take
        });
      }
      return response;
    });
  };
  componentDidMount = () => {
    const { user } = this.props;
    if (user.role === "manager") {
      Promise.all([
        this.getAllNotifications(0),
        this.fetchSubmitNotifications(),
        this.fetchReleasedNotifications(),
        this.fetchEmployeeShares(0)
      ]).finally(() => {
        this.setState({ isLoading: false });
      })
    } else {

      this.getAllNotifications(0).then(() => {
        this.setState({ isLoading: false });
      });
    }
    Modal.setAppElement(document.getElementById("app"));
  };
  seeScheduleDetails = (index, type = "submitted") => {
    const notifications =
      type === "released" ? this.state.released : this.state.schedules;
    if (notifications[index]) {
      const notification = notifications[index];
      this.setState(
        {
          modalSchedules: notification.schedule
        },
        () => {
          this.setState({
            isModalOpen: true
          });
        }
      );
    }
  };
  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const allItems = [];
    this.state.allNotifications.forEach((notification, i) => {
      allItems.push(
        <div
          key={`notification-${i}`}
          className="list d-flex align-items-center border-bottom py-3"
        >
          <div>
            <div className="media-left">
              <Tooltip
                placement="top"
                text={`${notification.firstName} ${notification.lastName}`}
              >
                <Avatar item={notification} />
              </Tooltip>
            </div>
          </div>
          <div className="wrapper w-100 ml-3">
            <div className="mb-2">
              <h6>{notification.body}</h6>
              {StringHelper.formatScheduleStatus(
                notification.type,
                notification.object,
                notification.data
              )}
              <small className="ml-2 text-muted w-200">
                {moment(notification.createdAt).fromNow()}
              </small>
            </div>
          </div>
          <Link to={`/app/notification/${notification.id}`}>
            <span className="btn text-nowrap btn-link text-primary m-b-5 m-t-5">
              More Details
            </span>
          </Link>
        </div>
      );
    });
    const allShared = [];
    if (this.state.shares) {
      this.state.shares.forEach((notification, i) => {
        allShared.push(
          <div
            key={`notification-${i}`}
            className="list d-flex align-items-center border-bottom py-3"
          >
            <div>
              <div className="media-left">
                <Tooltip
                  placement="top"
                  text={`${notification.firstName} ${notification.lastName}`}
                >
                  <Avatar item={notification} />
                </Tooltip>
              </div>
            </div>
            <div className="wrapper w-100 ml-3">
              <div className="mb-2">
                <h6>{notification.body}</h6>
                {StringHelper.formatScheduleStatus(
                  notification.type,
                  notification.object,
                  notification.data
                )}
                <small className="ml-2 text-muted w-200">
                  {moment(notification.createdAt).fromNow()}
                </small>
              </div>
            </div>
            <Link to={`/app/notification/${notification.id}`}>
              <span className="btn btn-link btn-sm btn-info m-b-5 m-t-5 w-95">
                More Details
              </span>
            </Link>
          </div>
        );
      });
    }
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className={isLoading ? "loader" : "hidden"}>
          <div id="spinner" />
        </div>
      );
    }
    return (
      <div className="container content-area">
        <Modal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          style={customModalStyles}
          contentLabel="Schedule Details"
          shouldCloseOnOverlayClick={false}
        >
          <div className="modal-header">
            <h4>Schedule Details</h4>
          </div>
          <div className="table-responsive">
            <table className="table card-table table-vcenter text-nowrap mb-0">
              <thead>
                <tr className="bg-light">
                  <th className="w-1">No</th>
                  <th>Event Type</th>
                  <th>Notes</th>
                  <th>Status</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Hrs</th>
                </tr>
              </thead>
              <tbody>
                {this.state.modalSchedules.length ? (
                  this.state.modalSchedules.map((s, key) => (
                    <tr key={key} className="border-bottom">
                      <td>
                        <span className="text-muted">
                          {(key + 1).toString().length > 3
                            ? key + 1
                            : ("00" + (key + 1)).slice(-3)}
                        </span>
                      </td>
                      <td>{s.name}</td>
                      <td>{s.notes ? s.notes : ""}</td>
                      <td>
                        {" "}
                        {StringHelper.formatScheduleStatus(
                          s.status,
                          "schedule"
                        )}
                      </td>
                      <td>{moment(s.startDate).format("LLL")}</td>
                      <td>{moment(s.endDate).format("LLL")}</td>
                      <td>
                        <span className="badge badge-info">
                          <i className="fas fa-clock mr-1" />
                          {Duration.durationAsHoursAndMinsString(
                            s.endDate,
                            s.startDate
                          )}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                    <tr>
                      <td colSpan={5}>No data for this notification</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <div className="pull-right">
              <button
                className="btn btn-outline-info btn-sm mr-2"
                onClick={this.closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <section className="section">
          <div className="section-body">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="e-panel card">
                  <div className="card-header">
                    <h4>Notifications</h4>
                  </div>
                  <div className="card-body">
                    <div className="row pb-4">
                      <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item">
                            <NavLink
                              to="/app/notifications/all"
                              className="nav-link"
                              activeClassName="active"
                            >
                              General Notifications
                            </NavLink>
                          </li>
                          {this.state.user.role === "manager" ? (
                            <>
                              <li className="nav-item">
                                <NavLink
                                  exact
                                  to="/app/notifications/approve-notifications"
                                  className="nav-link"
                                  activeClassName="active"
                                >
                                  Schedules For Approval ({this.state.totalRows}
                                  )
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  exact
                                  to="/app/notifications/coverage-notifications"
                                  className="nav-link"
                                  activeClassName="active"
                                >
                                  Coverage requests (
                                  {this.state.totalReleasedRows})
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  exact
                                  to="/app/notifications/approve-employee-shares"
                                  className="nav-link"
                                  activeClassName="active"
                                >
                                  Employee Shares For Approval (
                                  {this.state.totalShares})
                                </NavLink>
                              </li>
                            </>
                          ) : null}
                        </ul>
                      </div>
                    </div>

                    {this.props.location.pathname ===
                      "/app/notifications/approve-notifications" &&
                      this.state.user.role === "manager" && (
                        <>{this.renderApprovedSchedules()}</>
                      )}
                    {this.props.location.pathname ===
                      "/app/notifications/coverage-notifications" &&
                      this.state.user.role === "manager" && (
                        <>{this.renderReleasedSchedules()}</>
                      )}
                    {this.props.location.pathname ===
                      "/app/notifications/approve-employee-shares" &&
                      this.state.user.role === "manager" && (
                        <>
                          {this.state.mode === NOTIFICATION_MODES.BROWSE &&
                            allShared.length > 0 ? (
                              <InfiniteScroll
                                pageStart={-1}
                                loadMore={this.fetchEmployeeShares.bind(this)}
                                hasMore={this.state.hasMoreShareNotifications}
                                loader={
                                  <h6 className="text-center mt-3">
                                    <b>Loading...</b>
                                  </h6>
                                }
                              >
                                {allShared}
                              </InfiniteScroll>
                            ) : (
                              <h6
                                key="no-notifications"
                                className="text-center mt-3"
                              >
                                <b>No new notifications</b>
                              </h6>
                            )}
                        </>
                      )}
                    {this.props.location.pathname ===
                      "/app/notifications/all" ||
                      this.props.location.pathname === "/app/notifications" ? (
                        <>
                          {this.state.mode === NOTIFICATION_MODES.BROWSE &&
                            this.state.allNotifications.length ? (
                              <InfiniteScroll
                                pageStart={0}
                                loadMore={this.getAllNotifications}
                                hasMore={this.state.hasMoreAllNotifications}
                                loader={
                                  <h6 className="text-center mt-3">
                                    <b>Loading...</b>
                                  </h6>
                                }
                              >
                                {allItems}
                              </InfiniteScroll>
                            ) : (
                              <h6
                                key="no-notifications"
                                className="text-center mt-3"
                              >
                                <b>No new notifications</b>
                              </h6>
                            )}
                        </>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderApprovedSchedules() {
    return (
      <>
        <div className="row mb-4">
          {this.state.schedules.filter(item => !item.statusChanged).length > 0 ?
            <div className="col-12 d-flex">
              <div className="form-check form-check-inline mr-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="select-all"
                  onChange={this.onSelectAll}
                  checked={this.state.selectedAll}
                />
                <label className="form-check-label" htmlFor="select-all">
                  Select all
              </label>
              </div>
              <button
                className="btn btn-success btn-sm mr-2"
                onClick={this.approveSelectedSchedule}
                disabled={this.validateActionButtons()}
              >
                <i className="fas fa-check-double mr-1" />
              Approve
            </button>
              <button
                className="btn btn-danger btn-sm mr-2"
                onClick={this.rejectSelectedSchedule}
                disabled={this.validateActionButtons()}
              >
                <i className="fas fa-times mr-1" />
              Reject
            </button>
            </div>
          : null}
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered mb-0 text-nowrap">
            <tbody>
              <tr>
                <th>#</th>
                <th>Employee</th>
                <th>Dept</th>
                <th>Dates Scheduled</th>
                <th>Hrs</th>
                <th>Submitted</th>
                <th className="text-center">Action</th>
              </tr>
              {this.state.schedules.map((item, index) => {
                if (item.statusChanged) {
                  return (
                    <tr key={item.userId} className="text-center">
                      <td colSpan="7">{item.statusChanged}</td>
                    </tr>
                  );
                }
                return (
                  <tr key={item.id}>
                    <td className="align-middle">
                      <div>
                        <input
                          type="checkbox"
                          value={item.selected || ""}
                          checked={item.selected || this.state.selectAll}
                          onChange={() => this.onCheckboxChange(index)}
                        />
                      </div>
                    </td>
                    <td className="align-middle">{item.name}</td>
                    <td className="align-middle">{item.department}</td>
                    <td className="align-middle">
                      <span
                        onClick={() => this.seeScheduleDetails(index)}
                        className="badge badge-success cursor-pointer b-hover"
                      >
                        <i className="fas fa-clock" />{" "}
                        {moment(item.startDate).format("MM-DD-YYYY hh:mm A")}
                        <i className="fas fa-arrow-right mx-2" />
                        <i className="fas fa-clock" />{" "}
                        {moment(item.endDate).format("MM-DD-YYYY hh:mm A")}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span className="badge badge-info">
                        <i className="fas fa-clock mr-1" />
                        {Duration.durationAsHoursAndMinsString(
                          item.endDate,
                          item.startDate
                        )}
                      </span>
                    </td>
                    <td className="align-middle">
                      {moment(item.createdAt).fromNow()}
                    </td>
                    <td className="text-center align-middle">
                      <button
                        className="btn btn-success btn-sm mr-2"
                        onClick={() => this.approveSchedule(index)}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-danger btn-sm mr-2"
                        onClick={() => this.rejectSchedule(index)}
                      >
                        Reject
                      </button>
                      <Link
                        to={`/app/people/${item.code}`}
                        className="btn btn-info btn-sm"
                      >
                        Compose
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {this.state.mode === NOTIFICATION_MODES.FETCHING && (
          <h6 className="text-center mt-3">
            <b>Loading...</b>
          </h6>
        )}

        {this.state.mode === NOTIFICATION_MODES.BROWSE &&
          !this.state.schedules.length && (
            <h6 className="text-center mt-3">
              <b>No new schedules</b>
            </h6>
          )}
        {this.state.pageCount > 1 && (
          <div className="d-flex justify-content-center">
            <ReactPaginate
              previousLabel={"‹"}
              nextLabel={"›"}
              breakLabel={"..."}
              breakLinkClassName={"page-link"}
              breakClassName={"page-item"}
              pageCount={this.state.pageCount}
              initialPage={this.state.page - 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={page => this.setPage(page, "submitted")}
              containerClassName={"pagination mt-3 mb-0"}
              pageLinkClassName={"page-link"}
              pageClassName={"page-item"}
              activeClassName={"active"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
            />
            <br />
          </div>
        )}
      </>
    );
  }

  renderReleasedSchedules() {
    return (
      <>
        <div className="row mb-4">
          {this.state.released.filter(item => !item.statusChanged).length > 0 ?
            <div className="col-12 d-flex">
              <div className="form-check form-check-inline mr-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="select-all"
                  onChange={this.onReleasedSelectAll}
                  checked={this.state.selectedAllReleased}
                />
                <label className="form-check-label" htmlFor="select-all">
                  Select all
              </label>
              </div>
              <button
                className="btn btn-success btn-sm mr-2"
                onClick={() => {
                  this.approveSelectedSchedule("released");
                }}
                disabled={this.validateActionButtons("released")}
              >
                <i className="fas fa-check-double mr-1" />
              Approve
            </button>
              <button
                className="btn btn-danger btn-sm mr-2"
                onClick={() => {
                  this.rejectSelectedSchedule("released");
                }}
                disabled={this.validateActionButtons("released")}
              >
                <i className="fas fa-times mr-1" />
              Reject
            </button>
            </div>
            : null}
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered mb-0 text-nowrap">
            <tbody>
              <tr>
                <th>#</th>
                <th>Old Employee</th>
                <th>New Employee</th>
                <th>Dept</th>
                <th>Dates Scheduled</th>
                <th>Hrs</th>
                <th>Note</th>
                <th>Released</th>
                <th>Other Manager Approval</th>
                <th className="text-center">Action</th>
              </tr>
              {this.state.released.map((item, index) =>
                <ReleasedRow
                  userId={this.state.user.id}
                  key={index}
                  item={item}
                  index={index}
                  selectedAllReleased={this.state.selectedAllReleased}
                  onCheckboxChange={this.onCheckboxChange}
                  seeScheduleDetails={this.seeScheduleDetails}
                  approveSchedule={this.approveSchedule}
                  rejectSchedule={this.rejectSchedule}
                />
              )}
            </tbody>
          </table>
        </div>

        {this.state.mode === NOTIFICATION_MODES.FETCHING && (
          <h6 className="text-center mt-3">
            <b>Loading...</b>
          </h6>
        )}

        {this.state.mode === NOTIFICATION_MODES.BROWSE &&
          !this.state.released.length && (
            <h6 className="text-center mt-3">
              <b>No new released schedules</b>
            </h6>
          )}
        {this.state.releasedPageCount > 1 && (
          <div className="d-flex justify-content-center">
            <ReactPaginate
              previousLabel={"‹"}
              nextLabel={"›"}
              breakLabel={"..."}
              breakLinkClassName={"page-link"}
              breakClassName={"page-item"}
              pageCount={this.state.releasedPageCount}
              initialPage={this.state.releasePage - 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={page => this.setPage(page, "released")}
              containerClassName={"pagination mt-3 mb-0"}
              pageLinkClassName={"page-link"}
              pageClassName={"page-item"}
              activeClassName={"active"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
            />
            <br />
          </div>
        )}
      </>
    );
  }
}

Notifications.propTypes = {};

export default Notifications;

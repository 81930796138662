import Modal from "react-modal";
import React from "react";
import '../SchedulePrint/styles/style.css';
import PropTypes from "prop-types";
import customModalStyles from "./customStyle";
import NotificationSectionDropdownItem from "../Notification/NotificationSectionDropdownItem";
import NotificationSection from "../Notification/NotificationSection";
import NotificationSectionToggleItem from "../Notification/NotificationSectionToggleItem";

const SHIFT_ALERTS_BEFORE_MINUTES_OPTIONS = {
    5: '5 minutes',
    10: '10 minutes',
    15: '15 minutes',
    20: '20 minutes',
    30: '30 minutes',
    45: '45 minutes',
    60: '60 minutes',
    120: '120 minutes',
    180: '180 minutes',
};

class EventNotification extends React.Component {
    state = {
        eventNotification: {
            rules:{"enabled": true, "minsBefore": 30},
            viaEmail: 1,
            viaSms: 0,
            viaWeb: 0,
            viaPush: 0
        },
    };
    componentDidMount() {
        this.setState({
            eventNotification: this.props.eventNotification
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.eventNotification !== this.props.eventNotification) {
            this.setState({
                eventNotification: this.props.eventNotification
            })
        }
    }
    saveNotification = () =>{
        this.props.onSubmit(this.state.eventNotification);
    }

    deleteNotification = () =>{
        this.props.onDelete(this.state.eventNotification);
    }
    render() {
        const {
            eventNotification,
            isOpen,
            closeModal
        } = this.props;
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customModalStyles}
                contentLabel="Shift alarms"
                shouldCloseOnOverlayClick={false}
            >
                <NotificationSection id="shifts-section" title="Shift alarms">
                    <NotificationSectionDropdownItem
                        selected={this.state.eventNotification.rules.minsBefore}
                        options={SHIFT_ALERTS_BEFORE_MINUTES_OPTIONS}
                        onChange={(value)=>{
                            const eventNotification = this.state.eventNotification;
                            eventNotification.rules.minsBefore = value;
                            this.setState({
                                eventNotification
                            })
                        }}
                        name="Number of minutes before shift" description="Indicate when you'd like to be reminded of an upcoming shift, so you make it to work on time" />
                    <NotificationSectionToggleItem
                        active={this.state.eventNotification.viaEmail === 1}
                        onChange={(value)=>{
                            const eventNotification = this.state.eventNotification;
                            eventNotification.viaEmail = value ? 1 : 0;
                            this.setState({
                                eventNotification
                            })
                        }}
                        name="Via Email" />
                </NotificationSection>
                <div className="modal-footer">
                    <div className="pull-right">
                        <button onClick={this.saveNotification} className="btn btn-info btn-sm mr-2">

                            {eventNotification.scheduleId ? (
                                <>
                                    <i className="mr-1 fas fa-save"/>Update
                                </>
                            ) : (
                                <>
                                    <i className="mr-1 fas fa-save"/>Save
                                </>
                            )}
                        </button>
                        {eventNotification.scheduleId && (
                            <button onClick={this.deleteNotification} className="btn btn-danger btn-sm mr-2">
                                <i className="mr-1 fas fa-trash"/>Delete
                            </button>
                        )}
                        <button className="btn btn-warning btn-sm mr-2" onClick={closeModal}>Close</button>
                    </div>
                </div>

            </Modal>
        )
    }
}
EventNotification.defaultProps = {
    closeModal: () => {},
    eventNotification: {},
    isOpen: false,
    onSubmit: () => {},
    onDelete: () => {},
};
EventNotification.propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    eventNotification: PropTypes.object.isRequired
};
export default EventNotification

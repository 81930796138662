import BaseService from "./BaseService";

class EmployeeService extends BaseService {
  fetchEmployees(page = 1, limit, filters) {
    const queries = `${page ? `?page=${page}` : ""}${
      limit ? `&limit=${limit}` : ""
      }`;

    return this.apiGet({
      url: `/employees${queries}${filters}`
    });
  }

  fetchAllUsers(page, limit) {
    const queries = `${page ? `?page=${page}` : ""}${
      limit ? `&limit=${limit}` : ""
      }`;

    return this.apiGet({
      url: `/users/all${queries}`
    });
  }

  searchUsers(keyword, includeMe = false) {
    keyword = keyword !== "" ? keyword : "a";
    return this.apiGet({
      url: `/users/search/${keyword}${includeMe ? "?includeMe=true" : ""}`
    });
  }
  searchMyEmployees(keyword, withData = false, filters = {}) {
    keyword = keyword !== "" ? keyword : "";
    if (withData) {
      filters.withData = true;
    }
    return this.apiGet({
      url: `/users/my-employees/${keyword}`,
      params: filters
    });
  }
  getMyJobHistory() {
    return this.apiGet({
      url: "/users/my-job-history"
    });
  }

  fetchOneEmployee(userId) {
    return this.apiGet({
      url: `/employee/${userId}/profile`
    });
  }

  sendMessage(data) {
    const messageData = new FormData();
    Object.keys(data).forEach(field => {
      if (field === "attachment") {
        messageData.append("attachment", data[field], data[field].name);
      } else {
        messageData.append(field, data[field]);
      }
    });
    return this.api.post(`/messages`, messageData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  fetchPeopleFilters(getAllLocations, checkForMyDepartment, getZones) {
    return this.apiGet({
      url: `/employees/filters`,
      params: {
        get_all_locations: getAllLocations ? true : undefined,
        check_my_department: checkForMyDepartment ? true : undefined,
        get_zones: getZones ? true : undefined
      }
    });
  }
  fetchLocationManagers(locationId, search = null) {
    return this.apiGet({
      url: `/employees/location-managers/${locationId}${search ? `?search=${search}` : ''}`
    });
  }
  fetchUserLocationFilters() {
    return this.apiGet({
      url: `/employees/user-locations-filters`
    });
  }
  shareEmployee(location, id, startDate, endDate, managers) {
    return this.apiPost({
      url: `/employee/${id}/share`,
      data: {
        location: parseInt(location, 10),
        startDate,
        endDate,
        managers
      }
    });
  }
  getShareEmployee(id) {
    return this.apiGet({
      url: `/employee/${id}/share`
    });
  }
  updateShareEmployee(location, id, startDate, endDate, managers) {
    return this.apiPut({
      url: `/employee/${id}/share`,
      data: {
        location: parseInt(location, 10),
        startDate,
        endDate,
        managers
      }
    });
  }
  deleteShareEmployee(id) {
    return this.apiDelete({
      url: `/employee/${id}/share`
    });
  }
  approveShare(data) {
    return this.apiPost({
      url: `/employee/share/approve`,
      data
    });
  }
  rejectShare(data) {
    return this.apiPost({
      url: `/employee/share/reject`,
      data
    });
  }

  updateProfilePicture(pictureData) {
    const messageData = new FormData();
    messageData.append("pic", pictureData);
    return this.api.post(`/employee/profilePic`, messageData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
  updateShowMyScheduleUnderMyEmployees(
    id,
    showMyScheduleUnderMyEmployees = false
  ) {
    return this.apiPut({
      url: `/employee/${id}/showMyScheduleUnderMyEmployees`,
      data: {
        showMyScheduleUnderMyEmployees
      }
    });
  }
}

export default new EmployeeService();

import React from "react";
import PropTypes from "prop-types";
import StringHelper from "../../helpers/StringHelper";

class ProfileDetails extends React.Component {

  render() {
    const {
      jobTitle,
      code,
      companyName,
      workEmailAddress,
      personalEmailAddress,
      workPhoneNumber,
      homePhone,
      departmentDescription,
      supervisorLastName,
      supervisorFirstName,
      payGroupDescription,
      locationDescription,
      lastHireDate,
      status,
      cardHeader
    } = this.props;
    return (
      <div className="card">
        <div className="card-header">
          <h4>{cardHeader}</h4>
        </div>
        <div className="card-body">
          <p>
            <b>Employee Code: </b> {code || "N/A"}
          </p>
          <p>
            <b>Company: </b> {companyName || "N/A"}
          </p>
          <p>
            <b>Supervisor: </b> { `${supervisorFirstName} ${supervisorLastName}`|| "N/A"}
          </p>
          <p>
            <b>Employee Status: </b> {StringHelper.statusFormat(status) || "N/A"}
          </p>
          <p>
            <b>Last Hired: </b> {StringHelper.dateFormat(lastHireDate) || "N/A"}
          </p>
          <p>
            <b>Pay Group: </b> {payGroupDescription || "N/A"}
          </p>
          <p>
            <b>Job Title: </b> {jobTitle || "N/A"}
          </p>
          <p>
            <b>Location: </b> {locationDescription || "N/A"}
          </p>
          <p>
            <b>Org Level 4 (Dept): </b> {departmentDescription || "N/A"}
          </p>
          <p>
            <b>Work e-mail: </b> {workEmailAddress ? (<a rel="noopener noreferrer" href={'mailto:'+ workEmailAddress} target="_blank"> {workEmailAddress} </a>) : "N/A"}
          </p>
          <p>
            <b>Personal e-mail: </b> {personalEmailAddress ? (<a rel="noopener noreferrer" href={'mailto:'+ personalEmailAddress} target="_blank"> {personalEmailAddress} </a>) : "N/A"}
          </p>
          <p>
            <b>Primary Home Phone: </b> { homePhone ? StringHelper.formatPhone(homePhone) : (StringHelper.formatPhone(workPhoneNumber) || "N/A")}
          </p>
        </div>
      </div>
    )
  }
}
ProfileDetails.defaultProps = {
    jobTitle: '',
    code: '',
    companyName: '',
    workEmailAddress: '',
    personalEmailAddress:'',
    workPhoneNumber:'',
    homePhone:'',
    departmentDescription:'',
    supervisorLastName:'',
    supervisorFirstName:'',
    payGroupDescription:'',
    locationDescription:'',
    lastHireDate:'',
    status:'',
};

ProfileDetails.propTypes = {
    jobTitle: PropTypes.string,
    code: PropTypes.string,
    companyName: PropTypes.string,
    workEmailAddress: PropTypes.string,
    personalEmailAddress: PropTypes.string,
    workPhoneNumber:PropTypes.string,
    homePhone:PropTypes.string,
    departmentDescription:PropTypes.string,
    supervisorLastName:PropTypes.string,
    supervisorFirstName:PropTypes.string,
    payGroupDescription:PropTypes.string,
    locationDescription:PropTypes.string,
    lastHireDate:PropTypes.string,
    status:PropTypes.string,
};

export default ProfileDetails;

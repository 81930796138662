import {Toast} from "../../libs/scheduler/scheduler.module";

export default class ErrorToast {
    constructor(html) {
        let toast = new Toast({
            html,
            timeout: 5000
        });
        if(toast.element) {
            toast.element.classList.add('toast-error');
        }
        toast.show();
    }
}

import axios from "axios";
import StorageManager from "../helpers/StorageManager";

class BaseService {
  constructor() {
    this.token = StorageManager.get("token");
    this.api = axios.create({
      baseURL: process.env.REACT_APP_ENV === "local"
          ? `http://localhost:${process.env.REACT_APP_API_PORT}`
          : process.env.REACT_APP_API_GATEWAY_ENDPOINT,
      headers: {
        Authorization: `Bearer ${StorageManager.get("token")}`,
      },
    });
    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Do something with response error
        if (error.response && error.response.status === 401) {
          StorageManager.clearOne("token");
          window.location.href = "/";
        }
        return Promise.reject(error);
      }
    );
  }

  reloadAxios() {
    const options = {
      baseURL: process.env.REACT_APP_ENV === "local"
          ? `http://localhost:${process.env.REACT_APP_API_PORT}`
          : process.env.REACT_APP_API_GATEWAY_ENDPOINT,
    };
    const token = StorageManager.get("token");
    this.token = token;
    if (token) {
      options.headers = {
        Authorization: `Bearer ${StorageManager.get("token")}`,
      };
    }
    this.api = axios.create(options);
  }

  apiPost(options) {
    const token = StorageManager.get("token");
    if (token !== this.token || !token) {
      this.reloadAxios();
    }
    return this.api.post(options.url, options.data);
  }

  apiGet(options) {
    const token = StorageManager.get("token");
    if (token !== this.token || !token) {
      this.reloadAxios();
    }
    const { url, ...config } = options;
    return this.api.get(url, config);
  }

  apiDelete(options) {
    const token = StorageManager.get("token");
    if (token !== this.token || !token) {
      this.reloadAxios();
    }
    return this.api.delete(options.url);
  }

  apiPut(options) {
    const token = StorageManager.get("token");
    if (token !== this.token || !token) {
      this.reloadAxios();
    }
    return this.api.put(options.url, options.data);
  }

  assignParamsToUrl(path, params) {
    let paramsPath = path;
    for (let param of path.match(/\{\w+\}/gm)) {
      const key = param.match(/\w+/)[0];
      paramsPath = paramsPath.replace(/\{\w+\}/, params[key]);
    }
    return paramsPath;
  }
}

export default BaseService;

import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownItem,
  Dropdown,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import moment from "moment";
import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";

// Components
import HorizontalMenu from "../HorizontalMenu";

// Services11
import userService from "../../../services/UserService";

// Styles
import "./assets/styles/Navbar.css";
import Tooltip from "../Tooltip";
import StringHelper from "../../../helpers/StringHelper";
import StorageManager from "../../../helpers/StorageManager";
// Images
// import logo from "../../../general/assets/images/logo-symbol-color.png";
import logo from "../../../general/assets/images/ArconLogo.svg";
import Avatar from "../Avatar";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

class Navbar extends Component {
  constructor(props) {
    super(props);
    const { user, notifications, messages } = props;
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      menuOpen: false,
      user,
      notifications,
      messages,
      readNotifications: false
    };
  }

  toggle() {
    this.setState(
      {
        dropdownOpen: !this.state.dropdownOpen
      },
      () => {
        if (
          !this.state.readNotifications &&
          this.state.notifications.filter(notification => !notification.isRead)
            .length
        ) {
          userService.readNotifications(this.state.user.id);
          this.setState({
            readNotifications: true
          });
        } else if (this.state.readNotifications) {
          this.setState({
            readNotifications: false,
            notifications: this.state.notifications.map(notification => ({
              ...notification,
              isRead: 1
            }))
          });
        }
      }
    );
  }

  async connect() {
    /*this.socket = new WebSocket(
      `${process.env.REACT_APP_API_GATEWAY_SOCKET_ENDPOINT}?token=${sessionStorage.getItem("token")}`
    );
    this.socket.addEventListener("message", message => {
      const notification = {
        id: `new-${this.state.notifications.length}`,
        isRead: null,
        body: message.data,
      };
      this.setState({
        notifications: (message.data && [notification, ...this.state.notifications]) || [],
      });
    });*/
    this.firebaseApp = firebase.initializeApp(firebaseConfig);
    if (process.env.REACT_APP_ANALYTICS_ENABLE === window.location.hostname) {
      this.firebaseApp.analytics();
    }
    let messaging = null;
    if (firebase.messaging.isSupported()) {
      messaging = this.firebaseApp.messaging();
      messaging.usePublicVapidKey(
        // Project Settings => Cloud Messaging => Web Push certificates
        "BMl6ez3AIVBypr27XWpUEeesu8mwZtXfhlkD6T3fEya4bbnFu7AALtxpA-838PVG6lLGvK454oAywKRBMOGVUcY"
      );
      messaging
        .getToken()
        .then(token => {
          userService
            .registerFirebaseToken({
              token,
              platform: "web"
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then(token => {
            userService
              .registerFirebaseToken({
                token,
                platform: "web"
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          });
      });

      messaging.onMessage(this.processMessage);
      navigator.serviceWorker.addEventListener("message", this.processMessage);
    }
  }

  processMessage = message => {
    const user = this.state.user;
    let data;
    if (message.data && message.data["firebase-messaging-msg-data"]) {
      data = message.data["firebase-messaging-msg-data"].data;
    } else if (message.data && message.data.data) {
      data = message.data.data;
    }
    if (data && parseInt(data.userId) === user.id) {
      if (data.type === "notification") {
        const notification = {
          id: parseInt(data.id),
          isRead: parseInt(data.isRead),
          body: data.body,
          object: data.object,
          type: data.notType
        };
        let notifications = this.state.notifications;
        const ind = notifications.findIndex(n => n.id === notification.id);
        if (ind >= 0) {
          notifications[ind] = notification;
        } else {
          notifications = [notification, ...notifications];
        }
        this.setState({
          notifications
        });
      } else if (data.type === "message") {
        const message = {
          id: parseInt(data.id),
          isRead: parseInt(data.isRead),
          body: data.body,
          createdAt: data.createdAt,
          firstName: data.firstName,
          groupName: data.groupName,
          isGroup: parseInt(data.isGroup),
          lastName: data.lastName,
          receiverFirstName: data.receiverFirstName,
          receiverId: parseInt(data.receiverId),
          receiverLastName: data.receiverLastName,
          replyOf: parseInt(data.replyOf),
          sender: parseInt(data.sender),
          userId: parseInt(data.userId),
          users: JSON.parse(data.users),
          you: data.you
        };

        let messages = this.state.messages;
        const ind = messages.findIndex(m => m.replyOf === message.replyOf);
        if (ind >= 0) {
          messages[ind] = message;
        } else {
          messages = [message, ...messages];
        }
        this.setState({
          messages
        });
      }
    }
  };

  toggleSidebar = () => {
    const menu = document.getElementsByClassName("horizontal-mainwrapper")[0];

    if (menu.classList.contains("active")) menu.classList.remove("active");
    else menu.classList.add("active");
  };

  onCloseSidebar = () => {
    const menu = document.getElementsByClassName("horizontal-mainwrapper")[0];
    menu.classList.remove("active");
  };

  exitImpersonation = async () => {
    try {
      const {
        data: { token }
      } = await userService.exitImpersonation();
      StorageManager.set("token", token);
      window.location = "/app/people";
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    this.connect();
  }

  componentDidUpdate() {
    if (
      !this.state.inMessages &&
      this.props.location.pathname.indexOf("/app/messages") !== -1
    )
      this.setState({ inMessages: true });
  }

  render() {
    const { user, menuOpen, inMessages } = this.state;

    return (
      <React.Fragment>
        <nav
          className="navbar navbar-expand-lg main-navbar"
          id="headerMenuCollapse"
        >
          <div className="container">
            <span
              id="horizontal-navtoggle"
              onClick={this.toggleSidebar}
              className="animated-arrow mr-1"
            >
              <span />
            </span>
            <a className="header-brand mr-auto" href="/">
              <img
                src={logo}
                className="navbar-brand header-brand-img"
                alt="Logo"
              />
            </a>

            <ul className="navbar-nav navbar-right">
              {user.previous && (
                <li className="d-block">
                  <button
                    style={{ marginTop: 6 }}
                    className="btn btn-danger mr-2"
                    onClick={this.exitImpersonation}
                  >
                    <i className="fas fa-user-secret" />
                    <span style={{ marginLeft: 3 }}>Exit Proxy</span>
                  </button>
                </li>
              )}
              <li className="d-block d-md-none">
                <NavLink
                  to="/app/messages"
                  exact
                  className="nav-link nav-link-lg pointer"
                >
                  <i className="far fa-envelope" />
                  {!inMessages &&
                    !this.state.dropdownOpen &&
                    !!this.state.messages.filter(
                      message =>
                        message.isRead === 0 &&
                        message.users
                          .map(u => u.id)
                          .includes(this.state.user.id)
                    ).length && <span className="pulse bg-danger" />}
                </NavLink>
              </li>
              <li className="d-block d-md-none">
                <NavLink
                  to="/app/notifications/all"
                  exact
                  className="nav-link nav-link-lg pointer"
                >
                  <i className="far fa-bell" />
                  {!this.state.dropdownOpen &&
                    !!this.state.notifications.filter(
                      notification => !notification.isRead
                    ).length && <span className="pulse bg-danger" />}
                </NavLink>
              </li>

              <UncontrolledDropdown
                className="d-none d-md-block"
                setActiveFromChild
              >
                <DropdownToggle
                  tag="li"
                  className="nav-link nav-link-lg cursor-pointer"
                >
                  <i className="far fa-envelope" />
                  {!inMessages &&
                    !this.state.dropdownOpen &&
                    !!this.state.messages.filter(
                      message =>
                        message.isRead === 0 &&
                        message.users
                          .map(u => u.id)
                          .includes(this.state.user.id)
                    ).length && <span className="pulse bg-danger" />}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-list" right>
                  <DropdownItem header>
                    Messages
                    <div className="float-right">
                      <Link to="/app/messages">View all</Link>
                    </div>
                  </DropdownItem>

                  {this.state.messages.map(message => {
                    return (
                      <DropdownItem
                        key={message.id}
                        className={
                          !inMessages && !message.isRead
                            ? "new-notification"
                            : ""
                        }
                      >
                        <Link to={`/app/messages/${message.replyOf}`}>
                          <React.Fragment>
                            <div className="dropdownmsg d-flex mt-0">
                              <div className="media-left">
                                <Tooltip
                                  placement="top"
                                  text={`${message.groupName}`}
                                >
                                  <Avatar item={message} />
                                </Tooltip>
                              </div>
                              <div className="media-body">
                                <div className="media-heading text-truncate">
                                  {!message.isRead ? (
                                    <b>
                                      {StringHelper.textTruncate(
                                        message.groupName,
                                        30
                                      )}
                                    </b>
                                  ) : (
                                      <span>
                                        {StringHelper.textTruncate(
                                          message.groupName,
                                          30
                                        )}
                                      </span>
                                    )}
                                </div>
                                <p className="msg">
                                  {message.you} {message.body}
                                </p>
                                <small className="text-muted">
                                  <i className="far fa-clock mr-1" />
                                  <time
                                    className="hidden-sm-down"
                                    dateTime="2017"
                                  >
                                    {moment(message.createdAt).fromNow()}
                                  </time>
                                </small>
                              </div>
                            </div>
                          </React.Fragment>
                        </Link>
                      </DropdownItem>
                    );
                  })}

                  {!this.state.messages.length && (
                    <DropdownItem>
                      <div className="dropdown-item-desc ml-0">
                        <b>No new messages</b>
                      </div>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>

              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
                className="d-none d-md-block"
              >
                <DropdownToggle
                  tag="li"
                  className="nav-link nav-link-lg cursor-pointer"
                >
                  <i className="far fa-bell" />
                  {!this.state.dropdownOpen &&
                    !!this.state.notifications.filter(
                      notification => !notification.isRead
                    ).length && <span className="pulse bg-danger" />}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-list" right>
                  <DropdownItem tag="div" header>
                    Notifications
                    {this.state.user.role === "manager" && (
                      <div className="float-right">
                        <Link to="/app/notifications/all">View all</Link>
                      </div>
                    )}
                  </DropdownItem>

                  {this.state.notifications.map(notification => {
                    return (
                      <Link
                        key={notification.id}
                        className="notification"
                        style={{
                          padding: "15px",
                          color: notification.isRead ? "#333" : undefined,
                          width: "100%",
                          display: "flex",
                          flexFlow: "column nowrap",
                          textDecoration: "none"
                        }}
                        to={
                          notification.object === "leave" &&
                            notification.type !== "leave-delete"
                            ? `/app/leaves${
                            ["leave-submit", "leave-update"].includes(
                              notification.type
                            )
                              ? "/approve"
                              : ""
                            }`
                            : notification.object === "schedule" &&
                              ["release", "pick-up"].includes(notification.type)
                              ? "/app/notifications/coverage-notifications"
                              : `/app/notification/${notification.id}`
                        }
                      >
                        {!notification.isRead ? (
                          <b>{notification.body}</b>
                        ) : (
                            <span>{notification.body}</span>
                          )}
                        <span className="time text-muted">
                          <i
                            className="far fa-clock mr-1"
                            style={{ fontSize: "inherit", color: "inherit" }}
                          />
                          {moment(notification.createdAt).fromNow()}
                        </span>
                      </Link>
                      // </DropdownItem>
                    );
                  })}

                  {!this.state.notifications.length && (
                    <DropdownItem>
                      <div className="dropdown-item-desc ml-0">
                        <b>No new notifications</b>
                      </div>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>

              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="a" className="nav-link pt-2" caret>
                  <div className="d-sm-none d-lg-inline-block ml-2 mt-1">
                    {this.state.user.firstName} {this.state.user.lastName}
                  </div>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    href="/signout"
                    onClick={this.props.logout}
                    className="dropdown-item has-icon d-flex align-items-center"
                  >
                    <i className="fas fa-sign-out-alt" /> Sign out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          </div>
        </nav>
        <HorizontalMenu
          onClose={this.onCloseSidebar}
          isMobile={this.props.isMobile}
          isOpen={menuOpen}
          isManager={this.state.user.role}
          isSuperUser={this.state.user.isSuperUser}
          accessLevelId={this.state.user.accessLevelId}
          canViewClockIn={this.props.canViewClockIn}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Navbar);

import zipcelx from 'zipcelx';

class ExcelExporter {
    static get defaultConfig() {
        return {
            /**
             * An export file name
             * @config {String} filename
             * @default
             */
            filename : 'grid',

            /**
             * Defines how date in a cell will be formatted
             * @config {String} dateFormat
             * @default
             */
            dateFormat : 'YYYY-MM-DD',

            /**
             * Specifies a default column width if no width specified
             * @config {Number} defaultColumnWidth
             * @default
             */
            defaultColumnWidth : 100,

            /**
             * If true and the grid is grouped, shows the grouped value in the first column. True by default.
             * @config {Boolean} showGroupHeader
             * @default
             */
            showGroupHeader : true,

            /**
             * An array of columns configuration used to specify columns width, headers name, and column fields to get the data from.
             * 'field' config is required. If 'text' is missing, it will try to get it retrieved from the grid column or the 'field' config.
             * If 'width' is missing, it will try to get it retrieved from the grid column or {@link #config-defaultColumnWidth} config.
             * If no columns provided the config will be generated from the grid columns.
             *
             * For example:
             * ```javascript
             * columns : [
             *     'firstName', // field
             *     'age', // field
             *     { text : 'Starts', field : 'start', width : 140 },
             *     { text : 'Ends', field : 'finish', width : 140 }
             * ]
             * ```
             *
             * @config {String[]|Object[]} columns
             * @default
             */
            columns : []
        };
    }

    /**
     * The main export function to generate and download *.xlsx file
     * @param {Object} config A configuration for a specific export.
     * @param {Object[]} columns An array of columns configuration
     * @param {Object[]} rows An array of rows configuration
     * @param {String} [config.filename] A file name for a specific export. If omitted the value will be taken from the {@link #config-filename} config.
     * @param {String[]|Object[]} [config.columns] An array of columns configuration for a specific export. If omitted the value will be taken from the {@link #config-columns} config.
     */
    export(config = {}, columns = [], rows = []) {
        const filename = config.filename || this.filename;
        zipcelx({
            filename : filename,
            sheet    : {
                data : [columns].concat(rows),
                cols : columns
            }
        });
    }
}


export default ExcelExporter;

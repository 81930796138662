import React from "react";
import Modal from "react-modal";
import customModalStyles from "../../Modals/customStyle";
import PropTypes from "prop-types";
import moment from "moment";
import { SCHEDULE_MODES, TABS } from "../../../containers/Schedule/Constant";
import AsyncSelect from "react-select/lib/Async";
import { loadOptions } from "../helpers/AsyncSelect";
import Calendar from "react-calendar";
import { calculateEstimation, calculateMyEstimation } from "../helpers/SchedulerHelper";
import { getWeekConfig } from "../helpers/DateHelper";

class CopyModal extends React.Component {
  state = {
    copyDate: null,
    copyError: null
  };
  render() {
    const {
      isOpen,
      closeModal,
      selectedEvents,
      isCopying,
      completePercentage,
      selectedResources,
      activeTab,
      copyToResources,
      onCopyToResource,
      selectedDays,
      onDateChange,
      copyError,
      startCopyMonthlyEvent,
      startCopyEvents,
      mode,
      endCopy,
      user
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Copy Shifts"
        shouldCloseOnOverlayClick={false}
      >
        <div className="modal-header">
          <h4>
            Copy {selectedEvents.length} shift
            {selectedEvents.length > 1 ? "s" : ""} to
          </h4>
        </div>
        <div className="modal-body custom-height">
          {isCopying ? (
            <div>
              <div className="clearfix ">
                <span className="float-left">
                  {completePercentage < 100 ? (
                    <span className="text-warning">
                      {" "}
                      Copying {selectedEvents.length} shifts ....{" "}
                    </span>
                  ) : (
                      <span className="text-success"> Copying completed </span>
                    )}
                </span>
              </div>
              <div className="table-responsive">
                <table className="table table-striped table-bordered mb-0 text-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Employee</th>
                      <th>Event Type</th>
                      <th>Original Start Date</th>
                      <th>New Start Date</th>
                      <th>Original End Date</th>
                      <th>New End Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedEvents.map((item, index) => {
                      return (
                        <tr key={item.id + index}>
                          <td className="align-middle">{index + 1}</td>
                          <td className="align-middle">{item.resourceName}</td>
                          <td className="align-middle">{item.name}</td>
                          <td className="align-middle">
                            {moment(item.oldStartDate).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                          <td className="align-middle">
                            {moment(item.startDate).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                          <td className="align-middle">
                            {moment(item.oldEndDate).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                          <td className="align-middle">
                            {moment(item.endDate).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                          <td className="align-middle">
                            {item.copyStatus === 1 ? (
                              <span className="text-success">
                                Successfully copied
                              </span>
                            ) : item.copyStatus === 2 ? (
                              <span className="text-danger">
                                {item.copyMessage}
                              </span>
                            ) : (
                                  <span className="text-warning">Copying...</span>
                                )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
              <div>
                {selectedResources.length === 1 &&
                  activeTab === TABS.MY_EMPLOYEES && (
                    <div className="col-12 mb-3">
                      <div className="col-12 mb-3 text-center">
                        <span>
                          Please select one or more employees to copy to
                        <br />
                        </span>
                      </div>
                      <AsyncSelect
                        isMulti={true}
                        value={copyToResources}
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        onChange={onCopyToResource}
                      />
                    </div>
                  )}

                <div className="col-12 mb-3 text-center">
                  {Object.keys(selectedDays).length > 1 ? (
                    <span>
                      You are copying shifts for{" "}
                      {Object.keys(selectedDays).length} different days.
                      <br />
                    Please select a starting date.
                      <br />
                    </span>
                  ) : (
                      <span>
                        Please select a date
                        <br />
                      </span>
                    )}
                </div>

                <div className="col-12">
                  <Calendar
                    calendarType={getWeekConfig(user) === 'isoWeek' ? 'ISO 8601' : 'US'}
                    minDate={moment().toDate()}
                    className="mr-0"
                    locale={"en-US"}
                    id={"startCopyDate"}
                    onChange={onDateChange}
                  />
                </div>
              </div>
            )}
          {copyError && (
            <div className="errorsContainer mt-2 text-center text-danger">
              {copyError}
            </div>
          )}
        </div>
        <div className="modal-footer">
          {!isCopying && (
            <div className="pull-right">
              <button
                className="btn btn-primary mr-2"
                onClick={
                  mode === SCHEDULE_MODES.THIS_MONTH
                    ? startCopyMonthlyEvent
                    : startCopyEvents
                }
              >
                Copy
              </button>

              <button className="btn btn-light" onClick={closeModal}>
                Cancel
              </button>
            </div>
          )}
          {endCopy && (
            <div className="pull-right">
              <button className="btn btn-light" onClick={closeModal}>
                Close
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
CopyModal.defaultProps = {
  closeModal: () => { },
  isOpen: false,
  selectedEvents: [],
  isCopying: false,
  completePercentage: 100,
  selectedResources: [],
  activeTab: "",
  copyToResources: [],
  onCopyToResource: () => { },
  selectedDays: [],
  onDateChange: () => { },
  copyError: null,
  startCopyMonthlyEvent: () => { },
  startCopyEvents: () => { }
};
CopyModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
export default CopyModal;

import React from "react";
import PropTypes from "prop-types";
import EmployeeService from "../../services/EmployeeService";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ProfileDetails from "../ProfileDetails/ProfileDetails";
import StringHelper from "../../helpers/StringHelper";
import customModalStyles from "./customStyle";

class ProfileModal extends React.Component {
  state = {
    error: null,
    success: null,
    userId: 0,
    firstName: "",
    lastName: "",
    jobTitle: "",
    code: "",
    companyName: "",
    workEmailAddress: "",
    personalEmailAddress: "",
    workPhoneNumber: "",
    homePhone: "",
    departmentDescription: "",
    empId: -1,
    isLoading: true,
    supervisorLastName: "",
    supervisorFirstName: "",
    payGroupDescription: "",
    locationDescription: "",
    lastHireDate: "",
    status: "",
    user: {},
    accessLevelId: 0,
    accessLevelIsUpdating: false,
    salaryHourly: "",
    hourlyRate: 0
  };
  toastDismiss = () => {
    this.setState({
      error: null
    });
  };
  fetchPeopleDetails() {
    const { employeeCode = -1 } = this.props;

    EmployeeService.fetchOneEmployee(employeeCode)
      .then(res => {
        if (res.data) {
          const profile = res.data;
          this.setState({
            ...profile,
            isLoading: false
          });
        } else {
          this.setState({
            error: (
              <div>
                <p>404, User not found!</p>
                <Link to="/app/people" className="btn btn-info">
                  Go back
                </Link>
              </div>
            ),
            isLoading: false
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          error: (
            <div>
              <p>404, User not found!</p>
              <Link to="/app/people" className="btn btn-info">
                Go back
              </Link>
            </div>
          ),
          isLoading: false
        });
      });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.employeeCode !== this.props.employeeCode &&
      this.props.employeeCode !== null &&
      this.props.employeeCode !== ""
    ) {
      this.fetchPeopleDetails();
    }
  }
  closeModal = () => {
    this.setState(
      {
        error: null,
        success: null,
        userId: 0,
        firstName: "",
        lastName: "",
        jobTitle: "",
        code: "",
        companyName: "",
        workEmailAddress: "",
        personalEmailAddress: "",
        workPhoneNumber: "",
        homePhone: "",
        departmentDescription: "",
        empId: -1,
        isLoading: true,
        supervisorLastName: "",
        supervisorFirstName: "",
        payGroupDescription: "",
        locationDescription: "",
        lastHireDate: "",
        status: "",
        user: {},
        accessLevelId: 0,
        accessLevelIsUpdating: false,
        salaryHourly: "",
        hourlyRate: 0
      },
      () => {
        this.props.closeModal();
      }
    );
  };
  render() {
    const {
      firstName,
      lastName,
      jobTitle,
      code,
      companyName,
      workEmailAddress,
      personalEmailAddress,
      workPhoneNumber,
      homePhone,
      departmentDescription,
      supervisorLastName,
      supervisorFirstName,
      payGroupDescription,
      locationDescription,
      lastHireDate,
      status,
      error,
      salaryHourly,
      hourlyRate,
      isLoading
    } = this.state;

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        style={customModalStyles}
        contentLabel="Employee Profile"
        shouldCloseOnOverlayClick={false}
        className="col-md-5 col-sm-12 card-employee-profile"
      >
        {isLoading ? (
          <div className="text-muted p-4">Getting employee profile....</div>
        ) : error ? (
          <div className="text-danger p-4">User Profile error!</div>
        ) : (
          <ProfileDetails
            cardHeader={`Employee: ${firstName} ${lastName}`}
            jobTitle={jobTitle}
            code={code}
            companyName={companyName}
            workEmailAddress={workEmailAddress}
            personalEmailAddress={personalEmailAddress}
            workPhoneNumber={workPhoneNumber}
            homePhone={homePhone}
            departmentDescription={departmentDescription}
            supervisorLastName={supervisorLastName}
            supervisorFirstName={supervisorFirstName}
            payGroupDescription={payGroupDescription}
            locationDescription={locationDescription}
            lastHireDate={lastHireDate}
            status={StringHelper.statusFormat(status)}
            salaryHourly={salaryHourly}
            hourlyRate={hourlyRate}
          />
        )}

        <div className="d-flex justify-content-end pr-3 pb-3">
          <button className="btn btn-light" onClick={this.closeModal}>
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
ProfileModal.defaultProps = {
  closeModal: () => {},
  isOpen: false
};
ProfileModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  employeeCode: PropTypes.string.isRequired
};
export default ProfileModal;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Toast from "../../components/Blocks/Toast/Toast";
import { TableRow } from "./TableRow";
import { CrudForm } from "./CrudForm";
import zipcelx from "zipcelx";
import { ExcelRenderer } from "react-excel-renderer";

import FileUpload from "../../components/FileUpload/FileUpload";

// import Pagination from "rc-pagination";
import ReactPaginate from "react-paginate";
import "rc-pagination/assets/index.css";

// import styles
import "./assets/styles/index.css";
import AsyncAlert from "../../helpers/AsyncAlert";
import PreviewImportTable from "./PreviewImportTable";

export default class CrudPage extends React.Component {
  state = {
    form: {
      mode: "create",
      data: {},
    },
    table: {
      data: [],
      currentPage: 1,
      totalPages: 1,
      total: 0,
    },
    page: 1,
    otherData: {
      data: [],
      isLoading: false,
      total: 0,
      selectedAll: false,
      regionSelect: [],
      selectedRegion: 0,
      success: null,
      error: null,
    },
    isDownloadingSample: false,
    isLoading: false,
    success: null,
    error: null,
    search: undefined,
  };

  constructor(props, context) {
    super(props, context);
    this.setMode = this.setMode.bind(this);
    this.reset = this.reset.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.saveData = this.saveData.bind(this);
    this.removeData = this.removeData.bind(this);
    this.setPage = this.setPage.bind(this);
    this.importData = this.importData.bind(this);
  }

  componentDidMount() {
    this.fetchData(1);
  }

  setMode(mode, data = {}) {
    if (this.getOtherData) {
      this.setState(
        {
          otherData: {
            data: [],
            isLoading: true,
            total: 0,
            selectedAll: false,
          },
        },
        () => {
          this.getOtherData(mode === "edit" && data.id ? data.id : 0);
        }
      );
    }
    this.setState({
      form: {
        mode,
        data,
      },
    });
  }

  setPage(page) {
    const { isLoading } = this.state;
    if (!isLoading) {
      this.setState(
        {
          page,
        },
        () => {
          this.fetchData(page);
        }
      );
    }
  }

  reset() {
    this.setMode("create");
  }

  fetchData(page) {
    this.setState({
      isLoading: true,
    });

    this.fetch(page, this.state.search)
      .then(({ data }) => {
        this.setState({
          table: data,
        });
      })
      .catch((err) => {
        this.setState({
          error: this.getError(err),
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }
  getError(err) {
    let error = err.message;
    if (err.response && err.response.data) {
      error = err.response.data;
    } else if (err.response && err.response.message) {
      error = err.response.message;
    }
    return error;
  }
  saveData() {
    const { data: formData } = this.state.form;
    let { data: tableData, currentPage, totalPages, total } = this.state.table;
    this.setState({
      isLoading: true,
    });
    if (typeof formData.id === "undefined") {
      this.create(formData)
        .then(() => {
          this.setState(
            {
              isLoading: false,
            },
            () => {
              this.reset();
              this.setPage(this.state.page);
            }
          );
        })
        .catch((err) => {
          this.setState({
            error: this.getError(err),
            isLoading: false,
          });
        });
    } else {
      this.update(formData)
        .then(({ data }) => {
          const index = tableData.findIndex((i) => i.id === data.id);
          if (index !== -1) {
            tableData[index] = data;
          }
          this.setState({
            isLoading: false,
            table: {
              data: tableData,
              currentPage,
              totalPages,
              total,
            },
          });
          this.reset();
          this.setPage(this.state.page);
        })
        .catch((err) => {
          this.setState({
            error: this.getError(err),
            isLoading: false,
          });
        });
    }
  }

  async removeData(data) {
    let { data: tableData, currentPage, totalPages, total } = this.state.table;
    const { data: formData } = this.state.form;
    const otherData = this.state.otherData;
    otherData.isLoading = true;

    const allow = await AsyncAlert.alert(
      `Delete ${this.name}`,
      `You are going to delete a ${this.name}. Do you want to continue?`
    );
    if (allow) {
      this.setState({
        isLoading: true,
        otherData,
      });
      this.delete(data.id)
        .then(({ message }) => {
          const index = tableData.findIndex((i) => i.id === data.id);
          if (index !== -1) {
            tableData.splice(index, 1);
            total -= 1;
          }
          otherData.isLoading = false;
          this.setState(
            {
              isLoading: false,
              otherData,
              table: {
                data: tableData,
                currentPage,
                totalPages,
                total,
              },
              success: message,
            },
            () => {
              if (this.getOtherData) {
                this.getOtherData(0);
              }
            }
          );
          if (data.id === formData.id) {
            this.reset();
          }
        })
        .catch((err) => {
          otherData.isLoading = false;
          this.setState({
            error: this.getError(err),
            isLoading: false,
            otherData,
          });
        });
    }
  }

  downloadSample = async () => {
    this.setState({ isDownloadingSample: true });

    try {
      await zipcelx({
        filename: `${this.name}_Sample`,
        sheet: { data: this.sampleData },
      });
    } catch (e) {
      console.log(e);
    }

    this.setState({ isDownloadingSample: false });
  };

  async importData() {
    return this.import(this.state.previewData.rows);
  }

  cancelImport = () => {
    this.setState({ previewData: null });
  };

  onImportSuccess = () => {
    this.fetchData(this.state.page);
    this.setState({ success: "The data has been imported successfully" });
  };

  previewImport = ([file]) => {
    if (file) {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          this.setState({
            previewData: {
              rows: resp.rows,
            },
          });
        }
      });
    }
  };

  setSearch = (e) => {
    e.preventDefault();

    const { value } = e.target;

    this.setState(
      {
        search: value.length === 0 ? undefined : value,
      },
      () => {
        value.length === 0 &&
          this.setState({ page: 1 }, () => this.fetchData(1));
      }
    );
  };

  submitSearch = (e) => {
    e && e.preventDefault();
    this.setState({ page: 1 }, () => this.fetchData(1));
  };

  render() {
    const {
      name,
      fields,
      enableImport,
      importSampleLink,
      ExtraComponent,
      downloadSample,
      searchPlaceHolder = "Search...",
    } = this;
    const {
      form: { data: formData, mode = "" },
      table: { data: tableData, currentPage, totalPages, total },
      isLoading,
      success,
      error,
      otherData,
      isDownloadingSample,
      previewData,
    } = this.state;
    const formHeader = `${mode.charAt(0).toUpperCase()}${mode.substr(
      1
    )} ${name.toLowerCase()}`;
    // const formHeader = `${mode.charAt(0).toUpperCase()}${mode.substr(
    //   1
    // )} ${name}`;
    const tableHeader = name.endsWith("y")
      ? `${name.substr(0, name.length - 1)}ies`
      : `${name}s`;

    const shownFields = fields.filter((f) => f.showOnTable !== false);
    const { user = {} } = this.props;
    return (
      <React.Fragment>
        {success && (
          <Toast
            status="success"
            title={`${tableHeader}.`}
            message={success}
            dismiss={() => this.setState({ success: null })}
          />
        )}
        {otherData.success && (
          <Toast
            status="success"
            title={"Locations"}
            message={otherData.success}
            dismiss={() => {
              otherData.success = null;
              this.setState({ otherData });
            }}
          />
        )}
        {error && (
          <Toast
            title={`${tableHeader}.`}
            message={error}
            dismiss={() => this.setState({ error: null })}
          />
        )}
        {otherData.error && (
          <Toast
            title={"Locations"}
            message={otherData.error}
            dismiss={() => {
              otherData.error = null;
              this.setState({ otherData });
            }}
          />
        )}

        <div className="row">
          {previewData && (
            <PreviewImportTable
              isOpen={true}
              name={name}
              importData={this.importData}
              closeModal={this.cancelImport}
              data={previewData}
              onSuccess={this.onImportSuccess}
            />
          )}
          <div className="card col-lg-4 mb-sm-4 mb-lg-0 col-12">
            <div className="card-header">
              <h4>{formHeader}</h4>
            </div>
            <div className="card-body ">
              <CrudForm
                user={user}
                mode={mode}
                formData={formData}
                fields={fields}
                onFormSubmit={async (values) => {
                  const { form } = this.state;
                  const newData = Object.assign({}, form.data, values);
                  this.setState(
                    {
                      form: {
                        ...form,
                        data: newData,
                      },
                    },
                    async () => {
                      await this.saveData();
                    }
                  );
                }}
                onCancel={this.reset}
              />
            </div>
          </div>

          <div className="col-lg-8 pl-lg-3 pl-0 col-12">
            <div className="card px-2 mb-0">
              <div className="card-header">
                <div className="float-left">
                  <h4>
                    {tableHeader} ({total}){" "}
                  </h4>
                </div>
                {enableImport === true && (
                  <div className="float-right">
                    {importSampleLink && (
                      <a
                        style={{
                          textDecoration: "none",
                          width: "30px",
                          marginRight: "17px",
                        }}
                        onClick={downloadSample}
                        href="#"
                      >
                        {isDownloadingSample
                          ? "Downloading..."
                          : "Download sample"}
                      </a>
                    )}
                    <FileUpload
                      onRef={(uploader) => (this.uploader = uploader)}
                      accept=".xlsx"
                      // accept="text/csv,.csv"
                      onChange={this.previewImport}
                      maxFileSize={10485760}
                      multiple={false}
                      labelClass="d-none"
                      buttonClassName="d-none"
                      withIcon={false}
                      withPreview={false}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-primary btn-with-icon btn-import"
                      onClick={() =>
                        this.uploader && this.uploader.triggerFileUpload()
                      }
                    >
                      <i className="fas fa-upload" /> Import
                    </button>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div className="float-right">
                  <form onSubmit={this.submitSearch}>
                    <div className="input-group">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        style={{ width: "250px" }}
                        onChange={this.setSearch}
                        placeholder={searchPlaceHolder}
                      />
                      <div className="input-group-btn">
                        <button className="btn btn-info" type="submit">
                          <i className="fas fa-search" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped table-bordered mb-0 text-nowrap">
                  <thead>
                    <tr>
                      <th>Actions</th>
                      {shownFields.map(({ label }, i) => (
                        <th className="text-center" key={i}>
                          {label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      tableData.map(({ id, ...otherData }, index) => (
                        <TableRow
                          key={index}
                          rowData={{ id, ...otherData }}
                          fields={fields}
                          actions={this.actions || ["U", "D"]}
                          onEdit={() =>
                            this.setMode("edit", { id, ...otherData })
                          }
                          onDuplicate={() => this.setMode("create", otherData)}
                          onDelete={() => this.removeData({ id, ...otherData })}
                        />
                      ))}
                    {!isLoading && tableData.length === 0 && (
                      <tr>
                        <td colSpan="100%" align="center">
                          No data
                        </td>
                      </tr>
                    )}
                    {isLoading && (
                      <tr>
                        <td colSpan="100%" align="center">
                          <div id="spinner" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center mb-3">
                {totalPages > 1 ? (
                  <ReactPaginate
                    previousLabel={"‹"}
                    nextLabel={"›"}
                    breakLabel={"..."}
                    breakLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    pageCount={totalPages}
                    initialPage={0}
                    forcePage={currentPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={({ selected }) => this.setPage(selected + 1)}
                    containerClassName={"pagination mt-3 mb-0"}
                    pageLinkClassName={"page-link"}
                    pageClassName={"page-item"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {ExtraComponent && (
          <ExtraComponent
            mode={mode}
            formData={formData}
            tableData={tableData}
            otherData={otherData}
          />
        )}
      </React.Fragment>
    );
  }
}

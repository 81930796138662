const START_DATE = "startDate";
const END_DATE = "endDate";

const SCHEDULE_STATUSES = {
    PENDING: "pending",
    APPROVED: "approved",
    REJECTED: "rejected",
    SUBMITTED: "submitted",
    RELEASED: "released",
    TO_REGULAR: "to-regular"
};

const SCHEDULE_MODES = {
    TODAY: "today",
    THIS_WEEK: "this_week",
    THIS_MONTH: "this_month",
    DATE_RANGE: "date_range",
};

const TABS = {
    MY_SHIFT: "my-shift",
    MY_EMPLOYEES: "my-employees",
    MY_DEPARTMENT: "my-department",
};
const MYSQL_DATETIME_FORMAT="YYYY-MM-DD HH:mm";
const SCHEDULE_MAPPED_EVENTS = {
    REGULAR: 'Regular',
    VACATION: 'Vacation',
    SICK: 'Sick',
    PERSONAL: 'Personal',
    OFFSITE: 'Offsite',
    LEAVE_OF_ABSENCE: 'Leave of Absence',
    UNAVAILABILITY: 'Unavailability',
    SCHEDULED_DAY_OFF: 'Scheduled Day Off',
    OTHER: 'Other'
};
const SCHEDULE_EVENTS = [
    'Regular',
    'Vacation',
    'Sick',
    'Personal',
    'Offsite',
    'Leave of Absence',
    'Unavailability',
    'Scheduled Day Off',
    'Other'
];
export {
    SCHEDULE_MODES,
    TABS,
    START_DATE,
    END_DATE,
    SCHEDULE_STATUSES,
    MYSQL_DATETIME_FORMAT,
    SCHEDULE_EVENTS,
    SCHEDULE_MAPPED_EVENTS
}

import React, { useState } from "react";
import moment from "moment";
import StringHelper from "../../helpers/StringHelper";
import { Link } from "react-router-dom";
import Duration from "../../helpers/Duration";

const ReleasedRow = ({
  item: itemData,
  index,
  selectedAllReleased,
  onCheckboxChange,
  seeScheduleDetails,
  approveSchedule,
  rejectSchedule,
  userId
}) => {
  const [open, setOpen] = useState(false);
  //item.note = !item.note ? '' : item.note;
  const { usersData, isMultiple, otherUser, mgResponse, ...item } = itemData;

  const canApprove = (!isMultiple || !mgResponse || (mgResponse && mgResponse[userId] && mgResponse[userId].status === 'pending')) && !item.statusChanged ? true : false;
  return (
    <tr key={item.id}>
      <td className="align-middle">
        {canApprove ? (
          <div>
            <input
              type="checkbox"
              value={item.selected || ""}
              checked={
                item.selected || selectedAllReleased
              }
              onChange={() => onCheckboxChange(index)}
            />
          </div>
        ) : null}
      </td>
      <td className="align-middle">{usersData ? (usersData.old ? `${usersData.old.firstName} ${usersData.old.lastName}` : '-') : item.name}</td>
      <td className="align-middle">{usersData ? (usersData.new ? `${usersData.new.firstName} ${usersData.new.lastName}` : '-') : '-'}</td>
      <td className="align-middle">{item.department}</td>
      <td className="align-middle">
        <span
          onClick={() =>
            seeScheduleDetails(index, "released")
          }
          className="badge badge-success cursor-pointer b-hover"
        >
          <i className="fas fa-clock" />{" "}
          {moment(item.startDate).format("MM-DD-YYYY hh:mm A")}
          <i className="fas fa-arrow-right mx-2" />
          <i className="fas fa-clock" />{" "}
          {moment(item.endDate).format("MM-DD-YYYY hh:mm A")}
        </span>
      </td>
      <td className="align-middle">
        <span className="badge badge-info">
          <i className="fas fa-clock mr-1" />
          {Duration.durationAsHoursAndMinsString(
            item.endDate,
            item.startDate
          )}
        </span>
      </td>
      <td className={`${item.note ? 'd-flex' : 'align-middle'}`} title={item.note}>
        <div className="text-wrap">
          {item.note ? open ? item.note : StringHelper.textTruncate(item.note, 40) : ''}
        </div>
        {item.note && item.note.length > 40 && (
          <button
            className="btn btn-light btn-sm ml-2"
            onClick={() => setOpen(o => !o)}
          >
            <i className={`fas fa-chevron-${open ? "up" : "down"}`} />
          </button>
        )}

      </td>
      <td className="align-middle">
        {moment(item.createdAt).fromNow()}
      </td>
      <td className="align-middle">
        {isMultiple && mgResponse ? Object.keys(mgResponse).map(k => {
          const mgr = mgResponse[k];
          return (
            <div key={k} className="mgr-response">
              <span className="mgr-name">{`${mgr.firstName} ${mgr.lastName}`}:</span>
              <span className="mgr-status">{StringHelper.formatLeaveStatus(mgr.status)}</span>
            </div>
          )
        }) : ''}
      </td>
      <td className="text-center align-middle">
        {canApprove ? (
          <React.Fragment>
            <button
              className="btn btn-success btn-sm mr-2"
              onClick={() => approveSchedule(index, "released")}
            >
              Approve
                      </button>
            <button
              className="btn btn-danger btn-sm mr-2"
              onClick={() => rejectSchedule(index, "released")}
            >
              Reject
                      </button>
            <Link
              to={`/app/people/${item.code}`}
              className="btn btn-info btn-sm"
            >
              Compose
              </Link>
          </React.Fragment>
        ) : null}
      </td>
    </tr>
  );
};
export default ReleasedRow;
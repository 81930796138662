import React from "react";
import { NavLink } from "react-router-dom";

// Styles
import "./assets/styles/Settings.css";
import SettingsService from "../../services/SettingsService";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import UserGroupForm from "../../components/Forms/UserGroupForm";
import GroupTableRow from "../../components/Tables/GroupTableRow"; // Import css
import GeneralSettings from "./Notifications";
import Budgets from "./Budgets";
import Locations from "./Locations";
import Departments from "./Departments";
import Jobs from "./Jobs";
import SecondaryJob from "./SecondaryJob";
import SecondaryLocation from "./SecondaryLocation";
import Employees from "./Employees";
import Companies from "./Companies";
import Loader from "../../components/Blocks/Loader";
import Regions from "./Regions";
import Sections from "./Sections";
import Forecasting from "./Forecasting";
import LocationTimezone from "./LocationTimezone";
import AccessLevel from "./AccessLevel";
import DepartmentView from "./DepartmentView";
import EarningCodes from "./EarningCodes";
import ClockIn from "./ClockIn";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      name: "",
      user,
      groups: {
        name: "",
        users: [],
        selectedUsers: [],
        editMode: false,
        editGroupId: 0,
        fromIsSaving: false,
      },
      userGroups: [],
      tree: [],
      checked: [],
      expanded: [],
      departments: [],
      selectedDepartments: [],
      totalDepartments: 0,
      filterDepartments: [],
      allLocations: [],
      jobs: [],
      selectedJobs: [],
      totalJobs: 0,
      filterJobs: [],
      locationError: null,
      usersError: null,
      isLoading: false,
      isGroupsLoading: true,
      accessLevels: [],
      editMode: false,
      formIsSaving: false,
      formGroupIsSaving: false,
      editLevelId: 0,
      mapLocations: {},
      mapJobs: {},
      mapDepartments: {},
      employees: [],
    };
  }
  onGroupFormSubmit = (fields) => {
    return new Promise((resolve) => {
      if (fields.users && fields.users.length > 1) {
        const groups = this.state.groups;
        groups.fromIsSaving = true;
        this.setState({ groups });
        if (this.state.groups.editMode) {
          if (this.state.groups.editGroupId !== 0) {
            groups.name = fields.groupName;
            this.setState({ groups });
            const data = {
              id: groups.editGroupId,
              name: fields.groupName,
              users: JSON.stringify(fields.users.map((u) => u.value)),
            };
            this.setState({
              usersError: null,
            });
            SettingsService.updateUserGroup(data)
              .then((response) => {
                const userGroups = this.state.userGroups;
                const ind = userGroups.findIndex((u) => u.id === data.id);
                if (ind >= 0) {
                  const group = userGroups[ind];
                  group.name = data.name;
                  group.users = fields.users;
                  userGroups[ind] = group;
                } else {
                  alert("404, User group Can't be updated!");
                }
                this.setState({ userGroups });
                this.resetGroupForm();
                resolve();
              })
              .catch((err) => {
                alert("An error occurred during updating user group");
                this.resetGroupForm();
                resolve();
              });
          } else {
            this.setState({
              usersError: "An error occurred while updating",
            });
            this.resetGroupForm();
            resolve();
          }
        } else {
          const data = {
            name: fields.groupName,
            users: JSON.stringify(fields.users.map((u) => u.value)),
          };
          this.setState({
            locationError: null,
          });
          SettingsService.createUserGroup(data)
            .then((response) => {
              const userGroups = this.state.userGroups;
              if (response.data && response.data.id) {
                userGroups.push({
                  id: response.data.id,
                  name: fields.groupName,
                  users: fields.users,
                });
              } else {
                alert("404, User group Can't be created!");
              }
              this.setState({ userGroups });
              this.resetGroupForm();
              resolve();
            })
            .catch((err) => {
              console.log(err);
              alert("An error occurred during creating user group");
              this.resetGroupForm();
              resolve();
            });
        }
      } else {
        this.setState({
          usersError: "Please select at least two users",
        });
        resolve();
      }
    });
  };

  resetGroupForm = () => {
    const groups = this.state.groups;
    groups.name = "";
    groups.editGroupId = 0;
    groups.selectedUsers = [];
    groups.editMode = false;
    groups.fromIsSaving = false;
    this.setState({
      groups,
      usersError: null,
    });
  };

  getUserGroups = () => {
    SettingsService.getUserGroups()
      .then((res) => {
        if (res.data) {
          this.setState({
            userGroups: res.data,
            isGroupsLoading: false,
          });
        } else {
          alert("404, Groups not founded!");
          this.setState({
            isGroupsLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("An error occurred during getting user groups");
        this.setState({
          isGroupsLoading: false,
        });
      });
  };

  deleteUserGroup = (group) => {
    confirmAlert({
      title: "Delete User Group",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = group.id;
            SettingsService.deleteUserGroup(id)
              .then((res) => {
                const userGroups = this.state.userGroups.filter(
                  (g) => g.id !== id
                );
                this.setState({ userGroups });
              })
              .catch((err) => {
                console.log(err);
                alert("An error occurred during deleting user group");
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  editUserGroups = (group) => {
    const groups = this.state.groups;
    groups.name = group.name;
    groups.selectedUsers = group.users;
    groups.editGroupId = group.id;
    groups.editMode = true;
    this.setState({
      groups,
    });
  };

  getLocationTree = () => {
    SettingsService.getTree()
      .then((res) => {
        if (res.data) {
          const jobs = res.data.jobs || [];
          const departments = res.data.departments || [];
          const tree = res.data.locations || [];
          const mapLocations = res.data.mapLocations || {};
          const mapDepartments = res.data.mapDepartments || {};
          const mapJobs = res.data.mapJobs || {};
          const allLocations = res.data.allLocations || [];
          const employees = res.data.employees || [];
          this.setState({
            tree,
            departments,
            filterDepartments: departments,
            allLocations,
            jobs,
            filterJobs: jobs,
            isLoading: false,
            mapLocations,
            mapDepartments,
            mapJobs,
            employees,
          });
        } else {
          alert("404, location tree not founded!");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("An error occurred during getting location tree");
        this.setState({
          isLoading: false,
        });
      });
  };
  componentDidMount() {
    const { user } = this.props;
    if (user && user.isSuperUser === 1) {
      this.getUserGroups();
      this.getLocationTree();
    }
  }

  onChangeUserTimezone = (timezone) => {
    const user = this.state.user;
    const { onChangeUserTimezone } = this.props;

    this.setState(
      {
        user: {
          ...user,
          ...{
            tzId: timezone.id,
            tzName: timezone.text,
            tzOffset: timezone.offset,
          },
        },
      },
      () => {
        onChangeUserTimezone(timezone);
      }
    );
  };

  onChangeUserWeekConf = (weekConf) => {
    const user = this.state.user;
    const { onChangeUserWeekConf } = this.props;
    this.setState(
      {
        user: {
          ...user,
          weekConf,
        },
      },
      () => {
        onChangeUserWeekConf(weekConf);
      }
    );
  };

  render() {
    const {
      isLoading,
      user,
      tree,
      departments,
      filterDepartments,
      allLocations,
      jobs,
      filterJobs,
      mapLocations,
      mapDepartments,
      mapJobs,
      employees,
    } = this.state;
    if (isLoading) {
      return (
        <div className="container content-area">
          <Loader isLoading={true} />
        </div>
      );
    }

    const isAdmin = user && user.isSuperUser === 1;
    const isManager = user && user.role === "manager";
    const isGM = user && user.departmentCode === "001";
    const hasAccessLevel = user && user.accessLevelId !== null;
    const canSeeDepLocComEmpJob =
      window.location.hostname !== "equinox.getarcon.com";
    return (
      <div className="container content-area">
        <Loader isLoading={isLoading} />
        <section className="section">
          <div className="section-body">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Settings</h4>
                  </div>
                  <div className="card-body display-flex flex-lg-row flex-column">
                    <ul
                      className="nav nav-pills flex-lg-column flex-md-row col-lg-2 col-sm-12"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings"
                          exact
                          className={"nav-link show"}
                          activeClassName="active"
                        >
                          General
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/access-level"
                          exact
                          className={
                            "nav-link show" + (isAdmin ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Access levels
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/user-groups"
                          className={
                            "nav-link show" + (isAdmin ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          User Groups
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/locations"
                          exact
                          className={
                            "nav-link show" +
                            (isAdmin && canSeeDepLocComEmpJob ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Locations
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/secondary-locations"
                          exact
                          className={
                            "nav-link show" + (isAdmin ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Secondary Location
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/regions"
                          exact
                          className={
                            "nav-link show" + (hasAccessLevel ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Regions
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/sections"
                          exact
                          className={
                            "nav-link show" + (isAdmin ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Zones
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/departments"
                          exact
                          className={
                            "nav-link show" +
                            (isAdmin && canSeeDepLocComEmpJob ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Departments
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/companies"
                          exact
                          className={
                            "nav-link show" +
                            (isAdmin && canSeeDepLocComEmpJob ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Companies
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/jobs"
                          exact
                          className={
                            "nav-link show" +
                            (isAdmin && canSeeDepLocComEmpJob ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Jobs
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/secondary-jobs"
                          exact
                          className={
                            "nav-link show" + (isAdmin ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Secondary Job
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/forecasting"
                          exact
                          className={
                            "nav-link show" + (isAdmin ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Forecasting
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/earning-codes"
                          exact
                          className={
                            "nav-link show" + (isGM || isAdmin ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Earning Codes
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/clock-in"
                          exact
                          className={
                            "nav-link show" + (isGM || isAdmin ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Clock in
                        </NavLink>
                      </li>
                      {/*<li className="nav-item">
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/department-view"
                          exact
                          className={
                            "nav-link show" + ((isGM || isAdmin) ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Department View
                        </NavLink>
                      </li>
                      {/*<li className="nav-item">
                        <NavLink
                          to="/app/settings/employees"
                          exact
                          className={
                            "nav-link show" + (isAdmin && canSeeDepLocComEmpJob ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Employees
                        </NavLink>
                      </li>*/}
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/budgets"
                          exact
                          className={
                            "nav-link show" + (isAdmin ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Budgets
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/app/settings/location-timezone"
                          exact
                          className={
                            "nav-link show" + (hasAccessLevel ? "" : " d-none")
                          }
                          activeClassName="active"
                        >
                          Location Timezone
                        </NavLink>
                      </li>
                    </ul>

                    {this.props.location.pathname === "/app/settings" && (
                      <div
                        className="tab-content col-lg-10 col-12 px-3"
                        id="myTabContent"
                      >
                        <div
                          className="row fade active pt-sm-4 pt-lg-0 show"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <GeneralSettings
                            isManager={isManager}
                            user={user}
                            onChangeUserTimezone={this.onChangeUserTimezone}
                            onChangeWeekConf={this.onChangeUserWeekConf}
                          />
                        </div>
                      </div>
                    )}
                    {this.props.location.pathname ===
                      "/app/settings/access-level" &&
                      isAdmin && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row fade active pt-sm-4 pt-lg-0 show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <AccessLevel
                              user={user}
                              tree={tree}
                              departments={departments}
                              filterDepartments={filterDepartments}
                              allLocations={allLocations}
                              jobs={jobs}
                              filterJobs={filterJobs}
                              mapLocations={mapLocations}
                              mapDepartments={mapDepartments}
                              mapJobs={mapJobs}
                              employees={employees}
                            />
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname ===
                      "/app/settings/locations" &&
                      isAdmin &&
                      canSeeDepLocComEmpJob && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Locations user={user} />
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname === "/app/settings/regions" &&
                      hasAccessLevel && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Regions user={user} />
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname ===
                      "/app/settings/sections" &&
                      hasAccessLevel && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Sections user={user} />
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname ===
                      "/app/settings/departments" &&
                      isAdmin &&
                      canSeeDepLocComEmpJob && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active  show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Departments user={user} />
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname ===
                      "/app/settings/companies" &&
                      isAdmin &&
                      canSeeDepLocComEmpJob && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active  show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Companies user={user} />
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname === "/app/settings/jobs" &&
                      isAdmin &&
                      canSeeDepLocComEmpJob && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active  show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Jobs user={user} />
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname ===
                      "/app/settings/secondary-jobs" &&
                      isAdmin && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active  show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <SecondaryJob user={user} />
                          </div>
                        </div>
                      )}
                    {this.props.location.pathname ===
                      "/app/settings/secondary-locations" &&
                      isAdmin && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active  show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <SecondaryLocation user={user} />
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname ===
                      "/app/settings/employees" &&
                      isAdmin &&
                      canSeeDepLocComEmpJob && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active  show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Employees
                              user={user}
                              locations={this.state.allLocations}
                              departments={this.state.departments}
                              jobs={this.state.jobs}
                            />
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname ===
                      "/app/settings/user-groups" &&
                      isAdmin && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div className="row">
                              <div className="card col-lg-4 mx-0 mx-lg-3 mb-2 col-12">
                                <div className="card-header">
                                  <h4>
                                    {this.state.editGroupMode
                                      ? "Update"
                                      : "Create new"}{" "}
                                    user group{" "}
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <UserGroupForm
                                    onFormSubmit={this.onGroupFormSubmit}
                                    onCancel={this.resetGroupForm}
                                    users={this.state.groups.selectedUsers}
                                    usersError={this.state.usersError}
                                    groupName={this.state.groups.name}
                                    editMode={this.state.groups.editMode}
                                    formIsSaving={this.state.formGroupIsSaving}
                                  />
                                </div>
                              </div>

                              <div className="card col-lg-7 col-12">
                                <div className="card-header">
                                  <h4>User groups (0) </h4>
                                </div>
                                <div className="table-responsive">
                                  <table className="table table-striped table-bordered mb-0 text-nowrap">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th className="text-center">Actions</th>
                                        <th className="text-center">Name</th>
                                        <th className="text-center">Users</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {!this.state.isGroupsLoading &&
                                        this.state.userGroups.map(
                                          (group, key) => (
                                            <GroupTableRow
                                              key={group.id}
                                              ind={key}
                                              id={group.id}
                                              onDelete={this.deleteUserGroup}
                                              onEdit={this.editUserGroups}
                                              name={group.name}
                                              users={group.users}
                                            />
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {this.props.location.pathname === "/app/settings/budgets" &&
                      isAdmin && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="row pt-sm-4 pt-lg-0 fade active  show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            {this.state.tree.length > 0 ? (
                              <Budgets
                                locations={this.state.allLocations}
                                departments={this.state.filterDepartments}
                              />
                            ) : (
                              <h6 className="text-center mt-3">
                                <b>Loading...</b>
                              </h6>
                            )}
                          </div>
                        </div>
                      )}
                    {this.props.location.pathname ===
                      "/app/settings/location-timezone" &&
                      hasAccessLevel && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="tab-pane fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <LocationTimezone user={user} />
                          </div>
                        </div>
                      )}
                    {this.props.location.pathname ===
                      "/app/settings/forecasting" &&
                      isAdmin && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="tab-pane fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Forecasting user={user} />
                          </div>
                        </div>
                      )}
                    {this.props.location.pathname ===
                      "/app/settings/department-view" &&
                      (isGM || isAdmin) && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="tab-pane fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <DepartmentView
                              user={user}
                              departments={(user.allMyDepartmentLocations || [])
                                .filter((d) => d.locationId === user.locationId)
                                .map((d) => {
                                  return {
                                    label: d.department,
                                    value: d.departmentId,
                                  };
                                })}
                            />
                          </div>
                        </div>
                      )}
                    {this.props.location.pathname ===
                      "/app/settings/earning-codes" &&
                      isAdmin && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="tab-pane fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <EarningCodes
                              user={user}
                              tree={tree}
                              departments={departments}
                              filterDepartments={filterDepartments}
                              allLocations={allLocations}
                              jobs={jobs}
                              filterJobs={filterJobs}
                              mapLocations={mapLocations}
                              mapDepartments={mapDepartments}
                              mapJobs={mapJobs}
                              employees={employees}
                            />
                          </div>
                        </div>
                      )}
                    {this.props.location.pathname ===
                      "/app/settings/clock-in" &&
                      isAdmin && (
                        <div
                          className="tab-content col-lg-10 col-12 px-3"
                          id="myTabContent"
                        >
                          <div
                            className="tab-pane fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <ClockIn
                              user={user}
                              tree={tree}
                              departments={departments}
                              filterDepartments={filterDepartments}
                              allLocations={allLocations}
                              jobs={jobs}
                              filterJobs={filterJobs}
                              mapLocations={mapLocations}
                              mapDepartments={mapDepartments}
                              mapJobs={mapJobs}
                              employees={employees}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Settings.propTypes = {};

export default Settings;

import React from "react";

// Services
import MessagingService from "../../services/MessagingService";
import UserService from "../../services/UserService";
import TextMessage from "./TextMessage";
import Upload from "../../components/Upload";
import EmojiPicker from "../../components/EmojiPicker";

// Styles
import "./assets/styles/MessagePreview.css";

class MessagePreview extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      messageDetails: {},
      texts: [],
      attachments: {},
      user,
      isLoading: true,
      isSending: false,
      aws_cdn: "https://dxv8p7y79jw20.cloudfront.net/",
      attachment: null,
      page: 1,
      calling: false,
      limit: 10,
      done: false,
      emojiPickerOpen: false,
      body: "",
      displayBody: ""
    };
  }

  fetchMessageDetails(resetToInitial = false) {
    const { id } = this.props.match.params;
    let { page, limit } = this.state;
    if (resetToInitial) {
      page = 1;
    }
    MessagingService.fetchOneMessage(id, { page, limit })
      .then(res => {
        if (res.data && res.data.threads) {
          const threads = res.data.threads;
          const mapAttachments = res.data.mapAttachments;
          let texts = [];
          let attachments = {};
          if (resetToInitial) {
            texts = [...threads];
            attachments = { ...mapAttachments };
          } else {
            texts = [...this.state.texts, ...threads];
            attachments = { ...this.state.attachments, ...mapAttachments };
          }
          this.setState({
            texts,
            attachments,
            done: res.data.threads.length < limit,
            calling: false,
            page
          });
        } else {
          alert("Something went wrong!");
          this.setState({
            done: true
          });
        }
      })
      .catch(err => console.log(err.response ? err.response.data : err));
  }

  fetch() {
    this.setState(({ page }) => ({ page: page + 1 }), this.fetchMessageDetails);
  }

  setNewMessage = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  submitNewText = e => {
    e.preventDefault();

    const { id } = this.props.match.params;
    const { body } = this.state;
    const { attachment } = this.state;

    if (!body || body.length === 0) return;

    const message = {
      id,
      body
    };
    if (attachment) {
      if (attachment.preview.type === "url") {
        message.attachmentUrl = attachment.url;
      } else {
        message.attachment = attachment;
      }
    }
    this.setState({
      isSending: true
    });

    MessagingService.setNewMessage(message)
      .then(res => {
        this.fetchMessageDetails(true);
        this.setState({
          isSending: false,
          attachment: null,
          body: ""
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isSending: false,
          attachment: null,
          body: ""
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState(
        {
          messageDetails: {},
          texts: [],
          done: false,
          page: 1,
          calling: false
        },
        this.fetchMessageDetails
      );
    }
  }

  async componentDidMount() {
    this.fetchMessageDetails();
  }

  saveVideoModal = attachment => {
    this.setState({
      attachment
    });
  };

  onUploadFilesChanged = files => {
    if (files[0]) {
      this.setState({
        attachment: files[0]
      });
    } else {
      this.setState({
        attachment: null
      });
    }
  };

  removeAttachment = () => {
    this.setState({
      attachment: null
    });
  };

  onScroll = e => {
    const {
      target: { scrollTop }
    } = e;

    const { done, calling } = this.state;

    !done && !calling && scrollTop <= 0 && this.fetch();
  };

  onSelectEmoji = ({ emoji }) => {
    this.setState(({ body }) => ({
      body: body + emoji
    }));
  };

  render() {
    const {
      texts,
      user,
      done,
      isSending,
      attachment,
      attachments,
      body
    } = this.state;

    return (
      <React.Fragment>
        <div className="chat-content" onScroll={this.onScroll}>
          {user.id &&
            texts.map((text, key) => (
              <TextMessage
                text={text}
                key={key}
                user={user}
                attachment={attachments[text.id]}
              />
            ))}

          {!done && (
            <div className="message-loader-indicator">
              <i className="fas fa-spinner fa-spin" />
            </div>
          )}
        </div>

        <form>
          <div className="input">
            <textarea
              name="body"
              placeholder="Type your message..."
              value={body}
              onChange={this.setNewMessage}
            />
            <EmojiPicker onSelectEmoji={this.onSelectEmoji} />
          </div>

          <div className="actions">
            <div>
              <Upload
                attachment={attachment}
                onUploadFilesChanged={this.onUploadFilesChanged}
                saveVideoModal={this.saveVideoModal}
                removeAttachment={this.removeAttachment}
              />
            </div>

            <button
              type="submit"
              onClick={this.submitNewText}
              disabled={isSending}
              className="btn btn-primary"
            >
              {isSending ? (
                <React.Fragment>
                  <i className="fas fa-spinner fa-spin mr-2" /> Sending...
                </React.Fragment>
              ) : (
                  <React.Fragment>
                    <i className="fas fa-paper-plane mr-2" /> Send
                  </React.Fragment>
                )}
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

MessagePreview.propTypes = {};

export default MessagePreview;

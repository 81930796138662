import React, {Component} from 'react';
import LeavesService from "../../services/LeavesService";
import ToBeApprovedList from "./ToBeApprovedList";
import moment from "moment";
import ScheduleService from "../../services/ScheduleService";
import {MYSQL_DATETIME_FORMAT} from "../../containers/Schedule/Constant";
import LeavesCalendar from "./LeavesCalendar";
import Tooltip from "../Blocks/Tooltip";
export default class ApproveLeaves extends Component {
    state = {
        leaves:[],
        startDate: null,
        endDate: null,
        events: [],
        row: {
            startDate: null,
            endDate: null,
        }
    };
    getApproveList = () =>{
        LeavesService.getApproveLeaves().then((response)=> {
            const { data } = response;
            let startDate = null;
            let endDate = null;
            const leaves = data.map(item => {
                item.startDate = moment(item.startDate);
                item.endDate = moment(item.endDate);
                item.createdAt = moment(item.createdAt);
                if(item.startDate < startDate || !startDate) {
                    startDate = item.startDate
                }
                if(item.endDate > endDate || !endDate) {
                    endDate = item.endDate
                }
                    return item;
            });
            this.setState({
                leaves,
                startDate,
                endDate
            }, this.getDayOffSchedules);
        })
    };
    getDayOffSchedules= () => {
        const {startDate, endDate} = this.state;
        if(startDate && endDate && moment(startDate).isValid() && moment(endDate).isValid()) {
            ScheduleService.fetchCustomSchedules(
                moment(this.state.startDate).format(MYSQL_DATETIME_FORMAT)+':00',
                moment(this.state.endDate).format(MYSQL_DATETIME_FORMAT)+':00',
                {events:JSON.stringify(['Scheduled Day Off'])}
            ).then((response) => {
                this.setState({
                    events: response.data.events
                });
                this.props.setLoading(false);
            })
        } else {
            this.props.setLoading(false);
        }

    };
    componentDidMount(): void {
        if(this.props.user) {
            this.getApproveList();
        }
    }
    rejectLeave = async (ids) => {
        this.props.setLoading(true);
        LeavesService.rejectLeaves(ids).then((response) => {
            let leaves = this.state.leaves;
            const data = response.data;
            leaves = leaves.map(l => {
                const leave = data.find(lv => lv.id === l.id);
                if(leave) {
                    leave.firstName = l.firstName;
                    leave.lastName = l.lastName;
                    leave.userId = l.userId;
                    leave.startDate = moment(leave.startDate);
                    leave.endDate = moment(leave.endDate);
                    leave.createdAt = moment(leave.createdAt);
                    return leave;
                }
                return l;
            });
            this.setState({
                leaves
            }, () => {
                this.props.setLoading(false);
            })
        });
    };
    approveLeave = async (ids) => {
        this.props.setLoading(true);
        LeavesService.approveLeaves(ids).then((response) => {
            let leaves = this.state.leaves;
            const data = response.data;
            leaves = leaves.map(l => {
                const leave = data.find(lv => lv.id === l.id);
                if(leave) {
                    leave.firstName = l.firstName;
                    leave.lastName = l.lastName;
                    leave.userId = l.userId;
                    leave.startDate = moment(leave.startDate);
                    leave.endDate = moment(leave.endDate);
                    leave.createdAt = moment(leave.createdAt);
                    return leave;
                }
                return l;
            });
            this.setState({
                leaves
            }, () => {
                this.getDayOffSchedules();
            });
        });
    };
    filterByDate = (row) => {
      this.setState({
          row: {
              startDate: null,
              endDate: null,
          }
      }, () => {
          this.setState({
              row: {
                  startDate: row.startDate,
                  endDate: row.endDate
              }
          });
      })
    };
    render() {
        const {leaves, startDate, endDate, events, row} = this.state;
        const {user} = this.props;
        const calendarStartDate = row.startDate ? row.startDate : startDate;;
        const calendarEndDate = row.endDate ? row.endDate : endDate;
        return (
            <div>
                <div className="row mt-4" style={{display:"flex", alignItems: "stretch"}}>
                    <ToBeApprovedList
                        user={user}
                        awaitingApproval={leaves}
                        showAllActions={leaves.filter(l => l.status === 'pending').length > 1}
                        name={true}
                        rejectLeave={this.rejectLeave}
                        approveLeave={this.approveLeave}
                        actions={(row) => {
                        return (
                            <div>
                                {row.status === 'pending' && (
                                    <>
                                        <Tooltip placement="top" text="Reject">
                                            <button className="btn btn-danger btn-sm mr-2" onClick={() => {
                                                this.rejectLeave([row.id]);
                                            }}>
                                                <i className="fas fa-undo" />
                                            </button>
                                        </Tooltip>
                                        <Tooltip placement="top" text="Approve">
                                            <button className="btn btn-success btn-sm mr-2" onClick={() => {this.approveLeave([row.id])}}>
                                            <i className="fas fa-check" />
                                            </button>
                                        </Tooltip>
                                    </>
                                )}

                                <Tooltip placement="top" text="See Who is scheduled on those dates">
                                    <button className="btn btn-default btn-sm" onClick={() => {this.filterByDate(row)}}>
                                        <i className="fas fa-eye" />
                                    </button>
                                </Tooltip>
                            </div>
                        )
                    }}
                    />
                </div>
                <div className="row mt-4" style={{display:"flex", alignItems: "stretch"}}>
                    {calendarStartDate && calendarEndDate && (
                            <LeavesCalendar startDate={calendarStartDate} showSeeAll={!!(row.startDate && row.endDate)} filterByDate={this.filterByDate} endDate={calendarEndDate} events={events} user={user}/>
                    )}

                </div>
            </div>
        );
    }
}


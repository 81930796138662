import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
  getEligibleEnd,
  getEligibleStart
} from "./BreakHelper";
import Select from "react-select";

function usePrev(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const Zone = ({
  index,
  item,
  dispatchSectionData,
  eventStartTime,
  eventEndTime,
}) => {
  const eventDate = moment(eventStartTime).format('YYYY-MM-DD');
  const { startTime, endTime, text } = item;
  const start = (moment.isMoment(startTime) ? startTime : moment(`${eventDate} ${startTime}`)).format('YYYY-MM-DD HH:mm');
  const end = (moment.isMoment(endTime) ? endTime : moment(`${eventDate} ${endTime}`)).format('YYYY-MM-DD HH:mm');
  const prevStart = usePrev(start);
  const prevEnd = usePrev(end);

  const [changed, setChanged] = useState(false);

  const [startOpt, setStartOpt] = useState([]);
  const [endOpt, setEndOpt] = useState([]);

  useEffect(() => {
    const iterator = getEligibleStart(eventStartTime, -5);
    const endLoopTime = getEligibleEnd(eventEndTime, 5);

    const newStartOpt = [];
    const newEndOpt = [];
    newStartOpt.push({ value: moment(iterator).format('YYYY-MM-DD HH:mm'), label: moment(iterator).format('hh:mm A') });
    while (iterator.isSameOrBefore(endLoopTime)) {
      iterator.add(5, "minutes");
      newStartOpt.push({ value: moment(iterator).format('YYYY-MM-DD HH:mm'), label: moment(iterator).format('hh:mm A') });
      newEndOpt.push({ value: moment(iterator).format('YYYY-MM-DD HH:mm'), label: moment(iterator).format('hh:mm A') });
    }
    newEndOpt.push({ value: moment(eventEndTime).format('YYYY-MM-DD HH:mm'), label: moment(eventEndTime).format('hh:mm A') });

    setStartOpt(newStartOpt);
    setEndOpt(newEndOpt);
  }, [eventStartTime, eventEndTime]);

  useEffect(() => {
    if (
      start &&
      end &&
      prevStart &&
      prevEnd &&
      (moment(start).format("HH:mm") !== moment(prevStart).format("HH:mm") ||
        moment(end).format("HH:mm") !== moment(prevEnd).format("HH:mm"))
    ) {
      setChanged(true);
      setTimeout(() => {
        setChanged(false);
      }, 700);
    }
  }, [start, end, prevEnd, prevStart]);

  const onChangeStart = ({value}) => {
    const newStartTime = moment(value);
    const newEndTime = moment(newStartTime).add(5, "minutes");
    const prevE = moment(prevEnd);
    if (
      newStartTime.isAfter(prevE) ||
      prevE.diff(newStartTime, "minutes") < 5 ||
      (prevE.isBefore(newStartTime) && 
      moment(newStartTime).diff(prevE, "minutes") < 5)
    ) {
      dispatchSectionData({
        type: "end",
        index,
        value: newEndTime.format('HH:mm')
      });
    }
    dispatchSectionData({
      type: "start",
      index,
      value: newStartTime.format('HH:mm')
    });
  };

  const onChangeEnd = ({ value }) => {
    const newEndTime = moment(value);
    const newStartTime = moment(newEndTime).add(-5, "minutes");
    const prevS = moment(prevStart);
    if (
      newEndTime.isBefore(prevS) ||
      newEndTime.diff(prevS, "minutes") < 5 ||
      (prevS.isBefore(newEndTime) &&
        newEndTime.diff(prevS, "minutes") < 5)
    ) {
      dispatchSectionData({
        type: "start",
        index,
        value: newStartTime.format('HH:mm')
      });
    }
    dispatchSectionData({
      type: "end",
      index,
      value: newEndTime.format('HH:mm')
    });
  };
  return (
    <div className={`${changed ? "changed" : ""}`}>
      <div>
        <span className="label">Zone</span>
        <div>{text}</div>
      </div>

      {start && (
        <div>
          <span className="label">Start Time</span>
          <Select
            isMulti={false}
            isClearable={false}
            value={{ value: moment(start).format('YYYY-MM-DD HH:mm'), label: moment(start).format('hh:mm A') }}
            onChange={onChangeStart}
            options={startOpt}
          />
          {/* <select
            disabled={startOpt.length === 0}
            onChange={onChangeStart}
            value={getOptionValue(start)}
          >
            {startOpt.length === 0 && (
              <option label="Break too long" />
            )}
            {startOpt.map((o, i) => (
              <option
                key={i}
                value={getOptionValue(o)}
                label={o.format("hh:mm A")}
              />
            ))}
          </select> */}
        </div>
      )}

      {end && (
        <div>
          <span className="label">End Time</span>
          <Select
            isMulti={false}
            isClearable={false}
            value={{value: moment(end).format('YYYY-MM-DD HH:mm'), label: moment(end).format('hh:mm A')}}
            onChange={onChangeEnd}
            options={endOpt}
          />
          {/* <select
            disabled={endOpt.length === 0}
            onChange={onChangeEnd}
            value={getOptionValue(end)}
          >
            {endOpt.length === 0 && (
              <option label="Break too long" />
            )}
            {endOpt.map((o, i) => (
              <option
                key={i}
                value={getOptionValue(o)}
                label={o.format("hh:mm A")}
              />
            ))}
          </select> */}
        </div>
      )}
    </div>
  );
};

export default Zone;

import React from "react";
export default class NotificationSectionItem extends React.Component {
    render () {
        const { name, description, children } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-9 col-sm-9 col-xs-12 align-middle">
                        <span className="lead-sm">{name}</span>
                        <p className="text-muted">{description}</p>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

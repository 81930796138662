import BaseService from "./BaseService";

class DashboardService extends BaseService {
  fetchDashboard(startDate, endDate) {
    const queries = `${startDate ? `?startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`;
    return this.apiGet({
      url: `/dashboard${queries}`,
    });
  }
}

export default new DashboardService();

import React, { Component } from "react";
import moment from "moment/moment";
import AsyncSelect from "react-select/lib/Async";
import Select from "react-select";
import CheckboxTree from "react-checkbox-tree";
import ToggleButton from "react-toggle-button";

import { DateRangePicker } from "react-dates";
import EmployeeService from "../../../services/EmployeeService";
import { getWeekStartDay } from "../../Schedule/helpers/DateHelper";
import { SCHEDULE_EVENTS } from "../../../containers/Schedule/Constant";

let timeout = null;
const loadOptions = (inputValue, callback) => {
  timeout && clearTimeout(timeout);

  timeout = setTimeout(async () => {
    const search = await EmployeeService.searchMyEmployees(
      inputValue,
      true
    );
    const data = prepareResults(search.data);
    return callback(data);
  }, 300);
};
const prepareResults = (results) => {
  const preparedResults = [];

  results &&
    results.forEach((result) => {
      preparedResults.push({
        value: result.id,
        label: result.name,
        ...result,
      });
    });

  return preparedResults;
};
export default class EmployeeRportFilterForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showTree: false,
      displayLocationTree: false,
      values: {
        startDate: moment(),
        endDate: moment().add(30, "days"),
        locations: [],
        jobs: [],
        departments: [],
        events: ['Regular'],
        sectionId: [],
        users: [],
        salaryHourly: ['H']
      },
      focusedInput: null,
      roles: [],
      locations: [],
      locationTree: [],
      departments: [],
      zones: [],
      checked: [],
      expanded: []
    };
  }

  filter = e => {
    e && e.preventDefault();

    this.props.filter({
      ...this.state.values,
      startDate: this.state.values.startDate.toISOString(),
      endDate: this.state.values.endDate.toISOString()
    });
  };

  onTypeChange = ({ value: action }) => {
    this.setState(({ values }) => ({
      values: { ...values, action }
    }));
  };

  onDateRangeFocusChange = focusedInput => {
    this.setState({
      focusedInput: !focusedInput ? null : focusedInput
    });
  };

  onDateRangeChange = ({ startDate, endDate }) => {
    const values = { ...this.state.values };
    if (startDate) {
      values.startDate = startDate;
      values.endDate = null;
    }
    if (endDate) values.endDate = endDate;
    this.setState({ values });
  };

  onUserChange = users => {
    this.setState(({ values }) => {
      return {
        values: { ...values, users: users.map(u => u.value) }
      };
    });
  };

  formatUsers = users => {
    return users.map(({ id: value, name: label }) => ({
      label,
      value
    }));
  };

  componentWillMount = async () => {
    try {
      EmployeeService.fetchPeopleFilters(false, true, true).then((response) => {
        this.setState({
          roles: response.data.jobs,
          locations: response.data.locations,
          locationTree: response.data.locationTree,
          displayLocationTree: response.data.locations.length > 1,
          departments: response.data.departments,
          zones: response.data.zones,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      values,
      focusedInput,
      roles,
      departments,
      zones,
      showTree,
      displayLocationTree,
      locationTree,
      checked,
      expanded,
      locations
    } = this.state;
    const { isLoading, user } = this.props;
    const eventNames = SCHEDULE_EVENTS.map(ev => {
      return {
        name: ev,
        value: ev
      }
    });
    const salaryHourlyOpt = [
      { value: 'H', name: 'Hourly' },
      { value: 'S', name: 'Salary' },
    ];
    const selectedEventNames = eventNames.filter(ev => values.events.includes(ev.value));
    const selectedHourlyRate = salaryHourlyOpt.filter(v => values.salaryHourly.includes(v.value));
    return (
      <form onSubmit={this.filter}>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          gap: '10px'
        }}>
          <div className="">
            <label
              className="col-form-label"
              style={{ fontSize: "13px" }}
            >
              Select Date:
          </label>
            <div>
              <DateRangePicker
                firstDayOfWeek={getWeekStartDay(user)}
                startDateId="startDate"
                endDateId="endDate"
                startDate={values.startDate}
                endDate={values.endDate}
                onDatesChange={this.onDateRangeChange}
                onFocusChange={this.onDateRangeFocusChange}
                focusedInput={focusedInput}
                isOutsideRange={() => {
                  return false;
                }}
                disabled={isLoading}
                small={true}
              />
            </div>
          </div>
          <div className="">
            <label
              className="col-form-label"
              style={{ fontSize: "13px" }}
            >
              Select Users:
          </label>
            <div>
              <AsyncSelect
                isMulti={true}
                isClearable={true}
                cacheOptions
                style={{ width: "300px" }}
                loadOptions={loadOptions}
                placeholder="Type to search for Users..."
                onChange={this.onUserChange}
              />
            </div>
          </div>
          <div className="">
            <label
              className="col-form-label"
              style={{ fontSize: "13px" }}
            >
              Salary/Hourly:
          </label>
            <div>
              <Select
                getOptionLabel={o => o.name}
                isMulti={true}
                name="salaryHourly"
                mode="input"
                onChange={val => {
                  this.setState(({ values }) => {
                    return {
                      values: { ...values, salaryHourly: val.map(v => v.value) }
                    };
                  });
                }}
                maxMenuHeight={200}
                options={salaryHourlyOpt}
                value={selectedHourlyRate}
                placeholder="Salary/Hourlys"
              />
            </div>
          </div>

        </div>
        {displayLocationTree && (
          <div className="d-flex align-items-center mb-3 mt-3">
            <span className="mr-3">
              Filter By region:
                  </span>

            <ToggleButton
              value={showTree}
              thumbStyle={{ borderRadius: 4 }}
              trackStyle={{
                borderRadius: 4
              }}
              colors={{
                activeThumb: {
                  base: "#fff"
                },
                active: {
                  base: "#61c08d"
                }
              }}
              onToggle={(value) => {
                this.setState({
                  showTree: !value,
                });
              }}
            />
          </div>
        )}
        <div style={{
          display: 'grid',
          gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
          gap: '10px'
        }}>
          {displayLocationTree && showTree && (
            <div
              style={{
                gridRowStart: 1,
                gridRowEnd: 3
              }}>

              <div
                className={`
                  ${displayLocationTree && showTree ? "min-height-200" : "hidden"}
                  `}
              >
                <CheckboxTree
                  nodes={locationTree}
                  checked={checked}
                  expanded={expanded}
                  onCheck={(checked) => {
                    this.setState(({ values }) => {
                      return {
                        checked,
                        values: { ...values, locations: checked }
                      };
                    });
                  }}
                  onExpand={(expanded) => {
                    this.setState({ expanded })
                  }}
                />
              </div>

            </div>
          )}
          {roles.length ? (
            <React.Fragment>
              <div
                className={`
                  ${
                  !displayLocationTree || (displayLocationTree && !showTree)
                    ? ""
                    : "hidden"
                  } mb-3`}
              >
                <label>Location:</label>
                <Select
                  getOptionLabel={o => o.name}
                  isMulti={true}
                  name="locations"
                  mode="input"
                  onChange={val => {
                    this.setState(({ values }) => {
                      return {
                        values: { ...values, locations: val.map(v => v.value) }
                      };
                    });
                  }}
                  maxMenuHeight={200}
                  options={locations}
                  placeholder="Locations"
                />
              </div>
            </React.Fragment>
          ) : null}
          {roles.length > 0 && (
            <div className="mb-3">
              <label>Jobs:</label>
              <Select
                menuPlacement={showTree ? "top" : "bottom"}
                maxMenuHeight={showTree ? 200 : 140}
                isMulti={true}
                getOptionLabel={o => o.name}
                name="roles"
                options={roles}
                onChange={val => {
                  this.setState(({ values }) => {
                    return {
                      values: { ...values, jobs: val.map(v => v.value) }
                    };
                  });
                }}
                placeholder="Roles"
              />
            </div>
          )}

          {departments.length > 0 && (
            <div className="mb-3">
              <label>Departments:</label>
              <Select
                menuPlacement="top"
                isMulti={true}
                maxMenuHeight={showTree ? 200 : 170}
                getOptionLabel={o => o.name}
                name="department"
                mode="input"
                options={departments}
                onChange={val => {
                  this.setState(({ values }) => {
                    return {
                      values: { ...values, departments: val.map(v => v.value) }
                    };
                  });
                }}
                placeholder="Departments"
              />
            </div>
          )}

          {zones.length > 0 && (
            <div className="mb-3">
              <label>Zones:</label>
              <Select
                menuPlacement={showTree ? "top" : "bottom"}
                maxMenuHeight={showTree ? 200 : 140}
                isMulti={true}
                getOptionLabel={o => o.name}
                name="roles"
                options={zones}
                onChange={val => {
                  this.setState(({ values }) => {
                    return {
                      values: { ...values, sectionId: val.map(v => v.value) }
                    };
                  });
                }}
                placeholder="Zones"
              />
            </div>
          )}
          {roles.length > 0 && (
            <div className="">
              <label>Events:</label>
              <Select
                menuPlacement="top"
                maxMenuHeight={200}
                isMulti={true}
                name="events"
                mode="input"
                getOptionLabel={o => o.name}
                value={selectedEventNames}
                onChange={val => {
                  this.setState(({ values }) => {
                    return {
                      values: { ...values, events: val.map(v => v.value) }
                    };
                  });
                }}
                options={eventNames}
                placeholder="Events"
              />
            </div>
          )}
        </div>
        <div className="form-group row">
          <div className="col-12 mt-3">
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary"
            >
              {isLoading ? "Generating..." : "Generate"}
            </button>
          </div>
        </div>
      </form>
    );
  }

  renderSelectOptions(options) {
    return options.map(({ name, label }) => (
      <option key={name} value={name}>
        {label}
      </option>
    ));
  }
}

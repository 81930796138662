const en = {
    timestamp: 'Timestamp',
    sunday: 'SUNDAY',
    monday: 'MONDAY',
    tuesday: 'TUESDAY',
    wednesday: 'WEDNESDAY',
    thursday: 'THURSDAY',
    friday: 'FRIDAY',
    saturday: 'SATURDAY',
    min: 'min',
    break: 'Break',
    breaks: 'Breaks',
    regular: 'Regular',
    vacation: 'Vacation',
    sick: 'Sick',
    personal: 'Personal',
    offsite: 'Offsite',
    'leave of absence':'Leave of Absence',
    other: 'Other',
    unavailability: 'Unavailability',
    'scheduled day off': 'Scheduled Day Off',
    notes: "Notes"
};
const es = {
    timestamp: 'Marca de tiempo',
    sunday: 'DOMINGO',
    monday: 'LUNES',
    tuesday: 'MARTES',
    wednesday: 'MIÉRCOLES',
    thursday: 'JUEVES',
    friday: 'VIERNES',
    saturday: 'SÁBADO',
    min: 'min',
    break: 'Pausa',
    breaks: 'Pausa',
    regular: 'Regular',
    vacation: 'Vacaciones',
    sick: 'Enfermo',
    personal: 'Personal',
    offsite: 'Fuera del sitio',
    'leave of absence':'Excedencia',
    other: 'Otro',
    unavailability: 'Indisponibilidad',
    'scheduled day off': 'Día libre programado',
    notes: "Notas"
};
const languages = {
    en,
    es
};
export {languages};

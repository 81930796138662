import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Blocks/Tooltip/Tooltip";

const GroupTableRow = props => {
  return (
    <tr>
      <td>
        <span className="text-muted">{(props.ind + 1 ).toString().length > 3 ? (props.ind + 1 ) : ('00'+ (props.ind + 1)).slice(-3)}</span>
      </td>
      <td className="align-middle text-center">
        <div>
          <Tooltip placement="top" text="Delete">
            <button className="btn btn-danger btn-sm mr-2" onClick={() => props.onDelete(props)}>
              <i className="fas fa-trash" />
            </button>
          </Tooltip>
          <Tooltip placement="top" text="Edit">
            <button className="btn btn-info btn-sm" onClick={() => props.onEdit(props)}>
              <i className="fas fa-pen" />
            </button>
          </Tooltip>
        </div>
      </td>
      <td className="text-nowrap align-middle">{props.name}</td>
      <td className="text-nowrap align-middle">{props.users.length}</td>
    </tr>
  );
};

GroupTableRow.defaultProps = {
  ind: -1,
  id: -1,
  name: "---",
  onDelete: () => alert("Provide onClick handler!!"),
  users: []
};

GroupTableRow.propTypes = {
  ind: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

export default GroupTableRow;

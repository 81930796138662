import CrudPage from "../../components/CrudPage";
import CrudService from "../../services/CrudService";
import EmployeeService from "../../services/EmployeeService";
import FilterService from "../../services/FilterService";
const { secondaryLocation } = require("./sampleData");
const secondaryLocationService = new CrudService("secondary-location");

let timeoutUser = null;
let timeoutLocation = null;
let timeoutManagers = null;
export default class SecondaryLocations extends CrudPage {
  name = "Secondary Location";
  fields = [
    {
      name: "id",
      label: "ID",
      type: "text",
      showOnForm: false,
      showOnTable: false
    },
    {
      name: "name",
      label: "Employee",
      type: "react-select",
      placeholder: "Type to search for an employee",
      menuIsOpen: false,
      closeMenuOnSelect: true,
      isMulti: false,
      loadOptions: (search, callback) => {
        timeoutUser && clearTimeout(timeoutUser);
        timeoutUser = setTimeout(async () => {
          EmployeeService.searchMyEmployees(search).then(employees => {
            callback(
              employees.data.map(({ name: label, id: value, ...other }) => ({
                label,
                value,
                ...other
              }))
            );
          });
        }, 300);
      },
      showOnTable: false,
      clearable: true
    },
    {
      name: "employee",
      label: "Employee",
      type: "text",
      showOnForm: false
    },
    {
      name: "employeeCode",
      label: "Employee Code",
      type: "text",
      showOnForm: false
    },
    { name: "code", label: "Code", type: "text", showOnForm: false },
    { name: "description", label: "Location", type: "text", showOnForm: false },
    { name: "supervisor", label: "Supervisor", type: "text", showOnForm: false },
    {
      name: "location",
      label: "Location",
      type: "react-select",
      placeholder: "Type to search for a location",
      menuIsOpen: false,
      closeMenuOnSelect: true,
      isMulti: false,
      loadOptions: (search, callback) => {
        timeoutLocation && clearTimeout(timeoutLocation);
        timeoutLocation = setTimeout(async () => {
          FilterService.getAllLocations({ search }).then(locations => {
            callback(
              locations.data.map(({ name: label, value }) => ({ label, value }))
            );
          });
        }, 300);
      },
      showOnTable: false,
      clearable: true
    },
    {
      name: "supervisors",
      label: "Supervisor",
      type: "react-select",
      placeholder: "Type to search for an manager",
      menuIsOpen: false,
      closeMenuOnSelect: true,
      isMulti: false,
      dependsOn: 'location',
      loadOptions: (search, callback, locationId) => {
        timeoutManagers && clearTimeout(timeoutManagers);
        timeoutManagers = setTimeout(async () => {
          EmployeeService.fetchLocationManagers(locationId, search).then(response => {
            callback((response.data && response.data.employees ? response.data.employees : []).map(e => {
              return {
                label: `${e.firstName} ${e.lastName}`,
                value: e.id
              }
            }));
          });
        }, 300);
      },
      showOnTable: false,
      clearable: true
    },
    { name: "status", label: "Active", type: "toggle", defaultVal: false }
  ];
  enableImport = false;
  importSampleLink = "https://dxv8p7y79jw20.cloudfront.net/Jobs+Sample.csv";

  sampleData = secondaryLocation;
  searchPlaceHolder = "Search by title, code, employee or employee number...";
  actions = ["U"];

  fetch(page, search) {
    return secondaryLocationService.list(page, search);
  }
  prepareData(data) {
    if (data.location) data.locationId = data.location.value;
    if (data.name) data.employeeId = data.name.employee_id;
    if (data.supervisors) data.supervisors = JSON.stringify([data.supervisors.value]);
    delete data.location;
    delete data.name;
    return data;
  }
  create(data) {
    data = this.prepareData(data);
    return secondaryLocationService.create(data);
  }

  update(data) {
    data = this.prepareData(data);
    return secondaryLocationService.update(data);
  }

  delete(id) {
    return secondaryLocationService.remove(id);
  }

  import(file) {
    return secondaryLocationService.importSample(file);
  }
}

import CrudPage from "../../components/CrudPage";

import CrudService from "../../services/CrudService";
import FilterService from "../../services/FilterService";
const crudSectionService = new CrudService("sections");

export default class Sections extends CrudPage {
  name = "Zones";
  colors = [{
    label: "#7986CB",
    value: 'color_zone_1'
  }, {
    label: "#009688",
    value: 'color_zone_2'
  }, {
    label: "#795548",
    value: 'color_zone_3'
  }, {
    label: "#B39DDB",
    value: 'color_zone_4'
  }, {
    label: "#616161",
    value: 'color_zone_5'
  }, {
    label: "#E67C73",
    value: 'color_zone_6'
  }, {
    label: "115737",
    value: 'color_zone_7'
  }, {
    label: "#9E69AF",
    value: 'color_zone_8'
  }, {
    label: "#A79B8E",
    value: 'color_zone_9'
  }, {
    label: "#AD1457",
    value: 'color_zone_10'
  }, {
    label: "#3F51B5",
    value: 'color_zone_11'
  }, {
    label: "#562E62",
    value: 'color_zone_12'
  }]
  fields = [
    {
      name: "id",
      label: "ID",
      type: "text",
      showOnForm: false,
      showOnTable: false,
    },
    { name: "code", label: "Code", type: "text" },
    { name: "description", label: "Description", type: "text" },
    {
      name: "showAll",
      showOnTable: false,
      label: "Show to all employees",
      type: "toggle",
      defaultVal: false
    },
    {
      name: "jobs",
      label: "Jobs",
      type: "react-select",
      showIf: {
        prop: 'showAll',
        value: false
      },
      defaultVal: [],
      placeholder: "Type to search for a job",
      menuIsOpen: true,
      loadOptions: (search, callback) => {
        FilterService.getAllJobs({ limit: 20, search }).then((jobs) => {
          callback(
            jobs.data.jobs.map(({ name: label, value }) => ({ label, value }))
          );
        });
      },
      showOnTable: false,
    },
    {
      name: "color",
      label: "Color",
      type: "color",
      options: this.colors,
      showOnTable: true,
    },
    {
      name: "totalJobs",
      label: "Jobs",
      type: "number",
      showOnForm: false,
    },
    { name: "isActive", label: "Active", type: "toggle", defaultVal: false },
  ];
  actions = ["U", "D", "DUP"];

  fetch(page, search = "") {
    return crudSectionService.list(page, search);
  }

  create(data) {
    if (!data.code || !data.description || (!data.jobs && !data.showAll))
      return new Promise(() => { });

    const newData = { ...data };
    newData.jobs = data.jobs.map((j) => j.value);
    return crudSectionService.create(newData);
  }

  update(data) {
    if (!data.code || !data.description || (!data.jobs && !data.showAll))
      return new Promise(() => { });

    const newData = { ...data };
    newData.jobs = data.jobs.map((j) => j.value);
    return crudSectionService.update(newData);
  }

  delete(id) {
    return crudSectionService.remove(id);
  }
}

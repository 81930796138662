import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/es";
import "./styles/pdf.css";
import COLORS from "../BryntumScheduler/colors";
import WeekCalendar from "../WeekCalendar/WeekCalendar";
import ArrayHelper from "../../helpers/ArrayHelper";
import logo from "../../general/assets/images/logo-symbol-color.png";
import { MYSQL_DATETIME_FORMAT } from "../../containers/Schedule/Constant";
import { getSectionData, getSectionValues } from "../Schedule/helpers/Export";

const views = {
  MyWeek: "MyWeek",
  EmployeeWeek: "EmployeeWeek"
};
// Images

class WeeklyShiftPDF extends React.Component {
  state = {
    events: [],
    resources: [],
    isLoading: true
  };
  isSameMonth = (m1, m2) => {
    return m1.month() === m2.month() && m1.year() === m2.year();
  };

  async formatEvents() {
    this.setState({
      isLoading: true
    });
    const resources = await ArrayHelper.mapBy(this.props.resources, "id");
    const events = [];
    this.props.events.forEach(e => {
      if (resources[e.resourceId]) {
        const user = resources[e.resourceId];
        events.push({
          id: e.id,
          resourceId: user.id,
          name: e.name,
          startDate: moment(e.startDate).toDate(),
          endDate: moment(e.endDate).toDate(),
          currentDate: moment(e.startDate).toDate(),
          eventColor: (e.color ? e.color : (COLORS[e.name] || COLORS[e.status])),
          color: e.color,
          status: e.status,
          notes: e.notes,
          breaks: e.breaks,
          breakData: e.breakData,
          userName: user.name,
          role: user.role,
          location: user.location,
          section: getSectionData(e)
        });
      }
    });
    this.setState({
      events,
      isLoading: false
    });
  }

  componentDidMount() {
    this.formatEvents();
    const { language = 'en' } = this.props;
    moment.locale(language);
  }

  componentDidUpdate(p) {
    if (p.events.length !== this.props.events.length) this.formatEvents();
  }

  render() {
    const { events, resources, isLoading } = this.state;
    const {
      startDate,
      endDate,
      preview,
      blackAndWhite,
      breakTotal,
      breakDetails,
      showRole,
      showSection,
      showLocation,
      showShiftDuration,
      lang,
      notes,
      user
    } = this.props;

    return (
      <div
        className={`${
          blackAndWhite ? "black-white" : ""
          } pb-5 pt-5 pr-2 pl-2 pdf-document`}
      >
        <div className={isLoading ? "loader" : "hidden"}>
          <div id="spinner" />
        </div>
        <div className="pdf-header">
          <div className="pdf-date">
            <span>
              {this.isSameMonth(moment(startDate), moment(endDate))
                ? `${moment(startDate).format("DD")} - ${moment(endDate)
                  .format("DD MMM YYYY")
                  .replace(".", "")
                  .toUpperCase()}`
                : `${moment(startDate)
                  .format("DD MMM")
                  .replace(".", "")
                  .toUpperCase()} - ${moment(endDate)
                    .format("DD MMM YYYY")
                    .replace(".", "")
                    .toUpperCase()}`}
            </span>
            <div className="pdf-timestamp">
              <span>
                {" "}
                {lang.timestamp || "Timestamp"}:{" "}
                {moment().format("YYYY-MM-DD hh:mm:ss A")}
              </span>
            </div>
          </div>
          <div className="pdf-logo">
            <img alt="logo" src={logo} />
          </div>
        </div>
        <WeekCalendar
          user={user}
          events={events}
          resources={resources}
          startDate={startDate}
          endDate={endDate}
          currentView={preview}
          breakTotal={breakTotal}
          breakDetails={breakDetails}
          showRole={showRole}
          showLocation={showLocation}
          showSection={showSection}
          showShiftDuration={showShiftDuration}
          lang={lang}
        />
        <div className="notes">
          <h4>{`${lang.notes}`}:</h4>
          <div>{notes}</div>
        </div>
      </div>
    );
  }
}

WeeklyShiftPDF.defaultProps = {
  preview: views.MONTH,
  startDate: moment()
    .startOf("month")
    .format(`${MYSQL_DATETIME_FORMAT}:00`),
  endDate: moment()
    .endOf("month")
    .format(`${MYSQL_DATETIME_FORMAT}:00`),
  isMyEvent: undefined,
  blackAndWhite: false,
  showShiftDuration: false
};
WeeklyShiftPDF.propTypes = {
  preview: PropTypes.string,
  events: PropTypes.array,
  blackAndWhite: PropTypes.bool,
  showShiftDuration: PropTypes.bool
};

export default WeeklyShiftPDF;

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

function usePrev(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

const TimeInput = ({ disabled, value, onChange, type }) => {
    const isAM = moment(value).format("a") === "am";
    const [changed, setChanged] = useState(false);
    const prevValue = usePrev(value);

    const onChangeH = ({ target: { value: hValue } }) => {
        if (disabled) return false;

        const hourValue = parseInt(hValue);
        const newValue = isAM
            ? hourValue === 12
                ? 0
                : hourValue
            : hourValue === 12
            ? 12
            : hourValue + 12;

        hourValue &&
            hourValue > 0 &&
            hourValue <= 12 &&
            onChange(moment(value).hours(newValue));
    };

    const onChangeM = ({ target: { value: mValue } }) => {
        if (disabled) return false;

        const minuteValue = parseInt(mValue);
        minuteValue >= 0 &&
            minuteValue <= 59 &&
            onChange(moment(value).minutes(minuteValue));
    };

    const onClickAM = () => {
        if (disabled) return false;
        !isAM && onChange(moment(value).hour(value.hour() - 12));
    };
    const onClickPM = () => {
        if (disabled) return false;
        isAM && onChange(moment(value).hour(value.hour() + 12));
    };

    useEffect(() => {
        if (
            type === "end time" &&
            value &&
            prevValue &&
            prevValue.format("HH:mm") !== value.format("HH:mm")
        ) {
            setChanged(true);
            setTimeout(() => {
                setChanged(false);
            }, 500);
        }
    }, [value, type]);

    const hourValue = value && value.format("h");
    const minuteValue = value && value.format("mm");

    return (
        <div className={`time-input ${changed ? "changed" : ""}`}>
            <input
                disabled={disabled}
                value={hourValue}
                type="number"
                onBlur={onChangeH}
                onChange={onChangeH}
            ></input>
            <input
                disabled={disabled}
                value={minuteValue}
                type="number"
                onBlur={onChangeM}
                onChange={onChangeM}
            ></input>

            <div
                className={`period${isAM ? " selected" : ""}${
                    disabled ? " disabled" : ""
                }`}
                onClick={onClickAM}
            >
                AM
            </div>

            <div
                className={`period${!isAM ? " selected" : ""}${
                    disabled ? " disabled" : ""
                }`}
                onClick={onClickPM}
            >
                PM
            </div>
        </div>
    );
};

export default TimeInput;

import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import "./assets/styles/DayCalendar.css";
import COLORS from "../BryntumScheduler/colors";
import Duration from "../../helpers/Duration";

const views = {
  MyDay: "MyDay",
  EmployeeDay: "EmployeeDay",
};

class DayCalendar extends React.Component {
  state = {
    dimessions: null,
    hourWidth: null,
    minWidth: null,
    nameWidth: 100,
  };
  constructor(props) {
    super(props);
    this.getDayHours = this.getDayHours.bind(this);
    this.mapEvents = this.mapEvents.bind(this);
    this.isSameDay = this.isSameDay.bind(this);
  }

  isSameDay(m1, m2) {
    return (
      m1.date() === m2.date() &&
      m1.month() === m2.month() &&
      m1.year() === m2.year()
    );
  }

  mapEvents(events) {
    const mapEvents = {};
    events.forEach((ev) => {
      if (!mapEvents[`resource-${ev.resourceId}`]) {
        mapEvents[`resource-${ev.resourceId}`] = [];
      }
      mapEvents[`resource-${ev.resourceId}`].push(ev);
    });
    return mapEvents;
  }

  getDayHours(startDate, endDate, currentDate) {
    const hours = [];
    let currDate = moment(startDate).startOf("day");
    const lastDate = moment(endDate).endOf("day");
    while (currDate.isSameOrBefore(lastDate)) {
      const newDate = currDate.clone();
      const hour = newDate.format("hhA");
      hours.push({
        key: `${hours.length}`,
        hourDisplay: hour,
        hour: newDate.format("HH:mm").toString(),
      });
      currDate = currDate.add(1, "hour");
    }
    return hours;
  }
  getLeftOfHour(ind) {
    return `${this.state.hourWidth * ind}px`;
  }
  eventRenderer(eventRecord, showShiftDuration, showRole, lang) {
    eventRecord.eventColor =
      eventRecord.status === "approved"
        ? COLORS[eventRecord.name]
        : COLORS[eventRecord.status] || "yellow";
    let hours = "";
    if (showShiftDuration) {
      let duration = moment.duration(
        moment(eventRecord.endDate).diff(moment(eventRecord.startDate))
      );
      if (eventRecord.breaks) {
        duration = duration.add(-1 * eventRecord.breaks, "minute");
      }
      hours = `${duration.hours() > 0 ? duration.hours() + "h" : ""} ${
        duration.minutes() > 0 ? duration.minutes() + (lang.min || "min") : ""
        }`;
    }
    const nestedEvents = this.getNestedEvents(
      eventRecord,
      showShiftDuration,
      showRole,
      lang,
      hours
    );
    return (
      <div
        key={eventRecord.id}
        style={this.getEventStyle(eventRecord.startDate, eventRecord.endDate)}
        className="event"
      >
        <div
          style={{ height: "36px", position: "relative" }}
          className={`shift can-be-selected ${eventRecord.eventColor} ${eventRecord.color ? eventRecord.color : ''}`}
        >
          {nestedEvents.map((ev) => ev)}
        </div>
      </div>
    );
  }
  getNestedEvents(eventRecord, showShiftDuration, showRole, lang, hours) {
    const nestedEvents = [];
    let breakData = [];
    if (eventRecord.breakData) {
      breakData = JSON.parse(eventRecord.breakData);
    }
    if (breakData.length === 0) {
      nestedEvents.push(
        <div className="shift-details">
          <div className="nbsp-if-empty">
            {moment(eventRecord.startDate).format("LT") +
              " - " +
              moment(eventRecord.endDate).format("LT")}
            {showShiftDuration ? " • " + hours : ""}
          </div>
          <div className="nbsp-if-empty">
            {showRole ? `${eventRecord.role} • ` : ""}{" "}
            {(lang[eventRecord.name.toLowerCase()] || eventRecord.name)}
          </div>
          {eventRecord.section && Array.isArray(eventRecord.section) && eventRecord.section.length > 0 && (
            <div className="nbsp-if-empty">
              {eventRecord.section.map(s => <span className={`${s.zoneColor ? `zone ${s.zoneColor}` : ''}`}>{s.zone}</span>)}
            </div>
          )}
        </div>
      );
    } else {
      const totalHours = Duration.durationAsHours(
        eventRecord.endDate,
        eventRecord.startDate
      );
      let left = 0;
      for (let i = 0; i < breakData.length; i++) {
        const br = breakData[i];
        let startDate, endDate;
        if (i === 0) {
          startDate = eventRecord.startDate;
          endDate = `${moment(eventRecord.startDate).format("YYYY-MM-DD")} ${
            br.start
            }`;

          const eventDuration = Duration.durationAsHours(endDate, startDate);
          const eventWidth = this.getNestedEventWidth(
            totalHours,
            eventDuration
          );

          hours = "";
          let duration = moment.duration(
            moment(endDate).diff(moment(startDate))
          );
          hours = `${duration.hours() > 0 ? duration.hours() + "h" : ""} ${
            duration.minutes() > 0
              ? duration.minutes() + (lang.min || "min")
              : ""
            }`;
          nestedEvents.push(
            <div
              style={{
                position: "absolute",
                height: "100%",
                left: `${left}%`,
                width: `${eventWidth}%`,
              }}
              key={nestedEvents.length}
            >
              <div className="shift-details">
                <div className="nbsp-if-empty">
                  {moment(startDate).format("LT") +
                    " - " +
                    moment(endDate).format("LT")}
                  {showShiftDuration ? " • " + hours : ""}
                </div>
                <div className="nbsp-if-empty">
                  {showRole ? `${eventRecord.role} • ` : ""}{" "}
                  {(lang[eventRecord.name.toLowerCase()] || eventRecord.name)}
                </div>
                {eventRecord.section && Array.isArray(eventRecord.section) && eventRecord.section.length > 0 && (
                  <div className="nbsp-if-empty">
                    {eventRecord.section.map(s => <span className={`${s.zoneColor ? `zone ${s.zoneColor}` : ''}`}>{s.zone}</span>)}
                  </div>
                )}
              </div>
            </div>
          );
          left = left + eventWidth;
        }
        const breakStart = (startDate = `${moment(eventRecord.startDate).format(
          "YYYY-MM-DD"
        )} ${br.start}`);
        const breakend = `${moment(eventRecord.startDate).format(
          "YYYY-MM-DD"
        )} ${br.end}`;
        const breakDuration = Duration.durationAsHours(breakend, breakStart);
        const breakWidth = this.getNestedEventWidth(totalHours, breakDuration);
        nestedEvents.push(
          <div
            style={{
              position: "absolute",
              height: "100%",
              left: `${left}%`,
              width: `${breakWidth}%`,
            }}
            key={nestedEvents.length}
            className="break"
          >
            <div className="shift-details">
              <div className="nbsp-if-empty">
                {moment(breakStart).format("h.ma").replace("m", "")}
                <br />
                {moment(breakend).format("h.ma").replace("m", "")}
                <br />
                {br.duration > 60
                  ? `${(br.duration - (br.duration % 60)) / 60}h ${
                  br.duration % 60
                  }m`
                  : `${br.duration}m`}
              </div>
            </div>
          </div>
        );
        left += breakWidth;
        if (i === breakData.length - 1) {
          startDate = `${moment(eventRecord.startDate).format("YYYY-MM-DD")} ${
            br.end
            }`;
          endDate = eventRecord.endDate;
        } else {
          const br2 = breakData[i + 1];
          startDate = `${moment(eventRecord.startDate).format("YYYY-MM-DD")} ${
            br.end
            }`;
          endDate = `${moment(eventRecord.startDate).format("YYYY-MM-DD")} ${
            br2.start
            }`;
        }
        hours = "";
        let duration = moment.duration(moment(endDate).diff(moment(startDate)));
        hours = `${duration.hours() > 0 ? duration.hours() + "h" : ""} ${
          duration.minutes() > 0 ? duration.minutes() + (lang.min || "min") : ""
          }`;
        const eventDuration = Duration.durationAsHours(endDate, startDate);
        const eventWidth = this.getNestedEventWidth(totalHours, eventDuration);
        nestedEvents.push(
          <div
            style={{
              position: "absolute",
              height: "100%",
              left: `calc(${left}% + 1px)`,
              width: `${eventWidth}%`,
            }}
            key={nestedEvents.length}
          >
            <div className="shift-details">
              <div className="nbsp-if-empty">
                {moment(startDate).format("LT") +
                  " - " +
                  moment(endDate).format("LT")}
                {showShiftDuration ? " • " + hours : ""}
              </div>
            </div>
          </div>
        );
        left += eventWidth;
      }
    }

    return nestedEvents;
  }
  getNestedEventWidth(totalHours, duration) {
    return (duration / totalHours) * 100;
  }
  getEventStyle = (start, end) => {
    const eventDuration = Duration.durationAsHours(end, start);
    const eventDay = moment(start).format("YYYY-MM-DD 00:00:00");
    const startDuration = Duration.durationAsHours(start, eventDay);
    const width = 4.166666667 * eventDuration;
    const left = 4.166666667 * startDuration;
    return {
      width: `${width}%`,
      left: `${left}%`,
    };
  };
  componentDidMount() {
    const width = this.calendarHeader.offsetWidth;
    const hourWidth = (width - this.state.nameWidth) / 24;
    const minWidth = hourWidth / 60;
    this.setState({
      dimessions: {
        width,
        height: this.calendarHeader.offsetHeight,
      },
      hourWidth,
      minWidth,
    });
  }
  render() {
    const { startDate, endDate, currentDate, lang } = this.props;
    const hours = this.getDayHours(startDate, endDate, currentDate);
    return (
      <div
        className={"b-react-monthly-container"}
        id={this.props.id}
        ref={(el) => (this.el = el)}
      >
        <div className="header-border">
          <div
            ref={(el) => (this.calendarHeader = el)}
            className="calendar-header"
            id="calendarHeaderRow"
          >
            <div
              style={{ width: `${this.state.nameWidth}px` }}
              className="calendar-header-cell full-name"
            >
              {lang.fullName || "Full Name"}
            </div>
            <div className="hours-container">
              {hours.map((h) => (
                <span key={h.key} className="calendar-header-cell hours">
                  {h.hourDisplay}
                </span>
              ))}
            </div>
          </div>
        </div>
        {this.state.dimessions && this.state.hourWidth && this.renderData()}
      </div>
    );
  }
  renderData() {
    const {
      startDate,
      endDate,
      currentDate,
      events,
      showRole,
      showShiftDuration,
      lang,
    } = this.props;
    const hours = this.getDayHours(startDate, endDate, currentDate);
    const mapEvents = this.mapEvents(events);
    return (
      <React.Fragment>
        {Object.values(mapEvents).map((evs, key) => (
          <div className="resource-row" key={key}>
            <div
              className="cell full-name"
              style={{
                left: 0,
                width: `${this.state.nameWidth}px`,
              }}
            >
              {evs[0].userName}
            </div>
            <div className="hours-container">
              {hours.map((h, ind) => (
                <span key={h.key} className="cell hours"></span>
              ))}
              {evs.map((ev) =>
                this.eventRenderer(ev, showShiftDuration, showRole, lang)
              )}
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

DayCalendar.defaultProps = {
  startDate: moment().startOf("day"),
  endDate: moment().endOf("day"),
  currentDate: moment(),
  currentView: views.MyDay,
  events: [],
  resources: [],
  showShiftDuration: true,
};
DayCalendar.propTypes = {
  currentView: PropTypes.string,
  events: PropTypes.array,
  resources: PropTypes.array,
  showShiftDuration: PropTypes.bool,
};

export default DayCalendar;

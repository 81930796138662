import React from "react";
import Modal from "react-modal";
import customModalStyles from "./customStyle";
import PropTypes from "prop-types";
import moment from "moment";
import Duration from "../../helpers/Duration";
import Breaks from "../../containers/Schedule/Breaks";
import ScheduleService from "../../services/ScheduleService";
import COLORS from "../BryntumScheduler/colors";
import { SCHEDULE_MODES, SCHEDULE_STATUSES } from "../../containers/Schedule/Constant";
import ArrayHelper from "../../helpers/ArrayHelper";

class AssignEvents extends React.Component {
    state = {
        error: null,
        mapResourceWithEvents: {},
        isLoading: false,
        assignCompleted: false,
        mapEventWithStatus: {},
        events: []
    };
    componentDidMount() {
        const {
            isMySchedule,
            events,
            resources
        } = this.props;
        const mapResourceWithEvents = {};
        if (isMySchedule) {
            events.forEach((ev) => {
                mapResourceWithEvents[ev.id] = resources[0].id
            });
        }
        this.setState({
            events,
            mapResourceWithEvents
        });
    }

    onResourceChanged = (resourceId, eventId) => {
        const { mapResourceWithEvents } = this.state;
        mapResourceWithEvents[eventId] = resourceId;
        this.setState({
            mapResourceWithEvents
        })
    };
    saveAll = async () => {
        const {
            onEventsAssign,
            isMySchedule,
            resources
        } = this.props;
        const mapResources = await ArrayHelper.mapBy(resources, 'id');
        const { events, mapResourceWithEvents } = this.state;
        if (Object.values(mapResourceWithEvents).length === events.length && Object.values(mapResourceWithEvents).filter(v => parseInt(v) === 0).length === 0) {
            this.setState({
                error: null,
                isLoading: true
            });
            const savedEvents = events.map((ev) => {
                ev.data.resourceId = parseInt(mapResourceWithEvents[ev.id]);
                ev.resourceId = parseInt(mapResourceWithEvents[ev.id]);
                return this.saveSchedule(ev, mapResources[ev.resourceId]);
            });
            await Promise.all(savedEvents).then((newEvents) => {
                newEvents = newEvents.filter(ev => ev !== null);
                this.setState({
                    isLoading: false,
                    assignCompleted: true
                }, () => {
                    const ids = newEvents.map(ev => ev.id);
                    if (ids.length > 0) {
                        onEventsAssign(ids, newEvents, isMySchedule);
                    }
                })
            });
        } else {
            this.setState({
                error: 'Please assign each event one resource'
            })
        }
    };
    saveSchedule = (event, resource) => {
        return new Promise((resolve, reject) => {
            const {
                id,
                resourceId,
                name,
                currentDate,
                startDate,
                endDate,
                notes,
                break1,
                break1StartTime,
                break1EndTime,
                break2,
                break2StartTime,
                break2EndTime,
                break3,
                break3StartTime,
                break3EndTime,
                break4,
                break4StartTime,
                break4EndTime,
            } = event.data;
            const { breaks, breakData } = Breaks.constructBreakDate(event.data);

            const data = {
                id,
                resourceId,
                name,
                startDate: moment(startDate).format(),
                endDate: moment(endDate).format(),
                currentDate: moment(currentDate).format(),
                breaks,
                breakData: JSON.stringify(breakData),
                notes,
                status: SCHEDULE_STATUSES.PENDING,
                isUnavailable: 0,
                oldUserId: 0,
                locationId: resource.locationId,
                jobId: resource.jobId
            };
            const { resourceId: oldUserId, locationId, jobId, startDate: oldStartDate, endDate: oldEndDate, currentDate: oldCurrentDate } = event.originalData;
            if (parseInt(resourceId) === 0) {
                data.jobId = jobId;
                data.locationId = locationId;
                data.oldUserId = oldUserId;
                data.status = SCHEDULE_STATUSES.APPROVED;
            } else {
                data.jobId = resource.jobId;
                data.locationId = resource.locationId;
                data.oldUserId = 0;
                data.status = SCHEDULE_STATUSES.PENDING;
            }
            if (parseInt(resourceId) !== parseInt(oldUserId)) {
                data.startDate = moment(oldStartDate).format();
                data.endDate = moment(oldEndDate).format();
                data.currentDate = moment(oldCurrentDate).format();
            }
            return ScheduleService.saveSchedule(data).then((response) => {
                if (response.data.message) {
                    const { mapEventWithStatus } = this.state;
                    mapEventWithStatus[data.id] = `Error: ${response.data.message}`;
                    this.setState({
                        mapEventWithStatus
                    }, () => {
                        resolve(null);
                    })
                } else {
                    const eventData = {
                        id,
                        resourceId,
                        name,
                        notes,
                        breaks,
                        breakData: JSON.stringify(breakData),
                        break1,
                        break1StartTime,
                        break1EndTime,
                        break2,
                        break2StartTime,
                        break2EndTime,
                        break3,
                        break3StartTime,
                        break3EndTime,
                        break4,
                        break4StartTime,
                        break4EndTime,
                        locationId: data.locationId,
                        jobId: data.jobId,
                        oldUserId: data.oldUserId ? data.oldUserId : 0,
                        startDate: moment(data.startDate).toDate(),
                        endDate: moment(data.endDate).toDate(),
                        currentDate: moment(data.currentDate).toDate(),
                        status: data.status,
                        eventColor: COLORS[data.status],
                        cls: data.status === 'approved' ? event.data.cls : '',
                        resizable: this.props.mode === SCHEDULE_MODES.TODAY,
                    };
                    resolve(eventData);
                }
            }).catch((err) => {
                reject(err);
            });
        })
    };
    render() {
        const {
            isOpen,
            closeModal,
            resources,
            mapLocations,
            mapJobs,
            isMySchedule,
            jobs
        } = this.props;
        const {
            events,
            mapResourceWithEvents,
            isLoading,
            mapEventWithStatus,
            assignCompleted
        } = this.state;
        resources.map(r => {
            r.jobs = jobs.filter(j => j.employees.includes(r.employeeId)).map(j => j.value);
            return r;
        });
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customModalStyles}
                contentLabel="Available shift"
                shouldCloseOnOverlayClick={false}
            >
                <div className="modal-header">
                    <h4>Assign Events to Employees</h4>
                </div>
                <div className="modal-body custom-height">
                    <div className={isLoading ? "loader" : "hidden"}>
                        <div id="spinner" />
                    </div>
                    {(!assignCompleted || Object.values(mapEventWithStatus).length > 0) ? (
                        <table className="table table-responsive-lg">
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Event Type</th>
                                    <th>Location</th>
                                    <th>Role</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Breaks</th>
                                    <th>Duration</th>
                                    <th>Assign To</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    events.map((event, key) => (
                                        <tr key={key}>
                                            <td>
                                                <div tabIndex="0" className="b-sch-color-green">
                                                    <div className="b-sch-event b-sch-event-resizable-true"
                                                        style={{ animationDelay: "0s" }}>
                                                        <span className="text-break white-space-normal font-size">

                                                            {Duration.durationAsHoursAndMinsWithBreakString(event.data.endDate, event.data.startDate, event.data.breaks)} <br />
                                                            {mapLocations[event.data.locationId]} <br /> {mapJobs[event.data.jobId].text} • {event.data.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{event.data.name}</td>
                                            <td>{mapLocations[event.data.locationId]}</td>
                                            <td>{mapJobs[event.data.jobId].text}</td>
                                            <td>{moment(event.data.startDate).format('YYYY-MM-DD hh:mm a')}</td>
                                            <td>{moment(event.data.endDate).format('YYYY-MM-DD hh:mm a')}</td>
                                            <td>{event.data.breaks}min</td>
                                            <td>{Duration.durationAsHoursAndMinsWithBreakString(event.data.endDate, event.data.startDate, event.data.breaks)}</td>
                                            <td>
                                                <select disabled={isMySchedule} defaultValue={mapResourceWithEvents[event.data.id] || ""} onChange={(e => {
                                                    const id = e.target.value;
                                                    this.onResourceChanged(id, event.data.id);
                                                })}>
                                                    {[{ id: 0, name: '' }, ...resources.filter(r => r.jobs.includes(event.data.jobId) && r.locationId === event.data.locationId)].map((r, key) => (
                                                        <option key={key} value={r.id}>{r.name}</option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td>
                                                {assignCompleted ? (
                                                    mapEventWithStatus[event.data.id] ? (<span className="text-danger">{mapEventWithStatus[event.data.id]}</span>) : (<span className="text-success">Assign Completed</span>)
                                                ) : (
                                                        <span className="text-warning">{mapResourceWithEvents[event.data.id] && mapResourceWithEvents[event.data.id] !== '0' ? 'Ready to Assign' : 'Waiting for employee'}</span>
                                                    )}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    ) : (
                            <div>
                                <h3 className="text-success">Assign Completed</h3>
                            </div>
                        )}

                </div>
                <div className="modal-footer">
                    {
                        this.state.error && (
                            <div className="errorsContainer mt-2 text-center text-danger">
                                {this.state.error}
                            </div>
                        )
                    }
                    <div className="pull-right">
                        {
                            !assignCompleted && (
                                <button className="btn btn-outline-success btn-sm mr-2" onClick={this.saveAll}>Save</button>
                            )
                        }

                        <button className="btn btn-outline-warning btn-sm mr-2" onClick={this.props.closeModal}>Close
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

AssignEvents.defaultProps = {
    closeModal: () => {
    },
    isOpen: false,
};
AssignEvents.propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,

};
export default AssignEvents;

import React, { Component } from "react";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB"
import enUS from "date-fns/locale/en-US"
import { Formik } from "formik";
import * as Yup from "yup";
import { getWeekStartDay } from "../Schedule/helpers/DateHelper";

// Login validations schema
const leaveSchema = Yup.object().shape({
    startDate: Yup.date()
        .required("Start date is required"),
    endDate: Yup.date()
        .required("End date is required"),
    notes: Yup.string()
        .trim()
        .required("Note is required"),
});

export default class RequestLeavesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: undefined,
            maxStartDate: undefined,
            minStartDate: moment().toDate(),
            endDate: undefined,
            maxEndDate: undefined,
            minEndDate: moment().toDate(),
            notes: "",
        };
    }

    clickOutside = () => {
        this.startDatePicker.cancelFocusInput();
        this.startDatePicker.setOpen(false);
        this.endDatePicker.cancelFocusInput();
        this.endDatePicker.setOpen(false);
    };

    startDateValueChanged = date => {
        this.setState({
            startDate: date,
            minEndDate: date
        });
    };

    endDateValueChanged = date => {
        this.setState({
            endDate: date,
            maxStartDate: date
        });
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.editingLeave !== prevProps.editingLeave) {
            const {
                editingLeave
            } = this.props;
            this.setState({
                maxStartDate: editingLeave ? editingLeave.endDate.toDate() : undefined,
                minEndDate: editingLeave ? editingLeave.startDate.toDate() : moment().toDate(),
                minStartDate: (!editingLeave || moment().toDate() < editingLeave.startDate.toDate()) ? moment().toDate() : editingLeave.startDate.toDate()
            })
        }
    }

    render() {
        const {
            maxStartDate,
            minStartDate,
            maxEndDate,
            minEndDate,
        } = this.state;
        const {
            editingLeave,
            onCancel,
            user
        } = this.props;
        const formValues = {
            startDate: editingLeave ? editingLeave.startDate.toDate() : undefined,
            endDate: editingLeave ? editingLeave.endDate.toDate() : undefined,
            notes: editingLeave ? editingLeave.note : ''
        };
        const initialValues = {
            startDate: undefined,
            endDate: undefined,
            maxStartDate: undefined,
            maxEndDate: undefined,
            notes: "",
        };
        const firstDayOfWeek = getWeekStartDay(user);
        if (firstDayOfWeek) {
            registerLocale('en-GB', enGB)
        } else {
            registerLocale('en-US', enUS)
        }
        return (
            <div className="card">
                <div className="card-header">
                    <h4>Time off request</h4>
                </div>
                <div className="card-body">

                    <Formik
                        initialValues={formValues}
                        validationSchema={leaveSchema}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            await this.props.requestLeave(values);
                            resetForm(initialValues);
                            this.setState(initialValues);
                            setSubmitting(false);
                        }}
                        enableReinitialize={true}
                    >
                        {({ errors, values, touched, isSubmitting, handleSubmit, handleBlur, setFieldValue }) => (
                            <form id="leaveForm" onSubmit={handleSubmit} className="card-body" tabIndex="500">
                                <div className="form-group">
                                    <DatePicker
                                        locale={firstDayOfWeek ? 'en-GB' : 'en-US'}
                                        selected={values.startDate}
                                        value={values.startDate}
                                        name="startDate"
                                        autoComplete='off'
                                        placeholderText="Start date"
                                        className="form-control"
                                        onChange={
                                            (value) => {
                                                setFieldValue('startDate', value, true);
                                                this.startDateValueChanged(value);
                                            }
                                        }
                                        onBlur={handleBlur}
                                        disabled={isSubmitting}
                                        minDate={minStartDate}
                                        maxDate={maxStartDate}
                                        dateFormat="MM/dd/yyyy"
                                        ref={r =>
                                            (this.startDatePicker = r)
                                        }
                                        onClickOutside={
                                            this.clickOutside
                                        }
                                    />
                                    {errors.startDate && touched.startDate && <div className="input-validation-error">{errors.startDate}</div>}
                                </div>
                                <div className="form-group">
                                    <DatePicker
                                        locale={firstDayOfWeek ? 'en-GB' : 'en-US'}
                                        selected={values.endDate}
                                        autoComplete='off'
                                        value={values.endDate}
                                        placeholderText="End date"
                                        name="endDate"
                                        className="form-control"
                                        onChange={
                                            (value) => {
                                                setFieldValue('endDate', value, true);
                                                this.endDateValueChanged(value);
                                            }
                                        }
                                        onBlur={handleBlur}
                                        disabled={isSubmitting}
                                        minDate={minEndDate}
                                        maxDate={maxEndDate}
                                        dateFormat="MM/dd/yyyy"
                                        ref={r =>
                                            (this.endDatePicker = r)
                                        }
                                        onClickOutside={
                                            this.clickOutside
                                        }
                                    />
                                    {errors.endDate && touched.endDate && <div className="input-validation-error">{errors.endDate}</div>}
                                </div>
                                <div className="form-group">
                                    <textarea
                                        style={{ minHeight: "6em", resize: "none", height: "200px" }}
                                        value={values.notes}
                                        name="notes"
                                        onChange={
                                            (e) => {
                                                setFieldValue('notes', e.target.value, true);
                                                this.setState({
                                                    notes: e.target.value
                                                })
                                            }
                                        }
                                        onBlur={handleBlur}
                                        disabled={isSubmitting}
                                        placeholder="Notes"
                                        className="form-control"
                                    />
                                    {errors.notes && touched.notes && <div className="input-validation-error">{errors.notes}</div>}
                                </div>
                                <div className="submit mt-3 mb-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {editingLeave ? (
                                        <>
                                            <button type="submit" className="btn btn-primary w-100" style={{ marginRight: "1em" }} disabled={isSubmitting}>
                                                <i className="fas fa-check mr-2" />
                                                Update leave
                                            </button>
                                            <button type="button" className="btn btn-danger w-100" style={{ marginLeft: "1em" }} onClick={onCancel} disabled={isSubmitting}>
                                                <i className="fas fa-close mr-2" />
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                            <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
                                                <i className="fas fa-check mr-2" />
                                            Request leave
                                            </button>
                                        )}

                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

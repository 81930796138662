import { TABS } from '../../../containers/Schedule/Constant';
import {
  changeToRegular,
  makeShiftsAvailable,
  notifyForEvent,
  releaseEvent
} from '../helpers/EventActions';
import { onEventDelete } from '../helpers/CUD_API';
import {
  handleMultiSelect,
  onScheduleSave,
  publishEvents,
  submitEvents,
  buildFilters,
  isManager,
  getNextPageMonthlySchedule
} from '../helpers/SchedulerHelper';
import { openModal } from '../Modals';
import CalendarView from '../../CalendarView/CalendarView';
import React from 'react';

function renderMonthlyView() {
  const {
    user,
    selfEvents,
    events,
    resources,
    config,
    activeTab,
    mapEvents,
    scheduleConfigs
  } = this.state;
  const filters = this.call(buildFilters, true);
  return (
    <CalendarView
      ref={'calendarView'}
      user={user}
      locations={[]}
      jobs={[]}
      resources={resources}
      self={activeTab === TABS.MY_SHIFT}
      manager={isManager(user)}
      readOnly={activeTab === TABS.MY_DEPARTMENT}
      events={activeTab === TABS.MY_SHIFT ? selfEvents : events}
      getNextPage={(filters = {}) => this.call(getNextPageMonthlySchedule, filters)}
      mapEvents={mapEvents}
      config={config}
      notifyForEvent={(e) => this.call(notifyForEvent, e)}
      releaseEvent={(e) => this.call(releaseEvent, e)}
      onEventDelete={(e, s, isM) => this.call(onEventDelete, e, s, isM)}
      submitEvents={(e) => this.call(submitEvents, e)}
      onScheduleSave={(e) => this.call(onScheduleSave, e)}
      onSelectEventsChange={(e) => this.call(handleMultiSelect, e)}
      copyEvents={() => this.call(openModal, 'copyModal')}
      changeToRegular={(e) => this.call(changeToRegular, e)}
      makeAvailable={(e) => this.call(makeShiftsAvailable, e)}
      publishEvents={(e) => this.call(publishEvents, e)}
      scheduleConfigs={scheduleConfigs}
      filters={filters}
      setEmployeeRates={(rates) => {
        if (rates && typeof rates === "object" && Object.keys(rates).length) {
          this.setState({
            rates: {
              ...this.state.rates,
              ...rates
            }
          })
        }
      }}
    />
  );
}

export {
  renderMonthlyView
};

import React from "react";
import moment from "moment";
import Modal from "react-modal";
import { TABS, SCHEDULE_EVENTS, SCHEDULE_MODES } from "./Constant";
import "react-dates/lib/css/_datepicker.css";
import "./assets/styles/Schedules.css";
import { getUser, getScheduleConfigs } from "../../components/Schedule/helpers/R_API";
import { setActiveTabFromQuery } from "../../components/Schedule/helpers/ModeAndTab";
import { renderModals } from "../../components/Schedule/Modals";
import { renderHeader } from "../../components/Schedule/Header";
import { renderSubHeader } from "../../components/Schedule/Estimation";
import { startDateAndEndDateAreValid } from "../../components/BryntumScheduler/Configs";
import { renderPagination } from "../../components/Schedule/helpers/Renders";
import { renderMonthlyView } from "../../components/Schedule/schedule-render/MonthlyView";
import { renderAvailableView } from "../../components/Schedule/schedule-render/AvailableView";
import { renderForecastView } from "../../components/Schedule/schedule-render/ForecastView";
import { renderScheduleView } from "../../components/Schedule/schedule-render/ScheduleView";
import { renderDepartmentView } from "../../components/Schedule/schedule-render/MyDepartmentView";
import { renderMyAvailableView } from "../../components/Schedule/schedule-render/MyAvailableView";
import { renderMyScheduleView } from "../../components/Schedule/schedule-render/MyScheduleView";

class BaseSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventChanged: false,
            openEventModal: false,
            eventId: 0,
            eventNotification: {
                rules: { enabled: true, minsBefore: 30 },
                viaEmail: 1,
                viaSms: 0,
                viaWeb: 0,
                viaPush: 0
            },
            mappedEvents: {},
            employeeCode: null,
            showEmployeeProfileModal: false,
            selectedEvent: "",
            eventsVersion: 0,
            resourcesVersion: 0,
            availableEventsVersion: 0,
            availableLocations: [],
            availableJobs: [],
            mapLocations: {},
            mapJobs: {},
            available: [],
            selfAvailable: [],
            events: [],
            resources: [],
            selfResource: [],
            selfEvents: [],
            activeTab: TABS.MY_SHIFT,
            focused: false,
            startDate: null,
            endDate: null,
            focusedInput: null,
            copyClicked: false,
            focusedCopyDateInput: true,
            mode: "today",
            page: 1,
            total_pages: 1,
            config: {
                viewPreset: "hourAndDay",
                startDate: moment()
                    .startOf("day")
                    .toDate(),
                endDate: moment()
                    .endOf("day")
                    .toDate()
            },
            selectedEvents: [],
            selectedResources: [],
            copyToResources: [],
            isLoading: true,
            newEvents: 0,
            user: {},
            zones: [],
            roles: [],
            locations: [],
            locationTree: [],
            displayLocationTree: false,
            showTree: true,
            checked: [],
            expanded: [],
            departments: [],
            selectedRoles: [],
            selectedRolesLength: 0,
            selectedZones: [],
            selectedZonesLength: 0,
            selectedLocationId: "",
            selectedShowEmployees: "-1",
            selectedLocations: [],
            selectedTreeLocations: [],
            selectedLocationsLength: 0,
            selectedEventNames: [],
            selectedEventsLength: 0,
            selectedDepartments: [],
            selectedDepartmentsLength: 0,
            searchName: "",
            filterModal: false,
            saveFilterModal: false,
            viewIsFiltered: false,
            isResenting: false,
            eventNames: SCHEDULE_EVENTS.map(ev => {
                return {
                    name: ev,
                    value: ev
                }
            }),
            copyModal: false,
            selectedDays: {},
            isCopying: false,
            endCopy: false,
            completePercentage: 0,
            copyDate: null,
            copyError: null,
            printModal: false,
            departmentBudget: 0,
            estimation: {
                scheduledEmployees: [],
                scheduledEvents: [],
                totalEmployees: 0,
                time: {
                    hr: 0,
                    min: 0,
                    cost: 0
                },
                ot: {
                    hr: 0,
                    min: 0,
                    cost: 0
                },
                background: {
                    scheduledEmployees: [],
                    scheduledEvents: [],
                    time: {
                        hr: 0,
                        min: 0,
                        cost: 0
                    },
                    ot: {
                        hr: 0,
                        min: 0,
                        cost: 0
                    },
                }
            },
            availableShifts: {},
            totalAvailableShifts: 0,
            openAvailableShift: false,
            openForecastView: false,
            openSelfAvailableShift: false,
            lastCopiedEvents: [],
            mapEvents: [],
            sortByEventStartDate: false,
            hideTerminatedEmployees: true,
            rates: {},
            scheduleConfigs: {
                sections: {},
                filters: []
            },
            selectedFilterId: '',
            allZones: []
        };
    }

    call = (fn, ...arg) => fn.call(this, ...arg);
    componentDidMount() {
        const { user } = this.props;
        this.call(getUser, user, () => {
            this.call(setActiveTabFromQuery);
        });
        this.call(getScheduleConfigs);
        Modal.setAppElement(document.getElementById("app"));
    }
    render() {
        const {
            user,
            mode,
            config,
            isLoading
        } = this.state;
        return (
            <div className="container-fluid bg-white">
                {this.call(renderModals)}
                {this.call(renderHeader)}
                {this.call(renderSubHeader)}
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className={isLoading ? "loader" : "hidden"}>
                            <div id="spinner" />
                        </div>
                        {config.startDate && mode === SCHEDULE_MODES.THIS_MONTH ?
                            (
                                <React.Fragment>
                                    {this.call(renderMonthlyView)}
                                </React.Fragment>
                            )
                            :
                            <React.Fragment>
                                {this.state.activeTab === TABS.MY_EMPLOYEES && user.role === "manager" && (
                                    <div>
                                        {config.startDate && config.endDate && startDateAndEndDateAreValid(config.startDate, config.endDate) && (
                                            <React.Fragment>
                                                {this.call(renderAvailableView)}

                                                {this.call(renderForecastView)}

                                                {this.call(renderScheduleView)}
                                            </React.Fragment>
                                        )}
                                        {this.call(renderPagination)}
                                    </div>
                                )}
                                {this.state.activeTab === TABS.MY_DEPARTMENT && (
                                    <div>
                                        {config.startDate && config.endDate && startDateAndEndDateAreValid(config.startDate, config.endDate) && (
                                            <React.Fragment>
                                                {this.call(renderDepartmentView)}
                                            </React.Fragment>
                                        )}
                                        {this.call(renderPagination)}
                                    </div>
                                )}
                                {this.state.activeTab === TABS.MY_SHIFT && config.endDate && config.startDate &&
                                    startDateAndEndDateAreValid(config.startDate, config.endDate) && (
                                        <React.Fragment>
                                            {this.call(renderMyAvailableView)}
                                            {this.call(renderMyScheduleView)}
                                        </React.Fragment>
                                    )}
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default BaseSchedule;

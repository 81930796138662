import React, { Component, useState } from "react";
import moment from "moment";
import StringHelper from "../../helpers/StringHelper";

const Row = ({ request, idx, actions, name }) => {
  const [open, setOpen] = useState(false);

  return (
    <tr>
      <td>{idx + 1}</td>
      {actions && <td>{actions(request)}</td>}
      {name && <td>{`${request.firstName} ${request.lastName}`}</td>}

      <td>{request.startDate.format("DD MMMM YYYY")}</td>
      <td>{request.endDate.format("DD MMMM YYYY")}</td>
      <td className="d-flex" title={request.note}>
        <div className="text-wrap">
          {open ? request.note : StringHelper.textTruncate(request.note, 40)}
        </div>
        <button
          className="btn btn-light btn-sm ml-2"
          onClick={() => setOpen(o => !o)}
        >
          <i className={`fas fa-chevron-${open ? "up" : "down"}`} />
        </button>
      </td>
      <td>{StringHelper.formatLeaveStatus(request.status)}</td>
      <td>{moment(request.createdAt).fromNow()}</td>
    </tr>
  );
};

export default class ToBeApprovedList extends Component {
  render() {
    const {
      awaitingApproval,
      actions,
      name,
      showAllActions,
      approveLeave,
      rejectLeave
    } = this.props;

    return (
      <div
        className="card"
        style={{ height: "calc(100% - 30px)", width: "100%" }}
      >
        <div className="card-header">
          <h4>Awaiting for approval</h4>
        </div>

        <div className="card-body">
          {showAllActions && (
            <div className="row float-right">
              <button
                className="btn btn-danger btn-sm mr-2"
                onClick={() => {
                  rejectLeave(
                    awaitingApproval
                      .filter(l => l.status === "pending")
                      .map(l => l.id)
                  );
                }}
              >
                <i className="fas fa-undo" /> Reject All
              </button>
              <button
                className="btn btn-success btn-sm"
                onClick={() => {
                  approveLeave(
                    awaitingApproval
                      .filter(l => l.status === "pending")
                      .map(l => l.id)
                  );
                }}
              >
                <i className="fas fa-check" /> Approve All
              </button>
            </div>
          )}
          {!awaitingApproval.length ? (
            <h6>You have no requests waiting for approval</h6>
          ) : (
            <div className="table-responsive">
              <table className="table table-striped text-nowrap table-scroll">
                <thead>
                  <tr>
                    <th />
                    {actions && <th>Actions</th>}
                    {name && <th>Employee</th>}
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Notes</th>
                    <th>Status</th>
                    <th>Requested</th>
                  </tr>
                </thead>

                <tbody>
                  {awaitingApproval.map((request, idx) => {
                    return (
                      <Row
                        request={request}
                        idx={idx}
                        name={name}
                        actions={actions}
                        key={request.id}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

import React from "react";
import ReactApexChart from "react-apexcharts";

import StringHelper from "../../helpers/StringHelper";
const colors = ["#7c8480","#61c08d","#a3bfb0"];
class Graph extends React.Component {
  state = {
    options: {
        plotOptions: {
            bar: {
                distributed: true,
                dataLabels: {
                    position: 'top', // top, center, bottom
                    style: {
                        fontSize: '12px',
                        colors: ['#7c8480'],
                    }
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return `$${StringHelper.formatMoney(val)}`;
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#7c8480'],
            }
        },
        xaxis: {
            type: "category",
            categories: ['Budget', 'Actual Spent', 'Scheduled'],
            position: 'bottom',
            labels: {
                style: {
                    colors: colors,
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
        },
        tooltip: {
            enabled: false,
            show: false
        },
        // colors: ["#e5e9ea", "#01cf6b", "#7f7f7f"], ?? this is not working
        colors: colors,
        fill: {
            gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100]
            },
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
            },
        },
        grid: {
            show: false,
        }
    },
  };

  render() {
    const { title, budget, actual, scheduled } = this.props;
    const { options: chartOptions } = this.state;
    const series = [{ data: [budget, actual, scheduled] }];
    return (
      <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card dep-card">
          <div className="card-header dep-card-header">
              <span className="card-title dep-card-title">{title}</span>
          </div>
          <div className="card-body pt-1 pb-3 dep-card-body">
            <ReactApexChart options={chartOptions} series={series} type="bar" height="170" />
          </div>
        </div>
      </div>
    );
  }
}

export default Graph;

import moment from "moment";

const eventDragValidation = (context, isAvailable, mapJobs, mapLocations, jobsData) => {
  if (!(context.browserEvent && (context.browserEvent.metaKey || context.browserEvent.ctrlKey))) {
    const task = context.draggedRecords[0],
      newResource = context.newResource;
    const startDate = context.startDate;
    if (
      parseInt(newResource.data.id) !== 0 &&
      task.data.locationId &&
      task.data.jobId
    ) {
      const currDate = moment(task.data.startDate);
      if (!moment(startDate).isSame(currDate, 'D')) {
        const message = `This task is only for date ${currDate.format('YYYY-MM-DD HH:mm')} to ${moment(task.data.endDate).format('YYYY-MM-DD HH:mm')}!`;
        return {
          valid: false,
          message
        };
      }
      const employeeJobs = jobsData.filter(j => j.employees.includes(newResource.data.employeeId));
      const employeeLocations = (newResource.data.allLocations && newResource.data.allLocations.length ?
        newResource.data.allLocations.filter(el =>
          el.startDate === null || (currDate.isBetween(moment(el.startDate), moment(el.endDate), "D"))
        ).map(el => parseInt(el.locationId))
        : [parseInt(newResource.data.locationId)]).filter(l => l === parseInt(task.data.locationId));
      if (employeeLocations.length === 0 || employeeJobs.filter(j => j.value === task.data.jobId).length === 0) {
        const message = `This task is only for location ${mapLocations[task.data.locationId]} and job ${mapJobs[task.data.jobId].text}!`;
        return {
          valid: false,
          message
        };
      }
    } else if (parseInt(newResource.data.id) === 0 && task.name !== 'Regular') {
      let message = `Only Regular shift can be set available!`;
      return {
        valid: false,
        message
      };
    }
  }
  return {
    valid: true,
    message: 'You can drop this event here'
  };
};

export {
  eventDragValidation
}

import {
  SCHEDULE_MAPPED_EVENTS,
  SCHEDULE_MODES,
  TABS,
} from "../../../containers/Schedule/Constant";
import ExcelExporter from "../../BryntumScheduler/ExcelExporter";
import moment from "moment";
import { fetchMonthlySchedules, getMonthlySchedules } from "./R_API";
const getSectionValues = (eventData) => {
  if (eventData.sectionData && typeof eventData.sectionData === 'string') {
    eventData.sectionData = JSON.parse(eventData.sectionData);
  }
    return eventData.sectionData &&
      Array.isArray(eventData.sectionData) ?
      eventData.sectionData.map(d =>
        `${d.zone}: ${d.startTimeDisplay ? d.startTimeDisplay : d.startTime} - ${d.endTimeDisplay ? d.endTimeDisplay : d.endTime}`
      ).join(' | ') : ''
} 
const getSectionData = (eventData) => {
  if (eventData.sectionData && typeof eventData.sectionData === 'string') {
    eventData.sectionData = JSON.parse(eventData.sectionData);
  }
  return eventData.sectionData &&
    Array.isArray(eventData.sectionData) ? eventData.sectionData : []
} 
async function exportEvents() {
  const {
    resources: stateResources,
    activeTab,
    user,
    scheduleConfigs,
  } = this.state;

  const events = await (activeTab === TABS.MY_SHIFT
    ? this.call(getMonthlySchedules, true)
    : this.call(fetchMonthlySchedules, false, true));

  const resources =
    activeTab === TABS.MY_SHIFT
      ? stateResources
      : [
          {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            role: user.jobTitle,
            location: user.locationDescription,
          },
        ];
  const jobSections =
    scheduleConfigs && scheduleConfigs.sections ? scheduleConfigs.sections : {};
  const sections = {};
  Object.values(jobSections).forEach((sec) => {
    sec.forEach((s) => {
      if (!sections[s.value]) {
        sections[s.value] = s.text;
      }
    });
  });
  this.call(exportToExcel, events, resources, sections);
}

function exportToExcel(events, resources, sections) {
  const columns = [
    { value: "Employee", field: "name", width: 500, type: "String" },
    { value: "Location", field: "location", width: 500, type: "String" },
    { value: "Role", field: "role", width: 500, type: "String" },
    { value: "Zone", field: "section", width: 500, type: "String" },
    { value: "Task", field: "name", width: 500, type: "String" },
    { value: "Starts", field: "startDate", width: 500, type: "String" },
    { value: "Ends", field: "endDate", width: 500, type: "String" },
    { value: "Duration", field: "duration", width: 500, type: "String" },
    { value: "Breaks", field: "breaks", width: 500, type: "String" },
    { value: "Break 1", field: "break1", width: 500, type: "String" },
    { value: "Break 2", field: "break2", width: 500, type: "String" },
    { value: "Break 3", field: "break3", width: 500, type: "String" },
    { value: "Break 4", field: "break4", width: 500, type: "String" },
  ];
  let rows = [];
  const excelExporter = new ExcelExporter();
  const { config, mode } = this.state;
  let filename = `Schedule (${moment(config.startDate).format(
    "YYYY-MM-DD"
  )} - ${moment(config.endDate).format("YYYY-MM-DD")})`;
  resources = events.map((e) => {
    return {
      id: e.resourceId,
      name: `${e.firstName} ${e.lastName}`,
      role: e.jobName,
      location: e.locationName,
    };
  });
  switch (mode) {
    case SCHEDULE_MODES.TODAY:
      filename = `Today Schedule (${moment(config.startDate).format(
        "YYYY-MM-DD"
      )})`;
      rows = this.call(
        getMonthlyOrCustomSheetData,
        events,
        resources,
        sections
      );
      break;
    case SCHEDULE_MODES.THIS_WEEK:
      filename = `Weekly Schedule (${moment(config.startDate).format(
        "YYYY-MM-DD"
      )} - ${moment(config.endDate).format("YYYY-MM-DD")})`;
      rows = this.call(
        getMonthlyOrCustomSheetData,
        events,
        resources,
        sections
      );
      break;
    case SCHEDULE_MODES.THIS_MONTH:
      filename = `${moment(config.startDate)
        .startOf("month")
        .format("MMMM")} Schedule`;
      events = events.filter((ev) => ev.id !== -1);
      rows = this.call(
        getMonthlyOrCustomSheetData,
        events,
        resources,
        sections
      );
      break;
    case SCHEDULE_MODES.DATE_RANGE:
      filename = `Custom Date Range Schedule (${moment(config.startDate).format(
        "YYYY-MM-DD"
      )} - ${moment(config.endDate).format("YYYY-MM-DD")})`;
      rows = this.call(
        getMonthlyOrCustomSheetData,
        events,
        resources,
        sections
      );
      break;
    default:
      break;
  }
  excelExporter.export(
    {
      filename,
    },
    columns,
    rows
  );
}
function getWeeklyOrDailySheetData(events, resources, sections = {}) {
  const rows = [];
  events.forEach((ev) => {
    const u = resources.find((u) => u.id === ev.resourceId);
    let duration = moment.duration(
      moment(ev.endDate).diff(moment(ev.startDate))
    );
    if (ev.breaks) {
      duration = duration.add(-1 * ev.breaks, "minute");
    }
    const hours =
      ev.name === SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF
        ? "24h"
        : `${duration.hours() > 0 ? duration.hours() + "h" : ""} ${
            duration.minutes() > 0 ? duration.minutes() + "min" : ""
          }`;
    let breakData = [];
    if (ev.breakData) {
      breakData = JSON.parse(ev.breakData);
    }
    let breaks = "";
    if (ev.breaks > 0) {
      if (ev.breaks > 60) {
        const breakMins = ev.breaks % 60;
        const breakHours = (ev.breaks - breakMins) / 60;
        breaks = `${breakHours > 0 ? " " + breakHours + "h" : ""}${
          breakMins > 0 ? " " + breakMins + "min" : ""
        }`;
      } else {
        breaks = `${ev.breaks}min`;
      }
    }
    let break1 = "";
    let break2 = "";
    let break3 = "";
    let break4 = "";
    for (let i = 0; i < breakData.length; i++) {
      const br = breakData[i];
      switch (i) {
        case 0:
          break1 = `${moment(`2019-07-07 ${br.start}`).format(
            "hh:mm A"
          )} - ${moment(`2019-07-07 ${br.end}`).format("hh:mm A")} (${
            br.duration
          }min)`;
          break;
        case 1:
          break2 = `${moment(`2019-07-07 ${br.start}`).format(
            "hh:mm A"
          )} - ${moment(`2019-07-07 ${br.end}`).format("hh:mm A")} (${
            br.duration
          }min)`;
          break;
        case 2:
          break3 = `${moment(`2019-07-07 ${br.start}`).format(
            "hh:mm A"
          )} - ${moment(`2019-07-07 ${br.end}`).format("hh:mm A")} (${
            br.duration
          }min)`;
          break;
        case 3:
          break4 = `${moment(`2019-07-07 ${br.start}`).format(
            "hh:mm A"
          )} - ${moment(`2019-07-07 ${br.end}`).format("hh:mm A")} (${
            br.duration
          }min)`;
          break;
        default:
          break;
      }
    }
    if (u) {
      
      const el = [
        { value: u.name, type: "String" },
        { value: u.location, type: "String" },
        { value: u.role, type: "String" },
        { value: getSectionValues(ev), type: "String" },
        { value: ev.name, type: "String" },
        {
          value: moment(ev.startDate).format("YYYY-MM-DD hh:mm A").toString(),
          type: "String",
        },
        {
          value: moment(ev.endDate).format("YYYY-MM-DD hh:mm A").toString(),
          type: "String",
        },
        { value: hours, type: "String" },
        { value: breaks, type: "String" },
        { value: break1, type: "String" },
        { value: break2, type: "String" },
        { value: break3, type: "String" },
        { value: break4, type: "String" },
      ];
      rows.push(el);
    }
  });
  return rows;
}

function getMonthlyOrCustomSheetData(events, resources, sections = {}) {
  const rows = [];
  events.forEach((ev) => {
    const u = resources.find((u) => u.id === ev.resourceId);
    let duration = moment.duration(
      moment(ev.endDate).diff(moment(ev.startDate))
    );
    if (ev.breaks) {
      duration = duration.add(-1 * ev.breaks, "minute");
    }
    const hours =
      ev.name === SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF
        ? "24h"
        : `${duration.hours() > 0 ? duration.hours() + "h" : ""} ${
            duration.minutes() > 0 ? duration.minutes() + "min" : ""
          }`;
    let breakData = [];
    if (ev.breakData) {
      breakData = JSON.parse(ev.breakData);
    }
    let breaks = "";
    if (ev.breaks > 0) {
      if (ev.breaks > 60) {
        const breakMins = ev.breaks % 60;
        const breakHours = (ev.breaks - breakMins) / 60;
        breaks = `${breakHours > 0 ? " " + breakHours + "h" : ""}${
          breakMins > 0 ? " " + breakMins + "min" : ""
        }`;
      } else {
        breaks = `${ev.breaks}min`;
      }
    }
    let break1 = "";
    let break2 = "";
    let break3 = "";
    let break4 = "";
    for (let i = 0; i < breakData.length; i++) {
      const br = breakData[i];
      switch (i) {
        case 0:
          break1 = `${moment(`2019-07-07 ${br.start}`).format(
            "hh:mm A"
          )} - ${moment(`2019-07-07 ${br.end}`).format("hh:mm A")} (${
            br.duration
          }min)`;
          break;
        case 1:
          break2 = `${moment(`2019-07-07 ${br.start}`).format(
            "hh:mm A"
          )} - ${moment(`2019-07-07 ${br.end}`).format("hh:mm A")} (${
            br.duration
          }min)`;
          break;
        case 2:
          break3 = `${moment(`2019-07-07 ${br.start}`).format(
            "hh:mm A"
          )} - ${moment(`2019-07-07 ${br.end}`).format("hh:mm A")} (${
            br.duration
          }min)`;
          break;
        case 3:
          break4 = `${moment(`2019-07-07 ${br.start}`).format(
            "hh:mm A"
          )} - ${moment(`2019-07-07 ${br.end}`).format("hh:mm A")} (${
            br.duration
          }min)`;
          break;
        default:
          break;
      }
    }
    const el = [
      { value: u.name, type: "String" },
      { value: u.location, type: "String" },
      { value: u.role, type: "String" },
      { value: getSectionValues(ev), type: "String" },
      { value: ev.name, type: "String" },
      {
        value: moment(ev.startDate).format("YYYY-MM-DD hh:mm A").toString(),
        type: "String",
      },
      {
        value: moment(ev.endDate).format("YYYY-MM-DD hh:mm A").toString(),
        type: "String",
      },
      { value: hours, type: "String" },
      { value: breaks, type: "String" },
      { value: break1, type: "String" },
      { value: break2, type: "String" },
      { value: break3, type: "String" },
      { value: break4, type: "String" },
    ];
    rows.push(el);
  });
  return rows;
}
export {
  exportEvents,
  exportToExcel,
  getMonthlyOrCustomSheetData,
  getWeeklyOrDailySheetData,
  getSectionValues,
  getSectionData,
};

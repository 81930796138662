import React, { Component } from "react";
import Modal from "react-modal";
import customStyle from "./customStyle";
import "./assets/styles/resetPassword.css";
import UserService from "../../services/UserService";

class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      sendEmail: true,
      errorMsg: null,
      resetting: false
    };
  }

  onChange = e => {
    this.setState({ password: e.target.value });
  };

  generateRandom = () => {
    let password = Math.random()
      .toString(36)
      .slice(-6);

    this.setState({ password });
  };

  savePassword = async () => {
    const { password, sendEmail } = this.state;
    const {
      employee: { userId },
      onDone
    } = this.props;

    if (!password.trim().length)
      this.setState({ errorMsg: "New password is required" }, () =>
        setTimeout(() => {
          this.setState({ errorMsg: null });
        }, 5000)
      );
    else
      try {
        const response = await UserService.resetPassword(userId, {
          userId,
          sendEmail,
          password
        });

        this.closeModal();
        onDone(true, response.data.message);
        this.setState({ resetting: false });
      } catch (e) {
        onDone(false);
        this.setState({ resetting: false });
        console.log(e);
      }
  };

  closeModal = () => {
    this.setState({ password: "", sendEmail: true });
    this.props.closeModal();
  };

  copyPassword = e => {
    e.preventDefault();
    this.input.select();
    document.execCommand("copy");
  };

  render() {
    const { isOpen, closeModal } = this.props;
    const { password, sendEmail, errorMsg, resetting } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyle}
        contentLabel="Reset password"
        shouldCloseOnOverlayClick={false}
        className="col-md-5 col-sm-12 card"
      >
        <div className="modal-header">
          <h4>Reset password</h4>
        </div>

        <div className="modal-body">
          <div className="top">
            <h6>Enter a new password or</h6>
            <button
              className="btn btn-info btn-sm ml-2"
              onClick={this.generateRandom}
            >
              Generate a random password
            </button>
          </div>
          <p>
            The employee will be required to enter his new password when he
            login with the new password
          </p>

          <div className="input-group mb-2">
            <input
              ref={i => (this.input = i)}
              className="form-control"
              value={password}
              onChange={this.onChange}
              placeholder="New password"
            />
            <div className="input-group-append">
              <button
                onClick={this.copyPassword}
                className="input-group-text"
              >
                Copy
              </button>
            </div>
          </div>

          {errorMsg && (
            <div className="alert alert-danger mb-3">{errorMsg}</div>
          )}

          <div className="form-check">
            <input
              className="form-check-input"
              onChange={() => this.setState({ sendEmail: !sendEmail })}
              type="checkbox"
              id="send-email"
              defaultChecked={sendEmail}
            />
            <label className="form-check-label" htmlFor="send-email">
              Send new password via e-mail
            </label>
          </div>
        </div>

        <div className="modal-footer">
          <div className="pull-right">
            <button
              className="btn btn-primary mr-2"
              onClick={this.savePassword}
            >
              {resetting ? (
                <span>
                  <i className="fas fa-spinner fa-spin mr-2" />
                  Resetting...
                </span>
              ) : (
                <React.Fragment>
                  <i className="fas fa-check" /> Save
                </React.Fragment>
              )}
            </button>

            <button
              className="btn btn-light"
              onClick={this.closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ResetPasswordModal;

import BaseService from "./BaseService";

class LeavesService extends BaseService {
    getCollection(params) {
        return this.apiGet({
            url: "/leaves",
            params
        });
    }

    getItem(id) {
        return this.apiGet({
            url: `/leaves/${id}`
        });
    }
    getApproveLeaves() {
        return this.apiGet({
            url: `/leaves/leaves-for-approve`
        });
    }
    create(data) {
        return this.apiPost({
            url: `/leaves`,
            data
        });
    }

    update(id, data) {
        return this.apiPut({
            url: `/leaves/${id}`,
            data
        });
    }

    delete(id,isApproved = false) {
        return this.apiDelete({
            url: `/leaves/${id}${isApproved ? '?isApproved=true': ''}`,
        });
    }
    rejectLeaves(ids) {
        return this.apiPost({
            url: `/leaves/reject`,
            data: {
                ids
            }
        });
    }
    approveLeaves(ids) {
        return this.apiPost({
            url: `/leaves/approve`,
            data: {
                ids
            }
        });
    }
}

export default new LeavesService();

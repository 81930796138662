import React, { useEffect, useMemo, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import SelectSearch from "react-select";
import "./../../../general/assets/styles/react-select-search-style.css";

const ClockInSettingsForm = ({
  locationTree,
  editMode,
  onFormSubmit,
  departments = [],
  jobs = [],
  selectedRule,
  onCancel,
}) => {
  const [name, setName] = useState("");
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [tree, setTree] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [error, setError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [formIsSaving, setFormIsSaving] = useState(false);
  const [departmentInputValue, setDepartmentInputValue] = useState("");

  useEffect(() => {
    if (locationTree && departments.length && jobs.length) {
      if (!editMode && !selectedRule) {
        setTree(locationTree);
        setDepartmentOptions(departments);
      }
    }
  }, [locationTree, departments.length, jobs.length]);

  useEffect(() => {
    if (selectedRule) {
      const levelDep = selectedRule.departments ? selectedRule.departments : [];
      const levelLoc = selectedRule.locations ? selectedRule.locations : [];

      setChecked(levelLoc);
      setExpanded([]);
      setName(selectedRule.name);
      filterOptions(levelLoc, levelDep);
    } else {
      resetToInitial();
    }
  }, [selectedRule]);

  const resetToInitial = () => {
    setDepartmentOptions(departments);
    setTree(locationTree);
    setChecked([]);
    setExpanded([]);
    setName("");
    setSelectedDepartments([]);
  };

  const filterOptions = (loc, dep) => {
    setChecked(loc.map((l) => l.toString()));
    setSelectedDepartments(departments.filter((d) => dep.includes(+d.value)));
  };

  const getDepartmentIds = () => selectedDepartments.map((d) => +d.value);

  const onCheck = (checkedNodes) => {
    setChecked(checkedNodes);
    filterOptions(checkedNodes, getDepartmentIds());
  };

  const onChangeDepartments = (selectedDepartments) => {
    setSelectedDepartments(selectedDepartments);
    filterOptions(
      checked,
      selectedDepartments.map((d) => d.value)
    );
  };

  const submitForm = async () => {
    if (!name || name.trim() === "") {
      setNameError("Rule name is required");
      return;
    }

    setFormIsSaving(true);
    setError(null);
    setNameError(null);
    onFormSubmit({
      name,
      checked,
      selectedDepartments,
      editMode,
      editRuleId: selectedRule && selectedRule.id ? selectedRule.id : 0,
    })
      .then((valid) => {
        if (valid) {
          onCancel();
          resetToInitial();
        } else {
          setError("Couldn't submit rule");
        }
      })
      .catch((err) => {
        console.error("error", err);
        setError("Couldn't submit rule");
      })
      .finally(() => {
        setFormIsSaving(false);
      });
  };

  const submitDisabled = useMemo(
    () => !name || selectedDepartments.length === 0 || formIsSaving,
    [name, selectedDepartments, formIsSaving]
  );

  return (
    <div>
      <div className="form-group row">
        <label className="col-md-3 col-form-label text-nowrap">Name:</label>
        <div className="col-md-9">
          <input
            type="text"
            name="levelName"
            className="form-control"
            onChange={({ target: { value } }) => setName(value)}
            value={name}
            disabled={formIsSaving}
          />
          {nameError && (
            <div className="input-validation-error px-3 py-1">{nameError}</div>
          )}
        </div>
      </div>

      {tree && tree.length > 0 && (
        <div className="form-group row">
          <label className="col-md-12 col-form-label">Select locations:</label>
          <div className="col-md-12">
            <CheckboxTree
              nodes={tree}
              checked={checked}
              expanded={expanded}
              onCheck={onCheck}
              onExpand={setExpanded}
            />
          </div>
        </div>
      )}

      {departmentOptions && departmentOptions.length > 0 ? (
        <div className="form-group row">
          <label className="col-md-12 col-form-label">
            Select departments:
          </label>
          <div className="col-md-12">
            <SelectSearch
              inputValue={departmentInputValue}
              onInputChange={(inputValue, { action }) => {
                if (action !== "set-value") {
                  setDepartmentInputValue(inputValue);
                  return inputValue;
                }
                return departmentInputValue;
              }}
              closeMenuOnSelect={false}
              options={departmentOptions}
              onChange={onChangeDepartments}
              isMulti={true}
              getOptionLabel={(o) => o.name}
              value={selectedDepartments}
              name="departments"
              placeholder="Select Departments"
              fuse={{
                keys: ["name"],
                threshold: 0.3,
              }}
            />
          </div>
        </div>
      ) : null}

      <div className="form-group row">
        {error && (
          <div className="input-validation-error px-3 py-1">{error}</div>
        )}

        {editMode ? (
          <div className="col-12 mt-4 text-right">
            <button
              disabled={submitDisabled}
              onClick={submitForm}
              className="btn btn-primary mr-2"
            >
              {formIsSaving ? "Updating..." : "Update"}
            </button>
            <button
              type="button"
              disabled={formIsSaving}
              onClick={onCancel}
              className="btn btn-default"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="col-12 mt-4 text-right">
            <button
              onClick={submitForm}
              disabled={submitDisabled}
              className="btn btn-info"
            >
              {formIsSaving ? "Creating..." : "Create"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClockInSettingsForm;

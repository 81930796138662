import React from "react";

import SettingsService from "../../services/SettingsService";
import Toast from "../../components/Blocks/Toast/Toast";
import NotificationSectionDropdownItem from "../../components/Notification/NotificationSectionDropdownItem";
import NotificationSectionToggleItem from "../../components/Notification/NotificationSectionToggleItem";
import NotificationSection from "../../components/Notification/NotificationSection";
import TimezoneDropdownItem from "../../components/Settings/TimezoneDropdownItem";
import UserService from "../../services/UserService";
import WeekConfItem from "../../components/Settings/WeekConfItem";

const SHIFT_ALERTS_BEFORE_MINUTES_OPTIONS = {
  5: "5 minutes",
  10: "10 minutes",
  15: "15 minutes",
  20: "20 minutes",
  30: "30 minutes",
  45: "45 minutes",
  60: "60 minutes",
  120: "120 minutes",
  180: "180 minutes",
};

const DASHBOARD_NOTIFICATIONS_ACTIVE_OPTIONS = {
  never: "Never",
  always: "Always when there is a new notification on my Dashboard",
};

const MESSAGE_NOTIFICATIONS_ACTIVE_OPTIONS = {
  never: "Never",
  always: "Always when I receive a new message",
  personalOnly: "Only when I am mentioned or get a direct message",
};

const NEWSFEED_NOTIFICATIONS_ACTIVE_OPTIONS = {
  never: "Never",
  always: "Always when there is a new post to pages I follow",
  personalOnly: "Only when I am mentioned",
};

const DAILY_REPORT_TIME_DELIVERY_OPTIONS = Array.from(Array(24).keys()).reduce(
  (acc, i) => {
    let h = (i % 12 || 12).toString();
    h = h.length === 1 ? `0${h}` : h;
    const a = Math.floor(i / 12) === 0 ? "AM" : "PM";
    return acc.concat([
      `${h}:00 ${a}`,
      `${h}:15 ${a}`,
      `${h}:30 ${a}`,
      `${h}:45 ${a}`,
    ]);
  },
  []
);

export default class GeneralSettings extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      sections: {},
      isLoading: true,
      hasChanges: false,
      success: null,
      error: null,
      activeSection: "#shifts-section",
      timezoneList: [],
      showLoading: false,
      saving: false,
    };

    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.toastDismiss = this.toastDismiss.bind(this);
  }

  componentDidMount() {
    this.fetchNotificationConfig();
    this.fetchTimezoneList();
  }

  fetchNotificationConfig() {
    SettingsService.getNotificationsConfig().then((res) => {
      this.setState({
        sections: res.data,
        isLoading: false,
      });
    });
  }

  fetchTimezoneList() {
    SettingsService.getTimezoneList().then((res) => {
      this.setState({
        timezoneList: res.data,
      });
    });
  }

  onTimezoneChange = (val) => {
    const {
      user: { id },
      onChangeUserTimezone,
    } = this.props;
    this.setState({
      showLoading: true,
    });
    UserService.updateTimezone(id, { userId: id, tzId: val })
      .then(() => {
        const timezone = this.state.timezoneList.filter(
          (tz) => tz.id === Number(val)
        )[0];
        onChangeUserTimezone(timezone);
        this.setState({
          showLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          error: true,
          showLoading: false,
        });
      });
  };
  onWeekConfChange = (val) => {
    const {
      user: { id },
      onChangeWeekConf,
    } = this.props;
    this.setState({
      showLoading: true,
    });
    UserService.updateWeekConf(id, { userId: id, weekConf: val })
      .then(() => {
        onChangeWeekConf(val);
        this.setState({
          showLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          error: true,
          showLoading: false,
        });
      });
  };

  updateNotificationConfig() {
    this.setState({ saving: true });
    SettingsService.updateNotificationsConfig(this.state.sections)
      .then(() => {
        this.setState({
          hasChanges: false,
          success: true,
          saving: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: true,
          saving: false,
        });
      });
  }

  onSaveChanges() {
    this.updateNotificationConfig();
  }

  toastDismiss() {
    this.setState({
      success: null,
      error: null,
    });
  }

  render() {
    const { isLoading, showLoading, error, success /*, activeSection*/ } =
      this.state;
    const { isManager } = this.props;
    const errorToast = error && (
      <Toast
        title="Notification settings."
        message="Failed to update notification settings!"
        dismiss={this.toastDismiss}
      />
    );
    const successToast = success && (
      <Toast
        status="success"
        title="Notification settings."
        message="Notification settings updated."
        dismiss={this.toastDismiss}
      />
    );

    if (isLoading || showLoading) return <div id="spinner" />;

    return (
      <div className="tab-content" id="myTabContent">
        {error && errorToast}
        {success && successToast}
        <div className="tab-pane fade p-0 active show" role="tabpanel">
          <div className="row">
            <div className="card mx-lg-auto col-lg-10">
              {this.renderUserConfSection()}
              {this.renderShiftsSection()}
              {this.renderShiftsApprovalSection()}
              {/*{this.renderDashboardSection()}
            {this.renderMessagesSection()}
            {this.renderNewsFeedSection()}*/}
              {isManager && this.renderDailyReportSection()}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10 pr-0 mx-lg-auto d-flex justify-content-end">
              <button
                onClick={this.onSaveChanges}
                className="btn btn-primary"
                type="button"
                disabled={!this.state.hasChanges || this.state.saving}
              >
                {this.state.saving ? (
                  <i className="fas fa-spining" />
                ) : (
                  <i className="fas fa-save" />
                )}{" "}
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUserConfSection() {
    const { /* via_sms, via_push,*/ rules } =
      this.state.sections["shift_alerts"];
    const { user } = this.props;
    return (
      <NotificationSection id="shifts-section" title="User Configurations">
        <TimezoneDropdownItem
          active={rules.enabled}
          name="Timezone"
          description="Select timezone"
          options={this.state.timezoneList}
          selected={user.tzId}
          onChange={this.onTimezoneChange}
        />
        <WeekConfItem
          weekConf={user.weekConf}
          onChange={this.onWeekConfChange}
          name="Week starting day"
          description="Choose week starting day"
        />
      </NotificationSection>
    );
  }
  renderShiftsSection() {
    const shiftNotificationSetter = this.sectionSetterFactory("shift_alerts");
    const { /* via_sms, via_push,*/ via_email, rules } =
      this.state.sections["shift_alerts"];
    return (
      <NotificationSection id="shifts-section" title="Shift alarms">
        <NotificationSectionToggleItem
          active={rules.enabled}
          onChange={shiftNotificationSetter("rules.enabled")}
          name="Shift alarms"
          description="Enable shift alarms, so you get a reminder for upcoming shifts"
        />
        <NotificationSectionDropdownItem
          selected={rules.minsBefore}
          options={SHIFT_ALERTS_BEFORE_MINUTES_OPTIONS}
          onChange={shiftNotificationSetter("rules.minsBefore")}
          name="Number of minutes before shift"
          description="Indicate when you'd like to be reminded of an upcoming shift, so you make it to work on time"
        />
        <NotificationSectionToggleItem
          active={rules.updatesEnabled}
          onChange={shiftNotificationSetter("rules.updatesEnabled")}
          name="Shift updates"
          description="Enable shift updates alarms, so you get a notification when your shift is updated or deleted"
        />
        {/* <NotificationSectionToggleItem active={via_sms} onChange={shiftNotificationSetter('via_sms')} name="Via SMS to my mobile phone" />
        <NotificationSectionToggleItem active={via_push} onChange={shiftNotificationSetter('via_push')} name="Via push notifications to my mobile device" description="Download the KOHA app for iOS or Android" />
       */}{" "}
        <NotificationSectionToggleItem
          active={via_email}
          onChange={shiftNotificationSetter("via_email")}
          name="Via Email"
        />
      </NotificationSection>
    );
  }
  renderShiftsApprovalSection() {
    const { isManager } = this.props;
    return (
      <NotificationSection id="shifts-approval" title="Schedule Notifications">
        {this._renderShiftsApproval()}
        {isManager && this._renderShiftsSubmit()}
        {isManager && this._renderShiftsUpdates()}
        {this._renderShiftsReleased()}
        {this._renderShiftsConvertToUnvailability()}
      </NotificationSection>
    );
  }
  _renderShiftsApproval() {
    const shiftNotificationSetter = this.sectionSetterFactory("shift_approval");
    const { /* via_sms, via_push,*/ via_email } = this.state.sections[
      "shift_approval"
    ] || { via_email: true };
    return (
      <NotificationSectionToggleItem
        active={via_email}
        onChange={shiftNotificationSetter("via_email")}
        name="Shift approvals"
        description="Get Shift approvals with email"
      />
    );
  }
  _renderShiftsSubmit() {
    const shiftNotificationSetter = this.sectionSetterFactory("shift_submit");
    const { /* via_sms, via_push,*/ via_email } = this.state.sections[
      "shift_submit"
    ] || { via_email: true };
    return (
      <>
        <NotificationSectionToggleItem
          active={via_email}
          onChange={shiftNotificationSetter("via_email")}
          name="Shift submit"
          description="Get a notification with email when a user submit a schedule for approval"
        />
      </>
    );
  }

  _renderShiftsUpdates() {
    const shiftNotificationSetter = this.sectionSetterFactory("shift_updated");
    const { /* via_sms, via_push,*/ via_email } =
      this.state.sections["shift_updated"];
    return (
      <>
        <NotificationSectionToggleItem
          active={via_email}
          onChange={shiftNotificationSetter("via_email")}
          name="Shift updated"
          description="Get notification over email when my created shift is updated by another manager"
        />
      </>
    );
  }
  _renderShiftsReleased() {
    const shiftNotificationSetter = this.sectionSetterFactory("shift_release");
    const { /* via_sms, via_push,*/ via_email } =
      this.state.sections["shift_release"];
    return (
      <>
        <NotificationSectionToggleItem
          active={via_email}
          onChange={shiftNotificationSetter("via_email")}
          name="Shift released"
          description="Get notification over email when employeee ask to release the shift Or Supervisor approve or reject your release"
        />
      </>
    );
  }
  _renderShiftsConvertToUnvailability() {
    const shiftNotificationSetter = this.sectionSetterFactory(
      "shift_convert_to_regular"
    );
    const { /* via_sms, via_push,*/ via_email } =
      this.state.sections["shift_convert_to_regular"];
    return (
      <>
        <NotificationSectionToggleItem
          active={via_email}
          onChange={shiftNotificationSetter("via_email")}
          name="Shift convert to regular"
          description="Get notification over email when supervisor ask to change shift to regular Or Employee reject or approve the request"
        />
      </>
    );
  }
  renderDashboardSection() {
    const dashboardNotificationSetter = this.sectionSetterFactory("dashboard");
    const { rules, /*via_push,*/ via_email } = this.state.sections["dashboard"];
    return (
      <NotificationSection
        id="dashboard-section"
        title="Dashboard notifications"
        info="Dashboard notifications include updates on shifts, tasks and announcements relevant to you. By default, these notifications are displayed on your Dashboard."
      >
        <NotificationSectionDropdownItem
          selected={rules.deliveryFreq}
          options={DASHBOARD_NOTIFICATIONS_ACTIVE_OPTIONS}
          onChange={dashboardNotificationSetter("rules.deliveryFreq")}
          name="Send me additional alerts when:"
        />
        {/*<NotificationSectionToggleItem active={via_push} onChange={dashboardNotificationSetter('via_push')} name="Via push notifications to my mobile device" description="Download the KOHA app for iOS or Android" />*/}
        <NotificationSectionToggleItem
          active={via_email}
          onChange={dashboardNotificationSetter("via_email")}
          name="Via Email"
        />
      </NotificationSection>
    );
  }

  renderMessagesSection() {
    const messagesNotificationSetter = this.sectionSetterFactory("messages");
    const { rules, /*via_push,*/ via_email } = this.state.sections["messages"];
    return (
      <NotificationSection
        id="messages-section"
        title="Messages notifications"
        info="Message notifications inform you of any new messages in conversations you are part of. By default, these notifications are displayed as badges on the Messages."
      >
        <NotificationSectionDropdownItem
          selected={rules.deliveryFreq}
          options={MESSAGE_NOTIFICATIONS_ACTIVE_OPTIONS}
          onChange={messagesNotificationSetter("rules.deliveryFreq")}
          name="Send me additional alerts when:"
        />
        {/*<NotificationSectionToggleItem active={via_push} onChange={messagesNotificationSetter('via_push')} name="Via push notifications to my mobile device" description="Download the KOHA app for iOS or Android" />*/}
        <NotificationSectionToggleItem
          active={via_email}
          onChange={messagesNotificationSetter("via_email")}
          name="Via Email"
        />
      </NotificationSection>
    );
  }

  renderNewsFeedSection() {
    const newsFeedNotificationSetter = this.sectionSetterFactory("newsfeed");
    const { rules, /*via_push,*/ via_email /*, via_web*/ } =
      this.state.sections["newsfeed"];
    return (
      <NotificationSection
        id="newsfeed-section"
        title="Newsfeed notifications"
        info="Newsfeed notifications inform you of any new posts on pages you follow. By default, these notifications are displayed as badges on the Newsfeed."
      >
        <NotificationSectionDropdownItem
          selected={rules.deliveryFreq}
          options={NEWSFEED_NOTIFICATIONS_ACTIVE_OPTIONS}
          onChange={newsFeedNotificationSetter("rules.deliveryFreq")}
          name="Send me additional alerts when:"
        />
        {/*<NotificationSectionToggleItem active={via_push} onChange={newsFeedNotificationSetter('via_push')} name="Via push notifications to my mobile device" description="Download the KOHA app for iOS or Android" />*/}
        <NotificationSectionToggleItem
          active={via_email}
          onChange={newsFeedNotificationSetter("via_email")}
          name="Via Email"
        />
        {/*<NotificationSectionToggleItem active={via_web} onChange={newsFeedNotificationSetter('via_web')} name="Via notifications to my web browser" />*/}
      </NotificationSection>
    );
  }

  renderDailyReportSection() {
    const dailyReportNotificationSetter =
      this.sectionSetterFactory("daily_report");
    const { rules } = this.state.sections["daily_report"];
    const onDailyReportEnabled = (val) => {
      const timezoneDiff = -new Date().getTimezoneOffset() / 60;
      dailyReportNotificationSetter("rules.enabled")(val);
      dailyReportNotificationSetter("rules.timezoneDiff")(
        timezoneDiff.toString()
      );
    };
    return (
      <NotificationSection id="daily-report-section" title="Daily email report">
        <NotificationSectionToggleItem
          active={rules.enabled}
          onChange={onDailyReportEnabled}
          name="Send me daily email report"
          description="Get information about your employees and hours scheduled"
        />
        <NotificationSectionDropdownItem
          selected={rules.deliveryTime}
          options={DAILY_REPORT_TIME_DELIVERY_OPTIONS}
          onChange={dailyReportNotificationSetter("rules.deliveryTime")}
          name="Time of day to deliver email report"
        />
      </NotificationSection>
    );
  }

  sectionSetterFactory(section) {
    return (field) => (val) => {
      const sectionState = this.state.sections[section];
      const parts = field.split("."),
        lastKey = parts.pop();
      let currentState = sectionState;
      for (let part of parts) {
        currentState = currentState[part];
        if (!currentState) break;
      }
      if (currentState) {
        currentState[lastKey] = val;
        this.setState({
          hasChanges: true,
          sections: {
            ...this.state.sections,
            [section]: sectionState,
          },
        });
      }
    };
  }

  sectionGetterFactory(section) {
    return (field) => {
      const sectionState = this.state.sections[section];
      const parts = field.split(".");
      let val,
        currentState = sectionState;
      do {
        val = currentState[parts.shift()];
        currentState = val;
      } while (val);

      return val;
    };
  }
}

/**
 * Helper components
 */

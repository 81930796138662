import { DateHelper } from "../../libs/scheduler/scheduler.module";
import moment from "moment";
const timeAxis = {
  continuous: false,
  generateTicks(start, end, unit, increment) {
    // Use our own custom time intervals for day time-axis
    const ticks = [];
    if (unit === 'day') {
      let intervalEnd;

      while (start < end) {
        if (start.getDay() > "05") {
          // Fridays are lazy days, working 10am - 4pm
          start.setHours(5);
          intervalEnd = DateHelper.add(start, 15, 'h');
        } else {
          start.setHours(5);
          intervalEnd = DateHelper.add(start, 18, 'h');
        }

        ticks.push({
          startDate: start,
          endDate: intervalEnd
        });
        start = DateHelper.add(start, 1, 'd');
      }
    } else {
      let intervalEnd;
      if (start.getDay() > 5) {
        start.setHours(5);
        intervalEnd = DateHelper.add(start, 15, 'h');
      } else {
        start.setHours(5);
        intervalEnd = DateHelper.add(start, 18, 'h');
      }
      let i = start.getHours();
      start.setHours(0);
      for (i; i < intervalEnd.getHours(); i += 1) {
        const startDate = DateHelper.add(start, i, 'h');
        const endDate = DateHelper.add(start, (i + 1), 'h');
        ticks.push({
          startDate,
          endDate
        });
      }
    }
    return ticks;
  }
};
const timeOpenSettings = {
  0: {
    start: {
      hr: "00",
      min: "00"
    },
    end: {
      hr: "23",
      min: "59"
    }
  },
  1: {
    start: {
      hr: "00",
      min: "00"
    },
    end: {
      hr: "23",
      min: "59"
    }
  },
  2: {
    start: {
      hr: "00",
      min: "00"
    },
    end: {
      hr: "23",
      min: "59"
    }
  },
  3: {
    start: {
      hr: "00",
      min: "00"
    },
    end: {
      hr: "23",
      min: "59"
    }
  },
  4: {
    start: {
      hr: "00",
      min: "00"
    },
    end: {
      hr: "23",
      min: "59"
    }
  },
  5: {
    start: {
      hr: "00",
      min: "00"
    },
    end: {
      hr: "23",
      min: "59"
    }
  },
  6: {
    start: {
      hr: "00",
      min: "00"
    },
    end: {
      hr: "23",
      min: "59"
    }
  }
};
const defaultShifts = {
  'CA': {
    start: "09:00:00",
    finish: "18:00:00"
  }
};
const startDateAndEndDateAreValid = (startDate, endDate) => {
  startDate = moment(startDate);
  endDate = moment(endDate);
  return startDate.isValid() && endDate.isValid() && endDate.isAfter(startDate);
};
const disableOrEnableEventEdit = (event, data, isManager) => {
  if (
    (!isManager && data.name !== 'Unavailability') ||
    (isManager && ['Unavailability', 'Scheduled Day Off'].includes(data.name)) ||
    data.leaveId
  ) {
    event.eventEdit.name.readOnly = true;
    event.eventEdit.endTimeField.readOnly = true;
    event.eventEdit.startTimeField.readOnly = true;
    event.eventEdit.startTimeField.disabled = true;
    event.eventEdit.endTimeField.disabled = true;
    event.eventEdit.name.disabled = true;
    event.eventEdit.sectionId.disabled = true;
    event.eventEdit.zoneContainer.disabled = true;
    event.eventEdit.jobId.disabled = true;
    event.eventEdit.locationId.disabled = true;
    event.eventEdit.saveButton.hidden = true;
    event.eventEdit.deleteButton.hidden = true;
    event.eventEdit.deleteButton.disabled = true;
    event.eventEdit.showDeleteButton = false;
    event.eventEdit.break1.readOnly = true;
    if (!event.eventEdit.break1.hidden && event.eventEdit.break1.widgets) {
      event.eventEdit.break1.widgets.map((w) => {
        w.disabled = true;
        return w;
      })
    }
    event.eventEdit.break2.readOnly = true;
    if (!event.eventEdit.break2.hidden && event.eventEdit.break2.widgets) {
      event.eventEdit.break2.widgets.map((w) => {
        w.disabled = true;
        return w;
      })
    }
    event.eventEdit.break3.readOnly = true;
    if (!event.eventEdit.break3.hidden && event.eventEdit.break3.widgets) {
      event.eventEdit.break3.widgets.map((w) => {
        w.disabled = true;
        return w;
      })
    }
    event.eventEdit.break4.readOnly = true;
    if (!event.eventEdit.break4.hidden && event.eventEdit.break4.widgets) {
      event.eventEdit.break4.widgets.map((w) => {
        w.disabled = true;
        return w;
      })
    }
    event.eventEdit.breakButton.disabled = true;
  } else {
    event.eventEdit.name.readOnly = false;
    event.eventEdit.endTimeField.readOnly = false;
    event.eventEdit.startTimeField.readOnly = false;
    event.eventEdit.startTimeField.disabled = false;
    event.eventEdit.endTimeField.disabled = false;
    event.eventEdit.name.disabled = false;
    event.eventEdit.sectionId.disabled = false;
    event.eventEdit.zoneContainer.disabled = false;
    event.eventEdit.jobId.disabled = false;
    event.eventEdit.locationId.disabled = false;
    event.eventEdit.saveButton.hidden = false;
    event.eventEdit.deleteButton.hidden = false;
    event.eventEdit.deleteButton.disabled = false;
    event.eventEdit.showDeleteButton = true;
    event.eventEdit.break1.readOnly = false;
    if (!event.eventEdit.break1.hidden && event.eventEdit.break1.widgets) {
      event.eventEdit.break1.widgets.map((w) => {
        w.disabled = false;
        return w;
      })
    }
    event.eventEdit.break2.readOnly = false;
    if (!event.eventEdit.break2.hidden && event.eventEdit.break2.widgets) {
      event.eventEdit.break2.widgets.map((w) => {
        w.disabled = false;
        return w;
      })
    }
    event.eventEdit.break3.readOnly = false;
    if (!event.eventEdit.break3.hidden && event.eventEdit.break3.widgets) {
      event.eventEdit.break3.widgets.map((w) => {
        w.disabled = false;
        return w;
      })
    }
    event.eventEdit.break4.readOnly = false;
    if (!event.eventEdit.break4.hidden && event.eventEdit.break4.widgets) {
      event.eventEdit.break4.widgets.map((w) => {
        w.disabled = false;
        return w;
      })
    }
    event.eventEdit.breakButton.disabled = false;
  }
  return event;
};
export {
  timeAxis,
  timeOpenSettings,
  defaultShifts,
  startDateAndEndDateAreValid,
  disableOrEnableEventEdit
}

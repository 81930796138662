import React, { Component } from "react";
import BudgetReportsPage from "./BudgetReportsPage";
import EmployeeReportsPage from "./EmployeeReportsPage";
import ScheduleLoggerPage from "./ScheduleLoggerPage";
import FilterService from "../../services/FilterService";

// Styles
import "./assets/styles/Reports.css";
import "./assets/styles/Export.css";
import UsageDataPage from "./UsageDataPage";

const PAGES = {
  BUDGET_REPORT: "Budget Report",
  SCHEDULE_LOGGER: "Schedule Logs",
  USAGE_DATA: "Usage Data",
  EMPLOYEE_REPORT: "Employee Report"
};

export default class Reports extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      user,
      selectedPage: PAGES.BUDGET_REPORT,
      filterData: {}
      // selectedPage: PAGES.USAGE_DATA
    };
  }

  async componentDidMount() {
    let filterData = await FilterService.getFilterBy(true, true);
    filterData = filterData.data;
    this.setState({ filterData });
  }

  changeTab = selectedPage => {
    this.setState({ selectedPage });
  };

  getPages = () => {
    const { selectedPage, filterData, user } = this.state;

    switch (selectedPage) {
      case PAGES.BUDGET_REPORT:
        return <BudgetReportsPage filterData={filterData} user={user} />;

      case PAGES.SCHEDULE_LOGGER:
        return <ScheduleLoggerPage user={user} />;

      case PAGES.USAGE_DATA:
        return <UsageDataPage filterData={filterData} user={user} />;

      case PAGES.EMPLOYEE_REPORT:
        return <EmployeeReportsPage filterData={filterData} user={user} />;

      default:
        break;
    }
  };

  render() {
    const { selectedPage } = this.state;
    return (
      <div className="container content-area">
        <section className="section">
          <div className="section-body">
            <div className="row mt-5">
              <div className="col-12 col-md-2">
                <div className="report-switch">
                  {Object.values(PAGES).map(page => (
                    <button
                      key={page}
                      onClick={() => this.changeTab(page)}
                      className={`${selectedPage === page ? "active" : ""}`}
                    >
                      {page.toLowerCase()}
                    </button>
                  ))}
                </div>
              </div>
              <div className="col-12 col-md-10">{this.getPages()}</div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from "react";
import Toast from "../../components/Blocks/Toast/Toast";
import ExportTable from "./ExportTable";

import { ReportGenerateForm } from "../../components/Forms/ReportsForm";
import ReportService from "../../services/ReportService";
import DataTable from "react-data-table-component";

// Styles
import "./assets/styles/Reports.css";
import "./assets/styles/Export.css";
import StringHelper from "../../helpers/StringHelper";
import { MYSQL_DATETIME_FORMAT } from "../Schedule/Constant";
import moment from "moment";

const roundToTwo = num => {
  return +(Math.round(num + "e+2") + "e-2");
};
const REPORT_OPTIONS = [
  {
    value: "departmentsBudget",
    label: "Scheduled budgets for departments"
  },
  {
    value: "employeesBudget",
    label: "Scheduled budgets for employees"
  }
];

const REPORT_TYPE_OPTIONS = [
  {
    value: "daily",
    label: "Daily"
  },
  {
    value: "monthly",
    label: "Monthly"
  }
];

export default class BudgetReportsPage extends Component {
  state = {
    isLoading: false,
    successMessage: "",
    reportData: [],
    reportType: "",
    report: "",
    startDate: "",
    endDate: ""
  };

  generateReport = report => {
    this.setState(
      {
        reportData: [],
        isLoading: true,
        report: report.report.value,
        startDate: report.startDate.format("YYYY-MM-DD"),
        endDate: report.endDate.format("YYYY-MM-DD"),
        reportType: report.reportType.value,
        location: report.location ? report.location.value : null,
        department: report.department ? report.department.value : null
      },
      async () => {
        const { data } = await ReportService.generateReport(
          report.report.value,
          {
            dateRangeType: report.reportType.value,
            startDate: report.startDate
              .startOf("day")
              .format(`${MYSQL_DATETIME_FORMAT}:00`),
            endDate: report.endDate
              .endOf("day")
              .format(`${MYSQL_DATETIME_FORMAT}:00`),
            location: report.location ? report.location.value : null,
            department: report.department ? report.department.value : null
          }
        );

        this.setState({
          reportData: data,
          //successMessage: 'Report Generated',
          isLoading: false
        });
      }
    );
  };

  render() {
    const { successMessage } = this.state;
    const successToast = successMessage ? (
      <Toast
        status="success"
        title="Reports"
        message={successMessage}
        dismiss={() => this.setState({ successMessage: null })}
      />
    ) : (
        ""
      );

    return (
      <div className="e-panel card">
        <div className="card-header">
          <h4>Budget Reports</h4>
        </div>
        <div className="col-12">
          {successToast}
          <div className="row">{this.renderFiltersForm()}</div>
          <div className="row">{this.renderTable()}</div>
        </div>
      </div>
    );
  }

  renderPreLoader() {
    const { isLoading } = this.state;
    return isLoading ? (
      <div className="container content-area">
        <section className="section">
          <div className="row mt-5">
            <div className="col">
              <p>
                <b>Loading...</b>
              </p>
            </div>
          </div>
        </section>
      </div>
    ) : null;
  }

  renderFiltersForm() {
    const { filterData, user } = this.props;

    return (
      <div className="col-12 mb-2">
        <ReportGenerateForm
          user={user}
          filterData={filterData}
          onSubmit={this.generateReport}
          reportOptions={REPORT_OPTIONS}
          reportTypeOptions={REPORT_TYPE_OPTIONS}
          autoTriggerFirst={false}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }

  sort = (rows, field, direction) => {
    let newRows = [...rows];
    field === "period"
      ? newRows.sort((a, b) =>
        moment(a).isSameOrAfter(moment(b), "date") ? 1 : -1
      )
      : newRows.sort((a, b) => {
        let first = a[field];
        let second = b[field];

        if (first === undefined || second === undefined) return 1;

        if (first.indexOf("$") !== -1) {
          first = parseFloat(first.replace("$", "").replace(",", ""));
          second = parseFloat(second.replace("$", "").replace(",", ""));
        } else if (first.indexOf("%") !== -1) {
          first = parseFloat(first.replace("%", "").replace(",", ""));
          second = parseFloat(second.replace("%", "").replace(",", ""));
        }
        return first > second ? 1 : -1;
      });

    if (direction === "desc") newRows.reverse();
    return newRows;
  };

  renderTable() {
    const { report, reportType, reportData } = this.state;

    const renderValueRow = (row, field) => {
      const value = parseFloat(
        row[field]
          .replace("$", "")
          .replace("%", "")
          .replace(",", "")
      );
      const className =
        value > 0 ? "text-success" : value < 0 ? "text-danger" : "";
      return <div className={className}>{row[field]}</div>;
    };

    let title = "";
    if (reportType) {
      title +=
        " " + REPORT_TYPE_OPTIONS.find(opt => opt.value === reportType).label;
    }
    if (report) {
      title += " " + REPORT_OPTIONS.find(opt => opt.value === report).label;
    }

    let exportFilename = "";
    if (reportType) {
      exportFilename += REPORT_TYPE_OPTIONS.find(
        opt => opt.value === reportType
      ).label;
    }
    switch (report) {
      case "departmentsBudget":
        exportFilename += " Scheduled Departments Budgets";
        break;
      case "employeesBudget":
        exportFilename += " Scheduled Employees Budgets";
        break;
      default:
    }

    const forDepartment = report === "departmentsBudget";
    let columns = [];
    let rows = [];
    if (forDepartment) {
      columns = [
        {
          name: "Date Range",
          selector: "period",
          sortable: true,
          minWidth: "130px"
        },
        {
          name: "Location",
          selector: "locationName",
          minWidth: "230px",
          sortable: true
        },
        {
          name: "Department",
          selector: "departmentName",
          sortable: true,
          grow: 3
        },
        {
          name: "Budgeted $",
          selector: "budget",
          sortable: true
        },
        {
          name: "Scheduled $",
          selector: "scheduledBudget",
          sortable: true
        },
        {
          name: "Actual $",
          selector: "actualBudget",
          sortable: true
        },
        {
          name: "Variance Act to Sch $",
          selector: "varianceActToSch$",
          sortable: true,
          cell: row => renderValueRow(row, "varianceActToSch$")
        },
        {
          name: "Variance Act to Sch %",
          selector: "varianceActToSch",
          sortable: true,
          cell: row => renderValueRow(row, "varianceActToSch")
        },
        {
          name: "Variance Act to Bud $",
          selector: "varianceActToBud$",
          sortable: true,
          cell: row => renderValueRow(row, "varianceActToBud$")
        },
        {
          name: "Variance Act to Bud %",
          selector: "varianceActToBud",
          cell: row => renderValueRow(row, "varianceActToBud"),
          sortable: true
        },
        {
          name: "Variance Sch to Bud $",
          selector: "varianceSchToBud$",
          cell: row => renderValueRow(row, "varianceSchToBud$"),
          sortable: true
        },
        {
          name: "Variance Sch to Bud %",
          selector: "varianceSchToBud",
          cell: row => renderValueRow(row, "varianceSchToBud"),
          sortable: true
        }
      ];
      rows = (forExport = false) =>
        reportData.map((row, key) => {
          const varianceActToSch$ =
            (row.actualBudget || 0) - (row.scheduledBudget || 0);
          const varianceActToSch = roundToTwo(
            (varianceActToSch$ / (row.scheduledBudget || 1)) * 100
          );
          const varianceActToBud$ = (row.actualBudget || 0) - (row.budget || 0);
          const varianceActToBud = roundToTwo(
            (varianceActToBud$ / (row.actualBudget || 1)) * 100
          );
          const varianceSchToBud$ =
            (row.scheduledBudget || 0) - (row.budget || 0);
          const varianceSchToBud = roundToTwo(
            (varianceSchToBud$ / (row.scheduledBudget || 1)) * 100
          );
          return forExport
            ? [
              { value: row.period, type: "string" },
              { value: row.locationName, type: "string" },
              { value: row.departmentName, type: "string" },
              {
                value: `$${StringHelper.formatMoney(row.budget || 0)}`,
                type: "string"
              },
              {
                value: `$${StringHelper.formatMoney(
                  row.scheduledBudget || 0
                )}`,
                type: "string"
              },
              {
                value: `$${StringHelper.formatMoney(row.actualBudget || 0)}`,
                type: "string"
              },
              {
                value: `$${StringHelper.formatMoney(varianceActToSch$)}`,
                type: "string"
              },
              { value: `${varianceActToSch}%`, type: "string" },
              {
                value: `$${StringHelper.formatMoney(varianceActToBud$)}`,
                type: "string"
              },
              { value: `${varianceActToBud}%`, type: "string" },
              {
                value: `$${StringHelper.formatMoney(varianceSchToBud$)}`,
                type: "string"
              },
              { value: `${varianceSchToBud}%`, type: "string" }
            ]
            : {
              period: row.period,
              locationName: row.locationName,
              departmentName: row.departmentName,
              budget: `$${StringHelper.formatMoney(row.budget || 0)}`,
              scheduledBudget: `$${StringHelper.formatMoney(
                row.scheduledBudget || 0
              )}`,
              actualBudget: `$${StringHelper.formatMoney(
                row.actualBudget || 0
              )}`,
              varianceActToSch$: `$${StringHelper.formatMoney(
                varianceActToSch$
              )}`,
              varianceActToSch: `${varianceActToSch}%`,
              varianceActToBud$: `$${StringHelper.formatMoney(
                varianceActToBud$
              )}`,
              varianceActToBud: `${varianceActToBud}%`,
              varianceSchToBud$: `$${StringHelper.formatMoney(
                varianceSchToBud$
              )}`,
              varianceSchToBud: `${varianceSchToBud}%`
            };
        });
    } else {
      columns = [
        {
          name: "Date Range",
          value: "Date Range",
          minWidth: "130px",
          selector: "period",
          sortable: true
        },
        {
          name: "Location",
          value: "Location",
          minWidth: "230px",
          selector: "locationName",
          sortable: true
        },
        {
          name: "Department",
          value: "Department",
          minWidth: "150px",
          selector: "departmentName",
          sortable: true
        },
        {
          name: "Employee",
          minWidth: "150px",
          value: "Employee",
          selector: "userName",
          sortable: true
        },
        {
          name: "Employee Code",
          value: "Employee Code",
          selector: "userCode",
          sortable: true
        },
        {
          name: "Job",
          minWidth: "200px",
          value: "Job",
          selector: "userRole",
          sortable: true
        },
        {
          name: "Scheduled Hours",
          value: "Scheduled Hours",
          selector: "scheduledHours",
          sortable: true
        },
        {
          name: "Actual Hours",
          value: "Actual Hours",
          selector: "actualHours",
          sortable: true
        },
        {
          name: "Hours Var",
          value: "Hours Var",
          selector: "varianceToScheduledHRS",
          sortable: true
        },
        {
          name: "Scheduled $",
          value: "Scheduled $",
          selector: "scheduledBudget",
          sortable: true
        },
        {
          name: "Actual $",
          value: "Actual $",
          selector: "actualBudget",
          sortable: true
        },
        {
          name: "Var $",
          value: "Var $",
          selector: "varianceToScheduled$",
          cell: row => renderValueRow(row, "varianceToScheduled$"),
          sortable: true
        }
      ];

      rows = (forExport = false) =>
        reportData.map((row, key) => {
          const varianceToScheduledHRS =
            (row.scheduledHours || 0) - (row.actualHours || 0);
          const varianceToScheduled$ =
            (row.scheduledBudget || 0) - (row.actualBudget || 0);
          return forExport
            ? [
              { value: row.period, type: "string" },
              { value: row.locationName, type: "string" },
              { value: row.departmentName, type: "string" },
              { value: row.userName, type: "string" },
              { value: row.userCode, type: "string" },
              { value: row.userRole, type: "string" },
              { value: roundToTwo(row.scheduledHours || 0), type: "number" },
              { value: roundToTwo(row.actualHours || 0), type: "number" },
              { value: roundToTwo(varianceToScheduledHRS), type: "number" },
              {
                value: `$${StringHelper.formatMoney(
                  row.scheduledBudget || 0
                )}`,
                type: "string"
              },
              {
                value: `$${StringHelper.formatMoney(row.actualBudget || 0)}`,
                type: "string"
              },
              {
                value: `$${StringHelper.formatMoney(varianceToScheduled$)}`,
                type: "string"
              }
            ]
            : {
              period: row.period,
              locationName: row.locationName,
              departmentName: row.departmentName,
              userName: row.userName,
              userCode: row.userCode,
              userRole: row.userRole,
              scheduledHours: roundToTwo(row.scheduledHours || 0),
              actualHours: roundToTwo(row.actualHours || 0),
              varianceToScheduledHRS: roundToTwo(varianceToScheduledHRS),
              scheduledBudget: `$${StringHelper.formatMoney(
                row.scheduledBudget || 0
              )}`,
              actualBudget: `$${StringHelper.formatMoney(
                row.actualBudget || 0
              )}`,
              varianceToScheduled$: `$${StringHelper.formatMoney(
                varianceToScheduled$
              )}`
            };
        });
    }

    return (
      <div className="col-12 my-2">
        <div className="card">
          {title && (
            <div className="card-header">
              <div className="float-left">
                <span style={{ fontSize: "17px" }}>{title}</span>
              </div>
              <div className="float-right">
                {reportData.length > 0 ? (
                  <ExportTable
                    className="btn btn-warning mr-2 mb-md-2 export-report-button"
                    table="report-table"
                    filename={exportFilename}
                    sheet="Budgets"
                    icon="mr-1 fas fa-file-excel-o"
                    data={rows("export")}
                    columns={columns}
                    buttonText="Export Report"
                  />
                ) : null}
              </div>
            </div>
          )}
          <div className="card-body">
            <div className="e-table">
              <div className="table-responsive table-lg">
                <DataTable
                  sortFunction={this.sort}
                  columns={columns}
                  noHeader={true}
                  data={rows()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRows() {
    const { isLoading, reportData } = this.state;
    // const forDepartment = reportType === "departmentsBudget";

    // const renderEmployeeCols = row => {
    //   const varianceToScheduledHRS =
    //     (row.scheduledHours || 0) - (row.actualHours || 0);
    //   const varianceToScheduled$ =
    //     (row.scheduledBudget || 0) - (row.actualBudget || 0);
    //   return (
    //     <>
    //       <td className="text-center">{row.userName}</td>
    //       <td className="text-center">{row.userCode}</td>
    //       <td className="text-center">{row.userRole}</td>
    //       <td className="text-center">{roundToTwo(row.scheduledHours || 0)}</td>
    //       <td className="text-center">{roundToTwo(row.actualHours || 0)}</td>
    //       <td className="text-center">
    //         <span
    //           className={
    //             varianceToScheduledHRS < 0
    //               ? "text-red"
    //               : varianceToScheduledHRS === 0
    //               ? ""
    //               : "text-green"
    //           }
    //         >
    //           {" "}
    //           {roundToTwo(varianceToScheduledHRS)}
    //         </span>
    //       </td>
    //       <td className="text-center">
    //         ${StringHelper.formatMoney(row.scheduledBudget || 0)}
    //       </td>
    //       <td className="text-center">
    //         ${StringHelper.formatMoney(row.actualBudget || 0)}
    //       </td>
    //       <td className="text-center">
    //         <span
    //           className={
    //             varianceToScheduled$ < 0
    //               ? "text-red"
    //               : varianceToScheduled$ === 0
    //               ? ""
    //               : "text-green"
    //           }
    //         >
    //           {" "}
    //           ${StringHelper.formatMoney(varianceToScheduled$)}
    //         </span>
    //       </td>
    //     </>
    //   );
    // };
    // const renderDepartmentCols = row => {
    //   const varianceActToSch$ =
    //     (row.actualBudget || 0) - (row.scheduledBudget || 0);
    //   const varianceActToSch = roundToTwo(
    //     (varianceActToSch$ / (row.scheduledBudget || 1)) * 100
    //   );
    //   const varianceActToBud$ = (row.actualBudget || 0) - (row.budget || 0);
    //   const varianceActToBud = roundToTwo(
    //     (varianceActToBud$ / (row.actualBudget || 1)) * 100
    //   );
    //   const varianceSchToBud$ = (row.scheduledBudget || 0) - (row.budget || 0);
    //   const varianceSchToBud = roundToTwo(
    //     (varianceSchToBud$ / (row.scheduledBudget || 1)) * 100
    //   );
    //   return (
    //     <>
    //       <td className="text-center">
    //         ${StringHelper.formatMoney(row.budget || 0)}
    //       </td>
    //       <td className="text-center">
    //         ${StringHelper.formatMoney(row.scheduledBudget || 0)}
    //       </td>
    //       <td className="text-center">
    //         ${StringHelper.formatMoney(row.actualBudget || 0)}
    //       </td>
    //       <td className="text-center">
    //         <span
    //           className={
    //             varianceActToSch$ < 0
    //               ? "text-red"
    //               : varianceActToSch$ === 0
    //               ? ""
    //               : "text-green"
    //           }
    //         >
    //           {" "}
    //           ${StringHelper.formatMoney(varianceActToSch$)}
    //         </span>
    //       </td>
    //       <td className="text-center">
    //         <span
    //           className={
    //             varianceActToSch < 0
    //               ? "text-red"
    //               : varianceActToSch === 0
    //               ? ""
    //               : "text-green"
    //           }
    //         >
    //           {" "}
    //           {varianceActToSch}%
    //         </span>
    //       </td>
    //       <td className="text-center">
    //         <span
    //           className={
    //             varianceActToBud$ < 0
    //               ? "text-red"
    //               : varianceActToBud$ === 0
    //               ? ""
    //               : "text-green"
    //           }
    //         >
    //           {" "}
    //           ${StringHelper.formatMoney(varianceActToBud$)}
    //         </span>
    //       </td>
    //       <td className="text-center">
    //         <span
    //           className={
    //             varianceActToBud < 0
    //               ? "text-red"
    //               : varianceActToBud === 0
    //               ? ""
    //               : "text-green"
    //           }
    //         >
    //           {" "}
    //           {varianceActToBud}%
    //         </span>
    //       </td>
    //       <td className="text-center">
    //         <span
    //           className={
    //             varianceSchToBud$ < 0
    //               ? "text-red"
    //               : varianceSchToBud$ === 0
    //               ? ""
    //               : "text-green"
    //           }
    //         >
    //           {" "}
    //           ${StringHelper.formatMoney(varianceSchToBud$)}
    //         </span>
    //       </td>
    //       <td className="text-center">
    //         <span
    //           className={
    //             varianceSchToBud < 0
    //               ? "text-red"
    //               : varianceSchToBud === 0
    //               ? ""
    //               : "text-green"
    //           }
    //         >
    //           {" "}
    //           {varianceSchToBud}%
    //         </span>
    //       </td>
    //     </>
    //   );
    // };

    let reportRows = (
      <tr>
        <td colSpan="100%" align="center">
          {isLoading ? "Loading..." : "No data"}
        </td>
      </tr>
    );
    if (reportData.length) {
      reportRows = reportData.map((row, key) => {
        return row;
        //  (
        //   <tr key={key}>
        //     <td className="text-center">{row.period}</td>
        //     <td className="text-center">{row.locationName}</td>
        //     <td className="text-center">{row.departmentName}</td>
        //     {forDepartment
        //       ? renderDepartmentCols(row)
        //       : renderEmployeeCols(row)}
        //   </tr>
        // );
      });
    }

    return reportRows;
    // return <tbody>{reportRows}</tbody>;
  }
}

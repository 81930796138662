import React, { Component } from "react";
import { columns } from "./UsageDataTableColumns";
import ExportTable from "../../../containers/Reports/ExportTable";
import zipcelx from "zipcelx";

export default class UsageDataTable extends Component {
  state = {
    isLoading: false
  };

  export = async () => {
    const { resources } = this.props;

    let data = [
      [
        { type: "string", value: "Location" },
        { type: "string", value: "Department" },
        { type: "string", value: "Week Start" },
        { type: "string", value: "Last 7 Days" },
        { type: "string", value: "# Active" },
        { type: "string", value: "This Week" }
      ]
    ];

    data = data.concat(
      resources.map(row => [
        {
          type: "string",
          value: row.location
        },
        {
          type: "string",
          value: row.department
        },
        {
          type: "string",
          value: row.weekStart
        },
        {
          type: "number",
          value: row.lastWeek
        },
        {
          type: "number",
          value: row.nrActive
        },
        {
          type: "number",
          value: row.thisWeek
        }
      ])
    );

    try {
      await zipcelx({
        filename: `Usage Data`,
        sheet: { data }
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { isLoading, resources } = this.props;

    let reportRows = (
      <tr>
        <td colSpan="100%" align="center">
          {isLoading ? "Loading..." : "No data"}
        </td>
      </tr>
    );

    if (resources && resources.length) {
      reportRows = resources.map((row, key) => {
        return (
          <tr key={key}>
            <td className="text-left">{row.location}</td>
            <td className="text-left">{row.department}</td>
            <td className="text-center">{row.logins}</td>
            <td className="text-center">{row.scheduledEmployees}</td>
            <td className="text-center">{row.scheduledHours}</td>
            <td className="text-center">{row.totalBrakes}</td>
            <td className="text-center">{row.workingHours}</td>
          </tr>
        );
      });
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card mb-2">
            <div className="card-header">
              <div className="float-left">
                <div style={{ fontSize: "17px" }}>Usage Data</div>
              </div>
              <div className="float-right">
                {resources && resources.length > 0 && (
                  <ExportTable
                    className="btn btn-warning mr-2 mb-md-2 export-report-button"
                    table="report-table"
                    filename="Usage Data"
                    sheet="Report"
                    icon="mr-1 fas fa-file-excel-o"
                    data={resources}
                    columns={columns}
                    onPress={this.export}
                    buttonText="Export Report"
                  />
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="e-table">
                <div className="table-responsive table-lg">
                  <table
                    id="report-table"
                    className="table table-bordered text-nowrap table-hover table-sm"
                  >
                    <thead>
                      <tr>
                        {columns.map(({ value, field }) => {
                          return (
                            <th key={field} rowSpan={2} className="text-center">
                              {value}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>{reportRows}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

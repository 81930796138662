import { EventStore, ResourceModel, Tooltip } from "../../libs/scheduler/scheduler.module";

const EventDragConfig  = {
  showExactDropPosition: true,
  constrainDragToTimeline : false,
  showTooltip: true,
  onDragStart({ context, event }){
    const me        = this,
      client   = me.client,
      name     = client.scheduledEventName;

    me.currentOverClient = client;
    me.scrollClients = {};

    me.onMouseOverNewTimeline(client);

    const dragData = me.dragData = me.getDragData(context, event);

    if (me.showTooltip) {
      if (!me.tip) {
        me.tip = new Tooltip({
          id         : `${client.id}-event-drag-tip`,
          align      : 'b-t',
          autoShow   : true,
          clippedBy  : me.constrainDragToTimeline ? [client.timeAxisSubGridElement, client.bodyContainer] : null,
          forElement : context.element,
          getHtml    : me.getTipHtml.bind(me),
          // During drag, it must be impossible for the mouse to be over the tip.
          style      : 'pointer-events:none'
        });

        me.tip.on('innerhtmlupdate', me.updateDateIndicator, me);
      }
      else {
        me.tip.showBy(context.element);
      }
    }

    const copyKeyPressed =  dragData.browserEvent && (dragData.browserEvent.metaKey || dragData.browserEvent.ctrlKey);//me.isCopyKeyPressed();
    if (copyKeyPressed) {
      //dragData.refElements.addCls('sch-event-copy');
      dragData.originalHidden = false;
      /*if(dragData.draggedRecords && dragData.draggedRecords[0]){
        dragData.draggedRecords[0] = dragData.draggedRecords[0].copy();
      }*/
    }
    // Trigger eventDragStart or taskDragStart depending on product
    client.trigger(`${name}DragStart`, {
      [`${name}Records`] : dragData.draggedRecords,
      context            : dragData
    });
  },
  updateRecords(context) {
    let me                    = this,
      fromScheduler         = me.scheduler,
      toScheduler           = me.currentOverClient,
      eventStore            = fromScheduler.eventStore,
      resourceStore         = fromScheduler.resourceStore,
      assignmentStore       = eventStore.assignmentStore,
      newResource           = context.newResource,
      draggedRecords        = context.draggedRecords,
      resourceRecord        = context.resourceRecord,
      copyKeyPressed        = context.browserEvent && (context.browserEvent.metaKey || context.browserEvent.ctrlKey), //me.isCopyKeyPressed(),
      startDate             = context.startDate,
      endDate               = context.endDate,
      timeDiff              = context.timeDiff;
    // Move event to the correct event Store in case of dragging across multiple schedulers
    if (fromScheduler.eventStore !== toScheduler.eventStore) {
      fromScheduler.eventStore.remove(draggedRecords);
      toScheduler.eventStore.add(draggedRecords);

      // TODO: Should perhaps set resourceId to null when adding to another eventStore. If not the operation might
      //  have some side effects if the target scheduler has a resource with same id?
      //  (in SchedulerStores#onInternalEventStoreChange & HorizontalEventMapper#onEventUpdate)
    }

    let draggedRecord         = draggedRecords[0],
      relatedDraggedRecords = draggedRecords.slice(1);

    // Scheduler multiple assignment mode
    if (assignmentStore && eventStore instanceof EventStore) {
      me.updateRecordsMultipleAssignmentMode(startDate, timeDiff, draggedRecord, relatedDraggedRecords, resourceRecord,
        newResource, eventStore, resourceStore, assignmentStore, copyKeyPressed);
    }
    // Gantt mode (and task store instead of event store)
    else if (assignmentStore) {
      me.updateRecordsSingleAssignmentMode(startDate, timeDiff, draggedRecord.event,
        relatedDraggedRecords.map(r => r.event), resourceRecord, newResource, eventStore, resourceStore,
        copyKeyPressed);
    }
    // Scheduler single assignment mode
    else {
      /*me.updateRecordsSingleAssignmentMode(startDate, timeDiff, draggedRecord, relatedDraggedRecords, resourceRecord,
        newResource, eventStore, resourceStore, copyKeyPressed);*/
      let toAdd = [];
      if (copyKeyPressed) {
        draggedRecord = draggedRecord.copy(null);
        toAdd.push(draggedRecord);
      }
      // Process original dragged record
      draggedRecord.beginBatch();

      if (!copyKeyPressed && newResource !== resourceRecord && resourceRecord instanceof ResourceModel &&
        newResource instanceof ResourceModel) {
        draggedRecord.reassign(resourceRecord, newResource);
      }
      else if (newResource !== resourceRecord && resourceRecord instanceof ResourceModel && newResource instanceof ResourceModel) {
        draggedRecord.assign(newResource);
        draggedRecord.resource = newResource;
        draggedRecord.locationId = newResource.locationId;
        draggedRecord.jobId = newResource.jobId;
      }
      //
      draggedRecord.startDate = startDate;
      draggedRecord.endDate = endDate;
      draggedRecord.currentDate = draggedRecord.startDate;
      //remove rendered div, forcing a new one to be created on render
      //        draggedEvent.removeAllElementsFromCache();

      draggedRecord.endBatch();

      // Process related records
      let indexDiff = me.constrainDragToResource ? 0 : resourceStore.indexOf(resourceRecord) - resourceStore.indexOf(draggedRecord);

      relatedDraggedRecords.forEach(related => {
        // grabbing resources early, since after ".copy()" the record won't belong to any store
        // and ".getResources()" won't work
        let relatedResources = related.getResources();

        if (copyKeyPressed) {
          related = related.copy(null);
          toAdd.push(related);
        }

        related.beginBatch();

        // calculate new startDate (and round it) based on timeDiff
        related.setStartDate(me.adjustStartDate(related.getStartDate(), timeDiff), true, eventStore.skipWeekendsDuringDragDrop);

        indexDiff !== 0 && relatedResources.length && relatedResources.forEach(r => {
          let newIndex = resourceStore.indexOf(r) - indexDiff,
            newResource;

          if (newIndex < 0) {
            newIndex = 0;
          }
          else if (newIndex >= resourceStore.getCount()) {
            newIndex = resourceStore.getCount() - 1;
          }

          newResource = resourceStore.getAt(newIndex);
          related.reassign(r, newResource);
        });

        related.endBatch();
      });

      if (toAdd.length) eventStore.add(toAdd);
    }
    context.draggedRecords[0] = draggedRecord;
    // Tell the world there was a successful drop
    toScheduler.trigger('eventDrop', {
      eventRecords         : [draggedRecord],
      isCopy               : copyKeyPressed,
      event                : context.browserEvent,
      targetEventRecord    : context.targetEventRecord,
      targetResourceRecord : context.newResource,
      context
    });
  },
  dragTipTemplate : data => {
    const parent = document.getElementsByClassName('b-tooltip-content')[0];
    if(parent) {
      if(!data.valid) {
        parent.classList.add('tip-invalid');
        parent.classList.remove('tip-valid')
      } else {
        parent.classList.add('tip-valid');
        parent.classList.remove('tip-invalid')
      }
    }

    return (
      `
                          <div class="b-sch-tip-${data.valid ? 'valid drag-valid' : 'invalid drag-invalid'}">
                              ${data.startClockHtml}
                              ${data.endClockHtml}
                              <div class="b-sch-tip-message">${data.message}</div>
                          </div>
                      `
    )
  }
};
export default EventDragConfig;

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

// Containers
import App from "./../containers/App";
import Login from "./../containers/Login";
import ChangePassword from "./../containers/ChangePassword";
import NotFound from "./../containers/NotFound";

// Libs
import AuthRouteLib from "../libs/AuthRouteLib";
import ForgotPassword from "../containers/ForgotPassword";
import ResetPassword from "../containers/ForgotPassword/ResetPassword";


const Routes = () => {
  return (
    <Router>
      <Switch>
        <Redirect from="/" exact to="/app" />
        <AuthRouteLib.showIfNotAuthed path="/login" exact redirectPath="/" component={Login}/>
        <AuthRouteLib.showIfNotAuthed path="/change-password/:employeeCode" exact redirectPath="/" component={ChangePassword}/>
        <AuthRouteLib.showIfNotAuthed path="/forgot-password" exact redirectPath="/" component={ForgotPassword}/>
        <AuthRouteLib.showIfNotAuthed path="/reset-password/:token" exact redirectPath="/" component={(props) => {
            return props.match.params.token ? <ResetPassword/> : <NotFound/>;
        }}/>
        <AuthRouteLib.showIfAuthed path="/app" redirectPath="/login" component={App}/>
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default Routes;

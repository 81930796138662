import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";

// Login validations schema
const loginSchema = Yup.object().shape({
    password: Yup.string()
        .trim()
        .required("Current password is required"),
    newPassword: Yup.string()
        .trim()
        .required('New password is required')
        .test('validation-strength', '', function (value) {
            let message = 'Must contain ';
            if( !value || value.length <6) {
                message += '6 characters, ';
            }
            if(!(/(.*[A-Z].*)$/.test(value))) {
                message += 'one uppercase, ';
            }
            if(!(/(.*[a-z].*)$/.test(value))) {
                message += 'one lowercase, ';
            }
            if(!(/(.*\d.*)$/.test(value))) {
                message += 'one number, ';
            }
            if(!(/(.*[@$!%*?&].*)$/.test(value))) {
                message += 'one special case character (@$!%*?&) ';
            }
            if(message !== 'Must contain '){
                return this.createError({ message, path: 'newPassword' })
            }
            return true;
        })
        .min(6, 'Must contain 6 characters')
        .max(20, 'Must contain up to 20 characters'),
    confirmPassword: Yup.string()
        .trim()
        .required("Confirm password is required")
        .test('passwords-match', 'Passwords must match', function(value) {
            return this.parent.newPassword === value;
        })
});

export default class ProfileChangePasswordForm extends Component {
    render() {
        const initialValues = {
            password: "",
            newPassword: "",
            confirmPassword: "",
        };

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={async (values, {setSubmitting, resetForm}) => {
                    const {confirmPassword, ...data} = values;
                    await this.props.submitProfileChangePassword(data);
                    resetForm(initialValues);
                    setSubmitting(false);
                }}
                enableReinitialize={true}
            >
                {({errors, values, touched, isSubmitting, handleChange, handleSubmit, handleReset, handleBlur}) => (
                    <form id="profileChangePassword" onReset={handleReset} onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="oldPassword">Current password</label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Current password"
                                disabled={isSubmitting}
                            />
                            {errors.password && touched.password && (
                                <div className="input-validation-error text-center py-1">{errors.password}</div>
                            )}
                        </div>
                        <hr/>
                        <p className="mb-2" style={{ textAlign: "left", fontSize:"11px"}}>
                            Your new password must contain:
                            <ol style={{paddingLeft: "20px"}}>
                                <li>Minimum 6 characters</li>
                                <li>Maximum 20 characters</li>
                                <li>At least one uppercase letter</li>
                                <li>At least one lowercase letter</li>
                                <li>At least one number</li>
                                <li>At least one special character (@#$!%*?&)</li>
                            </ol>
                        </p>
                        <div className="form-group">
                            <label htmlFor="newPassword">New password</label>
                            <input
                                type="password"
                                name="newPassword"
                                id="newPassword"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.newPassword}
                                placeholder="New password"
                                disabled={isSubmitting}
                            />
                            {errors.newPassword && touched.newPassword && (
                                <div className="input-validation-error text-center py-1">{errors.newPassword}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPassword">Confirm password</label>
                            <input
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                placeholder="Confirm password"
                                disabled={isSubmitting}
                            />
                            {errors.confirmPassword && touched.confirmPassword && (
                                <div className="input-validation-error text-center py-1">{errors.confirmPassword}</div>
                            )}
                        </div>
                        <button type="submit" className="btn btn-secondary mt-3 mr-3" disabled={isSubmitting}>
                            {isSubmitting ? "Changing..." : "Change password"}
                        </button>
                        <button type="reset" className="btn btn-light mt-3" disabled={isSubmitting}>
                            Clear
                        </button>
                    </form>
                )}
            </Formik>
        );
    }
}

import BaseService from "./BaseService";

class MessagingService extends BaseService {
  fetchAllMessages() {
    return this.apiGet({
      url: "/messages"
    });
  }

  fetchOneMessage(id = -1, params) {
    return this.apiGet({
      url: `/messages/${id}`,
      params
    });
  }

  setNewMessage(data) {
    const messageData = new FormData();
    const { id } = data;
    Object.keys(data).forEach(field => {
      if (field === "attachment") {
        messageData.append("attachment", data[field], data[field].name);
      } else {
        messageData.append(field, data[field]);
      }
    });
    return this.api.post(`/messages/${id}/create`, messageData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
}

export default new MessagingService();

import React, { Component } from "react";
import LocationService from "../../services/LocationService";
import CheckboxTree from "react-checkbox-tree";
import SettingsService from "../../services/SettingsService";
import TimezoneDropdownItem from "../../components/Settings/TimezoneDropdownItem";
import ButtonGroup from "../../components/Buttons/ButtonGroup";

const filters = ["All", "With Timezone", "Without Timezone"];

export default class LocationTimezone extends Component {
  state = {
    locations: [],
    selected: 0,
    tree: [],
    checkedItems: [],
    expanded: [],
    timezoneList: [],
    showLoading: false,
    error: false,
    defaultFilter: filters[2]
  };

  async getLocationTree(filter) {
    const { data } = await LocationService.getLocationTreeByState(filter);

    if (data) {
      this.setState({
        tree: data
      });
    }
  }

  async getLocations() {
    const {
      data: { data }
    } = await LocationService.getLocations();

    if (data) {
      this.setState({
        locations: data
      });
    }
  }

  async getTimezoneList() {
    const { data } = await SettingsService.getTimezoneList();

    if (data) {
      this.setState({
        timezoneList: data
      });
    }
  }

  onCheck = checkedItems => {
    const { locations } = this.state;

    if (checkedItems.length === 1) {
      const timezoneId = locations.find(e => e.id === Number(checkedItems[0]))
        .timezoneId;

      this.setState({
        selected: timezoneId
      });
    } else {
      this.setState({
        selected: 0
      });
    }

    this.setState({
      checkedItems
    });
  };

  onExpand = expanded => {
    this.setState({ expanded });
  };

  onClick = e => {};

  onTimezoneChange = async val => {
    const { checkedItems } = this.state;
    this.setState({
      showLoading: true
    });
    LocationService.changeLocationTimezone(checkedItems, val)
      .then(() => {
        this.setState(
          {
            showLoading: false,
            selected: val,
            checkedItems: []
          },
          () => {
            this.getLocations();
            this.getLocationTree({
              filters: filters.indexOf(this.state.defaultFilter)
            });
          }
        );
      })
      .catch(() => {
        this.setState({
          showLoading: false,
          selected: 0,
          error: true,
          checkedItems: []
        });
      });
  };

  onChangeFilters = async val => {
    this.setState(
      {
        defaultFilter: val,
        checkedItems: []
      },
      () => {
        this.getLocations();
        this.getLocationTree({ filters: filters.indexOf(val) });
      }
    );
  };

  componentDidMount() {
    this.getLocations();
    this.getLocationTree({
      filters: filters.indexOf(this.state.defaultFilter)
    });
    this.getTimezoneList();
  }

  render() {
    const { timezoneList, showLoading, checkedItems, selected } = this.state;

    return (
      <div className="col-12 col-sm-12">
        <div className="row">
          {/* Render tree */}
          <div className="card col-5 my-5 pull-left">
            <div className="card-header">
              <h4>Locations</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <ButtonGroup
                  options={filters}
                  selected={this.state.defaultFilter}
                  onChange={this.onChangeFilters}
                />
                <label className="col-md-12 col-form-label">
                  Select Locations:
                </label>
                <div className="col-md-12">
                  <CheckboxTree
                    nodes={this.state.tree}
                    checked={this.state.checkedItems}
                    expanded={this.state.expanded}
                    onCheck={this.onCheck}
                    onExpand={this.onExpand}
                    onClick={this.onClick}
                  />
                  {this.props.locationError && (
                    <div className="input-validation-error px-3 py-1">
                      {this.props.locationError}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Render timezones dropdown */}
          <div className="col-7 my-5 pull-left">
            <div className="e-panel card">
              <div className="card-header">
                <h4>Set Timezone</h4>
              </div>
              <div className="card-body">
                <TimezoneDropdownItem
                  name="Timezone"
                  description="Select timezone"
                  options={timezoneList}
                  selected={selected}
                  onChange={this.onTimezoneChange}
                  showLoading={showLoading}
                  disabled={checkedItems.length === 0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

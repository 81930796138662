import Modal from "react-modal";
import React from "react";
import '../SchedulePrint/styles/style.css';
import PropTypes from "prop-types";
import customModalStyles from "./customStyle";
import {CodeConfirmationForm} from '../Forms/ForgotPasswordForm';

class ForgotPasswordCodeConfirmModal extends React.Component {
    render() {
        const {
            isOpen,
            closeModal,
            submitVerificationCode,
            email,
            error
        } = this.props;
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customModalStyles}
                contentLabel="Verification Code"
                shouldCloseOnOverlayClick={false}
            >
                <CodeConfirmationForm error={error} email={email} submitVerificationCode={submitVerificationCode} id="shifts-section" title="Verification Code" />
            </Modal>
        )
    }
}
ForgotPasswordCodeConfirmModal.defaultProps = {
    closeModal: () => {},
    isOpen: false,
    onSubmit: () => {},
    onDelete: () => {},
};
ForgotPasswordCodeConfirmModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
export default ForgotPasswordCodeConfirmModal

import Modal from "react-modal";
import React from "react";
import ToggleButton from "react-toggle-button";
import "../SchedulePrint/styles/style.css";
import customModalStyles from "./customStyle";
import ProfileDetails from "../ProfileDetails";
import Avatar from "../Blocks/Avatar";
import Toast from "../Blocks/Toast";
import StringHelper from "../../helpers/StringHelper";
import UserService from "../../services/UserService";
import EmployeeService from "../../services/EmployeeService";
import SettingsService from "../../services/SettingsService";
import SelectSearch from "react-select";
import "./assets/styles/userDetails.css";

class ViewUserDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      error: null,
      success: null,
      userId: 0,
      firstName: "",
      lastName: "",
      jobTitle: "",
      code: "",
      companyName: "",
      workEmailAddress: "",
      personalEmailAddress: "",
      workPhoneNumber: "",
      homePhone: "",
      departmentDescription: "",
      empId: -1,
      isLoading: true,
      supervisorLastName: "",
      supervisorFirstName: "",
      payGroupDescription: "",
      locationDescription: "",
      lastHireDate: "",
      status: 0,
      levels: [],
      user,
      accessLevelId: [],
      originalAccessLevelId: [],
      accessLevelIsUpdating: false,
      salaryHourly: "",
      hourlyRate: "",
      showMyScheduleUnderMyEmployees: false,
      accessLevelIdInput: ''
    };
  }

  fetchPeopleDetails() {
    const { id = -1 } = this.props;

    EmployeeService.fetchOneEmployee(id)
      .then(res => {
        if (res.data) {
          const profile = res.data;
          if (!profile.accessLevelId || profile.accessLevelId === null) {
            profile.accessLevelId = [];
          } else {
            profile.accessLevelId = JSON.parse(profile.accessLevelId);
          }
          this.setState({
            ...profile,
            originalAccessLevelId: profile.accessLevelId,
            isLoading: false
          });
        } else {
          this.setState({
            error: (
              <div>
                <p>404, User not found!</p>
                <button className="btn btn-primary">Go back</button>
              </div>
            ),
            isLoading: false
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          error: (
            <div>
              <p>404, User not found!</p>
              <button className="btn btn-primary">Go back</button>
            </div>
          ),
          isLoading: false
        });
      });
  }

  getAccessLevels() {
    SettingsService.getAccessLevel().then(response => {
      if (response.data) {
        const levels = response.data;
        this.setState({
          levels
        });
      }
    });
  }

  onChangeAccessLevel = event => {
    this.setState({
      accessLevelId: 1 * event.target.value
    });
  };
  updateAccessLevel = () => {
    if (this.state.accessLevelId !== this.state.originalAccessLevelId) {
      this.setState({
        accessLevelIsUpdating: true
      });
      SettingsService.setAccessLevel(
        this.state.userId,
        this.state.accessLevelId
      ).then(res => {
        this.setState({
          originalAccessLevelId: this.state.accessLevelId,
          accessLevelIsUpdating: false,
          success: res.data.message
        });
      });
    }
  };
  onChangeShowMyScheduleUnderMyEmployees = value => {
    this.setState({
      isLoading: true
    })
    EmployeeService.updateShowMyScheduleUnderMyEmployees(this.state.empId, !value)
      .then(res => {
        this.setState({
          showMyScheduleUnderMyEmployees: !value,
          isLoading: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          error: err.response && err.response.message ? err.response.message : err.message,
          isLoading: false
        });
      });

  }
  toastDismiss = () => {
    this.setState({
      error: null,
      success: null
    });
  };
  componentDidMount() {
    const { user } = this.props;
    if (user.isSuperUser) {
      this.getAccessLevels();
    }
    this.fetchPeopleDetails();
  }

  render() {
    const { isOpen, closeModal } = this.props;

    const {
      firstName,
      lastName,
      jobTitle,
      code,
      companyName,
      workEmailAddress,
      personalEmailAddress,
      workPhoneNumber,
      homePhone,
      departmentDescription,
      supervisorLastName,
      supervisorFirstName,
      payGroupDescription,
      locationDescription,
      lastHireDate,
      status,
      isLoading,
      levels,
      user,
      accessLevelId,
      originalAccessLevelId,
      accessLevelIsUpdating,
      error,
      success,
      salaryHourly,
      hourlyRate,
      showMyScheduleUnderMyEmployees,
      accessLevelIdInput
    } = this.state;

    const errorToast = error && (
      <Toast
        title="Employee Error!"
        message={error}
        dismiss={this.toastDismiss}
      />
    );
    const successToast = success && (
      <Toast
        status="success"
        title="Access level was updated successfully."
        message={success}
        dismiss={this.toastDismiss}
      />
    );

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={{ content: { ...customModalStyles.content, maxWidth: '70%' } }}
        contentLabel="User details"
        shouldCloseOnOverlayClick={true}
      >
        {isLoading ? (
          <div className="p-5">
            <div>Loading...</div>
          </div>
        ) : (
            <div className="view-user-details">
              {errorToast}
              {successToast}
              {!error && (
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body box">
                        <Avatar
                          extraClass="mx-auto"
                          item={this.state}
                          size={100}
                        />
                        <h2>
                          {firstName} {lastName}
                          <br />
                          <span>{jobTitle}</span>
                        </h2>
                        {user.isSuperUser ? (
                          <React.Fragment>
                            <div className="row p-3 bg-gray border-white">
                              <div className="print-row">Allow employee to schedule without approving</div>
                              <div className="pull-right">
                                <ToggleButton
                                  thumbStyle={{ borderRadius: 4 }}
                                  trackStyle={{
                                    borderRadius: 4
                                  }}
                                  colors={{
                                    activeThumb: {
                                      base: "#fff"
                                    },
                                    active: {
                                      base: "#61c08d"
                                    }
                                  }}
                                  value={showMyScheduleUnderMyEmployees || false}
                                  onToggle={this.onChangeShowMyScheduleUnderMyEmployees}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <SelectSearch
                                inputValue={accessLevelIdInput}
                                onInputChange={(inputValue, { action }) => {
                                  if (action !== "set-value") {
                                    this.setState({
                                      accessLevelIdInput: inputValue
                                    });
                                    return inputValue;
                                  }
                                  return accessLevelIdInput;
                                }}
                                closeMenuOnSelect={false}
                                options={levels}
                                onChange={(values) => {
                                  this.setState({
                                    accessLevelId: values.map(v => v.id.toString())
                                  })
                                }}
                                isMulti={true}
                                getOptionLabel={(o) => o.name}
                                getOptionValue={(o) => o.id}
                                value={accessLevelId ? levels.filter(l => accessLevelId.includes(l.id.toString())) : []}
                                name="accessLevel"
                                placeholder="Choose an access level"
                              />
                              {/* <select
                                className="form-control form-control-sm"
                                onChange={this.onChangeAccessLevel}
                                value={accessLevelId}
                              >
                                <option value="0">-- No Access Level --</option>
                                {levels.map(l => (
                                  <option key={l.id} value={l.id}>
                                    {l.name}
                                  </option>
                                ))}
                              </select> */}
                              <div className="pull-right mt-3 w-100">
                                <button
                                  disabled={
                                    accessLevelId === originalAccessLevelId ||
                                    accessLevelIsUpdating
                                  }
                                  onClick={this.updateAccessLevel}
                                  type="button"
                                  className="btn btn-info w-100"
                                >
                                  {accessLevelIsUpdating
                                    ? "Updating..."
                                    : "Change Access Level"}
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <ProfileDetails
                      cardHeader="More Details"
                      jobTitle={jobTitle}
                      code={code}
                      companyName={companyName}
                      workEmailAddress={workEmailAddress}
                      personalEmailAddress={personalEmailAddress}
                      workPhoneNumber={workPhoneNumber}
                      homePhone={homePhone}
                      departmentDescription={departmentDescription}
                      supervisorLastName={supervisorLastName}
                      supervisorFirstName={supervisorFirstName}
                      payGroupDescription={payGroupDescription}
                      locationDescription={locationDescription}
                      lastHireDate={lastHireDate}
                      status={StringHelper.statusFormat(status)}
                      salaryHourly={salaryHourly}
                      hourlyRate={hourlyRate}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
      </Modal>
    );
  }
}

export default ViewUserDetailsModal;

import CrudPage from "../../components/CrudPage";
import CrudService from "../../services/CrudService";
const {jobs} = require("./sampleData");
const jobsService = new CrudService("jobs");

export default class Jobs extends CrudPage {
  name = "Job";
  fields = [
    { name: "id", label: "ID", type: "text", showOnForm: false },
    { name: "code", label: "Code", type: "text" },
    { name: "title", label: "Title", type: "text" },
    { name: "country", label: "Country", type: "text" },
    { name: "familyCode", label: "Family Code", type: "text" },
    { name: "isActive", label: "Active", type: "toggle", defaultVal: false }
  ];
  enableImport = true;
  importSampleLink = "https://dxv8p7y79jw20.cloudfront.net/Jobs+Sample.csv";

  sampleData = jobs;
  searchPlaceHolder="Search by title or code...";

  fetch(page, search) {
    return jobsService.list(page, search);
  }

  create(data) {
    return jobsService.create(data);
  }

  update(data) {
    return jobsService.update(data);
  }

  delete(id) {
    return jobsService.remove(id);
  }

  import(file) {
    return jobsService.importSample(file);
  }
}

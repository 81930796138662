import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

// Login validations schema
const Schema = Yup.object().shape({
    password: Yup.string()
        .trim()
        .required('New password is required')
        .test('validation-strength', '', function (value) {
            let message = 'Must contain ';
            if (!value || value.length < 6) {
                message += '6 characters, ';
            }
            if (!(/(.*[A-Z].*)$/.test(value))) {
                message += 'one Uppercase, ';
            }
            if (!(/(.*[a-z].*)$/.test(value))) {
                message += 'one Lowercase, ';
            }
            if (!(/(.*\d.*)$/.test(value))) {
                message += 'one Number, ';
            }
            if (!(/(.*[@$!%*?&].*)$/.test(value))) {
                message += 'one special case Character (@$!%*?&) ';
            }
            if (message !== 'Must contain ') {
                return this.createError({ message, path: 'password' })
            }
            return true;
        })
        .min(6, 'Must contain 6 characters')
        .max(20, 'Must contain up to 20 characters'),
    confirmPassword: Yup.string()
        .trim()
        .required("Confirm password is required")
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value;
        })
});


export default class ResetPasswordForm extends Component {
    render() {
        return (
            <Formik
                initialValues={{
                    password: "",
                    confirmPassword: "",
                }}
                validationSchema={Schema}
                onSubmit={async (values, { setSubmitting }) => {
                    await this.props.submitResetPassword(values);
                    setSubmitting(false);
                }}
            >
                {({ errors, values, touched, isSubmitting, handleChange, handleSubmit, handleBlur }) => {
                    console.log(errors);
                    return (
                        <form id="forgot-reset-password" onSubmit={handleSubmit} className="card-body" tabIndex="500">
                            <h5 className="mb-2">Create your new password</h5>
                            <p className="mb-3">Follow instruction below to create a strong password</p>
                            <div className="form">
                                <hr />
                                <p className="mb-2" style={{ textAlign: "left", fontSize: "11px" }}>
                                    Your new password must contain:
                                <ol style={{ paddingLeft: "20px" }}>
                                        <li>Minimum 6 characters</li>
                                        <li>Maximum 20 characters</li>
                                        <li>At least one uppercase letter</li>
                                        <li>At least one lowercase letter</li>
                                        <li>At least one number</li>
                                        <li>At least one special character (@#$!%*?&)</li>
                                    </ol>
                                </p>
                                <div className="form-group">
                                    <label htmlFor="password">New password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        autoFocus={true}
                                        className="form-control"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        placeholder="New password"
                                        disabled={isSubmitting}
                                    />
                                    {errors.password && touched.password && (
                                        <div className="input-validation-error">{errors.password}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirmPassword">Confirm password</label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        className="form-control"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        placeholder="Confirm password"
                                        disabled={isSubmitting}
                                    />
                                    {errors.confirmPassword && touched.confirmPassword && (
                                        <div className="input-validation-error">{errors.confirmPassword}</div>
                                    )}
                                </div>
                            </div>
                            <div className="submit mt-3 mb-3">
                                <button type="submit" className="btn btn-info btn-block" disabled={isSubmitting}>
                                    {isSubmitting ? "Processing..." : "Submit"}
                                </button>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        );
    }
}

import CrudPage from "../../components/CrudPage";

import CrudService from "../../services/CrudService";
import {locations} from "./sampleData";

const locationsServices = new CrudService("locations");

export default class Locations extends CrudPage {
  name = "Location";
  fields = [
    { name: "id", label: "ID", type: "text", showOnForm: false },
    { name: "code", label: "Code", type: "text" },
    { name: "description", label: "Description", type: "text" },
    { name: "state", label: "State", type: "text" },
    { name: "country", label: "Country", type: "text" },
    { name: "city", label: "City", type: "text" },
    { name: "zip", label: "Zip code", type: "text" },
    { name: "isActive", label: "Active", type: "toggle", defaultVal: false }
  ];
  enableImport = true;
  importSampleLink =
    "https://dxv8p7y79jw20.cloudfront.net/Locations+Sample.csv";

  sampleData = locations;
  searchPlaceHolder="Search by description or code...";

  fetch(page, search) {
    return locationsServices.list(page, search);
  }

  create(data) {
    return locationsServices.create(data);
  }

  update(data) {
    return locationsServices.update(data);
  }

  delete(id) {
    return locationsServices.remove(id);
  }

  import(file) {
    return locationsServices.importSample(file);
  }
}

import React from "react";
import "./assets/styles/Export.css";
import ExcelExporter from "../../components/BryntumScheduler/ExcelExporter";

export default class ExportTable extends React.Component {
  excelExport = () => {
    const { columns, data: rows } = this.props;
    const excelExporter = new ExcelExporter();
    excelExporter.export(
      {
        filename: this.props.filename || "name"
      },
      columns,
      rows
    );
  };
  render() {
    const { icon, disabled = false, onPress } = this.props;
    return (
      <button
        id={this.props.id}
        className={this.props.className}
        type="button"
        onClick={onPress || this.excelExport}
        disabled={disabled}
      >
        {icon && <i className={icon} />}
        {this.props.buttonText}
      </button>
    );
  }
}

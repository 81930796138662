import React from "react";
import moment from "moment";
import { Link, NavLink } from "react-router-dom";
import Collapsible from "react-collapsible";
import weather from "openweather-apis";
import "../../components/FileUpload/index.css";
import CommentForm from "../../components/Forms/CommentForm";
import ReactPlayer from "react-player";
import ImageViewer from "react-images-viewer";

// Services
import PostService from "../../services/PostService";
import DashboardService from "../../services/DashboardService";

// Helpers
import StringHelper from "../../helpers/StringHelper";

// Styles
import "./assets/styles/Stream.css";

// Images
import quarterBg from "./../../general/assets/images/equinox-bldg-darker.jpg";
import devBg from "./../../general/assets/images/devBg1.jpg";
import Axios from "axios";
import Upload from "../../components/Upload";
import OverTime from "../../helpers/OverTime";
import Avatar from "../../components/Blocks/Avatar";
import EmojiPicker from "../../components/EmojiPicker";
import InfiniteScroll from "react-infinite-scroller";

const STREAM_PAGE_MODE = {
  BROWSE: "browse",
  FETCHING: "fetching",
  SAVING: "saving",
  DELETING: "deleting",
  LIKE: "like",
  NO_RESULTS: "no_results",
  WITH_RESULTS: "with_results",
  WITH_ERROR: "with_error"
};

class Stream extends React.Component {
  constructor(props) {
    super(props);
    const { user, messages, notifications } = props;
    this.onUploadFilesChanged = this.onUploadFilesChanged.bind(this);
    this.saveVideoModal = this.saveVideoModal.bind(this);
    this.state = {
      mode: STREAM_PAGE_MODE.BROWSE,
      user,
      post: {
        note: "",
        locations: []
      },
      posts: [],
      error: "",
      locations: [],
      schedules: [],
      picture: null,
      pictureData: null,
      attachment: null,
      attachmentError: null,
      schedulesLoading: true,
      messagesLoading: false,
      weather: {
        inputElement: ""
      },
      hasImage: false,
      hasFile: false,
      reply: {
        note: "",
        postId: 0
      },
      deletePosts: {},
      likePosts: {},
      imageRefs: [],
      myMessages: messages,
      myNotifications: notifications,
      aws_cdn: "https://dxv8p7y79jw20.cloudfront.net/",
      showVideoModal: false,
      videoUrl: "",
      images: [],
      imageViewerOpen: false,
      postPerPage: 2,
      hasMorePosts: true,
      deletedPosts: 0,
    };
  }

  extractLinks(text) {
    return (text || "").replace(
      /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
      (match, space, url) => {
        var hyperlink = url;
        if (!hyperlink.match("^https?://")) {
          hyperlink = "http://" + hyperlink;
        }
        return (
          space +
          `<a rel="noopener noreferrer" target="_blank" href="${hyperlink}">${url}</a>`
        );
      }
    );
  }

  createPost = event => {
    event.preventDefault();
    if (!this.state.post.note) return;

    this.setState({
      mode: STREAM_PAGE_MODE.SAVING
    });
    const post = Object.assign({}, this.state.post);
    const { attachment } = this.state;
    if (attachment) {
      if (attachment.preview.type === "url") {
        post.attachmentUrl = attachment.url;
      } else {
        post.attachment = attachment;
      }
    }
    PostService.createPost(post).then((response) => {
      if (response.data.post) {
        this.setState({
          post: {
            ...this.state.post,
            note: ""
          },
          posts: [response.data.post, ...this.state.posts],
          picture: null,
          hasImage: false,
          hasFile: false,
          mode: STREAM_PAGE_MODE.BROWSE,
          attachment: null,
          attachmentError: null
        });
      }
    });
  };
  getPostsWithPages = (page) => {
    const { postPerPage, deletedPosts } = this.state;
    PostService.getPostsWithPages(page, postPerPage, true, deletedPosts).then(response => {
      if (response.data) {
        const postIds = this.state.posts.map(p => p.id);
        this.setState({
          deletedPosts: 0,
          posts: [...this.state.posts, ...(response.data || []).filter(p => !postIds.includes(p.id))],
          hasMorePosts: response.data.length === postPerPage
        })
      } else {
        this.setState({
          deletedPosts: 0,
          hasMorePosts: false,
        })
      }
    }).catch(e => {
      this.setState({
        deletedPosts: 0,
        hasMorePosts: false,
      })
    });
  };

  saveVideoModal = attachment => {
    this.setState({
      attachment
    });
  };

  onUploadFilesChanged = files => {
    if (files[0]) {
      this.setState({
        attachment: files[0]
      });
    } else {
      this.setState({
        attachment: null
      });
    }
  };

  onChange = event => {
    this.setState({
      post: {
        ...this.state.post,
        note: event.target.value
      }
    });
  };

  onSelectEmoji = ({ emoji }) => {
    this.setState(({ post }) => ({
      post: {
        ...post,
        note: post.note + emoji
      }
    }));
  };

  onChangeLocation = event => {
    this.setState({
      post: {
        ...this.state.post,
        locations:
          event.target.value === "all"
            ? this.state.locations.map(l => l.id)
            : [event.target.value]
      }
    });
  };

  fetchDashboard = () => {
    const startDate = moment().format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    DashboardService.fetchDashboard(startDate, endDate)
      .then(response => {
        const post = {
          note: "",
          locations: response.data.locations.map(l => l.id)
        };
        this.setState({
          schedules: response.data.schedules,
          locations: response.data.locations || [],
          post,
          schedulesLoading: false
        }, () => {
          this.setState({
            post: {
              ...this.state.post,
              locations: this.state.locations.map(l => l.id)
            }
          });
        });
      })
      .catch(err => {
        this.setState({
          schedulesLoading: false
        });
        console.log(err);
      });
  };

  fetchWeather = async () => {
    const { user } = this.state;
    const ot = new OverTime();
    const { data = {} } = await Axios.get("https://ipapi.co/json");
    await weather.setLang("en");
    await weather.setCity(data.city);
    await weather.setUnits(
      ot.isOnCanada(user.locationCode) || user.locationCode === "985"
        ? "metric"
        : "imperial"
    );
    await weather.setAPPID("6a6342c2b24512a57fbfa77512bb5de5");

    weather.getAllWeather((err, json) => {
      const retrievedWeather =
        json.weather && json.weather.length > 0
          ? json.weather[0]
          : {
            description: "",
            icon: "",
            id: 0,
            main: ""
          };
      this.setState({
        weather: {
          ...json.main,
          ...retrievedWeather
        }
      });
    });
  };

  componentDidMount = () => {
    this.fetchDashboard();
    this.fetchWeather();
  };

  openComments = postId => {
    this.refs[`reply-post-${postId}`].classList.toggle("hidden");
  };
  deletePost = (postId, parentPostId = 0) => {
    const { deletePosts } = this.state;
    deletePosts[postId] = true;
    this.setState({
      mode: STREAM_PAGE_MODE.DELETING,
      deletePosts
    });
    PostService.deletePost(postId).then(() => {
      const posts = !parentPostId ? this.state.posts.filter(p => p.id !== postId) : this.state.posts.map(p => {
        if (p.id === parentPostId) {
          p.reply = (p.reply || []).filter(r => r.id !== postId);
          p.comments -= 1;
        }
        return p;
      });
      delete deletePosts[postId];
      this.setState({
        posts,
        deletedPosts: this.state.deletedPosts + (!parentPostId ? 1 : 0),
        deletePosts
      })
    });
  };
  postReply = fields => {
    const post = fields;
    const { attachment } = fields;
    this.setState({
      mode: STREAM_PAGE_MODE.SAVING
    });
    if (attachment) {
      if (attachment.preview.type === "url") {
        post.attachmentUrl = attachment.url;
        delete post.attachment;
      } else {
        post.attachment = attachment;
      }
    } else {
      delete post.attachment;
    }
    PostService.replyToComment(post).then((response) => {
      if (response.data.post) {
        const posts = this.state.posts.map(p => {
          if (p.id === post.postId) {
            p.reply = p.reply || [];
            p.reply.push(response.data.post);
            p.comments += 1;
          }
          return p;
        })
        this.setState({
          posts,
          mode: STREAM_PAGE_MODE.BROWSE
        });
      }
    });
  };
  unLikeComment = (postId, parentPostId = 0) => {
    const { likePosts } = this.state;
    likePosts[postId] = true;
    this.setState({
      mode: STREAM_PAGE_MODE.LIKE,
      likePosts
    });
    PostService.unLikeComment(postId).then(() => {
      const posts = this.state.posts.map(p => {
        if (parentPostId) {
          if (p.id === parentPostId) {
            p.reply = (p.reply || []).map(r => {
              if (r.id === postId) {
                r.liked = false;
                r.likes -= 1;
              }
              return r;
            })
          }
        } else if (p.id === postId) {
          p.liked = false;
          p.likes -= 1;
        }
        return p;
      });
      delete likePosts[postId];
      this.setState({
        posts,
        mode: STREAM_PAGE_MODE.BROWSE,
        likePosts
      })
    });
  };
  likeComment = (postId, parentPostId = 0) => {
    const { likePosts } = this.state;
    likePosts[postId] = true;
    this.setState({
      mode: STREAM_PAGE_MODE.LIKE,
      likePosts
    });
    PostService.likeComment(postId).then(() => {
      const posts = this.state.posts.map(p => {
        if (parentPostId) {
          if (p.id === parentPostId) {
            p.reply = (p.reply || []).map(r => {
              if (r.id === postId) {
                r.liked = true;
                r.likes += 1;
              }
              return r;
            })
          }
        } else if (p.id === postId) {
          p.liked = true;
          p.likes += 1;
        }
        return p;
      });
      delete likePosts[postId];
      this.setState({
        posts,
        mode: STREAM_PAGE_MODE.BROWSE,
        likePosts
      })
    });
  };
  render() {
    const {
      weather,
      myMessages,
      attachment,
      myNotifications,
      images,
      imageViewerOpen
    } = this.state;

    const isEquinox = window.location.host === "equinox.getarcon.com";

    const topWidgetBg = isEquinox ? quarterBg : devBg;

    return (
      <>
        <div
          className={`container home-top-widget ${isEquinox ? "equinox" : ""}`}
          style={{
            backgroundImage: `url(${topWidgetBg})`
          }}
        >
          <div className="background-overlay" />

          <div className="text">
            <div className="greeting">
              {`Welcome, ${this.state.user.firstName || ""} ${this.state.user
                .lastName || ""}`}
            </div>

            <div className="date">
              {moment(new Date()).format("dddd, DD MMMM")}
            </div>
          </div>

          <div className="weather">
            {weather.main === "Clear" && <i className="fas fa-sun" />}
            {weather.main === "Clouds" && <i className="fas fa-cloud" />}
            {weather.main === "Rain" && <i className="fas fa-cloud-rain" />}
            {weather.main === "Snow" && <i className="fas fa-cloud-snow" />}
            {weather.temp !== undefined && (
              <div className="temp">
                {Math.round(weather.temp)}
                <sup>o</sup>
              </div>
            )}
          </div>
        </div>

        <div className="container content-area">
          <section className="section">
            <div className="row pt-2">
              <div className="col-lg-12 col-xl-8 col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-body p-4">
                    <form onSubmit={this.createPost}>
                      <div style={{ position: "relative" }}>
                        <textarea
                          value={this.state.post.note}
                          className="form-control pt-2"
                          placeholder="What's on your mind?"
                          rows={5}
                          onChange={this.onChange}
                        />
                        <EmojiPicker onSelectEmoji={this.onSelectEmoji} />
                      </div>
                      <div className="create-post-actions">
                        <div>
                          <Upload
                            attachment={attachment}
                            onUploadFilesChanged={this.onUploadFilesChanged}
                            saveVideoModal={this.saveVideoModal}
                            removeAttachment={this.removeAttachment}
                          />
                        </div>

                        <div className="last">
                          <select
                            className="form-control form-control-sm stream-dropdown"
                            style={{ height: "33px" }}
                            onChange={this.onChangeLocation}
                            disabled={this.state.locations.length === 1}
                          >
                            {this.state.locations.length > 1 && (
                              <option value="all">All locations</option>
                            )}
                            {this.state.locations.map(location => (
                              <option key={location.id} value={location.id}>
                                {location.description}
                              </option>
                            ))}
                          </select>

                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            style={{
                              marginLeft: "10px",
                              display: "flex",
                              flexFlow: "row nowrap",
                              alignItems: "center"
                            }}
                            disabled={
                              this.state.mode === STREAM_PAGE_MODE.SAVING
                            }
                          >
                            {this.state.mode === STREAM_PAGE_MODE.SAVING ? (
                              <>
                                <i className="fas fa-spinner fa-spin" />
                                &ensp; Sharing...
                              </>
                            ) : (
                                <>
                                  <i
                                    style={{ marginRight: 5 }}
                                    className="fas fa-share ml-1"
                                  />
                                Share
                              </>
                              )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row">
                  <InfiniteScroll
                    className="col-12 mb-3"
                    pageStart={-1}
                    loadMore={this.getPostsWithPages}
                    hasMore={this.state.hasMorePosts}
                    loader={
                      <h6 className="text-center mt-3">
                        <div style={{ padding: '20px', height: '150px', position: 'inherit' }} id="spinner"> Loading more posts... </div>
                      </h6>
                    }
                  >
                    <ul className="timelineleft mb-5">
                      {this.renderPosts()}
                    </ul>
                  </InfiniteScroll>
                </div>
              </div>

              <div className="col-lg-12 col-xl-4 col-md-12 col-sm-12">
                {this.state.user.role === "manager" ? (
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Today's Schedule</h4>
                    </div>
                    <div className="card-body pt-1 pb-3">
                      {this.state.schedules.map((schedule, key) => (
                        <div
                          className="list d-flex align-items-center border-bottom py-3"
                          key={key}
                        >
                          <Avatar item={schedule} />

                          <div className="wrapper w-100 ml-3">
                            <p className="mb-0">
                              {schedule.firstName} {schedule.lastName} (
                              {schedule.duration.hrs}h
                              {` ${
                                schedule.duration.min > 0
                                  ? schedule.duration.min + "min"
                                  : ""
                                }`}
                              )
                            </p>
                            <div className="mb-0">
                              <Collapsible
                                trigger={
                                  schedule.events.length && (
                                    <span>
                                      Schedules ({schedule.events.length})
                                      <span className="caret ml-1" />
                                    </span>
                                  )
                                }
                              >
                                {schedule.events.map((event, key) => (
                                  <div key={key}>
                                    {event.name}{" "}
                                    {StringHelper.getTime(event.startDate)} -{" "}
                                    {StringHelper.getTime(event.endDate)}
                                    {event.breaks && event.breaks > 0
                                      ? ` • Break ${event.breaks} min `
                                      : " "}
                                    {["Offsite", "Other"].includes(event.name) ? (
                                      <span>
                                        <i
                                          className="fas fa-info-circle color-info"
                                          title={event.notes}
                                        />{" "}
                                      </span>
                                    ) : null}
                                  </div>
                                ))}
                              </Collapsible>
                            </div>
                          </div>
                        </div>
                      ))}
                      {this.state.schedulesLoading && (
                        <p className="text-center font-bold">Loading...</p>
                      )}
                      {this.state.schedules.length > 0 ? (
                        <div className="list d-flex justify-content-center align-items-center pt-3">
                          <Link to="/app/schedule?tab=my-employees">
                            View all
                          </Link>
                        </div>
                      ) : (
                          !this.state.schedulesLoading && (
                            <div className="list d-flex">
                              No schedule for today
                            </div>
                          )
                        )}
                    </div>
                  </div>
                ) : this.state.user.role ? (
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">My Week Schedule</h4>
                    </div>
                    <div className="card-body pt-1 pb-3">
                      {this.state.schedules.map((schedule, key) => (
                        <div
                          className="list d-flex align-items-center border-bottom py-3"
                          key={key}
                        >
                          <div className="wrapper w-100 ml-3">
                            <p className="mb-0">
                              {schedule.day} ({schedule.duration.hrs}h
                              {` ${
                                schedule.duration.min > 0
                                  ? schedule.duration.min + "min"
                                  : ""
                                }`}
                              )
                            </p>
                            <div className="mb-0">
                              <Collapsible
                                trigger={
                                  schedule.events.length && (
                                    <span>
                                      Schedules ({schedule.events.length} ){" "}
                                      <span className="caret" />
                                    </span>
                                  )
                                }
                              >
                                {schedule.events.map((event, key) => (
                                  <div key={key}>
                                    {event.name}{" "}
                                    {StringHelper.getTime(event.startDate)} -{" "}
                                    {StringHelper.getTime(event.endDate)}
                                    {event.breaks && event.breaks > 0
                                      ? ` • Break ${event.breaks} min `
                                      : " "}
                                    {["Offsite", "Other"].includes(event.name) ? (
                                      <span>
                                        <i
                                          className="fas fa-info-circle color-info"
                                          title={event.notes}
                                        />{" "}
                                      </span>
                                    ) : null}
                                  </div>
                                ))}
                              </Collapsible>
                            </div>
                          </div>
                        </div>
                      ))}
                      {this.state.schedulesLoading && (
                        <p className="text-center font-bold">Loading...</p>
                      )}
                      {this.state.schedules.length > 0 ? (
                        <div className="list d-flex justify-content-center align-items-center pt-3">
                          <Link to="/app/schedule">View all</Link>
                        </div>
                      ) : (
                          !this.state.schedulesLoading && (
                            <div className="list d-flex">
                              No schedule for this week
                            </div>
                          )
                        )}
                    </div>
                  </div>
                ) : null}
                <div className="card widget-stream">
                  <div className="card-header">
                    <h4 className="card-title">Messages</h4>
                    <NavLink
                      to="/app/compose-new-message"
                      className="btn btn-primary btn-sm ml-3 compose-btn"
                    >
                      <i className="fas fa-pen" /> Compose
                    </NavLink>
                  </div>
                  <div className="card-body pt-1 pb-3">
                    {myMessages.map((msg, key) => {
                      return (
                        <Link
                          to={`/app/messages/${msg.id}`}
                          key={key}
                          className="list d-flex w-100 align-items-center justify-content-start py-3 h-msg-link"
                        >
                          <div className="col-2 p-0">
                            <Avatar item={msg} />
                          </div>

                          <div className="d-flex flex-column col-10 p-0 ml-2 pr-3">
                            <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                              <b>
                                Message from {msg.firstName} {msg.lastName}
                              </b>

                              <small className="text-muted text-right">
                                <i className="far fa-clock mr-1" />
                                {moment(msg.createdAt).fromNow(true)}
                              </small>
                            </div>

                            <div className="flex-1 ellipsis">{msg.body}</div>
                          </div>
                        </Link>
                      );
                    })}

                    {this.state.messagesLoading && (
                      <p className="text-center font-bold">Loading...</p>
                    )}
                    {myMessages.length > 0 ? (
                      <div className="list d-flex justify-content-center align-items-center pt-3">
                        <Link to="/app/messages">View all</Link>
                      </div>
                    ) : (
                        !this.state.messagesLoading && (
                          <div className="list d-flex ml-4">No message to display</div>
                        )
                      )}
                  </div>
                </div>
                <div className="card widget-stream">
                  <div className="card-header">
                    <h4 className="card-title">Notifications</h4>
                  </div>
                  <div className="card-body pt-1 pb-3">
                    {myNotifications.map((notification, key) => (
                      <Link
                        to={
                          notification.object === "leave" &&
                            notification.type !== "leave-delete"
                            ? `/app/leaves${
                            ["leave-submit", "leave-update"].includes(
                              notification.type
                            )
                              ? "/approve"
                              : ""
                            }`
                            : notification.object === "schedule" &&
                              ["release", "pick-up"].includes(notification.type)
                              ? "/app/notifications/coverage-notifications"
                              : `/app/notification/${notification.id}`
                        }
                        key={key}
                        className="list d-flex align-items-center py-3 h-msg-link"
                      >
                        <div className="wrapper w-100 d-flex flex-column">
                          <p className="mb-0">{notification.body}</p>
                          <small className="text-muted">
                            <i className="far fa-clock mr-1" />
                            {moment(notification.createdAt).fromNow()}
                          </small>
                        </div>
                      </Link>
                    ))}

                    {this.state.messagesLoading && (
                      <p className="text-center font-bold">Loading...</p>
                    )}
                    {myNotifications.length > 0 ? (
                      <div className="list d-flex justify-content-center align-items-center pt-3">
                        <Link to="/app/notifications/all">View all</Link>
                      </div>
                    ) : (
                        !this.state.messagesLoading && (
                          <div className="list d-flex ml-4">
                            No notification to display
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ImageViewer
            closeBtnTitle="Close"
            imgs={images}
            isOpen={imageViewerOpen}
            onClose={() => this.setState({ imageViewerOpen: false })}
          />
        </div>
      </>
    );
  }
  renderPosts() {
    const {
      aws_cdn,
      user,
      posts
    } = this.state;
    return posts.map(post => {
      const attachment = this.getPostAttachment(post, aws_cdn);
      return (
        <li key={post.id}>
          {attachment.icon}
          <div className="timelineleft-item">
            <span className="time">
              <i className="far fa-clock" />
              &ensp;
              {moment(post.createdOn).fromNow()}
            </span>
            <h3 className="timelineleft-header">
              <div style={{ height: '50px' }}>
                <Avatar extraClass="float-left mr-2" item={post} />
                <div style={{ paddingTop: '5px' }}>{post.firstName} {post.lastName}</div>
                <div className="text-muted mt-2" style={{ fontSize: '12px' }}>{post.location || ''}</div>
              </div>
            </h3>
            <div className="timelineleft-body">
              <p
                dangerouslySetInnerHTML={{
                  __html: this.extractLinks(post.note)
                }}
              />
              {attachment.body}
            </div>
            <div className="timelineleft-footer">
              {post.liked ? (
                <button
                  className="btn-link btn-sm btn-link-active mr-1"
                  onClick={this.unLikeComment.bind(
                    this,
                    post.id,
                    0
                  )}
                  disabled={this.state.likePosts[post.id]}
                >
                  {this.state.likePosts[post.id] ? (
                    <span>
                      <i className="fas fa-spinner fa-spin" />{" "}
                    </span>
                  ) : (
                      <i className="fas fa-thumbs-up mr-1" />
                    )}
                  {post.likes}
                </button>
              ) : (
                  <button
                    className="btn-link btn-sm mr-1"
                    disabled={this.state.likePosts[post.id]}
                    onClick={this.likeComment.bind(this, post.id, 0)}
                  >
                    {this.state.likePosts[post.id] ? (
                      <span>
                        <i className="fas fa-spinner fa-spin" />{" "}
                      </span>
                    ) : (
                        <i className="fas fa-thumbs-up mr-1" />
                      )}
                    {post.likes}

                  </button>
                )}
              <button
                onClick={this.openComments.bind(this, post.id)}
                className="btn-link"
              >
                <i className="fas fa-comment-dots" />{" "}
                {post.comments}
              </button>

              {(user.id === post.userId ||
                user.role === "manager") && (
                  <button
                    onClick={this.deletePost.bind(this, post.id, 0)}
                    className="btn-link"
                    style={{ float: "right" }}
                    disabled={this.state.deletePosts[post.id]}
                  >
                    {this.state.deletePosts[post.id] ? (
                      <span>
                        <i className="fas fa-spinner fa-spin" />{" "}
                                      Deleting...
                      </span>
                    ) : (
                        <i className="fas fa-trash" />
                      )}
                  </button>
                )}

              <div
                ref={"reply-post-" + post.id}
                className="col-md-12 commentsblock mt-2 pb-2 hidden"
              >
                {post.reply &&
                  post.reply.map((r, key) => {
                    const replyAttachment = this.getPostAttachment(r, aws_cdn);
                    return (
                      <div className="media" key={key}>
                        <div className="media-left">
                          <Avatar item={r} />
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">
                            {r.firstName} {r.lastName}
                          </h4>
                          <p
                            className="text-break"
                            dangerouslySetInnerHTML={{
                              __html: this.extractLinks(r.note)
                            }}
                          ></p>
                          {r.attachment && r.attachment.length ? (
                            <p className="post-attachment">
                              {replyAttachment.body}
                            </p>
                          ) : null}
                          <ul className="nav nav-pills pull-left">
                            <li className="post-likes">
                              {r.liked ? (
                                <button
                                  className="btn-link btn-sm btn-link-active mr-1"
                                  onClick={this.unLikeComment.bind(
                                    this,
                                    r.id,
                                    post.id
                                  )}
                                  disabled={this.state.likePosts[r.id]}
                                >
                                  {this.state.likePosts[r.id] ? (
                                    <span>
                                      <i className="fas fa-spinner fa-spin" />{" "}
                                    </span>
                                  ) : (
                                      <i className="fas fa-thumbs-up mr-1" />
                                    )}
                                  {r.likes}
                                </button>
                              ) : (
                                  <button
                                    className="btn-link btn-sm mr-1"
                                    onClick={this.likeComment.bind(
                                      this,
                                      r.id,
                                      post.id
                                    )}
                                    disabled={this.state.likePosts[r.id]}
                                  >
                                    {this.state.likePosts[r.id] ? (
                                      <span>
                                        <i className="fas fa-spinner fa-spin" />{" "}
                                      </span>
                                    ) : (
                                        <i className="fas fa-thumbs-up mr-1" />
                                      )}
                                    {r.likes}
                                  </button>
                                )}
                            </li>
                            <li
                              className="post-likes"
                              style={{
                                // float: "right",
                                marginTop: "3px"
                              }}
                            >
                              {(user.id === r.userId ||
                                user.role === "manager") && (
                                  <button
                                    onClick={this.deletePost.bind(
                                      this,
                                      r.id,
                                      post.id
                                    )}
                                    className="btn-link"
                                    disabled={
                                      this.state.deletePosts[r.id]
                                    }
                                  >
                                    {this.state.deletePosts[r.id] ? (
                                      <span>
                                        <i className="fas fa-spinner fa-spin" />{" "}
                                                    Deleting...
                                      </span>
                                    ) : (
                                        <i className="fas fa-trash" />
                                      )}
                                  </button>
                                )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    )
                  })}
                {/* <div className="status-upload mt-2 mb-3 d-inline-block w-100"> */}
                <CommentForm
                  streamPageMode={STREAM_PAGE_MODE}
                  onFormSubmit={this.postReply}
                  mode={this.state.mode}
                  note={""}
                  postId={post.id}
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </li>
      )
    })
  }
  getPostAttachment(post, aws_cdn) {
    let icon = <i className="fas bg-primary fa-envelope" />;
    let body = null;
    const icons = {
      video: <i className="fas bg-primary fa-video" />,
      image: <i className="fas bg-primary fa-image" />,
      pdf: <i className="fas bg-primary fa-file-pdf" />,
      excel: <i className="fas bg-primary fa-file-excel" />,
      word: <i className="fas bg-primary fa-file-word" />
    }
    if (post.attachment && post.attachment.length) {
      body = post.attachment.map((a, key) => {
        let attachment = "";
        if (a.type === "image") {
          if (key === 0) icon = icons.image;
          attachment = (
            <img
              onClick={() => {
                this.setState({
                  images: [
                    { src: `${aws_cdn}${a.path}` }
                  ],
                  imageViewerOpen: true
                });
              }}
              key={key}
              src={`${aws_cdn}${a.path}`}
              className="attachment-image"
              alt={`${post.firstName} ${post.lastName} attachment`}
            />
          );
        } else if (a.type === "video") {
          if (key === 0) icon = icons.video;
          attachment = (
            <ReactPlayer
              key={key}
              controls
              width={"100%"}
              url={`${aws_cdn}${a.path}`}
            />
          );
        } else if (a.type === "video/link") {
          if (key === 0) icon = icons.video;
          attachment = (
            <ReactPlayer
              key={key}
              controls
              width={"100%"}
              url={a.path}
            />
          );
        } else if (a.type === "file/pdf") {
          if (key === 0) icon = icons.pdf;
          attachment = (
            <div key={key}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={key}
                href={`${aws_cdn}${a.path}`}
              >
                <span className="fileIcon color-red mr-2">
                  <i className="fas fa-file-pdf" />
                </span>
                <span className="fileName">
                  {" "}
                  {a.name}
                </span>
              </a>
            </div>
          );
        } else if (a.type === "file/word") {
          if (key === 0) icon = icons.word;
          attachment = (
            <div key={key}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={key}
                href={`${aws_cdn}${a.path}`}
              >
                <span className="fileIcon color-blue mr-2">
                  <i className="fas fa-file-word" />
                </span>
                <span className="fileName">
                  {" "}
                  {a.name}
                </span>
              </a>
            </div>
          );
        } else if (a.type === "file/excel") {
          if (key === 0) icon = icons.excel;
          attachment = (
            <div key={key}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={key}
                href={`${aws_cdn}${a.path}`}
              >
                <span className="fileIcon color-green mr-2">
                  <i className="fas fa-file-excel" />
                </span>
                <span className="fileName">
                  {" "}
                  {a.name}
                </span>
              </a>
            </div>
          );
        }
        return attachment;
      })
    }
    return { icon, body }
  }
  removeAttachment = () => {
    this.setState({
      attachment: null
    });
  };
}

Stream.propTypes = {};

export default Stream;

import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorker from './serviceWorker';

import 'react-dates/initialize';
import "./general/assets/styles/global.css";
if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function(registration) {
            console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function(err) {
            console.log("Service worker registration failed, error:", err);
        });
}


Sentry.init({
    dsn: "https://9b75043c521048ecb9ec55ea95ec243a@o376763.ingest.sentry.io/5592346",
    autoSessionTracking: true,
    integrations: [
        new Integrations.BrowserTracing(),
    ]
});

ReactDOM.render(<Routes />, document.getElementById('root'));

serviceWorker.unregister();

import {
  SCHEDULE_MODES,
  TABS,
  MYSQL_DATETIME_FORMAT,
} from "../../containers/Schedule/Constant";
import EventNotification from "../Modals/EventNotification";
import PrintModal from "../Modals/PrintModal";
import moment from "moment";
import ProfileModal from "../Modals/ProfileModal";
import React from "react";
import CopyModal from "./modals/CopyModal";
import FiltersModal from "./modals/FiltersModal";
import {
  fetchMonthlySchedules,
  fetchSchedules,
  getMonthlySchedules,
  getSchedulesForExport,
} from "./helpers/R_API";
import { deleteNotification, saveNotification, saveFilter, deleteFilter } from "./helpers/CUD_API";
import {
  calculateEstimation,
  calculateMyEstimation,
  handleMultiSelect,
  startCopyEvents,
  startCopyMonthlyEvent,
  canModifyShift,
  isManager,
  refreshSchedules,
  selectMyFilter,
} from "./helpers/SchedulerHelper";
import ScheduleService from "../../services/ScheduleService";
import { onDateChange } from "./helpers/DateHelper";
const getResourcesFromEvents = (events) => {
  return events.map((ev) => {
    return {
      hourlyRate: ev.hourlyRate,
      id: ev.resourceId,
      jobCode: ev.jobCode,
      jobId: ev.jobId,
      location: ev.locationName,
      locationCode: ev.locationCode,
      locationId: ev.locationId,
      name: `${ev.firstName} ${ev.lastName}`,
      role: ev.jobName,
      salaryHourly: ev.salaryHourly,
    };
  });
};
function closeModal(callback) {
  this.setState({
    filterModal: false,
    showEmployeeProfileModal: false,
    employeeCode: null,
    copyModal: false,
    selectedEvents: [],
    selectedResources: [],
    copyToResources: [],
    selectedDays: {},
    isCopying: false,
    endCopy: false,
    completePercentage: 0,
    copyDate: null,
    copyError: null,
    printModal: false,
    eventId: 0,
    eventNotification: {
      rules: { enabled: true, minsBefore: 30 },
      viaEmail: 1,
      viaSms: 0,
      viaWeb: 0,
      viaPush: 0,
    },
    openEventModal: false,
  }, () => {
    if (callback) {
      callback()
    }
  });
  if (this.refs.scheduler && this.refs.scheduler.schedulerEngine) {
    this.refs.scheduler.schedulerEngine.clearEventSelection();
  } else if (
    this.refs.calendarView &&
    this.refs.calendarView.setSelectedEvents
  ) {
    this.refs.calendarView.setSelectedEvents([]);
  }
}

function clearFilters(force = false) {
  this.call(closeModal);
  this.setState(
    {
      isResenting: true,
      selectedZones: [],
      selectedZonesLength: 0,
      selectedRoles: [],
      selectedRolesLength: 0,
      selectedLocations: [],
      selectedTreeLocations: [],
      checked: [],
      expanded: [],
      selectedLocationsLength: 0,
      selectedEventNames: [],
      selectedEventsLength: 0,
      selectedDepartments: [],
      selectedDepartmentsLength: 0,
      searchName: "",
      selectedFilterId: '',
    },
    () => {
      this.setState({ isResenting: false });
      if (this.state.viewIsFiltered || force) {
        this.call(refreshSchedules, !force);
      }
    }
  );
}
function searchOnScheduler(state) {
  if (state) {
    this.setState(state, () => {
      if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
        this.call(fetchSchedules, true);
      } else {
        this.call(fetchMonthlySchedules, true);
      }
    });
  } else {
    if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
      this.call(fetchSchedules, true);
    } else {
      this.call(fetchMonthlySchedules, true);
    }
  }

  this.call(closeModal);
}

function onCheck(checked) {
  const selectedLocations = checked.map((l) => parseInt(l, 10));
  this.setState({
    checked,
    selectedLocationsLength: checked.length,
    selectedTreeLocations: selectedLocations,
  });
}

function onExpand(expanded) {
  this.setState({ expanded });
}

function openModal(modalName) {
  this.setState({
    [modalName]: true,
  });
}

async function copyView() {
  const events = (this.state.activeTab === TABS.MY_SHIFT
    ? this.state.selfEvents
    : this.state.events
  ).filter((ev) =>
    canModifyShift(ev, this.state.activeTab, isManager(this.state.user))
  );
  if (events.length) {
    this.call(
      handleMultiSelect,
      events,
      this.state.activeTab === TABS.MY_SHIFT
    );
    this.call(openModal, "copyModal");
  }
}

function onUndoLastCopy() {
  const property = [
    this.state.activeTab === TABS.MY_SHIFT ? "selfEvents" : "events",
  ];
  const { lastCopiedEvents } = this.state;
  if (lastCopiedEvents.length) {
    const ids = lastCopiedEvents.map((e) => e.id);
    this.setState({
      isLoading: true,
    });
    ScheduleService.deleteSchedules(ids)
      .then(async () => {
        const events = (this.state[property] || []).filter(
          (event) => !ids.includes(event.id)
        );
        this.setState(
          {
            [property]: events,
            available: this.state.available.filter(
              (event) => !ids.includes(event.id)
            ),
            lastCopiedEvents: [],
            eventsVersion: this.state.eventsVersion + 1,
            resourcesVersion: this.state.resourcesVersion + 1,
            availableEventsVersion: this.state.availableEventsVersion + 1,
            isLoading: false,
          },
          async () => {
            if (this.state.activeTab === TABS.MY_EMPLOYEES) {
              await this.call(calculateEstimation);
            } else if (this.state.activeTab === TABS.MY_SHIFT) {
              await this.call(calculateMyEstimation);
            }
          }
        );
      })
      .catch(() => {
        this.setState({
          lastCopiedEvents: [],
          eventsVersion: this.state.eventsVersion + 1,
          resourcesVersion: this.state.resourcesVersion + 1,
          availableEventsVersion: this.state.availableEventsVersion + 1,
          isLoading: false,
        });
      });
  }
}

function renderModals() {
  const {
    activeTab,
    mode,
    selfEvents,
    selfResource,
    events,
    resources,
    config,
    employeeCode,
    showEmployeeProfileModal,
    selectedResources,
    copyToResources,
    selectedDays,
    copyError,
    filterModal,
    zones,
    roles,
    departments,
    eventNames,
    isResenting,
    displayLocationTree,
    showTree,
    locationTree,
    selectedLocations,
    locations,
    selectedLocationsLength,
    selectedZones,
    selectedZonesLength,
    selectedRoles,
    selectedRolesLength,
    selectedDepartments,
    selectedDepartmentsLength,
    selectedEventNames,
    selectedEventsLength,
    checked,
    expanded,
    endCopy,
    isCopying,
    completePercentage,
    selectedEvents,
    scheduleConfigs,
    user,
    selectedFilterId,
    selectedTreeLocations
  } = this.state;
  let printEvents = [];
  let printResources = [];
  if (this.state.activeTab === TABS.MY_EMPLOYEES) {
    printEvents = events;
    const resourceIds = events.map((e) => e.resourceId);
    printResources = resources.filter((r) => resourceIds.includes(r.id));
  } else {
    printEvents = selfEvents;
    printResources = selfResource;
  }
  if (this.state.mode === SCHEDULE_MODES.THIS_MONTH) {
    printEvents = printEvents.filter((ev) => ev.id !== -1);
    printResources = getResourcesFromEvents(printEvents);
  }
  let filter = null;
  if (selectedFilterId !== '') {
    filter = (scheduleConfigs.filters || []).find(f => f.id === parseInt(selectedFilterId));
  }
  return (
    <React.Fragment>
      {this.state.openEventModal && (
        <EventNotification
          isOpen={this.state.openEventModal}
          eventNotification={this.state.eventNotification}
          closeModal={() => {
            this.call(closeModal);
          }}
          onSubmit={(notification) => this.call(saveNotification, notification)}
          onDelete={(notification) =>
            this.call(deleteNotification, notification)
          }
        />
      )}
      {this.state.printModal && (
        <PrintModal
          getEvents={async (range) => {
            if (
              range === "all" &&
              (activeTab !== TABS.MY_SHIFT ||
                mode === SCHEDULE_MODES.THIS_MONTH)
            ) {
              const events = await (activeTab === TABS.MY_SHIFT
                ? this.call(getMonthlySchedules, true)
                : mode === SCHEDULE_MODES.THIS_MONTH
                  ? this.call(fetchMonthlySchedules, false, true)
                  : this.call(getSchedulesForExport, "employees"));
              const resources = getResourcesFromEvents(events);
              return {
                events,
                resources,
              };
            } else {
              return {
                events: printEvents,
                resources: printResources,
              };
            }
          }}
          isOpen={this.state.printModal}
          isGenerated={false}
          closeModal={() => {
            this.call(closeModal);
          }}
          activeTab={activeTab}
          startDate={moment(config.startDate).format(
            `${MYSQL_DATETIME_FORMAT}:00`
          )}
          endDate={moment(config.endDate).format(`${MYSQL_DATETIME_FORMAT}:00`)}
          // events={printEvents}
          resources={printResources}
          mode={mode}
          scheduleConfigs={scheduleConfigs}
          user={user}
        />
      )}
      <ProfileModal
        isOpen={showEmployeeProfileModal && employeeCode !== null}
        employeeCode={employeeCode ? employeeCode : ""}
        closeModal={() => {
          this.call(closeModal);
        }}
      />
      {filterModal && (
        <FiltersModal
          isOpen={filterModal}
          closeModal={() => {
            this.call(closeModal);
          }}
          displayTree={(value) => {
            this.setState({
              showTree: !value,
            });
          }}
          zones={zones}
          roles={roles}
          isResenting={isResenting}
          departments={departments}
          eventNames={eventNames}
          clearFilters={() => this.call(clearFilters)}
          searchOnScheduler={(state) => this.call(searchOnScheduler, state)}
          displayLocationTree={displayLocationTree}
          showTree={showTree}
          locationTree={locationTree}
          checked={checked}
          expanded={expanded}
          selectedLocations={selectedLocations}
          locations={locations}
          selectedLocationsLength={selectedLocationsLength}
          selectedTreeLocations={selectedTreeLocations}
          selectedRoles={selectedRoles}
          selectedRolesLength={selectedRolesLength}
          selectedZones={selectedZones}
          selectedZonesLength={selectedZonesLength}
          selectedDepartments={selectedDepartments}
          selectedDepartmentsLength={selectedDepartmentsLength}
          selectedEventNames={selectedEventNames}
          selectedEventsLength={selectedEventsLength}
          filter={filter}
          saveFilter={(state, name, id) => {
            this.setState(state, () => {
              this.call(saveFilter, name, id, searchOnScheduler)
            })

          }}
          deleteFilter={(id) => this.call(deleteFilter, id, () => {
            this.call(closeModal, () => {
              this.call(selectMyFilter, { target: { value: null } })
            });
          })}
        />
      )}
      {this.state.copyModal && (
        <CopyModal
          user={user}
          isOpen={this.state.copyModal}
          closeModal={async () => {
            this.call(closeModal);
            if (this.state.lastCopiedEvents.length) {
              if (this.state.activeTab === TABS.MY_EMPLOYEES) {
                await this.call(calculateEstimation);
              } else if (this.state.activeTab === TABS.MY_SHIFT) {
                await this.call(calculateMyEstimation);
              }
            }
          }}
          completePercentage={completePercentage}
          selectedResources={selectedResources}
          activeTab={activeTab}
          copyToResources={copyToResources}
          onCopyToResource={(newValue) => {
            this.setState({
              copyToResources: newValue,
            });
          }}
          selectedDays={selectedDays}
          onDateChange={(date) => this.call(onDateChange, date)}
          copyError={copyError}
          startCopyMonthlyEvent={() => this.call(startCopyMonthlyEvent)}
          startCopyEvents={() => this.call(startCopyEvents)}
          mode={mode}
          endCopy={endCopy}
          selectedEvents={selectedEvents}
          isCopying={isCopying}
        />
      )}
    </React.Fragment>
  );
}

export {
  renderModals,
  closeModal,
  clearFilters,
  searchOnScheduler,
  onCheck,
  onExpand,
  openModal,
  copyView,
  onUndoLastCopy,
};

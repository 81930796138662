import BaseService from "./BaseService";

class PostService extends BaseService {
  createPost(data) {
    const postData = new FormData();
    Object.keys(data).forEach((field) => {
      if (field === 'attachment') {
        postData.append('attachment', data[field], data[field].name);
      } else {
        postData.append(field, data[field]);
      }
    });
    return this.api.post("/posts", postData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  replyToComment(data) {
    const postData = new FormData();
    Object.keys(data).forEach((field) => {
      if (field === 'attachment') {
        postData.append('attachment', data[field], data[field].name);
      } else {
        postData.append(field, data[field]);
      }
    });
    return this.api.post("/posts/reply", postData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  likeComment(postId) {
    return this.apiPost({
      url: `/posts/like`,
      data: { postId },
    });
  }
  unLikeComment(postId) {
    return this.apiPost({
      url: `/posts/unlike`,
      data: { postId },
    });
  }

  fetchPosts() {
    return this.apiGet({
      url: "/posts",
    });
  }
  getPostsWithPages(page = 1, perPage = 5, getReplies = false, deletedPosts = 0) {
    return this.apiGet({
      url: "posts/with-pages",
      params: { page, perPage, getReplies, deletedPosts }
    })
  }
  deletePost(postId) {
    return this.apiDelete({
      url: `/posts/${postId}`
    })
  }
}

export default new PostService();

import { isManager } from "./SchedulerHelper";

function handleResourceContextMenu(context) {
    const { id } = context.record.data;
    const events = this.state.mappedEvents[id] || [];
    context.items = context.items.map(item => {
        if (item.name === "copyEvents") {
            item.hidden =
                events.filter(
                    e => !["Unavailability", "Day off"].includes(e.name)
                ).length === 0 || !isManager(this.state.user);
        }
        if (item.name === "removeRow") {
            item.text = "Hide row";
            item.hidden = true;
        }
        return item;
    });
}

export {
    handleResourceContextMenu
}

import React from "react";
import ToggleButton from "react-toggle-button";
import NotificationSectionItem from "./NotificationSectionItem";
import toggleStyle from "./toggleStyle";

export default class NotificationSectionToggleItem extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            active: props.active || false,
        };
    }

    render () {
        const { name, description, onChange } = this.props;
        const self = this;
        return (
            <NotificationSectionItem name={name} description={description}>
                <div className="pull-right" style={description ? {marginTop: '10px'} : {marginTop: '5px'}}>
                    <ToggleButton
                        value={self.state.active}
                        colors={toggleStyle.colors}
                        thumbStyle={toggleStyle.thumb}
                        trackStyle={toggleStyle.track}
                        inactiveLabelStyle={toggleStyle.inactiveLabel}
                        onToggle={(value) => {
                            self.setState({ active: !value });
                            if (typeof onChange === 'function') {
                                onChange(!value);
                            }
                        }}
                    />
                </div>
            </NotificationSectionItem>
        );
    }
}

/* eslint-disable */
import React from "react";
import MonthlyCustomPDF from "../../components/SchedulePrint/MonthlyCustomPDF";
import moment from "moment";
import PropTypes from "prop-types";
import WeeklyShiftPDF from "../../components/SchedulePrint/WeeklyShiftPDF";
import DailyShiftPDF from "../../components/SchedulePrint/DailyShiftPDF";
import { languages } from "./language";
import { MYSQL_DATETIME_FORMAT, SCHEDULE_MODES } from "../Schedule/Constant";

const TABS = {
  MY_SHIFT: "my-shift",
  MY_EMPLOYEES: "my-employees"
};
const views = {
  MONTH: "Month",
  CUSTOM: "Custom",
  MyWeek: "MyWeek",
  EmployeeWeek: "EmployeeWeek",
  MyDay: "MyDay",
  EmployeeDay: "EmployeeDay"
};
class SchedulePdf extends React.Component {
  state = {
    activeTab: TABS.MY_SHIFT,
    mode: SCHEDULE_MODES.THIS_MONTH,
    blackAndWhite: false,
    showShiftDuration: true,
    breakTotal: true,
    breakDetails: true,
    showDepartment: true,
    showLocation: true,
    showRole: true,
    showSection: false,
    startDate: moment()
      .startOf("month")
      .format(`${MYSQL_DATETIME_FORMAT}:00`),
    endDate: moment()
      .endOf("month")
      .format(`${MYSQL_DATETIME_FORMAT}:00`)
  };

  updateState = () => {
    const {
      activeTab,
      startDate,
      endDate,
      mode,
      blackAndWhite,
      showShiftDuration,
      breakTotal,
      breakDetails,
      showDepartment,
      showLocation,
      showRole,
      showSection,
      notes
    } = this.props;
    const state = {};
    if (activeTab && [TABS.MY_SHIFT, TABS.MY_EMPLOYEES].includes(activeTab)) {
      state.activeTab = activeTab;
    }
    if (
      startDate &&
      endDate &&
      moment(startDate).isValid() &&
      moment(endDate).isValid() &&
      moment(startDate).isBefore(moment(endDate))
    ) {
      state.startDate = moment(startDate)
        .startOf("day")
        .format(`${MYSQL_DATETIME_FORMAT}:00`);
      state.endDate = moment(endDate)
        .startOf("day")
        .format(`${MYSQL_DATETIME_FORMAT}:00`);
    }
    if (
      startDate &&
      [
        SCHEDULE_MODES.THIS_WEEK,
        SCHEDULE_MODES.THIS_MONTH,
        SCHEDULE_MODES.DATE_RANGE
      ].includes(mode)
    ) {
      state.mode = mode;
    }
    state.blackAndWhite = !!blackAndWhite;
    state.showShiftDuration = !!showShiftDuration;
    state.breakTotal = !!breakTotal;
    state.breakDetails = !!breakDetails;
    state.showDepartment = !!showDepartment;
    state.showLocation = !!showLocation;
    state.showRole = !!showRole;
    state.showSection = !!showSection;
    state.notes = notes;
    this.setState(state);
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const props = this.props;
    if (
      prevProps.activeTab !== props.activeTab ||
      prevProps.startDate !== props.startDate ||
      prevProps.endDate !== props.endDate ||
      prevProps.mode !== props.mode ||
      prevProps.blackAndWhite !== props.blackAndWhite ||
      prevProps.showShiftDuration !== props.showShiftDuration ||
      prevProps.breakTotal !== props.breakTotal ||
      prevProps.breakDetails !== props.breakDetails ||
      prevProps.showDepartment !== props.showDepartment ||
      prevProps.showLocation !== props.showLocation ||
      prevProps.showRole !== props.showRole ||
      prevProps.showSection !== props.showSection ||
      prevProps.notes !== props.notes ||
      prevProps.events.length !== props.events.length
    ) {
      this.updateState();
    }
  }

  render() {
    const {
      mode,
      startDate,
      endDate,
      blackAndWhite,
      activeTab,
      showShiftDuration,
      breakTotal,
      breakDetails,
      showDepartment,
      showLocation,
      showRole,
      showSection,
      sections,
      events,
      resources,
      language,
      notes,
      user
    } = this.props;
    const lang = languages[language] || languages["en"];
    return (
      <div>
        <div className="container">
          {mode === SCHEDULE_MODES.TODAY ? (
            <DailyShiftPDF
              user={user}
              ref={el => (this.componentRef = el)}
              preview={
                activeTab === TABS.MY_SHIFT ? views.MyDay : views.EmployeeDay
              }
              startDate={startDate}
              endDate={endDate}
              isMyEvent={activeTab === TABS.MY_SHIFT}
              blackAndWhite={blackAndWhite}
              showShiftDuration={showShiftDuration}
              breakTotal={breakTotal}
              breakDetails={breakDetails}
              showDepartment={showDepartment}
              showLocation={showLocation}
              showRole={showRole}
              showSection={showSection}
              sections={sections}
              events={events}
              resources={resources}
              language={language}
              lang={lang}
              notes={notes}
            />
          ) : mode === SCHEDULE_MODES.THIS_WEEK ? (
            <WeeklyShiftPDF
              user={user}
              ref={el => (this.componentRef = el)}
              preview={
                activeTab === TABS.MY_SHIFT ? views.MyWeek : views.EmployeeWeek
              }
              startDate={startDate}
              endDate={endDate}
              isMyEvent={activeTab === TABS.MY_SHIFT}
              blackAndWhite={blackAndWhite}
              showShiftDuration={showShiftDuration}
              breakTotal={breakTotal}
              breakDetails={breakDetails}
              showDepartment={showDepartment}
              showLocation={showLocation}
              showRole={showRole}
              showSection={showSection}
              sections={sections}
              events={events}
              resources={resources}
              language={language}
              lang={lang}
              notes={notes}
            />
          ) : (
                <MonthlyCustomPDF
                  user={user}
                  ref={el => (this.componentRef = el)}
                  preview={
                    mode === SCHEDULE_MODES.THIS_MONTH ? views.MONTH : views.CUSTOM
                  }
                  startDate={startDate}
                  endDate={endDate}
                  isMyEvent={activeTab === TABS.MY_SHIFT}
                  blackAndWhite={blackAndWhite}
                  showShiftDuration={showShiftDuration}
                  breakTotal={breakTotal}
                  breakDetails={breakDetails}
                  showDepartment={showDepartment}
                  showLocation={showLocation}
                  showRole={showRole}
                  showSection={showSection}
                  sections={sections}
                  events={events}
                  resources={resources}
                  language={language}
                  lang={lang}
                  notes={notes}
                />
              )}
        </div>
      </div>
    );
  }
}
SchedulePdf.defaultProps = {
  activeTab: TABS.MY_SHIFT,
  mode: SCHEDULE_MODES.THIS_MONTH,
  blackAndWhite: false,
  showShiftDuration: true,
  startDate: moment()
    .startOf("month")
    .format(`${MYSQL_DATETIME_FORMAT}:00`),
  endDate: moment()
    .endOf("month")
    .format(`${MYSQL_DATETIME_FORMAT}:00`)
};
SchedulePdf.propTypes = {
  activeTab: PropTypes.string,
  mode: PropTypes.string,
  blackAndWhite: PropTypes.bool,
  showShiftDuration: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default SchedulePdf;

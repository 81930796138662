import BaseService from "./BaseService";

class NotificationService extends BaseService {
    fetchOneNotification(id = -1) {
        return this.apiGet({
            url: `/notifications/details/${id}`
        });
    }

    getAll(page, perPage) {
        return this.apiGet({
            url: `/notifications?page=${page}&perPage=${perPage}`
        });
    }

    fetchSubmitNotifications(options) {
        return this.apiGet({
            url: `/notifications/submitted?start=${options.start}&take=${options.take}`
        });
    }
    fetchReleasedNotifications(options) {
        return this.apiGet({
            url: `/notifications/released?start=${options.start}&take=${options.take}`
        });
    }

    fetchEmployeeSharesNotifications(options) {
        return this.apiGet({
            url: `/notifications/shares?start=${options.start}&take=${options.take}`
        });
    }
}

export default new NotificationService();

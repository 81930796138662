import moment from 'moment';
import BreakData from "./data/data";
import Duration from "../../helpers/Duration";
import OverTime from "../../helpers/OverTime";
import { SCHEDULE_MAPPED_EVENTS } from "../../containers/Schedule/Constant";
import { getEligibleEnd, getEligibleStart } from "../CalendarView/BreakHelper";
const overTime = new OverTime();

const breakData = new BreakData();
const every430Hours = {
    type: 'every',
    hours: 4,
    minutes: 30,
    defaultBreakMin: 30,
};
const every5Hours = {
    type: 'every',
    hours: 5,
    minutes: 0,
    defaultBreakMin: 30,
};
const afterSomeHours = {
    type: 'after',
    rules: [
        {
            hours: 6,
            minutes: 0,
            defaultBreakMin: 30
        },
        {
            hours: 8,
            minutes: 0,
            defaultBreakMin: 60
        }
    ]
};
export default class BreakController {
    breakIncrementHours = 4;
    breakIncrementMinutes = 30;
    defaultBreak = 30;
    breakRules = {
        'ON': every430Hours,
        'BC': every430Hours,
        'NY': afterSomeHours,
        'CT': afterSomeHours,
        'CA': every430Hours,
        'MA': every5Hours
    };
    maxBreaks = 4;
    breakMarginMin = 5;
    checkForBreaks = (resource, record, startDate, endDate, name) => {
        if ((overTime.isOnCalifornia(resource.locationCode) || overTime.isOnCanada(resource.locationCode) || overTime.isOnMasachutsess(resource.locationCode)) && resource.salaryHourly === 'H' && name === SCHEDULE_MAPPED_EVENTS.REGULAR) {
            let h = 4;
            let m = 30;
            if (overTime.isOnMasachutsess(resource.locationCode)) {
                h = 5;
                m = 0;
            }
            return this.generateBreaks(record, moment(startDate), moment(endDate), h, m);
        }
        return this.removeGeneratedBreaks(record);
    };
    generateBreaks = (record, startDate, endDate, h = 4, m = 30, db = 30) => {
        this.breakIncrementHours = h;
        this.breakIncrementMinutes = m;
        this.defaultBreak = db;
        const duration = moment.duration(endDate.diff(startDate));
        const hours = Math.floor((duration.asMinutes() / 60));
        const minutes = (duration.asMinutes() % 60);
        record.break1 = 0;
        record.break1EndTime = null;
        record.break1StartTime = null;
        record.break2 = 0;
        record.break2EndTime = null;
        record.break2StartTime = null;
        record.break3 = 0;
        record.break3EndTime = null;
        record.break3StartTime = null;
        record.break4 = 0;
        record.break4EndTime = null;
        record.break4StartTime = null;
        record.breakData = "[]";
        record.breaks = 0;
        let minutes1 = startDate.get('minutes');
        if (minutes1 % 5 !== 0) {
            startDate = startDate.add((minutes1 % 5), "minutes");
        }
        let break4Complete = false;
        let break3Complete = false;
        let break2Complete = false;
        let break1Complete = false;
        const break4Hr = (3 * (this.breakIncrementHours + ((this.defaultBreak + this.breakIncrementMinutes) / 60)) + this.breakIncrementHours + (this.breakIncrementMinutes / 60));
        const break4HrRoundedUp = Math.ceil(break4Hr);
        const break4HrRoundedDown = Math.ceil(break4Hr) === Math.floor(break4Hr) ? Math.floor(break4Hr) - 1 : Math.floor(break4Hr);
        if (hours >= break4HrRoundedUp || ((hours + minutes / 60) > break4Hr)) {
            break4Complete = true;
        }
        const break3Hr = (2 * (this.breakIncrementHours + ((this.defaultBreak + this.breakIncrementMinutes) / 60)) + this.breakIncrementHours + (this.breakIncrementMinutes / 60));
        const break3HrRoundedUp = Math.ceil(break3Hr);
        const break3HrRoundedDown = Math.floor(break3Hr);
        if (hours >= break3HrRoundedUp || ((hours + minutes / 60) > break3Hr)) {
            break3Complete = true;
        }

        const break2Hr = ((this.breakIncrementHours + ((this.defaultBreak + this.breakIncrementMinutes) / 60)) + this.breakIncrementHours + (this.breakIncrementMinutes / 60));
        const break2HrRoundedUp = Math.ceil(break2Hr);
        const break2HrRoundedDown = Math.floor(break2Hr);
        if (hours >= break2HrRoundedUp || ((hours + minutes / 60) > break2Hr)) {
            break2Complete = true;
        }
        const break1Hr = (this.breakIncrementHours + (this.breakIncrementMinutes / 60));
        const break1HrRoundedUp = Math.ceil(break1Hr);
        const break1HrRoundedDown = Math.floor(break1Hr);
        if (hours >= break1HrRoundedUp || ((hours + minutes / 60) > break1Hr)) {
            break1Complete = true;
        }
        if (break4Complete) {
            record.data.breaks = 4 * this.defaultBreak;
            startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes');
            minutes1 = startDate.get('minutes');
            if (minutes1 % 5 !== 0) {
                startDate = startDate.add(-(minutes1 % 5), "minutes");
            }
            const break1Start = startDate.format('HH:mm');
            const break1End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            const break2Start = startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes').format('HH:mm');
            const break2End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            const break3Start = startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes').format('HH:mm');
            const break3End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            let break4Start = startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes').format('HH:mm');
            if (hours === break4HrRoundedUp && minutes <= 5 && break4HrRoundedUp !== break4HrRoundedDown) {
                break4Start = startDate.add((minutes - 5), "minutes").format('HH:mm');
            } else if (hours === break4HrRoundedDown && break4HrRoundedUp !== break4HrRoundedDown) {
                break4Start = startDate.add(-(minutes - (minutes - 35)), "minutes").format('HH:mm');
            } else if (hours === break4HrRoundedDown && break4HrRoundedUp === break4HrRoundedDown && minutes < 35) {
                break4Start = startDate.add(-(35 - minutes), "minutes").format('HH:mm');
            }
            minutes1 = startDate.get('minutes');
            if (minutes1 % 5 !== 0) {
                startDate = startDate.add(-(minutes1 % 5), "minutes");
            }
            const break4End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            record.break1 = this.defaultBreak;
            record.break1StartTime = break1Start;
            record.break1EndTime = break1End;
            record.break2 = this.defaultBreak;
            record.break2StartTime = break2Start;
            record.break2EndTime = break2End;
            record.break3 = this.defaultBreak;
            record.break3StartTime = break3Start;
            record.break3EndTime = break3End;
            record.break4 = this.defaultBreak;
            record.break4StartTime = break4Start;
            record.break4EndTime = break4End;
            record.data.break1 = this.defaultBreak;
            record.data.break1StartTime = break1Start;
            record.data.break1EndTime = break1End;
            record.data.break2 = this.defaultBreak;
            record.data.break2StartTime = break2Start;
            record.data.break2EndTime = break2End;
            record.data.break3 = this.defaultBreak;
            record.data.break3StartTime = break3Start;
            record.data.break3EndTime = break3End;
            record.data.break4 = this.defaultBreak;
            record.data.break4StartTime = break4Start;
            record.data.break4EndTime = break4End;
        } else if (break3Complete) {
            record.data.breaks = 3 * this.defaultBreak;
            startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes');
            minutes1 = startDate.get('minutes');
            if (minutes1 % 5 !== 0) {
                startDate = startDate.add(-(minutes1 % 5), "minutes");
            }
            const break1Start = startDate.format('HH:mm');
            const break1End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            const break2Start = startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes').format('HH:mm');
            const break2End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            let break3Start = startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes').format('HH:mm');
            if (hours === break3HrRoundedUp && minutes <= 5 && break3HrRoundedUp !== break3HrRoundedDown) {
                break3Start = startDate.add((minutes - 5), "minutes").format('HH:mm');
            } else if (hours === break3HrRoundedDown && break3HrRoundedUp !== break3HrRoundedDown) {
                break3Start = startDate.add(-(minutes - (minutes - 35)), "minutes").format('HH:mm');
            } else if (hours === break3HrRoundedDown && break3HrRoundedUp === break3HrRoundedDown && minutes < 35) {
                break3Start = startDate.add(-(35 - minutes), "minutes").format('HH:mm');
            }
            minutes1 = startDate.get('minutes');
            if (minutes1 % 5 !== 0) {
                startDate = startDate.add(-(minutes1 % 5), "minutes");
            }
            const break3End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            record.break1 = this.defaultBreak;
            record.break1StartTime = break1Start;
            record.break1EndTime = break1End;
            record.break2 = this.defaultBreak;
            record.break2StartTime = break2Start;
            record.break2EndTime = break2End;
            record.break3 = this.defaultBreak;
            record.break3StartTime = break3Start;
            record.break3EndTime = break3End;
            record.data.break1 = this.defaultBreak;
            record.data.break1StartTime = break1Start;
            record.data.break1EndTime = break1End;
            record.data.break2 = this.defaultBreak;
            record.data.break2StartTime = break2Start;
            record.data.break2EndTime = break2End;
            record.data.break3 = this.defaultBreak;
            record.data.break3StartTime = break3Start;
            record.data.break3EndTime = break3End;
        } else if (break2Complete) {
            record.data.breaks = 2 * this.defaultBreak;
            startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes');
            minutes1 = startDate.get('minutes');
            if (minutes1 % 5 !== 0) {
                startDate = startDate.add(-(minutes1 % 5), "minutes");
            }
            const break1Start = startDate.format('HH:mm');
            const break1End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            let break2Start = startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes').format('HH:mm');
            if (hours === break2HrRoundedUp && minutes <= 5 && break2HrRoundedUp !== break2HrRoundedDown) {
                break2Start = startDate.add((minutes - 5), "minutes").format('HH:mm');
            } else if (hours === break2HrRoundedDown && break2HrRoundedUp !== break2HrRoundedDown) {
                break2Start = startDate.add(-(minutes - (minutes - 35)), "minutes").format('HH:mm');
            } else if (hours === break2HrRoundedDown && break2HrRoundedUp === break2HrRoundedDown && minutes < 35) {
                break2Start = startDate.add(-(35 - minutes), "minutes").format('HH:mm');
            }
            minutes1 = startDate.get('minutes');
            if (minutes1 % 5 !== 0) {
                startDate = startDate.add(-(minutes1 % 5), "minutes");
            }
            const break2End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            record.break1 = this.defaultBreak;
            record.break1StartTime = break1Start;
            record.break1EndTime = break1End;
            record.break2 = this.defaultBreak;
            record.break2StartTime = break2Start;
            record.break2EndTime = break2End;
            record.data.break1 = this.defaultBreak;
            record.data.break1StartTime = break1Start;
            record.data.break1EndTime = break1End;
            record.data.break2 = this.defaultBreak;
            record.data.break2StartTime = break2Start;
            record.data.break2EndTime = break2End;
        } else if (break1Complete) {
            record.data.breaks = this.defaultBreak;
            startDate.add(this.breakIncrementHours, 'hour').add(this.breakIncrementMinutes, 'minutes');
            if (hours === break1HrRoundedUp && minutes <= 5 && break1HrRoundedUp !== break1HrRoundedDown) {
                startDate = startDate.add((minutes - 5), "minutes");
            } else if (hours === break1HrRoundedDown && break1HrRoundedUp !== break1HrRoundedDown) {
                startDate = startDate.add(-(minutes - (minutes - 35)), "minutes");
            } else if (hours === break1HrRoundedDown && break1HrRoundedUp === break1HrRoundedDown && minutes < 35) {
                startDate = startDate.add(-(35 - minutes), "minutes");
            }
            minutes1 = startDate.get('minutes');
            if (minutes1 % 5 !== 0) {
                startDate = startDate.add(-(minutes1 % 5), "minutes");
            }
            const break1Start = startDate.format('HH:mm');
            const break1End = startDate.add(this.defaultBreak, 'minutes').format('HH:mm');
            record.break1 = this.defaultBreak;
            record.break1StartTime = break1Start;
            record.break1EndTime = break1End;
            record.data.break1 = this.defaultBreak;
            record.data.break1StartTime = break1Start;
            record.data.break1EndTime = break1End;
        }
        return record;
    };
    generateBreaksByRules = (record, startDate, endDate, rule) => {
        record = this.removeGeneratedBreaks(record);
        let minutes1 = startDate.get('minutes');
        if (minutes1 % 5 !== 0) {
            startDate = startDate.add((minutes1 % 5), "minutes");
        }
        let minutes2 = endDate.get('minutes');
        if (minutes2 % 5 !== 0) {
            endDate = endDate.add(-(minutes2 % 5), "minutes");
        }
        const duration = moment.duration(endDate.diff(startDate));
        const minutes = duration.asMinutes();

        if (rule.type === 'every') {
            for (let i = 1; i <= this.maxBreaks; i++) {
                let minDuration = (rule.hours * i * 60) + rule.minutes * i + (i - 1) * rule.defaultBreakMin;
                if (minutes > minDuration) {
                    if (minutes <= (minDuration + rule.defaultBreakMin + this.breakMarginMin)) {
                        minDuration = (minDuration - this.breakMarginMin) - ((minDuration + rule.defaultBreakMin) - minutes);
                    }
                    record.breaks += rule.defaultBreakMin;
                    record.data.breaks += rule.defaultBreakMin;
                    record[`break${i}`] = rule.defaultBreakMin;
                    record.data[`break${i}`] = rule.defaultBreakMin;
                    const sd = moment(startDate).clone();
                    const startTime = sd.add(minDuration, "minutes").format('HH:mm');
                    const endTime = sd.add(rule.defaultBreakMin, "minutes").format('HH:mm');
                    record[`break${i}StartTime`] = startTime;
                    record[`break${i}EndTime`] = endTime;
                    record.data[`break${i}StartTime`] = startTime;
                    record.data[`break${i}EndTime`] = endTime;
                } else {
                    break;
                }
            }
        } else {
            const rules = rule.rules.sort((a, b) => {
                return (b.hours + (b.minutes ? (b.minutes / 60) : 0)) - (a.hours + (a.minutes ? (a.minutes / 60) : 0));
            });
            for (let i = 0; i < rules.length; i++) {
                const r = rules[i];
                let minDuration = (r.hours * 60) + r.minutes;
                if (minutes > minDuration) {
                    if (minutes <= (minDuration + r.defaultBreakMin + this.breakMarginMin)) {
                        minDuration = (minDuration - this.breakMarginMin) - ((minDuration + r.defaultBreakMin) - minutes);
                    }
                    record.breaks = r.defaultBreakMin;
                    record.data.breaks = r.defaultBreakMin;
                    record[`break1`] = r.defaultBreakMin;
                    record.data[`break1`] = r.defaultBreakMin;
                    const sd = moment(startDate).clone();
                    const startTime = sd.add(minDuration, "minutes").format('HH:mm');
                    const endTime = sd.add(r.defaultBreakMin, "minutes").format('HH:mm');
                    record[`break1StartTime`] = startTime;
                    record[`break1EndTime`] = endTime;
                    record.data[`break1StartTime`] = startTime;
                    record.data[`break1EndTime`] = endTime;
                    break;
                }
            }
        }
        return record;
    };

    breakDurationChanged(combo, record, breakStartTime, breakEndTime, brNumber) {
        const startTimeField = combo.owner.owner.widgetMap.startTimeField;
        const endTimeField = combo.owner.owner.widgetMap.endTimeField;
        if (record && record.value > 0 && moment(startTimeField.value).isValid() && moment(endTimeField.value).isValid()) {

            const break1ST = combo.owner.owner.widgetMap.break1.widgetMap.break1StartTime.value;
            const break2ST = combo.owner.owner.widgetMap.break2.widgetMap.break2StartTime.value;
            const break3ST = combo.owner.owner.widgetMap.break3.widgetMap.break3StartTime.value;
            const break4ST = combo.owner.owner.widgetMap.break4.widgetMap.break4StartTime.value;
            const break1ET = combo.owner.owner.widgetMap.break1.widgetMap.break1EndTime.value;
            const break2ET = combo.owner.owner.widgetMap.break2.widgetMap.break2EndTime.value;
            const break3ET = combo.owner.owner.widgetMap.break3.widgetMap.break3EndTime.value;
            const break4ET = combo.owner.owner.widgetMap.break4.widgetMap.break4EndTime.value;
            const breaks = [];
            if (break1ST && break1ET && brNumber !== 1) {
                const st1 = break1ST.split(':');
                const et1 = break1ET.split(':');
                breaks.push({
                    sh: st1[0] * 1,
                    sm: st1[1] * 1,
                    eh: et1[0] * 1,
                    em: et1[1] * 1,
                })
            }
            if (break2ST && break2ET && brNumber !== 2) {
                const st1 = break2ST.split(':');
                const et1 = break2ET.split(':');
                breaks.push({
                    sh: st1[0] * 1,
                    sm: st1[1] * 1,
                    eh: et1[0] * 1,
                    em: et1[1] * 1,
                })
            }
            if (break3ST && break3ET && brNumber !== 3) {
                const st1 = break3ST.split(':');
                const et1 = break3ET.split(':');
                breaks.push({
                    sh: st1[0] * 1,
                    sm: st1[1] * 1,
                    eh: et1[0] * 1,
                    em: et1[1] * 1,
                })
            }
            if (break4ST && break4ET && brNumber !== 4) {
                const st1 = break4ST.split(':');
                const et1 = break4ET.split(':');
                breaks.push({
                    sh: st1[0] * 1,
                    sm: st1[1] * 1,
                    eh: et1[0] * 1,
                    em: et1[1] * 1,
                })
            }
            const startDate = moment(startTimeField.value);
            const endDate = moment(endTimeField.value);
            const duration = moment.duration(endDate.diff(startDate));
            const hours = Math.floor(duration.asMinutes() / 60);
            const startTime = startDate.format('HH:mm');
            const endTime = hours === 24 ? '24:00' : moment(endTimeField.value).format('HH:mm');
            const startHrs = startTime.split(':');
            const endHrs = endTime.split(':');
            const startConfig = {
                h: startHrs[0] * 1,
                m: startHrs[1] * 1,
                am: startHrs[0] * 1 < 12,
            };
            const endConfig = {
                h: endHrs[0] * 1,
                m: endHrs[1] * 1,
                am: endHrs[0] * 1 < 12,
            };
            breakStartTime.disabled = false;
            breakEndTime.disabled = false;
            const break1StartTimeData = JSON.parse(JSON.stringify(breakData.time.filter(rec => (rec.h > startConfig.h || (rec.h === startConfig.h && rec.m > startConfig.m)) &&
                (rec.h < endConfig.h || (rec.h === endConfig.h && rec.m < endConfig.m))).filter(rec => {
                    let br1 = true, br2 = true, br3 = true;
                    for (let i = 0; i < breaks.length; i++) {
                        const br = breaks[i];
                        const b = (rec.h < br.sh || (rec.h === br.sh && rec.m <= br.sm)) || (rec.h > br.eh || (rec.h === br.eh && rec.m >= br.em));
                        switch (i) {
                            case 0:
                                br1 = b;
                                break;
                            case 1:
                                br2 = b;
                                break;
                            default:
                                br3 = b;
                                break;
                        }
                    }
                    if ((rec.m + record.value) >= 60) {
                        rec.endValue = `${(rec.h + 1) < 10 ? '0' + (rec.h + 1) : (rec.h + 1)}:${((rec.m + record.value) - 60) < 10 ? '0' + ((rec.m + record.value) - 60) : ((rec.m + record.value) - 60)}`;
                    } else {
                        rec.endValue = `${rec.h < 10 ? '0' + rec.h : rec.h}:${(rec.m + record.value) < 10 ? '0' + (rec.m + record.value) : (rec.m + record.value)}`;
                    }
                    if ((rec.m - record.value) < 0) {
                        rec.startValue = `${(rec.h - 1) < 10 ? '0' + (rec.h - 1) : (rec.h - 1)}:${((rec.m - record.value) + 60) < 10 ? '0' + ((rec.m - record.value) + 60) : ((rec.m - record.value) + 60)}`;
                    } else {
                        rec.startValue = `${rec.h < 10 ? '0' + rec.h : rec.h}:${(rec.m - record.value) < 10 ? '0' + (rec.m - record.value) : (rec.m - record.value)}`;
                    }
                    return br1 && br2 && br3
                }
                )));
            const allValues = break1StartTimeData.map(b => b.value);
            breakStartTime.store.data = break1StartTimeData.filter(el => allValues.includes(el.endValue));
            breakEndTime.store.data = break1StartTimeData.filter(el => allValues.includes(el.startValue));

            // if the selected record has been filtered out need to reset value
            if (breakStartTime.record && !breakStartTime.store.getById(breakStartTime.record.id)) {
                breakStartTime.value = null;
            }
            // if the selected record has been filtered out need to reset value
            if (breakEndTime.record && !breakEndTime.store.getById(breakEndTime.record.id)) {
                breakEndTime.value = null;
            }
        } else {
            breakStartTime.disabled = true;
            breakEndTime.disabled = true;
            breakStartTime.value = null;
            breakEndTime.value = null;
        }
    }

    deleteBreak(button, breakName) {
        if (button) {
            button.owner.widgetMap[breakName].value = 0;
            button.owner.owner.widgetMap[breakName].hidden = true;
            button.owner.owner.widgetMap.breakButton.hidden = false;
        }
        return false;
    }

    breakStartTimeChanged(combo, record, breakCombo, breakEndTimeField) {
        const breakMin = breakCombo.value;
        if (breakMin > 0) {
            breakEndTimeField.value = record.data.endValue;
        }
    }

    breakEndTimeChanged(combo, record, breakCombo, breakStartTimeField) {
        const breakMin = breakCombo.value;
        if (breakMin > 0) {
            breakStartTimeField.value = record.data.startValue;
        }
    }

    removeAllBreaks = (event) => {
        event.eventRecord.break1 = 0;
        event.eventRecord.break1EndTime = null;
        event.eventRecord.break1StartTime = null;
        event.eventRecord.break2 = 0;
        event.eventRecord.break2EndTime = null;
        event.eventRecord.break2StartTime = null;
        event.eventRecord.break3 = 0;
        event.eventRecord.break3EndTime = null;
        event.eventRecord.break3StartTime = null;
        event.eventRecord.break4 = 0;
        event.eventRecord.break4EndTime = null;
        event.eventRecord.break4StartTime = null;
        event.eventRecord.breakData = "[]";
        event.eventRecord.breaks = 0;
        event.eventEdit.extraWidgets = event.eventEdit.extraWidgets.map(widget => {
            if (['break1', 'break2', 'break3', 'break4', 'breakButton'].includes(widget.ref)) {
                widget.hidden = true;
            }
            return widget;
        });
    };
    removeGeneratedBreaks = (record) => {
        record.break1 = 0;
        record.break1EndTime = null;
        record.break1StartTime = null;
        record.break2 = 0;
        record.break2EndTime = null;
        record.break2StartTime = null;
        record.break3 = 0;
        record.break3EndTime = null;
        record.break3StartTime = null;
        record.break4 = 0;
        record.break4EndTime = null;
        record.break4StartTime = null;
        record.breakData = "[]";
        record.breaks = 0;
        return record;
    };
    moveBreaks = (record) => {
        const { data, originalData } = record;
        if (data && originalData && data.startDate && originalData.startDate) {
            if (moment(data.startDate).format('HHmm') !== moment(originalData.startDate).format('HHmm') || (
                moment(data.endDate).format('HHmm') !== moment(originalData.endDate).format('HHmm') && moment(data.endDate).isBefore(originalData.endDate)
            )) {
                const difference = this.getMinuteDifference(data.startDate, originalData.startDate);
                const prevEligibleStart = getEligibleStart(moment(originalData.startDate));
                const newEligibleStart = getEligibleStart(moment(data.startDate));
                const newEligibleEnd = getEligibleEnd(moment(data.endDate));
                const roundFunction = difference > 0 ? "floor" : "ceil";
                const newDifference = Math[roundFunction](difference / 5) * 5;
                const currentDate = moment(data.currentDate).format('YYYY-MM-DD');
                for (let i = 1; i < 5; i++) {
                    if (record[`break${i}`] && record[`break${i}StartTime`] && record[`break${i}EndTime`]) {
                        const start = moment(`${currentDate} ${record[`break${i}StartTime`]}`);
                        const end = moment(`${currentDate} ${record[`break${i}EndTime`]}`);
                        let newBreakStart = moment(`${currentDate} ${record[`break${i}StartTime`]}`);
                        let newBreakEnd = moment(`${currentDate} ${record[`break${i}EndTime`]}`);
                        newBreakStart = moment(start).add(
                            newDifference,
                            "minutes"
                        );
                        newBreakEnd = moment(end).add(
                            newDifference,
                            "minutes"
                        );

                        const currDiff = this.getMinuteDifference(
                            newBreakStart,
                            newEligibleStart
                        );
                        const prevDiff = this.getMinuteDifference(
                            start,
                            prevEligibleStart
                        );

                        if (
                            difference > 0
                                ? prevDiff > currDiff
                                : prevDiff < currDiff
                        ) {
                            const filler = Math.sign(difference) * 5;
                            newBreakStart.add(filler, "minutes");
                            newBreakEnd.add(filler, "minutes");
                        }
                        if (
                            newBreakEnd.isSameOrBefore(
                                newEligibleEnd,
                                "minutes"
                            )
                        ) {
                            record[`break${i}StartTime`] = newBreakStart.format('HH:mm');
                            record[`break${i}EndTime`] = newBreakEnd.format('HH:mm');
                            record.data[`break${i}StartTime`] = newBreakStart.format('HH:mm');
                            record.data[`break${i}EndTime`] = newBreakEnd.format('HH:mm');
                        } else {
                            record.data[`break${i}`] = null;
                            record.data[`break${i}StartTime`] = null;
                            record.data[`break${i}EndTime`] = null;
                            record[`break${i}`] = null;
                            record[`break${i}StartTime`] = null;
                            record[`break${i}EndTime`] = null;
                        }

                    }
                }
            }
        }
        return record;
    };
    getMinuteDifference = (n, p) => {
        return moment
            .duration(
                moment(n)
                    .endOf("minute")
                    .diff(moment(p).endOf("minute"))
            )
            .asMinutes();
    };
    validBreaks = (values, h = 4, m = 30) => {
        let start = values.startDate;
        const currentDate = moment(values.currentDate).format('YYYY-MM-DD');
        if (values.break1StartTime) {
            const end = new Date(`${currentDate} ${values.break1StartTime}`);
            const duration = Duration.durationAsHoursAndMinsObject(end, start);
            start = new Date(`${currentDate} ${values.break1EndTime}`);
            if (duration.hours > h || (duration.hours === h && duration.minutes > m)) {
                return false;
            }
        }
        if (values.break2StartTime) {
            const end = new Date(`${currentDate} ${values.break2StartTime}`);
            const duration = Duration.durationAsHoursAndMinsObject(end, start);
            start = new Date(`${currentDate} ${values.break2EndTime}`);
            if (duration.hours > h || (duration.hours === h && duration.minutes > m)) {
                return false;
            }
        }
        if (values.break3StartTime) {
            const end = new Date(`${currentDate} ${values.break3StartTime}`);
            const duration = Duration.durationAsHoursAndMinsObject(end, start);
            start = new Date(`${currentDate} ${values.break3EndTime}`);
            if (duration.hours > h || (duration.hours === h && duration.minutes > m)) {
                return false;
            }

        }
        if (values.break4StartTime) {
            const end = new Date(`${currentDate} ${values.break4StartTime}`);
            const duration = Duration.durationAsHoursAndMinsObject(end, start);
            start = new Date(`${currentDate} ${values.break4EndTime}`);
            if (duration.hours > h || (duration.hours === h && duration.minutes > m)) {
                return false;
            }
        }
        const duration = Duration.durationAsHoursAndMinsObject(values.endDate, start);
        return !(duration.hours > h || (duration.hours === h && duration.minutes > m));

    }
    validBreaksByRules = (values, rule) => {
        let start = values.startDate;
        const currentDate = moment(values.currentDate).format('YYYY-MM-DD');
        let valid = '';
        if (rule.type === 'every') {
            for (let i = 1; i <= this.maxBreaks; i++) {
                if (values[`break${i}StartTime`]) {
                    const end = new Date(`${currentDate} ${values[`break${i}StartTime`]}`);
                    const duration = Duration.durationAsHoursAndMinsObject(end, start);
                    start = new Date(`${currentDate} ${values[`break${i}EndTime`]}`);
                    if (duration.hours > rule.hours || (duration.hours === rule.hours && duration.minutes > rule.minutes)) {
                        valid = `${(rule.hours ? rule.hours : 0) + (rule.minutes ? parseFloat((rule.minutes / 60).toFixed(1)) : 0)}`;
                        break;
                    }
                }
            }
            if (valid) {
                const duration = Duration.durationAsHoursAndMinsObject(values.endDate, start);
                valid = !(duration.hours > rule.hours || (duration.hours === rule.hours && duration.minutes > rule.minutes)) ?
                    `${(rule.hours ? rule.hours : 0) + (rule.minutes ? parseFloat((rule.minutes / 60).toFixed(1)) : 0)}` :
                    '';
            }
        } else {
            const shiftDuration = Duration.durationAsHoursAndMinsObject(values.endDate, values.startDate);
            const rules = rule.rules.filter(r => {
                return r.hours < shiftDuration.hours || (r.hours === shiftDuration.hours && r.minutes < shiftDuration.minutes);
            }).sort((a, b) => {
                return (b.hours + (b.minutes ? (b.minutes / 60) : 0)) - (a.hours + (a.minutes ? (a.minutes / 60) : 0));
            });
            for (let i = 0; i < rules.length; i++) {
                const r = rules[i];
                if (values.break1StartTime) {
                    const end = new Date(`${currentDate} ${values.break1StartTime}`);
                    const duration = Duration.durationAsHoursAndMinsObject(end, start);
                    if (duration.hours > r.hours || (duration.hours === r.hours && duration.minutes > r.minutes)) {
                        valid = `${(r.hours ? r.hours : 0) + (r.minutes ? parseFloat((r.minutes / 60).toFixed(1)) : 0)}`;
                        break;
                    }
                    if (shiftDuration.hours > r.hours || (shiftDuration.hours === r.hours && shiftDuration.minutes > r.minutes)) {
                        break;
                    }
                } else {
                    if (shiftDuration.hours > r.hours || (shiftDuration.hours === r.hours && shiftDuration.minutes > r.minutes)) {
                        valid = `${(r.hours ? r.hours : 0) + (r.minutes ? parseFloat((r.minutes / 60).toFixed(1)) : 0)}`;
                        break;
                    }
                }
            }
        }
        return valid;
    }
}

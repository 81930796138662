/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import Toast from "../../components/Blocks/Toast/Toast";
import { ExcelRenderer } from "react-excel-renderer";
import zipcelx from "zipcelx";

import BudgetService from "../../services/BudgetService";
import StringHelper from "../../helpers/StringHelper";

import BudgetTableRow from "../../components/Tables/BudgetTableRow";
import BudgetForm from "../../components/Forms/BudgetForm";
import FileUpload from "../../components/FileUpload/FileUpload";
import Modal from "react-modal";

import "./assets/styles/Budgets.css";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/Blocks/Loader/Loader";
import PreviewImportTable from "../../components/CrudPage/PreviewImportTable";

const { budgets } = require("./sampleData");

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "90%",
    maxHeight: "100%"
  }
};

export default class Budgets extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
      budgets: [],
      activeBudget: null,
      activeBudgetIndex: -1,
      uploader: null,
      showForm: false,
      filters: {
        locationId: 0,
        departmentId: 0,
        year: new Date().getFullYear()
      },
      locationOptions: [],
      departmentOptions: [],
      yearOptions: [],
      successMessage: "",
      errorMessage: ""
    };
    this.saveBudget = this.saveBudget.bind(this);
    this.importBudgets = this.importBudgets.bind(this);
  }

  sampleData = budgets;

  saveBudget(budget, isUpdate) {
    this.setState({
      activeBudget: budget
    });
    if (budget) {
      this.setState({ isLoading: true });
      const saving = BudgetService.saveBudget(budget);
      saving
        .then(res => {
          if (res.data.message) {
            const total = MONTHS.reduce((s, m) => s + (budget[m] || 0), 0);
            budget.total = total;
            if (isUpdate && this.state.activeBudgetIndex >= 0) {
              const budgets = [].concat(this.state.budgets);
              budgets[this.state.activeBudgetIndex] = budget;
              this.setState({
                budgets,
                isLoading: false,
                successMessage: res.data.message
              });
            } else {
              this.setState({
                isLoading: false,
                successMessage: res.data.message
              });
            }
          } else {
            this.setState({ isLoading: false });
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({
            isLoading: false,
            errorMessage:
              "Something went wrong while saving your budget. Please try again."
          });
        });
    }
  }

  deleteBudget(budget, budgetIndex) {
    this.setState({ isLoading: true });
    BudgetService.deleteBudget(budget)
      .then(res => {
        const budgets = [].concat(this.state.budgets);
        budgets.splice(budgetIndex, 1);
        this.setState({
          budgets,
          isLoading: false,
          successMessage: res.data.message
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoading: false,
          errorMessage:
            "Something went wrong while deleting your budget. Please try again."
        });
      });
  }

  updateFilters(filterName, value) {
    const filters = Object.assign({}, this.state.filters, {
      [filterName]: value
    });
    this.setState({ filters });
    this.fetchBudgets(filters);
  }

  previewImport = ([file]) => {
    ExcelRenderer(file, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          previewData: {
            rows: resp.rows
          }
        });
      }
    });
  };

  cancelImport = () => {
    this.setState({ previewData: null });
  };

  async importBudgets() {
    return BudgetService.importBudgetsSample(this.state.previewData.rows, this.state.filters.year);
  }

  onImportSuccess = () => {
    this.setState(
      { successMessage: "The data has been imported successfully" },
      () => {
        this.fetchBudgets(this.state.filters);
      }
    );
  };

  downloadSample = async () => {
    this.setState({ isDownloadingSample: true });

    try {
      await zipcelx({
        filename: `Budgets_Sample`,
        sheet: { data: this.sampleData }
      });
    } catch (e) {
      console.log(e);
    }

    this.setState({ isDownloadingSample: false });
  };

  fetchBudgets(filters) {
    this.setState({
      isLoading: true
    });
    BudgetService.getBudgets(filters || this.state.filters).then(
      ({ data: budgets }) => {
        this.setState({
          isLoading: false,
          budgets
        });
      }
    );
  }

  componentDidMount() {
    const { locations, departments } = this.props;
    const departmentOptions = departments;
    const locationOptions = locations;
    const year = new Date().getFullYear();
    const yearOptions = Array.from(new Array(6), (val, index) => {
      const yearOffset = index % 2 === 0 ? +index / 2 : -Math.ceil(index / 2);
      return (year + yearOffset).toString();
    })
      .sort()
      .map(year => ({
        value: year,
        label: year
      }));

    const filters = {
      year
    };
    if (departmentOptions.length > 0) {
      filters.departmentId = departmentOptions[0].value;
    }
    this.setState(
      {
        departmentOptions,
        locationOptions,
        yearOptions,
        filters
      },
      () => {
        this.fetchBudgets(filters);
      }
    );
  }

  renderRows() {
    const { budgets } = this.state;
    if (budgets.length) {
      const total = budgets.reduce((total, budget) => {
        const yearTotal = MONTHS.reduce((s, m) => s + (budget[m] || 0), 0);
        return total + yearTotal;
      }, 0);
      const budgetsRows = budgets.map((budget, rowId) => {
        return (
          <BudgetTableRow
            key={rowId}
            budget={budget}
            onEdit={() => {
              this.setState({
                activeBudget: budget,
                activeBudgetIndex: rowId,
                showForm: true
              });
            }}
            onDelete={budget => {
              this.deleteBudget(budget, rowId);
            }}
          />
        );
      });

      return (
        <Fragment>
          {budgetsRows}
          <tr>
            <td colSpan="15" align="right">
              <b>Total: </b>
            </td>
            <td>${StringHelper.formatMoney(total || 0)}</td>
          </tr>
        </Fragment>
      );
    } else {
      return (
        <tr>
          <td colSpan="16" className="align-middle text-center">
            No data
          </td>
        </tr>
      );
    }
  }

  renderFilters() {
    const {
      filters,
      locationOptions,
      departmentOptions,
      yearOptions
    } = this.state;
    return (
      <div className="row">
        <div className="col-sm-6 col-md-4">
          <select
            className="form-control input-sm schedule-select"
            placeholder="Year"
            onChange={event => this.updateFilters("year", event.target.value)}
            value={filters.year}
          >
            {yearOptions.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-6 col-md-4">
          <select
            className="form-control input-sm schedule-select"
            placeholder="Location Code"
            onChange={event =>
              this.updateFilters("locationId", event.target.value)
            }
            value={filters.locationId}
          >
            <option value="">All Locations</option>
            {locationOptions.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-6 col-md-4">
          <select
            className="form-control input-sm schedule-select"
            placeholder="Departament Code"
            onChange={event =>
              this.updateFilters("departmentId", event.target.value)
            }
            value={filters.departmentId}
          >
            <option value="">All Departments</option>
            {departmentOptions.map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  renderForm() {
    const { showForm } = this.state;
    const closeModal = () => this.setState({ showForm: false });
    return (
      <Modal
        isOpen={showForm}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Upload Video"
        shouldCloseOnOverlayClick={true}
      >
        <div className="modal-header">
          <h4>{this.state.activeBudget ? "Update" : "Add"} budgets </h4>
        </div>
        <div className="modal-body">
          <BudgetForm
            onFormSubmit={(budget, update) => {
              closeModal();
              return this.saveBudget(budget, update);
            }}
            onCancel={() => {
              this.setState({
                activeBudget: null,
                activeBudgetIndex: -1,
                showForm: false
              });
            }}
            budget={this.state.activeBudget}
            locationOptions={this.state.locationOptions}
            departmentOptions={this.state.departmentOptions}
            yearOptions={this.state.yearOptions}
          />
        </div>
      </Modal>
    );
  }

  renderUpload() {
    return (
      <FileUpload
        onRef={uploader => (this.uploader = uploader)}
        accept=".xlsx, .xls"
        onChange={this.previewImport}
        maxFileSize={10485760}
        multiple={false}
        labelClass="d-none"
        buttonClassName="d-none"
        withIcon={false}
        withPreview={false}
      />
    );
  }

  renderTable() {
    const { isLoading, budgets, isDownloadingSample } = this.state;
    const monthsHeaders = MONTHS.map(month => (
      <th key={month + "_hdr"} className="text-center">
        {month}
      </th>
    ));
    return (
      <div className="col-12 my-5 pull-left">
        <Loader isLoading={isLoading} />
        <div className="e-panel card">
          <div className="card-header">
            <div className="float-left">
              <h4>Budgets ({budgets.length})</h4>
            </div>
            <div className="float-right">
              <a
                style={{
                  textDecoration: "none",
                  width: "30px",
                  marginRight: "17px"
                }}
                onClick={this.downloadSample}
                href="#"
              >
                {isDownloadingSample ? "Downloading..." : "Download sample"}
              </a>
              {this.renderUpload()}
              <button
                type="button"
                className="btn btn-sm btn-primary btn-with-icon btn-import"
                onClick={() =>
                  this.uploader && this.uploader.triggerFileUpload()
                }
              >
                <i className="fas fa-upload" /> Import
              </button>
            </div>
          </div>
          <div className="my-3" style={{ marginLeft: "30px" }}>
            <button
              className="btn btn-secondary btn-with-icon"
              type="button"
              onClick={() => this.setState({ showForm: true })}
            >
              Add Budgets
            </button>
          </div>
          <div className="card-body budget-filters">{this.renderFilters()}</div>
          <div className="card-body">
            <div className="e-table">
              <div className="table-responsive table-lg">
                <table className="table table-bordered text-nowrap table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">Actions</th>
                      <th className="text-center">Location Code</th>
                      <th className="text-center">Department Code</th>
                      {/* <th className="text-center">Year</th> */}
                      {monthsHeaders}
                      <th className="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>{!isLoading && this.renderRows()}</tbody>
                </table>
              </div>
              {/* {preLoader} */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { successMessage, errorMessage, previewData } = this.state;
    const successToast = successMessage ? (
      <Toast
        status="success"
        title="Budgets"
        message={successMessage}
        dismiss={() => this.setState({ successMessage: null })}
      />
    ) : (
      ""
    );
    const errorToast = errorMessage ? (
      <Toast
        status="error"
        title="Budgets"
        message={errorMessage}
        dismiss={() => this.setState({ errorMessage: null })}
      />
    ) : (
      ""
    );

    return (
      <div className="col-12 col-sm-12">
        {successToast}
        {errorToast}
        {previewData && (
          <PreviewImportTable
            isOpen={true}
            name="Budgets"
            importData={this.importBudgets}
            closeModal={this.cancelImport}
            data={previewData}
            onSuccess={this.onImportSuccess}
          />
        )}
        <div className="row">
          {this.renderForm()}
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

import React from "react";
// Services
import AuthService from "../../services/AuthService";
// Helpers
import StorageManager from "../../helpers/StorageManager";
// Components
import ChangePasswordForm from "../../components/Forms/ChangePasswordForm";
import Toast from "../../components/Blocks/Toast/Toast";
// Images
import WhiteLogo from "../../general/assets/images/arcon-white.png";
// Styles
import "./assets/styles/ChangePassword.css";

class ChangePassword extends React.Component {
  state = {
    error: null,
    success: null,
    values: {},
    loggingIn: false
  };

  submitChangePassword = values => {
    return AuthService.changePassword({
      employeeId: this.props.match.params.employeeCode,
      password: values.oldPassword,
      newPassword: values.newPassword
    })
      .then(response => {
        this.setState(
          {
            values,
            success: response.data.message
          },
          () => {
            if (response.data.token) {
              StorageManager.clearAll();
              const authSuccess = StorageManager.set(
                "token",
                response.data.token
              );
              if (authSuccess.success) {
                window.location = "/app";
              } else {
                alert("Storage not set!!");
              }
            }
          }
        );
      })
      .catch(this.showError);
  };

  logMeIn = () => {
    const { employeeCode, newPassword } = this.state.values;

    this.setState({
      loggingIn: true
    });

    AuthService.login(employeeCode, newPassword)
      .then(res => {
        if (res.data.token) {
          const authSuccess = StorageManager.set("token", res.data.token);

          if (authSuccess.success) {
            window.location = "/app";
          } else {
            this.setState({
              loggingIn: false
            });
            alert("Storage not set!!");
          }
        }
      })
      .catch(this.showError);
  };

  showError = error => {
    this.setState({
      error:
        error.response && error.response.data
          ? error.response.data
          : "An Error Occurred"
    });
  };

  toastDismiss = () => {
    this.setState({
      error: null,
      success: null
    });
  };

  componentWillMount() {
    let picture = StorageManager.get("login-picture-index");
    if (!picture) {
      picture = 1;
      StorageManager.set("login-picture-index", 2);
    } else {
      picture = parseInt(picture);
      StorageManager.set(
        "login-picture-index",
        picture === 3 ? 1 : picture + 1
      );
    }

    this.setState({ picture });
  }

  render() {
    const { error, success, picture } = this.state;

    const toastError = error && (
      <Toast
        title="Change password failed!"
        message={error}
        dismiss={this.toastDismiss}
      />
    );

    const toastSuccess = success && (
      <Toast
        status="success"
        title="Successfully updated!"
        message={
          <React.Fragment>
            <p className="m-0">
              Your password has been updated successfully. Redirecting to your
              dashboard...
            </p>
          </React.Fragment>
        }
        dismiss={this.toastDismiss}
      />
    );

    return (
      <div className={`login-section picture-${picture}`}>
        {toastError}
        {toastSuccess}

        <div>
          <img src={WhiteLogo} className="" alt="logo" />
          <ChangePasswordForm
            submitChangePassword={this.submitChangePassword}
          />
        </div>
      </div>
    );
  }
}

// ResetPassword.propTypes = {
// };

export default ChangePassword;

import CrudPage from "../../components/CrudPage";
import CrudService from "../../services/CrudService";
import EmployeeService from "../../services/EmployeeService";
const crudService = new CrudService("custom-departments");

let timeoutUser = null;
export default class CustomDepartmentView extends CrudPage {
  name = "Custom Department View";
  fields = [
    {
      name: "employee",
      label: "Employee",
      type: "text",
      showOnForm: false
    },
    {
      name: "employeeCode",
      label: "Employee Code",
      type: "text",
      showOnForm: false
    },
    { name: "code", label: "Code", type: "text", showOnForm: false },
    { name: "description", label: "Department", type: "text", showOnForm: false },
    {
      name: "department",
      label: "Department",
      type: "select-search",
      placeholder: "Type to search for a department",
      menuIsOpen: false,
      closeMenuOnSelect: true,
      isMulti: false,
      showOnTable: false,
      clearable: true,
      options: this.props.departments
    },
    {
      name: "name",
      label: "Employee",
      type: "react-select",
      placeholder: "Type to search for an employee",
      menuIsOpen: false,
      closeMenuOnSelect: true,
      isMulti: false,
      loadOptions: (search, callback) => {
        timeoutUser && clearTimeout(timeoutUser);
        timeoutUser = setTimeout(async () => {
          EmployeeService.searchUsers(search, true).then(employees => {
            callback(
              employees.data.map(({ name: label, id: value, ...other }) => ({
                label,
                value,
                ...other
              }))
            );
          });
        }, 300);
      },
      showOnTable: false,
      clearable: true
    },
    { name: "status", label: "Active", type: "toggle", defaultVal: false }
  ];
  enableImport = false;
  importSampleLink = "https://dxv8p7y79jw20.cloudfront.net/Jobs+Sample.csv";

  sampleData = [];
  searchPlaceHolder = "Search by title, code, employee or employee number...";
  actions = ["U", "D"];

  fetch(page, search) {
    return crudService.list(page, search);
  }
  prepareData(data) {
    if (data.department) data.departmentId = data.department.value;
    if (data.name) data.employeeId = data.name.employee_id;
    delete data.department;
    delete data.name;
    return data;
  }
  create(data) {
    data = this.prepareData(data);
    return crudService.create(data);
  }

  update(data) {
    data = this.prepareData(data);
    return crudService.update(data);
  }

  delete(id) {
    return crudService.remove(id);
  }

  import(file) {
    return crudService.importSample(file);
  }
}

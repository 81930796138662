import React from "react";
import CrudPage from "../../components/CrudPage";

import CrudService from "../../services/CrudService";
import LocationService from "../../services/LocationService";
const crudRegionService = new CrudService("regions");

export default class Regions extends CrudPage {
  name = "Region";
  fields = [
    { name: "id", label: "ID", type: "text", showOnForm: false, showOnTable: false },
    { name: "code", label: "Code", type: "text" },
    { name: "description", label: "Description", type: "text" },
    {
      name: "locations",
      label: "Locations",
      type: "number",
      defaultVal: 0,
      showOnForm: false
    },
    { name: "isActive", label: "Active", type: "toggle", defaultVal: false }
  ];
  ExtraComponent = ({
    mode,
    formData,
    tableData,
    otherData: {
      total,
      data,
      isLoading,
      selectedAll = false,
      regionSelect = [],
      selectedRegion = 0
    }
  }) => {
    const tableHeader =
      mode === "create"
        ? "Locations without region"
        : `Locations of region ${
            formData && formData.code ? formData.code : ""
          }`;
    const checkedLocations = data.filter(l => l.checked).length;
    return (
      <div className="card col-md-12 pull-left">
        <div className="card-header">
          <h4>
            {tableHeader} ({total}){" "}
          </h4>
        </div>
        <div className="card-body">
          {!isLoading && (
            <div className="row w-100 mb-4">
              <div className="form-check col-lg-2 mb-4 mb-lg-0 col-12 ml-3 form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="select-all"
                  onChange={this.onSelectAll}
                  checked={selectedAll}
                />
                <label className="form-check-label" htmlFor="select-all">
                  Select all
                </label>
              </div>

              <div className="col-lg-10 col-12 row justify-content-between align-item-center">
                <div className="col-12 col-lg-7 mb-3 mb-lg-0">
                  <label htmlFor="regionSelect">Choose a region:</label>
                  <select
                    id="regionSelect"
                    name="regionSelect"
                    className="form-control"
                    onChange={e => {
                      if (e.target.value || e.target.value === 0) {
                        const otherData = this.state.otherData;
                        otherData.selectedRegion = e.target.value;
                        this.setState({ otherData });
                      }
                    }}
                    defaultValue={selectedRegion}
                  >
                    {regionSelect.map((r, key) => (
                      <option key={key} value={r.value}>
                        {r.text}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12 col-lg-5 d-flex align-items-center justify-content-lg-end">
                  <button
                    className="btn btn-primary text-nowrap"
                    onClick={() => {
                      this.changeLocationRegion(formData.id);
                    }}
                    disabled={checkedLocations === 0}
                  >
                    {mode === "edit"
                      ? "Change region Location"
                      : `Assign region to ${checkedLocations} Location${
                          checkedLocations > 1 ? "s" : ""
                        }`}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered mb-0 text-nowrap">
            <thead>
              <tr>
                <th />
                <th>Id</th>
                <th>Code</th>
                <th>Description</th>
                <th>State</th>
                <th>City</th>
                <th>Region</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                data.map((d, index) => (
                  <tr key={index}>
                    <td className="align-middle text-center">
                      <div>
                        <input
                          type="checkbox"
                          value={d.checked || ""}
                          checked={d.checked || selectedAll}
                          onChange={() => this.onCheckboxChange(index)}
                        />
                      </div>
                    </td>
                    <td className="text-nowrap align-middle">{d.id}</td>
                    <td className="text-nowrap align-middle">{d.code}</td>
                    <td className="text-nowrap align-middle">
                      {d.description}
                    </td>
                    <td className="text-nowrap align-middle">{d.state}</td>
                    <td className="text-nowrap align-middle">{d.city}</td>
                    <td className="text-nowrap align-middle">
                      {d.regionId ? d.regionCode : ""}
                    </td>
                  </tr>
                ))}
              {!isLoading && data.length === 0 && (
                <tr>
                  <td colSpan="100%" align="center">
                    No data
                  </td>
                </tr>
              )}
              {isLoading && (
                <tr>
                  <td colSpan="100%" align="center">
                    Loading...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  onSelectAll = () => {
    const otherData = this.state.otherData;
    otherData.data = otherData.data.map(l => {
      l.checked = !otherData.selectedAll;
      return l;
    });
    otherData.selectedAll = !otherData.selectedAll;
    this.setState({ otherData });
  };
  onCheckboxChange = index => {
    const otherData = this.state.otherData;
    const selected =
      otherData.data[index] === undefined
        ? false
        : otherData.data[index].checked;
    otherData.data = [
      ...otherData.data.slice(0, index),
      { ...otherData.data[index], checked: !selected },
      ...otherData.data.slice(index + 1)
    ];
    otherData.selectedAll = otherData.data.every(item => item.checked);
    this.setState({ otherData });
  };
  changeLocationRegion = (oldRegionId, regionId) => {
    const otherData = this.state.otherData;
    regionId = otherData.selectedRegion;
    if (oldRegionId !== regionId) {
      const locationIds = otherData.data.filter(l => l.checked).map(l => l.id);
      if (locationIds.length) {
        otherData.isLoading = true;
        this.setState({ otherData });
        LocationService.changeLocationRegion(regionId, locationIds)
          .then(res => {
            otherData.isLoading = false;
            otherData.selectedAll = false;
            if (res.data && res.data.status === 200) {
              otherData.data = otherData.data
                .filter(l => !locationIds.includes(l.id))
                .map(l => {
                  l.checked = false;
                  return l;
                });
              otherData.total = otherData.data.length;
              otherData.success =
                res.data && res.data.message
                  ? res.data.message
                  : "Location region was set successfully";
              this.fetchData(this.state.page || 1);
            } else {
              otherData.error =
                res.data && res.data.message
                  ? res.data.message
                  : "Something  wrong happened while saving the regions";
            }
            this.setState({ otherData });
          })
          .catch(err => {
            otherData.isLoading = false;
            otherData.total = 0;
            otherData.data = [];
            otherData.error = err.message;
            this.setState({
              otherData
            });
          });
      }
    }
  };
  fetch(page) {
    return crudRegionService.list(page);
  }

  create(data) {
    return crudRegionService.create(data);
  }

  update(data) {
    delete data.locations;
    return crudRegionService.update(data);
  }

  delete(id) {
    return crudRegionService.remove(id);
  }
  getOtherData = regionId => {
    const otherData = this.state.otherData;
    otherData.selectedAll = false;
    otherData.isLoading = true;
    otherData.selectedRegion = 0;
    this.setState({ otherData });
    LocationService.getRegionLocations(regionId)
      .then(response => {
        otherData.data = response.data.data.map(d => {
          d.checked = false;
          return d;
        });
        otherData.total = response.data.total;
        otherData.regionSelect = response.data.regions;
        otherData.isLoading = false;
        this.setState({ otherData });
      })
      .catch(err => {
        otherData.isLoading = false;
        otherData.total = 0;
        otherData.data = [];
        otherData.selectedAll = false;
        otherData.regionSelect = [];
        otherData.error = err.message;
        this.setState({
          otherData
        });
      });
  };
  componentDidMount() {
    this.getOtherData(0);
  }
}

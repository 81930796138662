import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Blocks/Tooltip/Tooltip";
import StringHelper from "../../helpers/StringHelper";

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const BudgetTableRow = props => {
  const { locationCode, departmentCode, total } = props.budget;
  const monthAmounts = MONTHS.map(month => {
    const budget = StringHelper.formatMoney(props.budget[month] || 0);
    return <td key={month} className="text-nowrap align-middle">${budget}</td>
  });

  return (
    <tr>
      <td className="align-middle text-center">
        <div>
          {/* <Tooltip placement="top" text="Delete">
            <button className="btn btn-danger btn-sm mr-2" onClick={() => props.onDelete(props)}>
              <i className="ion-android-delete" />
            </button>
          </Tooltip> */}
          <Tooltip placement="top" text="Edit">
            <button className="btn btn-info btn-sm" onClick={() => props.onEdit(props)}>
              <i className="fas fa-pen" />
            </button>
          </Tooltip>
        </div>
      </td>
      <td className="text-nowrap align-middle">{locationCode}</td>
      <td className="text-nowrap align-middle">{departmentCode}</td>
      {/* <td className="text-nowrap align-middle">{year}</td> */}
      {monthAmounts}
      <td className="text-nowrap align-middle">${StringHelper.formatMoney(total || 0)}</td>
    </tr>
  );
};

BudgetTableRow.defaultProps = {
  onEdit: () => console.log('No onEdit handler'),
  onDelete: () => console.log('No onDelete handler'),
};

BudgetTableRow.propTypes = {
  budget: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default BudgetTableRow;

import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";

// Login validations schema
const Schema = Yup.object().shape({
    confirmationCode: Yup.string()
        .trim(),
});

export default class ForgotPasswordForm extends Component {
    render() {
        const { email, error } = this.props;
        return (
            <Formik
                initialValues={{
                    confirmationCode: "",
                }}
                validationSchema={Schema}
                onSubmit={async (values, {setSubmitting}) => {
                    await this.props.submitVerificationCode(values);
                    setSubmitting(false);
                }}
            >
                {({errors, values, touched, isSubmitting, handleChange, handleSubmit, handleBlur}) => (
                    <form id="forgot-password" onSubmit={handleSubmit} className="card-body" tabIndex="500">
                        <h5 className="mb-2">Enter verification code</h5>
                        <p className="mb-3">Please check your email address{email && (<b> {email}</b>)} for a confirmation code and insert it below</p>
                        <div className="form-group">
                            <input
                                type="text"
                                name="confirmationCode"
                                id="confirmationCode"
                                autoFocus={true}
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmationCode}
                                placeholder=""
                                disabled={isSubmitting}
                            />
                        </div>
                        {error && (
                            <div className="form-group">
                                <label style={{color: 'red', fontSize: '13px'}} className="error-message">{error}</label>
                            </div>
                        )}

                        <div className="modal-footer">
                            <div className="pull-right">
                                <button type="submit" className="btn btn-info btn-block" disabled={isSubmitting}>
                                    {isSubmitting ? "Verifying..." : "Verify"}
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

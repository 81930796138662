import React from "react";
import Tooltip from "../Blocks/Tooltip/Tooltip";
import moment from "moment";

export const TableRow = ({
  rowData,
  fields,
  onEdit,
  onDelete,
  onDuplicate,
  actions = ["U", "D"]
}) => {
  const shownFields = fields.filter(f => f.showOnTable !== false);

  return (
    <tr>
      <td className="align-middle text-center">
        <div>
          {actions.includes("D") ? (
            <Tooltip placement="top" text="Delete">
              <button className="btn btn-danger btn-sm mr-2" onClick={onDelete}>
                <i className="fas fa-trash" />
              </button>
            </Tooltip>
          ) : null}
          {actions.includes("U") ? (
            <Tooltip placement="top" text="Edit">
              <button className="btn btn-info btn-sm mr-2" onClick={onEdit}>
                <i className="fas fa-pen" />
              </button>
            </Tooltip>
          ) : null}
          {actions.includes("DUP") ? (
            <Tooltip placement="top" text="Duplicate">
              <button className="btn btn-default btn-sm" onClick={onDuplicate}>
                <i className="fas fa-copy" />
              </button>
            </Tooltip>
          ) : null}
        </div>
      </td>
      {shownFields.map(({ name, type, momentFormat, options }, i) => {
        let value = rowData[name];
        if (value !== undefined && value !== null) {
          switch (type) {
            case "toggle":
              value = value ? "Yes" : "No";
              break;
            case "date":
              value = moment(value).format(momentFormat);
              break;
            case "select":
              value = (options.find(op => op.value === value) || {}).name;
              break;
            case "color":
              value = <div className={`color-picker ${value}`} style={{ margin: "auto" }}></div>
              break;
            default:
              value = value && value.toString();
          }
        }
        return (
          <td key={i} className="text-nowrap align-middle">
            {value}
          </td>
        );
      })}
    </tr>
  );
};

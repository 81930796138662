import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ClockInService from "../../services/ClockInService";
import DepartmentService from "../../services/DepartmentService";
import LocationService from "../../services/LocationService";
import "./styles/index.css";

const cdn = "https://dxv8p7y79jw20.cloudfront.net/";

const getLog = ({ type, time }) => {
  const formattedTime = moment(time).format("hh:mm a");
  switch (type) {
    case "shiftStart":
      return (
        <div>
          <b>{formattedTime}</b> - You started shift
        </div>
      );
    case "shiftEnd":
      return (
        <div>
          <b>{formattedTime}</b> - You ended shift
        </div>
      );
    case "breakStart":
      return (
        <div>
          <b>{formattedTime}</b> - You started break
        </div>
      );
    case "breakEnd":
      return (
        <div>
          <b>{formattedTime}</b> - You ended break
        </div>
      );
    case "lunchStart":
      return (
        <div>
          <b>{formattedTime}</b> - You started lunch
        </div>
      );
    case "lunchEnd":
      return (
        <div>
          <b>{formattedTime}</b> - You ended lunch
        </div>
      );
    default:
      return "";
  }
};

function ClockIn({ user }) {
  const [locationOptions, setLocationOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [earningCodeOptions, setEarningCodeOptions] = useState([]);
  const [time, setTime] = useState(moment());
  const [location, setLocation] = useState();
  const [department, setDepartment] = useState();
  const [earningCode, setEarningCode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isActing, setIsActing] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [logs, setLogs] = useState([]);
  const [shift, setShift] = useState({
    id: null,
    started: false,
    inBreak: false,
    inLunchBreak: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(moment());
    }, moment().endOf("m").diff(moment(), "ms"));

    return () => clearTimeout(timer);
  }, [time]);

  useEffect(() => {
    Promise.all([
      LocationService.getUserLocations(),
      DepartmentService.getUserDepartments(),
    ])
      .then((response) => {
        const locations = response[0].data;
        const departments = response[1].data;

        setLocationOptions(locations);
        setLocation(locations[0].locationId);
        setDepartmentOptions(departments);
        setDepartment(departments[0].id);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    ClockInService.getShift()
      .then((res) => {
        let activeShift = {
            id: null,
            started: false,
            inBreak: false,
            inLunchBreak: false,
          },
          logs = [];

        if (
          !res.data.result ||
          !Array.isArray(res.data.result) ||
          res.data.result.length === 0
        )
          return;

        res.data.result.forEach((shift) => {
          logs.push({ type: "shiftStart", time: shift.shift.startedAt });

          logs = logs.concat(
            shift.break.reduce((breakLogs, b) => {
              if (b.status === "running") {
                breakLogs.push({ type: `${b.type}Start`, time: b.startedAt });
                if (b.type === "lunch") activeShift.inLunchBreak = true;
                else activeShift.inBreak = true;
              } else
                breakLogs = breakLogs.concat([
                  {
                    type: `${b.type}Start`,
                    time: b.startedAt,
                  },
                  { type: `${b.type}End`, time: b.endedAt },
                ]);

              return breakLogs;
            }, [])
          );

          if (shift.status === "running") {
            activeShift.started = true;
            activeShift.id = shift.id;
            setLocation(shift.location);
            setDepartment(shift.department);
            setEarningCode(shift.earningCode);
          } else
            logs.push({
              type: "shiftEnd",
              time: shift.shift.endedAt,
            });
        });

        setLogs(logs);
        setShift(activeShift);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  const actionsDisabled = useMemo(
    () => isActing || isLoading,
    [isActing, isLoading]
  );

  const selectsDisabled = useMemo(
    () => isActing || isLoading || shift.started,
    [isActing, isLoading]
  );

  const onStartShift = useCallback(async () => {
    if (actionsDisabled) return;

    setIsActing(true);
    try {
      const res = await ClockInService.startShift({
        location,
        department,
        earningCode,
      });
      setShift((oldShift) => ({ ...oldShift, started: true, id: res.data.id }));
      setLogs((oldLogs) =>
        oldLogs.concat({ type: "shiftStart", time: res.data.shift.startedAt })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsActing(false);
    }
  }, [location, department, earningCode, actionsDisabled]);

  const onEndShift = useCallback(async () => {
    if (actionsDisabled) return;

    setIsActing(true);
    try {
      await ClockInService.endShift(shift.id);
      setShift({
        started: false,
        id: null,
        inBreak: false,
        inLunchBreak: false,
      });
      setLogs((oldLogs) => {
        let newLogs = [...oldLogs];

        if (shift.inBreak) newLogs.push({ type: `breakEnd`, time: moment() });
        if (shift.inLunchBreak)
          newLogs.push({ type: "lunchEnd", time: moment() });
        newLogs.push({ type: `shiftEnd`, time: moment() });
        return newLogs;
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsActing(false);
    }
  }, [shift, actionsDisabled]);

  const onStartBreak = useCallback(
    async (breakType) => {
      if (actionsDisabled) return;

      setIsActing(true);
      try {
        await ClockInService.startBreak(shift.id, breakType);

        setShift((oldShift) => ({
          ...oldShift,
          ...(breakType === "lunch"
            ? { inLunchBreak: true }
            : { inBreak: true }),
        }));
        setLogs((oldLogs) =>
          oldLogs.concat({ type: `${breakType}Start`, time: moment() })
        );
      } catch (e) {
        console.error(e);
      } finally {
        setIsActing(false);
      }
    },
    [shift, actionsDisabled]
  );

  const onEndBreak = useCallback(async () => {
    if (actionsDisabled) return;

    setIsActing(true);
    try {
      await ClockInService.endBreak(shift.id);
      const breakType = shift.inBreak ? "break" : "lunch";
      setShift((oldShift) => ({
        ...oldShift,
        ...(breakType === "lunch"
          ? { inLunchBreak: false }
          : { inBreak: false }),
      }));
      setLogs((oldLogs) =>
        oldLogs.concat({ type: `${breakType}End`, time: moment() })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsActing(false);
    }
  }, [shift, actionsDisabled]);

  const onChangeLocation = useCallback(
    ({ target: { value } }) => {
      if (selectsDisabled) return;
      setLocation(value);
    },
    [selectsDisabled]
  );
  const onChangeDepartment = useCallback(
    ({ target: { value } }) => {
      if (selectsDisabled) return;
      setDepartment(value);
    },
    [selectsDisabled]
  );
  const onChangeEarningCode = useCallback(
    ({ target: { value } }) => {
      if (selectsDisabled) return;
      setEarningCode(value);
    },
    [selectsDisabled]
  );

  return (
    <div className="clock-in-container container content-area">
      <section className="section">
        <div className="section-body">
          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4>
                    Welcome, {user.firstName} {user.lastName}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col col-sm-4 display-flex flex-column align-items-center">
                      <div className="info">
                        {user.employeeCode}, {user.locationDescription}
                      </div>

                      <div
                        className="user-avatar"
                        style={
                          user.avatar
                            ? { backgroundImage: `url(${cdn}${user.avatar})` }
                            : {}
                        }
                      >
                        {!user.avatar && (
                          <div
                            style={{ fontSize: "84px" }}
                          >{`${user.firstName[0]} ${user.lastName[0]}`}</div>
                        )}
                      </div>

                      <div className="time">{time.format("dddd, hh:mm a")}</div>

                      <div className="params">
                        <div className="form-group">
                          <label className="col-form-label">Location</label>
                          <select
                            disabled={selectsDisabled}
                            className="form-control input-sm"
                            placeholder={"Select location..."}
                            name={"location"}
                            onChange={onChangeLocation}
                            value={location}
                          >
                            {locationOptions.map(
                              ({ location: name, locationId: value }) => (
                                <option key={value} value={value}>
                                  {name}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div className="form-group">
                          <label className="col-form-label">Department</label>
                          <select
                            disabled={selectsDisabled}
                            className="form-control input-sm"
                            placeholder={"Select department..."}
                            name={"department"}
                            onChange={onChangeDepartment}
                            value={department}
                          >
                            {departmentOptions.map(
                              ({ description: name, id: value }) => (
                                <option key={value} value={value}>
                                  {name}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div className="form-group">
                          <label className="col-form-label">Earning code</label>
                          <select
                            disabled={selectsDisabled}
                            className="form-control input-sm"
                            placeholder={"Select earning code..."}
                            name={"earningCode"}
                            onChange={onChangeEarningCode}
                            value={earningCode}
                          >
                            {earningCodeOptions.map(({ name, value }) => (
                              <option key={value} value={value}>
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="actions">
                        {!shift.started ? (
                          <button
                            disabled={actionsDisabled}
                            onClick={onStartShift}
                            className="start-shift btn btn-primary btn-lg"
                          >
                            Start shift
                          </button>
                        ) : (
                          <button
                            disabled={actionsDisabled}
                            onClick={onEndShift}
                            className="start-shift btn btn-danger btn-lg"
                          >
                            End shift
                          </button>
                        )}

                        {shift.started && (
                          <>
                            <span>Or go to:</span>

                            {shift.inLunchBreak ? (
                              <button
                                disabled={actionsDisabled}
                                onClick={onEndBreak}
                                className="start-shift btn btn-outline-warning btn-md"
                              >
                                End lunch
                              </button>
                            ) : shift.inBreak ? (
                              <button
                                disabled={actionsDisabled}
                                onClick={onEndBreak}
                                className="start-shift btn btn-outline-info btn-md"
                              >
                                End break
                              </button>
                            ) : (
                              <div className="row breaks">
                                <div className="col">
                                  <button
                                    disabled={actionsDisabled}
                                    onClick={() => onStartBreak("lunch")}
                                    className="start-shift btn btn-outline-warning btn-md"
                                  >
                                    <i className="fas fa-utensils" />
                                    Lunch
                                  </button>
                                </div>
                                <div className="col">
                                  <button
                                    disabled={actionsDisabled}
                                    onClick={() => onStartBreak("break")}
                                    className="start-shift btn btn-outline-info btn-md"
                                  >
                                    <i className="fas fa-coffee" />
                                    Break
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col col-sm-8">
                      <div className="tabs">
                        <button
                          disabled={actionsDisabled}
                          onClick={() => setActiveTab(0)}
                          className={`tab${activeTab === 0 ? " active" : ""}`}
                        >
                          today
                        </button>
                        <button
                          disabled={actionsDisabled}
                          onClick={() => setActiveTab(1)}
                          className={`tab${activeTab === 1 ? " active" : ""}`}
                        >
                          current pay period
                        </button>
                        <button
                          disabled={actionsDisabled}
                          onClick={() => setActiveTab(2)}
                          className={`tab${activeTab === 2 ? " active" : ""}`}
                        >
                          previous pay period
                        </button>
                      </div>

                      <div className="logs">
                        {isLoading ? (
                          <div>Loading...</div>
                        ) : logs.length === 0 ? (
                          <div className="log-placeholder">
                            You don't have an active shift
                          </div>
                        ) : (
                          logs.map((log, index) => (
                            <div key={index} className="log">
                              {getLog(log)}
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ClockIn;

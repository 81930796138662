const locations = [
  [
    {
      value: "Code",
      type: "string"
    },
    {
      value: "Description",
      type: "string"
    },
    {
      value: "State",
      type: "string"
    },
    {
      value: "Country",
      type: "string"
    },
    {
      value: "City",
      type: "string"
    },
    {
      value: "Zip code",
      type: "string"
    },
    {
      value: "Active",
      type: "string"
    }
  ],
  [
    {
      value: "101",
      type: "string"
    },
    {
      value: "101-EQ-Old 76th Street",
      type: "string"
    },
    {
      value: "NY",
      type: "string"
    },
    {
      value: "USA",
      type: "string"
    },
    {
      value: "New York",
      type: "string"
    },
    {
      value: "10024",
      type: "string"
    },
    {
      value: "No",
      type: "string"
    }
  ],
  [
    {
      value: "102",
      type: "string"
    },
    {
      value: "102-EQ - Flatiron",
      type: "string"
    },
    {
      value: "NY",
      type: "string"
    },
    {
      value: "USA",
      type: "string"
    },
    {
      value: "New York",
      type: "string"
    },
    {
      value: "10003",
      type: "string"
    },
    {
      value: "Yes",
      type: "string"
    }
  ]
];
const departments = [
  [
    {
      value: "Code",
      type: "string"
    },
    {
      value: "Description",
      type: "string"
    },
    {
      value: "Active",
      type: "string"
    }
  ],
  [
    {
      value: "001",
      type: "string"
    },
    {
      value: "General Manager",
      type: "string"
    },
    {
      value: "Yes",
      type: "string"
    }
  ],
  [
    {
      value: "002",
      type: "string"
    },
    {
      value: "Complex GM",
      type: "string"
    },
    {
      value: "No",
      type: "string"
    }
  ]
];
const companies = [
  [
    {
      value: "External Id",
      type: "string"
    },
    {
      value: "Code",
      type: "string"
    },
    {
      value: "Name",
      type: "string"
    },
    {
      value: "Active",
      type: "string"
    }
  ],
  [
    {
      value: "001",
      type: "string"
    },
    {
      value: "EQX",
      type: "string"
    },
    {
      value: "Equinox Holdings, Inc",
      type: "string"
    },
    {
      value: "Yes",
      type: "string"
    }
  ],
  [
    {
      value: "002",
      type: "string"
    },
    {
      value: "EQHI",
      type: "string"
    },
    {
      value: "Equinox Holdings, Inc.",
      type: "string"
    },
    {
      value: "No",
      type: "string"
    }
  ]
];
const jobs = [
  [
    {
      value: "Code",
      type: "string"
    },
    {
      value: "Title",
      type: "string"
    },
    {
      value: "Country",
      type: "string"
    },
    {
      value: "Family Code",
      type: "string"
    },
    {
      value: "Active",
      type: "string"
    }
  ],
  [
    {
      value: "100001",
      type: "string"
    },
    {
      value: "Exe Chairman Managing Ptr",
      type: "string"
    },
    {
      value: "USA",
      type: "string"
    },
    {
      value: "EVP",
      type: "string"
    },
    {
      value: "Yes",
      type: "string"
    }
  ]
];
const employees = [
  [
    { value: "External id", type: "string" },
    { value: "Code", type: "string" },
    { value: "Company", type: "string" },
    { value: "Location", type: "string" },
    { value: "Supervisor external id", type: "string" },
    { value: "Job", type: "string" },
    { value: "Department", type: "string" },
    { value: "Job change reason code", type: "string" },
    { value: "Pay group description", type: "string" },
    { value: "Pay group code", type: "string" },
    { value: "Pay period", type: "string" },
    { value: "Salary hourly", type: "string" },
    { value: "Hourly rate", type: "string" },
    { value: "Work phone", type: "string" },
    { value: "Home phone", type: "string" },
    { value: "Work email", type: "string" },
    { value: "Personal email", type: "string" },
    { value: "Project code", type: "string" },
    { value: "Org level 1 code", type: "string" },
    { value: "Org level 2 code", type: "string" },
    { value: "Org level 3 code", type: "string" },
    { value: "Org level 4 code", type: "string" },
    { value: "Last Hire", type: "string" },
    { value: "Date of termination", type: "string" }
  ],
  [
    { value: "001", type: "string" },
    { value: "022147", type: "string" },
    { value: "Equinox Holdings, Inc", type: "string" },
    { value: "130-EQ - Brooklyn Heights", type: "string" },
    { value: "001", type: "string" },
    { value: "Group Fitness Instructor", type: "string" },
    { value: "Group Fitness", type: "string" },
    { value: "Job change reason code", type: "string" },
    { value: "Pay group description", type: "string" },
    { value: "Pay group code", type: "string" },
    { value: "Pay period", type: "string" },
    { value: "H", type: "string" },
    { value: "Hourly rate", type: "string" },
    { value: "Work phone", type: "string" },
    { value: "Home phone", type: "string" },
    { value: "example@email.com", type: "string" },
    { value: "example@email.com", type: "string" },
    { value: "Project code", type: "string" },
    { value: "Org level 1 code", type: "string" },
    { value: "Org level 2 code", type: "string" },
    { value: "Org level 3 code", type: "string" },
    { value: "Org level 4 code", type: "string" },
    { value: "Last Hire", type: "string" },
    { value: "Date of termination", type: "string" }
  ]
];
const budgets = [
  [
    { value: "Location Code", type: "string" },
    { value: "Department Code", type: "string" },
    { value: "Jan", type: "string" },
    { value: "Feb", type: "string" },
    { value: "Mar", type: "string" },
    { value: "Apr", type: "string" },
    { value: "May", type: "string" },
    { value: "Jun", type: "string" },
    { value: "Jul", type: "string" },
    { value: "Aug", type: "string" },
    { value: "Sep", type: "string" },
    { value: "Oct", type: "string" },
    { value: "Nov", type: "string" },
    { value: "Dec", type: "string" }
  ],
  [
    { value: "102", type: "string" },
    { value: "022", type: "string" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" },
    { value: 0, type: "number" }
  ]
];
const secondaryJob = [
  [
    { value: "Employee Code", type: "string" },
    { value: "Employee", type: "string" },
    { value: "Code", type: "string" },
    { value: "Title", type: "string" },
    { value: "Hourly Rate", type: "string" },
    { value: "Start date", type: "date" }
  ],
  [
    { value: "000001", type: "string" },
    { value: "Employee Name", type: "string" },
    { value: "600103", type: "string" },
    { value: "PT - Tier 3", type: "string" },
    { value: 15.0, type: "number" },
    { value: "2018-01-01", type: "string" }
  ]
]
const secondaryLocation = [
  [
    { value: "Employee Code", type: "string" },
    { value: "Employee", type: "string" },
    { value: "Code", type: "string" },
    { value: "Title", type: "string" }
  ],
  [
    { value: "000001", type: "string" },
    { value: "Employee Name", type: "string" },
    { value: "101", type: "string" },
    { value: "101-EQ-Old 76th Street", type: "string" },
  ]
]

module.exports = {
  locations,
  departments,
  companies,
  jobs,
  employees,
  budgets,
  secondaryJob,
  secondaryLocation
};

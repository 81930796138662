import React, { Component } from "react";
import moment from "moment";
import Select from "react-select";

import { DateRangePicker } from "react-dates";
import ReportService from "../../../services/ReportService";
import { getWeekStartDay } from "../../Schedule/helpers/DateHelper";

export default class ScheduleLogFilterForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      values: {
        startDate: moment().subtract(30, "days"),
        endDate: moment()
      },
      focusedInput: null
    };
  }

  filter = e => {
    e && e.preventDefault();

    this.props.filter({
      ...this.state.values,
      startDate: this.state.values.startDate.toISOString(),
      endDate: this.state.values.endDate.toISOString()
    });
  };

  onTypeChange = ({ value: action }) => {
    this.setState(({ values }) => ({
      values: { ...values, action }
    }));
  };

  onDateRangeFocusChange = focusedInput => {
    this.setState({
      focusedInput: !focusedInput ? null : focusedInput
    });
  };

  onDateRangeChange = ({ startDate, endDate }) => {
    const values = { ...this.state.values };
    if (startDate) {
      values.startDate = startDate;
      values.endDate = null;
    }
    if (endDate) values.endDate = endDate;
    this.setState({ values }, () => startDate && endDate && this.filter());
  };

  onUserChange = users => {
    this.setState(({ values }) => {
      return {
        values: { ...values, userIds: users.map(u => u.value) }
      };
    });
  };

  formatUsers = users => {
    return users.map(({ id: value, name: label }) => ({
      label,
      value
    }));
  };

  componentWillMount = async () => {
    try {
      const users = await ReportService.getLogsUsers();
      this.setState({ users: this.formatUsers(users.data) });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { values, focusedInput, users } = this.state;
    const { isLoading, user } = this.props;
    return (
      <form onSubmit={this.filter}>
        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Date:
          </label>
          <div className="col-md-8">
            <DateRangePicker
              firstDayOfWeek={getWeekStartDay(user)}
              startDateId="startDate"
              endDateId="endDate"
              startDate={values.startDate}
              endDate={values.endDate}
              onDatesChange={this.onDateRangeChange}
              onFocusChange={this.onDateRangeFocusChange}
              focusedInput={focusedInput}
              isOutsideRange={() => {
                return false;
              }}
              disabled={isLoading}
              small={true}
            />
          </div>
        </div>

        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Users:
          </label>
          <div className="col-md-8">
            <Select
              name="users"
              isMulti={true}
              onChange={this.onUserChange}
              disabled={isLoading}
              options={users}
              placeholder="Type to search for Users..."
            />
          </div>
        </div>

        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Action Type:
          </label>
          <div className="col-md-8">
            <Select
              name="actionTypes"
              onChange={this.onTypeChange}
              isDisabled={isLoading}
              placeholder="Select Action Type..."
              // value={values.reportType}
              options={[
                { value: "all", label: "All events" },
                { value: "update", label: "Updated events" },
                { value: "delete", label: "Deleted events" }
              ]}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 mt-3">
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary"
            >
              {isLoading ? "Generating..." : "Generate"}
            </button>
          </div>
        </div>
      </form>
    );
  }

  renderSelectOptions(options) {
    return options.map(({ name, label }) => (
      <option key={name} value={name}>
        {label}
      </option>
    ));
  }
}

import React from "react";
import { NavLink, Route } from "react-router-dom";
import moment from "moment";
// Services
import MessagingService from "../../services/MessagingService";
import MessagePreview from "../MessagePreview/MessagePreview";
import StringHelper from "../../helpers/StringHelper";
import Avatar from "../../components/Blocks/Avatar";
// Styles
import "./assets/styles/Messages.css";

class Messages extends React.Component {
  state = {
    isLoading: true,
    allMessages: [],
    messages: [],
    search: "",
    messagesNavbar: false
  };

  fetchAllMessages() {
    MessagingService.fetchAllMessages()
      .then(res => {
        if (res.data.receive) {
          this.setState({
            messages: res.data.receive,
            allMessages: res.data.receive,
            isLoading: false
          });
        } else {
          alert("Something went wrong!");
          this.setState({
            isLoading: false
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoading: false
        });
      });
  }

  searchMsg = e => {
    this.setState({
      search: e.target.value.trim()
    });
  };

  submitForm = e => {
    e.preventDefault();

    let { allMessages } = this.state;

    allMessages = allMessages.filter(
      msg =>
        msg.body.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    );

    this.setState({
      messages: allMessages
    });
  };

  toggleMessagesNavbar = e => {
    e && e.preventDefault();

    this.setState(prevState => {
      return {
        messagesNavbar: !prevState.messagesNavbar
      };
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.state.messagesNavbar && this.toggleMessagesNavbar();
    }
  }

  componentDidMount() {
    this.fetchAllMessages();
  }

  render() {
    const { isLoading, messages } = this.state;
    const { user } = this.props;

    const preLoader = isLoading && messages.length === 0 && (
      <p className="p-4 text-muted">Loading...</p>
    );

    const noMessages = !isLoading && messages.length === 0 && (
      <p className="p-4 text-muted">No messages to show.</p>
    );

    return (
      <div className="container content-area">
        <section className="section">
          <div className="section-body">
            <div className="container content-area mt-5">
              <div className="section">
                <div className="col-12 col-md-3 col-lg-2 p-0 mb-4">
                  <NavLink
                    to="/app/compose-new-message"
                    className="btn btn-primary w-100"
                  >
                    <i className="fas fa-pen" /> Compose
                  </NavLink>
                </div>

                <div className="messages-container">
                  <nav
                    className={`${
                      this.state.messagesNavbar ? " messages-navbar-shown" : ""
                      }`}
                  >
                    <form
                      className="search-messages"
                      onSubmit={this.submitForm}
                    >
                      <input
                        type="search"
                        onChange={this.searchMsg}
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <button type="submit">
                        <span>Submit</span>
                        <i className="fas fa-search" />
                      </button>
                    </form>

                    <div className="messages-list">
                      {preLoader}
                      {noMessages}
                      {messages.map((msg, key) => (
                        <NavLink
                          to={`/app/messages/${msg.replyOf}`}
                          key={key}
                          exact
                          activeClassName="chat-active"
                        >
                          <Avatar size={60} item={msg} />

                          <div className="details">
                            <h5 className="">
                              {StringHelper.textTruncate(msg.groupName, 40)}
                            </h5>

                            <div className="body">
                              {`${msg.you} ${msg.body}`}
                            </div>

                            <small className="">
                              <i className="far fa-clock mr-1 ml-0" />

                              <time>{moment(msg.createdAt).fromNow()}</time>
                            </small>
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  </nav>

                  <div className="inbox">
                    <Route
                      path="/app/messages/:id"
                      exact
                      component={(props) => <MessagePreview {...props} user={user} />}
                    />
                    <Route
                      path="/app/messages"
                      exact
                      render={() => (
                        <p className="p-4 text-muted">No message selected</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Messages.propTypes = {};

export default Messages;

import React from "react";
import moment from "moment";
import momentGB from 'moment';
import 'moment/locale/en-gb';
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import AddEvent from "./AddEvent";

import "./Assets/Styles/dnd-styles.css";
import "./Assets/Styles/react-big-calendar.css";
import "./Assets/Styles/colors.css";

import "../BryntumScheduler/assets/scheduler.material.css";
import "../BryntumScheduler/assets/styles.css";
import AllEventsModal from "./AllEventsModal";
import colorMapping from "../BryntumScheduler/colors";
import { SCHEDULE_MAPPED_EVENTS } from "../../containers/Schedule/Constant";
import getContextMenu from "./GetContextMenu";
import Duration from "../../helpers/Duration";
import { canPublish } from "../Schedule/helpers/SchedulerHelper";

const contextMenuWidth = 180;
const contextMenuItemHeight = 50;
momentGB.locale('en-GB');
const localizer = momentLocalizer(moment);
// const DragAndDropCalendar = withDragAndDrop(Calendar);

const mapEvents = (events, extra = true) =>
    Object.keys(events).reduce((previous, date) => {
        const dateEvents = events[date].events;
        const dateTotal = events[date].total;

        const newEvents = [
            ...previous,
            ...dateEvents.map(e => ({
                start: moment(e.startDate).toDate(),
                end: moment(e.endDate).toDate(),
                ...e,
                sectionId: e.sectionId && typeof e.sectionId === 'string' ? JSON.parse(e.sectionId) : (e.sectionId || []),
                sectionData: e.sectionData && typeof e.sectionData === 'string' ? JSON.parse(e.sectionData) : (e.sectionData || []),
            }))
        ];

        if (dateEvents.length < dateTotal && extra)
            for (let i = 0; i < dateTotal - dateEvents.length; i++) {
                newEvents.push({
                    start: moment(date)
                        .endOf("day")
                        .toDate(),
                    end: moment(date)
                        .endOf("day")
                        .toDate(),
                    status: "",
                    id: -1,
                    name: ""
                });
            }

        return newEvents;
    }, []);

class CalendarView extends React.Component {
    state = {
        events: [],
        edit: false,
        currentDate: moment(),
        ctrlPressed: false,
        selectedDate: undefined,
        selectedEvents: [],
        rightClickedEvent: undefined,
        contextMenu: { position: { x: 0, y: 0 }, type: null },
        showMore: {
            position: { x: 0, y: 0 },
            visible: false,
            date: null
        },
        addEventOpen: false
    };

    getContextMenu = type => {
        let { selectedEvents, rightClickedEvent: eventData } = this.state;
        const {
            readOnly,
            self,
            manager,
            user,
            notifyForEvent,
            releaseEvent,
            submitEvents,
            copyEvents,
            changeToRegular,
            publishEvents,
            makeAvailable
        } = this.props;
        switch (type) {
            case "date":
                return [
                    {
                        name: "Add",
                        icon: "fas fa-plus",
                        onClick: () => this.addEvent(false)
                    }
                ];
            case "event":
                return getContextMenu({
                    selectedEvents,
                    eventData,
                    readOnly,
                    mySchedule: self,
                    manager,
                    addEvent: this.addEvent,
                    notifyForEvent,
                    releaseEvent,
                    onEventDelete: this.deleteEvent,
                    submitEvents,
                    copyEvents,
                    changeToRegular,
                    publishEvents,
                    makeAvailable,
                    canPublish: canPublish(user)
                });
            default:
                return [
                    {
                        name: "Add",
                        onClick: () => this.addEvent(false)
                    }
                ];
        }
    };

    addEvent = (edit = false) => {
        const { readOnly } = this.props;
        if (!readOnly)
            this.setState(
                ({ selectedEvents }) => ({
                    edit,
                    selectedEvents: edit ? selectedEvents : [],
                    addEventOpen: true
                }),
                () => {
                    this.props.onSelectEventsChange(this.state.selectedEvents);
                }
            );
    };
    deleteEvent = (event, selectedEvents) => {
        const { onEventDelete } = this.props;
        this.setState(
            {
                edit: false,
                selectedDate: undefined,
                selectedEvents: [],
                rightClickedEvent: undefined,
                contextMenu: { position: { x: 0, y: 0 }, type: null }
            },
            () => {
                this.props.onSelectEventsChange(this.state.selectedEvents);
            }
        );
        return onEventDelete(event, selectedEvents, true);
    };

    clickOutside = e => {
        const isOutsideClick = classList =>
            [...e.path].every(node =>
                !!node.classList
                    ? [...node.classList].every(c => !classList.includes(c))
                    : true
            );

        isOutsideClick(["context-menu"]) &&
            this.setState({
                contextMenu: { type: null, position: { x: 0, y: 0 } }
            });

        isOutsideClick(["all-events-modal", "rbc-show-more"]) &&
            this.setState({
                showMore: {
                    visible: false,
                    date: null,
                    position: { x: 0, y: 0 }
                }
            });
    };

    onKeydown = e => {
        ["Control", "Meta"].includes(e.key) &&
            this.setState({ ctrlPressed: true });
    };

    onKeyup = e => {
        ["Control", "Meta"].includes(e.key) &&
            this.setState({ ctrlPressed: false });
    };
    setSelectedEvents = selectedEvents => {
        this.setState({
            selectedEvents
        });
    };
    onClickShowMore = e => {
        this.setState(({ showMore }) => ({
            showMore: {
                ...showMore,
                visible: true,
                position: { x: e.clientX, y: e.clientY }
            }
        }));
    };
    canAdd = (date, isOutOfRange) => {
        const { mapEvents: mEvents, self } = this.props;
        const mEvent = mEvents[moment(date).format("YYYY-MM-DD")];
        let showAdd = !isOutOfRange;
        if (self && mEvent && mEvent.events && mEvent.events.length) {
            showAdd =
                mEvent.events[0].name !==
                SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF;
        }
        return showAdd;
    };
    componentDidUpdate = () => {
        Array.from(
            document.getElementsByClassName("rbc-show-more")
        ).forEach(s => s.addEventListener("click", this.onClickShowMore));
    };

    componentDidMount = async () => {
        document.addEventListener("click", this.clickOutside);
        document.addEventListener("keydown", this.onKeydown);
        document.addEventListener("keyup", this.onKeyup);

        /*const { currentDate } = this.state;
        const res = await ScheduleService.fetchMonthSchedules({
            page: 0,
            startDate: currentDate.startOf("month").format("YYYY-MM-DD"),
            endDate: currentDate.endOf("month").format("YYYY-MM-DD")
        });
        // console.log(res.data);*/

        this.setState({
            currentDate: moment(this.props.config.startDate || undefined)
        });
    };

    componentWillUnmount = () => {
        document.removeEventListener("click", this.clickOutside);
        document.removeEventListener("keydown", this.onKeydown);
        document.removeEventListener("keyup", this.onKeyup);
    };

    openDateContextMenu = (e, date, canAdd = true) => {
        e.preventDefault();
        if (!canAdd) return;
        this.setState({
            selectedDate: date,
            contextMenu: {
                position: { x: e.clientX, y: e.clientY },
                type: "date"
            }
        });
    };

    openEventContextMenu = (e, event) => {
        e.preventDefault();

        const { selectedEvents } = this.state;
        const isAlreadySelected = selectedEvents.find(e => e.id === event.id);

        this.setState(
            {
                selectedEvents:
                    selectedEvents.length > 1
                        ? isAlreadySelected
                            ? selectedEvents
                            : [...selectedEvents, event]
                        : [event],
                rightClickedEvent: event,
                contextMenu: {
                    position: { x: e.clientX, y: e.clientY },
                    type: "event"
                }
            },
            () => {
                this.props.onSelectEventsChange(this.state.selectedEvents);
            }
        );
    };

    selectSlot = date => {
        this.setState({ selectedDate: moment(date) }, this.addEvent);
    };

    openEvent = event => {
        const { self } = this.props;
        (![
            SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF,
            SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY
        ].includes(event.name) ||
            self) &&
            !event.leaveId &&
            this.setState(
                {
                    selectedEvents: [event],
                    selectedDate: moment(event.currentDate)
                },
                () => {
                    this.addEvent(true);
                    this.props.onSelectEventsChange(this.state.selectedEvents);
                }
            );
    };

    selectEvent = event => {
        const { selectedEvents, ctrlPressed } = this.state;

        const isAlreadySelected = !!selectedEvents.find(e => e.id === event.id);

        const newSelectedEvents = ctrlPressed
            ? isAlreadySelected
                ? selectedEvents.filter(e => e.id !== event.id)
                : [...selectedEvents, event]
            : [event];

        this.setState(
            {
                selectedEvents: newSelectedEvents
            },
            () => {
                this.props.onSelectEventsChange(this.state.selectedEvents);
            }
        );
    };

    renderEvent = ({ event }) => {
        const { self, scheduleConfigs } = this.props;
        const duration = Duration.durationAsHoursAndMinsString(
            event.end,
            event.start,
            event.breaks
        );
        let sectionData = [];
        if (scheduleConfigs.sections && (scheduleConfigs.sections[event.jobId] || scheduleConfigs.sections['all']) && event.sectionId) {
            const jobSections = scheduleConfigs.sections[event.jobId] || [];
            const allSections = scheduleConfigs.sections['all'] || [];
            const sections = [...jobSections, ...allSections];
            if (!Array.isArray(event.sectionId)) {
                event.sectionId = [event.sectionId];
            }
            if (!Array.isArray(event.sectionData)) {
                event.sectionData = [];
            }
            sectionData = event.sectionData;
            const selectedSections = sections.filter(s => event.sectionId.includes(s.value));
            selectedSections.forEach(s => {
                if (!sectionData.find(sd => sd.zoneId === s.value)) {
                    sectionData.push({
                        startTime: moment(event.start).format('HH:mm'),
                        startTimeDisplay: moment(event.start).format('HH:mm A'),
                        endTime: moment(event.end).format('HH:mm'),
                        endTimeDisplay: moment(event.end).format('HH:mm A'),
                        zoneColor: s.color,
                        zoneId: s.value,
                        zone: s.text,
                        id: s.value
                    });
                }
            })
        }
        const start = moment(event.start);
        const end = moment(event.end);
        const breaks = event.breaks > 0 ? ` • Break ${event.breaks} min` : "";
        return (
            <div
                style={{ width: "100%", fontSize: "0.8em" }}
                onClick={e => e.preventDefault()}
            >
                {event.name !== SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF && (
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            alignItems: "center"
                        }}
                    >
                        {`${start.format("h:mm A")} - ${end.format("h:mm A")}`}
                        &ensp;&bull;&ensp;
                        {duration}
                    </div>
                )}

                <div
                    style={{
                        display: "flex",
                        flexDirection:
                            event.name ===
                                SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF
                                ? "column"
                                : "row",
                        alignItems:
                            event.name ===
                                SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF
                                ? "flex-start"
                                : "center",
                        width: "100%",
                        textOverlow: "ellipsis"
                    }}
                >
                    <div style={{ marginRight: "5px" }}>
                        <b>{event.name}</b>
                        {breaks}
                        &ensp;<i style={{ fontSize: "1em" }} className="fas fa-map-marker-alt" />&nbsp;
                        {event.locationCode}
                    </div>
                    {event.name === SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF &&
                        event.leaveId ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexFlow: "row wrap",
                                    alignItems: "center"
                                }}
                            >
                                Generated By Leave
                            </div>
                        ) : null
                    }
                </div>
                {Array.isArray(sectionData) && sectionData.length > 0 && (
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            alignItems: "center"
                        }}
                    >
                        {sectionData.map(s => <span className={`${s.zoneColor ? `zone ${s.zoneColor}` : ''}`}>&ensp;{s.zone} | {s.startTimeDisplay || s.startTime}-{s.endTimeDisplay || s.endTime}</span>)}
                    </div>
                )}
                {!self && (
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            alignItems: "center",
                            width: "100%",
                            textOverlow: "ellipsis"
                        }}
                    >
                        <i
                            style={{ fontSize: "1em" }}
                            className="fas fa-user"
                        />
                            &ensp;
                        {event.firstName} {event.lastName}
                    </div>
                )}
            </div>
        );
    };

    renderEventWrapper = ({ event, children }) => {
        const { name, status, id, color: zoneColor } = event;
        const { selectedEvents } = this.state;
        const isSelected = selectedEvents.find(e => e.id === id);

        const color = status === "approved" ? colorMapping[name] : colorMapping[status];

        return (
            <div
                onContextMenu={e => this.openEventContextMenu(e, event)}
                className={`event-wrapper event-color-${color} ${zoneColor ? zoneColor : ''} ${isSelected ? ` selected` : ""
                    }`}
                onClick={e => e.preventDefault()}
            >
                {children}
            </div>
        );
    };

    renderDate = ({ value }) => {
        const { currentDate } = this.state;
        const { readOnly } = this.props;

        const isOutOfRange =
            moment(value).isBefore(currentDate.startOf("month")) ||
            moment(value).isAfter(currentDate.endOf("month"));

        const isToday =
            moment()
                .startOf("day")
                .diff(moment(value), "days") === 0;
        const canAdd = !readOnly && this.canAdd(value, isOutOfRange);
        return (
            <div
                onClick={() =>
                    this.setState({ selectedEvents: [] }, () => {
                        this.props.onSelectEventsChange(
                            this.state.selectedEvents
                        );
                    })
                }
                onDoubleClick={e => {
                    if (canAdd) this.selectSlot(value);
                }}
                onContextMenu={e =>
                    this.openDateContextMenu(e, moment(value), canAdd)
                }
                className={`rbc-day-bg ${isOutOfRange ? "rbc-off-range-bg" : ""
                    } ${isToday ? "rbc-today" : ""}`}
            />
        );
    };

    renderDateHeader = ({ label, date, isOffRange }) => {
        const { readOnly } = this.props;
        return (
            <div className="rbc-date-header">
                {!readOnly && this.canAdd(date, isOffRange) ? (
                    <button
                        className="add-event-btn"
                        onClick={() => {
                            this.setState({
                                selectedDate: moment(date),
                                selectedEvents: [],
                                addEventOpen: true
                            });
                        }}
                    >
                        +
                    </button>
                ) : (
                        <span className="add-event-span" />
                    )}

                {label}
            </div>
        );
    };

    renderContextMenu = () => {
        const {
            contextMenu: {
                type: contextMenuType,
                position: { x, y }
            }
        } = this.state;

        const options = this.getContextMenu(contextMenuType);
        const optionNr = options.filter(o => o.renderIf).length;

        const bottomThreshold =
            window.innerHeight - optionNr * contextMenuItemHeight - 10;
        const rightThreshold = window.innerWidth - contextMenuWidth - 30;

        const top = y > bottomThreshold ? bottomThreshold : y;
        const left = x > rightThreshold ? rightThreshold : x;

        return contextMenuType ? (
            <div
                className={`context-menu `}
                style={{ top, left }}
            >
                {options.map(m => {
                    return (
                        (m.renderIf === undefined || m.renderIf) && (
                            <div
                                key={m.name}
                                onClick={() => {
                                    this.setState(
                                        {
                                            contextMenu: {
                                                position: { x: 0, y: 0 },
                                                type: null
                                            }
                                        },
                                        m.onClick
                                    );
                                }}
                                className="context-menu-element"
                            >
                                <i className={m.icon} /> {m.name}
                            </div>
                        )
                    );
                })}
            </div>
        ) : null;
    };

    render() {
        const {
            addEventOpen,
            selectedDate,
            selectedEvents,
            edit,
            showMore
        } = this.state;
        const { events, readOnly, config, getNextPage } = this.props;
        const {
            locations,
            jobs,
            user,
            self,
            onScheduleSave,
            scheduleConfigs,
            filters,
            setEmployeeRates
        } = this.props;
        const contextMenu = this.renderContextMenu();

        return (
            <div
                style={{
                    height: "100vh",
                    padding: "1em 0",
                    position: "relative"
                }}
            >
                {readOnly ? null : contextMenu}
                {addEventOpen && (
                    <AddEvent
                        user={user}
                        self={self}
                        edit={edit}
                        event={selectedEvents[0]}
                        locations={locations}
                        jobs={jobs}
                        isOpen={addEventOpen}
                        selectedDate={selectedDate}
                        scheduleConfigs={scheduleConfigs}
                        onRequestClose={() =>
                            this.setState(
                                {
                                    edit: false,
                                    addEventOpen: false,
                                    selectedDate: undefined,
                                    selectedEvents: [],
                                    rightClickedEvent: undefined
                                },
                                () => {
                                    this.props.onSelectEventsChange(
                                        this.state.selectedEvents
                                    );
                                }
                            )
                        }
                        onScheduleDelete={this.deleteEvent}
                        onScheduleSave={onScheduleSave}
                        filters={filters}
                        setEmployeeRates={setEmployeeRates}
                    />
                )}

                <Calendar
                    onShowMore={(events, date) => {
                        this.setState(({ showMore }) => ({
                            showMore: {
                                ...showMore,
                                date: moment(date)
                            }
                        }));
                    }}
                    popup={false}
                    selectable
                    views={[Views.MONTH]}
                    culture={user && user.weekConf === 'week' ? 'en-US' : 'en-GB'}
                    localizer={user && user.weekConf === 'week' ? localizer : momentLocalizer(momentGB)}
                    events={events}
                    toolbar={false}
                    date={config.startDate}
                    defaultDate={
                        new Date(config.startDate.toLocaleDateString())
                    }
                    onNavigate={() => { }}
                    defaultView={Views.MONTH}
                    onSelectEvent={this.selectEvent}
                    onDoubleClickEvent={this.openEvent}
                    components={{
                        event: this.renderEvent,
                        eventWrapper: this.renderEventWrapper,
                        dateCellWrapper: this.renderDate,
                        month: {
                            dateHeader: this.renderDateHeader
                        }
                    }}
                />

                <AllEventsModal
                    filters={filters}
                    mapEvents={mapEvents}
                    openEventContextMenu={this.openEventContextMenu}
                    selectEvent={this.selectEvent}
                    openEvent={this.openEvent}
                    selectedEvents={selectedEvents}
                    position={showMore.position}
                    date={showMore.date}
                    visible={showMore.visible}
                    getNextPage={getNextPage}
                    firstPage={events}
                    scheduleConfigs={scheduleConfigs}
                />
            </div>
        );
    }
}

export default CalendarView;

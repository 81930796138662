import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Toast from "../../components/Blocks/Toast";
import ComposePrsnlMsgForm from "../../components/Forms/ComposePrsnlMsgForm";
import "./assets/styles/ComposePersonalMsg.css";

// Services
import EmployeeService from "../../services/EmployeeService";

export default class ComposePersonalMsg extends Component {
  state = {
    userDetails: {},
    error: null,
    success: null,
    firstName: "",
    lastName: "",
    receiver: "",
    selectedUser: null,
    usersSuggestion: [],
    attachment: null
  };

  fetchEmployeeDetails() {
    EmployeeService.fetchAllUsers()
      .then(res => {
        if (res.data) {
          const preparedResults = [];

          res.data.forEach(result => {
            preparedResults.push({
              value: result.employee_code,
              label: `${result.first_name} ${result.last_name}`
            });
          });

          this.setState({
            usersSuggestion: preparedResults
          });
        } else {
          this.setState({
            error: (
              <div>
                <p>404, User not found!</p>
                <Link to="/app/people" className="btn btn-info">
                  Go back
                </Link>
              </div>
            )
          });
        }
      })
      .catch(err => console.log(err));
  }

  async componentDidMount() {
    this.fetchEmployeeDetails();
    // this.setState({
    //   usersSuggestion: [
    //     { value: "chocolate", label: "Chocolate" },
    //     { value: "strawberry", label: "Strawberry" },
    //     { value: "vanilla", label: "Vanilla" },
    //   ],
    // });
  }

  toastDismiss = () => {
    this.setState({
      error: null,
      success: null
    });
  };

  submitMsg = async fields => {
    const { attachment } = this.state;
    const message = {
      body: fields.body,
      receiver: fields.user
    };
    if (attachment) {
      if (attachment.preview.type === "url") {
        message.attachmentUrl = attachment.url;
      } else {
        message.attachment = attachment;
      }
    }
    return EmployeeService.sendMessage(message)
      .then(res =>
        this.setState(
          {
            success: res.data.message,
            attachment: null
          },
          () => {
            setTimeout(() => {
              this.props.history.push("/app/messages");
            }, 2500);
          }
        )
      )
      .catch(err => {
        this.setState({
          attachment: null,
          error: "Message has not been sent, please try again!"
        });
      });
  };

  saveVideoModal = attachment => {
    this.setState({
      attachment
    });
  };

  onUploadFilesChanged = files => {
    if (files[0]) {
      this.setState({
        attachment: files[0]
      });
    } else {
      this.setState({
        attachment: null
      });
    }
  };

  removeAttachment = () => {
    this.setState({
      attachment: null
    });
  };
  render() {
    const {
      error,
      success,
      usersSuggestion,
      selectedUser,
      attachment
    } = this.state;

    const errorToast = error && (
      <Toast
        title="Compose new message error!"
        message={error}
        dismiss={this.toastDismiss}
      />
    );
    const successToast = success && (
      <Toast
        status="success"
        title="Message has been sent."
        message={success}
        dismiss={this.toastDismiss}
      />
    );

    return (
      <div className="container content-area">
        {errorToast}
        {successToast}
        <section className="section">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/app/messages" className="text-muted">
                Messages
              </Link>
            </li>
            <li className="breadcrumb-item active text-" aria-current="page">
              Compose New Message
            </li>
          </ol>

          <div className="section-body">
            <div className="row">
              <div className="col-xl-9 col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Compose New Message</h4>
                  </div>
                  <div className="card-body">
                    <ComposePrsnlMsgForm
                      usersSuggestion={usersSuggestion}
                      selectedUser={selectedUser}
                      submitMsg={this.submitMsg}
                      onUploadFilesChanged={this.onUploadFilesChanged}
                      saveVideoModal={this.saveVideoModal}
                      removeAttachment={this.removeAttachment}
                      attachment={attachment}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

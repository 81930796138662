import BaseService from "./BaseService";

class LocationsService extends BaseService {
  getLoggedInUserLocation() {
    return this.apiGet({
      url: `/locations`,
    });
  }
  getAllLocations() {
    return this.apiGet({
      url: `/employees/filter/locations`,
    });
  }
  getLocations() {
    return this.apiGet({
      url: "/locations",
    });
  }
  getRegionLocations(regionId) {
    return this.apiGet({
      url: `/regions/${regionId}/locations`,
    });
  }
  getLocationTreeByState(filter) {
    return this.apiGet({
      url: "/locations/tree-by-state",
      params: filter,
    });
  }
  changeLocationRegion(regionId, locationIds) {
    return this.apiPost({
      url: `/regions/locations/change-region`,
      data: { locationIds, regionId },
    });
  }
  changeLocationTimezone(locationIds, timezoneId) {
    return this.apiPut({
      url: "/locations/timezone",
      data: { locationIds, timezoneId },
    });
  }

  getUserLocations() {
    return this.apiGet({ url: "/locations/self" });
  }
}

export default new LocationsService();

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Leaves as LeavesComponent, ApproveLeaves } from '../../components/Leaves';
import './assets/styles/style.css';

class Leaves extends Component {
    constructor(props) {
        super(props);
        const { user } = props;
        this.state = {
            isLoading: true,
            user,
        };
    }
    componentDidMount = () => {
    };
    setLoading = (loading) => {
        this.setState({
            isLoading: loading
        })
    };
    render() {
        const {
            user,
            isLoading,
        } = this.state;
        const isManager = user && user.role === 'manager';

        return (
            <div className="container content-area">
                <div className="section">
                    <div className="card mt-5">
                        <div className="card-header">
                            <h4>Time off request Management</h4>
                        </div>
                        {isLoading && (
                            <div className={"loader"}>
                                <div id="spinner" />
                            </div>
                        )}
                        <div className="card-body">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <NavLink
                                        to="/app/leaves"
                                        exact
                                        className={"nav-link show"}
                                        activeClassName="active">
                                        Time off request
                                    </NavLink>
                                </li>
                                {isManager && (
                                    <li className="nav-item">
                                        <NavLink
                                            to="/app/leaves/approve"
                                            className={"nav-link show"}
                                            activeClassName="active">
                                            To Be Approved
                                      </NavLink>
                                    </li>
                                )}
                            </ul>
                            {this.props.location.pathname === "/app/leaves" && (
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade active show"
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        {
                                            user && user.id && (
                                                <LeavesComponent
                                                    setLoading={this.setLoading}
                                                    user={user}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            )}
                            {this.props.location.pathname === "/app/leaves/approve" && isManager && (
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade p-3 active show"
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        {
                                            user && user.id && (
                                                <ApproveLeaves
                                                    setLoading={this.setLoading}
                                                    user={user}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Leaves;

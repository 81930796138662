import CrudPage from "../../components/CrudPage";
import CrudService from "../../services/CrudService";
import EmployeeService from "../../services/EmployeeService";
import FilterService from "../../services/FilterService";
const { secondaryJob } = require("./sampleData");
const secondaryJobsService = new CrudService("secondary-job");

let timeoutUser = null;
let timeoutJobs = null;
export default class SecondaryJobs extends CrudPage {
  name = "Secondary Job";
  fields = [
    {
      name: "id",
      label: "ID",
      type: "text",
      showOnForm: false,
      showOnTable: false
    },
    {
      name: "name",
      label: "Employee",
      type: "react-select",
      placeholder: "Type to search for an employee",
      menuIsOpen: false,
      closeMenuOnSelect: true,
      isMulti: false,
      loadOptions: (search, callback) => {
        timeoutUser && clearTimeout(timeoutUser);
        timeoutUser = setTimeout(async () => {
          EmployeeService.searchMyEmployees(search).then(employees => {
            callback(
              employees.data.map(({ name: label, id: value, ...other }) => ({
                label,
                value,
                ...other
              }))
            );
          });
        }, 300);
      },
      showOnTable: false,
      clearable: true
    },
    {
      name: "employee",
      label: "Employee",
      type: "text",
      showOnForm: false
    },
    {
      name: "employeeCode",
      label: "Employee Code",
      type: "text",
      showOnForm: false
    },
    { name: "code", label: "Code", type: "text", showOnForm: false },
    { name: "title", label: "Job", type: "text", showOnForm: false },
    {
      name: "job",
      label: "Job",
      type: "react-select",
      placeholder: "Type to search for a job",
      menuIsOpen: false,
      closeMenuOnSelect: true,
      isMulti: false,
      loadOptions: (search, callback) => {
        timeoutJobs && clearTimeout(timeoutJobs);
        timeoutJobs = setTimeout(async () => {
          FilterService.getAllJobs({ limit: 20, search }).then(jobs => {
            callback(
              jobs.data.jobs.map(({ name: label, value }) => ({ label, value }))
            );
          });
        }, 300);
      },
      showOnTable: false,
      clearable: true
    },
    {
      name: "hourlyRate",
      label: "Hourly Rate",
      type: "double",
      min: 0.00,
      step: 0.01
    },
    {
      name: "startDate",
      label: "Start date",
      type: "date",
      format: "MMM dd, yyyy",
      placeholder: "Select Date",
      momentFormat: "MMM DD, YYYY"
    },
    {
      name: "endDate",
      label: "End date",
      type: "date",
      format: "MMM dd, yyyy",
      placeholder: "Select Date",
      momentFormat: "MMM DD, YYYY"
    },
    { name: "isActive", label: "Active", type: "toggle", defaultVal: false }
  ];
  enableImport = true;
  importSampleLink = "https://dxv8p7y79jw20.cloudfront.net/Jobs+Sample.csv";

  sampleData = secondaryJob;
  searchPlaceHolder = "Search by title, code, employee or employee number...";
  actions = ["U"];

  fetch(page, search) {
    return secondaryJobsService.list(page, search);
  }
  prepareData(data) {
    if (!data.endDate) {
      data.endDate = null;
    }
    if (data.job) data.jobId = data.job.value;
    if (data.name) data.employeeId = data.name.employee_id;
    delete data.job;
    delete data.name;
    return data;
  }
  create(data) {
    data = this.prepareData(data);
    return secondaryJobsService.create(data);
  }

  update(data) {
    data = this.prepareData(data);
    return secondaryJobsService.update(data);
  }

  delete(id) {
    return secondaryJobsService.remove(id);
  }

  import(file) {
    return secondaryJobsService.importSample(file);
  }
}

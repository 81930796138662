import React, {Component} from 'react';
import RequestLeavesForm from "./RequestLeavesForm";
import ToBeApprovedList from "./ToBeApprovedList";
import UpcomingLeavesList from "./UpcomingLeavesList";
import LeavesService from "../../services/LeavesService";
import moment from "moment";
import ErrorToast from "../BryntumScheduler/ErrorToast";
import {confirmAlert} from "react-confirm-alert";
import Tooltip from "../Blocks/Tooltip";
export default class Leaves extends Component {
    constructor(props) {
        super(props);
        this.state = {
            awaitingApproval: [],
            upcomingTimeOff: [],
            editingLeave: null
        };
    }
    getToBeApprovalList = async () => {
        const {user: {id: userId}} = this.props;
        const { data } = await LeavesService.getCollection({userId, status: 'pending'});
        return data.map(item => {
            item.startDate = moment(item.startDate);
            item.endDate = moment(item.endDate);
            item.createdAt = moment(item.createdAt);
            return item;
        });
    };
    getUpcomingTimeOffList = async () => {
        const {user: {id: userId}} = this.props;
        const { data } = await LeavesService.getCollection({userId, status: 'approved'});
        return data.map(item => {
            item.startDate = moment(item.startDate);
            item.endDate = moment(item.endDate);
            item.createdAt = moment(item.createdAt);
            return item;
        });
    };
    requestLeave = async (leave) => {
        const {startDate, endDate, notes} = leave;
        const { awaitingApproval, editingLeave} = this.state;
        try {
            this.props.setLoading(true);
            const item = {
                startDate: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                note: notes,
            };
            const state = {};
            if (editingLeave) {
                const ind = awaitingApproval.findIndex(l => l.id === editingLeave.id);
                awaitingApproval[ind].startDate = moment(item.startDate);
                awaitingApproval[ind].endDate = moment(item.endDate);
                awaitingApproval[ind].note = item.note;
                await LeavesService.update(editingLeave.id, item);
            } else {
                const {data: l }  = await LeavesService.create(item);
                l.startDate = moment(l.startDate);
                l.endDate = moment(l.endDate);
                l.createdAt = moment(l.createdAt);
                l.status = 'pending';
                awaitingApproval.push(l);
            }
            state.editingLeave= null;
            state.awaitingApproval = awaitingApproval;
            this.setState(state, ()=>{
                this.props.setLoading(false);
            })
        } catch (e) {
            new ErrorToast(`Error: ${e.response &&  e.response.data? e.response.data : e.message}`);
            this.props.setLoading(false);
        }
    };
    deleteLeave = async (id) => {
        confirmAlert({
            title: 'Delete Leave',
            message: 'Are you sure to do this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const { awaitingApproval} = this.state;
                        this.props.setLoading(true);
                        LeavesService.delete(id).then(()=>{
                          this.setState({
                            editingLeave: null,
                            awaitingApproval: awaitingApproval.filter(l => l.id !== id),
                            isLoading: false,
                          }, () => {
                            this.props.setLoading(false);
                          });
                        }).catch((e)=>{
                          console.log(e);
                          new ErrorToast(`Error: An error happened during deleting your leave`);
                          this.props.setLoading(false);
                        });

                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };
    setEditingLeave = async (leave) => {
        this.setState({editingLeave: leave});
    };
    onCancel = () => {
        this.setState({editingLeave: null});
    };
    componentDidMount = () => {
        this.props.setLoading(true);
        Promise.all([this.getUpcomingTimeOffList(), this.getToBeApprovalList()]).then(response=>{
            this.setState({
                awaitingApproval: response[1],
                upcomingTimeOff: response[0]
            },()=> {
                this.props.setLoading(false);
            });

        })
    };
    deleteApprovedLeaves = async (id) => {
      confirmAlert({
        title: 'Delete your approved leave!',
        message: 'Are you sure to do this?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              const { upcomingTimeOff } = this.state;
              this.props.setLoading(true);
              LeavesService.delete(id, true).then(()=>{
                this.setState({
                  upcomingTimeOff: upcomingTimeOff.filter(l => l.id !== id),
                  isLoading: false,
                }, () => {
                  this.props.setLoading(false);
                });
              }).catch((e)=>{
                console.log(e);
                new ErrorToast(`Error: An error happened during deleting your leave`);
                this.props.setLoading(false);
              });

            }
          },
          {
            label: 'No'
          }
        ]
      });
    };
    render() {
        const {awaitingApproval, upcomingTimeOff, editingLeave} = this.state;
        const {user} = this.props;
        return (
            <div>
                <div className="row mt-3">
                    <div className="col-md-4 col-sm-12">
                        <RequestLeavesForm editingLeave={editingLeave} onCancel={this.onCancel} requestLeave={this.requestLeave} user={user} />
                    </div>

                    <div className="col-md-8 col-sm-12" style={{flex: 1}}>
                        <ToBeApprovedList user={user} awaitingApproval={awaitingApproval} actions={(request)=> {
                            return (
                                <div>
                                    <Tooltip placement="top" text="Delete">
                                        <button className="btn btn-danger btn-sm mr-2" onClick={() => {
                                            this.deleteLeave(request.id);
                                        }}>
                                            <i className="fas fa-trash" />
                                        </button>
                                    </Tooltip>
                                    <Tooltip placement="top" text="Edit">
                                        <button className="btn btn-info btn-sm" onClick={() => {this.setEditingLeave(request)}}>
                                            <i className="fas fa-edit" />
                                        </button>
                                    </Tooltip>
                                </div>
                            )
                        }}/>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <UpcomingLeavesList user={user} deleteLeave={this.deleteApprovedLeaves} upcomingTimeOff={upcomingTimeOff}/>
                    </div>
                </div>
            </div>
        );
    }
}


import moment from "moment";
import { Container } from "../../libs/scheduler/scheduler.module";

export function generateZones(combo, zonesSelected, min, max) {
  const zoneContainer = combo.owner.widgetMap.zoneContainer;
  zoneContainer.hidden = zonesSelected.length === 0;
  zoneContainer.widgets = [];
  zoneContainer.items = [];
  zoneContainer.contentElement.innerHTML = '';
  if (zonesSelected.length) {
    zonesSelected.forEach(z => {
      const container = new Container({
        flex: "1, 0, 100%",
        widgets: [
          {
            type: 'text',
            ref: 'zone',
            flex: "1 0 30%",
            editable: false,
            value: z.text,
          },
          {
            type: 'number',
            ref: 'zoneId',
            flex: "1 0 30%",
            editable: false,
            hidden: true,
            value: z.value,
          },
          {
            type: 'text',
            ref: 'zoneColor',
            flex: "1 0 30%",
            editable: false,
            hidden: true,
            value: z.color,
          },
          {
            type: 'timefield',
            ref: 'startTime',
            flex: "1 0 30%",
            style: { marginLeft: "2%" },
            value: z.startTime,
            min: moment(min),
            max: moment(max).add(-5, 'minutes').toDate(),
          },
          {
            type: 'timefield',
            ref: 'endTime',
            flex: "1 0 30%",
            style: { marginLeft: "2%" },
            value: z.endTime,
            min: moment(min).add(5, 'minutes').toDate(),
            max: moment(max),
          }
        ]
      });
      zoneContainer.widgets.push(container);
    });
  }
  zoneContainer.layout.renderChildren();
}
export function onDateTimeChange(newEligibleStart, newEndTime, state) {
  const eventDate = moment(newEligibleStart).format('YYYY-MM-DD');
  return state.map(item => {
    let startTime = moment(`${eventDate} ${item.startTime}`);
    let endTime = moment(`${eventDate} ${item.endTime}`);
    if (
      newEndTime.isBefore(startTime) ||
      newEndTime.diff(startTime, "minutes") < 5 ||
      (startTime.isBefore(newEndTime) &&
        newEndTime.diff(startTime, "minutes") < 5)
    ) {
      item.endTime = newEndTime.format('HH:mm');
      item.startTime = newEndTime.clone().add(-5, 'minutes').format('HH:mm');
    } else if (
      newEligibleStart.isAfter(endTime) ||
      endTime.diff(newEligibleStart, "minutes") < 5 ||
      (endTime.isBefore(newEligibleStart) &&
        moment(newEligibleStart).diff(endTime, "minutes") < 5)
    ) {
      item.startTime = newEligibleStart.format('HH:mm');
      item.endTime = newEligibleStart.clone().add(5, 'minutes').format('HH:mm');
    } else {
      if (
        newEndTime.isBefore(endTime) ||
        newEndTime.diff(endTime, "minutes") < 5 ||
        (endTime.isBefore(newEndTime) &&
          newEndTime.diff(endTime, "minutes") < 5)
      ) {
        item.endTime = newEndTime.format('HH:mm');
      }
      if (
        newEligibleStart.isAfter(startTime) ||
        startTime.diff(newEligibleStart, "minutes") < 5 ||
        (startTime.isBefore(newEligibleStart) &&
          moment(newEligibleStart).diff(startTime, "minutes") < 5)
      ) {
        item.startTime = newEligibleStart.format('HH:mm');
      }
    }
    return item;
  });

}
export function updateZones(newEligibleStart, newEndTime, state, eventEdit) {

}
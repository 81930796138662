import React from "react";

import StringHelper from "../../helpers/StringHelper";
import Graph from "./Graph";

import UsersService from "../../services/UserService";
import ReportsService from "../../services/ReportService";

// Styles
import "./assets/styles/Dashboard.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import FilterService from "../../services/FilterService";

class Dashboard extends React.Component {
  state = {
    isLoading: false,
    locations: [],
    selectedLocation: null,
    departmentStats: [],
    startDate: new Date(),
    monthPickerFocused: false,
    dateString: moment(new Date()).format("MMMM YYYY")
  };

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      isLoading: true,
      selectedLocation: user.locationId,
    });
    Promise.all([this.fetchLocations()])
      .then(() => {
        return this.fetchStats(user.locationId);
      })
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  }

  fetchLocations() {
    return FilterService.getAllLocations().then(res => {
      const st = {
        locations: res.data,
      };
      if (st.locations.length > 0) {
        const sl = st.locations.find(s => s.value === this.state.selectedLocation);
        if (!sl) {
          st.selectedLocation = st.locations[0].value;
        }
      }
      this.setState(st);
      return res.data;
    });
  }

  fetchStats(locationId, startDate) {
    this.setState({
      isLoading: true
    });
    if (!locationId) {
      locationId = this.state.selectedLocation;
    }
    if (!startDate) {
      startDate = this.state.startDate;
    }
    return ReportsService.dashboardReport(locationId, startDate).then(res => {
      this.setState({
        departmentStats: res.data,
        isLoading: false
      });
    });
  }

  onPressDate = () => {
    this.setState({ monthPickerFocused: true });
  };

  render() {
    const { isLoading, locations } = this.state;

    return (
      <div className="container content-area">
        <section className="section">
          <div className="section-body">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4>GM Dashboard</h4>
                  </div>
                  <div className="card-body">
                    {!(isLoading && !locations.length) &&
                      this.renderLocationFilter()}
                    {isLoading && (
                      <h6 className="text-center mt-3">
                        <div id="spinner" />
                      </h6>
                    )}
                    {!isLoading && this.renderCards()}
                    {!isLoading && this.renderGraphs()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderLocationFilter() {
    const {
      locations,
      selectedLocation,
      startDate,
      monthPickerFocused,
      dateString
    } = this.state;

    const handleChange = e => {
      this.setState({
        selectedLocation: e.target.value
      });
      this.fetchStats(e.target.value);
    };

    const setStartDate = date => {
      this.setState(
        {
          monthPickerFocused: false,
          startDate: date,
          dateString: moment(date).format("MMMM YYYY")
        },
        () => this.fetchStats(this.state.selectedLocation, date)
      );
    };

    return (
      <div className="row mb-4 px-sm-3 position-relative gm-dashboard">
        <div className="col-lg-6 col-sm-12 mb-sm-3">
          <label className="font-weight-bold">Selected location:</label>

          <select
            className="form-control"
            name="locationCode"
            onChange={handleChange}
            value={selectedLocation || ""}
          >
            {locations.map(({ value, name }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-lg-6 col-sm-12">
          <label className="font-weight-bold">Month:</label>

          <div className={"date-changer"}>
            <span className="date" onClick={this.onPressDate}>
              {dateString}
              &ensp;
              <i className="fas fa-caret-down" />
            </span>
            <DatePicker
              selected={startDate}
              popperModifiers={{
                offset: { offset: "20px, 10px" }
              }}
              onClickOutside={() => {
                this.setState({ monthPickerFocused: false });
              }}
              className={"position-absolute"}
              style={{ bottom: "50%" }}
              open={monthPickerFocused}
              showMonthYearPicker
              onChange={setStartDate}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCards() {
    const { isLoading, departmentStats } = this.state;
    const departmentsHtml = departmentStats.map(department => {
      const actualFormatted = StringHelper.formatMoney(department.actualBudget);
      const budgetFormatted = StringHelper.formatMoney(department.budget);

      const difference = department.budget - department.actualBudget;
      const differenceFormatted = StringHelper.formatMoney(difference);
      let actualToBudgetPerc = 100 * department.actualBudget;
      if (department.budget > 0) {
        actualToBudgetPerc /= department.budget;
      } else {
        actualToBudgetPerc = 0;
      }
      if (actualToBudgetPerc > 100) actualToBudgetPerc = 100;
      actualToBudgetPerc = actualToBudgetPerc.toFixed(2);

      return (
        <div
          className="col-lg-3 col-md-6 col-sm-12"
          key={department.departmentName}
        >
          <div className="card dep-card">
            <div className="card-header dep-card-header">
              <span className="card-title dep-card-title">
                {department.departmentName}
              </span>
            </div>
            <div className="card-body pt-1 pb-3 dep-card-body">
              <div>
                <span className="text-success">${actualFormatted}</span>
                <span> / ${budgetFormatted} / </span>
                <span className={difference < 0 ? "text-danger" : ""}>
                  ${differenceFormatted}
                </span>
              </div>
              <div className="progress-bar-back">
                <div
                  className="progress-bar"
                  style={{ width: `${actualToBudgetPerc}%` }}
                ></div>
              </div>
              {isLoading && <p className="text-center font-bold">Loading...</p>}
            </div>
          </div>
        </div>
      );
    });

    return <div className="row">{departmentsHtml}</div>;
  }

  renderGraphs() {
    const { departmentStats } = this.state;
    const departmentsHtml = departmentStats.map(department => {
      return (
        <Graph
          key={department.departmentName}
          title={`${department.departmentName} MDT`}
          budget={department.budget}
          actual={department.actualBudget}
          scheduled={department.scheduledBudget}
        />
      );
    });

    return <div className="row">{departmentsHtml}</div>;
  }
}

Dashboard.propTypes = {};

export default Dashboard;

import React from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";
import Avatar from 'react-avatar-edit';

// Styles
import "./styles/ProfilePic.css";
import EmployeeService from "../../services/EmployeeService";

const customModalStyles = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '650px',
        height: '450px'
    }
};

class ProfilePicture extends React.Component {

    state = {
        profileImg: null,
        profileImgName: null,
        thumbnail: null,
        showUploadModal: false,
    };

    constructor(props, context) {
        super(props, context);
        this.state.profileImg = props.profilePic;
        this.state.thumbnail = props.profilePic;
        this.onClose = this.onClose.bind(this);
        this.onCrop = this.onCrop.bind(this);
        this.onOpenUploadModal = this.onOpenUploadModal.bind(this);
        this.onCloseUploadModal = this.onCloseUploadModal.bind(this);
        this.onChangePicture = this.onChangePicture.bind(this);
        this.onFileLoad = this.onFileLoad.bind(this);
        this.onRemoveProfilePicture = this.onRemoveProfilePicture.bind(this);
    }

    onChangePicture() {
        this.setState({
            profileImg: this.state.thumbnail,
        });

        const contentType = this.state.thumbnail.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
        fetch(this.state.thumbnail)
        .then(res => res.blob())
        .then(blob => {
            const file = new File([blob], this.state.profileImgName || 'untitled', {
                type: contentType.length && contentType[1]
            });
            EmployeeService.updateProfilePicture(file)
                .then(() => {
                    this.setState({
                        showUploadModal: false,
                    });
                });
        });
    }

    onRemoveProfilePicture() {
        EmployeeService.updateProfilePicture(null)
                .then(() => {
                    this.setState({
                        thumbnail: null,
                        profileImg: null,
                        showUploadModal: false,
                    });
                });
    }

    onClose() {
        this.setState({
            thumbnail: null
        });
    }

    onCrop(preview) {
        this.setState({
            thumbnail: preview,
        });
    }

    onFileLoad(file) {
        this.setState({
            profileImgName: file.name,
        });
    }

    onOpenUploadModal() {
        this.setState({
            showUploadModal: true,
        });
    }

    onCloseUploadModal() {
        this.setState({
            showUploadModal: false,
        });
    }

    render() {
        const { firstName, lastName, jobTitle } = this.props;
        const { profileImg } = this.state;
        return (
            <div className="card-body text-center profile-bg box">
                <div className="text-center">
                    <div className="userprofile social">
                        <div className="userphoto img" style={{position: 'relative'}} onClick={this.onOpenUploadModal}>
                            {profileImg && this.renderProfilePic()}
                            {!profileImg && this.renderInitials()}
                        </div>
                        <h2 className="mt-4 text-dark font-weight-bold">
                            {firstName} {lastName}
                            <br/>
                            <span>{jobTitle}</span>
                        </h2>
                    </div>
                </div>
                {this.renderUploadModal()}
            </div>
        )
    }

    renderProfilePic() {
        const { profileImg } = this.state;
        return (<img src={profileImg} alt="User Profile Avatar" />);
    }

    renderInitials() {
        const { firstName, lastName } = this.props;
        return (
            <div className="p-details-initials">
                {firstName ? firstName[0] : ""}
                {lastName ? lastName[0] : ""}
            </div>
        );
    }

    renderUploadModal() {
        const imgSelected = !this.state.thumbnail;
        return (
            <Modal
                isOpen={this.state.showUploadModal}
                onRequestClose={this.onCloseUploadModal}
                style={customModalStyles}
                contentLabel="Choose Your Profile Image"
                shouldCloseOnOverlayClick={true}
            >
            <div className="modal-header">
                <h5>Choose Your Profile Image</h5>
                </div>
                <div className="modal-body">
                    <Avatar
                        style={{ maxWidth: '100%', margin: 'auto' }}
                        width={570}
                        height={240}
                        onCrop={this.onCrop}
                        onClose={this.onClose}
                        onFileLoad={this.onFileLoad}
                        src={this.state.thumbnail}
                    />
                </div>
                <div className="modal-footer">
                <div className="pull-right">
                    <button className="btn btn-outline-info btn-sm mr-2" onClick={this.onChangePicture} disabled={imgSelected}>Change</button>
                    <button className="btn btn-outline-info btn-sm mr-2" onClick={this.onRemoveProfilePicture} disabled={!this.state.profileImg}>Remove Profile Picture</button>
                    <button className="btn btn-outline-info btn-sm mr-2" onClick={this.onCloseUploadModal}>Cancel</button>
                </div>
            </div>
            </Modal>
        )
    }
}

ProfilePicture.defaultProps = {
    profilePic: null,
};

ProfilePicture.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    jobTitle: PropTypes.string.isRequired,
    profilePic: PropTypes.string,
};

export default ProfilePicture;

import React from "react";
import "bootstrap/dist/css/bootstrap.css";
// Services
import AuthService from "../../services/AuthService";
import StorageManager from "../../helpers/StorageManager";
// Components
import LoginForm from "../../components/Forms/LoginForm";
import Toast from "../../components/Blocks/Toast/Toast";
// Styles
import "./assets/styles/Login.css";
// Images
import WhiteLogo from "../../general/assets/images/arcon-white.png";
import UserService from "../../services/UserService";

class Login extends React.Component {
  state = {
    error: null,
  };

  submitLogin = (values, setFieldValue) => {
    return AuthService.login(values.employeeCode, values.password)
      .then((res) => {
        if (res.data.requirePasswordChange) {
          return this.props.history.push(
            `/change-password/${values.employeeCode}`
          );
        }

        if (res.data.token) {
          const authSuccess = values.rememberMe
            ? StorageManager.set(
                "token",
                res.data.token
              )
            : StorageManager.setToSession("token", res.data.token);

          if (authSuccess.success) {
            window.location = "/app";
          } else {
            setFieldValue("password", "");
            alert("Storage not set!!");
          }
        }
      })
      .catch((e) => this.showError(e, setFieldValue));
  };

  showError = (error, setFieldValue) => {
    setFieldValue("password", "");
    this.setState({
      error:
        error.response && error.response.data
          ? error.response.data
          : "An Error Occurred",
    });
  };

  errorDismiss = () => {
    this.setState({
      error: null,
    });
  };

  async componentWillMount() {
    let picture = StorageManager.get("login-picture-index");
    if (!picture) {
      picture = 1;
      StorageManager.set("login-picture-index", 2);
    } else {
      picture = parseInt(picture);
      StorageManager.set(
        "login-picture-index",
        picture === 3 ? 1 : picture + 1
      );
    }

    const url = await UserService.getLoginImage(picture);

    this.setState({ picture: url.data });
  }

  render() {
    const { error, picture } = this.state;

    const toastError = error && (
      <Toast
        title="Login failed!"
        message={error}
        dismiss={this.errorDismiss}
      />
    );

    return (
      <React.Fragment>
        {toastError}
        <div
          className="login-section"
          style={{ backgroundImage: `url(${picture})` }}
        >
          <div>
            <img src={WhiteLogo} className="" alt="logo" />
            <LoginForm submitLogin={this.submitLogin} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {};

export default Login;

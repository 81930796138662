import BaseService from "./BaseService";

class CrudService extends BaseService {
  constructor(uri) {
    super();
    this._uri = uri;
  }

  list(page, search = undefined) {
    return this.api.get(`/${this._uri}`, {
      params: {
        page,
        search,
      },
    });
  }

  get(id) {
    return this.api.get(`/${this._uri}/${id}`);
  }

  create(data) {
    return this.api.post(`/${this._uri}`, data);
  }

  update(data) {
    const id = data.id;
    data = Object.keys(data)
      .filter((key) => key !== "id")
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
    return this.api.put(`/${this._uri}/${id}`, data);
  }

  remove(id) {
    return this.api.delete(`/${this._uri}/${id}`);
  }

  importSample(data) {
    return this.api.post(`/${this._uri}/import/json`, { data });
  }

  import(data) {
    const postData = new FormData();
    Object.keys(data).forEach((field) => {
      if (data[field] instanceof File) {
        postData.append(field, data[field], data[field].name);
      } else {
        postData.append(field, data[field]);
      }
    });
    return this.api.post(`/${this._uri}/import`, postData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}

export default CrudService;

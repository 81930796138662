import moment from "moment";
import each from "each.js";
import ArrayHelper from "./ArrayHelper";

export default class CostCalculation {
  constructor(weekConf = 'week') {
    this.rules = {
      CA: {
        day: {
          type: "max",
          hrs: 8,
          min: 0
        },
        week: {
          type: "max",
          hrs: 40,
          min: 0
        },
        other: [
          {
            type: "day",
            dayScheduled: 7,
            value: 6,
            overtime: true
          }
        ]
      },
      ON: {
        week: {
          type: "max",
          hrs: 44,
          min: 0
        }
      },
      BC: {
        week: {
          type: "max",
          hrs: 44,
          min: 0
        }
      },
      __all: {
        week: {
          type: "max",
          hrs: 40,
          min: 0
        }
      }
    };
    this.overTimeCoefficent = 1.5;
    this.orverTimeOnlyHourly = true;
    this.calculateCostOnlyHourly = true;
    this.weekStart = weekConf === 'week' ? "Sun" : "Mon";
    this.scheduleNames = ["Regular"];
  }
  setWeekStart(weekConf) {
    this.weekStart = weekConf === 'week' ? "Sun" : "Mon";
  }
  isSameDay(m1, m2) {
    return (
      m1.date() === m2.date() &&
      m1.month() === m2.month() &&
      m1.year() === m2.year()
    );
  }
  async calculateCost(
    startDate,
    endDate,
    schedules,
    locationState,
    isHourly,
    rates = {},
    getTotal = false
  ) {
    schedules = schedules.filter(
      s => this.scheduleNames.includes(s.name) && s.id > 0
    );
    if (this.isSameDay(moment(startDate), moment(endDate))) {
      return this.calculateCostByDay(
        schedules,
        locationState,
        isHourly,
        rates,
        getTotal
      );
    } else {
      return await this.calculateCostByWeek(
        schedules,
        locationState,
        isHourly,
        rates,
        getTotal
      );
    }
  }
  maxHourRule(schedules, cost, rule) {
    if (rule && rule.type === "max") {
      const result = this.findShiftCost(schedules, rule.hrs, rule.min);
      schedules = result.schedules;
      cost = result.cost;
    }
    return {
      schedules,
      cost
    };
  }
  otherRule(schedules, cost, rules = []) {
    if (rules instanceof Array) {
      rules = rules.filter(r => r.type === "day" && r.overtime);
      if (rules.length > 0) {
        rules.forEach(r => {
          cost = this.getCostObject();
          schedules = schedules.map(s => {
            if (typeof s.day === "undefined") {
              s.day = moment(s.startDate).weekday();
            }
            if (s.day === r.value && schedules.length === r.dayScheduled) {
              // cost
              s.ovt.cost += s.reg.cost * this.overTimeCoefficent;
              s.reg.cost = 0;
              // hours
              s.ovt.hrs += s.reg.hrs;
              s.reg.hrs = 0;
              // minutes
              s.ovt.min += s.reg.min;
              s.reg.min = 0;
              s.ovt = this.checkMinutes(s.ovt);
            }
            s.cost = s.ovt.cost + s.reg.cost;
            cost.ovt.cost += s.ovt.cost;
            cost.ovt.hrs += s.ovt.hrs;
            cost.ovt.min += s.ovt.min;
            cost.ovt = this.checkMinutes(cost.ovt);
            cost.reg.cost += s.reg.cost;
            cost.reg.hrs += s.reg.hrs;
            cost.reg.min += s.reg.min;
            cost.reg = this.checkMinutes(cost.reg);
            return s;
          });
        });
      }
    }
    return {
      schedules,
      cost
    };
  }
  hasOverTime(isHourly) {
    return (
      (this.calculateCostOnlyHourly && isHourly) ||
      !this.calculateCostOnlyHourly ||
      (isHourly && this.orverTimeOnlyHourly) ||
      !this.orverTimeOnlyHourly
    );
  }
  getDayRule(locationState) {
    let rule = null;
    if (
      (this.rules[locationState] && this.rules[locationState].day) ||
      (this.rules.__all && this.rules.__all.day)
    ) {
      rule =
        this.rules[locationState] && this.rules[locationState].day
          ? this.rules[locationState].day
          : this.rules.__all.day;
    }
    return rule;
  }
  getWeekRule(locationState) {
    let rule = null;
    if (
      (this.rules[locationState] && this.rules[locationState].week) ||
      (this.rules.__all && this.rules.__all.week)
    ) {
      rule =
        this.rules[locationState] && this.rules[locationState].week
          ? this.rules[locationState].week
          : this.rules.__all.week;
    }
    return rule;
  }
  getOtherRule(locationState) {
    let rule = null;
    if (
      (this.rules[locationState] && this.rules[locationState].other) ||
      (this.rules.__all && this.rules.__all.other)
    ) {
      rule =
        this.rules[locationState] && this.rules[locationState].other
          ? this.rules[locationState].other
          : this.rules.__all.other;
    }
    return rule;
  }
  calculateCostByDay(shifts, locationState, isHourly, rates, getTotal = false) {
    let { schedules, cost } = this.setDefaultCostAndRate(shifts, rates);
    const rule = this.getDayRule(locationState);
    if (this.hasOverTime(isHourly) && rule) {
      const result = this.maxHourRule(schedules, cost, rule);
      schedules = result.schedules;
      cost = result.cost;
    }
    const response = {
      schedules
    };
    if (getTotal) {
      response.cost = cost;
    }
    return response;
  }
  async calculateCostByWeek(
    shifts,
    locationState,
    isHourly,
    rates,
    getTotal = false
  ) {
    let { schedules, cost } = this.setDefaultCostAndRate(shifts, rates);
    if (this.hasOverTime(isHourly)) {
      const mapSchedulesByWeek = this.mapShifts(schedules);
      const mapSchedule = await ArrayHelper.mapBy(schedules, "id");
      const keys = Object.keys(mapSchedulesByWeek);
      let totalCost = this.getCostObject();
      await each.concurrent(
        keys,
        async key => {
          let weekSchedules = mapSchedulesByWeek[key];
          let weekCost = this.getCostObject();
          if (weekSchedules.length) {
            const dayRule = this.getDayRule(locationState);
            if (dayRule) {
              const dailyMap = this.mapShifts(weekSchedules, "day");
              const mapWeekSchedules = await ArrayHelper.mapBy(
                weekSchedules,
                "id"
              );
              const dailyKeys = Object.keys(dailyMap);
              await each.concurrent(
                dailyKeys,
                async k => {
                  const sc = dailyMap[k];
                  if (sc.length) {
                    const dailyResult = this.maxHourRule(
                      sc,
                      this.getCostObject(),
                      dayRule
                    );
                    weekCost = this.sumCost(weekCost, dailyResult.cost);
                    dailyResult.schedules.forEach(s => {
                      if (mapWeekSchedules[s.id]) {
                        mapWeekSchedules[s.id] = s;
                        mapSchedule[s.id] = s;
                      }
                    });
                  }
                },
                5
              );
              weekSchedules = Object.values(mapWeekSchedules);
            }
            const otherRules = this.getOtherRule(locationState);
            if (
              otherRules &&
              otherRules instanceof Array &&
              otherRules.length > 0
            ) {
              const otherResult = this.otherRule(
                weekSchedules,
                this.getCostObject(),
                otherRules
              );
              weekCost = otherResult.cost;
              weekSchedules = otherResult.schedules;
            }
            const weekRule = this.getWeekRule(locationState);
            if (weekRule) {
              const weekResult = this.maxHourRule(
                weekSchedules,
                this.getCostObject(),
                weekRule
              );
              weekCost = weekResult.cost;
              weekSchedules = weekResult.schedules;
            }
            weekSchedules.forEach(s => {
              if (mapSchedule[s.id]) mapSchedule[s.id] = s;
            });
            totalCost = this.sumCost(totalCost, weekCost);
          }
        },
        3
      );
      schedules = Object.values(mapSchedule);
      if (this.hasCost(totalCost)) cost = totalCost;
    }
    const response = {
      schedules
    };
    if (getTotal) {
      response.cost = cost;
    }
    return response;
  }
  sumCost(cost, newCost) {
    cost.reg.cost += newCost.reg.cost;
    cost.reg.hrs += newCost.reg.hrs;
    cost.reg.min += newCost.reg.min;
    cost.ovt.cost += newCost.ovt.cost;
    cost.ovt.hrs += newCost.ovt.hrs;
    cost.ovt.min += newCost.ovt.min;
    cost.reg = this.checkMinutes(cost.reg);
    cost.ovt = this.checkMinutes(cost.ovt);
    return cost;
  }
  hasCost(cost) {
    return (
      cost.ovt.hrs ||
      cost.ovt.min ||
      cost.ovt.cost ||
      cost.reg.cost ||
      cost.reg.hrs ||
      cost.reg.min
    );
  }
  checkMinutes(obj) {
    if (obj.min > 59) {
      obj.min -= 60;
      obj.hrs += 1;
    }
    if (obj.min < 0) {
      obj.hrs -= 1;
      obj.min = 60 + obj.min;
    }
    return obj;
  }
  getCostObject() {
    return {
      reg: {
        hrs: 0,
        min: 0,
        cost: 0
      },
      ovt: {
        hrs: 0,
        min: 0,
        cost: 0
      }
    };
  }
  setDefaultCostAndRate(schedules, rates) {
    const cost = this.getCostObject();
    schedules = schedules.map(sc => {
      const duration = this.getDuration(sc);
      const hours = duration.hours();
      const minutes = duration.minutes();
      sc.cost = 0;
      sc.rate = 0;
      sc.duration = duration.asHours();
      sc.ovt = {
        hrs: 0,
        min: 0,
        cost: 0
      };
      sc.reg = {
        hrs: hours,
        min: minutes,
        cost: 0
      };
      if (rates[sc.jobId] && rates[sc.jobId].rates.length > 0) {
        const jobRates = ArrayHelper.sort(
          rates[sc.jobId].rates,
          "startDate",
          "asc",
          true
        );
        const rate = this.findRate(
          moment(sc.startDate).format("YYYY-MM-DD"),
          jobRates
        );
        if (rate) {
          const shiftCost = sc.duration * rate.rate;
          sc.cost = shiftCost;
          sc.rate = rate.rate;
          sc.reg.cost = shiftCost;
        }
      }
      cost.reg.cost += sc.reg.cost;
      cost.reg.hrs += sc.reg.hrs;
      cost.reg.min += sc.reg.min;
      cost.reg = this.checkMinutes(cost.reg);
      return sc;
    });
    return {
      schedules,
      cost
    };
  }
  getDuration(sc) {
    const startDate = moment(sc.startDate);
    startDate.second() || startDate.millisecond()
      ? startDate.add(1, "minute").startOf("minute")
      : startDate.startOf("minute");
    const endDate = moment(sc.endDate);
    endDate.second() || endDate.millisecond()
      ? endDate.add(1, "minute").startOf("minute")
      : endDate.startOf("minute");
    let duration = moment.duration(moment(endDate).diff(moment(startDate)));
    if (sc.breaks) {
      duration = duration.add(-1 * sc.breaks, "minute");
    }
    return duration;
  }
  mapShifts(schedules, by = "week") {
    const map = {};
    schedules.forEach(s => {
      const startDate = moment(s.startDate);
      let ind =
        by === "week" ? startDate.isoWeek().toString() : startDate.format("DD");
      if (this.weekStart === "Sun" && by === "week") {
        ind = startDate.week();
      }
      if (!map[ind]) {
        map[ind] = [];
      }
      s.day = startDate.weekday();
      map[ind].push(s);
    });
    return map;
  }
  findRate(startDate, arr = []) {
    arr = JSON.parse(JSON.stringify(arr));
    let rate = arr.find(r => moment(r.startDate).isSameOrBefore(moment(startDate), "day") &&
      (!r.endDate || moment(r.endDate).isSameOrAfter(moment(startDate), "day")));
    if (!rate && Array.isArray(arr) && arr.length > 0) {
      rate = arr.pop();
    }
    return rate;
  }
  findShiftCost(schedules, hours, minutes) {
    const cost = this.getCostObject();
    schedules = schedules.map(s => {
      if (
        cost.reg.hrs < hours ||
        (cost.reg.hrs === hours && cost.reg.min < minutes)
      ) {
        cost.reg.hrs += s.reg.hrs;
        cost.reg.min += s.reg.min;
        cost.reg = this.checkMinutes(cost.reg);
        const shiftRegCost = (s.reg.hrs + s.reg.min / 60) * s.rate;
        cost.reg.cost += shiftRegCost;
        s.reg.cost = shiftRegCost;
        if (
          !(
            cost.reg.hrs < hours ||
            (cost.reg.hrs === hours && cost.reg.min < minutes)
          )
        ) {
          const ovtHours = cost.reg.hrs - hours;
          const ovtMin =
            cost.reg.hrs === hours ? cost.reg.min - minutes : cost.reg.min;
          const ovtCostFromReg = (ovtHours + ovtMin / 60) * s.rate;
          cost.reg.cost -= ovtCostFromReg;
          const ovtCost = ovtCostFromReg * this.overTimeCoefficent;
          s.reg.cost -= ovtCostFromReg;
          s.ovt.cost += ovtCost;
          s.ovt.hrs += ovtHours;
          s.ovt.min += ovtMin;
          s.ovt = this.checkMinutes(s.ovt);
          s.reg.min -= ovtMin;
          s.reg.hrs -= ovtHours;
          s.reg = this.checkMinutes(s.reg);
          cost.reg.min -= ovtMin;
          cost.reg.hrs -= ovtHours;
          cost.reg = this.checkMinutes(cost.reg);
        }
      } else {
        cost.ovt.hrs += s.reg.hrs;
        cost.ovt.min += s.reg.min;
        cost.ovt = this.checkMinutes(cost.ovt);
        const ovtCost =
          (s.reg.hrs + s.reg.min / 60) * (s.rate * this.overTimeCoefficent);
        s.ovt.cost += ovtCost;
        s.reg.hrs = 0;
        s.reg.min = 0;
        s.reg.cost = 0;
      }
      cost.ovt.hrs += s.ovt.hrs;
      cost.ovt.min += s.ovt.min;
      cost.ovt.cost += s.ovt.cost;
      cost.ovt = this.checkMinutes(cost.ovt);
      s.cost = s.reg.cost + s.ovt.cost;
      return s;
    });
    return {
      cost,
      schedules
    };
  }
}


import React from "react";
export default class NotificationSectionDropdownItem extends React.Component {
    render () {
        const { name, description, options, selected, onChange } = this.props;
        let optionsElements = (<option>Default Option</option>);
        if (options) {
            const isArr = Array.isArray(options);
            optionsElements = Object.keys(options).map((optionKey) => {
                const optionVal = options[optionKey];
                optionKey = isArr ? optionVal : optionKey;
                return (
                    <option key={optionKey} value={optionKey}>{optionVal}</option>
                );
            });
        }

        return (
            <div className="container">
                <div className="row">
                    <div className={description ? "col-md-8 col-sm-8 col-xs-12" : "col-md-4 col-sm-4 col-xs-12"}>
                        <span className="lead-sm">{name}</span>
                        <p className="text-muted">{description}</p>
                    </div>
                    <div className={description ? "col-md-4 col-sm-4 col-xs-12" : "col-md-8 col-sm-8 col-xs-12"}>
                        <div className="pull-right">
                            <select
                                className="form-control input-sm schedule-select"
                                onChange={(event) => event.target && onChange(event.target.value)}
                                value={selected}
                            >
                                {optionsElements}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

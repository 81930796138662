import { startDateAndEndDateAreValid } from "../BryntumScheduler/Configs";
import {
  SCHEDULE_MODES,
  SCHEDULE_STATUSES,
  TABS
} from "../../containers/Schedule/Constant";
import DateRange from "../../containers/Forecasting/DateRange";
import moment from "moment";
import React from "react";
import { setActiveTab, setMode } from "./helpers/ModeAndTab";
import { clearFilters, copyView, onUndoLastCopy, openModal } from "./Modals";
import {
  onDeleteAllEvents,
  publishEvents,
  submitEvents,
  canModifyShift,
  isManager,
  canPublish,
  refreshSchedules
} from "./helpers/SchedulerHelper";
import { exportEvents } from "./helpers/Export";
import { onDatesChange, onSelectDateChange } from "./helpers/DateHelper";
import Tooltip from "../Blocks/Tooltip";

function renderHeader() {
  const {
    activeTab,
    user,
    estimation,
    mode,
    selfEvents,
    events,
    config,
    exporting
  } = this.state;

  const copyEventLength = (this.state.activeTab === TABS.MY_SHIFT
    ? selfEvents
    : events
  ).filter(ev => canModifyShift(ev, activeTab, user && isManager(user)))
    .length;
  return (
    <div className="schedule-header">
      <div>
        <div className="switch-mode">
          <button
            className={activeTab === TABS.MY_SHIFT ? "selected" : ""}
            onClick={() => this.call(setActiveTab, TABS.MY_SHIFT, false, true)}
          >
            My Schedule
          </button>

          {user && user.role === "manager" && (
            <button
              className={activeTab === TABS.MY_EMPLOYEES ? "selected" : ""}
              onClick={() => this.call(setActiveTab, TABS.MY_EMPLOYEES, false, true)}
            >
              My Employees
            </button>
          )}
          <button
            className={activeTab === TABS.MY_DEPARTMENT ? "selected" : ""}
            onClick={() => this.call(setActiveTab, TABS.MY_DEPARTMENT, false, true)}
          >
            Department view
          </button>
        </div>

        {config.startDate &&
          config.endDate &&
          startDateAndEndDateAreValid(config.startDate, config.endDate) && (
            <div className="schedule-actions">
              {user.role === "manager" && activeTab === TABS.MY_EMPLOYEES && (
                <React.Fragment>
                  <Tooltip placement="bottom" text="Filters">
                    <button
                      className="btn btn-outline-primary mr-2 btn-with-icon"
                      onClick={() => this.call(openModal, "filterModal")}
                    >
                      <i className="fas fa-filter" />
                    </button>
                  </Tooltip>

                  {(this.state.selectedRolesLength > 0 ||
                    this.state.selectedDepartmentsLength > 0 ||
                    this.state.selectedLocationsLength > 0 ||
                    this.state.selectedEventsLength > 0 ||
                    this.state.selectedZonesLength > 0 ||
                    this.state.searchName !== "") && (
                      <Tooltip placement="bottom" text="Clear filters">
                        <button
                          className="btn btn-outline-secondary btn-with-icon mr-2"
                          onClick={() => this.call(clearFilters)}
                        >
                          <i className="mr-1 fas fa-filter" />
                        Clear
                      </button>
                      </Tooltip>
                    )}
                </React.Fragment>
              )}

              {this.state.selectedEvents.length > 0 ? (
                <Tooltip
                  placement="bottom"
                  text={`Copy ${this.state.selectedEvents.length} event${
                    this.state.selectedEvents.length > 1 ? "s" : ""
                    }`}
                >
                  <button
                    className="btn mr-2 btn-outline-info btn-with-icon"
                    onClick={() => this.call(openModal, "copyModal")}
                  >
                    <i className="fas fa-copy" />
                  </button>
                </Tooltip>
              ) : (
                  copyEventLength > 0 && (
                    <Tooltip placement="bottom" text="Copy all">
                      <button
                        className="btn mr-2 btn-outline-info btn-with-icon"
                        onClick={() => this.call(copyView)}
                      >
                        <i className="fas fa-copy" />
                      </button>
                    </Tooltip>
                  )
                )}

              {this.state.lastCopiedEvents.length > 0 && (
                <Tooltip placement="bottom" text="Undo last copy">
                  <button
                    className="btn mr-2 btn-outline-orange btn-with-icon"
                    onClick={() => this.call(onUndoLastCopy)}
                  >
                    <i className="fas fa-undo" />
                  </button>
                </Tooltip>
              )}

              {this.state.selectedEvents.length === 0 && copyEventLength > 0 && (
                <Tooltip placement="bottom" text="Delete all">
                  <button
                    className="btn btn-ghost btn-outline-secondary mr-2 btn-with-icon"
                    onClick={() => this.call(onDeleteAllEvents)}
                  >
                    <i className="fas fa-trash-alt" />
                  </button>
                </Tooltip>
              )}

              {(activeTab === TABS.MY_EMPLOYEES && events.length > 0) ||
                (activeTab === TABS.MY_SHIFT && selfEvents.length > 0) ? (
                  <Tooltip placement="bottom" text="Print">
                    <button
                      className="btn mr-2 btn-outline-info btn-with-icon"
                      onClick={() => this.call(openModal, "printModal")}
                    >
                      <i className="fas fa-print" />
                    </button>
                  </Tooltip>
                ) : null}

              {((activeTab === TABS.MY_EMPLOYEES && events.length > 0) ||
                (activeTab === TABS.MY_SHIFT && selfEvents.length > 0)) && (
                  <Tooltip placement="bottom" text="Export">
                    <button
                      className="btn mr-2 btn-outline-green btn-with-icon"
                      onClick={async () => {
                        this.setState({ exporting: true });
                        await this.call(exportEvents);
                        this.setState({ exporting: false });
                      }}
                    >
                      {exporting ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                          <i className="fas fa-file-excel" />
                        )}
                    </button>
                  </Tooltip>
                )}
              {((user.role === "manager" &&
                activeTab === TABS.MY_EMPLOYEES &&
                (events.length > 0 ||
                  estimation.scheduledEmployees.length > 0)) || activeTab === TABS.MY_DEPARTMENT) &&
                SCHEDULE_MODES.THIS_MONTH !== mode && (
                  <Tooltip placement="bottom" text="Sort by shift start date">
                    <button
                      className="btn btn-outline-primary mr-2 btn-with-icon"
                      onClick={() => {
                        this.setState(
                          {
                            sortByEventStartDate: !this.state
                              .sortByEventStartDate
                          },
                          () => {
                            this.call(refreshSchedules);
                          }
                        );
                      }}
                    >
                      <i
                        className={
                          (this.state.sortByEventStartDate
                            ? "far fa-check-square"
                            : "far fa-square") + " mr-2"
                        }
                      />
                      <i className="fas fa-sort-amount-down-alt" />
                    </button>
                  </Tooltip>
              )}
            {user.role === "manager" &&
              activeTab === TABS.MY_EMPLOYEES &&
              SCHEDULE_MODES.THIS_MONTH !== mode && (
              <Tooltip placement="bottom" text={this.state.hideTerminatedEmployees ? 'Show terminated employees' : 'Hide terminated employees'}>
                  <button
                    className="btn btn-outline-primary mr-2 btn-with-icon"
                    onClick={() => {
                      this.setState(
                        {
                          hideTerminatedEmployees: !this.state
                            .hideTerminatedEmployees
                        },
                        () => {
                          this.call(refreshSchedules);
                        }
                      );
                    }}
                  >
                    <i
                      className={
                      (this.state.hideTerminatedEmployees
                          ? "far fa-check-square"
                          : "far fa-square") + " mr-2"
                      }
                  />
                  <i className={`fa ${!this.state.hideTerminatedEmployees ? 'fa-eye-slash' : 'fa-eye'}`} />
                  </button>
                </Tooltip>
              )}
            </div>
          )}
      </div>

      <div>
        <DateRange
          onDateChange={(date, addAmount) => {
            const { config } = this.state;
            if (moment(config.startDate).isSame(moment(date), 'D') && !addAmount) {
              return;
            }
            this.call(onSelectDateChange, date, addAmount)
          }
          }
          onModeChange={mode => this.call(setMode, mode)}
          onDatesChange={values => this.call(onDatesChange, values)}
          config={{
            ...config,
            startDate: config.startDate || moment(),
            endDate: config.endDate || moment()
          }}
          mode={mode}
          user={user}
        />

        {isManager(user) &&
          config.startDate &&
          config.endDate && activeTab !== TABS.MY_DEPARTMENT &&
          startDateAndEndDateAreValid(config.startDate, config.endDate) ? (
            <React.Fragment>
              {activeTab === TABS.MY_SHIFT ? (
                <button
                  className="btn btn-primary"
                  onClick={id => this.call(submitEvents, id)}
                  disabled={
                    !selfEvents.filter(
                      event => event.status === SCHEDULE_STATUSES.PENDING
                    ).length
                  }
                >
                  {canPublish(user) ? 'Publish' : 'Submit'}(
                  {selfEvents.filter(
                    event => event.status === SCHEDULE_STATUSES.PENDING
                  ).length || 0}
                )
                </button>
              ) : (
                  <button
                    className="btn btn-primary"
                    onClick={id => this.call(publishEvents, id)}
                    disabled={estimation.unPublishedEvents === 0}
                  >
                    Publish({estimation.unPublishedEvents})
                  </button>
                )}
            </React.Fragment>
          ) : null}
      </div>
    </div>
  );
}

export { renderHeader };

import BaseService from "./BaseService";

class ReportService extends BaseService {
  generateReport(type, reportData) {
    return this.apiPost({
      url: `/reports/${type}`,
      data: reportData
    });
  }

  dashboardReport(locationId, startDate) {
    return this.apiPost({
      url: "/reports/gmdashboard",
      data: {
        location: locationId,
        startDate
      }
    });
  }

  forecastData(props) {
    let q = `?${Object.keys(props)
      .map(k => `${k}=${props[k]}`)
      .join("&")}`;

    return this.apiGet({
      url: "/forecast" + q
    });
  }

  forecastChartData(params) {
    return this.apiGet({
      url: "/forecast/checkins",
      params
    });
  }

  getEvent(id, params) {
    return this.apiGet({
      url: "/reports/schedule-logs/event/" + id,
      params
    });
  }

  getLogs(filters) {
    return this.apiGet({
      url: "/reports/schedule-logs",
      params: filters
    });
  }

  getLogsUsers() {
    return this.apiGet({
      url: "/reports/schedule-logs/user-list"
    });
  }

  searchLocations(params) {
    return this.apiGet({
      url: "/employees/filter/locations",
      params
    });
  }

  searchUsers(params) {
    return this.apiGet({
      url: "/activity-log/users",
      params
    });
  }

  searchDepartments(params) {
    return this.apiGet({
      url: "/employees/filter/departments",
      params
    });
  }

  getUsageData(filters) {
    return this.apiGet({
      url: "/reports/usage-data",
      params: filters
    });
  }
  getEmployeeReport(filters) {
    return this.apiGet({
      url: "/reports/employee-report",
      params: filters
    });
  }
}

export default new ReportService();

import React, { useReducer, useState, useEffect } from "react";
import "./assets/styles/Forecasting.css";
import Select from "react-select";
import SettingsService from "../../services/SettingsService";
import Toast from "../../components/Blocks/Toast/Toast";

const ACTIONS = {
  NEW: "NEW",
  REMOVE: "REMOVE",
  START: "START",
  END: "END",
  VALUE: "VALUE",
  SET: "SET",
  CLEAR: "CLEAR"
};

const FILTERS = {
  ALL: "All",
  WITH_FORECASTING: "With Forecasting",
  WITHOUT_FORECASTING: "Without Forecasting"
};

const Row = ({
  row: { rangeStart, rangeEnd, value },
  index,
  values,
  change: changeParent
}) => {
  const isLast = index === values.length - 1 || rangeEnd === null;

  const change = ({ target: { value } }, type) => {
    changeParent({
      type,
      index,
      value: isNaN(parseInt(value)) ? 0 : +parseInt(value)
    });
  };

  const getValue = v => (v === 0 ? 0 : v.toString().replace(/^0+/, ""));

  const focus = ({ target }) => target.select();

  return (
    <div className="row definition">
      <div className="col-12 col-md-6 range">
        <input
          type="number"
          min={0}
          onFocus={focus}
          value={getValue(rangeStart)}
          onChange={e => change(e, ACTIONS.START)}
        />
        {!isLast && (
          <React.Fragment>
            -
            <input
              min={0}
              onFocus={focus}
              type="number"
              value={getValue(rangeEnd)}
              onChange={e => change(e, ACTIONS.END)}
            />
          </React.Fragment>
        )}
        {isLast ? "+ checkins" : "checkins"}
      </div>

      <div className="col-10 col-md-4 value">
        up to
        <input
          type="number"
          onFocus={focus}
          min={1}
          value={getValue(value)}
          onChange={e => change(e, ACTIONS.VALUE)}
        />
        employees
      </div>

      {!isLast && (
        <div className="col-2 d-flex justify-content-end">
          <button
            onClick={e => change(e, ACTIONS.REMOVE)}
            className="btn btn-sm btn-danger"
          >
            <i className="fas fa-trash" />
          </button>
        </div>
      )}

      {!isLast && (
        <div className="add-new" onClick={e => change(e, ACTIONS.NEW)} />
      )}
    </div>
  );
};

function Forecasting() {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState();
  const [saving, setSaving] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState(departments);
  const [department, setDepartment] = useState(null);
  const [filter, setFilter] = useState(FILTERS.ALL);

  const reducer = (state, { type, value, index }) => {
    let newState = [...state];

    switch (type) {
      case ACTIONS.START:
        if (value < 0) break;
        if (value >= state[index].rangeEnd)
          newState[index].rangeEnd = value + 1;
        newState[index].rangeStart = value;

        const isLast = index === state.length - 1;
        let last = newState[newState.length - 1];
        if (!isLast && last.rangeStart <= value + 1)
          newState.splice(newState.length - 1, 1, {
            rangeStart: value + 2,
            value: last.value
          });
        break;

      case ACTIONS.END:
        newState[index].rangeEnd = value;

        last = newState[newState.length - 1];
        if (last.rangeStart <= value)
          newState.splice(newState.length - 1, 1, {
            rangeStart: value + 1,
            value: last.value
          });
        break;

      case ACTIONS.VALUE:
        newState[index].value = value || 1;
        break;

      case ACTIONS.NEW:
        if (state.length === 0) {
          newState = newState.concat([
            { rangeStart: 0, rangeEnd: 1, value: 1 },
            { rangeStart: 2, rangeEnd: null, value: 1 }
          ]);
        } else {
          const newStart = state[index].rangeEnd + 1;
          last = newState[newState.length - 1];
          newState.splice(index + 1, 0, {
            rangeStart: newStart,
            rangeEnd: newStart + 1,
            value: 1
          });

          const biggest = newState.reduce((t, v) => {
            return v.rangeEnd > t
              ? v.rangeStart > v.rangeEnd
                ? v.rangeStart
                : v.rangeEnd
              : v.rangeStart > t
              ? v.rangeStart
              : t;
          }, 0);
          if (last.rangeStart <= biggest)
            newState.splice(newState.length - 1, 1, {
              rangeStart: biggest + 1,
              value: last.value
            });
        }
        break;

      case ACTIONS.REMOVE:
        if (state.length === 2) newState = [];
        else newState.splice(index, 1);
        break;

      case ACTIONS.SET:
        newState = value;
        break;

      case ACTIONS.CLEAR:
        newState = [];
        break;

      default:
        break;
    }

    return newState;
  };
  const [values, change] = useReducer(reducer, []);

  const onChangeFilter = filter => {
    setFilter(filter);

    const newDepartments =
      filter === FILTERS.ALL
        ? departments
        : departments.filter(d =>
            filter === FILTERS.WITH_FORECASTING ? !!d.config : !d.config
          );
    setFilteredDepartments(newDepartments);
    setDepartment(null);
    change({ type: ACTIONS.CLEAR });
  };

  const onChangeDepartment = d => {
    setDepartment(d);

    if (d.config) {
      try {
        const value = JSON.parse(d.config);
        change({ type: ACTIONS.SET, value });
      } catch (e) {
        console.log(e);
      }
    } else change({ type: ACTIONS.CLEAR });
  };

  const getDepartments = async () => {
    try {
      const res = await SettingsService.getForecastingConfigs();
      setDepartments(res.data);
      setFilter(FILTERS.ALL);
      setFilteredDepartments(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const onSave = async () => {
    let check = true;

    for (let i = 0; i < values.length; i++) {
      const { rangeStart, rangeEnd } = values[i];
      const next = values[i + 1];

      if (rangeEnd !== null && rangeStart > rangeEnd) check = false;
      else if (next && rangeEnd >= next.rangeStart) check = false;
    }

    if (check) {
      setSaving(true);
      try {
        const res = await SettingsService.updateForecastingConfig(
          department.value,
          {
            config: JSON.stringify(values)
          }
        );

        const newConfig = res.data;

        const newDepartments = departments
          .filter(d => d.value !== newConfig.value)
          .concat([newConfig]);

        setDepartments(newDepartments);
        setDepartment(null);
        filter !== FILTERS.ALL && onChangeFilter(filter);
        setError(null);
        setSuccess(
          `The new config for department ${department.label} has been successfully saved`
        );
      } catch (e) {
        console.log(e);
      }
      setSaving(false);
    } else setError("All checkin ranges must be in ascending order");
  };

  const showSaveBtn = values.length > 0 || (department && department.config);

  return (
    <div className="row">
      {success && (
        <Toast
          status="success"
          title="Config successfully saved"
          message={success}
          dismiss={() => setSuccess(null)}
        />
      )}
      <div className="card col-12 col-lg-4 mt-5 mr-lg-4">
        <div className="card-header">
          <h4>Departments</h4>
        </div>

        <div className="card-body">
          <div className="btn-group mb-3">
            {Object.values(FILTERS).map(f => (
              <button
                key={f}
                onClick={() => onChangeFilter(f)}
                className={`btn btn-sm ${
                  filter === f ? "btn-primary" : "btn-light"
                }`}
              >
                {f}
              </button>
            ))}
          </div>

          <Select options={filteredDepartments} onChange={onChangeDepartment} />
        </div>
      </div>

      <div className="card col-12 col-lg-7 mt-3 mt-lg-5 forecast-settings">
        <div className="card-header">
          <h4>Forecasting</h4>
        </div>
        <div className="card-body">
          <h6 className="mb-4">Define employees number for checkin ranges</h6>

          <div className="col-12 p-0">
            {!department ? (
              <div className="text-muted">Please select a department</div>
            ) : values.length === 0 ? (
              <button
                disabled={!department}
                className="btn btn-primary mt-2"
                onClick={() => department && change({ type: ACTIONS.NEW })}
              >
                + Add
              </button>
            ) : (
              <React.Fragment>
                <div className="row titles">
                  <div className="col-6">Checkins</div>
                  <div className="col-4">Employees</div>
                  {values.length > 2 && (
                    <div className="col-2">
                      <button
                        onClick={() => change({ type: ACTIONS.CLEAR })}
                        className="btn btn-sm btn-danger"
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </div>
                {values.map((row, index) => (
                  <Row
                    row={row}
                    index={index}
                    values={values}
                    change={change}
                    key={index}
                  />
                ))}
              </React.Fragment>
            )}
          </div>

          {error && <div className="input-validation-error">{error}</div>}

          {showSaveBtn && (
            <button
              disabled={saving}
              className="btn btn-primary mt-4"
              onClick={onSave}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Forecasting;

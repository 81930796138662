import moment from "moment";
import React from "react";

export default class StringHelper {
  static capitalize = string =>
    string ? string.charAt(0).toUpperCase() + string.slice(1) : "";

  static capitalizeAll = string =>
    string
      ? string
        .split(" ")
        .map(word => (word.length > 1 ? StringHelper.capitalize(word) : word))
        .join(" ")
      : "";

  static currencyFormatter(currency) {
    return currency
      ? new Intl.NumberFormat("en-EN", {
        style: "currency",
        currency: currency
      })
      : "";
  }

  static dateFormat(date) {
    const d = moment(date).format("MM/DD/YYYY");
    return d === "Invalid date" ? "N/A" : d;
  }

  static getTime(date) {
    const d = moment(date).format("hh:mm A");
    return d === "Invalid date" ? "N/A" : d;
  }

  static formatPhone(phone) {
    phone = phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : "";
    return phone;
  }

  static statusFormat(status) {
    if (!status) return "";

    if (status.type === "Buffer") {
      return status.data[0] === 1 ? "Active" : "Terminated";
    } else {
      return status ? "Active" : "Terminated";
    }
  }

  static formatScheduleStatus(status, object, data) {
    if (object === "schedule") {
      switch (status) {
        case "publish-schedule":
          return <span className="badge badge-success">Published</span>;
        case "submit":
          return <span className="badge badge-success">Submitted</span>;
        case "approved":
        case "approve":
          return <span className="badge badge-success">Approved</span>;
        case "delete-schedule":
          return <span className="badge badge-danger">Deleted</span>;
        case "rejected":
        case "reject":
        case "reject-schedule":
          return <span className="badge badge-danger">Rejected</span>;
        case "update-schedule":
          return <span className="badge badge-info">Updated</span>;
        case "submitted":
          return <span className="badge badge-warning">Submitted</span>;
        case "approve-released":
          return (
            <span className="badge badge-success">
              Approved Released Schedule
            </span>
          );
        case "reject-released":
          return (
            <span className="badge badge-danger">
              Rejected Released Schedule
            </span>
          );
        case "to-regular":
          return <span className="badge badge-pink">Change to Regular</span>;
        case "approve-to-regular":
          return (
            <span className="badge badge-success">Converted to Regular</span>
          );
        case "reject-to-regular":
          return (
            <span className="badge badge-danger">
              Reject to convert to Regular
            </span>
          );
        default:
          return <span className="badge badge-secondary">{status}</span>;
      }
    } else {
      const d = JSON.parse(data);
      switch (d.status) {
        case 1:
          return (
            <span className="badge badge-success">Share Employee Approved</span>
          );
        case 3:
          return (
            <span className="badge badge-danger">Share Employee Rejected</span>
          );
        case 0:
          return (
            <span className="badge badge-danger">Share Employee Deleted</span>
          );
        case 4:
          return (
            <span className="badge badge-info">Share Employee Updated</span>
          );
        case 2:
          return (
            <span className="badge badge-warning">Pending share Employee</span>
          );
        case 5:
        case 'auto-reject':
          return (
            <span className="badge badge-danger">
              Shared Employee Auto Rejected
            </span>
          );
        case 6:
        case 'end-working':
          return (
            <span className="badge badge-pink">
              Shared Employee End Working
            </span>
          );
        case 7:
          return (
            <span className="badge badge-cyan">
              Shared Employee Start Working
            </span>
          );
        default:
          return (
            <span className="badge badge-warning">Pending share Employee</span>
          );
      }
    }
  }

  static textTruncate(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }

  static formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? "-" : "";
      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  }

  static formatEmployeeShared(shared) {
    switch (shared) {
      case 1:
        return <span className="badge badge-success">Shared</span>;
      case 2:
        return <span className="badge badge-warning">Pending</span>;
      default:
        return <span className="badge badge-secondary">My Employee</span>;
    }
  }

  static formatLeaveStatus(status) {
    switch (status) {
      case "approved":
        return <span className="badge badge-success">Approved</span>;
      case "pending":
        return <span className="badge badge-warning">Pending</span>;
      case "rejected":
        return <span className="badge badge-danger">Rejected</span>;
      default:
        return <span className="badge badge-secondary">{status}</span>;
    }
  }
}

import axios from "axios";
import StorageManager from "../helpers/StorageManager";
import BaseService from "./BaseService";

class ClockInService extends BaseService {
  constructor() {
    super();
    this.api = axios.create({
      baseURL: process.env.REACT_APP_CLOCKIN_API_GATEWAY_ENDPOINT,
      // baseURL: "http://localhost:3021",
      headers: {
        Authorization: `Bearer ${StorageManager.get("token")}`,
      },
    });
  }
  getShift = () => this.apiGet({ url: "/clock-in/list" });
  startShift = ({ location, department, earningCode }) =>
    this.apiPost({
      url: "/clock-in",
      data: { location, department, earningCode },
    });
  endShift = (shiftId) => this.apiPut({ url: `/clock-in/end/${shiftId}` });
  startBreak = (shiftId, breakType) =>
    this.apiPut({ url: `/clock-in/break/start/${shiftId}?type=${breakType}` });
  endBreak = (shiftId) =>
    this.apiPut({ url: `/clock-in/break/end/${shiftId}` });

  // settings rules
  getRules = (params) => this.apiGet({ url: "/clock-in/rules", params });
  
  createRule = (data) => this.apiPost({ url: `/clock-in/rules`, data });

  canViewClockIn = (data) => this.apiGet({ url: `/clock-in/rules/can-view` });
  
  updateRule = (id, data) =>
    this.apiPut({ url: `/clock-in/rules/${id}`, data });
  
    deleteRule = (id) => this.apiDelete({ url: `/clock-in/rules/${id}` });
}

export default new ClockInService();

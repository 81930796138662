import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import StorageManager from "../../helpers/StorageManager";
import IdleTimer from "react-idle-manager";

// Containers
import Stream from "../Stream";
import Profile from "../Profile";
import Settings from "../Settings";
import People from "../People";
import ComposeMessage from "../ComposeMessage";
import NotFound from "../NotFound";
import Schedule from "../Schedule";
import Messages from "../Messages";
import Forecasting from "../Forecasting";
import ComposePersonalMsg from "../ComposePersonalMsg";
import Notifications from "../Notifications";
import NotificationPreview from "../NotificationPreview";
import PeopleDetails from "../PeopleDetails";
import Reports from "../Reports";
import Leaves from "../Leaves";
import ClockIn from "../ClockIn";

// Components
import Navbar from "../../components/Blocks/Navbar";
import userService from "../../services/UserService";
import ClockInService from "../../services/ClockInService";

// Styles
import "./assets/styles/App.css";
import Dashboard from "../Dashboard";
import moment from "moment";
moment.locale("en");

const DesktopRoute = ({ isMobile, component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ history, ...props }) => {
        return !isMobile ? (
          <Component history={history} {...props} user={user} />
        ) : (
          <Redirect to="/app" />
        );
      }}
    />
  );
};

class Auth extends React.Component {
  state = {
    isLoading: true,
    isMobile: false,
    canViewClockIn: false,
    user: {},
    notifications: [],
    messages: [],
  };

  logout = (e) => {
    e && e.preventDefault();

    StorageManager.clearOne("token");
    this.props.history.replace("/");
  };

  listenForWindowChange = (e) => {
    const { innerWidth } = window;

    if (innerWidth) {
      if (innerWidth <= 526) {
        document
          .getElementsByTagName("body")[0]
          .classList.add("sidebar-gone", "active");
        this.setState({ isMobile: true });
      } else {
        document
          .getElementsByTagName("body")[0]
          .classList.remove("sidebar-gone", "active");
        this.setState({ isMobile: false });
      }
    }
  };

  componentDidMount() {
    this.getUser();
    this.listenForWindowChange();
    window.onresize = this.listenForWindowChange;

    this.setState({
      isLoading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.isIdle &&
      this.props.isIdle &&
      ["production", "prod"].includes(process.env.NODE_ENV)
    ) {
      this.logout();
    } else if (this.props.location.pathname !== prevProps.pathname) {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("sidebar-gone", "active");
    }
  }
  getUser() {
    userService
      .getUser("self")
      .then((response) => {
        if (response.data) {
          
          // check if can view clockin tab
          ClockInService.canViewClockIn()
            .then(res => {
              if (res.data === true) {
                this.setState({canViewClockIn: true});
              }
            })
            .catch(err => console.log(err));

          this.setState({
            user: response.data.user || {},
            messages: response.data.messages || [],
            notifications: response.data.notifications || [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  onChangeUserTimezone = (timezone) => {
    const user = this.state.user;
    this.setState({
      user: {
        ...user,
        ...{
          tzId: timezone.id,
          tzName: timezone.text,
          tzOffset: timezone.offset,
        },
      },
    });
  };
  onChangeUserWeekConf = (weekConf) => {
    const user = this.state.user;
    this.setState({
      user: {
        ...user,
        weekConf,
      },
    });
  };
  render() {
    const { isLoading, isMobile, user, notifications, messages, canViewClockIn } = this.state;
    const loading = (isLoading === true || !user.role) && <div id="spinner" />;

    return (
      <>
        {loading}
        <div id="app" className="page">
          {!loading && (
            <div className="main-wrapper page-main">
              <Navbar
                user={user}
                notifications={notifications}
                messages={messages}
                isMobile={isMobile}
                logout={this.logout}
                canViewClockIn={canViewClockIn}
              />
              <Switch>
                <Route
                  path="/app"
                  exact
                  render={({ history, ...props }) => (
                    <Stream
                      history={history}
                      {...props}
                      user={user}
                      notifications={notifications}
                      messages={messages}
                    />
                  )}
                />
                <Route
                  path="/app/profile"
                  render={({ history, ...props }) => (
                    <Profile history={history} {...props} user={user} />
                  )}
                />
                <Route
                  path="/app/settings"
                  render={({ history, ...props }) => (
                    <Settings
                      history={history}
                      {...props}
                      user={user}
                      onChangeUserTimezone={this.onChangeUserTimezone}
                      onChangeUserWeekConf={this.onChangeUserWeekConf}
                    />
                  )}
                />
                <Route
                  path="/app/forecasting"
                  render={({ history, ...props }) => (
                    <Forecasting history={history} {...props} user={user} />
                  )}
                />
                <DesktopRoute
                  isMobile={isMobile}
                  user={user}
                  path="/app/schedule"
                  component={Schedule}
                />
                {canViewClockIn ? (
                  <Route
                    path="/app/clock-in"
                    render={(props) => <ClockIn {...props} user={user} />}
                  />
                  ) : ( <Redirect to="/app" />) 
                }

                <DesktopRoute
                  isMobile={isMobile}
                  user={user}
                  path="/app/people"
                  exact
                  component={People}
                />
                <DesktopRoute
                  isMobile={isMobile}
                  user={user}
                  path="/app/people/:id"
                  exact
                  component={ComposeMessage}
                />
                <DesktopRoute
                  isMobile={isMobile}
                  user={user}
                  path="/app/people/details/:id"
                  exact
                  component={PeopleDetails}
                />
                <DesktopRoute
                  isMobile={isMobile}
                  user={user}
                  path="/app/reports"
                  component={Reports}
                />

                <Route
                  path="/app/leaves"
                  render={({ history, ...props }) => (
                    <Leaves history={history} {...props} user={user} />
                  )}
                />
                <Route
                  path="/app/messages"
                  render={({ history, ...props }) => (
                    <Messages history={history} {...props} user={user} />
                  )}
                />
                <Route
                  path="/app/compose-new-message"
                  component={ComposePersonalMsg}
                />
                <Route
                  path="/app/gmdashboard"
                  render={({ history, ...props }) => (
                    <Dashboard history={history} {...props} user={user} />
                  )}
                />
                <Route
                  path="/app/notifications"
                  render={({ history, ...props }) => (
                    <Notifications history={history} {...props} user={user} />
                  )}
                />
                <Route
                  path="/app/notification/:id"
                  exact
                  render={({ history, ...props }) => (
                    <NotificationPreview
                      history={history}
                      {...props}
                      user={user}
                    />
                  )}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default IdleTimer({
  key: `getarcon-app-${window.location.host}`,
  idleAfter: 60000 * 15,
})(Auth);

import React, { useState } from "react";
import Modal from "react-modal";
import StringHelper from "../../helpers/StringHelper";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxWidth: "80%",
    maxHeight: "80%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

function PreviewImportTable({
  isOpen,
  closeModal,
  data: { rows },
  name,
  importData,
  onSuccess
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onImport = async () => {
    setLoading(true);

    try {
      const res = await importData();
      if (res.data.status === 400) {
        setError(res.data.message);
      } else if (res.data.status === 200) {
        onSuccess && onSuccess();
        closeModal();
      }
    } catch (e) {
      setError(e.response ? e.response.data : e.message);
    }

    setLoading(false);
  };

  const head = rows[0];
  const body = rows.slice(1, 11);

  body.forEach(row => {
    for (let i = 0; i < row.length; i++) {
      const element = row[i];
      if (element === undefined) row[i] = "";
    }
  });
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customModalStyles}
      contentLabel="Preview Import"
      shouldCloseOnOverlayClick={false}
    >
      <h4 className="mb-4">Preview {name.toLowerCase()} import { (rows.length - 1) > body.length ? `(Showing ${body.length} from ${rows.length - 1} rows)` : ''}</h4>

      <div className="table-data-container">
        <table className="ExcelTable">
          <thead>
            <tr>
              {head.map((r, i) => (
                <th key={i}>{r}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {body.map((col, i) => (
              <tr key={i}>
                {col.map((c, i) => {
                  return (
                    <td key={i}>
                      {name.toLowerCase() === "budgets" && i > 1
                        ? `$${StringHelper.formatMoney(c || 0)}`
                        : c}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {error && <div className="input-validation-error">{error}</div>}

      <div className="mt-4">
        <button className="btn btn-primary" onClick={onImport}>
          {loading ? "Importing..." : "Import"}
        </button>
        <button className="btn btn-default ml-3" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default PreviewImportTable;

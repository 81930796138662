import CrudPage from "../../components/CrudPage";

import CrudService from "../../services/CrudService";

const {departments} = require("./sampleData");

const departmentsService = new CrudService("departments");

export default class Departments extends CrudPage {
  name = "Department";
  fields = [
    { name: "id", label: "ID", type: "text", showOnForm: false },
    { name: "code", label: "Code", type: "text" },
    { name: "description", label: "Description", type: "text" },
    { name: "isActive", label: "Active", type: "toggle", defaultVal: false }
  ];
  enableImport = true;
  importSampleLink =
    "https://dxv8p7y79jw20.cloudfront.net/Departments+Sample.csv";

  sampleData = departments;
  searchPlaceHolder="Search by description or code...";

  fetch(page, search) {
    return departmentsService.list(page, search);
  }

  create(data) {
    return departmentsService.create(data);
  }

  update(data) {
    delete data.levelDescription;
    return departmentsService.update(data);
  }

  delete(id) {
    return departmentsService.remove(id);
  }

  import(file) {
    return departmentsService.importSample(file);
  }
}

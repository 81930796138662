import ScheduleService from "../../../services/ScheduleService";
import SettingsService from "../../../services/SettingsService";
import { closeModal } from "../Modals";
import { TABS } from "../../../containers/Schedule/Constant";
import AsyncAlert from "../../../helpers/AsyncAlert";
import { clearSelectedSchedule } from "./EventActions";
import {
  calculateEstimation,
  calculateMyEstimation,
  handleMultiSelect,
  canModifyShift,
  isManager,
  buildFilters
} from "./SchedulerHelper";
import { mapEventsForMonthlyView } from "./EventHelper";
import UserFilterService from "../../../services/UserFilterService";

function saveSchedule(saveData) {
  return new Promise((resolve, reject) => {
    ScheduleService.saveSchedule(saveData)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
}

function saveNotification(notification) {
  if (notification.scheduleId && notification.userId) {
    SettingsService.updateEventNotificationConfig(
      notification.scheduleId,
      notification
    )
      .then(() => {
        this.call(closeModal);
      })
      .catch(e => {
        console.log(e);
      });
  } else {
    SettingsService.createEventNotificationConfig(
      this.state.eventId,
      notification
    )
      .then(response => {
        this.call(closeModal);
      })
      .catch(e => {
        console.log(e);
      });
  }
}
function saveFilter(name, id, callback = () => { }) {
  const filters = this.call(buildFilters, true);
  const { scheduleConfigs } = this.state;
  if (!scheduleConfigs.filters) {
    scheduleConfigs.filters = [];
  }
  const data = {
    name,
    view: 'scheduler-employee-view',
    locations: filters.locations,
    departments: filters.departments,
    jobs: filters.jobs,
    sections: filters.sectionId,
    events: filters.events,
  };

  (id === 0 ? UserFilterService.create(data) : UserFilterService.update(id, data)).then((response) => {
    if (response && response.data && (response.data.id || id)) {
      data.id = id === 0 ? response.data.id : id;
      const filterIndex = scheduleConfigs.filters.findIndex(f => f.id === id);
      if (filterIndex > -1) {
        scheduleConfigs.filters[filterIndex] = data;
      } else {
        scheduleConfigs.filters.push(data);
      }
      this.setState({
        scheduleConfigs,
        selectedFilterId: data.id
      }, callback)
    }
  })
}
function deleteFilter(id, callback = () => { }) {
  const { scheduleConfigs } = this.state;
  if (!scheduleConfigs.filters) {
    scheduleConfigs.filters = [];
  }
  UserFilterService.delete(id).then(response => {
    scheduleConfigs.filters = scheduleConfigs.filters.filter(f => f.id !== id);
    this.setState({
      scheduleConfigs,
      selectedFilterId: ''
    }, callback)
  })
}
function deleteNotification(notification) {
  if (notification.scheduleId && notification.userId) {
    SettingsService.deleteEventNotificationConfig(this.state.eventId)
      .then(() => {
        this.call(closeModal);
      })
      .catch(e => {
        console.log(e);
      });
  }
}

async function onEventDelete(event, selectedEvent, isMonthlyView = false) {
  const property = [
    this.state.activeTab === TABS.MY_SHIFT ? "selfEvents" : "events"
  ];
  selectedEvent = selectedEvent.filter(ev =>
    canModifyShift(
      ev,
      this.state.activeTab,
      this.state.user && isManager(this.state.user)
    )
  );
  if (selectedEvent.length) {
    const allow = await AsyncAlert.alert(
      `Delete ${selectedEvent.length || 1} schedule${
      selectedEvent.length > 1 ? "s" : ""
      }`,
      `You are going to delete  ${selectedEvent.length || 1} schedule${
      selectedEvent.length > 1 ? "s" : ""
      }. Do you want to continue?`
    );
    if (allow) {
      this.setState({ isLoading: true });
      if (selectedEvent.length > 1) {
        let ids = selectedEvent.map(e => e.id);
        if (ids.length) {
          ScheduleService.deleteSchedules(ids)
            .then(async () => {
              this.call(clearSelectedSchedule);
              this.call(handleMultiSelect, []);
              const events = (this.state[property] || []).filter(
                ev => !ids.includes(ev.id)
              );
              const lastCopiedEvents = (
                this.state.lastCopiedEvents || []
              ).filter(ev => !ids.includes(ev.id));
              if (isMonthlyView) {
                await this.call(mapEventsForMonthlyView, events, true);
              }
              this.setState(
                {
                  [property]: events,
                  available: this.state.available.filter(
                    ev => !ids.includes(ev.id)
                  ),
                  lastCopiedEvents,
                  eventsVersion: this.state.eventsVersion + 1,
                  resourcesVersion: this.state.resourcesVersion + 1,
                  availableEventsVersion: this.state.availableEventsVersion + 1,
                  isLoading: false
                },
                async () => {
                  if (this.state.activeTab === TABS.MY_EMPLOYEES) {
                    await this.call(calculateEstimation);
                  } else if (this.state.activeTab === TABS.MY_SHIFT) {
                    await this.call(calculateMyEstimation);
                  }
                }
              );
            })
            .catch(() => {
              this.setState({
                eventsVersion: this.state.eventsVersion + 1,
                resourcesVersion: this.state.resourcesVersion + 1,
                availableEventsVersion: this.state.availableEventsVersion + 1,
                isLoading: false
              });
            });
        }
      } else {
        const { id } = event.eventRecord.data;
        this.setState({ isLoading: true });
        ScheduleService.deleteSchedule(id)
          .then(async () => {
            this.call(clearSelectedSchedule);
            this.call(handleMultiSelect, []);
            const events = (this.state[property] || []).filter(
              ev => ev.id !== id
            );
            const lastCopiedEvents = (this.state.lastCopiedEvents || []).filter(
              ev => ev.id !== id
            );
            if (isMonthlyView) {
              await this.call(mapEventsForMonthlyView, events, true);
            }
            this.setState(
              {
                [property]: events,
                available: this.state.available.filter(ev => ev.id !== id),
                lastCopiedEvents,
                eventsVersion: this.state.eventsVersion + 1,
                resourcesVersion: this.state.resourcesVersion + 1,
                availableEventsVersion: this.state.availableEventsVersion + 1,
                isLoading: false
              },
              async () => {
                if (this.state.activeTab === TABS.MY_EMPLOYEES) {
                  await this.call(calculateEstimation);
                } else if (this.state.activeTab === TABS.MY_SHIFT) {
                  await this.call(calculateMyEstimation);
                }
              }
            );
          })
          .catch(e => {
            console.log(e);
            this.setState({
              eventsVersion: this.state.eventsVersion + 1,
              resourcesVersion: this.state.resourcesVersion + 1,
              availableEventsVersion: this.state.availableEventsVersion + 1,
              isLoading: false
            });
          });
      }
    } else {
      this.setState({
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion: this.state.availableEventsVersion + 1,
        isLoading: false
      });
    }
  }
}
export { saveSchedule, saveNotification, saveFilter, deleteFilter, deleteNotification, onEventDelete };

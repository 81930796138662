import React from "react";
import moment from "moment";
//eslint-disable-next-line
import {
  DateHelper,
  DomHelper,
  PresetManager,
  Scheduler,
  Store
} from "../../libs/scheduler/scheduler.module";
import "./assets/styles.css";
import "./assets/scheduler.material.css";
import "./ExcelExporter";
// import { YesNo } from "./CustomDrag";
import COLORS from "./colors";
import ExcelExporter from "./ExcelExporter";
import OverTime from "../../helpers/OverTime";
import BreakData from "./data/data";
import BreakController from "./Breaks";
import Duration from "../../helpers/Duration";
import {
  defaultShifts,
  disableOrEnableEventEdit,
  timeOpenSettings,
} from "./Configs";
import {
  SCHEDULE_EVENTS,
  SCHEDULE_MAPPED_EVENTS,
  SCHEDULE_MODES,
  SCHEDULE_STATUSES,
  MYSQL_DATETIME_FORMAT,
  TABS,
} from "../../containers/Schedule/Constant";
import ErrorToast from "./ErrorToast";
import AsyncAlert from "../../helpers/AsyncAlert";
import { eventDragValidation } from "./ValidFunctions";
import EventDragConfig from "./DragEvent";
import {
  canModifyShift,
  isShiftUnavailable,
} from "../Schedule/helpers/SchedulerHelper";
import { generateZones, onDateTimeChange } from "./Zones";
import { getEligibleEnd, getEligibleStart } from "../CalendarView/BreakHelper";

// Register the custom widget to make it available
// BryntumWidgetAdapterRegister.register('yesno', YesNo);
const breakController = new BreakController();
const presets = {
  hourAndDay: {
    tickWidth: 70,
    displayDateFormat: "ll LT",
    shiftIncrement: 1,
    shiftUnit: "day",
    defaultSpan: 24,
    timeResolution: {
      unit: "minute",
      increment: 5,
    },
    headerConfig: {
      middle: {
        unit: "h",
        align: "center",
        dateFormat: "KK",
      },
      top: {
        unit: "d",
        align: "center",
        dateFormat: "ddd DD MMM",
      },
    },
  },
  dayAndWeek: {
    tickWidth: 100,
    displayDateFormat: "ll LT",
    shiftUnit: "day",
    shiftIncrement: 1,
    defaultSpan: 5,
    timeResolution: {
      unit: "hour",
      increment: 1,
    },
    headerConfig: {
      top: {
        unit: "week",
        renderer(start, end, cfg) {
          return (
            DateHelper.format(start, "DD") +
            " - " +
            DateHelper.format(end, "DD") +
            " " +
            DateHelper.format(start, "MMM YYYY")
          );
          //return DateHelper.getShortNameOfUnit('week') + '.' + DateHelper.format(start, 'WW MMM YYYY');
        },
      },
      middle: {
        unit: "day",
        dateFormat: "ddd D MMM",
      } /*,
            bottom: {
                unit: 'day',
                renderer(start, end, cfg) {
                    cfg.headerCellCls = 'sch-hdr-startend';
                    return `<span>${DateHelper.format(start, 'HH:mm')}</span><span>${DateHelper.format(end, 'HH:mm')}</span>`;
                }
            }*/,
    },
  },
  dayAndMonth: {
    tickWidth: 100,
    displayDateFormat: "ll LT",
    shiftUnit: "day",
    shiftIncrement: 1,
    defaultSpan: 5,
    timeResolution: {
      unit: "hour",
      increment: 1,
    },
    headerConfig: {
      middle: {
        unit: "day",
        dateFormat: "ddd DD",
      },
      top: {
        unit: "month",
        dateFormat: "MMM YYYY", //Jan 2017
      } /*,
            bottom: {
                unit: 'day',
                renderer(start, end, cfg) {
                    cfg.headerCellCls = 'sch-hdr-startend';
                    return `<span>${DateHelper.format(start, 'HH:mm')}</span><span>${DateHelper.format(end, 'HH:mm')}</span>`;
                }
            },*/,
    },
  },
};
PresetManager.registerPreset("dayAndMonth", presets.dayAndMonth);
const breakData = new BreakData();
const overTime = new OverTime();
const break1StartTime = new Store({
  storeId: "break1StartTime",
  data: breakData.time,
});
const break1EndTime = new Store({
  storeId: "break1EndTime",
  data: breakData.time,
});
const break2StartTime = new Store({
  storeId: "break1StartTime",
  data: breakData.time,
});
const break2EndTime = new Store({
  storeId: "break1EndTime",
  data: breakData.time,
});
const break3StartTime = new Store({
  storeId: "break1StartTime",
  data: breakData.time,
});
const break3EndTime = new Store({
  storeId: "break1EndTime",
  data: breakData.time,
});
const break4StartTime = new Store({
  storeId: "break1StartTime",
  data: breakData.time,
});
const break4EndTime = new Store({
  storeId: "break1EndTime",
  data: breakData.time,
});

const breaks = new Store({
  storeId: "breaks",
  data: breakData.breaks,
});
const locations = new Store({
  storeId: "locations",
  data: [],
});
const jobs = new Store({
  storeId: "jobs",
  data: [],
});
const sectionStore = new Store({
  storeId: "sections",
  data: [],
});
/* eslint-disable */
export default class BryntumScheduler extends React.Component {
  schedulerFeatures = [
    "cellEdit",
    "columnLines",
    "dependencies",
    "eventDrag",
    "eventContextMenu",
    "eventDragCreate",
    "eventEdit",
    "eventFilter",
    "eventResize",
    "eventTooltip",
    "group",
    "groupSummary",
    "headerContextMenu",
    "labels",
    "nonWorkingTime",
    "regionResize",
    "sort",
    "scheduleTooltip",
    "stripe",
    "summary",
    "timeRanges",
    "filterBar",
  ];
  static currentDate = null;
  isUnavailability = false;
  isAvailable = false;
  static selectedEvents = [];
  locationsData = [];
  jobsData = [];
  locationMap = {};
  jobsMap = {};
  scheduleConfigs = {
    sections: {},
  };
  loggedInUser = null;
  static defaultProps = {
    enableDeleteKey: false,
    mapLocationsData: {},
    barMargin: 2,
    eventsVersion: 0,
    resourcesVersion: 0,
    eventStyle: "colored",
    eventSelectionDisabled: false,
    enableCellContextMenu: true,
    enableHeaderContextMenu: false,
    multiEventSelect: true,
    rowHeight: 80,
    responsiveLevels: {
      small: {
        levelWidth: 400,
      },
      medium: {
        levelWidth: 600,
      },
      normal: {
        levelWidth: "*",
      },
    },
    resourceStore: {
      pageSize: 50,
    },
    features: {
      filterBar: false,
      cellEdit: false,
      timeRanges: {
        enableResizing: false,
        showCurrentTimeLine: false,
        showHeaderElements: true,
      },
    },
    eventRenderer({ eventRecord, resourceRecord, tplData }) {
      eventRecord.data.startDate = eventRecord.data.startDate || eventRecord.startDate;
      eventRecord.data.endDate = eventRecord.data.endDate || eventRecord.endDate;
      eventRecord.data.currentDate = eventRecord.data.currentDate || eventRecord.currentDate;
      eventRecord.data.breakData = eventRecord.data.breakData || eventRecord.breakData;
      eventRecord.data.name = eventRecord.data.name || eventRecord.name;

      eventRecord.data.eventColor = eventRecord.originalData.eventColor =
        eventRecord.data.status === "approved"
          ? (eventRecord.data.color ? eventRecord.data.color : COLORS[eventRecord.data.name])
          : COLORS[eventRecord.data.status] || "yellow";
      const renderRole =
        this.scheduleMode === SCHEDULE_MODES.TODAY ||
        this.scheduleMode === SCHEDULE_MODES.THIS_WEEK;
      const startDate = moment(eventRecord.data.startDate);
      startDate.second() || startDate.millisecond()
        ? startDate.add(1, "minute").startOf("minute")
        : startDate.startOf("minute");
      const endDate = moment(eventRecord.data.endDate);
      endDate.second() || endDate.millisecond()
        ? endDate.add(1, "minute").startOf("minute")
        : endDate.startOf("minute");
      const dateToPx = (date) =>
        this.getCoordinateFromDate(date) - tplData.left;
      let breakData = [];
      if (eventRecord.data.breakData) {
        try {
          breakData = JSON.parse(eventRecord.data.breakData);
        } catch (e) {
          console.log(e);
        }
      }
      if (this.scheduleMode !== SCHEDULE_MODES.TODAY) {
        const event = eventRecord.data;
        const startDate = DateHelper.parse(event.startDate);
        const daySettings = timeOpenSettings[startDate.getDay()];
        if (daySettings) {
          const currentDate = moment(event.currentDate).format("YYYY-MM-DD");
          const eventStartDate = moment(
            `${currentDate} ${daySettings.start.hr}:${daySettings.start.min}:00`
          ).toDate();
          const eventEndDate = moment(
            `${currentDate} ${daySettings.end.hr}:${daySettings.end.min}:00`
          ).toDate();
          tplData.left = this.getCoordinateFromDate(eventStartDate);
          tplData.width =
            this.getCoordinateFromDate(eventEndDate) - tplData.left;
        }
      }
      const location = resourceRecord && resourceRecord.data && resourceRecord.data.locationId && this.mapLocationsData ? this.mapLocationsData[resourceRecord.data.locationId] : { code: '' };
      const role =
        renderRole &&
          resourceRecord &&
          resourceRecord.role &&
          eventRecord.data.jobId &&
          this.mapJobs
          ? (this.mapJobs[eventRecord.data.jobId]
            ? this.mapJobs[eventRecord.data.jobId].text
            : resourceRecord.role) +
          " •" +
          " "
          : "";
      let availableString = '';
      if (this.isAvailable && this.mapJobs && this.mapLocations) {
        availableString = `${
          this.mapLocations[eventRecord.data.locationId] || ""
          } <br/> ${
          this.mapJobs[eventRecord.data.jobId]
            ? this.mapJobs[eventRecord.data.jobId].text
            : ""
          } • `;
      }
      let sectionName = "";
      if (
        this.scheduleConfigs &&
        this.scheduleConfigs.sections &&
        eventRecord.data.sectionId &&
        (
          this.scheduleConfigs.sections[eventRecord.data.jobId] ||
          this.scheduleConfigs.sections['all']
        )
      ) {
        let sections = this.scheduleConfigs.sections[eventRecord.data.jobId] || [];
        if (this.scheduleConfigs.sections['all']) {
          sections = [...sections, ...this.scheduleConfigs.sections['all']];
        }
        try {
          eventRecord.data.sectionId = JSON.parse(eventRecord.data.sectionId);
        } catch (e) {
          
        }
        if (!Array.isArray(eventRecord.data.sectionId)) {
          eventRecord.data.sectionId = [eventRecord.data.sectionId];
        }
        const selectedSections = sections.filter(
          (s) => eventRecord.data.sectionId.includes(s.value)
        );
        try {
          eventRecord.data.sectionData = JSON.parse(eventRecord.data.sectionData);
        } catch (e) {
          if (!Array.isArray(eventRecord.data.sectionData)) {
            if (eventRecord.data.sectionData) {
              eventRecord.data.sectionData = [eventRecord.data.sectionData];
            } else {
              eventRecord.data.sectionData = [];
            }
          }
        }
        let sectionData = eventRecord.data.sectionData || [];
        selectedSections.forEach(s => {
          if (!sectionData.find(sd => sd.zoneId === s.value)) {
            sectionData.push({
              startTime: moment(eventRecord.data.startDate).format('HH:mm'),
              startTimeDisplay: moment(eventRecord.data.startDate).format('HH:mm A'),
              endTime: moment(eventRecord.data.endDate).format('HH:mm'),
              endTimeDisplay: moment(eventRecord.data.endDate).format('HH:mm A'),
              zoneColor: s.color,
              zoneId: s.value,
              zone: s.text,
              id: s.value
            });
          }
        })
        eventRecord.sectionData = eventRecord.data.sectionData;
        eventRecord.sectionId = eventRecord.data.sectionId;
        if (Array.isArray(sectionData) && sectionData.length > 0) {
          sectionName = sectionData.map(section => `<span class="${section.zoneColor ? 'zone ' + section.zoneColor : ''}">${section.zone}| ${section.startTimeDisplay || section.startTime}-${section.endTimeDisplay || section.endTime}</span>`).join(' ');
        }
      }
      if (
        this.scheduleMode === SCHEDULE_MODES.TODAY &&
        breakData.length &&
        eventRecord.data.name === "Regular"
      ) {
        const nestedEvents = [];
        for (let i = 0; i < breakData.length; i++) {
          const br = breakData[i];
          if (i === 0) {
            const event = {};
            event.startDate = moment(eventRecord.data.startDate).toDate();
            event.endDate = moment(`${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${br.start}:00`).toDate();
            event.startOffset = DateHelper.diff(moment(eventRecord.data.startDate).toDate(), event.startDate);
            event.endOffset = DateHelper.diff(event.startDate, event.endDate);
            const hours = Duration.durationAsHoursAndMinsString(
              `${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${br.start}:00`,
              eventRecord.data.startDate
            );
            event.name = `
                      <span class="text-break white-space-normal font-size">
                      ${
              eventRecord.name === "Scheduled Day Off"
                ? `${
                eventRecord.data.leaveId
                  ? '<span style="font-size: 10px;font-weight: 300;">Generated By Leave</span> <br/>'
                  : ""
                }`
                : hours + " <br/>"
              } ${role}${eventRecord.name}${sectionName !== '' ? " • " + sectionName : '' }
                    ${" • " + typeof location === "object" ? location.code : ''}
                      </span>
                  `;
            event.class = "";
            nestedEvents.push(event);
          }
          //add break event
          const breakEvent = {};
          breakEvent.startDate = moment(
            `${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${br.start}:00`
          ).toDate();
          breakEvent.endDate = moment(
            `${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${
            br.end
            }`
          ).toDate();
          breakEvent.startOffset = DateHelper.diff(
            moment(eventRecord.data.startDate).toDate(),
            breakEvent.startDate
          );
          breakEvent.endOffset = DateHelper.diff(
            breakEvent.startDate,
            breakEvent.endDate
          );
          breakEvent.name =
            `${moment(breakEvent.startDate)
              .format("h.ma")
              .replace("m", "")}<br>${moment(breakEvent.endDate)
                .format("h.ma")
                .replace("m", "")}<br>` +
            (br.duration > 60
              ? `${(br.duration - (br.duration % 60)) / 60}h ${
              br.duration % 60
              }m`
              : `${br.duration}m`);
          breakEvent.class = "break";
          nestedEvents.push(breakEvent);

          const event = {};
          let hours;
          if (i + 1 < breakData.length) {
            const br2 = breakData[i + 1];
            event.startDate = moment(
              `${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${
              br.end
              }`
            ).toDate();
            event.endDate = moment(
              `${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${
              br2.start
              }`
            ).toDate();
            hours = Duration.durationAsHoursAndMinsString(
              `${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${
              br2.start
              }`,
              `${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${
              br.end
              }`
            );
          } else {
            event.startDate = moment(
              `${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${
              br.end
              }`
            ).toDate();
            event.endDate = moment(eventRecord.data.endDate).toDate();
            hours = Duration.durationAsHoursAndMinsString(
              eventRecord.data.endDate,
              `${moment(eventRecord.data.startDate).format("YYYY-MM-DD")} ${
              br.end
              }`
            );
          }
          event.startOffset = DateHelper.diff(
            moment(eventRecord.data.startDate).toDate(),
            event.startDate
          );
          event.endOffset = DateHelper.diff(event.startDate, event.endDate);
          event.name = `
                      <span class="text-break white-space-normal font-size">
                        ${hours}
                      </span>
                  `;
          event.class = "";
          nestedEvents.push(event);
        }
        return nestedEvents
          .map((nestedEvent) => ({
            left: dateToPx(
              DateHelper.add(eventRecord.startDate, nestedEvent.startOffset)
            ),
            width: dateToPx(
              DateHelper.add(eventRecord.startDate, nestedEvent.endOffset)
            ),
            class: nestedEvent.class,
            name: nestedEvent.name,
          }))
          .map(
            (value) => `
                <div class="nested ${value.class}" style="left: ${value.left}px;width: ${value.width}px">
                    ${value.name}
                </div>
              `
          )
          .join("");
      } else {
        const hours = Duration.durationAsHoursAndMinsWithBreakString(
          endDate,
          startDate
        );
        let breaks = "";
        if (eventRecord.data.breaks > 0) {
          if (eventRecord.data.breaks > 60) {
            const breakMins = eventRecord.data.breaks % 60;
            const breakHours = (eventRecord.data.breaks - breakMins) / 60;
            breaks = `• Break${breakHours > 0 ? " " + breakHours + "h" : ""}${
              breakMins > 0 ? " " + breakMins + "min" : ""
              }`;
          } else {
            breaks = `• Break ${eventRecord.data.breaks}min`;
          }
        }
        return `
                  <span class="text-break white-space-normal font-size">
                    ${
          this.scheduleMode !== "today" &&
            eventRecord.name !== "Scheduled Day Off"
            ? startDate.format("LT") +
            " - " +
            endDate.format("LT") +
            " • "
            : ""
          } 
                    ${
          eventRecord.name === "Scheduled Day Off"
            ? `${
            eventRecord.data.leaveId
              ? '<span style="font-size: 10px;font-weight: 300;">Generated By Leave</span> <br/>'
              : ""
            }`
            : hours + " <br/>"
          }
                    ${role}${eventRecord.name} ${breaks} ${" • " + typeof location === "object" ? location.code : ''} 
                    ${sectionName !== '' ? " • " + sectionName : '' }
                  </span>
              `;
      }
    },
  };
  catchAll = (event) => {
    switch (event.type) {
      case "eventcontextmenuitem":
        if (event.item.name === "deleteEvent")
          return this.props.onEventDelete(event, this.selectedEvents);
        return;
      case "beforeeventdelete":
        return this.props.onEventDelete(event, this.selectedEvents);
      case "eventresizeend":
      case "aftereventsave":
        if (event.eventRecord) {
          if (event.type === "eventresizeend") {
            const resource = event.eventRecord.resource.data;
            event.eventRecord.data.name =
              event.eventRecord.data.name ||
              (this.isUnavailability ||
                (this.props.isManager === false &&
                  this.props.isMySchedule === true)
                ? "Unavailability"
                : "Regular");
            if (
              moment(event.eventRecord.data.endDate).isAfter(
                moment(event.eventRecord.data.startDate),
                "day"
              )
            ) {
              event.eventRecord.data.endDate = moment(
                `${moment(event.eventRecord.data.startDate).format(
                  "YYYY-MM-DD"
                )} 23:59:00`
              ).toDate();
              event.eventRecord.endDate = moment(
                `${moment(event.eventRecord.data.startDate).format(
                  "YYYY-MM-DD"
                )} 23:59:00`
              ).toDate();
            }
            if (
              breakController.breakRules[resource.locationState] &&
              event.eventRecord.data.name === SCHEDULE_MAPPED_EVENTS.REGULAR &&
              resource.salaryHourly === "H"
            ) {
              let sd = moment(event.eventRecord.data.startDate);
              const ed = moment(event.eventRecord.data.endDate);
              event.eventRecord = breakController.generateBreaksByRules(
                event.eventRecord,
                sd,
                ed,
                breakController.breakRules[resource.locationState]
              );
            } else {
              event.eventRecord = breakController.moveBreaks(event.eventRecord);
            }
          }
          return this.props.onEventSave(event);
        }
        return;
      case "aftereventdrop":
        if (!event.eventRecords || !event.eventRecords[0]) return;
        const resource = event.eventRecords[0].resource.data;
        if (resource &&
          breakController.breakRules[resource.locationState] &&
          event.eventRecords[0].data.name === SCHEDULE_MAPPED_EVENTS.REGULAR &&
          resource.salaryHourly === "H"
        ) {
          let sd = moment(event.eventRecords[0].data.startDate);
          const ed = moment(event.eventRecords[0].data.endDate);
          event.eventRecords[0] = breakController.generateBreaksByRules(
            event.eventRecords[0],
            sd,
            ed,
            breakController.breakRules[resource.locationState]
          );
        } else {
          event.eventRecords[0] = breakController.moveBreaks(
            event.eventRecords[0]
          );
        }
        return this.props.onEventMove(event);
      case "beforeeventdropfinalize":
        if (this.props.scheduleMode !== "today") {
          const { context } = event;
          let { endDate, startDate, origEnd, origStart } = context;
          endDate = moment(endDate).format(`${MYSQL_DATETIME_FORMAT}:00`);
          startDate = moment(startDate).format(`${MYSQL_DATETIME_FORMAT}:00`);
          origEnd = moment(origEnd).format(`${MYSQL_DATETIME_FORMAT}:00`);
          origStart = moment(origStart).format(`${MYSQL_DATETIME_FORMAT}:00`);
          if (!moment(origStart).isSame(moment(startDate), "day")) {
            event.context.startDate = DateHelper.parse(
              moment(
                `${moment(startDate).format("YYYY-MM-DD")} ${moment(
                  origStart
                ).format("HH:mm:ss")}`
              ).format()
            );
            event.context.endDate = DateHelper.parse(
              moment(
                `${moment(startDate).format("YYYY-MM-DD")} ${moment(
                  origEnd
                ).format("HH:mm:ss")}`
              ).format()
            );
          } else if (!moment(origEnd).isSame(moment(endDate), "day")) {
            event.context.startDate = DateHelper.parse(
              moment(
                `${moment(endDate).format("YYYY-MM-DD")} ${moment(
                  origStart
                ).format("HH:mm:ss")}`
              ).format()
            );
            event.context.endDate = DateHelper.parse(
              moment(
                `${moment(endDate).format("YYYY-MM-DD")} ${moment(
                  origEnd
                ).format("HH:mm:ss")}`
              ).format()
            );
          } else {
            event.context.startDate = DateHelper.parse(
              moment(
                `${moment(origStart).format("YYYY-MM-DD")} ${moment(
                  origStart
                ).format("HH:mm:ss")}`
              ).format()
            );
            event.context.endDate = DateHelper.parse(
              moment(
                `${moment(origEnd).format("YYYY-MM-DD")} ${moment(
                  origEnd
                ).format("HH:mm:ss")}`
              ).format()
            );
          }
        }
        return;
      case "beforeeventadd":
        if (event.newEventRecord) {
          this.setBreakAdded({
            break1: false,
            break2: false,
            break3: false,
            break4: false,
          });
          event.newEventRecord.data.breaks = 0;
          this.currentDate = event.newEventRecord.data.startDate;
          event.newEventRecord.data.name =
            this.isUnavailability ||
              (this.props.isManager === false && this.props.isMySchedule === true)
              ? "Unavailability"
              : "Regular";
          if (this.props.scheduleMode !== "today") {
            const resource = event.resources[0].data;
            if (
              breakController.breakRules[resource.locationState] &&
              event.newEventRecord.data.name ===
              SCHEDULE_MAPPED_EVENTS.REGULAR &&
              resource.salaryHourly === "H"
            ) {
              event.newEventRecord.data.breaks = 30;
            }
            const date = moment(event.newEventRecord.data.startDate).format(
              "YYYY-MM-DD"
            );
            if (overTime.isOnCanada(resource.locationCode)) {
              const config = defaultShifts.CA;
              event.newEventRecord.data.startDate = moment(
                `${date} ${config.start}`
              ).toDate();
              event.newEventRecord.data.endDate = moment(
                `${date} ${config.finish}`
              ).toDate();
              event.newEventRecord.startDate = moment(
                `${date} ${config.start}`
              ).toDate();
              event.newEventRecord.endDate = moment(
                `${date} ${config.finish}`
              ).toDate();
            } else {
              event.newEventRecord.data.startDate = moment(
                `${date} 09:00:00`
              ).toDate();
              event.newEventRecord.data.endDate = moment(`${date} 09:00:00`)
                .add(8, "hours")
                .toDate();
              event.newEventRecord.startDate = moment(
                `${date} 09:00:00`
              ).toDate();
              event.newEventRecord.endDate = moment(`${date} 09:00:00`)
                .add(8, "hours")
                .toDate();
            }
          }
          event.newEventRecord.data.currentDate =
            event.newEventRecord.data.startDate; //moment(event.newEventRecord.data.startDate).format('L').toString();
          event.newEventRecord.currentDate =
            event.newEventRecord.data.startDate;
        }
        return;
      case "beforeeventedit":
        this.currentDate = event.eventRecord.data.startDate;
        event.eventRecord.data.currentDate = event.eventRecord.data.startDate; //moment(event.eventRecord.data.startDate).format('L').toString();
        event.eventRecord.currentDate = event.eventRecord.data.startDate;
        if (`${event.eventRecord.data.id}`.indexOf("_generated") !== -1) {
          const resource = event.resourceRecord.data;
          event.eventRecord.data.locationId = resource.locationId;
          event.eventRecord.data.jobId =
            resource.jobId || resource.secondaryJobId;
          event.eventRecord.locationId = resource.locationId;
          event.eventRecord.jobId = resource.jobId || resource.secondaryJobId;
          event.eventRecord.data.name =
            this.isUnavailability ||
              (this.props.isManager === false && this.props.isMySchedule === true)
              ? "Unavailability"
              : "Regular";
          this.setBreakAdded({
            break1: false,
            break2: false,
            break3: false,
            break4: false,
          });
          this.resetEventEdit(event.eventEdit);
          if (
            breakController.breakRules[resource.locationState] &&
            resource.salaryHourly === "H" &&
            event.eventRecord.data.name === SCHEDULE_MAPPED_EVENTS.REGULAR
          ) {
            let sd = moment(event.eventRecord.data.startDate);
            const ed = moment(event.eventRecord.data.endDate);
            event.eventRecord = breakController.generateBreaksByRules(
              event.eventRecord,
              sd,
              ed,
              breakController.breakRules[resource.locationState]
            );
          } else {
            event.eventRecord = breakController.moveBreaks(event.eventRecord);
          }
        }
        const eventData = event.eventRecord.data;
        const widgets = event.eventEdit.extraWidgets;
        const res = event.resourceRecord.data;
        const locationIdWidget = widgets.find((w) => w.name === "locationId");
        const jobIdWidget = widgets.find((w) => w.name === "jobId");
        const sectionIdWidget = widgets.find((w) => w.name === "sectionId");
        const currDate = moment(event.eventRecord.data.currentDate);
        if (res && res.locationId && locationIdWidget) {
          const locData = JSON.parse(JSON.stringify(this.locationsData)).filter(
            (l) => res.allLocations && res.allLocations.length ? res.allLocations.filter(el =>
              el.startDate === null || el.endDate === null || (currDate.isBetween(moment(el.startDate), moment(el.endDate), "D"))
            ).map(el => el.locationId).includes(l.value) : l.value === res.locationId
          );
          const locId = locationIdWidget.value;
          console.log(this.locationsData, locData, currDate.format(), locId, event.eventRecord.data);
          locationIdWidget.store.removeAll();
          locationIdWidget.store.data = locData;
          const loggedInUserLocation = this.loggedInUser && this.loggedInUser.locationId ? locData.find(l => l.value === this.loggedInUser.locationId) : null;
          if (!locId) {
            locationIdWidget.value = eventData && eventData.locationId ? eventData.locationId : (loggedInUserLocation ? loggedInUserLocation.value : res.locationId);
            event.eventRecord.data.locationId = locationIdWidget.value;
            event.eventRecord.locationId = locationIdWidget.value;
          } else {
            locationIdWidget.value = locId;
          }
          locationIdWidget.disabled = locData.length === 1 && !this.isAvailable;
        } else if (locationIdWidget && !res.locationId) {
          const locData = JSON.parse(JSON.stringify(this.locationsData));
          locationIdWidget.store.removeAll();
          locationIdWidget.store.data = locData;
          const loggedInUserLocation = this.loggedInUser && this.loggedInUser.locationId ? locData.find(l => l.value === this.loggedInUser.locationId) : null;
          if (!loggedInUserLocation) {
            locationIdWidget.value = loggedInUserLocation.value;
            event.eventRecord.data.locationId = locationIdWidget.value;
            event.eventRecord.locationId = locationIdWidget.value;
          }

          locationIdWidget.disabled = locData.length === 1 && !this.isAvailable;
        }
        if (res && (res.jobId || res.secondaryJobId) && jobIdWidget) {
          const jobData = JSON.parse(
            JSON.stringify(this.jobsData)
          ).filter((l) => l.employees.includes(res.employeeId));
          jobIdWidget.store.removeAll();
          jobIdWidget.store.data = jobData;
          const jobId = jobIdWidget.value;
          if (!jobId) {
            jobIdWidget.value = eventData && eventData.jobId ? eventData.jobId : (res.jobId || res.secondaryJobId);
            event.eventRecord.data.jobId = jobIdWidget.value;
            event.eventRecord.jobId = jobIdWidget.value;
          } else {
            jobIdWidget.value = jobId;
          }
          jobIdWidget.disabled =
            jobData.length === 1 &&
            (!this.isAvailable || !locationIdWidget.value);
          let sections =
            this.scheduleConfigs && this.scheduleConfigs.sections
              ? this.scheduleConfigs.sections[jobIdWidget.value]
              : this.schedulerEngine.scheduleConfigs &&
                this.schedulerEngine.scheduleConfigs.sections
                ? this.schedulerEngine.scheduleConfigs.sections[jobIdWidget.value]
                : null;
          if (this.scheduleConfigs.sections['all']) {
            if (!sections) {
              sections = [];
            }
            sections = [...sections, ...this.scheduleConfigs.sections['all']]
          }
          if (eventData && sectionIdWidget && sections) {
            sectionIdWidget.store.removeAll();
            sectionIdWidget.store.data = sections;
            // let sectionId = sectionIdWidget.value;
            // if (!sectionId) {
            //   try {
            //     eventData.sectionId = JSON.parse(eventData.sectionId);
            //   } catch (e) {
                
            //   }
            //   if (!Array.isArray(eventData.sectionId)) {
            //     eventData.sectionId = [eventData.sectionId];
            //   }
            //   // sectionIdWidget.value = sections.find(s => eventData.sectionId.includes(s.value));
            // } else {
            //   if (!Array.isArray(sectionId)) {
            //     sectionId = [sectionId];
            //   }
            //   // sectionIdWidget.value = sections.find(s => sectionId.includes(s.value));;
            // }
            // event.eventRecord.data.sectionId = [25, 1];
            // event.eventRecord.sectionId = [25, 1];
          }
        } else if (jobIdWidget && !(res.jobId || res.secondaryJobId)) {
          const jobData = JSON.parse(JSON.stringify(this.jobsData));
          jobIdWidget.store.removeAll();
          jobIdWidget.store.data = jobData;
          jobIdWidget.disabled =
            jobData.length === 1 &&
            (!this.isAvailable || !locationIdWidget.value);
        }

        return !res.terminatedStatus;
      case "beforeeventeditshow":
        const { data } = event.eventRecord;
        this.zonesSelected = [];
        const resourceData = event.resourceRecord.data;
        if (resourceData && resourceData.locationId) {
          const locId = event.eventEdit.locationId.value;
          const loggedInUserLocation = this.loggedInUser && this.loggedInUser.locationId ? event.eventEdit.locationId.store.data.find(l => l.value === this.loggedInUser.locationId) : null;
          if (!locId) {
            event.eventEdit.locationId.value = loggedInUserLocation ? loggedInUserLocation.value : resourceData.locationId;
          } else {
            event.eventEdit.locationId.value = locId;
          }
          event.eventEdit.locationId.disabled =
            event.eventEdit.locationId.store.data.length === 1;
        }
        if (
          resourceData &&
          (resourceData.jobId || resourceData.secondaryJobId)
        ) {
          const jobId = event.eventEdit.jobId.value;
          if (!jobId)
            event.eventEdit.jobId.value =
              resourceData.jobId || resourceData.secondaryJobId;
          else {
            event.eventEdit.jobId.value = jobId;
          }
          event.eventEdit.jobId.disabled =
            event.eventEdit.jobId.store.data.length === 1;
        }
        if (
          event.eventEdit.jobId &&
          event.eventEdit.jobId.value &&
          this.props &&
          this.props.scheduleConfigs &&
          this.props.scheduleConfigs.sections &&
          (
            this.props.scheduleConfigs.sections[event.eventEdit.jobId.value] ||
            this.props.scheduleConfigs.sections['all']
          )
        ) {
          event.eventEdit.sectionId.hidden = false;
          event.eventEdit.sectionId.disabled = false;
          event.eventEdit.sectionId.triggerPaint();
          // let sectionId = data.sectionId;
          // if (sectionId) {
            
          //     try {
          //       sectionId = JSON.parse(sectionId);
          //     } catch (e) {

          //     }
          //     if (!Array.isArray(sectionId)) {
          //       sectionId = [sectionId];
          //     }
          //   event.eventEdit.sectionId.value = [25,1];
          //   console.log(sectionId, data);
          //     // sectionIdWidget.value = sections.find(s => eventData.sectionId.includes(s.value));
          // } else {
          //   event.eventEdit.sectionId.value = [];
          // }
          this.zonesSelected = (data.sectionData || []).map(z => {
            return {
              color: z.zoneColor,
              endTime: z.endTime,
              id: z.zoneId,
              startTime: z.startTime,
              text: z.zone,
              value: z.zoneId,
            }
          });
          if (this.zonesSelected.length && data && moment(data.startDate).isValid() && moment(data.endDate).isValid()) {
            generateZones(event.eventEdit.sectionId, this.zonesSelected, data.startDate, data.endDate);
          }
        }
        if (
          event.eventEdit.name === "Regular" ||
          event.eventRecord.name === "Regular"
        ) {
          event.eventEdit.break1.hidden = !data.break1;
          event.eventEdit.break2.hidden = !data.break2;
          event.eventEdit.break3.hidden = !data.break3;
          event.eventEdit.break4.hidden = !data.break4;
          event.eventEdit.breakButton.hidden =
            !(
              moment(data.startDate).isValid() && moment(data.endDate).isValid()
            ) ||
            (!!data.break1 && !!data.break2 && !!data.break3 && !!data.break4);
          this.setBreakAdded({
            break1: !!data.break1,
            break2: !!data.break2,
            break3: !!data.break3,
            break4: !!data.break4,
          });
        } else {
          breakController.removeAllBreaks(event);
        }
        if (this.isAvailable) {
          event.eventEdit.name.readOnly = true;
          event.eventEdit.name.store.data = event.eventEdit.name.store.data.filter(
            (ev) => ev.text === "Regular"
          );
          if (!this.props.isManager) {
            event = disableOrEnableEventEdit(event, data, this.props.isManager);
          }
        } else if (
          !this.props.isMySchedule ||
          this.props.isMySchedule === false
        ) {
          event.eventEdit.name.store.data = event.eventEdit.name.store.data.filter(
            (ev) => !["Unavailability", "Scheduled Day Off"].includes(ev.text)
          );
        } else if (
          this.props.isManager === false &&
          this.props.isMySchedule === true
        ) {
          event.eventEdit.name.store.data = event.eventEdit.name.store.data.filter(
            (ev) => ["Unavailability"].includes(ev.text)
          );
        }
        if (!this.isAvailable) {
          event = disableOrEnableEventEdit(event, data, this.props.isManager);
        }
        setTimeout(() => {
          event.eventEdit.sectionId.triggerPaint();
          event.eventEdit.jobId.triggerPaint();
          event.eventEdit.locationId.triggerPaint();
        }, 100);
        /*if(this.isUnavailability || data.isUnavailable) {
                event.eventEdit.name.store.data = event.eventEdit.name.store.data.filter(ev => ev.text === 'Unavailability');
                event.eventEdit.name.readOnly = true;

            } else {
                event.eventEdit.name.store.data = event.eventEdit.name.store.data.filter(ev => ev.text !== 'Unavailability')
            }*/
        break;
      case "eventselectionchange":
        if (event.selection.length > 0) {
          this.selectedEvents = event.selection.filter(e => e.resource && e.resource.data && !e.resource.data.terminatedStatus).map((e) => e.data);
        } else {
          this.selectedEvents = [];
        }
        if (this.props.onSelectEventsChange)
          return this.props.onSelectEventsChange(this.selectedEvents);
        return;
      case "cellcontextmenubeforeshow":
        this.props.handleResourceContextMenu(event);
        return;
      case "beforeeventresize":
      case "beforeeventdrag":
      case "eventcontextmenubeforeshow":
        return !event.eventRecord.resource.data.terminatedStatus;
      // case 'eventdragstart':
      //   if(event.context && event.context.browserEvent && (event.context.browserEvent.metaKey || event.context.browserEvent.ctrlKey)){
      //     event.eventRecords[0] = event.eventRecords[0].copy();;
      //   }
      //   return ;
      default:
        // console.log(event.type);
        return;
    }
  };
  selectedEvents = [];
  breakAdded = {
    break1: false,
    break2: false,
    break3: false,
    break4: false,
  };
  zonesSelected = [];
  startDate = null;
  endDate = null;
  featureEventEdit = {
    showNameField: false,
    autoClose: false,
    // autoClose: false,
    showResourceField: false,
    startDateConfig: {
      hidden: true,
      readonly: true,
      label: "Start Date",
      type: "date",
      clearable: false,
      required: true,
      editable: false,
      name: "startDate",
      ref: "startDateField",
      cls: "b-match-label",
      flex: "1 0 49%",
    },
    endDateConfig: {
      hidden: true,
      label: "End Date",
      type: "date",
      clearable: false,
      required: true,
      editable: false,
      name: "endDate",
      ref: "endDateField",
      cls: "b-match-label",
      flex: "1 0 49%",
    },
    startTimeConfig: {
      label: "Start Time",
      type: "time",
      clearable: false,
      required: true,
      editable: false,
      name: "startDate",
      ref: "startTimeField",
      cls: "b-match-label",
      flex: "1 0 49%",
      max: moment(this.currentDate).endOf("day").add(-1, "hours").toDate(),
      min: moment(this.currentDate).startOf("day").toDate(),
      triggers: {
        expand: {
          template: () =>
            `<div class="b-align-${
            this.align || "end"
            }"><div class="b-icon-clock-live"></div></div>`,
          handler: "onTriggerClick",
          align: "end",
        },
      },
      listeners: {
        change: ({ value, source }) => {
          const endTimeField = source.parent.widgetMap.endTimeField;
          const currentTimeField = source.parent.widgetMap.currentDate;
          if (value && endTimeField.value) {
            if (
              moment(endTimeField.value).format("YYYY-MM-DD") !==
              moment(value).format("YYYY-MM-DD")
            ) {

              const eventDate = moment(currentTimeField.value).startOf('day');
              let endTime = moment(eventDate).clone().hours(endTimeField.value.getHours()).minutes(endTimeField.value.getMinutes()).toDate();
              if (moment(endTime).isAfter(moment(eventDate).endOf('day'))) {
                endTime = moment(eventDate).clone().endOf('day').toDate();
              }
              let startTime = moment(eventDate).clone().hours(value.getHours()).minutes(value.getMinutes()).toDate();
              if (moment(startTime).isBefore(moment(eventDate).startOf('day'))) {
                startTime = moment(eventDate).clone().startOf('day').toDate();
              }
              source.value = endTime;
              startTimeField.value = startTime;
              // source.value = new Date(
              //   currentTimeField.value.getFullYear(),
              //   currentTimeField.value.getMonth(),
              //   currentTimeField.value.getDate(),
              //   value.getHours(),
              //   value.getMinutes()
              // );
              // endTimeField.value = new Date(
              //   currentTimeField.value.getFullYear(),
              //   currentTimeField.value.getMonth(),
              //   currentTimeField.value.getDate(),
              //   endTimeField.value.getHours(),
              //   endTimeField.value.getMinutes()
              // );
            }
            if (moment(value) >= moment(endTimeField.value)) {
              source.setError(
                `Warning: End date should be greater then end date`
              );
            } else {
              source.clearError(
                `Warning: End date should be greater then end date`
              );
              endTimeField.clearError(
                `Warning: End date should be greater then end date`
              );
            }
            const eventEdit = source.owner.eventEditFeature;
            const record = eventEdit.sectionId ? eventEdit.sectionId.records : [];
            if ((this.zonesSelected && this.zonesSelected.length > 0) || record.length > 0) {
              const newEligibleStart = getEligibleStart(moment(source.value), -5);
              const newEndTime = getEligibleEnd(moment(endTimeField.value), -5);
              const recordIds = record ? record.map(r => r.value) : [];
              this.zonesSelected = [...(this.zonesSelected || []), ...(record ? record.filter(r => this.zonesSelected.filter(z => z.value === r.value).length === 0).map(r => {
                r.data.startTime = moment(newEligibleStart).format('HH:mm');
                r.data.endTime = moment(newEndTime).format('HH:mm');
                return r.data;
              }) : [])].filter(r => recordIds.includes(r.value));
              this.zonesSelected = onDateTimeChange(newEligibleStart, newEndTime, this.zonesSelected);
              generateZones(eventEdit.sectionId, this.zonesSelected, newEligibleStart, newEndTime);
            }
          }
        },
        togglePicker: ({ show, source }) => {
          if (show) {
            const {
              picker: {
                widgetMap: { fieldHours, fieldMinutes },
              },
            } = source;
            fieldHours.selectAll();
            fieldHours.on({
              focusin: () => {
                fieldHours.selectAll();
              },
            });
            fieldMinutes.on({
              focusin: () => {
                fieldMinutes.selectAll();
              },
            });
          }
        },
      },
      dataset: { name: "Scheduled Day Off", disableOnDayOff: true },
    },
    endTimeConfig: {
      label: "End Time",
      type: "time",
      clearable: false,
      required: true,
      editable: false,
      name: "endDate",
      ref: "endTimeField",
      cls: "b-match-label",
      flex: "1 0 49%",
      style: { marginLeft: "2%" },
      max: moment(this.currentDate).endOf("day").toDate(),
      min: moment(this.currentDate).startOf("day").add(1, "hours").toDate(),
      triggers: {
        expand: {
          template: () =>
            `<div class="b-align-${
            this.align || "end"
            }"><div class="b-icon-clock-live"></div></div>`,
          handler: "onTriggerClick",
          align: "end",
        },
      },
      listeners: {
        change: ({ value, source }) => {
          const startTimeField = source.parent.widgetMap.startTimeField;
          const currentTimeField = source.parent.widgetMap.currentDate;
          if (value && startTimeField.value) {
            if (
              moment(startTimeField.value).format("YYYY-MM-DD") !==
              moment(value).format("YYYY-MM-DD")
            ) {
              const eventDate = moment(currentTimeField.value).startOf('day');
              let endTime = moment(eventDate).clone().hours(value.getHours()).minutes(value.getMinutes()).toDate();
              if (moment(endTime).isAfter(moment(eventDate).endOf('day'))) {
                endTime = moment(eventDate).clone().endOf('day').toDate();
              }
              let startTime = moment(eventDate).clone().hours(startTimeField.value.getHours()).minutes(startTimeField.value.getMinutes()).toDate();
              if (moment(startTime).isBefore(moment(eventDate).startOf('day'))) {
                startTime = moment(eventDate).clone().startOf('day').toDate();
              }
              source.value = endTime;
              startTimeField.value = startTime;
            }
            if (moment(startTimeField.value) >= moment(value)) {
              source.setError(
                `Warning: End date should be greater then end date`
              );
            } else {
              source.clearError(
                `Warning: End date should be greater then end date`
              );
              startTimeField.clearError(
                `Warning: End date should be greater then end date`
              );
            }
            if (source.owner && source.owner.eventEditFeature) {
              const eventEdit = source.owner.eventEditFeature;
              const resource = eventEdit.resourceRecord;
              if (eventEdit.eventRecord.data.name === "Regular") {
                if (
                  breakController.breakRules[resource.locationState] &&
                  resource.salaryHourly === "H" && moment(eventEdit.eventRecord.data.endDate).format('HHmm') !== moment(value).format('HHmm')
                ) {
                  let sd = moment(startTimeField.value);
                  const ed = moment(value);
                  const record = breakController.generateBreaksByRules(
                    eventEdit.eventRecord,
                    sd,
                    ed,
                    breakController.breakRules[resource.locationState]
                  );
                  const {
                    break1,
                    break2,
                    break3,
                    break4,
                  } = source.parent.widgetMap;
                  break1.items[0].value = null;
                  break2.items[0].value = null;
                  break3.items[0].value = null;
                  break4.items[0].value = null;
                  break1.hidden = true;
                  break2.hidden = true;
                  break3.hidden = true;
                  break4.hidden = true;
                  if (record.break1) {
                    break1.hidden = false;
                    break1.items[0].value = record.break1;
                    break1.items[1].value = record.break1StartTime;
                    break1.items[2].value = record.break1EndTime;
                  }
                  if (record.break2) {
                    break2.hidden = false;
                    break2.items[0].value = record.break2;
                    break2.items[1].value = record.break2StartTime;
                    break2.items[2].value = record.break2EndTime;
                  }
                  if (record.break3) {
                    break3.hidden = false;
                    break3.items[0].value = record.break3;
                    break3.items[1].value = record.break3StartTime;
                    break3.items[2].value = record.break3EndTime;
                  }
                  if (record.break4) {
                    break4.hidden = false;
                    break4.items[0].value = record.break4;
                    break4.items[1].value = record.break4StartTime;
                    break4.items[2].value = record.break4EndTime;
                  }
                }
              }
              const record = eventEdit.sectionId ? eventEdit.sectionId.records : [];
              if ((this.zonesSelected && this.zonesSelected.length > 0) || record.length > 0) {
                const newEligibleStart = getEligibleStart(moment(startTimeField.value), -5);
                const newEndTime = getEligibleEnd(moment(source.value), -5);
                const recordIds = record ? record.map(r => r.value) : [];
                this.zonesSelected = [...(this.zonesSelected || []), ...(record ? record.filter(r => this.zonesSelected.filter(z => z.value === r.value).length === 0).map(r => {
                  r.data.startTime = moment(newEligibleStart).format('HH:mm');
                  r.data.endTime = moment(newEndTime).format('HH:mm');
                  return r.data;
                }) : [])].filter(r => recordIds.includes(r.value));
                this.zonesSelected = onDateTimeChange(newEligibleStart, newEndTime, this.zonesSelected);
                generateZones(eventEdit.sectionId, this.zonesSelected, newEligibleStart, newEndTime);
              }
            }
          }
        },
        togglePicker: ({ show, source }) => {
          if (show) {
            const {
              picker: {
                widgetMap: { fieldHours, fieldMinutes },
              },
            } = source;
            fieldHours.selectAll();
            fieldHours.on({
              focusin: () => {
                fieldHours.selectAll();
              },
            });
            fieldMinutes.on({
              focusin: () => {
                fieldMinutes.selectAll();
              },
            });
          }
        },
      },
      dataset: { name: "Scheduled Day Off", disableOnDayOff: true },
    },
    extraWidgets: [
      {
        index: 0,
        type: "datefield",
        label: "Date",
        name: "currentDate",
        ref: "currentDate",
        editable: false,
        readOnly: false,
        format: "MM/DD/YYYY",
        disabled: true,
        style: "color: inherit;",
      },
      {
        index: 1,
        type: "combo",
        label: "Event Type",
        name: "name",
        ref: "name",
        editable: false,
        readOnly: this.isUnavailability,
        flex: "1 0 100%",
        items: SCHEDULE_EVENTS,
        listeners: {
          select: ({ source: combo, record }) => {
            if (record && record.value) {
              combo.owner.widgets.forEach((widget) => {
                if (widget.dataset && widget.dataset.name) {
                  if (widget.dataset.breakContainer) {
                    widget.hidden =
                      widget.dataset.name !== record.value ||
                      !this.breakAdded[widget.ref];
                  } else if (widget.dataset.disableOnDayOff) {
                    if (widget.dataset.name === record.value) {
                      if (widget.name === "endDate") {
                        this.endDate = widget.value;
                        widget.value = moment(
                          `${moment(widget.value).format(
                            "YYYY-MM-DD"
                          )} 23:59:00`
                        ).toDate();
                      } else {
                        this.startDate = widget.value;
                        widget.value = moment(
                          `${moment(widget.value).format(
                            "YYYY-MM-DD"
                          )} 00:00:00`
                        ).toDate();
                      }
                      widget.disabled = true;
                    } else {
                      if (widget.name === "endDate" && this.endDate) {
                        widget.value = this.endDate;
                        this.endDate = null;
                      } else if (this.startDate) {
                        widget.value = this.startDate;
                        this.startDate = null;
                      }
                      widget.disabled = false;
                    }
                  } else {
                    widget.hidden = widget.dataset.name !== record.value;
                  }
                } else if (widget.dataset && widget.dataset.eventName) {
                  widget.hidden = !widget.dataset.eventName.includes(record.value);
                }
              });
            }
          },
        },
      },
      {
        index: 2,
        type: "textarea",
        placeholder: "Tell us more...",
        label: "Notes",
        name: "notes",
        dataset: { eventName: ["Offsite", 'Other'] },
      },
      {
        index: 3,
        label: "Location",
        type: "combo",
        editable: false,
        clearable: true,
        flex: "1 0 100%",
        valueField: "value",
        name: "locationId",
        ref: "locationId",
        validateFilter: true,
        store: locations,
        listeners: {
          select: ({ source: combo, record }) => {
            const jobId = combo.owner.widgetMap.jobId;

            if (record) {
              jobId.store.filterBy((rec) =>
                rec.locations.includes(record.value)
              );
              jobId.disabled = false;

              // if the selected record has been filtered out need to reset value
              if (jobId.record && !jobId.store.getById(jobId.record.value)) {
                jobId.value = null;
              }
            } else {
              jobId.value = null;
              jobId.disabled = true;
            }
          },
        },
      },
      {
        index: 4,
        label: "Job",
        type: "combo",
        editable: false,
        clearable: true,
        disabled: true,
        flex: "1 0 100%",
        valueField: "value",
        name: "jobId",
        ref: "jobId",
        store: jobs,
        validateFilter: true,
        listeners: {
          select: ({ source: combo, record }) => {
            if (record && record.value) {
              combo.owner.widgets.forEach((widget) => {
                if (
                  widget.dataset &&
                  widget.dataset.name &&
                  widget.dataset.name === "SECTION"
                ) {
                  if (
                    this.props &&
                    this.props.scheduleConfigs &&
                    this.props.scheduleConfigs.sections
                  ) {
                    const sections = this.props.scheduleConfigs.sections;
                    if (sections[record.value] || sections['all']) {
                      widget.hidden = false;
                      widget.store.removeAll();
                      widget.store.data = [...(sections[record.value] || []), ...(sections['all'] || [])];
                    } else {
                      widget.hidden = true;
                      widget.store.removeAll();
                    }
                  }
                }
              });
            }
          },
        },
      },
      {
        label: "Zone",
        type: "combo",
        editable: true,
        clearable: true,
        multiSelect: true,
        flex: "1 0 100%",
        valueField: "value",
        name: "sectionId",
        ref: "sectionId",
        store: sectionStore,
        validateFilter: true,
        hidden: true,
        dataset: { name: "SECTION" },
        listeners: {
          select: ({ source: combo, record }) => {
            const startTimeField = combo.owner.widgetMap.startTimeField;
            const endTimeField = combo.owner.widgetMap.endTimeField;
            const recordIds = record ? record.map(r => r.value) : [];
            this.zonesSelected = [...this.zonesSelected, ...(record ? record.filter(r => this.zonesSelected.filter(z => z.value === r.value).length === 0).map(r => {
              r.data.startTime = moment(startTimeField.value).format('HH:mm');
              r.data.endTime = moment(endTimeField.value).format('HH:mm');
              return r.data;
            }) : [])].filter(r => recordIds.includes(r.value));
            generateZones(combo, this.zonesSelected, moment(startTimeField.value), moment(endTimeField.value));
          }
        }
      },
      {
        type: 'container',
        ref: 'zoneContainer',
        flex: "1, 0, 100%",
        widgets: [],
        hidden: this.zonesSelected.length === 0,
      },
      {
        type: "button",
        icon: "fas fa-plus-circle",
        text: " Add Break",
        color: "b-green",
        ref: "breakButton",
        editable: false,
        dataset: { breakButton: true, name: "Regular" },
        onClick: ({ source: button }) => {
          const enabledBreaks = Object.keys(this.breakAdded).filter(
            (k) => this.breakAdded[k] === true
          );
          const hiddenBreaks = Object.keys(this.breakAdded).filter(
            (k) => this.breakAdded[k] === false
          );
          const currentBreak = Object.values(this.breakAdded).filter(
            (b) => b === true
          ).length;
          let breakContainer = null;
          let allDataAreOK = true;
          if (enabledBreaks.length > 0) {
            breakContainer = button.owner.widgetMap[`break${currentBreak}`];
          }
          for (let i = 0; i < enabledBreaks.length; i++) {
            breakContainer = button.owner.widgetMap[enabledBreaks[i]];
            if (
              breakContainer &&
              breakContainer.widgets[0] &&
              (!breakContainer.widgets[0].value ||
                !breakContainer.widgets[1].value ||
                !breakContainer.widgets[2].value)
            ) {
              allDataAreOK = false;
              break;
            }
          }
          if (!allDataAreOK) {
            new ErrorToast(
              `Please add valid info for current enabled break${
              enabledBreaks.length > 1 ? "s" : ""
              }`
            );
          } else {
            const startTimeField = button.owner.widgetMap.startTimeField;
            const endTimeField = button.owner.widgetMap.endTimeField;
            if (
              moment(startTimeField.value).isValid() &&
              moment(endTimeField.value).isValid()
            ) {
              if (
                moment
                  .duration(
                    moment(endTimeField.value).diff(
                      moment(startTimeField.value)
                    )
                  )
                  .asMinutes() >= 60
              ) {
                if (hiddenBreaks.length > 0) {
                  let breakName = hiddenBreaks[0];
                  button.owner.widgets.forEach((widget) => {
                    if (widget.dataset && widget.dataset[breakName]) {
                      widget.hidden = widget.dataset[breakName] !== breakName;
                    }
                  });
                  this.breakAdded[breakName] = true;
                  if (hiddenBreaks.length === 1) {
                    // this is last break
                    button.hidden = true;
                  }
                } else {
                  new ErrorToast("You have reached limit of 4 breaks");
                }
              } else {
                new ErrorToast(
                  "There Is not enough time to add a break between start and end time"
                );
              }
            } else {
              new ErrorToast("Please select a starting and end time for shift");
            }
          }
        },
      },
      {
        ref: "break1",
        hidden: !this.breakAdded.break1,
        type: "container",
        flex: "1 0 100%",
        widgets: [
          {
            type: "combo",
            label: "Break",
            name: "break1",
            ref: "break1",
            valueField: "value",
            clearable: true,
            editable: true,
            flex: "1 0 25%",
            store: breaks,
            listeners: {
              select: ({ source: combo, record }) => {
                const break1StartTime = combo.owner.widgetMap.break1StartTime;
                const break1EndTime = combo.owner.widgetMap.break1EndTime;
                breakController.breakDurationChanged(
                  combo,
                  record,
                  break1StartTime,
                  break1EndTime,
                  1
                );
              },
            },
          },
          {
            label: "Start Time",
            type: "combo",
            clearable: true,
            editable: true,
            disabled: true,
            valueField: "value",
            name: "break1StartTime",
            ref: "break1StartTime",
            flex: "1 0 28%",
            style: { marginLeft: "2%" },
            dataset: { break1StartTime: true },
            store: break1StartTime,
            listeners: {
              select: ({ source: combo, record }) => {
                if (record) {
                  const breakCombo = combo.owner.widgetMap.break1;
                  const breakEndTimeField = combo.owner.widgetMap.break1EndTime;
                  breakController.breakStartTimeChanged(
                    combo,
                    record,
                    breakCombo,
                    breakEndTimeField
                  );
                }
              },
            },
          },
          {
            label: "End Time",
            type: "combo",
            clearable: true,
            editable: true,
            valueField: "value",
            disabled: true,
            name: "break1EndTime",
            ref: "break1EndTime",
            flex: "1 0 28%",
            dataset: { break1EndTime: true },
            style: { marginLeft: "2%" },
            store: break1EndTime,
            listeners: {
              select: ({ source: combo, record }) => {
                if (record) {
                  const breakCombo = combo.owner.widgetMap.break1;
                  const breakStartTimeField =
                    combo.owner.widgetMap.break1StartTime;
                  breakController.breakEndTimeChanged(
                    combo,
                    record,
                    breakCombo,
                    breakStartTimeField
                  );
                }
              },
            },
          },
          {
            type: "button",
            icon: "b-icon b-icon-trash",
            color: "b-red",
            flex: "1 0 10%",
            style: { marginLeft: "2%" },
            onClick: ({ source: button }) => {
              this.breakAdded["break1"] = breakController.deleteBreak(
                button,
                "break1"
              );
            },
          },
        ],
        dataset: { break1: "break1", name: "Regular", breakContainer: true },
      },
      {
        ref: "break2",
        hidden: !this.breakAdded.break2,
        type: "container",
        flex: "1 0 100%",
        widgets: [
          {
            type: "combo",
            label: "Break",
            name: "break2",
            ref: "break2",
            valueField: "value",
            clearable: true,
            editable: true,
            flex: "1 0 25%",
            store: breaks,
            listeners: {
              select: ({ source: combo, record }) => {
                const breakStartTimeField =
                  combo.owner.widgetMap.break2StartTime;
                const breakEndTimeField = combo.owner.widgetMap.break2EndTime;
                breakController.breakDurationChanged(
                  combo,
                  record,
                  breakStartTimeField,
                  breakEndTimeField,
                  2
                );
              },
            },
          },
          {
            label: "Start Time",
            type: "combo",
            clearable: true,
            editable: true,
            disabled: true,
            valueField: "value",
            name: "break2StartTime",
            ref: "break2StartTime",
            flex: "1 0 28%",
            style: { marginLeft: "2%" },
            dataset: { break2StartTime: true },
            store: break2StartTime,
            listeners: {
              select: ({ source: combo, record }) => {
                if (record) {
                  const breakCombo = combo.owner.widgetMap.break2;
                  const breakEndTimeField = combo.owner.widgetMap.break2EndTime;
                  breakController.breakStartTimeChanged(
                    combo,
                    record,
                    breakCombo,
                    breakEndTimeField
                  );
                }
              },
            },
          },
          {
            label: "End Time",
            type: "combo",
            clearable: true,
            editable: true,
            valueField: "value",
            disabled: true,
            name: "break2EndTime",
            ref: "break2EndTime",
            flex: "1 0 28%",
            dataset: { break2EndTime: true },
            style: { marginLeft: "2%" },
            store: break2EndTime,
            listeners: {
              select: ({ source: combo, record }) => {
                if (record) {
                  const breakCombo = combo.owner.widgetMap.break2;
                  const breakStartTimeField =
                    combo.owner.widgetMap.break2StartTime;
                  breakController.breakEndTimeChanged(
                    combo,
                    record,
                    breakCombo,
                    breakStartTimeField
                  );
                }
              },
            },
          },
          {
            type: "button",
            icon: "b-icon b-icon-trash",
            color: "b-red",
            flex: "1 0 10%",
            style: { marginLeft: "2%" },
            onClick: ({ source: button }) => {
              this.breakAdded["break2"] = breakController.deleteBreak(
                button,
                "break2"
              );
            },
          },
        ],
        dataset: { break2: "break2", name: "Regular", breakContainer: true },
      },
      {
        ref: "break3",
        hidden: !this.breakAdded.break3,
        type: "container",
        flex: "1 0 100%",
        widgets: [
          {
            type: "combo",
            label: "Break",
            name: "break3",
            ref: "break3",
            valueField: "value",
            clearable: true,
            editable: true,
            flex: "1 0 25%",
            store: breaks,
            listeners: {
              select: ({ source: combo, record }) => {
                const breakStartTimeField =
                  combo.owner.widgetMap.break3StartTime;
                const breakEndTimeField = combo.owner.widgetMap.break3EndTime;

                breakController.breakDurationChanged(
                  combo,
                  record,
                  breakStartTimeField,
                  breakEndTimeField,
                  3
                );
              },
            },
          },
          {
            label: "Start Time",
            type: "combo",
            clearable: true,
            editable: true,
            disabled: true,
            valueField: "value",
            name: "break3StartTime",
            ref: "break3StartTime",
            flex: "1 0 28%",
            style: { marginLeft: "2%" },
            dataset: { break3StartTime: true },
            store: break3StartTime,
            listeners: {
              select: ({ source: combo, record }) => {
                if (record) {
                  const breakCombo = combo.owner.widgetMap.break3;
                  const breakEndTimeField = combo.owner.widgetMap.break3EndTime;
                  breakController.breakStartTimeChanged(
                    combo,
                    record,
                    breakCombo,
                    breakEndTimeField
                  );
                }
              },
            },
          },
          {
            label: "End Time",
            type: "combo",
            clearable: true,
            editable: true,
            valueField: "value",
            disabled: true,
            name: "break3EndTime",
            ref: "break3EndTime",
            flex: "1 0 28%",
            dataset: { break3EndTime: true },
            style: { marginLeft: "2%" },
            store: break3EndTime,
            listeners: {
              select: ({ source: combo, record }) => {
                if (record) {
                  const breakCombo = combo.owner.widgetMap.break3;
                  const breakStartTimeField =
                    combo.owner.widgetMap.break3StartTime;
                  breakController.breakEndTimeChanged(
                    combo,
                    record,
                    breakCombo,
                    breakStartTimeField
                  );
                }
              },
            },
          },
          {
            type: "button",
            icon: "b-icon b-icon-trash",
            color: "b-red",
            flex: "1 0 10%",
            style: { marginLeft: "2%" },
            onClick: ({ source: button }) => {
              this.breakAdded["break3"] = breakController.deleteBreak(
                button,
                "break3"
              );
            },
          },
        ],
        dataset: { break3: "break3", name: "Regular", breakContainer: true },
      },
      {
        ref: "break4",
        hidden: !this.breakAdded.break4,
        type: "container",
        flex: "1 0 100%",
        widgets: [
          {
            type: "combo",
            label: "Break",
            name: "break4",
            ref: "break4",
            valueField: "value",
            clearable: true,
            editable: true,
            flex: "1 0 25%",
            store: breaks,
            listeners: {
              select: ({ source: combo, record }) => {
                const breakStartTimeField =
                  combo.owner.widgetMap.break4StartTime;
                const breakEndTimeField = combo.owner.widgetMap.break4EndTime;
                breakController.breakDurationChanged(
                  combo,
                  record,
                  breakStartTimeField,
                  breakEndTimeField,
                  4
                );
              },
            },
          },
          {
            label: "Start Time",
            type: "combo",
            clearable: true,
            editable: true,
            disabled: true,
            valueField: "value",
            name: "break4StartTime",
            ref: "break4StartTime",
            flex: "1 0 28%",
            style: { marginLeft: "2%" },
            dataset: { break4StartTime: true },
            store: break4StartTime,
            listeners: {
              select: ({ source: combo, record }) => {
                if (record) {
                  const breakCombo = combo.owner.widgetMap.break4;
                  const breakEndTimeField = combo.owner.widgetMap.break4EndTime;
                  breakController.breakStartTimeChanged(
                    combo,
                    record,
                    breakCombo,
                    breakEndTimeField
                  );
                }
              },
            },
          },
          {
            label: "End Time",
            type: "combo",
            clearable: true,
            editable: true,
            valueField: "value",
            disabled: true,
            name: "break4EndTime",
            ref: "break4EndTime",
            flex: "1 0 28%",
            dataset: { break4EndTime: true },
            style: { marginLeft: "2%" },
            store: break4EndTime,
            listeners: {
              select: ({ source: combo, record }) => {
                if (record) {
                  const breakCombo = combo.owner.widgetMap.break4;
                  const breakStartTimeField =
                    combo.owner.widgetMap.break4StartTime;
                  breakController.breakEndTimeChanged(
                    combo,
                    record,
                    breakCombo,
                    breakStartTimeField
                  );
                }
              },
            },
          },
          {
            type: "button",
            icon: "b-icon b-icon-trash",
            color: "b-red",
            flex: "1 0 10%",
            style: { marginLeft: "2%" },
            onClick: ({ source: button }) => {
              this.breakAdded["break4"] = breakController.deleteBreak(
                button,
                "break4"
              );
            },
          },
        ],
        dataset: { break4: "break4", name: "Regular", breakContainer: true },
      },
    ],
    onSaveClick() {
      this.save();
    },
    async save() {
      const me = this,
        { scheduler, eventRecord } = me;

      if (!eventRecord) {
        this.editor.hide();
        return;
      }

      const eventStore = me.eventStore,
        values = me.values,
        resourceRecords = (me.resourceField && me.resourceField.records) || [
          me.resourceRecord,
        ];
      values.sectionData = [];
      if (me.zoneContainer) {
        me.zoneContainer.widgets.forEach(w => {
          const zoneData = {};
          w.widgets.forEach(w1 => {
            if (['startTime', 'endTime'].includes(w1.ref)) {
              zoneData[w1.ref] = moment(w1.value).format('HH:mm');
              zoneData[`${w1.ref}Display`] = moment(w1.value).format('hh:mm A');
            } else {
              zoneData[w1.ref] = w1.value;
            }
          });
          values.sectionData.push(zoneData);
        })
      }
      eventRecord.data.sectionData = values.sectionData;
      if (
        me.break1 &&
        !me.break1._hidden &&
        (!values.break1 || !values.break1StartTime || !values.break1EndTime)
      ) {
        new ErrorToast(`Error: Please complete  data for first break`);
        return;
      }
      if (
        me.break2 &&
        !me.break2._hidden &&
        (!values.break2 || !values.break2StartTime || !values.break2EndTime)
      ) {
        new ErrorToast(`Error: Please complete  data for second break`);
        return;
      }
      if (
        me.break3 &&
        !me.break3._hidden &&
        (!values.break3 || !values.break3StartTime || !values.break3EndTime)
      ) {
        new ErrorToast(`Error: Please complete  data for third break`);
        return;
      }
      if (
        me.break4 &&
        !me.break4._hidden &&
        (!values.break4 || !values.break4StartTime || !values.break4EndTime)
      ) {
        new ErrorToast(`Error: Please complete  data for fourth break`);
        return;
      }

      if (
        moment(values.startDate).format("YYYY-MM-DD") !==
        moment(values.endDate).format("YYYY-MM-DD") ||
        moment(values.startDate).format("YYYY-MM-DD") !==
        moment(values.currentDate).format("YYYY-MM-DD")
      ) {
        values.startDate = new Date(
          values.currentDate.getFullYear(),
          values.currentDate.getMonth(),
          values.currentDate.getDate(),
          values.startDate.getHours(),
          values.startDate.getMinutes()
        );
        values.endDate = new Date(
          values.currentDate.getFullYear(),
          values.currentDate.getMonth(),
          values.currentDate.getDate(),
          values.endDate.getHours(),
          values.endDate.getMinutes()
        );
      }
      if (!values.locationId) {
        new ErrorToast(`Error: Please select a location for this shift`);
        return;
      }
      if (!values.jobId) {
        new ErrorToast(`Error: Please select a job for this shift`);
        me.jobId.validateFilter = true;
        return;
      }
      if (!me.isValid) {
        return;
      }
      if (moment(values.startDate) >= moment(values.endDate)) {
        new ErrorToast(`Warning: End date should be greater then end date`);
        return;
      }
      const resource = resourceRecords[0];
      if (
        resource.data &&
        breakController.breakRules[resource.locationState] &&
        resource.data.salaryHourly === "H"
      ) {
        const rule = breakController.breakRules[resource.locationState];
        const brakedRule = breakController.validBreaksByRules(values, rule);
        if (brakedRule !== "") {
          const allow = await AsyncAlert.alert(
            "Save schedule",
            `This employee was scheduled more than ${brakedRule} hrs without any break. Do you want to continue?`
          );
          if (!allow) {
            this.editor.hide();
            return;
          }
        }
      }

      // Check for potential overlap scenarios before saving. TODO needs to be indicated in the UI
      if (!me.scheduler.allowOverlap && eventStore) {
        const abort = resourceRecords.some((resource) => {
          return !eventStore.isDateRangeAvailable(
            values.startDate,
            values.endDate,
            eventRecord,
            resource
          );
        });
        if (abort) {
          new ErrorToast(
            `Warning: You can't add two shifts for same person in same time`
          );
          return;
        }
      }

      /**
       * Fires on the owning Scheduler before an event is saved
       * @event beforeeventsave
       * @param {Scheduler.view.Scheduler} source The scheduler instance
       * @param {Scheduler.model.EventModel} eventRecord The record about to be saved
       * @param {Scheduler.model.ResourceModel} resourceRecord [DEPRECATED IN FAVOR OF `resourceRecords`] The resource to which the event is assigned
       * @param {Scheduler.model.ResourceModel[]} resourceRecords The resources to which the event is assigned
       * @param {Object} values The new values
       * @preventable
       */
      if (
        scheduler.trigger("beforeEventSave", {
          eventRecord,
          resourceRecords,
          resourceRecord: resourceRecords[0],
          values,
        }) !== false
      ) {
        me.onBeforeSave(eventRecord);

        eventRecord.beginBatch();
        me.updateRecord(eventRecord);
        eventRecord.endBatch();

        // Check if this is a new record
        if (eventStore && !eventRecord.stores.length) {
          /**
           * Fires on the owning Scheduler before an event is added
           * @event beforeEventAdd
           * @param {Scheduler.feature.EventEdit} widget The editor instance
           * @param {Scheduler.model.EventModel} eventRecord The record about to be added
           * @param {Scheduler.model.ResourceModel[]} resources Resources that the record is assigned to
           * @preventable
           */
          if (
            scheduler.trigger("beforeEventAdd", {
              eventRecord,
              resources: resourceRecords,
            }) !== false
          ) {
            // Hand over the proxy element to be used by the new event
            if (me.dragProxyElement) {
              const eventTpl = scheduler.generateTplData(
                eventRecord,
                resourceRecords[0]
              );

              // Adopt the proxy as an event element.
              if (eventTpl) {
                DomHelper.syncClassList(
                  me.dragProxyElement,
                  eventTpl.wrapperCls
                );
                DomHelper.createElement({
                  tag: "div",
                  parent: me.dragProxyElement,
                  className: eventTpl.cls.toString(),
                });

                // This is a signal that it's from a drag-create, so needs to stay
                me.dragProxyElement.classList.add("b-sch-dragcreator-proxy");
                me.dragProxyElement.id = eventTpl.id;
                scheduler.currentOrientation.releaseTimeSpanDiv(
                  me.dragProxyElement,
                  true
                );
                me.dragProxyElement = null;

                // Flag to let HorizontalEventMapper know that it should reuse the element even though the
                // event is new
                eventRecord.instanceMeta(scheduler).fromDragProxy = true;
              }
            }

            // Add to eventStore first, then assign the resource. Order is necessary since assigning might
            // involve an AssignmentStore
            me.eventStore.add(eventRecord);
            me.eventStore.assignEventToResource(eventRecord, resourceRecords);

            // If a filter was reapplied and filtered out the newly added event we need to clean up the drag proxy...
            if (!me.eventStore.includes(eventRecord)) {
              // Feels a bit strange having that responsibility here, but since it is already handled
              const proxyElement = scheduler.element.querySelector(
                ".b-sch-dragcreator-proxy"
              );

              if (proxyElement) {
                scheduler.currentOrientation.availableDivs.remove(proxyElement);
                proxyElement.remove();
              }
            }
          } else {
            this.editor.hide();
            return;
          }
        } else if (scheduler.assignmentStore) {
          me.eventStore.assignEventToResource(
            eventRecord,
            resourceRecords,
            true
          );
        }

        /**
         * Fires on the owning Scheduler after an event is successfully saved
         * @event afterEventSave
         * @param {Scheduler.view.Scheduler} source The scheduler instance
         * @param {Scheduler.model.EventModel} eventRecord The record about to be saved
         */
        scheduler.trigger("afterEventSave", { eventRecord });
        me.onAfterSave(eventRecord);
      }

      this.editor.hide();
      this.zonesSelected = [];
      return eventRecord;
    },
  };
  resetEventEdit = (eventEdit) => {
    if (eventEdit.break1) {
      eventEdit.break1.widgets.forEach((widget) => {
        if (widget.type === "combo") {
          widget.value = null;
        }
      });
    }
    if (eventEdit.break2) {
      eventEdit.break2.widgets.forEach((widget) => {
        if (widget.type === "combo") {
          widget.value = null;
        }
      });
    }
    if (eventEdit.break3) {
      eventEdit.break3.widgets.forEach((widget) => {
        if (widget.type === "combo") {
          widget.value = null;
        }
      });
    }
    if (eventEdit.break4) {
      eventEdit.break4.widgets.forEach((widget) => {
        if (widget.type === "combo") {
          widget.value = null;
        }
      });
    }
  };
  setSelectedEvents = (selectedEvents) => {
    this.selectedEvents = selectedEvents;
  };
  setBreakAdded = (breakAdded) => {
    this.breakAdded = breakAdded;
  };

  componentDidMount() {
    this.isUnavailability = !!this.props.isUnavailability;
    this.isAvailable = !!this.props.isAvailable;
    this.jobsData = this.props.jobs;
    this.loggedInUser = this.props.loggedInUser;
    this.locationsData = this.props.locations;
    this.jobsMap = this.props.mapJobs;
    this.locationMap = this.props.mapLocations;
    this.scheduleConfigs = this.props.scheduleConfigs;
    const config = {
      weekStartDay: this.props.weekConf === 'isoWeek' ? 1 : 0,
      appendTo: this.el,
      callOnFunctions: true,
      allowOverlap: this.props.allowOverlap,
      fillTicks: this.props.scheduleMode !== "today",
      zoomOnMouseWheel: false,
      zoomOnTimeAxisDoubleClick: false,
      preventScrollZoom: true,
      features: {
        timeRanges: {
          enableResizing: false,
          showCurrentTimeLine: false,
          showHeaderElements: true,
        },
        excelExporter: {
          // Choose the date format for date fields
          dateFormat: "YYYY-MM-DD HH:mm",
        },
      },
      listeners: {
        catchAll: this.catchAll,
      },
      readOnly: this.props.readOnly,
    };
    config.mapLocationsData = {};
    (this.props.locations || []).forEach(l => {
      config.mapLocationsData[l.value] = l;
    });
    this.schedulerFeatures.forEach((fieldName) => {
      if (fieldName in this.props)
        config.features[fieldName] = this.props[fieldName];
    });
    Object.keys(this.props).forEach((propName) => {
      if (!this.schedulerFeatures.includes(propName)) {
        config[propName] = this.props[propName];
      }
    });
    const submitOrPublish = this.props.submitOrPublish;
    const isManager = this.props.isManager;
    const publishEvent = this.props.publishEvents;
    const submitEvents = this.props.submitEvents;
    const notifyForEvent = this.props.notifyForEvent;
    const scheduleMode = this.props.scheduleMode;
    if (scheduleMode === SCHEDULE_MODES.TODAY) {
      const today = moment(this.props.startDate).format("YYYY-MM-DD");
      config.timeRanges = [
        {
          name: "",
          /* "cls"      : "closed",
                 "startDate": `${today} 00:00`,*/
          startDate: `${today} 04:00`,
        },
        {
          name: "",
          /*"cls"      : "closed",*/
          startDate: `${today} 23:30`,
          /*"endDate"  : `${today} 23:59`*/
        },
      ];
    }
    const self = this;
    config.features.eventContextMenu = {
      processItems({ eventRecord, items }) {
        if (eventRecord && eventRecord.data && !self.props.readOnly) {
          const { schedulerEngine: me } = self;
          if (!me.isEventSelected(eventRecord)) {
            me.selectEvent(eventRecord);
          }
          const eventData = eventRecord.data;
          const submitOrPublish = self.props.submitOrPublish;
          const isManager = self.props.isManager;
          const canPublish = self.props.canPublish || false;
          const isAvailable = props.isAvailable;
          const isMyEmployeeView = submitOrPublish === "publish";
          const isMyView = submitOrPublish === "submit";
          let selectedEvents = self.selectedEvents;
          if (isMyView) {
            if (
              self.props.scheduleMode !== "today" &&
              eventData.name !== SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF &&
              !self.props.readOnly &&
              me &&
              selectedEvents.length <= 1
            ) {
              items.push({
                text: "Add Another Event",
                icon: "b-icon b-icon-add",
                weight: 100,
                name: "addEvent",
                onItem: ({ eventRecord, resourceRecord }) => {
                  me.internalAddEvent(
                    eventRecord.endDate,
                    resourceRecord,
                    me.getRowFor(resourceRecord)
                  );
                },
              });
            }
            if (isManager) {
              if (isAvailable) {
                if (
                  selectedEvents.filter(
                    (ev) => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR
                  ).length > 0
                ) {
                  items.push({
                    text:
                      selectedEvents.filter(
                        (ev) => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR
                      ).length > 1
                        ? `Assign ${
                        selectedEvents.filter(
                          (ev) => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR
                        ).length
                        } events to self`
                        : "Assign to self",
                    icon: "fas fa-lock",
                    name: "assignEvents",
                    onItem: async (event) => {
                      const { eventRecord } = event;
                      if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                        self.schedulerEngine.selectEvent(eventRecord);
                      }
                      const {
                        source: { selectedEvents },
                      } = event;
                      self.props.assignEvents(
                        selectedEvents.filter(
                          (ev) => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR
                        )
                      );
                    },
                  });
                }
              } else {
                if (eventData.status === "pending") {
                  items.push({
                    text: canPublish ? "Publish" : "Submit",
                    icon: "b-fa b-fa-fw b-fa-paper-plane",
                    onItem({ eventRecord }) {
                      submitEvents(eventRecord.data.id);
                    },
                  });
                }
                if (eventData.status !== SCHEDULE_STATUSES.RELEASED) {
                  const copySelectedEvents = self.selectedEvents.filter((ev) =>
                    canModifyShift(ev, "my-shift", isManager)
                  );
                  items.push({
                    text:
                      copySelectedEvents.length > 1
                        ? `Copy ${copySelectedEvents.length} events`
                        : "Copy",
                    icon: "fas fa-copy",
                    name: "copyEvents",
                    onItem: async (event) => {
                      const { eventRecord } = event;
                      if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                        self.schedulerEngine.selectEvent(eventRecord);
                      }
                      self.props.copyEvents();
                    },
                  });
                }
              }
              const selectedEventsWithoutEventsFromLeave = selectedEvents.filter(
                (event) => !event.leaveId
              );
              items.map((ev) => {
                if (
                  ev.name === "deleteEvent" &&
                  selectedEventsWithoutEventsFromLeave.length > 1 &&
                  !eventData.leaveId
                ) {
                  ev.text = `Delete ${selectedEventsWithoutEventsFromLeave.length} events`;
                } else if (ev.name === "deleteEvent" && eventData.leaveId) {
                  ev.hidden = true;
                }
                if (
                  ev.name === "editEvent" &&
                  (selectedEventsWithoutEventsFromLeave.length > 1 ||
                    eventData.leaveId)
                ) {
                  ev.hidden = true;
                }
                return ev;
              });
            } else {
              if (isAvailable && typeof self.props.pickEvents === "function") {
                if (
                  selectedEvents.filter(
                    (ev) => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR
                  ).length > 0
                ) {
                  items.push({
                    text:
                      selectedEvents.filter(
                        (ev) => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR
                      ).length > 1
                        ? `Pick ${
                        selectedEvents.filter(
                          (ev) => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR
                        ).length
                        } events`
                        : "Pick",
                    icon: "fas fa-lock",
                    name: "pickEvents",
                    onItem: async (event) => {
                      const { eventRecord } = event;
                      if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                        self.schedulerEngine.selectEvent(eventRecord);
                      }
                      const {
                        source: { selectedEvents },
                      } = event;
                      self.props.pickEvents(
                        selectedEvents.filter(
                          (ev) => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR
                        )
                      );
                    },
                  });
                }
              } else {
                selectedEvents = self.selectedEvents.filter((ev) =>
                  canModifyShift(ev, TABS.MY_SHIFT, false)
                );
                if (canModifyShift(eventData, TABS.MY_SHIFT, false)) {
                  items.push({
                    text:
                      selectedEvents.length > 1
                        ? `Copy ${selectedEvents.length} events`
                        : "Copy",
                    icon: "fas fa-copy",
                    name: "copyEvents",
                    onItem: async (event) => {
                      const { eventRecord } = event;
                      if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                        self.schedulerEngine.selectEvent(eventRecord);
                      }
                      self.props.copyEvents();
                    },
                  });
                }
              }


              items.map((ev) => {
                if (
                  ev.name === "deleteEvent" &&
                  selectedEvents.length > 1 &&
                  !eventData.leaveId
                ) {
                  ev.text = `Delete ${selectedEvents.length} events`;
                }
                if (
                  ev.name === "deleteEvent" &&
                  (selectedEvents.length === 0 ||
                    !isShiftUnavailable(eventData))
                ) {
                  ev.hidden = true;
                }
                if (
                  ev.name === "editEvent" &&
                  (selectedEvents.length === 0 ||
                    selectedEvents.length > 1 ||
                    !isShiftUnavailable(eventData))
                ) {
                  ev.hidden = true;
                }
                return ev;
              });
            }
            if (!isAvailable) {
              if (eventData.name === "Regular") {
                if (eventData.status === SCHEDULE_STATUSES.APPROVED) {
                  items.push({
                    text: "Notify Me",
                    icon: "b-fa b-fa-fw b-fa-paper-plane",
                    onItem({ eventRecord }) {
                      notifyForEvent(eventRecord);
                    },
                  });
                  const events = self.selectedEvents.filter(
                    (ev) =>
                      ev.name === "Regular" &&
                      ev.status === SCHEDULE_STATUSES.APPROVED
                  );
                  if (events.length > 0) {
                    items.push({
                      text:
                        events.length > 1
                          ? `Coverage request for ${events.length} events`
                          : "Coverage request",
                      icon: "fas fa-unlock",
                      name: "releaseEvents",
                      onItem: async (event) => {
                        const { eventRecord } = event;
                        if (
                          !self.schedulerEngine.isEventSelected(eventRecord)
                        ) {
                          self.schedulerEngine.selectEvent(eventRecord);
                        }
                        const {
                          source: { selectedEvents },
                        } = event;
                        self.props.releaseEvent(
                          selectedEvents.filter(
                            (ev) =>
                              ev.name === "Regular" &&
                              ev.status === SCHEDULE_STATUSES.APPROVED
                          )
                        );
                      },
                    });
                  }
                }
              }
            }
          } else if (isMyEmployeeView) {
            if (
              self.props.scheduleMode !== "today" &&
              eventData.name !== "Scheduled Day Off" &&
              !self.props.readOnly &&
              me &&
              selectedEvents.length <= 1
            ) {
              items.push({
                text: "Add Another Event",
                icon: "b-icon b-icon-add",
                weight: 100,
                name: "addEvent",
                onItem: ({ eventRecord, resourceRecord }) => {
                  me.internalAddEvent(
                    eventRecord.endDate,
                    resourceRecord,
                    me.getRowFor(resourceRecord)
                  );
                },
              });
            }
            if (
              eventData.name === "Unavailability" ||
              eventData.status === SCHEDULE_STATUSES.RELEASED
            ) {
              /*if(eventData.status === SCHEDULE_STATUSES.RELEASED) {
                                // make available
                                const events = self.selectedEvents.filter(ev => ev.name === 'Regular' && [SCHEDULE_STATUSES.PENDING, SCHEDULE_STATUSES.APPROVED, SCHEDULE_STATUSES.RELEASED].includes(ev.status));
                                if(typeof  self.props.makeAvailable === 'function' && events.length > 0) {
                                    items.push({
                                        icon: 'fas fa-unlock',
                                        name: 'makeAvailable',
                                        onItem: async (event) => {
                                            const {eventRecord} = event;
                                        text: events.length > 1 ? `Make Available ${events.length} events` : 'Make Available',
                                            if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                                                self.schedulerEngine.selectEvent(eventRecord);
                                            }
                                            const {source: { selectedEvents}} = event;
                                            self.props.makeAvailable(selectedEvents.filter(ev => ev.data.name === 'Regular'));
                                        }
                                    })
                                }
                            }*/
              // reject unavailability or released
              if (
                eventData.name === "Unavailability" &&
                eventData.status !== SCHEDULE_STATUSES.TO_REGULAR &&
                typeof self.props.changeToRegular === "function"
              ) {
                items.push({
                  text: "Change to regular",
                  icon: "fas fa-undo",
                  name: "changeToAvailable",
                  onItem: async (event) => {
                    const { eventRecord } = event;
                    if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                      self.schedulerEngine.selectEvent(eventRecord);
                    }
                    self.props.changeToRegular(eventRecord);
                  },
                });
              }

              items.map((ev) => {
                if (ev.name !== "changeToAvailable" && ev.name !== "addEvent") {
                  ev.hidden = true;
                }
                return ev;
              });
            } else {
              if (isAvailable) {
                selectedEvents = selectedEvents.filter(
                  (ev) => ev.name === "Regular"
                );
                items.push({
                  text:
                    self.selectedEvents.length > 1
                      ? `Assign ${selectedEvents.length} events to employees`
                      : "Assign to employee",
                  icon: "fas fa-lock",
                  name: "assignEvents",
                  onItem: async (event) => {
                    const { eventRecord } = event;
                    if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                      self.schedulerEngine.selectEvent(eventRecord);
                    }
                    const {
                      source: { selectedEvents },
                    } = event;
                    self.props.assignEvents(selectedEvents);
                  },
                });
              } else {
                if (eventData.status === SCHEDULE_STATUSES.PENDING) {
                  items.push({
                    text: "Publish",
                    icon: "b-fa b-fa-fw b-fa-upload",
                    onItem({ eventRecord }) {
                      publishEvent(eventRecord.data.id);
                    },
                  });
                }
                const events = self.selectedEvents.filter(
                  (ev) =>
                    ev.name === "Regular" &&
                    [
                      SCHEDULE_STATUSES.PENDING,
                      SCHEDULE_STATUSES.APPROVED,
                    ].includes(ev.status)
                );
                if (
                  typeof self.props.makeAvailable === "function" &&
                  events.length > 0
                ) {
                  items.push({
                    text:
                      events.length > 1
                        ? `Make Available ${events.length} events`
                        : "Make Available",
                    icon: "fas fa-unlock",
                    name: "makeAvailable",
                    onItem: async (event) => {
                      const { eventRecord } = event;
                      if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                        self.schedulerEngine.selectEvent(eventRecord);
                      }
                      const {
                        source: { selectedEvents },
                      } = event;
                      self.props.makeAvailable(
                        selectedEvents.filter(
                          (ev) =>
                            ev.data.name === "Regular" &&
                            [
                              SCHEDULE_STATUSES.PENDING,
                              SCHEDULE_STATUSES.APPROVED,
                            ].includes(ev.status)
                        )
                      );
                    },
                  });
                }
                const copySelectedEvents = self.selectedEvents.filter(
                  (ev) =>
                    !["Unavailability", "Scheduled Day Off"].includes(
                      ev.name
                    ) &&
                    [
                      SCHEDULE_STATUSES.PENDING,
                      SCHEDULE_STATUSES.APPROVED,
                    ].includes(ev.status)
                );
                if (
                  copySelectedEvents.length > 0 &&
                  !["Unavailability", "Scheduled Day Off"].includes(
                    eventData.name
                  )
                ) {
                  items.push({
                    text:
                      copySelectedEvents.length > 1
                        ? `Copy ${copySelectedEvents.length} events`
                        : "Copy",
                    icon: "fas fa-copy",
                    name: "copyEvents",
                    onItem: async (event) => {
                      const { eventRecord } = event;
                      if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                        self.schedulerEngine.selectEvent(eventRecord);
                      }
                      self.props.copyEvents();
                    },
                  });
                }
              }
              const deletedEvents = self.selectedEvents.filter(
                (ev) =>
                  !["Unavailability", "Scheduled Day Off"].includes(ev.name) &&
                  ev.status !== SCHEDULE_STATUSES.RELEASED &&
                  !ev.leaveId
              );
              if (deletedEvents.length > 0) {
                items.map((ev) => {
                  if (
                    ev.name === "deleteEvent" &&
                    deletedEvents.length > 1 &&
                    !eventData.leaveId
                  ) {
                    ev.text = `Delete ${deletedEvents.length} events`;
                  } else if (ev.name === "deleteEvent" && eventData.leaveId) {
                    ev.hidden = true;
                  }
                  if (ev.name === "editEvent" && deletedEvents.length > 1) {
                    ev.hidden = true;
                  }
                  return ev;
                });
              } else {
                items.map((ev) => {
                  if (ev.name === "deleteEvent") {
                    ev.hidden = true;
                  }
                  if (ev.name === "editEvent") {
                    ev.hidden = true;
                  }
                  return ev;
                });
              }
            }
          }
        } else if (self.props.readOnly) {
          items.map((ev) => {
            ev.hidden = true;
            return ev;
          });
        }
      },
    };
    config.features.eventResize = {
      validatorFn(event) {
        const me = this;
        const eventStore = me.store;
        const { eventRecord, resourceRecord, start, end } = event;
        const valid = eventStore.isDateRangeAvailable(
          start,
          end,
          eventRecord,
          resourceRecord
        );
        return {
          valid,
          message: valid
            ? ""
            : "Warning: You can't add two shifts for same person in same time",
        };
      },
    };
    config.features.eventEdit = this.featureEventEdit;
    config.features.headerContextMenu = {
      // Process items before menu is shown
      processItems({ items }) {
        // Remove zoom and Change Date
        items[0].hidden = true;
        items[1].hidden = true;
      },
    };
    config.viewPreset = presets[this.props.viewPreset];
    config.features.eventTooltip = {
      template: data => {
        return `
      ${data.eventRecord.name ? `<div class="b-sch-event-title">${data.eventRecord.name}</div>` : ''}
      ${data.startClockHtml}
      ${data.endClockHtml}
      ${data.eventRecord.data.sectionData ? `<div class="b-sch-event-title">Zones</div>` : ''}
      ${data.eventRecord.data.sectionData && Array.isArray(data.eventRecord.data.sectionData)? data.eventRecord.data.sectionData.map(d => {
        return `
        <div class="${d.zoneColor}">
            ${d.zone} |
            <span>${d.startTimeDisplay ? d.startTimeDisplay : d.startTime}</span> -
            <span>${d.endTimeDisplay ? d.endTimeDisplay : d.endTime}</span>
        </div>
        `
      }).join('') : ''}
      `
      }
    };
    const props = this.props;
    config.timeAxis = {
      continuous: false,
      generateTicks(start, end, unit, increment) {
        // Use our own custom time intervals for day time-axis
        start = props.startDate || start;
        end = props.endDate || end;
        if (unit === "day") {
          const ticks = [];
          let intervalEnd;
          while (start < end) {
            const daySettings = timeOpenSettings[start.getDay()];
            if (daySettings) {
              start.setHours(daySettings.start.hr, daySettings.start.min);
              intervalEnd = new Date(
                start.getFullYear(),
                start.getMonth(),
                start.getDate(),
                daySettings.end.hr,
                daySettings.end.min
              );
            } else {
              start.setHours(5);

              if (start.getDay() > 5 || start.getDay() === 0) {
                intervalEnd = DateHelper.add(start, 15, "h");
              } else {
                intervalEnd = DateHelper.add(start, 18, "h");
              }
            }

            ticks.push({
              startDate: start,
              endDate: intervalEnd,
            });
            start = DateHelper.add(start, 1, "d");
          }
          return ticks;
        } else {
          const ticks = [];
          let intervalEnd;
          const daySettings = timeOpenSettings[start.getDay()];
          if (daySettings) {
            start.setHours(daySettings.start.hr, daySettings.start.min);
            intervalEnd = new Date(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              daySettings.end.hr,
              daySettings.end.min
            );
          } else {
            if (start.getDay() > 5) {
              start.setHours(5);
              intervalEnd = DateHelper.add(start, 15, "h");
            } else {
              start.setHours(5);
              intervalEnd = DateHelper.add(start, 18, "h");
            }
          }
          let i = start.getHours();
          const startHrs = i;
          let endHrs = intervalEnd.getHours();
          let hasMinutes = false;
          let startHrsMinutes = 0;
          if (intervalEnd.getMinutes() > 0) {
            endHrs += 1;
            hasMinutes = true;
          }
          if (start.getMinutes() > 0) {
            startHrsMinutes = start.getMinutes();
            start.setMinutes(0);
          }
          start.setHours(0);
          for (i; i < endHrs; i += 1) {
            let startDate = DateHelper.add(start, i, "h");
            let endDate = DateHelper.add(start, i + 1, "h");
            if (daySettings) {
              if (i === endHrs - 1 && hasMinutes) {
                endDate = DateHelper.add(
                  startDate,
                  parseInt(daySettings.end.min),
                  "m"
                );
              }
              if (i === startHrs && startHrsMinutes > 0) {
                startDate = DateHelper.add(startDate, startHrsMinutes, "m");
              }
            }
            ticks.push({
              startDate,
              endDate,
            });
          }
          return ticks;
        }
      },
    };
    if (scheduleMode !== SCHEDULE_MODES.TODAY) {
      config.features.eventDragCreate = false;
    } else {
      config.features.eventDragCreate = {
        onResize({ context: { valid }, event }) {
          const me = this,
            client = me.client,
            { startDate, endDate } = me.createContext;

          if (!startDate || !endDate || endDate - startDate <= 0) valid = false;

          Object.assign(me.createContext, {
            event: event,
            async: false,
            finalize: (doCreate) => me.finalize(doCreate),
          });

          if (valid) {
            client.trigger("beforeDragCreateFinalize", {
              context: me.createContext,
              event,
              proxyElement: me.proxy,
            });
          } else {
            new ErrorToast(
              `Warning: You can't add two shifts for same person in same time`
            );
          }

          // Drag create could be finalized immediately
          if (me.createContext) {
            // OMG, how not to confuse those contexts?
            me.resize.context.async = me.createContext.async;

            if (!me.createContext.async) {
              me.finalize(valid);
            } else {
              // We do not want to remove resizer yet, because it will also remove context and proxy element (or resize to 0 width)
              // Instead we blindfold resize helper to prevent event resize from starting parallel drag create
              me.resize.removeListeners();
            }
          }
        },
      };
    }
    config.features.eventDrag = {
      ...EventDragConfig,
      onInvalidDrop() {
        const me = this;
        me.drag.reset();
        //me.renderRanges();
        const {
          record: { data },
        } = me.dragData;
        let message = `Warning: This employee is not in same location or the doesn't have same job as shift have `;
        if (data) {
          message = `This task is only for location ${
            self.locationMap[data.locationId]
            } and job ${self.jobsMap[data.jobId].text}!`;
        }
        new ErrorToast(message);
        if (me.tip) {
          me.tip.destroy();
          me.tip = null;
        }
      },
      validatorFn: (context) => {
        return eventDragValidation(
          context,
          self.isAvailable,
          self.jobsMap,
          self.locationMap,
          self.jobsData
        );
      },
    };
    if (this.isAvailable && !this.props.isManager) {
      config.features.eventDrag = false;
      config.features.eventDragCreate = false;
    }
    config.minHeight = "100px";
    //config.rowHeight = 45;
    const engine = (this.schedulerEngine = new Scheduler(config));
    this.schedulerEngine.exportToExcel = (events, resources) => {
      const columns = [
        { text: "Staff", field: "name", width: 200, type: "String" },
        { text: "Role", field: "role", width: 200, type: "String" },
        { text: "Location", field: "location", width: 200, type: "String" },
        { text: "Task", field: "name", width: 200, type: "String" },
        { text: "Starts", field: "startDate", width: 200, type: "String" },
        { text: "Ends", field: "endDate", width: 200, type: "String" },
      ];
      const rows = [];
      events.forEach((ev) => {
        const u = resources.find((u) => u.id === ev.resourceId);
        if (u) {
          const el = [
            { value: u.name, type: "String" },
            { value: u.role, type: "String" },
            { value: u.location, type: "String" },
            { value: ev.name, type: "String" },
            {
              value: moment(ev.startDate)
                .format("YYYY-MM-DD hh:mm A")
                .toString(),
              type: "String",
            },
            {
              value: moment(ev.endDate).format("YYYY-MM-DD hh:mm A").toString(),
              type: "String",
            },
          ];
          rows.push(el);
        }
      });
      const excelConfig = {
        dateFormat: "YYYY-MM-DD HH:mm",
      };
      const excelExporter = new ExcelExporter(
        this.schedulerEngine,
        excelConfig
      );
      excelExporter.export(
        {
          filename: "Export with column width specified",
          // Choose the Resource fields to include in the exported file
          resourceColumns: [],
          // Choose the Event fields to include in the exported file
          eventColumns: [
            { text: "Task", field: "name", width: 200 },
            { text: "Starts", field: "startDate", width: 200 },
            { text: "Ends", field: "endDate", width: 200 },
          ],
        },
        columns,
        rows
      );
    };
    Object.keys(engine.features).forEach((key) => {
      if (!this[key]) this[key] = engine.features[key];
    });
  }

  componentWillUnmount() {
    this.jobsData = [];
    this.loggedInUser = null;
    this.locationsData = [];
    this.jobsMap = {};
    this.locationMap = {};
    this.scheduleConfigs = {
      sections: {},
    };
    if (
      this.schedulerEngine &&
      this.schedulerEngine.eventEdit &&
      this.schedulerEngine.eventEdit.editor &&
      this.schedulerEngine.eventEdit.editor.isVisible
    )
      this.schedulerEngine.eventEdit.onCancelClick();
    if (
      this.schedulerEngine &&
      typeof this.schedulerEngine.destroy === "function"
    )
      this.schedulerEngine.destroy();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.jobsData = this.props.jobs;
    this.loggedInUser = this.props.loggedInUser;
    this.locationsData = this.props.locations;
    this.jobsMap = this.props.mapJobs;
    this.locationMap = this.props.mapLocations;
    this.scheduleConfigs = this.props.scheduleConfigs;
    const engine = this.schedulerEngine;
    engine.mapLocationsData = {};
    (this.props.locations || []).forEach(l => {
      engine.mapLocationsData[l.value] = l;
    })
    const props = this.props;
    const self = this;
    const scheduleMode = this.props.scheduleMode;
    const excludeProps = [
      "events",
      "resources",
      "eventsVersion",
      "resourcesVersion",
      "notifyForEvent",
      "columns",
      "adapter",
      "ref",
      "children",
      ...this.schedulerFeatures,
    ];
    if (scheduleMode === SCHEDULE_MODES.TODAY) {
      const today = moment(props.startDate).format("YYYY-MM-DD");
      engine.timeRanges = [
        {
          name: "",
          /* "cls"      : "closed",
                 "startDate": `${today} 00:00`,*/
          startDate: `${today} 04:00`,
        },
        {
          name: "",
          /*"cls"      : "closed",*/
          startDate: `${today} 23:30`,
          /*"endDate"  : `${today} 23:59`*/
        },
      ];
    }
    Object.keys(props).forEach((propName) => {
      if (
        !excludeProps.includes(propName) &&
        props[propName] !== prevProps[propName] &&
        !["startDate", "endDate"].includes(propName)
      ) {
        engine[propName] = props[propName];
      }
    });
    if (prevProps.viewPreset !== props.viewPreset)
      engine.viewPreset = presets[this.props.viewPreset];
    engine.features.eventEdit = this.featureEventEdit;
    if (prevProps.startDate !== props.startDate)
      engine.startDate = this.props.startDate;
    if (prevProps.endDate !== props.endDate)
      engine.endDate = this.props.endDate;
    //engine.fillTicks= this.props.scheduleMode !== 'today';
    engine.features.headerContextMenu = {
      // Process items before menu is shown
      processItems({ items }) {
        items[0].hidden = true;
        items[1].hidden = true;
      },
    };
    if (this.props.isAvailable && props.locations && props.jobs) {
      locations.data = props.locations;
      jobs.data = props.jobs;
    }
    if (prevProps.resourcesVersion !== props.resourcesVersion) {
      engine.resources = props.resources;
    }
    if (prevProps.eventsVersion !== props.eventsVersion) {
      engine.eventStore.removeAll();
      engine.eventStore.data = props.events;
    }
    engine.features["excelExporter"] = {
      // Choose the date format for date fields
      dateFormat: "YYYY-MM-DD HH:mm",
    };
    this.schedulerFeatures.forEach((fieldName) => {
      const currentProp = props[fieldName];
      const prevProp = prevProps[fieldName];
      if (
        fieldName in props &&
        currentProp !== prevProp &&
        JSON.stringify(currentProp) !== JSON.stringify(prevProp)
      ) {
        engine.features[fieldName].setConfig(currentProp);
      }
    });
    engine.timeAxis = {
      continuous: false,
      generateTicks(start, end, unit, increment) {
        // Use our own custom time intervals for day time-axis
        start = props.startDate || start;
        end = props.endDate || end;
        if (unit === "day") {
          const ticks = [];
          let intervalEnd;
          while (start < end) {
            const daySettings = timeOpenSettings[start.getDay()];
            if (daySettings) {
              start.setHours(daySettings.start.hr, daySettings.start.min);
              intervalEnd = new Date(
                start.getFullYear(),
                start.getMonth(),
                start.getDate(),
                daySettings.end.hr,
                daySettings.end.min
              );
            } else {
              start.setHours(5);

              if (start.getDay() > 5 || start.getDay() === 0) {
                intervalEnd = DateHelper.add(start, 15, "h");
              } else {
                intervalEnd = DateHelper.add(start, 18, "h");
              }
            }

            ticks.push({
              startDate: start,
              endDate: intervalEnd,
            });
            start = DateHelper.add(start, 1, "d");
          }
          return ticks;
        } else {
          const ticks = [];
          let intervalEnd;
          const daySettings = timeOpenSettings[start.getDay()];
          if (daySettings) {
            start.setHours(daySettings.start.hr, daySettings.start.min);
            intervalEnd = new Date(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              daySettings.end.hr,
              daySettings.end.min
            );
          } else {
            if (start.getDay() > 5) {
              start.setHours(5);
              intervalEnd = DateHelper.add(start, 15, "h");
            } else {
              start.setHours(5);
              intervalEnd = DateHelper.add(start, 18, "h");
            }
          }
          let i = start.getHours();
          const startHrs = i;
          let endHrs = intervalEnd.getHours();
          let hasMinutes = false;
          let startHrsMinutes = 0;
          if (intervalEnd.getMinutes() > 0) {
            endHrs += 1;
            hasMinutes = true;
          }
          if (start.getMinutes() > 0) {
            startHrsMinutes = start.getMinutes();
            start.setMinutes(0);
          }
          start.setHours(0);
          for (i; i < endHrs; i += 1) {
            let startDate = DateHelper.add(start, i, "h");
            let endDate = DateHelper.add(start, i + 1, "h");
            if (daySettings) {
              if (i === endHrs - 1 && hasMinutes) {
                endDate = DateHelper.add(
                  startDate,
                  parseInt(daySettings.end.min),
                  "m"
                );
              }
              if (i === startHrs && startHrsMinutes > 0) {
                startDate = DateHelper.add(startDate, startHrsMinutes, "m");
              }
            }
            ticks.push({
              startDate,
              endDate,
            });
          }
          return ticks;
        }
      },
      autoAdjust: false,
      viewPreset: presets[props.viewPreset],
    };
    if (scheduleMode !== SCHEDULE_MODES.TODAY) {
      engine.features.eventDragCreate = false;
    }
    engine.features.eventDrag = {
      ...EventDragConfig,
      onInvalidDrop() {
        const me = this;
        me.drag.reset();
        //me.renderRanges();
        const {
          record: { data },
        } = me.dragData;
        let message = `Warning: This employee is not in same location or the doesn't have same job as shift have `;
        if (data) {
          message = `This task is only for location ${
            self.locationMap[data.locationId]
            } and job ${self.jobsMap[data.jobId].text}!`;
        }
        new ErrorToast(message);
        if (me.tip) {
          me.tip.destroy();
          me.tip = null;
        }
      },
      validatorFn: (context) => {
        return eventDragValidation(
          context,
          self.isAvailable,
          self.jobsMap,
          self.locationMap,
          self.jobsData
        );
      },
    };
    if (this.isAvailable && !this.props.isManager) {
      engine.features.eventDrag = false;
      engine.features.eventDragCreate = false;
    }
  }

  addEvent() {
    const scheduler = this.schedulerEngine,
      startDate = new Date(scheduler.startDate.getTime()),
      endDate = new Date(startDate.getTime());

    endDate.setHours(endDate.getHours() + 1);

    scheduler.eventStore.add({
      resourceId: scheduler.resourceStore.first.id,
      startDate: startDate,
      endDate: endDate,
      name: "New shift",
      eventType: "Meeting",
    });
  }

  removeEvent() {
    this.schedulerEngine.selectedEvents &&
      this.schedulerEngine.selectedEvents[0].remove();
  }

  render() {
    return (
      <div
        className={"b-react-scheduler-container"}
        id={this.props.comId ? this.props.comId : ""}
        ref={(el) => (this.el = el)}
      />
    );
  }
}

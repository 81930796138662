export default class BreakData {
  constructor(){
    const newTime = [];
    const am = 12;
    for(let i = 0; i < 24; i++) {
      for(let j = 0; j<12; j++) {
        let min = j * 5;
        /*switch (j) {
          case 0:
            min = 0;
            break;
          case 1:
            min = 10;
            break;
          case 2:
            min = 15;
            break;
          case 3:
            min = 20;
            break;
          case 4:
            min = 30;
            break;
          case 5:
            min = 40;
            break;
          case 6:
            min = 45;
            break;
          case 7:
            min = 50;
            break;
        }*/
        let h = i;
        if( i === 0) {
          h = 12;
        } else if (i > 12) {
          h = i - 12;
        }
         h =  h  <  10 ? `0${h}` : h;
        const value = `${h}:${min < 10 ? '0'+min : min}`;
        newTime.push({
          text: `${value} ${i < am ? 'AM' : 'PM'}`,
          value: `${i < 10 ? '0'+i : i}:${min < 10 ? '0'+min : min}`,
          h: i,
          m: min,
          am: i < am,
        })
      }
    }
    this.time = newTime;
  }
  breaks = [
    { id: 0, value: 0, text: 'None' },
    { id: 10, value: 10, text: '10 Min' },
    { id: 15, value: 15, text: '15 Min' },
    { id: 20, value: 20, text: '20 Min' },
    { id: 30, value: 30, text: '30 Min' },
    { id: 45, value: 45, text: '45 Min' },
    { id: 60, value: 60, text: '60 Min' }
  ];
  time = [
    {"text" : "12:00 AM", value: '12:00', h: 12, m: 0, am: 1 },
    {"text" : "12:10 AM", value: '12:10', h: 12, m: 10, am: 1 },
    {"text" : "12:15 AM", value: '12:15', h: 12, m: 15, am: 1 },
    {"text" : "12:20 AM", value: '12:20', h: 12, m: 20, am: 1 },
    {"text" : "12:30 AM", value: '12:30', h: 12, m: 30, am: 1 },
    {"text" : "12:40 AM", value: '12:40', h: 12, m: 40, am: 1 },
    {"text" : "12:45 AM", value: '12:45', h: 12, m: 45, am: 1 },
    {"text" : "12:50 AM", value: '12:50', h: 12, m: 50, am: 1 },/*
    {"text" : "01:00 AM", h: 1, m: 0, am: 1 },
    {"text" : "01:10 AM", h: 1, m: 10, am: 1 },
    {"text" : "01:15 AM", h: 1, m: 15, am: 1 },
    {"text" : "01:20 AM", h: 1, m: 20, am: 1 },
    {"text" : "01:30 AM", h: 1, m: 30, am: 1 },
    {"text" : "01:45 AM", h: 1, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 1 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 1 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 1 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 1 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 1 },
    {"text" : "03:00 AM", h: 3, m: 0, am: 1 },
    {"text" : "03:15 AM", h: 3, m: 15, am: 1 },
    {"text" : "03:20 AM", h: 3, m: 20, am: 1 },
    {"text" : "03:30 AM", h: 3, m: 30, am: 1 },
    {"text" : "03:45 AM", h: 3, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 4, m: 0, am: 1 },
    {"text" : "02:15 AM", h: 4, m: 15, am: 1 },
    {"text" : "02:20 AM", h: 4, m: 20, am: 1 },
    {"text" : "02:30 AM", h: 4, m: 30, am: 1 },
    {"text" : "02:45 AM", h: 4, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 5, m: 0, am: 1 },
    {"text" : "02:15 AM", h: 5, m: 15, am: 1 },
    {"text" : "02:20 AM", h: 5, m: 20, am: 1 },
    {"text" : "02:30 AM", h: 5, m: 30, am: 1 },
    {"text" : "02:45 AM", h: 5, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 6, m: 0, am: 1 },
    {"text" : "02:15 AM", h: 6, m: 15, am: 1 },
    {"text" : "02:20 AM", h: 6, m: 20, am: 1 },
    {"text" : "02:30 AM", h: 6, m: 30, am: 1 },
    {"text" : "02:45 AM", h: 6, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 7, m: 0, am: 1 },
    {"text" : "02:15 AM", h: 7, m: 15, am: 1 },
    {"text" : "02:20 AM", h: 7, m: 20, am: 1 },
    {"text" : "02:30 AM", h: 7, m: 30, am: 1 },
    {"text" : "02:45 AM", h: 7, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 8, m: 0, am: 1 },
    {"text" : "02:15 AM", h: 8, m: 15, am: 1 },
    {"text" : "02:20 AM", h: 8, m: 20, am: 1 },
    {"text" : "02:30 AM", h: 8, m: 30, am: 1 },
    {"text" : "02:45 AM", h: 8, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 9, m: 0, am: 1 },
    {"text" : "02:15 AM", h: 9, m: 15, am: 1 },
    {"text" : "02:20 AM", h: 9, m: 20, am: 1 },
    {"text" : "02:30 AM", h: 9, m: 30, am: 1 },
    {"text" : "02:45 AM", h: 9, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 10, m: 0, am: 1 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 1 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 1 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 1 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 1 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 1 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 1 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 1 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 1 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },
    {"text" : "02:00 AM", h: 2, m: 0, am: 0 },
    {"text" : "02:15 AM", h: 2, m: 15, am: 0 },
    {"text" : "02:20 AM", h: 2, m: 20, am: 0 },
    {"text" : "02:30 AM", h: 2, m: 30, am: 0 },
    {"text" : "02:45 AM", h: 2, m: 45, am: 0 },*/
  ];
}

import React, { useState, useEffect, useReducer, useMemo } from "react";
import Modal from "react-modal";
import moment from "moment";
import Select from "react-select";
import TimeInput from "./TimeInput";
import Break from "./Break";
import BreaksClass from "../BryntumScheduler/Breaks";

import {
  validateBreakTimes,
  breakEdgeOffset,
  getEligibleStart,
  getEligibleEnd,
} from "./BreakHelper";

import "./Assets/Styles/add-event.css";
import "./Assets/Styles/breaks.css";
import {
  MYSQL_DATETIME_FORMAT,
  SCHEDULE_MAPPED_EVENTS,
} from "../../containers/Schedule/Constant";
import OverTime from "../../helpers/OverTime";
import EmployeeService from "../../services/EmployeeService";
import AsyncSelect from "react-select/lib/Async";
import AsyncAlert from "../../helpers/AsyncAlert";
import { isManager } from "../Schedule/helpers/SchedulerHelper";
import Zone from "./Zone";
import { onDateTimeChange } from "../BryntumScheduler/Zones";
const OT = new OverTime();

const managerNameOptions = [
  { name: "Regular", value: "Regular" },
  { name: "Vacation", value: "Vacation" },
  { name: "Sick", value: "Sick" },
  { name: "Personal", value: "Personal" },
  { name: "Offsite", value: "Offsite" },
  {
    name: "Leave of absence",
    value: "Leave of absence",
  },
  { name: "Other", value: "Other" },
  { name: "Scheduled Day Off", value: "Scheduled Day Off" },
];

const employeeNameOptionsNew = [
  { name: "Unavailability", value: "Unavailability" }
];

Modal.setAppElement("#root");

let timeout = null;
const getMinuteDifference = (n, p) => {
  return moment
    .duration(moment(n).endOf("minute").diff(moment(p).endOf("minute")))
    .asMinutes();
};

const mapBreaks = (time, breaks) => {
  return Object.keys(breaks).reduce((t, b, i) => {
    if (b.indexOf("Time") === -1 && breaks[b] > 0) {
      const index = parseInt(b.substr(-1, 1));
      t[index - 1] = {
        duration: breaks[b],
        start: moment(
          `${time.format("YYYY-MM-DD")} ${breaks[`break${index}StartTime`]}:00`
        ),
        end: moment(
          `${time.format("YYYY-MM-DD")} ${breaks[`break${index}EndTime`]}:00`
        ),
      };
    }
    return t;
  }, {});
};

const generateBreaks = ({
  employee,
  eventStartTime,
  eventEndTime,
  name,
  dispatchBreak,
}) => {
  const breakHelper = new BreaksClass();
  const newBreaks = { data: {} };
  breakHelper.checkForBreaks(
    {
      locationCode: employee.location_code,
      salaryHourly: employee.salary_hourly,
    },
    newBreaks,
    eventStartTime,
    eventEndTime,
    name.value
  );
  const finalBreaks = mapBreaks(eventStartTime, newBreaks);
  dispatchBreak({ type: "set", value: finalBreaks });
};
const compareBreaks = (breaks, eventBreaks, date) => {
  // console.log(breaks, eventBreaks, date);

  const formatDate = (d) => date.split(" ")[0] + " " + d + ":00";

  if (breaks.length !== eventBreaks.length) return true;
  else
    return breaks.every((b) => {
      return eventBreaks.reduce((t, eB) => {
        return (
          b.duration !== eB.duration ||
          !b.start.isSame(moment(formatDate(eB.start))) ||
          !b.end.isSame(moment(formatDate(eB.end))) ||
          t
        );
      }, false);
    });
};
const AddEvent = ({
  selectedDate: date = moment(),
  isOpen,
  onRequestClose,
  event,
  edit,
  locations: parentLocations,
  jobs: parentJobs,
  self,
  user,
  onScheduleSave,
  onScheduleDelete,
  readOnly,
  scheduleConfigs,
  filters,
  setEmployeeRates,
  preview = false,
}) => {
  filters = filters || {};

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState({});
  const [notes, setNotes] = useState("");
  const [resourceId, setResourceId] = useState({});
  const [location, setLocation] = useState({});
  const [locations, setLocations] = useState(parentLocations);
  const [job, setJob] = useState({});
  const [jobs, setJobs] = useState(parentJobs);
  const [section, setSection] = useState([]);
  // const [sectionData, setSectionData] = useState([]);
  const [sections, setSections] = useState([]);

  const [eventStartTime, setEventStartTime] = useState();
  const [eventEndTime, setEventEndTime] = useState();

  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);
  const [error, setError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [initEditEvent, setInitEditEvent] = useState(false);
  const [initialBreaksLoaded, setInitialBreaksLoaded] = useState(false);
  const [changed, setChanged] = useState(true);
  const [prevSearch, setPrevSearch] = useState(null);
  const [prevSearchData, setPrevSearchData] = useState([]);

  const breaksReducer = (state, action) => {
    switch (action.type) {
      case "refresh":
        return { ...state };

      case "set":
        return action.value;

      case "new":
        const newIndex = Object.keys(state).length;
        return newIndex === 4
          ? state
          : {
            ...state,
            [newIndex]: {
              duration: 10,
              start: moment(eventStartTime).add(breakEdgeOffset, "minutes"),
              end: moment(eventStartTime).add(
                10 + breakEdgeOffset,
                "minutes"
              ),
            },
          };

      case "delete":
        return Object.assign(
          {},
          Object.values(state).filter((a, i) => i !== action.index)
        );

      case "start":
        return state[action.index]
          ? {
            ...state,
            [action.index]: {
              ...state[action.index],
              start: action.value,
              end: moment(action.value).add(
                state[action.index].duration,
                "minutes"
              ),
            },
          }
          : state;

      case "end":
        return state[action.index]
          ? {
            ...state,
            [action.index]: {
              ...state[action.index],
              end: action.value,
              start: moment(action.value).subtract(
                state[action.index].duration,
                "minutes"
              ),
            },
          }
          : state;

      case "duration":
        const newEndTime = moment(state[action.index].start).add(
          action.value,
          "minutes"
        );
        const lastEligibleEndTime = getEligibleEnd(eventEndTime);
        const isNewEndTimeValid = newEndTime.isSameOrBefore(
          lastEligibleEndTime
        );

        return {
          ...state,
          [action.index]: {
            duration: action.value,
            start: isNewEndTimeValid
              ? moment(state[action.index].start)
              : moment(lastEligibleEndTime).subtract(action.value, "minutes"),
            end: isNewEndTimeValid ? newEndTime : lastEligibleEndTime,
          },
        };

      case "delete all":
        return {};

      case "event time change":
        const isStart = action.caller === "start";
        const prevEligibleStart = getEligibleStart(
          moment(action.prevStartTime)
        );
        const newEligibleStart = isStart
          ? getEligibleStart(moment(action.newStartTime))
          : eventStartTime;
        const newEligibleEnd = getEligibleEnd(moment(action.newEndTime));
        const roundFunction = action.difference > 0 ? "floor" : "ceil";
        const newDifference = Math[roundFunction](action.difference / 5) * 5;

        return Object.values(state).reduce(
          (newBreaks, { duration, start, end }, i) => {
            let newBreakStart = start;
            let newBreakEnd = end;

            if (isStart) {
              newBreakStart = moment(start).add(newDifference, "minutes");
              newBreakEnd = moment(end).add(newDifference, "minutes");

              const currDiff = getMinuteDifference(
                newBreakStart,
                newEligibleStart
              );
              const prevDiff = getMinuteDifference(start, prevEligibleStart);

              if (
                action.difference > 0
                  ? prevDiff > currDiff
                  : prevDiff < currDiff
              ) {
                const filler = Math.sign(action.difference) * 5;
                newBreakStart.add(filler, "minutes");
                newBreakEnd.add(filler, "minutes");
              }
            }

            if (newBreakEnd.isSameOrBefore(newEligibleEnd, "minutes"))
              newBreaks[i] = {
                duration,
                start: newBreakStart,
                end: newBreakEnd,
              };

            return newBreaks;
          },
          {}
        );
      default:
        return state;
    }
  };

  const [breaks, dispatchBreak] = useReducer(breaksReducer, {});

  const sectionDataReducer = (state, action) => {
    switch (action.type) {
      case "refresh":
        return [
          ...state,
        ];

      case "set":
        return action.value;

      case "new":
        state.push({
          color: action.color,
          endTime: eventEndTime.format('HH:mm'),
          id: action.value,
          startTime: eventEndTime.format('HH:mm'),
          text: action.text,
          value: action.value,
        });
        return [
          ...state,
        ];
      case "start":
        if (state[action.index]) {
          state[action.index].startTime= action.value;
        }
        return [
          ...state,
        ];

      case "end":
        if (state[action.index]) {
          state[action.index].endTime = action.value;
        }
        return [
          ...state,
        ];
      case "event time change":
        const isStart = action.caller === "start";
        const newEligibleStart = getEligibleStart((isStart
          ? moment(action.newStartTime)
          : eventStartTime), -5);
        const newEndTime = getEligibleEnd(moment(action.newEndTime), -5);
        return onDateTimeChange(newEligibleStart, newEndTime, state);
        // const eventDate = moment(newEligibleStart).format('YYYY-MM-DD');
        // return state.map(item => {
        //   let startTime = moment(`${eventDate} ${item.startTime}`);
        //   let endTime = moment(`${eventDate} ${item.endTime}`);
        //   if (
        //     newEndTime.isBefore(startTime) ||
        //     newEndTime.diff(startTime, "minutes") < 5 ||
        //     (startTime.isBefore(newEndTime) &&
        //       newEndTime.diff(startTime, "minutes") < 5)
        //   ) {
        //     item.endTime = newEndTime.format('HH:mm');
        //     item.startTime = newEndTime.clone().add(-5, 'minutes').format('HH:mm');
        //   } else if (
        //     newEligibleStart.isAfter(endTime) ||
        //     endTime.diff(newEligibleStart, "minutes") < 5 ||
        //     (endTime.isBefore(newEligibleStart) &&
        //       moment(newEligibleStart).diff(endTime, "minutes") < 5)
        //   ) {
        //     item.startTime = newEligibleStart.format('HH:mm');
        //     item.endTime = newEligibleStart.clone().add(5, 'minutes').format('HH:mm');
        //   } else {
        //     if (
        //       newEndTime.isBefore(endTime) ||
        //       newEndTime.diff(endTime, "minutes") < 5 ||
        //       (endTime.isBefore(newEndTime) &&
        //         newEndTime.diff(endTime, "minutes") < 5)
        //     ) {
        //       item.endTime = newEndTime.format('HH:mm');
        //     }
        //     if (
        //       newEligibleStart.isAfter(startTime) ||
        //       startTime.diff(newEligibleStart, "minutes") < 5 ||
        //       (startTime.isBefore(newEligibleStart) &&
        //         moment(newEligibleStart).diff(startTime, "minutes") < 5)
        //     ) {
        //       item.startTime = newEligibleStart.format('HH:mm');
        //     }
        //   }
        //   return item;
        // });

      default:
        return state;
    }
  };

  const [sectionData, dispatchSectionData] = useReducer(sectionDataReducer, []);

  const loadOptions = (inputValue, callback) => {
    timeout && clearTimeout(timeout);

    timeout = setTimeout(async () => {
      if (initEditEvent) {
        if (date) {
          filters.startDate = moment(date).format('YYYY-MM-DD 00:00:00')
        }
        const search = await EmployeeService.searchMyEmployees(
          event.employeeCode,
          true,
          filters
        );
        const data = prepareResults(search.data);
        const locations = data.length ? data[0].locations : [];
        const jobs = data.length ? data[0].jobs : [];
        let selectedJob = jobs.find(j => j.value === event.jobId);
        if (!selectedJob) {
          selectedJob = {
            value: event.jobId,
            name: event.jobName
          };
          jobs.push(selectedJob)
        }
        let selectedLocation = locations.find(l => l.value === event.locationId);
        if (!selectedLocation) {
          selectedLocation = {
            value: event.locationId,
            name: event.locationName,
            code: event.locationCode,
            state: event.locationState
          }
          locations.push(selectedLocation);
        }
        setResourceId(data[0]);
        setLocations(locations);
        setLocation(selectedLocation);
        setJobs(jobs);
        setJob(selectedJob);
        setInitEditEvent(false);
        //setSectionsBasedOnJob(selectedJob.value);
        return callback(data);
      } else {
        if (filters.search) {
          inputValue = filters.search;
        }
        if (inputValue !== prevSearch) {
          setPrevSearch(inputValue);
        } else {
          return callback(prevSearchData);
        }
        if (date) {
          filters.startDate = moment(date).format('YYYY-MM-DD 00:00:00')
        }
        const search = await EmployeeService.searchMyEmployees(
          inputValue,
          true,
          filters
        );
        const data = prepareResults(search.data);
        setPrevSearchData(data);
        return callback(data);
      }
    }, 300);
  };

  const prepareResults = (results) => {
    const preparedResults = [];

    results &&
      results.forEach((result) => {
        preparedResults.push({
          value: result.id,
          label: result.name,
          ...result,
        });
      });

    return preparedResults;
  };
  // const setSectionsBasedOnJob = (jobId) => {
  //   if(scheduleConfigs && scheduleConfigs.sections && jobId && scheduleConfigs.sections[jobId]) {
  //     const sections = scheduleConfigs.sections[jobId];
  //     setSections(sections);
  //     let section = null;
  //     if(event && event.sectionId) {
  //       section = sections.find(s => s.value === event.sectionId) || null
  //     }
  //     setSection(section);
  //   } else {
  //     setSections([]);
  //     setSection(null);
  //   }
  // }
  const getMyJobHistory = async () => {
    const name = `${user.firstName} ${user.lastName}`;
    const jobHistory = await EmployeeService.getMyJobHistory();
    let jobs = [];
    if (jobHistory.data && jobHistory.data.length) {
      jobs = jobHistory.data;
    }
    const locations = [
      { name: user.locationDescription, value: user.locationId },
    ];
    setResourceId({
      value: user.id,
      label: name,
      employee_code: user.employeeCode,
      first_name: user.firstName,
      id: user.id,
      job: user.jobTitle,
      job_id: user.jobId,
      jobs,
      last_name: user.lastName,
      location: user.locationDescription,
      location_code: user.locationCode,
      location_id: user.locationId,
      locations,
      name,
      secondary_job: user.secondaryJob,
      secondary_job_id: user.secondaryJobId,
      salary_hourly: user.salaryHourly,
      hourly_rate: user.hourlyRate,
    });
    setLocations(locations);
    setJobs(jobs);
    if (event) {
      setLocation(
        locations.find((l) => l.value === event.locationId) || locations[0]
      );
      const job = jobs.find((j) => j.value === event.jobId) || jobs[0];
      setJob(job);
      //setSectionsBasedOnJob(job.value)
    } else {
      setLocationByEmployee(locations);
      const job = jobs.find((j) => j.value === user.jobId) || jobs[0];
      setJob(job);
      //setSectionsBasedOnJob(job.value);
    }
  };
  const setLocationByEmployee = locations => {
    let selectedLocation = locations[0];
    let loc = undefined;
    if (user.allLocations) {
      const userLocationIds = user.allLocations.map(l => l.locationId.toString());
      loc = locations.find(l => userLocationIds.includes(l.value.toString()));
    } else if (user.locationId) {
      loc = locations.find(l => user.locationId.toString() === l.value.toString());
    }
    if (loc) {
      selectedLocation = loc;
    }
    setLocation(selectedLocation);
  };

  useEffect(() => {
    if (
      scheduleConfigs &&
      scheduleConfigs.sections &&
      job &&
      job.value &&
      (scheduleConfigs.sections[job.value] || scheduleConfigs.sections['all'])
    ) {
      const jobSections = scheduleConfigs.sections[job.value] || [];
      const allSections = scheduleConfigs.sections['all'] || [];
      const sections = [...jobSections, ...allSections];
      setSections(sections);
      let section = [];
      if (event && event.sectionId) {
        if (!Array.isArray(event.sectionId)) {
          event.sectionId = [event.sectionId];
        }
        section = sections.filter((s) => event.sectionId.includes(s.value)) || [];
      }
      if (event && event.sectionData) {
        if (typeof event.sectionData === 'string') {
          event.sectionData = JSON.parse(event.sectionData)
        }
        dispatchSectionData({
          type: 'set',
          value: (event.sectionData || []).map(z => {
            return {
              color: z.zoneColor,
              endTime: z.endTime,
              id: z.zoneId,
              startTime: z.startTime,
              text: z.zone,
              value: z.zoneId,
            }
          })
        });

      } else {
        dispatchSectionData({
          type: 'set',
          value: []
        });
      }
      setSection(section);
    } else {
      setSections([]);
      setSection(null);
    }
  }, [job, event]);
  useEffect(() => {
    if (self && user) {
      getMyJobHistory();
    }
  }, [self, user]);

  const isDayOff =
    name && name.value === SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF;

  useEffect(() => {
    if (isDayOff) {
      setPrevEndTime(eventEndTime);
      setPrevStartTime(eventStartTime);
      setEventStartTime(moment(date).startOf("day"));
      setEventEndTime(moment(date).endOf("day"));
    } else if (prevEndTime && prevStartTime) {
      setEventStartTime(prevStartTime);
      setEventEndTime(prevEndTime);
      setPrevEndTime(null);
      setPrevStartTime(null);
    }
  }, [name, isDayOff]);

  useEffect(() => {
    if (
      event ||
      initialBreaksLoaded ||
      !resourceId ||
      Object.keys(resourceId).length === 0
    )
      return;

    if (
      eventStartTime &&
      eventEndTime &&
      name &&
      name.value &&
      resourceId &&
      resourceId.value
    ) {
      generateBreaks({
        eventStartTime,
        eventEndTime,
        name,
        employee: resourceId,
        dispatchBreak,
      });
      setInitialBreaksLoaded(true);
    }
  }, [
    event,
    eventStartTime,
    eventEndTime,
    name,
    initialBreaksLoaded,
    resourceId,
  ]);

  // check for changes
  useEffect(() => {
    if (!event) return;
    if (
      (location && location.value !== event.locationId) ||
      (job && job.value !== event.jobId) ||
      (name && name.value !== event.name) ||
      (event.notes !== notes) ||
      (section && event.sectionId !== section.value) || // TODO CHECK SECTION
      (eventStartTime && !eventStartTime.isSame(moment(event.startDate))) ||
      (eventEndTime && !eventEndTime.isSame(moment(event.endDate))) ||
      compareBreaks(
        Object.values(breaks),
        JSON.parse(event.breakData),
        moment(event.startDate).format(MYSQL_DATETIME_FORMAT) + ":00"
      )
    )
      setChanged(true);
    else setChanged(false);
  }, [
    location,
    job,
    name,
    notes,
    section,
    eventStartTime,
    eventEndTime,
    breaks,
    event,
  ]);

  const nameOptions = useMemo(() => {
    return isManager(user)
      ? self || preview
        ? [...managerNameOptions, ...employeeNameOptionsNew]
        : managerNameOptions
      : self
        ? event && event.name !== SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY
          ? [...managerNameOptions, ...employeeNameOptionsNew]
          : employeeNameOptionsNew
        : [];
  }, [user, self]);

  const findOption = (value, options) => {
    const element = options.find((o) => o.value === value);
    return element ? { value, name: element.name } : {};
  };

  useEffect(() => {
    if (user) {
      if (event) {
        event.name && setName(findOption(event.name, nameOptions));
        event.notes && setNotes(event.notes);
        event.breakData &&
          dispatchBreak({
            type: "set",
            value: Object.assign(
              {},
              JSON.parse(event.breakData).map(({ start, end, duration }) => ({
                start: moment(
                  moment(event.start).format("YYYY-MM-DD") + " " + start + ":00"
                ),
                end: moment(
                  moment(event.start).format("YYYY-MM-DD") + " " + end + ":00"
                ),
                duration,
              }))
            ),
          });
        event.start && setEventStartTime(moment(event.start));
        event.end && setEventEndTime(moment(event.end));
        setInitEditEvent(true);
      } else {
        if (user && isManager(user))
          setName({
            value: SCHEDULE_MAPPED_EVENTS.REGULAR,
            name: SCHEDULE_MAPPED_EVENTS.REGULAR,
          });
        else if (user)
          setName({
            value: SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY,
            name: SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY,
          });

        setEventStartTime(moment(date).startOf("day").add(9, "hour"));
        setEventEndTime(
          moment(date)
            .startOf("day")
            .add(OT.isOnCanada(user.locationCode) ? 18 : 17, "h", "hour")
        );
      }
    }
  }, [event, user]);

  const onChangeStartTime = (newStartTime) => {
    const difference = getMinuteDifference(newStartTime, eventStartTime);

    let newEndTime = moment(eventEndTime).add(difference, "minutes");
    if (newEndTime.isAfter(newStartTime, "day"))
      newEndTime = moment(newStartTime).endOf("day");
    if (newEndTime.diff(newStartTime, 'minutes') >= 60) {
      setEventStartTime(newStartTime);
      setEventEndTime(newEndTime);

      if (Object.values(breaks).length > 0)
        dispatchBreak({
          type: "event time change",
          caller: "start",
          difference,
          prevStartTime: eventStartTime,
          newStartTime,
          newEndTime,
        });
      if (Array.isArray(sectionData) && sectionData.length > 0)
        dispatchSectionData({
          type: "event time change",
          caller: "start",
          prevStartTime: eventStartTime,
          newStartTime,
          newEndTime,
        });
      setTimeError("");
    } else {
      setTimeError("Event should be at least one hour long");
    }
  };

  const onChangeEndTime = (newEndTime) => {
    if (newEndTime.isAfter(eventStartTime) && newEndTime.diff(eventStartTime, 'minutes') >= 60) {
      const difference = moment
        .duration(
          moment(newEndTime)
            .endOf("minute")
            .diff(moment(eventEndTime).endOf("minute"))
        )
        .asMinutes();
      generateBreaks({
        eventStartTime,
        eventEndTime: newEndTime,
        name,
        employee: resourceId,
        dispatchBreak,
      });
      setEventEndTime(newEndTime);

      if (Object.values(breaks).length > 0)
        dispatchBreak({
          type: "event time change",
          caller: "end",
          difference,
          newEndTime,
        });

      if (Array.isArray(sectionData) && sectionData.length > 0)
        dispatchSectionData({
          type: "event time change",
          caller: "end",
          prevStartTime: eventStartTime,
          newEndTime,
        });
      setTimeError('');
    } else {
      if (newEndTime.isBefore(eventStartTime)) {
        setTimeError("End time can't be before start time");
      } else {
        setTimeError("Event should be at least one hour long");
      }
    }
  };

  const onChangeNotes = ({ target: { value } }) => setNotes(value);

  const resetToInitial = () => {
    dispatchBreak({ type: "delete all" });
    setLoading(false);
    setName(
      isManager(user)
        ? {
          name: SCHEDULE_MAPPED_EVENTS.REGULAR,
          value: SCHEDULE_MAPPED_EVENTS.REGULAR,
        }
        : {
          name: SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY,
          value: SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY,
        }
    );
    setNotes("");
    if (!self) {
      setResourceId(null);
      setLocation(null);
      setJob(null);
      //setSectionsBasedOnJob(null);
    }
    /*setEventStartTime(
            moment(date)
                .startOf("day")
                .add(9, "hours")
        );
        setEventEndTime(
            moment(date)
                .startOf("day")
                .add(17, "hours")
        );*/
    setPrevStartTime(null);
    setPrevEndTime(null);
    setError("");
    setTimeError("");
    onRequestClose();
  };

  const onSubmit = async () => {
    const newEvent = {
      id: "generated_",
      resourceId: 0,
      name: "",
      startDate: null,
      endDate: null,
      start: null,
      end: null,
      currentDate: null,
      breaks: 0,
      break1StartTime: null,
      break1EndTime: null,
      break2: null,
      break2StartTime: null,
      break2EndTime: null,
      break3: null,
      break3StartTime: null,
      break3EndTime: null,
      break4: null,
      break4StartTime: null,
      break4EndTime: null,
      locationId: null,
      jobId: null,
      jobName: "",
      notes: "",
      employeeCode: "",
      firstName: "",
      lastName: "",
      leaveId: 0,
      sectionId: [],
      sectionData: [],
      locationCode: "",
      locationName: "",
      oldUserId: 0,
      primaryJobId: "",
      salaryHourly: "",
      secondaryJob: "",
      secondaryJobId: null,
      status: "",
      hourlyRate: 0,
      isUnavailable: 0,
    };
    if (event && edit) {
      newEvent.id = event.id;
      newEvent.resourceId = event.resourceId;
      newEvent.name = event.name;
      newEvent.startDate = moment(event.start).toDate();
      newEvent.endDate = moment(event.end).toDate();
      newEvent.start = newEvent.startDate;
      newEvent.end = newEvent.endDate;
      newEvent.currentDate = moment(event.start).toDate();
      newEvent.locationId = event.locationId;
      newEvent.jobId = event.jobId;
      newEvent.jobName = event.jobName;
      newEvent.notes = event.notes;
      newEvent.employeeCode = event.employeeCode;
      newEvent.firstName = event.firstName;
      newEvent.lastName = event.lastName;
      newEvent.leaveId = event.leaveId;
      newEvent.sectionId = event.sectionId;
      newEvent.sectionData = event.sectionData;
      newEvent.locationCode = event.locationCode;
      newEvent.locationName = event.locationName;
      newEvent.oldUserId = event.oldUserId;
      newEvent.primaryJobId = event.primaryJobId;
      newEvent.salaryHourly = event.salaryHourly;
      newEvent.hourlyRate = event.hourlyRate;
      newEvent.secondaryJob = event.secondaryJob;
      newEvent.secondaryJobId = event.secondaryJobId;
      newEvent.status = event.status;
    }
    const originalData = JSON.parse(JSON.stringify(newEvent));
    if (event && edit) {
      originalData.breaks = event.breaks;
      originalData.break1 = event.break1;
      originalData.break1StartTime = event.break1StartTime;
      originalData.break1EndTime = event.break1EndTime;
      originalData.break2 = event.break2;
      originalData.break2StartTime = event.break2StartTime;
      originalData.break2EndTime = event.break2EndTime;
      originalData.break3 = event.break3;
      originalData.break3StartTime = event.break3StartTime;
      originalData.break3EndTime = event.break3EndTime;
      originalData.break4 = event.break4;
      originalData.break4StartTime = event.break4StartTime;
      originalData.break4EndTime = event.break4EndTime;
    }

    let check = true;
    if (!resourceId || !resourceId.value) check = "Please select an employee";
    else if (!location || !location.value) check = "Please select a location";
    else if (!job || !job.value) check = "Please select a job";
    else if (eventStartTime.isSameOrAfter(eventEndTime))
      check = "Start time must be before end time";
    else if (name.value === "Regular") {
      for (let i = 0; i < Object.values(breaks).length; i++) {
        const { start, end, duration } = Object.values(breaks)[i];
        const isValid = validateBreakTimes(breaks, i, start, end);
        if (isValid !== true) {
          check = isValid;
          break;
        }
        newEvent.breaks += duration;
        newEvent[`break${i + 1}`] = duration;
        newEvent[`break${i + 1}StartTime`] = moment(start).format("HH:mm");
        newEvent[`break${i + 1}EndTime`] = moment(end).format("HH:mm");
      }

      if (check === true) {
        let resource = resourceId;

        resource = {
          ...resource,
          locationCode: resource.locationCode || resource.location_code,
          salaryHourly: resource.salaryHourly || resource.salary_hourly,
        };

        if (
          resource &&
          (OT.isOnCalifornia(resource.locationCode) ||
            OT.isOnCanada(resource.locationCode) ||
            OT.isOnMasachutsess(resource.locationCode)) &&
          resource.salaryHourly === "H"
        ) {
          let h = 4;
          let m = 30;
          let length = "4.5";
          if (OT.isOnMasachutsess(resource.locationCode)) {
            h = 5;
            m = 0;
            length = "5";
          }

          const breakHelper = new BreaksClass();
          const areBreaksValid = breakHelper.validBreaks(
            {
              ...newEvent,
              startDate: moment(eventStartTime).toDate(),
              endDate: moment(eventEndTime).toDate(),
              currentDate: moment(date).toDate(),
            },
            h,
            m
          );

          if (!areBreaksValid) {
            const allow = await AsyncAlert.alert(
              `Save schedule`,
              `This employee was scheduled more than ${length} hrs without any break. Do you want to continue?`
            );

            if (!allow) return;
          }
        }
      }
    } else {
      for (let i = 1; i < 5; i++) {
        newEvent.breaks = 0;
        newEvent[`break${i}`] = null;
        newEvent[`break${i}StartTime`] = null;
        newEvent[`break${i}EndTime`] = null;
      }
    }
    if (check !== true) setError(check);
    else {
      setLoading(true);
      newEvent.name = name.value;
      newEvent.resourceId = resourceId.value;
      newEvent.notes =
        [SCHEDULE_MAPPED_EVENTS.OFFSITE, SCHEDULE_MAPPED_EVENTS.OTHER].includes(name.value) ? notes : null;
      newEvent.startDate = moment(eventStartTime).toDate();
      newEvent.endDate = moment(eventEndTime).toDate();
      newEvent.start = newEvent.startDate;
      newEvent.end = newEvent.endDate;
      newEvent.currentDate = moment(date).toDate();
      newEvent.locationId = location.value;
      newEvent.jobId = job.value;
      newEvent.employeeCode = resourceId.employee_code;
      newEvent.jobName = resourceId.job;
      newEvent.firstName = resourceId.first_name;
      newEvent.lastName = resourceId.last_name;
      newEvent.locationCode = resourceId.location_code;
      newEvent.locationName = resourceId.location;
      newEvent.primaryJobId = resourceId.job_id;
      newEvent.salaryHourly = resourceId.salary_hourly;
      newEvent.hourlyRate = resourceId.hourly_rate;
      newEvent.secondaryJob = resourceId.secondary_job;
      newEvent.secondaryJobId = resourceId.secondary_job_id;
      newEvent.sectionId = section && Array.isArray(section) ? section.map(s =>s.value) : []; // TODO CHECK SECTIONS
      const eventDate = moment(newEvent.currentDat).format('YYYY-MM-DD');
      newEvent.sectionData = sectionData.map(s => {
        return {
          zone: s.text,
          zoneId: s.value,
          zoneColor: s.color,
          startTime: s.startTime,
          startTimeDisplay: moment(`${eventDate} ${s.startTime}`).format('hh:mm A'),
          endTime: s.endTime,
          endTimeDisplay: moment(`${eventDate} ${s.endTime}`).format('hh:mm A')
        }
      })
      onScheduleSave({ eventRecord: { data: newEvent, originalData } })
        .then(() => {
          setLoading(false);
          setError("");
          resetToInitial();
        })
        .catch((msg) => {
          setLoading(false);
          setError(msg);
        });
    }
  };

  const onDelete = () => {
    if (event) {
      onScheduleDelete(
        {
          eventRecord: {
            data: event,
          },
        },
        [event]
      );
    }
    resetToInitial();
  };

  const canEdit =
    !readOnly &&
    ((user.role === "employee" &&
      self &&
      (!event || event.name === SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY)) ||
      isManager(user));

  const canSelectEmployee = user.role === "manager" && !self;
  const saveDisabled = event && !changed;

  return (
    <Modal
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          minWidth: "35vw",
          overflow: "",
        },
        overlay: {
          backgroundColor: "rgba(0,0,0,0.6)",
        },
      }}
      isOpen={isOpen}
    >
      <div className="add-event">
        <div className="header">
          <span>{readOnly ? "View" : edit ? "Edit" : "Add"} Event</span>
          <button className="close-btn" onClick={resetToInitial}>
            <i className="fas fa-times" />
          </button>
        </div>

        <div className="wrapper">
          {/* date */}
          <div>
            <span className="label">Date</span>
            <div className="value-icon">
              <div className="value">
                {!!event && eventStartTime
                  ? eventStartTime.format("MM/DD/YYYY")
                  : date.format("MM/DD/YYYY")}
              </div>
              <i className="fas fa-calendar" />
            </div>
          </div>

          {/* name */}
          <div>
            <span className="label">Name</span>
            {name && (
              <Select
                isDisabled={!canEdit}
                style={{ width: "300px" }}
                onChange={setName}
                value={name || {}}
                options={nameOptions}
                getOptionLabel={(option) => option.name}
              />
            )}
          </div>

          {/* notes */}
          {name && ["Offsite", "Other"].includes(name.value) && (
            <div>
              <span className="label">Notes</span>
              <textarea
                disabled={!canEdit}
                onChange={onChangeNotes}
                value={notes || ""}
              />
            </div>
          )}

          <React.Fragment>
            {/* employee */}
            {canSelectEmployee && (
              <div>
                <span className="label">Employee</span>

                <AsyncSelect
                  isDisabled={!canEdit}
                  isMulti={false}
                  value={resourceId || {}}
                  isClearable={true}
                  cacheOptions
                  style={{ width: "300px" }}
                  loadOptions={loadOptions}
                  defaultOptions
                  onChange={(newValue) => {
                    setResourceId(newValue);
                    setInitialBreaksLoaded(false);
                    dispatchBreak({ type: "delete all" });
                    if (newValue) {
                      if (newValue.locations && newValue.locations.length) {
                        setLocations(newValue.locations);
                        setLocationByEmployee(newValue.locations)
                      } else {
                        setLocations([]);
                        setLocation(null);
                      }
                      if (newValue.jobs && newValue.jobs.length) {
                        setJobs(newValue.jobs);
                        setJob(newValue.jobs[0]);
                        //setSectionsBasedOnJob(newValue.jobs[0].value);
                      } else {
                        setJobs([]);
                        setJob(null);
                        //setSectionsBasedOnJob(null);
                      }
                      if (newValue.rates) {
                        setEmployeeRates(newValue.rates);
                      }
                    } else {
                      setLocations([]);
                      setJobs([]);
                      setJob(null);
                      setLocation(null);
                      //setSectionsBasedOnJob(null);
                    }
                  }}
                />
              </div>
            )}

            {/* location */}
            <div>
              <span className="label">Location</span>
              <Select
                isMulti={false}
                isDisabled={self || !canEdit || locations.length < 2}
                getOptionLabel={(option) => option.name}
                isClearable={true}
                style={{ width: "300px" }}
                onChange={setLocation}
                value={location || {}}
                options={locations}
              />
            </div>

            {/* job */}
            <div>
              <span className="label">Job</span>
              <Select
                isMulti={false}
                isDisabled={!canEdit || jobs.length < 2}
                getOptionLabel={(option) => option.name}
                isClearable={true}
                style={{ width: "300px" }}
                onChange={(value) => {
                  setJob(value);
                  if (value && value.value) {
                    //setSectionsBasedOnJob(value.value)
                  } else {
                    //setSectionsBasedOnJob(null);
                  }
                }}
                value={job || {}}
                options={jobs}
              />
            </div>
          </React.Fragment>

          {/* start/end time */}
          <div className="time-inputs">
            <div>
              <span className="label">Start Time</span>
              <TimeInput
                type="start time"
                disabled={
                  !name ||
                  name.value === SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF ||
                  !canEdit
                }
                value={eventStartTime}
                onChange={onChangeStartTime}
              />
            </div>

            <div>
              <span className="label">End Time</span>
              <TimeInput
                type="end time"
                disabled={
                  !name ||
                  name.value === SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF ||
                  !canEdit
                }
                value={eventEndTime}
                onChange={onChangeEndTime}
              />
            </div>

            <div className="time-error">{timeError || ""}</div>
          </div>
          {/* section */}
          <div className={sections.length === 0 ? "hidden" : ""}>
            <span className="label">Zone</span>
            <Select
              isMulti={true}
              isDisabled={!canEdit}
              getOptionLabel={(option) => option.text}
              isClearable={true}
              style={{ width: "300px" }}
              onChange={(val) => {
                setSection(val);
                const ids = val && Array.isArray(val) ? val.map(v => v.value) : [];
                dispatchSectionData({
                  type: 'set',
                  value: [...sectionData, ...(val && Array.isArray(val) ? val.filter(r => sectionData.filter(z => z.value === r.value).length === 0).map(r => {
                    r.startTime = moment.isMoment(eventStartTime) ? moment(eventStartTime).format('HH:mm') : eventStartTime;
                    r.endTime = moment.isMoment(eventEndTime) ? moment(eventEndTime).format('HH:mm') : eventEndTime;
                    return r;
                  }) : [])].filter(r => ids.includes(r.value))
                });
              }}
              value={section || []}
              options={sections}
            />
          </div>
          {canEdit && Array.isArray(sectionData) && sectionData.length > 0 ? (
            <div className="breaks">
              {
                sectionData.map((s, k) => <Zone
                  item={s}
                  setError={setError}
                  index={parseInt(k)}
                  eventStartTime={eventStartTime}
                  eventEndTime={eventEndTime}
                  dispatchSectionData={dispatchSectionData}
                  key={k}
                />)}
            </div>) : null}
          {/* breaks */}
          {canEdit &&
            name &&
            name.value === "Regular" &&
            Object.keys(breaks).length < 4 && (
              <button
                className="add-break-btn"
                onClick={() => dispatchBreak({ type: "new" })}
              >
                <i style={{ marginRight: 5 }} className="fas fa-plus-circle" />
                ADD BREAK
              </button>
            )}

          {canEdit &&
            name &&
            name.value === "Regular" &&
            Object.keys(breaks).length > 0 && (
              <div className="breaks">
                {Object.keys(breaks).map((k) => (
                  <Break
                    breaks={breaks}
                    setError={setError}
                    index={parseInt(k)}
                    eventStartTime={eventStartTime}
                    eventEndTime={eventEndTime}
                    dispatchBreak={dispatchBreak}
                    breakItem={breaks[k]}
                    key={k}
                  />
                ))}
              </div>
            )}
          <div className="break-error">{error}</div>
        </div>

        {/* actions */}
        {canEdit && (
          <div className="buttons">
            <button
              onClick={onSubmit}
              disabled={loading || saveDisabled}
              className="btn btn-primary"
            >
              {loading ? "Saving..." : "Save"}
            </button>
            {edit && (
              <button
                onClick={onDelete}
                disabled={loading}
                className="btn btn-danger"
              >
                Delete
              </button>
            )}
            <button
              onClick={resetToInitial}
              className="btn btn-default"
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddEvent;

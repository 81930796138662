import React, { Component } from "react";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import Chart from "react-apexcharts";
import "./assets/Styles/style.css";
import { startDateAndEndDateAreValid } from "../../components/BryntumScheduler/Configs";
import DateRange from "./DateRange";
import ReportService from "../../services/ReportService";
import filterService from "../../services/FilterService";
import { getWeekConfig } from "../../components/Schedule/helpers/DateHelper";

const SCHEDULE_MODES = {
  TODAY: "today",
  THIS_WEEK: "this_week",
  THIS_MONTH: "this_month",
  DATE_RANGE: "date_range"
};

class Forecasting extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      mode: SCHEDULE_MODES.TODAY,
      config: {
        startDate: moment()
          .startOf("day")
          .toDate(),
        endDate: moment()
          .endOf("day")
          .toDate()
      },
      categories: [],
      isLoading: true,
      user,
      locations: [],
      selectedLocationId: null,
      selectedDepartmentId: 13,
      departments: []
    };
  }

  fetchData = async () => {
    const {
      selectedLocationId,
      selectedDepartmentId,
      config: { startDate, endDate },
      mode
    } = this.state;

    const sDate = moment.isMoment(startDate)
      ? startDate.format("YYYY-MM-DD")
      : moment(startDate).format("YYYY-MM-DD");
    const eDate = moment.isMoment(endDate)
      ? endDate.format("YYYY-MM-DD")
      : moment(endDate).format("YYYY-MM-DD");

    const data = await ReportService.forecastData({
      locationId: selectedLocationId,
      departmentId: selectedDepartmentId,
      startDate: sDate,
      endDate: eDate,
      mode
    });

    const formattedStartDate = moment.isMoment(startDate)
      ? startDate.format("MM-DD-YYYY")
      : moment(startDate).format("MM-DD-YYYY");
    const formattedEndDate = moment.isMoment(endDate)
      ? endDate.format("MM-DD-YYYY")
      : moment(endDate).format("MM-DD-YYYY");

    this.props.history.replace({
      search: `?startDate=${formattedStartDate}${
        mode === SCHEDULE_MODES.DATE_RANGE ? `&endDate=${formattedEndDate}` : ""
        }&locationId=${selectedLocationId ||
        ""}&departmentId=${selectedDepartmentId || ""}&mode=${mode}`
    });

    this.setState({ data: data.data, isLoading: false }, this.getCategories);
  };

  getLocations = () => {
    filterService.getFilterBy(true, true).then(response => {
      this.setState(
        {
          locations: response.data.locations || [],
          departments: response.data.departments || []
        },
        this.parseQuery
      );
    });
  };

  parseQuery = () => {
    const { locations, departments, user } = this.state;

    const urlParams = new URLSearchParams(window.location.search);
    const queryMode = urlParams.get("mode");
    let startDate = urlParams.get("startDate");
    let endDate = urlParams.get("endDate");
    const locationId = parseInt(urlParams.get("locationId"));
    let departmentId = parseInt(urlParams.get("departmentId"));
    let foundDefaultDepartment = false;
    if (departments.length === 1) {
      departmentId = departments[0].value;
    } else if (departments.length > 1) {
      foundDefaultDepartment = !!departments.find(l => l.value === 13);
    }

    const modeIsValid =
      queryMode && Object.values(SCHEDULE_MODES).indexOf(queryMode) !== -1;

    startDate = moment(startDate);
    endDate = moment(endDate);
    startDate = startDate.isValid() ? startDate : moment();
    endDate = endDate.isValid() ? endDate : moment();

    let newState = {
      config: {}
    };

    newState.mode = modeIsValid ? queryMode : SCHEDULE_MODES.TODAY;
    newState.selectedLocationId =
      !isNaN(locationId) && locations.find(l => l.value === locationId)
        ? locationId
        : "";
    newState.selectedDepartmentId =
      !isNaN(departmentId) && departments.find(l => l.value === departmentId)
        ? departmentId
        : departmentId !== undefined && foundDefaultDepartment
          ? 13
          : "";

    const { mode } = newState;
    const config = {};

    switch (mode) {
      case SCHEDULE_MODES.TODAY:
        config.startDate = startDate.startOf("day").toDate();
        config.endDate = startDate.endOf("day").toDate();
        break;

      case SCHEDULE_MODES.THIS_WEEK:
        config.startDate = startDate.startOf(getWeekConfig(user)).toDate();
        config.endDate = startDate.endOf(getWeekConfig(user)).toDate();
        break;

      case SCHEDULE_MODES.THIS_MONTH:
        config.startDate = startDate.startOf("month").toDate();
        config.endDate = startDate.endOf("month").toDate();
        break;

      case SCHEDULE_MODES.DATE_RANGE:
        if (endDate <= startDate) newState.mode = SCHEDULE_MODES.TODAY;
        config.startDate = startDate.startOf("day");
        config.endDate = endDate.endOf("day");
        break;
      default:
        break;
    }

    newState.config = config;

    this.setState(newState, this.fetchData);
  };

  componentDidMount() {
    this.getLocations();
  }

  onModeChange = mode => {
    const { user } = this.state;
    let newState = { mode, isLoading: mode !== SCHEDULE_MODES.DATE_RANGE };

    if (mode === SCHEDULE_MODES.DATE_RANGE)
      newState.config = { startDate: null, endDate: null };

    this.setState(newState, () => {
      if (mode === SCHEDULE_MODES.DATE_RANGE) return;

      let startDate, endDate;

      switch (mode) {
        case SCHEDULE_MODES.TODAY:
          startDate = moment()
            .startOf("day")
            .toDate();
          endDate = moment()
            .endOf("day")
            .toDate();

          break;
        case SCHEDULE_MODES.THIS_WEEK:
          startDate = moment()
            .startOf(getWeekConfig(user))
            .toDate();
          endDate = moment()
            .endOf(getWeekConfig(user))
            .toDate();
          break;

        case SCHEDULE_MODES.THIS_MONTH:
          startDate = moment()
            .startOf("month")
            .toDate();
          endDate = moment()
            .endOf("month")
            .toDate();
          break;
        default:
          break;
      }

      const config = {
        startDate,
        endDate
      };

      this.setState({ mode, config }, this.fetchData);
    });
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState(
      {
        isLoading: startDate && endDate,
        config: {
          startDate,
          endDate: endDate || null
        }
      },
      () => {
        if (endDate && startDate) {
          this.fetchData();
        }
      }
    );
  };

  onDateChange = (date, addAmount) => {
    const { mode } = this.state;

    this.setState({ isLoading: true }, () => {
      let dateMode;

      switch (mode) {
        case SCHEDULE_MODES.TODAY:
          dateMode = "day";
          break;

        case SCHEDULE_MODES.THIS_WEEK:
          dateMode = "week";
          break;

        case SCHEDULE_MODES.THIS_MONTH:
          dateMode = "month";
          break;
        default:
          break;
      }

      let startDate, endDate;

      if (addAmount) {
        startDate = date.startDate
          .add(addAmount, dateMode)
          .startOf("day")
          .toDate();
        endDate = date.endDate
          .add(addAmount, dateMode)
          .endOf("day")
          .toDate();
      } else {
        startDate = date.startOf(dateMode).toDate();
        endDate = date.endOf(dateMode).toDate();
      }

      this.setState(
        {
          config: {
            endDate,
            startDate
          }
        },
        this.fetchData
      );
    });
  };

  filterByLocation = e => {
    const selectedLocationId = e.target.value;

    this.setState(
      {
        isLoading: true,
        selectedLocationId
      },
      this.fetchData
    );
  };

  filterByDepartment = e => {
    const selectedDepartmentId = e.target.value;

    this.setState(
      {
        isLoading: true,
        selectedDepartmentId
      },
      this.fetchData
    );
  };

  getCategories = () => {
    const categories = [];
    const tickAmount = this.getTickAmount();

    const {
      mode,
      config: { startDate }
    } = this.state;

    const start = moment(startDate);

    for (let i = 0; i < tickAmount; i++) {
      switch (mode) {
        case SCHEDULE_MODES.TODAY:
          categories.push(start.add(i === 0 ? 0 : 1, "hour").format("h A"));
          break;

        default:
          categories.push(start.add(i === 0 ? 0 : 1, "day").format("D MMM"));
      }
    }

    this.setState({ categories });
  };

  getTickAmount = () => {
    const { config, mode } = this.state;

    let tickAmount;

    switch (mode) {
      case SCHEDULE_MODES.TODAY:
        tickAmount = 24;
        break;

      case SCHEDULE_MODES.THIS_WEEK:
        tickAmount = 7;
        break;

      case SCHEDULE_MODES.THIS_MONTH:
        tickAmount = moment(config.startDate).daysInMonth();
        break;

      case SCHEDULE_MODES.DATE_RANGE:
        const start = moment(config.startDate);
        const end = moment(config.endDate);

        tickAmount = end.diff(start, "days") + 1;
        break;
      default:
        break;
    }

    return tickAmount;
  };

  getOptions = () => {
    const {
      mode,
      categories,
      config: { startDate, endDate },
      data
    } = this.state;

    if (!data)
      return {
        schOptions: {},
        chkOptions: {},
        clsOptions: {},
        cmbOptions: {}
      };

    const { checkins, schedules, classes } = data;

    const rotateAlways =
      mode === SCHEDULE_MODES.THIS_MONTH ||
      (mode === SCHEDULE_MODES.DATE_RANGE &&
        moment(endDate).diff(moment(startDate), "days") + 1 > 20);

    const hasNonZeroValues = series => series.reduce((t, v) => t + v, 0) > 0;

    const hasSchedules = hasNonZeroValues(schedules);
    const hasCheckins = hasNonZeroValues(checkins);
    const hasClasses = hasNonZeroValues(classes);

    const options = {
      chart: {
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        lineCap: "square"
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8
        }
      },
      legend: {
        offsetY: -10,
        useSeriesColors: true,
        itemMargin: {
          vertical: 20
        }
      },
      xaxis: {
        categories,
        axisTicks: {
          height: 3
        },
        labels: {
          rotateAlways,
          trim: false,
          minHeight: 40
        }
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
        labels: {
          formatter: v => Math.round(v).toLocaleString()
        }
      },
      tooltip: {
        y: {
          formatter: v => v.toLocaleString()
        }
      }
    };

    const schOptions = {
      ...JSON.parse(JSON.stringify(options)),
      colors: ["#027ade"],
      yaxis: {
        ...JSON.parse(JSON.stringify(options.yaxis)),
        tickAmount: hasSchedules ? 3 : 1
      }
    };

    const chkOptions = {
      ...JSON.parse(JSON.stringify(options)),
      colors: ["#61c08d"],
      yaxis: {
        ...JSON.parse(JSON.stringify(options.yaxis)),
        tickAmount: hasCheckins ? 3 : 1
      }
    };

    const clsOptions = {
      ...JSON.parse(JSON.stringify(options)),
      colors: ["#eb3b7f"],
      yaxis: {
        ...JSON.parse(JSON.stringify(options.yaxis)),
        tickAmount: hasClasses ? 3 : 1
      }
    };

    const cmbOptions = {
      ...JSON.parse(JSON.stringify(options)),
      colors: ["#027ade", "#61c08d", "#eb3b7f"],
      fill: {
        type: "solid"
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%"
        }
      },
      stroke: {
        show: false
      },
      yaxis: {
        ...JSON.parse(JSON.stringify(options.yaxis)),
        tickAmount: hasSchedules || hasCheckins || hasClasses ? 3 : 1
      }
    };

    return { schOptions, chkOptions, clsOptions, cmbOptions };
  };

  render() {
    const {
      mode,
      config,
      isLoading,
      locations,
      selectedLocationId,
      departments,
      selectedDepartmentId,
      data = {},
      categories
    } = this.state;
    const { checkins = [], schedules = [], classes = [], totals = {} } = data;

    const {
      schedules: schTotal = 0,
      checkins: chkTotal = 0,
      classes: clsTotal = 0
    } = totals;

    const options = this.getOptions();

    const { schOptions, chkOptions, clsOptions, cmbOptions } = options;

    const combinedOptions = Object.keys(cmbOptions).length
      ? {
        ...JSON.parse(JSON.stringify(cmbOptions)),
        xaxis: {
          ...JSON.parse(JSON.stringify(cmbOptions.xaxis)),
          categories,
          tickPlacement: "between"
        }
      }
      : {};

    return (
      <div className="container-fluid">
        <div className="row row-color forecasting-bar">
          <div>
            <select
              name="locationFilter"
              className="form-control"
              onChange={this.filterByLocation}
              value={selectedLocationId}
            >
              {(locations || []).length > 1 && (
                <option value="">All locations</option>
              )}
              {(locations || []).map((location, key) => (
                <option key={key} value={location.value}>
                  {location.name}
                </option>
              ))}
            </select>

            <select
              name="departmentFilter"
              className="form-control"
              onChange={this.filterByDepartment}
              value={selectedDepartmentId}
            >
              {(departments || []).length > 1 && (
                <option value="">All Department</option>
              )}
              {(departments || []).map((location, key) => (
                <option key={key} value={location.value}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>

          <DateRange
            onDateChange={this.onDateChange}
            onModeChange={this.onModeChange}
            onDatesChange={this.onDatesChange}
            config={config}
            mode={mode}
          />
        </div>

        <div style={{ alignSelf: "center" }} className="m-0">
          <div className="col-12 p-0">
            {isLoading ? (
              <div className={"loader"}>
                <div id="spinner" />
              </div>
            ) : (
                config.startDate &&
                config.endDate &&
                startDateAndEndDateAreValid(config.startDate, config.endDate) && (
                  <div className="container content-area">
                    <div className="widgets">
                      <div
                        className={`widget employees ${
                          !isLoading ? "loaded" : ""
                          }`}
                      >
                        <div className="card-body">
                          <span>Number of scheduled employees</span>
                          <div>
                            <span />
                            <span>{schTotal.toLocaleString()}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`widget schedules ${
                          !isLoading ? "loaded" : ""
                          }`}
                      >
                        <div className="card-body">
                          <span>Total check-ins</span>
                          <div>
                            <span />
                            <span>{chkTotal.toLocaleString()}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`widget classes ${
                          !isLoading ? "loaded" : ""
                          }`}
                      >
                        <div className="card-body">
                          <span>Number of classes</span>
                          <div>
                            <span />
                            <span>{clsTotal.toLocaleString()}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="card chart-wrapper">
                          <div className="card-header">
                            <h4>Scheduled employees</h4>
                          </div>
                          <div className="card-body">
                            <Chart
                              height={250}
                              width={"100%"}
                              type="area"
                              series={[
                                {
                                  name: "Schedules",
                                  data: schedules
                                }
                              ]}
                              options={schOptions}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-12">
                        <div className="card chart-wrapper">
                          <div className="card-header">
                            <h4>Check-ins</h4>
                          </div>
                          <div className="card-body">
                            <Chart
                              height={250}
                              width={"100%"}
                              type="area"
                              series={[
                                {
                                  name: "Check-ins",
                                  data: checkins
                                }
                              ]}
                              options={chkOptions}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="card chart-wrapper">
                          <div className="card-header">
                            <h4>Classes</h4>
                          </div>
                          <div className="card-body">
                            <Chart
                              height={250}
                              width={"100%"}
                              type="area"
                              series={[
                                {
                                  name: "Classes",
                                  data: classes
                                }
                              ]}
                              options={clsOptions}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-12">
                        <div className="card chart-wrapper">
                          <div className="card-header">
                            <h4>Combined</h4>
                          </div>
                          <div className="card-body">
                            <Chart
                              height={250}
                              width={"100%"}
                              series={[
                                {
                                  name: "Scheduled employees",
                                  type: "bar",
                                  data: schedules
                                },
                                {
                                  name: "Checkins",
                                  type: "bar",
                                  data: checkins
                                },
                                {
                                  name: "Classes",
                                  type: "bar",
                                  data: classes
                                }
                              ]}
                              options={combinedOptions}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default Forecasting;

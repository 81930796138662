import React from "react";
import "./assets/styles/Settings.css";
import Tooltip from "../../components/Blocks/Tooltip";
import ClockInService from "../../services/ClockInService";
import DataTable from "react-data-table-component";
import Loader from "../../components/Blocks/Loader";
import { confirmAlert } from "react-confirm-alert";
import ClockInSettingsForm from "../../components/Forms/ClockInSettingsForm";

const renderValueRow = (props, field) => {
  const value = props[field];
  return props.name === "Super User" && !value
    ? "all"
    : Array.isArray(value)
    ? value.length
    : 0;
};

export default class ClockIn extends React.Component {
  state = {
    tree: [],
    totalJobs: 0,
    filterJobs: [],
    locationError: null,
    usersError: null,
    isLoading: true,
    areRulesLoading: true,
    isDataLoading: true,
    rules: [],
    page: 1,
    totalRows: 0,
    perPage: 10,
    editMode: false,
    mapLocations: {},
    mapJobs: {},
    mapDepartments: {},
    selectedRule: null,
    sort: "name",
    sortDirection: "desc",
    search: undefined,
  };

  columns = [
    {
      name: "Actions",
      selector: "action",
      sortable: false,
      minWidth: "130px",
      cell: (props) => (
        <div>
          <Tooltip placement="right" text="Delete">
            <button
              disabled={props.can_delete === 0}
              className="btn btn-danger btn-sm mr-2"
              onClick={() => this.deleteRule(props)}
            >
              <i className="fas fa-trash" />
            </button>
          </Tooltip>
          <Tooltip placement="right" text="Edit">
            <button
              className="btn btn-info btn-sm"
              onClick={() => this.editRule(props)}
            >
              <i className="fas fa-pen" />
            </button>
          </Tooltip>
        </div>
      ),
    },
    {
      name: "Name",
      selector: "name",
      minWidth: "230px",
      sortable: true,
    },
    {
      name: "Locations",
      selector: "locations",
      sortable: true,
      cell: (row) => renderValueRow(row, "locations"),
    },
    {
      name: "Departments",
      selector: "departments",
      sortable: true,
      cell: (row) => renderValueRow(row, "departments"),
    },
  ];

  onFormSubmit = async (fields) => {
    try {
      const {
        checked,
        selectedDepartments,
        name,
        editRuleId = 0,
        editMode = false,
      } = fields;

      // cast all location ids to number since the tree component set them to string
      const locationIds = checked.map((id) => { 
        return parseInt(id, 10); 
      });

      if (!name || selectedDepartments.length === 0) return false;

      if (editMode) {
        if (editRuleId === 0) {
          alert("An error occurred while updating");
          this.resetForm();
          return false;
        }

        const data = {
          id: editRuleId,
          name,
          locations: locationIds,
          departments: selectedDepartments.map((v) => v.value),
        };

        try {
          await ClockInService.updateRule(editRuleId, data);
          const rules = this.state.rules;
          const ind = rules.findIndex(({ id }) => id === data.id);
          if (ind >= 0) {
            let rule = rules[ind];
            const { id, ...otherData } = data;
            rule = {
              ...rule,
              ...otherData,
            };
            rules[ind] = rule;
          } else {
            alert("Rule not found");
            return false;
          }

          this.setState({ rules });
          this.resetForm();
          return true;
        } catch (e) {
          alert("An error occurred during creating rule");
          this.resetForm();
          return false;
        }
      } else {
        const data = {
          name,
          locations: locationIds,
          departments: selectedDepartments.map((v) => v.value),
        };

        try {
          const response = await ClockInService.createRule(data);
          const rules = this.state.rules;
          if (response.data && response.data.id) {
            rules.push({
              id: response.data.id,
              users: 0,
              can_delete: 1,
              ...data,
            });
          } else {
            alert("404, clock-in rule can't be created!");
          }
          this.setState({ isDataLoading: true }, () => {
            this.getRules();
          });
          this.resetForm();
          return true;
        } catch (err) {
          console.error(err);
          alert("An error occurred during creating clock-in rule");
          this.resetForm();
          return false;
        }
      }
    } catch (e) {
      return false;
    }
  };

  resetForm = () => {
    this.setState({ editMode: false, selectedRule: null });
  };

  getRules = () => {
    const { page, perPage, sort, sortDirection, search } = this.state;
    ClockInService.getRules({
      page,
      perPage,
      [`orderBy[${sort}]`]: sortDirection,
      name: search,
    })
      .then((res) => {
        if (res.data) {
          this.setState({
            rules: res.data.result,
            areRulesLoading: false,
            isDataLoading: false,
            totalRows: res.data.totalItems,
          });
        } else {
          alert("404, clock-in rules not found!");
          this.setState({
            areRulesLoading: false,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        // alert("An error occurred during getting access levels");
        this.setState({
          areRulesLoading: false,
        });
      });
  };

  deleteRule = (rule) => {
    confirmAlert({
      title: rule.users > 0 ? "WARNING" : "Delete clock-in rule",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = rule.id;
            ClockInService.deleteRule(id)
              .then(() => {
                this.setState(
                  {
                    isDataLoading: true,
                    editMode: false,
                    selectedRule: null,
                  },
                  this.getRules
                );
              })
              .catch((err) => {
                console.error(err);
                this.resetForm();
                alert("An error occurred during deleting the rule");
              });
          },
        },
        { label: "No" },
      ],
    });
  };

  editRule = (rule) => {
    this.setState({ selectedRule: rule, editMode: true });
  };

  componentDidMount() {
    const user = this.props.user;
    if (user && user.isSuperUser === 1) {
      this.getRules();
    }
  }

  handlePageChange = (page) => {
    this.setState({ page, isDataLoading: true }, this.getRules);
  };

  handlePerRowsChange = (perPage, page) => {
    this.setState({ page, perPage, isDataLoading: true }, this.getRules);
  };

  handleSort = (column, sortDirection) => {
    this.setState(
      { sort: column.select, sortDirection, isDataLoading: true },
      this.getRules
    );
  };

  setSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    this.setState({ search: value.length === 0 ? undefined : value }, () => {
      value.length === 0 &&
        this.setState({ isDataLoading: true, page: 1 }, this.getRules);
    });
  };

  submitSearch = (e) => {
    e && e.preventDefault();
    this.setState({ isDataLoading: true, page: 1 }, this.getRules);
  };

  render() {
    const { areRulesLoading, selectedRule, editMode, rules } = this.state;
    const { user, tree, departments, jobs, employees } = this.props;
    const isAdmin = user && user.isSuperUser === 1;

    return (
      <div className="row">
        <Loader isLoading={areRulesLoading && isAdmin} />
        <div className="card col-lg-4 mx-0 mx-lg-3 mb-4 col-12">
          <div className="card-header">
            <h4>{editMode ? "Update" : "Create new"} rule</h4>
          </div>
          <div className="card-body">
            <ClockInSettingsForm
              locationTree={tree}
              editMode={editMode}
              onFormSubmit={this.onFormSubmit}
              departments={departments}
              jobs={jobs}
              employees={employees}
              selectedRule={selectedRule}
              onCancel={this.resetForm}
            />
          </div>
        </div>
        <div className="card col-lg-7 col-12">
          <div className="card-header">
            <h4>
              Clock in rules {this.state.rules && `(${this.state.totalRows})`}
            </h4>
          </div>
          <div className="card-body">
            <div className="e-table">
              <div className="mb-3">
                <div className="float-right">
                  <form onSubmit={this.submitSearch}>
                    <div className="input-group">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        style={{ width: "250px" }}
                        onChange={this.setSearch}
                        placeholder={"Search by name"}
                      />
                      <div className="input-group-btn">
                        <button className="btn btn-info" type="submit">
                          <i className="fas fa-search" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="table-responsive table-lg">
                <DataTable
                  columns={this.columns}
                  fixedHeader
                  noHeader={true}
                  data={rules}
                  progressPending={this.state.isDataLoading}
                  pagination
                  paginationServer
                  highlightOnHover
                  striped
                  paginationTotalRows={this.state.totalRows}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  onSort={this.handleSort}
                  sortServer
                  customStyles={{
                    pagination: {
                      style: {
                        justifyContent: "flex-start",
                      },
                    },
                  }}
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

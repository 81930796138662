import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";

// Login validations schema
const loginSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .trim()
        .required("Current password is required"),
    newPassword: Yup.string()
        .trim()
        .required('New password is required')
        .test('validation-strength', '', function (value) {
            let message = 'Must contain ';
            if( !value || value.length <6) {
                message += '6 characters, ';
            }
            if(!(/(.*[A-Z].*)$/.test(value))) {
                message += 'one uppercase, ';
            }
            if(!(/(.*[a-z].*)$/.test(value))) {
                message += 'one lowercase, ';
            }
            if(!(/(.*\d.*)$/.test(value))) {
                message += 'one number, ';
            }
            if(!(/(.*[@$!%*?&].*)$/.test(value))) {
                message += 'one special character (@$!%*?&) ';
            }
            if(message !== 'Must contain '){
                return this.createError({ message, path: 'newPassword' })
            }
            return true;
        })
        .min(6, 'Must contain 6 characters')
        .max(20, 'Must contain up to 20 characters'),
    confirmPassword: Yup.string()
        .trim()
        .required("Confirm password is required")
        .test('passwords-match', 'Passwords must match', function(value) {
            return this.parent.newPassword === value;
        })
});


export default class LoginForm extends Component {
    render() {
        return (
            <Formik
                initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: ""
                }}
                validationSchema={loginSchema}
                onSubmit={async (values, {setSubmitting}) => {
                    await this.props.submitChangePassword(values);
                    setSubmitting(false);
                }}
            >
                {({errors, values, touched, isSubmitting, handleChange, handleSubmit, handleBlur}) => (
                    <form id="changePassword" onSubmit={handleSubmit} className="card-body" tabIndex="500">
                        <h5 className="mb-1">Change your default password</h5>
                        <p className="mb-3">This action is required for you to proceed with this app</p>
                        <div className="">
                            <div className="form-group">
                                <label style={{ textAlign: "left", width: '100%'}} htmlFor="oldPassword"> Current password</label>
                                <input
                                    type="password"
                                    name="oldPassword"
                                    id="oldPassword"
                                    autoFocus={true}
                                    className="form-control"
                                    onChange={handleChange}
                                    value={values.oldPassword}
                                    placeholder="Current password"
                                    disabled={isSubmitting}
                                />
                                {errors.oldPassword && touched.oldPassword && (
                                    <div className="input-validation-error">{errors.oldPassword}</div>
                                )}
                            </div>
                            <hr/>
                            <p className="mb-2" style={{ textAlign: "left", fontSize:"11px"}}>
                                Your new password must contain:
                                <ol style={{paddingLeft: "20px"}}>
                                    <li>Minimum 6 characters</li>
                                    <li>Maximum 20 characters</li>
                                    <li>At least one uppercase letter</li>
                                    <li>At least one lowercase letter</li>
                                    <li>At least one number</li>
                                    <li>At least one special character (@#$!%*?&)</li>
                                </ol>
                            </p>
                            <div className="form-group">
                                <label style={{textAlign: "left", width: '100%'}} htmlFor="oldPassword"> New password:</label>
                                <input
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    className="form-control"
                                    onChange={handleChange}
                                    value={values.newPassword}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                />
                                {errors.newPassword && touched.newPassword && (
                                    <div className="input-validation-error">{errors.newPassword}</div>
                                )}
                            </div>

                            <div className="form-group">
                                <label style={{textAlign: "left", width: '100%'}} htmlFor="oldPassword"> Confirm password:</label>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    className="form-control"
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                />
                                {errors.confirmPassword && touched.confirmPassword &&
                                <div className="input-validation-error">{errors.confirmPassword}</div>}
                            </div>

                        </div>
                        <div className="submit mt-3 mb-3">
                            <button type="submit" className="btn btn-info btn-block" disabled={isSubmitting}>
                                {isSubmitting ? "Submitting..." : "Submit"}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

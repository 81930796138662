import Modal from "react-modal";
import ToggleButton from "react-toggle-button";
import React from "react";
import "../SchedulePrint/styles/style.css";
import PropTypes from "prop-types";
import moment from "moment";
import SchedulePdf from "../../containers/SchedulePdf";
import ReactToPrint from "react-to-print";
import customModalStyles from "./customStyle";
import { SCHEDULE_MODES } from "../../containers/Schedule/Constant";

class PrintModal extends React.Component {
  state = {
    blackAndWhite: false,
    shiftDuration: true,
    breakTotal: true,
    breakDetails: true,
    showLocation: true,
    showRole: true,
    showSection: true,
    pageSize: 5,
    pageOrientation: 1,
    printOutScale: 1,
    generate: false,
    language: "en",
    range: "all",
    events: [],
    resources: [],
    preparing: false,
    notes: "",
  };

  print = async () => {
    this.setState({ preparing: true });
    await this.getEvents();
    this.printer.handleClick();
    // this.setState({ preparing: false });
  };

  closeModal = () => {
    this.setState(
      {
        generate: false,
      },
      () => {
        moment.locale("en");
        this.props.closeModal();
      }
    );
  };

  getEvents = async () => {
    const { events, resources } = await this.props.getEvents(this.state.range);
    this.setState({ events, resources });
  };
  componentDidMount() {
    if (this.props.mode && this.props.mode === SCHEDULE_MODES.TODAY) {
      this.setState({
        breakTotal: false,
        breakDetails: false,
        showLocation: false,
        showSection: true,
      });
    }
  }

  render() {
    const { events, resources, preparing } = this.state;
    const { scheduleConfigs } = this.props;
    const jobSections =
      scheduleConfigs && scheduleConfigs.sections
        ? scheduleConfigs.sections
        : {};
    const sections = {};
    Object.values(jobSections).forEach((sec) => {
      sec.forEach((s) => {
        if (!sections[s.value]) {
          sections[s.value] = s.text;
        }
      });
    });
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        style={customModalStyles}
        contentLabel="Print Schedules"
        shouldCloseOnOverlayClick={false}
      >
        <div>
          <div className="modal-header">
            <h4>Print Schedules</h4>
          </div>
          <div className="modal-body custom-height">
            <div className="row">
              <h6>
                Use the filters on the left side of the schedule to customize
                the printout.
              </h6>
            </div>
            <div className="row p-3 bg-gray border-white">
              <div className="print-row">Print in black &amp; white</div>
              <ToggleButton
                thumbStyle={{ borderRadius: 4 }}
                trackStyle={{
                  borderRadius: 4,
                }}
                colors={{
                  activeThumb: {
                    base: "#fff",
                  },
                  active: {
                    base: "#61c08d",
                  },
                }}
                value={this.state.blackAndWhite || false}
                onToggle={(value) => {
                  this.setState({
                    blackAndWhite: !value,
                  });
                }}
              />
            </div>
            <div className="row p-3 bg-gray border-white">
              <div className="print-row">Show Shift Duration</div>
              <div className="pull-right">
                <ToggleButton
                  thumbStyle={{ borderRadius: 4 }}
                  trackStyle={{
                    borderRadius: 4,
                  }}
                  colors={{
                    activeThumb: {
                      base: "#fff",
                    },
                    active: {
                      base: "#61c08d",
                    },
                  }}
                  value={this.state.shiftDuration || false}
                  onToggle={(value) => {
                    this.setState({
                      shiftDuration: !value,
                    });
                  }}
                />
              </div>
            </div>
            {this.props.mode !== SCHEDULE_MODES.TODAY && (
              <div className="row p-3 bg-gray border-white">
                <div className="print-row">Show Break Total</div>
                <div className="pull-right">
                  <ToggleButton
                    thumbStyle={{ borderRadius: 4 }}
                    trackStyle={{
                      borderRadius: 4,
                    }}
                    colors={{
                      activeThumb: {
                        base: "#fff",
                      },
                      active: {
                        base: "#61c08d",
                      },
                    }}
                    value={this.state.breakTotal || false}
                    onToggle={(value) => {
                      this.setState({
                        breakTotal: !value,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {this.props.mode !== SCHEDULE_MODES.TODAY && (
              <div className="row p-3 bg-gray border-white">
                <div className="print-row">Show Break Details</div>
                <div className="pull-right">
                  <ToggleButton
                    thumbStyle={{ borderRadius: 4 }}
                    trackStyle={{
                      borderRadius: 4,
                    }}
                    colors={{
                      activeThumb: {
                        base: "#fff",
                      },
                      active: {
                        base: "#61c08d",
                      },
                    }}
                    value={this.state.breakDetails || false}
                    onToggle={(value) => {
                      this.setState({
                        breakDetails: !value,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="row p-3 bg-gray border-white">
              <div className="print-row">Show Role</div>
              <div className="pull-right">
                <ToggleButton
                  thumbStyle={{ borderRadius: 4 }}
                  trackStyle={{
                    borderRadius: 4,
                  }}
                  colors={{
                    activeThumb: {
                      base: "#fff",
                    },
                    active: {
                      base: "#61c08d",
                    },
                  }}
                  value={this.state.showRole || false}
                  onToggle={(value) => {
                    this.setState({
                      showRole: !value,
                    });
                  }}
                />
              </div>
            </div>
            {/* {this.props.mode !== SCHEDULE_MODES.TODAY && (
              <div className="row p-3 bg-gray border-white">
                <div className="print-row">Show Zones</div>
                <div className="pull-right">
                  <ToggleButton
                    thumbStyle={{ borderRadius: 4 }}
                    trackStyle={{
                      borderRadius: 4,
                    }}
                    colors={{
                      activeThumb: {
                        base: "#fff",
                      },
                      active: {
                        base: "#61c08d",
                      },
                    }}
                    value={this.state.showSection || false}
                    onToggle={(value) => {
                      this.setState({
                        showSection: !value,
                      });
                    }}
                  />
                </div>
              </div>
            )} */}
            {this.props.mode !== SCHEDULE_MODES.TODAY && (
              <div className="row p-3 bg-gray border-white">
                <div className="print-row">Show Location</div>
                <div className="pull-right">
                  <ToggleButton
                    thumbStyle={{ borderRadius: 4 }}
                    trackStyle={{
                      borderRadius: 4,
                    }}
                    colors={{
                      activeThumb: {
                        base: "#fff",
                      },
                      active: {
                        base: "#61c08d",
                      },
                    }}
                    value={this.state.showLocation || false}
                    onToggle={(value) => {
                      this.setState({
                        showLocation: !value,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="row p-3 bg-gray border-white">
              <div className="print-row">Choose what to print</div>
              <div className="pull-right">
                <select
                  className="form-control schedule-select"
                  onChange={(event) => {
                    const value = event.target.value;
                    this.setState({ range: value });
                  }}
                  value={this.state.range}
                >
                  <option value="all">All</option>
                  <option value="visible">Visible</option>
                </select>
              </div>
            </div>
            <div className="row p-3 bg-gray border-white">
              <div className="print-row">Language</div>
              <div className="pull-right">
                <select
                  className="form-control schedule-select"
                  onChange={(event) => {
                    const value = event.target.value;
                    this.setState({
                      language: value,
                    });
                  }}
                  value={this.state.language}
                >
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                </select>
              </div>
            </div>
            <div className="row p-3 bg-gray border-white">
              <div className="print-row">Notes</div>
              <div className="w-100">
                <textarea
                  placeholder="Write notes here..."
                  className="form-control"
                  value={this.state.notes}
                  onChange={({ target: { value: notes } }) =>
                    this.setState({ notes })
                  }
                  rows={4}
                  cols={10}
                />
              </div>
            </div>
            <div className="hidden">
              <SchedulePdf
                user={this.props.user}
                ref="SchedulePdf"
                activeTab={this.props.activeTab}
                mode={this.props.mode}
                blackAndWhite={this.state.blackAndWhite}
                showShiftDuration={this.state.shiftDuration}
                breakTotal={this.state.breakTotal}
                breakDetails={this.state.breakDetails}
                showRole={this.state.showRole}
                showSection={this.state.showSection}
                sections={sections}
                showLocation={this.state.showLocation}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                events={events}
                resources={resources}
                language={this.state.language}
                notes={this.state.notes}
                range={this.state.range}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="pull-right">
              <ReactToPrint
                ref={(e) => {
                  this.printer = e;
                }}
                // onBeforeGetContent={() => this.setState({ preparing: false })}
                onBeforePrint={() => this.setState({ preparing: false })}
                // onAfterPrint={() => this.setState({ preparing: false })}
                pageStyle={"@page{size: 4in 2.5in; } @media print {body {-webkit-print-color-adjust: exact; } }"}
                trigger={() => <React.Fragment />}
                content={() => this.refs.SchedulePdf.componentRef}
              />
              <button onClick={this.print} className="btn btn-primary mr-2">
                {preparing ? (
                  "Preparing..."
                ) : (
                    <React.Fragment>
                      <i className="mr-1 fas fa-print" />
                    Print
                    </React.Fragment>
                  )}
              </button>
              <button className="btn btn-light" onClick={this.closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
PrintModal.defaultProps = {
  closeModal: () => { },
  isOpen: false,
};
PrintModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isGenerated: PropTypes.bool.isRequired,
  activeTab: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  mode: PropTypes.string,
};
export default PrintModal;

import moment from "moment";

export const breakMinutesDifference = 30;
export const breakEdgeOffset = 5;
export const defaultDuration = 30;

export const validateBreakTimes = (breaks, index, newStart, newEnd) => {
    let check = true;

    if (Object.values(breaks).length === 1) return check;

    Object.values(breaks)
        .filter((v, i) => i !== index)
        .every(({ start, end }) => {
            if (
                (newStart.isSameOrAfter(start) &&
                    newStart.isSameOrBefore(end)) ||
                (newEnd.isSameOrAfter(start) && newEnd.isSameOrBefore(end))
            ) {
                check = "Breaks can't overlap";
                return false;
            } else if (
                (newStart.isAfter(end) &&
                    moment(newStart).diff(end, "minutes") <
                        breakMinutesDifference) ||
                (newEnd.isBefore(start) &&
                    moment(start).diff(newEnd, "minutes") <
                        breakMinutesDifference)
            ) {
                check = `Breaks should be at least ${breakMinutesDifference} minutes apart`;
                return false;
            }

            return true;
        });

    return check;
};

export const getEligibleStart = (time, extra = 0) => {
    const newEligibleStart = moment(time).add(
        breakEdgeOffset + extra,
        "minutes"
    );
    const filler = 5 - (newEligibleStart.minutes() % 5 || 5);
    newEligibleStart.add(filler, "minutes");
    return newEligibleStart;
};

export const getEligibleEnd = (time, extra = 0) => {
    const newEligibleEnd = moment(time).subtract(
        breakEdgeOffset + parseInt(extra),
        "minutes"
    );

    const filler = newEligibleEnd.minutes() % 5;
    newEligibleEnd.subtract(filler, "minutes");
    return newEligibleEnd;
};

export const breakLengthOptions = [10, 15, 20, 30, 45, 60];

import React, { Component } from 'react';
import { Button, ButtonGroup as BaseGroup } from 'reactstrap';

export default class ButtonGroup extends Component {
  render() {
    const { options, onChange, selected, variant = 'light', style = {} } = this.props;
    return (
      <BaseGroup style={style}>
        {options.map((value, i) => (
          <Button
            key={i}
            color={value !== selected ? variant : 'primary'}
            onClick={value !== selected ? () => onChange(value, variant) : () => { }}
            active={value === selected}
          >
            {value}
          </Button>
        )
        )}
      </BaseGroup>
    );
  }
}

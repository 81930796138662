import React, { Component } from "react";
import { columns } from "./EmployeeReportTableColumns";
import ExportTable from "../../../containers/Reports/ExportTable";
import moment from "moment";
import StringHelper from "../../../helpers/StringHelper";

export default class EmployeeReportTable extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      isLoading: false,
      user
    };
  }

  getDetails = (item, forExport = false) => {
    const data = JSON.parse(item.detailedChanges);
    switch (item.action) {
      case "delete":
        const dateString = `${moment(data.startDate).format(
          "DD MMMM YYYY, hh:mm A"
        )} - ${moment(data.endDate).format("hh:mm A")}`;

        return forExport ? (
          `${dateString}\n${data.name}\nLocation: ${item.locationName}`
        ) : (
            <div className="detailed-changes">
              <div>
                <div>{dateString}</div>
                <div>{data.name}</div>
                <div>Location: {item.locationName}</div>
              </div>
            </div>
          );

      case "update":
        // console.log(data);
        const getLength = d =>
          d.old.length > d.new.length ? d.old.length : d.new.length;

        const getBreakString = b =>
          b ? `${b.start} - ${b.end} | ${b.duration} min` : "-";

        const stringifyBreakData = data =>
          forExport ? (
            `Break data: ${new Array(getLength(data))
              .fill(0)
              .reduce((t, o, i) => {
                console.log(o);
                return (
                  t +
                  `\n${getBreakString(data.old[i])} → ${getBreakString(
                    data.new[i]
                  )}`
                );
              }, "")}`
          ) : (
              <React.Fragment>
                <div>
                  {data.old.length > 0 ? (
                    data.old.map((o, i) => (
                      <div key={i}>
                        {o.start} - {o.end} | {o.duration} min
                      </div>
                    ))
                  ) : (
                      <div>-</div>
                    )}
                </div>

                <i className="fas fa-arrow-right" />

                <div>
                  {data.new.length > 0 ? (
                    data.new.map((o, i) => (
                      <div key={i}>
                        {o.start} - {o.end} | {o.duration} min
                      </div>
                    ))
                  ) : (
                      <div>-</div>
                    )}
                </div>
              </React.Fragment>
            );

        return forExport ? (
          Object.keys(data).reduce(
            (t, o, i) =>
              t +
              (o === "breakData"
                ? stringifyBreakData(data[o])
                : `${StringHelper.capitalize(o)}:\n${data[o].old ||
                "-"} → ${data[o].new || "-"}${
                i + 1 === Object.keys(data).length ? "" : "\n\n"
                }`),
            ""
          )
        ) : (
            <div className="detailed-changes">
              {Object.keys(data).map((o, i) => (
                <div key={i}>
                  <div className="title">{o}</div>

                  <div className="data">
                    {o === "breakData" ? (
                      stringifyBreakData(data[o])
                    ) : (
                        <React.Fragment>
                          <div>
                            <div>{data[o].old || "-"}</div>
                          </div>

                          <i className="fas fa-arrow-right" />

                          <div>
                            <div>{data[o].new || "-"}</div>
                          </div>
                        </React.Fragment>
                      )}
                  </div>
                </div>
              ))}
            </div>
          );

      default:
        break;
    }
  };

  componentDidMount() {
  }

  render() {
    const { isLoading, resources } = this.props;

    let reportRows = (
      <tr>
        <td colSpan="100%" align="center">
          {isLoading ? <div id="spinner" /> : "No data"}
        </td>
      </tr>
    );
    if (resources && resources.length) {
      reportRows = resources.map((row, key) => {
        return (
          <tr key={key}>
            <td className="text-left">
              {row.employeeName}
            </td>
            <td className="text-left">{row.employeeCode}</td>
            <td className="text-left">{row.name}</td>
            <td className="text-left">{row.startDate}</td>
            <td className="text-left">{row.endDate}</td>
            <td className="text-left">{row.duration}</td>
            <td className="text-left">{row.breaks}</td>
            <td className="text-left">{row.durationWithouBreak}</td>
            <td className="text-left">{`$${StringHelper.formatMoney(row.hourlyRate || 0)}`}</td>
            <td className="text-left">{`$${StringHelper.formatMoney(row.amount || 0)}`}</td>
            <td className="text-left">{row.locationCode}</td>
            <td className="text-left">{row.location}</td>
            <td className="text-left">{row.locationState}</td>
            <td className="text-left">{row.jobCode}</td>
            <td className="text-left">{row.job}</td>
            <td className="text-left">{row.departmentCode}</td>
            <td className="text-left">{row.department}</td>
          </tr>
        );
      });
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card mb-2">
            <div className="card-header">
              <div className="float-left">
                <div style={{ fontSize: "17px" }}>Employee Report</div>
              </div>
              <div className="float-right">
                {resources && resources.length > 0 && (
                  <ExportTable
                    className="btn btn-warning mr-2 mb-md-2 export-report-button"
                    table="report-table"
                    filename="Employee Report"
                    sheet="Report"
                    icon="mr-1 fas fa-file-excel-o"
                    data={resources}
                    columns={columns}
                    onPress={this.props.export}
                    buttonText={this.props.isLoadingExport ? 'Preparing to export...' : "Export Report"}
                  />
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="e-table">
                <div className="table-responsive table-lg">
                  <table
                    id="report-table"
                    className="table table-bordered text-nowrap table-hover table-sm"
                  >
                    <thead>
                      <tr>
                        {columns.map(({ value, field }) => {
                          return (
                            <th key={field} rowSpan={2} className="text-center">
                              {value}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>{reportRows}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

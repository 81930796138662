import BaseService from "./BaseService";

class BudgetService extends BaseService {
  getBudgets(filters) {
    let url = "/budgets/yearly";
    if (filters) {
      const queryString = Object.entries(filters).map(
        ([name, val]) => `${name}=${val}`
      );
      url = `${url}?${queryString.join("&")}`;
    }
    return this.apiGet({ url });
  }

  saveBudget(data) {
    return this.api.put("/budgets/yearly", data);
  }

  importBudgetsSample(data, year) {
    return this.api.post("/budgets/import/json", { data, year });
  }

  importBudgets(data) {
    const postData = new FormData();
    Object.keys(data).forEach(field => {
      if (field === "budgets") {
        postData.append("budgets", data[field], data[field].name);
      } else {
        postData.append(field, data[field]);
      }
    });
    return this.api.post("/budgets/import", postData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  createBudget(data) {
    return this.api.post("/budgets", data);
  }

  updateBudget(data) {
    return this.api.put("/budgets", data);
  }

  deleteBudget(data) {
    return this.api.delete("/budgets", { data });
  }
}

export default new BudgetService();

import { SCHEDULE_MODES } from "../../../containers/Schedule/Constant";
import moment from "moment";
import { refreshSchedules } from "./SchedulerHelper";

function onSelectDateChange(date, addAmount) {
    if (addAmount) {
        addAmount === 1 ? this.call(nextDate) : this.call(prevDate)
    } else {
        const { mode, config, user } = this.state;

        this.setState({
            config: {
                startDate: null,
                endDate: null,
                viewPreset: config.viewPreset
            }
        }, () => {
            let dateMode;

            switch (mode) {
                case SCHEDULE_MODES.TODAY:
                    dateMode = "day";
                    break;

                case SCHEDULE_MODES.THIS_WEEK:
                    dateMode = user && user.weekConf ? user.weekConf : "week";
                    break;

                case SCHEDULE_MODES.THIS_MONTH:
                    dateMode = "month";
                    break;
                default:
                    break;
            }


            const startDate = date.startOf(dateMode).toDate();
            const endDate = date.endOf(dateMode).toDate();

            this.setState(
                {
                    config: {
                        endDate,
                        startDate,
                        viewPreset: config.viewPreset
                    }
                },
                () => {
                    this.call(refreshSchedules)
                }
            );
        });
    }
}
function getWeekConfig(user) {
    return user && user.weekConf ? user.weekConf : "week";
}
function getWeekStartDay(user) {
    return user && user.weekConf === 'isoWeek' ? 1 : 0;
}
function addDate(amount, config) {
    const { mode, user } = this.state;
    switch (mode) {
        case SCHEDULE_MODES.TODAY:
            config.endDate = moment(config.endDate)
                .add(amount, "day")
                .endOf("day")
                .toDate();
            config.startDate = moment(config.startDate)
                .add(amount, "day")
                .startOf("day")
                .toDate();
            this.setState(
                {
                    config
                },
                () => {
                    this.call(refreshSchedules);
                }
            );
            break;
        case SCHEDULE_MODES.THIS_WEEK:
            config.endDate = moment(config.endDate)
                .add(amount, "week")
                .endOf(getWeekConfig(user))
                .endOf("day")
                .toDate();
            config.startDate = moment(config.startDate)
                .add(amount, "week")
                .startOf(getWeekConfig(user))
                .startOf("day")
                .toDate();
            this.setState(
                {
                    config
                },
                () => {
                    this.call(refreshSchedules);
                }
            );
            break;
        case SCHEDULE_MODES.THIS_MONTH:
            config.endDate = moment(config.endDate)
                .add(amount, "month")
                .endOf("month")
                .endOf("day")
                .toDate();
            config.startDate = moment(config.startDate)
                .add(amount, "month")
                .startOf("month")
                .startOf("day")
                .toDate();
            this.setState(
                {
                    config
                },
                () => {
                    this.call(refreshSchedules);
                }
            );
            break;
        default:
            break;
    }
}
function prevDate() {
    const { config } = this.state;
    this.setState(
        {
            config: {
                startDate: null,
                endDate: null,
                viewPreset: config.viewPreset
            }
        },
        () => {
            this.call(addDate, -1, config);
        }
    );
}
function nextDate() {
    const { config } = this.state;
    this.setState(
        {
            config: {
                startDate: null,
                endDate: null,
                viewPreset: config.viewPreset
            }
        },
        () => {
            this.call(addDate, 1, config);
        }
    );
}
function onDateChange(date) {
    this.setState({
        copyDate: date,
        copyError: null
    });
}
function onDatesChange({ startDate, endDate }) {
    if (
        (startDate && !endDate) ||
        (startDate && endDate && startDate <= endDate)
    ) {
        const sDate =
            (startDate &&
                moment(startDate)
                    .startOf("day")
                    .toDate()) ||
            "";
        const eDate =
            (endDate &&
                moment(endDate)
                    .endOf("day")
                    .toDate()) ||
            "";
        const config = {
            viewPreset: "dayAndMonth",
            startDate: null,
            endDate: null,
        };
        this.setState({
            startDate: null,
            endDate: null,
            config
        }, () => {
            this.setState({
                eventsVersion: this.state.eventsVersion + 1,
                resourcesVersion: this.state.resourcesVersion + 1,
                startDate,
                endDate,
                config: {
                    viewPreset: "dayAndMonth",
                    startDate: sDate,
                    endDate: eDate,
                },
            }, () => {
                if (endDate && startDate) {
                    this.call(refreshSchedules);
                }
            });
        })

    }
}

function onFocusChange(focusedInput) {
    this.setState({
        focusedInput: !focusedInput ? null : focusedInput
    });
}

export {
    onSelectDateChange,
    addDate,
    prevDate,
    nextDate,
    onDateChange,
    onDatesChange,
    onFocusChange,
    getWeekConfig,
    getWeekStartDay
}

import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Login validations schema
const loginSchema = Yup.object().shape({
  employeeCode: Yup.string()
    .trim()
    .required("Employee number is required"),
  password: Yup.string()
    .trim()
    .required("Password is required"),
  rememberMe: Yup.bool()
});

export default class LoginForm extends Component {
  render() {
    return (
      <Formik
        initialValues={{
          employeeCode: "",
          password: "",
          rememberMe: true
        }}
        validationSchema={loginSchema}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          await this.props.submitLogin(values, setFieldValue);
          setSubmitting(false);
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit,
          handleBlur
        }) => (
          <form
            id="login"
            onSubmit={handleSubmit}
            className="card-body"
            tabIndex="500"
            autocomplete="off"
          >
            <h5 className="mb-3">Login</h5>
            <div className="">
              <div className="form-group">
                <label htmlFor="employeeCode">Employee Id</label>
                <input
                  type="text"
                  name="employeeCode"
                  id="employeeCode"
                  autoFocus={true}
                  className="form-control"
                  onChange={handleChange}
                  value={values.employeeCode}
                  placeholder="Employee Id"
                  disabled={isSubmitting}
                />
                {errors.employeeCode && touched.employeeCode && (
                  <div className="input-validation-error">
                    {errors.employeeCode}
                  </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  onChange={handleChange}
                  value={values.password}
                  placeholder="Password"
                  disabled={isSubmitting}
                />
                {errors.password && touched.password && (
                  <div className="input-validation-error">
                    {errors.password}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="checkbox col-5">
                  <div className="custom-checkbox custom-control">
                    <input
                      type="checkbox"
                      name="rememberMe"
                      id="rememberMe"
                      className="custom-control-input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.rememberMe}
                      value={values.rememberMe}
                      disabled={isSubmitting}
                    />
                    <label
                      htmlFor="rememberMe"
                      className="custom-control-label"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="forgot-link col-7 d-flex justify-content-end text-white">
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
              </div>
            </div>
            <div className="submit mt-3 mb-3">
              <div className="instructions">
                LOGIN INSTRUCTIONS
                <br />
                Username: your 6 digit Employee ID
                <br />
                <br />
                Your Employee ID is your six digit ULTIPRO Employee number.
                Please check with your manager if you don't know what the number
                is.
                <br />
                <br />
              </div>
              <button
                type="submit"
                className="btn btn-info btn-block"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Logging in..." : "Login"}
              </button>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

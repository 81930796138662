import React, { useEffect, useState, useMemo } from "react";
import Chart from "react-apexcharts";
import "./assets/styles/style.css";
import moment from "moment";
import ReportService from "../../services/ReportService";
import { SingleDatePicker, CalendarDay } from "react-dates";
import DatePicker from "react-datepicker";
import WeekPicker from "../../containers/Forecasting/WeekPicker";
import { getWeekStartDay, getWeekConfig } from "../Schedule/helpers/DateHelper";

const SCHEDULE_MODES = {
  TODAY: "today",
  THIS_WEEK: "this_week",
  THIS_MONTH: "this_month",
  DATE_RANGE: "date_range"
};

const getTickAmount = ({ startDate, endDate, mode }) => {
  let tickAmount;

  switch (mode) {
    case SCHEDULE_MODES.TODAY:
      tickAmount = 24;
      break;

    case SCHEDULE_MODES.THIS_WEEK:
      tickAmount = 7;
      break;

    case SCHEDULE_MODES.THIS_MONTH:
      tickAmount = moment(startDate)
        .add(1, "month")
        .daysInMonth();
      break;

    case SCHEDULE_MODES.DATE_RANGE:
      const start = moment(startDate);
      const end = moment(endDate);

      tickAmount = end.diff(start, "days") + 1;
      break;
    default:
      break;
  }

  return tickAmount;
};

const getCategories = ({ mode, startDate, endDate }) => {
  const categories = [];

  const tickAmount = getTickAmount({ mode, startDate, endDate });

  let start = moment(startDate).startOf("day");

  for (let i = 0; i < tickAmount; i++) {
    if (mode === SCHEDULE_MODES.TODAY) {
      categories.push(start.add(i === 0 ? 0 : 1, "hour").format("h A"));
    } else {
      categories.push(start.add(i === 0 ? 0 : 1, "day").format("D MMM"));
    }
  }

  return categories;
};

const fetchData = async ({
  locations,
  mode,
  setData,
  startDate: sD,
  endDate: eD,
  dates: d = [],
  setConfigs
}) => {
  let startDate = moment(sD).format("YYYY-MM-DD");
  let endDate = moment(eD).format("YYYY-MM-DD");
  let dates = d;
  if (dates.length === 1) {
    startDate = dates[0].startOf("day").format("YYYY-MM-DD");
    endDate = dates[0].endOf("day").format("YYYY-MM-DD");
    dates = [];
  }

  const res = await ReportService.forecastChartData({
    locations: JSON.stringify(locations),
    startDate,
    endDate,
    dates: JSON.stringify(dates.map(d => d.format("YYYY-MM-DD"))),
    mode,
    withConfig: true
  });

  const checkins = res.data.checkins;
  setConfigs(res.data.config);

  if (mode === SCHEDULE_MODES.THIS_MONTH) {
    const diff =
      moment(startDate)
        .add(1, "months")
        .daysInMonth() - moment(startDate).daysInMonth();

    if (diff > 0) {
      const fill = new Array(diff).fill(0);
      setData([...checkins, ...fill]);
    } else if (diff < 0) {
      setData(checkins.slice(0, diff));
    } else {
      setData(checkins);
    }
  } else setData(checkins);
};

const scrollEventListener = e => {
  document.getElementsByClassName("forecast-scroll-container")[0].scrollLeft =
    e.target.scrollLeft;
};

const mouseMoveEventListener = e => {
  document.getElementsByClassName("forecast-period")[0].style.width =
    e.target.getBoundingClientRect().left - 5 + "px";
};

const mouseDownEventListener = splitter => {
  splitter[splitter.length - 1].addEventListener(
    "mousemove",
    mouseMoveEventListener
  );
};

const getInitDates = (mode, config) => {
  let sDate, eDate;
  switch (mode) {
    case SCHEDULE_MODES.TODAY:
      sDate = moment(config.startDate).add(-1, "days");
      eDate = moment(config.endDate).add(-1, "days");
      break;

    case SCHEDULE_MODES.THIS_WEEK:
      sDate = moment(config.startDate).add(-1, "week");
      eDate = moment(config.endDate).add(-1, "week");
      break;

    case SCHEDULE_MODES.THIS_MONTH:
      sDate = moment(config.startDate).add(-1, "month");
      eDate = moment(config.endDate).add(-1, "month");
      break;

    case SCHEDULE_MODES.DATE_RANGE:
      const diff = moment(config.endDate).diff(
        moment(config.startDate),
        "days"
      );

      if (diff === 0) {
        sDate = moment(config.startDate)
          .add(-1, "days")
          .startOf("day");
        eDate = moment(sDate).endOf("day");
      } else {
        sDate = moment(config.startDate).add(-diff - 1, "days");
        eDate = moment(config.startDate).add(-1, "days");
      }
      break;
    default:
      break;
  }

  return { startDate: sDate, endDate: eDate };
};

const RChart = ({ options, data, width, dates, mode }) => {
  return (
    <Chart
      height={148}
      style={{
        marginLeft: "-10px"
      }}
      width={width}
      type="bar"
      series={[
        {
          name:
            mode === SCHEDULE_MODES.TODAY && dates.length > 1
              ? "Checkins avg"
              : "Checkins",
          data
        }
      ]}
      options={options}
    />
  );
};

const ForecastChart = ({ mode, config, locations, selectedDepartments, user }) => {
  const [data, setData] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dates, setDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [chart, setChart] = useState(true);
  const [firstDate, setFirstDate] = useState(false);

  const department =
    selectedDepartments.length === 1 ? selectedDepartments[0].value : null;

  useEffect(() => {
    setChart(false);
    setTimeout(() => {
      setChart(true);
    }, 100);
  }, [department]);

  useEffect(() => {
    const { startDate, endDate } = getInitDates(mode, config);
    setStartDate(startDate);
    setEndDate(endDate);
    if (mode === SCHEDULE_MODES.TODAY && !firstDate) {
      setDates([moment(startDate)]);
      setSelectedDates([moment(startDate)]);
      setFirstDate(true);
    }

    const scrollers = document.getElementsByClassName(
      "b-grid-subgrid b-grid-horizontal-scroller b-widget-scroller b-overflowing-horizontally b-timeline-subgrid"
    );

    if (scrollers.length > 0)
      scrollers[scrollers.length - 1].addEventListener(
        "scroll",
        scrollEventListener
      );

    const splitter = document.getElementsByClassName("b-grid-splitter");

    if (splitter.length > 0) {
      splitter[splitter.length - 1].addEventListener("mousedown", () =>
        mouseDownEventListener(splitter)
      );
    }

    return () => {
      if (scrollers.length > 0)
        scrollers[scrollers.length - 1].removeEventListener(
          "scroll",
          scrollEventListener
        );

      if (splitter.length > 0) {
        splitter[splitter.length - 1].removeEventListener(
          "mousedown",
          mouseDownEventListener
        );

        splitter[splitter.length - 1].removeEventListener(
          "mousemove",
          mouseMoveEventListener
        );
      }
    };
  }, []);

  useEffect(() => {
    data.length > 0 && setLoading(false);
  }, [data]);

  useEffect(() => {
    if (startDate && endDate && locations && mode) {
      fetchData({ locations, startDate, endDate, mode, setData, setConfigs });
    }
  }, [mode, startDate, endDate, locations.length]);

  const categories = useMemo(() => {
    return getCategories({ startDate, endDate, mode });
  }, [startDate, endDate, mode]);

  const formatter = value => {
    if (!configs || !department) return value;
    let config = configs[department];
    if (!config) return value;
    config = JSON.parse(config);

    let estimation = config.find(
      ({ rangeStart, rangeEnd }) =>
        value >= rangeStart && (rangeEnd ? value <= rangeEnd : true)
    ).value;

    return value + (estimation ? ` (estimated employees: ${estimation})` : "");
  };

  const options = {
    tooltip: {
      y: {
        formatter
      }
    },
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "100%"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      lineCap: "square",
      width: 1
    },
    // fill: {
    //     type: "gradient",
    //     gradient: {
    //         opacityFrom: 0.6,
    //         opacityTo: 0.5
    //     }
    // },
    legend: {
      show: false
    },
    grid: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    xaxis: {
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      },
      categories
    },
    yaxis: {
      labels: {
        show: false
      },
      tickAmount: 2
    },
    colors: ["#1f9e67"]
  };

  const elements = document.getElementsByClassName("b-sch-foreground-canvas");
  const width =
    elements.length > 0 ? elements[elements.length - 1].scrollWidth + 10 : 0;

  const period = useMemo(() => {
    if (mode === SCHEDULE_MODES.TODAY) {
      return dates.length > 0
        ? dates.length > 1
          ? "Multiple days selected"
          : dates[0].format("DD MMMM")
        : "";
    } else {
      return `${moment(startDate).format("DD MMM")} - ${moment(endDate).format(
        "DD MMM"
      )}`;
    }
  }, [startDate, endDate, mode, dates]);

  const onDateChange = date => {
    setLoading(true);

    let startDate, endDate;

    switch (mode) {
      case SCHEDULE_MODES.TODAY:
        setLoading(false);
        const isSelected = !!selectedDates.find(d => d.isSame(date, "day"));
        if (selectedDates.length === 5 && !isSelected) return;
        const newDates = isSelected
          ? selectedDates.filter(d => !date.isSame(d, "day"))
          : [...selectedDates, date];
        setSelectedDates(newDates);
        break;

      case SCHEDULE_MODES.THIS_WEEK:
        startDate = moment(date).startOf(getWeekConfig(user));
        endDate = moment(date).endOf(getWeekConfig(user));
        break;

      case SCHEDULE_MODES.THIS_MONTH:
        startDate = moment(date).startOf("month");
        endDate = moment(date).endOf("month");
        break;

      case SCHEDULE_MODES.DATE_RANGE:
        const diff = moment(config.endDate).diff(
          moment(config.startDate),
          "days"
        );

        if (diff === 0) {
          startDate = moment(date).startOf("day");
          endDate = moment(date).endOf("day");
        } else {
          startDate = moment(date);
          endDate = moment(date).add(diff, "days");
        }
        break;
      default:
        break;
    }

    setStartDate(startDate);
    setEndDate(endDate);

    mode !== SCHEDULE_MODES.TODAY && setPickerOpen(false);
  };

  const onCancel = () => {
    setSelectedDates(dates);
    setPickerOpen(false);
  };

  const submitDates = async () => {
    setDates(selectedDates);
    setLoading(true);
    setPickerOpen(false);
    await fetchData({
      locations,
      startDate,
      endDate,
      mode,
      dates: selectedDates,
      setData,
      setConfigs
    });
    setLoading(false);
  };

  const SubmitBtn = () => (
    <div className="pb-4 pl-4">
      <div className="pb-4"> Date selected {selectedDates.length}/5</div>
      <button
        disabled={selectedDates.length === 0}
        className="btn btn-sm btn-primary mr-2"
        onClick={submitDates}
      >
        Submit
      </button>

      <button className="btn btn-sm btn-danger" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );

  return (
    <div className="forecast-chart-container">
      <div className="forecast-period">
        <div>
          {[SCHEDULE_MODES.TODAY, SCHEDULE_MODES.DATE_RANGE].includes(mode) && (
            <SingleDatePicker
              firstDayOfWeek={getWeekStartDay(user)}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              isOutsideRange={() => false}
              onDateChange={onDateChange}
              focused={pickerOpen}
              onFocusChange={() => { }}
              renderCalendarInfo={SubmitBtn}
              renderCalendarDay={props => {
                const { day, modifiers } = props;

                if (!!selectedDates.find(d => d.isSame(day, "day"))) {
                  modifiers && modifiers.add("selected");
                } else {
                  modifiers && modifiers.delete("selected");
                }
                return (
                  <CalendarDay
                    firstDayOfWeek={getWeekStartDay(user)}
                    {...props}
                    modifiers={modifiers}
                    onDayMouseEnter={() => { }}
                    onDayMouseLeave={() => { }}
                  />
                );
              }}
            />
          )}

          {mode === SCHEDULE_MODES.THIS_WEEK && (
            <WeekPicker
              firstDayOfWeek={getWeekStartDay(user)}
              weekConf={user.weekConf || 'week'}
              date={startDate}
              focused={pickerOpen}
              onDateChange={() => { }}
              onChangeFocus={() => {
                setPickerOpen(false);
              }}
              onWeekSelect={onDateChange}
            />
          )}

          {mode === SCHEDULE_MODES.THIS_MONTH && (
            <DatePicker
              popperModifiers={{
                offset: { offset: "20px, 10px" }
              }}
              onClickOutside={() => {
                setPickerOpen(false);
              }}
              open={pickerOpen}
              showMonthYearPicker
              onChange={onDateChange}
            />
          )}
        </div>
        <div className="wrapper" onClick={() => setPickerOpen(!pickerOpen)}>
          {mode === SCHEDULE_MODES.TODAY && dates.length > 1 ? (
            <React.Fragment>
              <div>Avg. forecasting data for dates:</div>
              <span className="dates-display">
                <ul>
                  {dates.map((d, key) => {
                    return <li key={key}>{moment(d).format("DD MMMM")}</li>;
                  })}
                </ul>
                <span>
                  <i className="fas fa-caret-down" />
                </span>
              </span>
            </React.Fragment>
          ) : (
              <React.Fragment>
                <div>Forecasting data for period :</div>
                <span>
                  {period} &ensp; <i className="fas fa-caret-down" />
                </span>
              </React.Fragment>
            )}
        </div>
      </div>
      <div className="forecast-scroll-container">
        {loading ? (
          <div className="forecast-loading">Loading...</div>
        ) : (
            chart && (
              <RChart
                options={options}
                width={width}
                data={data}
                dates={dates}
                mode={mode}
              />
            )
          )}
      </div>
    </div>
  );
};

export default ForecastChart;

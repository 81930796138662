import { handleMultiSelect, onEventSave, publishEvents } from "../helpers/SchedulerHelper";
import { openModal } from "../Modals";
import { getColumns } from "../../../containers/Schedule/Columns";
import { renderLocation, renderResourceName, renderRole } from "../helpers/Renders";
import AvailableShift from "../AvailableShift";
import { handleResourceContextMenu } from "../helpers/Resource";
import { onEventDelete } from "../helpers/CUD_API";
import { handleSelectionChange, onEventMove, onEventsAssign } from "../helpers/EventActions";
import Collapsible from "react-collapsible";
import React from "react";

function renderAvailableView() {
  const {
    user,
    mode,
    available,
    resources,
    config,
    availableEventsVersion,
    availableLocations,
    availableJobs,
    mapJobs,
    mapLocations,
    openAvailableShift,
    scheduleConfigs
  } = this.state;
  const getProfile = resource => {
    this.setState({
      employeeCode: resource.record.data.code,
      showEmployeeProfileModal: true
    });
  };
  const copyEvents = async resource => {
    const { id } = resource.record.data;
    const events = this.state.mappedEvents[id] || [];
    this.call(handleMultiSelect, events);
    this.call(openModal, "copyModal");
  };
  const availableColumns = getColumns(
    user,
    (d) => this.call(renderRole, d),
    (d) => this.call(renderResourceName, d),
    (d) => this.call(renderLocation, d),
    getProfile,
    copyEvents,
    true
  );
  return (
    <Collapsible
      className="mb-3 mt-3 scheduler-collapse"
      openedClassName="mb-3 mt-3 scheduler-collapse"
      open={openAvailableShift}
      onClosing={() => {
        this.setState({
          openAvailableShift: false
        })
      }}
      onOpening={() => {
        this.setState({
          openAvailableShift: true
        })
      }}
      trigger={(
        <span>
          Available Shifts ({available.length}) <span className="caret" />
        </span>
      )
      }
    >
      <AvailableShift
        loggedInUser={user}
        eventsVersion={availableEventsVersion}
        resourcesVersion={availableEventsVersion}
        jobs={availableJobs}
        mapJobs={mapJobs}
        mapLocations={mapLocations}
        locations={availableLocations}
        events={available}
        resources={[{ id: '0', name: '' }]}
        allResources={resources}
        startDate={config.startDate}
        endDate={config.endDate}
        viewPreset={config.viewPreset}
        scheduleMode={mode}
        weekConf={user.weekConf || 'week'}
        isManager={user.role === 'manager'}
        readOnly={user.role !== 'manager'}
        submitOrPublish={"publish"}
        publishEvents={(e) => this.call(publishEvents, e)}
        handleResourceContextMenu={(ctx) => this.call(handleResourceContextMenu, ctx)}
        columns={availableColumns}
        useInitialAnimation="slide-from-left"
        onEventSelectionChange={(s) => this.call(handleSelectionChange, s)}
        onSelectEventsChange={(se) => this.call(handleMultiSelect, se)}
        copyEvents={() => this.call(openModal, 'copyModal')}
        onEventSave={(e, isA) => this.call(onEventSave, e, isA)}
        onEventDelete={(e, s, isM) => this.call(onEventDelete, e, s, isM)}
        onEventMove={(e) => this.call(onEventMove, e)}
        notifyForEvent={() => {
        }}
        onEventsAssign={(ids, evs, isM) => this.call(onEventsAssign, ids, evs, isM)}
        scheduleConfigs={scheduleConfigs}
      />
    </Collapsible>
  )
}

export {
  renderAvailableView
}

import BaseService from "./BaseService";

class FilterService extends BaseService {
    getAllLocations(params = {}) {
        return this.apiGet({
            url: `/employees/filter/locations`,
            params
        });
    }
    getAllDepartments() {
        return this.apiGet({
            url: `/employees/filter/departments`
        });
    }
    getAllJobs(params = {}) {
        return this.apiGet({
            url: `/employees/filter/jobs`,
            params
        });
    }
    getFilters() {
        return this.apiGet({
            url: `/employees/filters`
        });
    }
    getFilterBy(location = false, departments = false, jobs = false) {
        let qs = '';
        if (location) {
            qs += 'locations=true'
        }
        if (departments) {
            qs += `${qs !== '' ? '&' : ''}departments=true`
        }

        if (jobs) {
            qs += `${qs !== '' ? '&' : ''}jobs=true`
        }
        return this.apiGet({
            url: `/employees/filterBy?${qs}`
        });
    }

    getMyLocationDepartment(params = {}) {
        return this.apiGet({
            url: `/custom-departments/my-location-department`,
            params
        });
    }
}

export default new FilterService();

import { getColumns } from "../../../containers/Schedule/Columns";
import { renderLocation, renderResourceName, renderRole } from "../helpers/Renders";
import BryntumScheduler from "../../BryntumScheduler/BryntumScheduler";
import React from "react";

function renderDepartmentView() {
  const {
    user,
    mode,
    events,
    availableLocations,
    availableJobs,
    mapJobs,
    mapLocations,
    resources,
    config,
    eventsVersion,
    resourcesVersion,
    scheduleConfigs = {}
  } = this.state;
  const getProfile = () => { };
  const copyEvents = () => { };
  const columns = getColumns(
    user,
    (d) => this.call(renderRole, d),
    (d) => this.call(renderResourceName, d),
    (d) => this.call(renderLocation, d),
    getProfile,
    copyEvents,
    false,
    true
  );
  return (
    <BryntumScheduler
      ref={"scheduler"}
      comId={"departmentScheduler"}
      autoHeight={true}
      allowOverlap={false}
      jobs={availableJobs}
      mapJobs={mapJobs}
      mapLocations={mapLocations}
      locations={availableLocations}
      eventsVersion={eventsVersion}
      resourcesVersion={resourcesVersion}
      events={events}
      resources={resources}
      startDate={config.startDate}
      endDate={config.endDate}
      viewPreset={config.viewPreset}
      scheduleMode={mode}
      weekConf={user.weekConf || 'week'}
      isManager={user.role === 'manager'}
      loggedInUser={user}
      readOnly={true}
      submitOrPublish={"publish"}
      publishEvents={() => {
      }}
      handleResourceContextMenu={() => {
      }}
      columns={columns}
      useInitialAnimation="slide-from-left"
      onEventSelectionChange={() => {
      }}
      onSelectEventsChange={() => {
      }}
      copyEvents={() => {
      }}
      onEventSave={() => {
      }}
      onEventDelete={() => {
      }}
      onEventMove={() => {
      }}
      notifyForEvent={() => {
      }}
      scheduleConfigs={scheduleConfigs}
    />
  )
}

export {
  renderDepartmentView
}

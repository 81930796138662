import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './assets/styles/BigCalendar.css';
import COLORS from "../BryntumScheduler/colors";
import { getWeekConfig } from '../Schedule/helpers/DateHelper';

const views = {
    MONTH: 'Month',
    CUSTOM: 'Custom'
};

class BigCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.getWeekDays = this.getWeekDays.bind(this);
        this.mapEvents = this.mapEvents.bind(this);
        this.isSameDay = this.isSameDay.bind(this);
    }

    isSameDay(m1, m2) {
        return m1.date() === m2.date() && m1.month() === m2.month() && m1.year() === m2.year()
    }

    mapEvents(events) {
        const mapEvents = {};
        events.forEach((ev) => {
            const startDate = moment(ev.startDate).format('MM-DD-YYYY').toString();
            if (!mapEvents[startDate]) {
                mapEvents[startDate] = [];
            }
            mapEvents[startDate].push(ev);
        });
        return mapEvents;
    }

    getWeekDays(startDate, endDate, currentDate) {
        const { user } = this.props;
        const weeks = [];
        let weekDays = [];
        const weekConfig = getWeekConfig(user);
        let currDate = moment(startDate).startOf(weekConfig).startOf('day');
        const lastDate = moment(endDate).endOf(weekConfig).startOf('day');
        while (currDate.isSameOrBefore(lastDate)) {
            const newDate = currDate.clone().startOf('day');
            const day = newDate.format('D') * 1;
            weekDays.push({
                key: `${weeks.length}${weekDays.length}`,
                isActive: currDate.clone().isBetween(moment(startDate).add(-1, 'day').startOf('day'), moment(endDate).endOf('day')),
                isCurrent: this.isSameDay(newDate, moment(currentDate)),
                firstDisplayDate: this.isSameDay(newDate, moment(startDate)),
                dateDisplay: day,
                monthName: newDate.format('MMM').replace('.', '').toUpperCase(),
                isFirstDayOfMonth: day === 1,
                date: newDate.format('MM-DD-YYYY').toString()
            });
            if (weekDays.length === 7) {
                weeks.push(weekDays);
                weekDays = [];
            }
            currDate = currDate.add(1, "day");
        }
        return weeks;
    };

    eventRenderer(eventRecord, showShiftDuration, breakTotal, breakDetails, showLocation, showRole, showSection, lang) {
        const { selected } = this.props;
        eventRecord.eventColor = eventRecord.status === 'approved' ? COLORS[eventRecord.name] : (COLORS[eventRecord.status] || 'yellow');
        let hours = '';
        if (showShiftDuration) {
            let duration = moment.duration(moment(eventRecord.endDate).diff(moment(eventRecord.startDate)));
            if (eventRecord.breaks) {
                duration = duration.add((-1 * eventRecord.breaks), 'minute');
            }
            hours = `${duration.hours() > 0 ? duration.hours() + 'h' : ''} ${duration.minutes() > 0 ? duration.minutes() + (lang.min || 'min') : ''}`;
        }
        let breakData = [];
        if (eventRecord.breakData && breakDetails) {
            breakData = JSON.parse(eventRecord.breakData);
        }
        let breaks = `${lang.break || 'Break'}: 0${lang.min || 'min'}`;
        if (eventRecord.breaks > 0 && (breakDetails || breakTotal)) {
            if (!breakTotal && breakDetails) {
                breaks = `${lang.breaks || 'Breaks'}:`;
            } else if (eventRecord.breaks > 60) {
                const breakMins = eventRecord.breaks % 60;
                const breakHours = (eventRecord.breaks - breakMins) / 60;
                breaks = `${lang.break || 'Break'}:${breakHours > 0 ? ' ' + breakHours + 'h' : ''}${breakMins > 0 ? ' ' + breakMins + (lang.min || 'min') : ''}`;
            } else {
                breaks = `${lang.break || 'Break'}: ${eventRecord.breaks}${lang.min || 'min'}`
            }
        }
        const name = `${lang[eventRecord.name.toLowerCase()] || eventRecord.name}${eventRecord.name !== 'Scheduled Day Off' ? ` • ${moment(eventRecord.startDate).format('LT')} - ${moment(eventRecord.endDate).format('LT')}` : ''}
                    ${showShiftDuration ? (' • ' + hours) : ''}`;
        return (
            <div key={eventRecord.id} className="padding-top-8">
                <div className={`shift can-be-selected ${eventRecord.eventColor} ${eventRecord.color ? eventRecord.color : ''} ${selected ? 'hover' : ''}`}>
                    <div className="shift-details">
                        <div className="ellipsis position-relative">
                            <span className="nbsp-if-empty" title={name}>
                                {name}
                            </span>
                        </div>
                        {
                            (breakDetails || breakTotal) && (
                                <div className="ellipsis position-relative">
                                    <span className="nbsp-if-empty">
                                        {breaks}
                                    </span>
                                    {
                                        breakData.map((br, key) => (
                                            <div key={key} className="nbsp-if-empty break-details">
                                                • {moment(`${moment(eventRecord.startDate).format('YYYY-MM-DD')} ${br.start}`).format('hh:mm A')} - {moment(`${moment(eventRecord.startDate).format('YYYY-MM-DD')} ${br.end}`).format('hh:mm A')} ({br.duration}{(lang.min || 'min')})
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                        {showRole && (
                            <div className="ellipsis duration">
                                <span className="nbsp-if-empty">• {eventRecord.role}</span>
                            </div>
                        )}
                        {showLocation && (
                            <div className="ellipsis duration">
                                <span className="nbsp-if-empty">• {eventRecord.location} </span>
                            </div>
                        )}
                        {showSection && eventRecord.section && Array.isArray(eventRecord.section) && eventRecord.section.length > 0 && (
                            <div className="ellipsis duration">
                                {eventRecord.section.map(s => <span className={`nbsp-if-empty ${s.zoneColor ? `zone ${s.zoneColor}` : ''}`}>{s.zone}</span>)}
                            </div>
                        )}
                        <div className="ellipsis duration">
                            < span className="nbsp-if-empty">• {eventRecord.userName}</span>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    render() {
        const {
            startDate,
            endDate,
            currentDate,
            events,
            breakTotal,
            breakDetails,
            showRole,
            showSection,
            showLocation,
            showShiftDuration,
            lang,
            user
        } = this.props;
        const weekConfig = getWeekConfig(user);
        const weeks = this.getWeekDays(startDate, endDate, currentDate);
        const mapEvents1 = this.mapEvents(events);
        return (
            <div className={'b-react-monthly-container'} id={this.props.id} ref={el => this.el = el}>
                <div className="header-border">
                    <div className="calendar-header">
                        {weekConfig === 'week' && <div className="calendar-header-cell">{lang.sunday || "SUNDAY"}</div>}
                        <div className="calendar-header-cell">{lang.monday || "MONDAY"}</div>
                        <div className="calendar-header-cell">{lang.tuesday || "TUESDAY"}</div>
                        <div className="calendar-header-cell">{lang.wednesday || "WEDNESDAY"}</div>
                        <div className="calendar-header-cell">{lang.thursday || "THURSDAY"}</div>
                        <div className="calendar-header-cell">{lang.friday || "FRIDAY"}</div>
                        <div className="calendar-header-cell">{lang.saturday || "SATURDAY"}</div>
                        {weekConfig === 'isoWeek' && <div className="calendar-header-cell">{lang.sunday || "SUNDAY"}</div>}
                    </div>
                </div>
                <table>
                    <tbody>
                        {weeks.length > 0 ? weeks.map((week, key) => (
                            <tr key={key} className="month-week">
                                {week.map((day) => (
                                    <td key={day.key}
                                        className={day.isActive ? (day.isCurrent ? "month-tile is-current-month today" : "month-tile is-current-month") : "month-tile"}>
                                        {day.isActive ? (
                                            <div>
                                                <div
                                                    className={day.isCurrent ? "month-tile-header today" : "month-tile-header"}>
                                                    <span
                                                        className="month-tile-header-date"> {day.dateDisplay} {(day.isFirstDayOfMonth || day.firstDisplayDate) && (
                                                            <span className="month-tile-header-month"> {day.monthName} </span>
                                                        )}</span>
                                                </div>
                                                <div className="month-tile-content">
                                                    {
                                                        mapEvents1[day.date] && mapEvents1[day.date].length > 0 ? mapEvents1[day.date].map((eventRecord) => {
                                                            return this.eventRenderer(eventRecord, showShiftDuration, breakTotal, breakDetails, showLocation, showRole, showSection, lang)
                                                        }) : null
                                                    }
                                                </div>
                                            </div>
                                        ) : (<div />)}

                                    </td>
                                ))}
                            </tr>
                        )) : null}
                    </tbody>
                </table>
            </div>
        )
    }
}

BigCalendar.defaultProps = {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    currentDate: moment(),
    currentView: views.MONTH,
    events: [],
    showShiftDuration: true
};
BigCalendar.propTypes = {
    currentView: PropTypes.string,
    events: PropTypes.array,
    showShiftDuration: PropTypes.bool
};

export default BigCalendar;

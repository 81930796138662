import { SCHEDULE_MODES } from "../../../containers/Schedule/Constant";
import EmployeeService from "../../../services/EmployeeService";
import moment from "moment";
import ScheduleService from "../../../services/ScheduleService";
import ArrayHelper from "../../../helpers/ArrayHelper";
import {
  calculateOvertime,
  mapAvailableFromDb,
  mapEvents,
  mapFromDb,
} from "./EventHelper";
import { buildFilters, calculateEstimation, isManager } from "./SchedulerHelper";

function getScheduleFilters(callback) {
  EmployeeService.fetchPeopleFilters(false, true, true).then((response) => {
    this.setState({
      roles: response.data.jobs,
      locations: response.data.locations,
      locationTree: response.data.locationTree,
      displayLocationTree: response.data.locations.length > 1,
      departments: response.data.departments,
      zones: response.data.zones,
    }, callback);
  });
}
function getMonthlySchedules(getAll = false) {
  const { config, mode } = this.state;
  const startDate = moment(config.startDate).format("YYYY-MM-DD");
  const endDate = moment(config.endDate).format("YYYY-MM-DD");

  if (getAll) {
    return ScheduleService.getMonthSchedules(
      "self",
      startDate,
      endDate,
      true
    ).then(async (response) => {
      let hrs = 0;
      let min = 0;
      return mapEvents(response.data, false).map((event) =>
        mapFromDb(event, mode, config, hrs, min, true)
      );
    });
  } else {
    this.setState({ isLoading: true });
    ScheduleService.getMonthSchedules("self", startDate, endDate, getAll)
      .then(async (response) => {
        let hrs = 0;
        let min = 0;
        const selfEvents = mapEvents(response.data).map((event) =>
          mapFromDb(event, mode, config, hrs, min, true)
        );
        const mappedEvents = await ArrayHelper.mapBy(
          selfEvents,
          "resourceId",
          null,
          true
        );
        this.setState({
          mappedEvents,
          selfEvents,
          mapEvents: response.data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          eventsVersion: this.state.eventsVersion + 1,
          resourcesVersion: this.state.resourcesVersion + 1,
          availableEventsVersion: this.state.availableEventsVersion + 1,
          isLoading: false,
        });
      });
  }
}
function getSchedulesForExport() {
  const { config } = this.state;
  const startDate = moment(config.startDate).format("YYYY-MM-DD");
  const endDate = moment(config.endDate).format("YYYY-MM-DD");
  let filters = {
    startDate,
    endDate,
  };
  const filterObj = this.call(buildFilters, true);
  filters = {
    ...filters,
    ...filterObj,
  };
  return ScheduleService.getSchedulesForExport(filters).then(
    async (response) => {
      return response.data;
    }
  );
}
function getSchedule(getTotal = false) {
  const { config, mode, user } = this.state;
  const startDate = moment(config.startDate).format("YYYY-MM-DD");
  const endDate = moment(config.endDate).format("YYYY-MM-DD");
  this.setState({ isLoading: true });
  ScheduleService.getSchedule("self", startDate, endDate, getTotal)
    .then(async (response) => {
      let hrs = 0;
      let min = 0;
      const selfEvents = response.data.events.map((event) =>
        mapFromDb(event, mode, config, hrs, min, true, isManager(user))
      );
      const available =
        (response.data.available &&
          response.data.available.map((event) =>
            mapAvailableFromDb(event, mode)
          )) ||
        [];
      const mappedEvents = await ArrayHelper.mapBy(
        selfEvents,
        "resourceId",
        null,
        true
      );
      response.data.resource[0] = await calculateOvertime(
        response.data.resource[0],
        selfEvents,
        config,
        response.data.rates || {},
        user
      );
      this.setState({
        mappedEvents,
        selfResource: response.data.resource,
        selfEvents,
        rates: response.data.rates || {},
        selfAvailable: available,
        availableLocations: response.data.locations,
        mapLocations: response.data.mapLocations,
        availableJobs: response.data.jobs,
        mapJobs: response.data.mapJobs,
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion: this.state.availableEventsVersion + 1,
        isLoading: false,
      });
    })
    .catch(() => {
      this.setState({
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion: this.state.availableEventsVersion + 1,
        isLoading: false,
      });
    });
}
function getScheduleConfigs() {
  ScheduleService.getScheduleConfigs()
    .then(async (response) => {
      const sections = response.data.sections || {};
      const filters = response.data.filters || [];
      let allZones = {};
      Object.keys(sections).forEach(j => {
        Object.values(sections[j]).forEach(s => {
          if (!allZones[s.value]) {
            allZones[s.value] = s;
          }
        })
      })
      this.setState({
        scheduleConfigs: {
          ...this.state.scheduleConfigs,
          sections,
          filters,
        },
        allZones: Object.values(allZones),
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

function getUser(user, callback) {
  this.setState({
    user,
  });
  if (user.role === "manager") {
    this.call(getScheduleFilters, callback);
  } else {
    callback();
  }
}

function fetchMyDepartmentSchedules() {
  this.setState({ isLoading: true });
  if (this.state.mode === SCHEDULE_MODES.THIS_MONTH) {
    this.call(fetchMyDepartmentSchedulesMonthly);
  } else {
    this.call(fetchMyDepartmentScheduleByDate);
  }
}

function fetchMyDepartmentSchedulesMonthly() {
  const { config, mode, page } = this.state;
  const startDate = moment(config.startDate).format("YYYY-MM-DD");
  const endDate = moment(config.endDate).format("YYYY-MM-DD");
  const filters = this.call(buildFilters, true);

  ScheduleService.fetchMonthDepartmentSchedules({ page, startDate, endDate, ...filters })
    .then(async (response) => {
      const events = mapEvents(response.data).map((event) =>
        mapFromDb(event, mode, config, 0, 0, false)
      );
      this.setState({
        mapEvents: response.data,
        events,
        isLoading: false,
      });
    })
    .catch(() => {
      this.setState({
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        isLoading: false,
      });
    });
}

function fetchMyDepartmentScheduleByDate() {
  const { config, mode } = this.state;
  const startDate = moment(config.startDate).format("YYYY-MM-DD");
  const endDate = moment(config.endDate).format("YYYY-MM-DD");
  const filters = this.call(buildFilters, true);

  ScheduleService.fetchMyDepartmentSchedules(
    this.state.page,
    100,
    startDate,
    endDate,
    filters
  )
    .then(async (response) => {
      const events =
        (response.data.events &&
          response.data.events.map((event) =>
            mapFromDb(event, mode, config, 0, 0, false)
          )) ||
        [];
      this.setState({
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        resources: response.data.resources,
        total_pages: response.data.total_pages,
        page: response.data.page,
        events,
        isLoading: false,
      });
    })
    .catch(() => {
      this.setState({
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion: this.state.availableEventsVersion + 1,
        isLoading: false,
      });
    });
}

function fetchMonthlySchedules(isSearch = false, getAll = false) {
  const { config, mode } = this.state;
  const startDate = moment(config.startDate).format("YYYY-MM-DD");
  const endDate = moment(config.endDate).format("YYYY-MM-DD");
  const filters = this.call(buildFilters, true);

  if (
    Object.keys(filters).length > 0 ||
    !isSearch ||
    this.state.viewIsFiltered
  ) {
    this.setState({
      isLoading: !getAll,
      viewIsFiltered: Object.keys(filters).length > 0,
    });
    filters.page = this.state.page;
    filters.startDate = startDate;
    filters.endDate = endDate;
    filters.getEstimation = !getAll;
    filters.getAll = getAll;

    if (getAll)
      return ScheduleService.fetchMonthSchedules(
        filters
      ).then(async (response) =>
        mapEvents(response.data, false).map((event) =>
          mapFromDb(event, mode, config, 0, 0, false)
        )
      );
    else
      ScheduleService.fetchMonthSchedules(filters)
        .then(async (response) => {
          const events = mapEvents(response.data.events).map((event) =>
            mapFromDb(event, mode, config, 0, 0, false)
          );
          this.setState(
            {
              mapEvents: response.data.events,
              rates: response.data.rates || {},
              events,
              estimation: response.data.estimation,
              isLoading: false,
              departmentBudget: response.data.departmentBudget || 0,
            },
            () => {
              this.call(calculateEstimation);
            }
          );
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
  }
}

function fetchSchedules(isSearch = false) {
  const { config, mode } = this.state;
  const startDate = moment(config.startDate).format("YYYY-MM-DD");
  const endDate = moment(config.endDate).format("YYYY-MM-DD");
  const filters = this.call(buildFilters);
  if (filters !== "" || !isSearch || this.state.viewIsFiltered) {
    this.setState({ isLoading: true, viewIsFiltered: filters !== "" });
    ScheduleService.fetchSchedules(
      this.state.page,
      100,
      startDate,
      endDate,
      filters,
      moment().utcOffset()
    )
      .then(async (response) => {
        const events = response.data.events
          ? response.data.events.map((event) => {
            return mapFromDb(event, mode, config, 0, 0, false);
          })
          : [];
        const available =
          (response.data.available &&
            response.data.available.map((event) =>
              mapAvailableFromDb(event, mode)
            )) ||
          [];
        this.setState(
          {
            rates: response.data.rates || {},
            eventsVersion: this.state.eventsVersion + 1,
            availableEventsVersion: this.state.availableEventsVersion + 1,
            resourcesVersion: this.state.resourcesVersion + 1,
            availableLocations: response.data.locations,
            mapLocations: response.data.mapLocations,
            availableJobs: response.data.jobs,
            mapJobs: response.data.mapJobs,
            resources: response.data.resources,
            departmentBudget: response.data.departmentBudget,
            total_pages: response.data.total_pages,
            page: response.data.page,
            estimation: {
              ...response.data.estimation,
              totalEmployees: response.data.total,
            },
            events,
            available,
            isLoading: false,
          },
          () => {
            this.call(calculateEstimation);
          }
        );
      })
      .catch(() => {
        this.setState({
          eventsVersion: this.state.eventsVersion + 1,
          resourcesVersion: this.state.resourcesVersion + 1,
          availableEventsVersion: this.state.availableEventsVersion + 1,
          isLoading: false,
        });
      });
  }
}

export {
  getMonthlySchedules,
  getScheduleFilters,
  getSchedule,
  getUser,
  fetchMyDepartmentSchedules,
  fetchMyDepartmentSchedulesMonthly,
  fetchMonthlySchedules,
  fetchSchedules,
  getScheduleConfigs,
  getSchedulesForExport,
};

export const columns = [
  { value: "Name", field: "employeeName", width: 500, type: "string" },
  { value: "Employee Code", field: "employeeCode", width: 500, type: "string" },
  { value: "Schedule", field: "name", width: 500, type: "string" },
  { value: "Start Date", field: "startDate", width: 500, type: "string" },
  { value: "End Date", field: "endDate", width: 500, type: "string" },
  { value: "Duration", field: "duration", width: 500, type: "string" },
  { value: "Breaks", field: "breaks", width: 500, type: "string" },
  { value: "Duration without break", field: "durationWithouBreak", width: 500, type: "string" },
  { value: "Hourly Rate", field: "hourlyRate", width: 500, type: "string" },
  { value: "Amount", field: "amount", width: 500, type: "string" },
  { value: "Location Code", field: "locationCode", width: 500, type: "string" },
  { value: "Location", field: "location", width: 500, type: "string" },
  { value: "State", field: "locationState", width: 500, type: "string" },
  { value: "Job Code", field: "jobCode", width: 500, type: "string" },
  { value: "Job", field: "job", width: 500, type: "string" },
  { value: "Department Code", field: "departmentCode", width: 500, type: "string" },
  { value: "Department", field: "department", width: 500, type: "string" },
];

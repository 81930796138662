import React from "react";
import AuthService from "../../services/AuthService";
import { ForgotPasswordForm } from "../../components/Forms/ForgotPasswordForm";
import Toast from "../../components/Blocks/Toast/Toast";
import ForgotPasswordCodeConfirmModal from "../../components/Modals/ForgotPasswordCodeConfirmModal";
// Images
import WhiteLogo from "../../general/assets/images/arcon-white.png";
import StorageManager from "../../helpers/StorageManager";

class ForgotPassword extends React.Component {
  state = {
    error: null,
    success: false,
    values: {},
    openModal: false,
    user: null
  };

  submitEmployeeNumber = values => {
    return AuthService.forgotPassword(values)
      .then(response => {
        if (response.data && response.data.data) {
          this.setState({
            user: response.data.data
          });
        }
        this.setState({
          values,
          success: true,
          openModal: true
        });
      })
      .catch(e => {
        this.showError(
          e.response && e.response.status === 402
            ? "No valid email address found on your profile. Please ask your manager to reset your password."
            : "Wrong employee number",
          false,
          false
        );
      });
  };

  submitVerificationCode = values => {
    if (this.state.user) {
      const user = Object.assign(this.state.user, values);
      return AuthService.verifyCode(user)
        .then(() => {
          this.setState(
            {
              values,
              success: true,
              openModal: false
            },
            () => {
              window.location = `/reset-password/${user.confirmationToken}`;
            }
          );
        })
        .catch(e => {
          const { status } = e.response;
          this.showError(
            status === 400
              ? "Invalid verification code!"
              : "Too many retries, please request a new confirmation code!",
            false,
            status === 400
          );
        });
    }
    this.showError("Invalid verification code!", false, false);
  };

  showError = (error, success, openModal) => {
    this.setState(
      {
        error: null
      },
      () => {
        this.setState({
          error,
          success,
          openModal
        });
      }
    );
    /*this.setState({
            error: error.response && error.response.data ? error.response.data : 'It\'s not possible processing your request at this moment!',
            openModal: false,
        });*/
  };

  toastDismiss = () => {
    this.setState({
      error: null,
      success: null
    });
  };

  componentWillMount() {
    let picture = StorageManager.get("login-picture-index");
    if (!picture) {
      picture = 1;
      StorageManager.set("login-picture-index", 2);
    } else {
      picture = parseInt(picture);
      StorageManager.set(
        "login-picture-index",
        picture === 3 ? 1 : picture + 1
      );
    }

    this.setState({ picture });
  }

  render() {
    const { error, openModal, picture } = this.state;

    const toastError = error && !openModal && (
      <Toast
        title="Change password failed!"
        message={error}
        dismiss={this.toastDismiss}
      />
    );

    return (
      <div className={`login-section picture-${picture}`}>
        {toastError}

        <div>
          <img src={WhiteLogo} className="" alt="logo" />
          <ForgotPasswordForm
            submitEmployeeNumber={this.submitEmployeeNumber}
          />
        </div>
        <ForgotPasswordCodeConfirmModal
          error={error}
          submitVerificationCode={this.submitVerificationCode}
          email={this.state.user ? this.state.user.email : ""}
          isOpen={this.state.openModal}
        />
      </div>
    );
  }
}

// ResetPassword.propTypes = {
// };

export default ForgotPassword;

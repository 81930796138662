import React, { Component } from "react";
import * as moment from "moment";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "./../../../general/assets/styles/react-select-search-style.css";

import DatePicker from "react-datepicker";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";
import ReportService from "../../../services/ReportService";
import { getWeekStartDay } from "../../Schedule/helpers/DateHelper";

export default class BudgetForm extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      reportOptions,
      reportTypeOptions,
      filterData: { locations, departments }
    } = props;
    this.state = {
      isSubmitting: false,
      locations,
      departments,
      values: {
        report: reportOptions[0],
        reportType: reportTypeOptions[0],
        startDate: moment(),
        endDate: moment(),
        department: null,
        location: null
      },
      focusedInput: null
    };
  }

  onGenerate = () => {
    this.props.onSubmit(this.state.values);
  };

  async componentDidMount() {
    let locations = await ReportService.searchLocations();
    let departments = await ReportService.searchDepartments();

    locations = locations.data;
    departments = departments.data.departments;

    this.setState({ locations, departments });

    const { autoTriggerFirst, onSubmit } = this.props;
    if (autoTriggerFirst && onSubmit) {
      this.onGenerate();
    }
  }

  onInputChange = ({ target }) => {
    const { values } = this.state;
    values[target.name] = target.value;
    this.setState({ values });
  };

  onSubmit = event => {
    event.preventDefault();
    this.onGenerate();
  };

  onDateRangeFocusChange = focusedInput => {
    this.setState({
      focusedInput: !focusedInput ? null : focusedInput
    });
  };

  onDateRangeChange = ({ startDate, endDate }) => {
    const { values } = this.state;
    if (startDate) values.startDate = startDate;
    if (endDate) values.endDate = endDate;
    this.setState({ values });
  };

  onMonthChange = month => {
    this.setState(({ values }) => ({
      values: {
        ...values,
        startDate: moment(month),
        endDate: moment(month).endOf("month")
      },
      focusedInput: null
    }));
  };

  onSelectChange = (type, value) => {
    this.setState(({ values }) => ({
      values: { ...values, [type]: value }
    }));
  };

  render() {
    const { values, focusedInput, locations, departments } = this.state;
    const { reportOptions, reportTypeOptions, isLoading, user } = this.props;

    return (
      <form className="form-horizontal" onSubmit={this.onSubmit}>
        {/* NOTE: for debuging */}
        {/* {JSON.stringify(values)} */}
        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Report:
          </label>
          <div className="col-md-8">
            <Select
              name="report"
              isDisabled={isLoading}
              placeholder="Select Report..."
              onChange={v => this.onSelectChange("report", v)}
              options={reportOptions}
              value={values.report}
            />
          </div>
        </div>
        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Report Type:
          </label>
          <div className="col-md-8">
            <Select
              name="reportType"
              placeholder="Select Report Type..."
              options={reportTypeOptions}
              onChange={v => this.onSelectChange("reportType", v)}
              isDisabled={isLoading}
              value={values.reportType}
            />
          </div>
        </div>
        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Date Range:
          </label>
          <div className="col-md-8">
            {values.reportType.value === "monthly" ? (
              <DatePicker
                disabled={isLoading}
                popperModifiers={{
                  offset: { offset: "20px, 10px" }
                }}
                selected={values.startDate.toDate()}
                onFocus={() => this.setState({ focusedInput: "monthPicker" })}
                dateFormat="MMMM"
                onClickOutside={() => {
                  this.setState({ focusedInput: null });
                }}
                open={focusedInput === "monthPicker"}
                showMonthYearPicker
                onChange={this.onMonthChange}
              />
            ) : (
                <DateRangePicker
                  firstDayOfWeek={getWeekStartDay(user)}
                  startDateId="startDate"
                  endDateId="endDate"
                  startDate={values.startDate}
                  endDate={values.endDate}
                  onDatesChange={this.onDateRangeChange}
                  onFocusChange={this.onDateRangeFocusChange}
                  focusedInput={focusedInput}
                  isOutsideRange={() => {
                    return false;
                  }}
                  disabled={isLoading}
                  small={true}
                />
              )}
          </div>
        </div>
        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Location:
          </label>
          <div className="col-md-8">
            <Select
              placeholder="Type to search for Locations..."
              getOptionLabel={o => o.name}
              onChange={v => this.onSelectChange("location", v)}
              options={locations}
              isDisabled={isLoading}
              isClearable={true}
            />
          </div>
        </div>

        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Department:
          </label>
          <div className="col-md-8">
            <Select
              getOptionLabel={o => o.name}
              placeholder="Type to search for Departments..."
              onChange={v => this.onSelectChange("department", v)}
              options={departments}
              isDisabled={isLoading}
              isClearable={true}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 mt-3">
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary"
            >
              {isLoading ? "Generating..." : "Generate"}
            </button>
          </div>
        </div>
      </form>
    );
  }

  renderSelectOptions(options) {
    return options.map(({ name, label }) => (
      <option key={name} value={name}>
        {label}
      </option>
    ));
  }
}

import React from 'react';


const NotFound = () => {
  return (
    <div>
      <h4>Not Found!</h4>
    </div>
  );
};

export default NotFound;

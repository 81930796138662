import ForecastChart from "../../ForecastChart";
import React from "react";
import Collapsible from "react-collapsible";

function renderForecastView() {
  const {
    mode,
    config,
    displayLocationTree,
    selectedLocationId,
    selectedLocations,
    selectedDepartments,
    selectedTreeLocations,
    showTree,
    openForecastView,
    user
  } = this.state;
  let forecastLocations = [];
  if (displayLocationTree && showTree && selectedTreeLocations.length) {
    forecastLocations = JSON.stringify(selectedTreeLocations);
  } else if (selectedLocations.length) {
    forecastLocations = JSON.stringify(selectedLocations);
  } else if (selectedLocationId && selectedLocationId !== "") {
    forecastLocations = selectedLocationId;
  }
  return (
    <Collapsible
      className="mb-3 mt-3 scheduler-collapse"
      openedClassName="mb-3 mt-3 scheduler-collapse forecast"
      open={openForecastView}
      onClosing={() => {
        this.setState({
          openForecastView: false
        })
      }}
      onOpening={() => {
        this.setState({
          openForecastView: true
        })
      }}
      trigger={(
        <span>
          Forecasting <span className="caret" />
        </span>
      )
      }
    >
      <ForecastChart
        mode={mode}
        selectedDepartments={selectedDepartments}
        locations={forecastLocations}
        config={config}
        user={user}
      />
    </Collapsible>
  )
}

export {
  renderForecastView
}

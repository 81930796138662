import CrudPage from "../../components/CrudPage";

import CrudService from "../../services/CrudService";

const {companies} = require("./sampleData");

const companiesService = new CrudService("companies");

export default class Companies extends CrudPage {
  name = "Company";
  fields = [
    { name: "id", label: "ID", type: "text", showOnForm: false },
    { name: "externalId", label: "External Id", type: "text", showOnTable: false },
    { name: "code", label: "Code", type: "text" },
    { name: "name", label: "Name", type: "text" },
    { name: "status", label: "Active", type: "toggle", defaultVal: false }
  ];
  enableImport = true;
  importSampleLink =
    "https://dxv8p7y79jw20.cloudfront.net/Companies+Sample.csv";

  sampleData = companies;
  searchPlaceHolder="Search by name or code...";

  fetch(page, search) {
    return companiesService.list(page, search);
  }

  create(data) {
    return companiesService.create(data);
  }

  update(data) {
    return companiesService.update(data);
  }

  delete(id) {
    return companiesService.remove(id);
  }

  import(file) {
    return companiesService.importSample(file);
  }
}

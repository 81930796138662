import React from "react";
import ReactSelect from "react-select";

export default class TimezoneDropdownItem extends React.Component {
  render() {
    const {
      name,
      description,
      options,
      selected,
      onChange,
      showLoading,
      disabled = false
    } = this.props;

    const selectedOption = selected
      ? options.find(o => o.id === selected)
      : null;

    return (
      <div className="container">
        <div className="row">
          <div
            className={
              description
                ? "col-md-6 col-sm-8 col-xs-12"
                : "col-md-4 col-sm-4 col-xs-12"
            }
          >
            <span className="lead-sm">{name}</span>
            <p className="text-muted">{description}</p>
          </div>
          <div
            className={
              description
                ? "col-md-6 col-sm-4 col-xs-12"
                : "col-md-8 col-sm-8 col-xs-12"
            }
          >
            <div className="pull-right w-100">
              <ReactSelect
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                options={options}
                value={selectedOption}
                getOptionLabel={o => o.text}
                getOptionValue={o => o.id}
                placeholder="Select Timezone"
                onChange={o => onChange(o.id)}
                isDisabled={disabled || showLoading}
              />
              <span className={!showLoading ? "hidden" : ""}>
                <i className="fas fa-spinner fa-spin" /> Saving...
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import Toast from "../../components/Blocks/Toast/Toast";
import { UsageDataFilterForm } from "../../components/Forms/ReportsForm";
import UsageDataTable from "../../components/Tables/ReportsTables/UsageDataTable";
import ReportService from "../../services/ReportService";

// Styles
import "./assets/styles/Reports.css";
import "./assets/styles/Export.css";
import ReactPaginate from "react-paginate";

// import usageData from "./mock1";

export default class UsageDataPage extends Component {
  state = {
    isLoading: false,
    successMessage: "",
    reportData: [],
    reportType: "",
    dateRangeType: "",
    startDate: "",
    endDate: "",
    usageData: [],
    total: 0,
    filters: {
      perPage: 10,
      userIds: [],
      locationIds: [],
      departmentIds: []
    },
    page: 0,
    initiated: false
  };

  setPage = ({ selected }) => {
    if (!this.state.initiated) return;
    this.setState({ page: selected + 1, isLoading: true }, this.getUsageData);
  };

  async getUsageData(refresh = false) {
    const { filters, page } = this.state;
    if (refresh) this.setState({ page: 1 });
    try {
      const usageData = await ReportService.getUsageData({
        ...filters,
        userIds: JSON.stringify(filters.userIds),
        page: refresh ? 1 : page
      });
      this.setState({
        usageData: usageData.data.data,
        total: Math.ceil(usageData.data.totalItems / filters.perPage)
      });
    } catch (e) {
      console.log(e);
    }

    this.setState({
      isLoading: false
    });
  }

  componentDidMount() {
    this.setState({ initiated: true });
  }

  filter = newFilters => {
    return this.setState(
      ({ filters }) => ({
        filters: { ...filters, ...newFilters },
        isLoading: true
      }),
      () => this.getUsageData(true)
    );
  };

  render() {
    const { successMessage, usageData, total, page, isLoading } = this.state;
    const { filterData, user } = this.props;

    const successToast = successMessage ? (
      <Toast
        status="success"
        title="Schedule Usage Data"
        message={successMessage}
        dismiss={() => this.setState({ successMessage: null })}
      />
    ) : (
        ""
      );

    const Loader = () => <div className="text-muted my-4">Loading...</div>;

    return (
      <div className="e-panel card">
        <div className="card-header">
          <h4>Usage Data</h4>
        </div>
        <div className="col-12">
          {successToast}
          <UsageDataFilterForm filterData={filterData} filter={this.filter} user={user} />

          {isLoading ? <Loader /> : <UsageDataTable resources={usageData} />}

          <div className="mb-4 d-flex justify-content-center">
            <ReactPaginate
              previousLabel={"‹"}
              nextLabel={"›"}
              breakLabel={"..."}
              breakLinkClassName={"page-link"}
              forcePage={page - 1}
              breakClassName={"page-item"}
              pageCount={total}
              initialPage={0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.setPage}
              containerClassName={"pagination mt-3 mb-0"}
              pageLinkClassName={"page-link"}
              pageClassName={"page-item"}
              activeClassName={"active"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
            />
          </div>
        </div>
      </div>
    );
  }
}

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
    breakLengthOptions,
    validateBreakTimes,
    getEligibleEnd,
    getEligibleStart
} from "./BreakHelper";

const getOptionValue = o => o && o.toString();

function usePrev(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

const Break = ({
    index,
    breakItem: b,
    dispatchBreak,
    eventStartTime,
    eventEndTime,
    setError,
    breaks
}) => {
    const { start, end, duration } = b;

    const prevStart = usePrev(start);
    const prevEnd = usePrev(end);

    const [changed, setChanged] = useState(false);

    const [startOpt, setStartOpt] = useState([]);
    const [endOpt, setEndOpt] = useState([]);

    useEffect(() => {
        const iterator = getEligibleStart(eventStartTime);
        const endLoopTime = getEligibleEnd(eventEndTime, duration);

        const newStartOpt = [];
        const newEndOpt = [];

        while (iterator.isSameOrBefore(endLoopTime)) {
            newStartOpt.push(moment(iterator));
            newEndOpt.push(moment(iterator).add(duration, "minutes"));
            iterator.add(5, "minutes");
        }

        setStartOpt(newStartOpt);
        setEndOpt(newEndOpt);
    }, [duration, eventStartTime, eventEndTime]);

    useEffect(() => {
        if (
            duration &&
            start &&
            end &&
            prevStart &&
            prevEnd &&
            (start.format("HH:mm") !== prevStart.format("HH:mm") ||
                end.format("HH:mm") !== prevEnd.format("HH:mm"))
        ) {
            setChanged(true);
            setTimeout(() => {
                setChanged(false);
            }, 700);
        }
    }, [duration, start, end, prevEnd, prevStart]);

    const onChangeStart = ({ target: { value } }) => {
        const newStartTime = moment(value);
        const newEndTime = moment(newStartTime).add(duration, "minutes");
        const isValid = validateBreakTimes(
            breaks,
            index,
            newStartTime,
            newEndTime
        );

        dispatchBreak({
            type: "start",
            index,
            value: isValid === true ? newStartTime : start
        });

        if (isValid !== true) setError(isValid);
        else setError(false);
    };

    const onChangeEnd = ({ target: { value } }) => {
        const newEndTime = moment(value);
        const newStartTime = moment(newEndTime).add(duration, "minutes");

        const isValid = validateBreakTimes(
            breaks,
            index,
            newStartTime,
            newEndTime
        );

        dispatchBreak({
            type: "end",
            index,
            value: isValid === true ? newEndTime : moment(end)
        });

        if (isValid !== true) setError(isValid);
        else setError(false);
    };

    const onChangeDuration = ({ target: { value } }) => {
        dispatchBreak({ type: "duration", index, value });
    };

    return (
        <div className={`${changed ? "changed" : ""}`}>
            <div>
                <span className="label">Break</span>
                <select value={duration} onChange={onChangeDuration}>
                    {breakLengthOptions.map(bL => (
                        <option key={bL} label={bL} value={bL} />
                    ))}
                </select>
            </div>

            {start && (
                <div>
                    <span className="label">Start Time</span>
                    <select
                        disabled={startOpt.length === 0}
                        onChange={onChangeStart}
                        value={getOptionValue(start)}
                    >
                        {startOpt.length === 0 && (
                            <option label="Break too long" />
                        )}
                        {startOpt.map((o, i) => (
                            <option
                                key={i}
                                value={getOptionValue(o)}
                                label={o.format("hh:mm A")}
                            />
                        ))}
                    </select>
                </div>
            )}

            {end && (
                <div>
                    <span className="label">End Time</span>
                    <select
                        disabled={startOpt.length === 0}
                        onChange={onChangeEnd}
                        value={getOptionValue(end)}
                    >
                        {startOpt.length === 0 && (
                            <option label="Break too long" />
                        )}
                        {endOpt.map((o, i) => (
                            <option
                                key={i}
                                value={getOptionValue(o)}
                                label={o.format("hh:mm A")}
                            />
                        ))}
                    </select>
                </div>
            )}
            <button onClick={() => dispatchBreak({ type: "delete", index })}>
                <i className="fas fa-trash" />
            </button>
        </div>
    );
};

export default Break;

import React, {Component} from "react";
import BigCalendar from "../BigCalendar/BigCalendar";

export default class LeavesCalendar extends Component {
    render() {
        const {
            startDate,
            endDate,
            events = [],
            showSeeAll,
            filterByDate
        } = this.props;

        return (
            <div className="card" style={{height: "calc(100% - 30px)", width: "100%"}}>
                <div className="card-header">
                    <h4>Employee <b>Scheduled day off</b> for period <b>{startDate.format("DD MMMM YYYY")} - {endDate.format("DD MMMM YYYY")}</b></h4>
                </div>

                <div className="card-body">
                    { showSeeAll && (
                        <button className="btn btn-default mb-3" onClick={() => {filterByDate({startDate: null, endDate: null})}}>
                            <i className="fas fa-eye" /> See All
                        </button>
                    )}
                    <BigCalendar
                        events={events}
                        startDate={startDate}
                        endDate={endDate}
                        currentView={'Custom'}
                        lang={{}}
                        showShiftDuration={false}
                        showLocation={true}
                        showRole={true}
                        selected={true}
                    />
                </div>
            </div>
        );
    }
}

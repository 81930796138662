import React from "react";
export default class NotificationSection extends React.Component {
    render() {
        const { id, title, info, children } = this.props;
        return (
            <div id={id} className="card-body">
                <div className="col-12">
                    <h5 className="border-bottom py-2 px-0">{title}</h5>
                </div>
                {info && this.renderInfo()}
                {children}
            </div>
        );
    }

    renderInfo() {
        const { info } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <span className="lead-sm text-muted">{info}</span><p/>
                    </div>
                </div>
            </div>
        );
    }
}

import React, { Component } from "react";
import Modal from "react-modal";

// Components
import Toast from "../../components/Blocks/Toast";
import ComposeMsgForm from "../../components/Forms/ComposeMsgForm/ComposeMsgForm";

// Services
import EmployeeService from "../../services/EmployeeService";
import customModalStyles from "./customStyle";

export default class ComposeMessageModal extends Component {
  state = {
    userDetails: {},
    error: null,
    success: null,
    firstName: "",
    lastName: "",
    receiver: "",
    attachment: null
  };

  fetchEmployeeDetails() {
    const userID = this.props.id;

    EmployeeService.searchUsers(userID)
      .then(res => {
        const user = res.data.find(u => u.employee_code === userID);
        if (user) {
          this.setState({
            firstName: user.first_name || "",
            lastName: user.last_name || "",
            receiver: user.employee_code || ""
          });
        } else {
          this.setState({
            error: (
              <div>
                <p>404, User not found!</p>
                <button
                  onClick={this.props.closeModal}
                  className="btn btn-info"
                >
                  Go back
                </button>
              </div>
            )
          });
        }
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    this.fetchEmployeeDetails();
  }

  toastDismiss = () => {
    this.setState({
      error: null,
      success: null
    });
  };

  submitMsg = async fields => {
    const { attachment } = this.state;
    const message = {
      body: fields.body,
      receiver: [this.state.receiver]
    };
    if (attachment) {
      if (attachment.preview.type === "url") {
        message.attachmentUrl = attachment.url;
      } else {
        message.attachment = attachment;
      }
    }
    return EmployeeService.sendMessage(message)
      .then(this.props.closeModal)
      .catch(err => {
        console.log("Compose err: ", err);
        this.setState({
          attachment: null
        });
      });
  };

  saveVideoModal = attachment => {
    this.setState({
      attachment
    });
  };

  onUploadFilesChanged = files => {
    if (files[0]) {
      this.setState({
        attachment: files[0]
      });
    } else {
      this.setState({
        attachment: null
      });
    }
  };

  removeAttachment = () => {
    this.setState({
      attachment: null
    });
  };

  render() {
    const { error, success, firstName, lastName, attachment } = this.state;
    const { isOpen, closeModal } = this.props;

    const errorToast = error && (
      <Toast
        title="Compose new message error!"
        message={error}
        dismiss={this.toastDismiss}
      />
    );
    const successToast = success && (
      <Toast
        status="success"
        title="Message has been sent."
        message={success}
        dismiss={this.toastDismiss}
      />
    );

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Compose message"
        shouldCloseOnOverlayClick={false}
      >
        <div className="">
          {errorToast}
          {successToast}
          <div className="">
            <h4 className="mb-3">Compose New Message</h4>

            <ComposeMsgForm
              firstName={firstName}
              lastName={lastName}
              submitMsg={this.submitMsg}
              onUploadFilesChanged={this.onUploadFilesChanged}
              saveVideoModal={this.saveVideoModal}
              removeAttachment={this.removeAttachment}
              attachment={attachment}
              goBack={closeModal}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

import moment from "moment";
import COLORS from "../../BryntumScheduler/colors";
import {
    MYSQL_DATETIME_FORMAT,
    SCHEDULE_MAPPED_EVENTS,
    SCHEDULE_MODES,
    SCHEDULE_STATUSES,
    TABS
} from "../../../containers/Schedule/Constant";
import CostCalculation from "../../../helpers/CostCalculation";
import Breaks from "../../../containers/Schedule/Breaks";
import each from "each.js";
import { canModifyShift } from "./SchedulerHelper";
const costCalculation = new CostCalculation();

const mapEvents = (events, extra = true) =>
    Object.keys(events).reduce((previous, date) => {
        const dateEvents = events[date].events;
        const dateTotal = events[date].total;

        const newEvents = [
            ...previous,
            ...dateEvents.map(e => ({
                start: moment(e.startDate).toDate(),
                end: moment(e.endDate).toDate(),
                ...e
            }))
        ];

        if (dateEvents.length < dateTotal && extra)
            for (let i = 0; i < dateTotal - dateEvents.length; i++) {
                newEvents.push({
                    start: moment(date)
                        .endOf("day")
                        .toDate(),
                    end: moment(date)
                        .endOf("day")
                        .toDate(),
                    status: "",
                    id: -1,
                    name: ""
                });
            }

        return newEvents;
    }, []);
const eventIsOnDates = (eventStartDate, eventEndDate, startDate, endDate) => {
    return (
        (startDate &&
            endDate &&
            moment(startDate).isSameOrBefore(moment(eventStartDate), 'minutes') &&
            moment(endDate).isSameOrAfter(moment(eventEndDate), 'minutes')) ||
        (!endDate && startDate && moment(startDate).isSameOrBefore(moment(eventStartDate), "minutes")) ||
        (endDate && !startDate && moment(endDate).isSameOrAfter(moment(eventEndDate), "minutes"))
    );
};

const mapFromDb = (event, mode, config, hrs, min, self = true, isManager = true) => {
    const { startDate, endDate } = config;
    event.break1 = 0;
    event.break1StartTime = null;
    event.break1EndTime = null;
    event.break2 = 0;
    event.break2StartTime = null;
    event.break2EndTime = null;
    event.break3 = 0;
    event.break3StartTime = null;
    event.break3EndTime = null;
    event.break4 = 0;
    event.break4StartTime = null;
    event.break4EndTime = null;
    event.startDate = moment(event.startDate).toDate();
    event.endDate = moment(event.endDate).toDate();
    event.currentDate = event.startDate;
    try {
        event.sectionId = JSON.parse(event.sectionId);
        event.sectionData = JSON.parse(event.sectionData);

    } catch (e) {
        
    }
    if (mode !== SCHEDULE_MODES.TODAY) {
        event.width = 100;
    }
    if (event.breakData) {
        try {
            const breakData = JSON.parse(event.breakData);
            if (breakData.length) {
                for (let i = 0; i < breakData.length; i++) {
                    const b = breakData[i];
                    if (b.duration && b.start && b.end) {
                        event[`break${i + 1}`] = b.duration;
                        event[`break${i + 1}StartTime`] = b.start;
                        event[`break${i + 1}EndTime`] = b.end;
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    event.eventColor = event.status === 'approved' ? (event.color ? event.color : COLORS[event.name]) : COLORS[event.status];
    if (event.status === 'released') {
        event.cls = 'released';
        event.editable = false;
        event.resizable = false;
        event.draggable = false;
    } else if (event.sectionData && Array.isArray(event.sectionData) && event.sectionData.length > 0) {
        event.cls = event.sectionData.map(s => s.zoneColor).join(' ');
    } else {
        event.cls = '';
    }
    if (self && !isManager && !canModifyShift(event, self ? TABS.MY_SHIFT : TABS.MY_EMPLOYEES, isManager)) {
        event.resizable = false;
        event.draggable = false;
    }
    event.resizable = mode === SCHEDULE_MODES.TODAY &&
        (event.isUnavailable === 0 || (self && event.name !== SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF));
    event.draggable = event.isUnavailable === 0 || self;
    if (self && eventIsOnDates(event.startDate, event.endDate, startDate, endDate)) {
        let duration = moment.duration(moment(event.endDate).diff(moment(event.startDate)));
        if (event.breaks) {
            duration = duration.add((-1 * event.breaks), 'minute');
        }
        hrs += duration.hours();
        min += duration.minutes();
        if (min >= 60) {
            hrs += 1;
            min -= 60;
        }
    }
    return event;
};
const mapAvailableFromDb = (event, mode) => {
    event.resourceId = '0';//'available-shift-1';
    event.eventStyle = "hollow";
    event.break1 = 0;
    event.break1StartTime = null;
    event.break1EndTime = null;
    event.break2 = 0;
    event.break2StartTime = null;
    event.break2EndTime = null;
    event.break3 = 0;
    event.break3StartTime = null;
    event.break3EndTime = null;
    event.break4 = 0;
    event.break4StartTime = null;
    event.break4EndTime = null;
    if (mode !== SCHEDULE_MODES.TODAY) {
        event.width = 100;
    }
    if (event.breakData) {
        try {
            const breakData = JSON.parse(event.breakData);
            if (breakData.length) {
                for (let i = 0; i < breakData.length; i++) {
                    const b = breakData[i];
                    if (b.duration && b.start && b.end) {
                        event[`break${i + 1}`] = b.duration;
                        event[`break${i + 1}StartTime`] = b.start;
                        event[`break${i + 1}EndTime`] = b.end;
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    event.startDate = moment(event.startDate).toDate();
    event.endDate = moment(event.endDate).toDate();
    event.currentDate = moment(event.startDate).toDate();
    event.eventColor = event.status === 'approved' ? (event.color ? event.color : COLORS[event.name]) : COLORS[event.status];
    event.resizable = mode === SCHEDULE_MODES.TODAY && event.isUnavailable === 0;
    event.draggable = event.isUnavailable === 0;
    return event
};
const calculateOvertime = async (resource, events, config, rates, user) => {
    resource.hrs = {
        total: { hrs: 0, min: 0 },
        ovt: { hrs: 0, min: 0 },
        regularCost: 0,
        otCost: 0
    };
    costCalculation.setWeekStart(user.weekConf);
    const result = await costCalculation.calculateCost(
        config.startDate,
        config.endDate,
        events,
        resource.locationState,
        resource.salaryHourly === "H",
        rates[resource.id] || {},
        true
    );
    const cost = result.cost;
    resource.hrs.ovt.hrs = cost.ovt.hrs;
    resource.hrs.ovt.min = cost.ovt.min;
    resource.hrs.total.hrs = cost.reg.hrs;
    resource.hrs.total.min = cost.reg.min;
    resource.hrs.regularCost = cost.reg.cost;
    resource.hrs.otCost = cost.ovt.cost;
    return resource;
};
const eventHasChanged = eventRecord => {
    const { originalData, data } = eventRecord;
    return (
        originalData.id !== data.id ||
        originalData.resourceId !== data.resourceId ||
        originalData.name !== data.name ||
        moment(originalData.startDate).format("HH:mm") !==
        moment(data.startDate).format("HH:mm") ||
        moment(originalData.endDate).format("HH:mm") !==
        moment(data.endDate).format("HH:mm") ||
        moment(originalData.currentDate).format("YYYY-MM-DD") !==
        moment(data.currentDate).format("YYYY-MM-DD") ||
        originalData.break1 !== data.break1 ||
        originalData.break1StartTime !== data.break1StartTime ||
        originalData.break1EndTime !== data.break1EndTime ||
        originalData.break2 !== data.break2 ||
        originalData.break2StartTime !== data.break2StartTime ||
        originalData.break2EndTime !== data.break2EndTime ||
        originalData.break3 !== data.break3 ||
        originalData.break3StartTime !== data.break3StartTime ||
        originalData.break3EndTime !== data.break3EndTime ||
        originalData.break4 !== data.break4 ||
        originalData.break4StartTime !== data.break4StartTime ||
        originalData.break4StartTime !== data.break4StartTime ||
        originalData.locationId !== data.locationId ||
        originalData.jobId !== data.jobId ||
        (originalData.notes !== data.notes && (originalData.notes !== null)) ||
        originalData.sectionId !== data.sectionId
    );
};
const processEventSave = event => {
    const {
        id,
        resourceId,
        name,
        startDate,
        endDate,
        currentDate,
        locationId,
        jobId,
        sectionId = [],
        sectionData = [],
    } = event.eventRecord.data;
    const { breaks, breakData } = Breaks.constructBreakDate(
        event.eventRecord.data
    );
    let notes = null;
    if (["Offsite", "Other"].includes(name)) {
        notes = event.eventRecord.data.notes;
    }
    const date = moment(currentDate).format('YYYY-MM-DD');
    const sd = `${moment(startDate).format('HH:mm')}:00`;
    const ed = `${moment(endDate).format('HH:mm')}:00`;
    const newStartDate = moment(`${date} ${sd}`).format(MYSQL_DATETIME_FORMAT) + ':00';
    const newEndDate = moment(`${date} ${ed}`).format(MYSQL_DATETIME_FORMAT) + ':00';
    const saveData = {
        id,
        resourceId,
        name,
        breaks,
        breakData: JSON.stringify(breakData),
        startDate: newStartDate,
        endDate: newEndDate,
        notes,
        status: SCHEDULE_STATUSES.PENDING,
        isUnavailable: 0,
        locationId,
        jobId,
        sectionId: JSON.stringify(sectionId),
        sectionData: JSON.stringify(sectionData),
    };
    if (parseInt(resourceId) === 0) {
        saveData.status = SCHEDULE_STATUSES.APPROVED;
    }
    if ([SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY, SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF].includes(name)) {
        saveData.status = SCHEDULE_STATUSES.APPROVED;
        saveData.isUnavailable = 1;
    }
    return { saveData, breaks, breakData, newStartDate, newEndDate };
};
const buildEventData = (saveData, eventId, breaks, breakData, newStartDate, newEndDate, data, isAvailable, mode, isMonthly = false) => {
    const eventData = {
        ...data,
        id: eventId,
        resourceId: saveData.resourceId,
        name: saveData.name,
        notes: saveData.notes,
        breaks,
        breakData: JSON.stringify(breakData),
        startDate: moment(newStartDate).toDate(),
        endDate: moment(newEndDate).toDate(),
        status: saveData.status,
        isUnavailable: saveData.isUnavailable,
        oldUserId: 0,
        locationId: saveData.locationId,
        jobId: saveData.jobId,
        eventColor: COLORS[saveData.status],
        cls: saveData.status === 'approved' ? data.cls : '',
        resizable: mode === SCHEDULE_MODES.TODAY,
        sectionId: typeof saveData.sectionId ==='string' ? JSON.parse(saveData.sectionId) : saveData.sectionId,
        sectionData: saveData.sectionData ? (
            Array.isArray(saveData.sectionData) ? saveData.sectionData : JSON.parse(saveData.sectionData)
        ) : [],
    };
    if (isAvailable) {
        eventData.eventStyle = "hollow";
    }
    if (isMonthly) {
        eventData.start = moment(newStartDate).toDate();
        eventData.end = moment(newEndDate).toDate();
    }

    return eventData;
};
async function mapEventsForMonthlyView(events, updateState = false) {
    try {
        let mpEvents = {};
        await each.concurrent(events, (ev) => {
            const day = moment(ev.startDate).format('YYYY-MM-DD');
            if (!mpEvents[day]) {
                mpEvents[day] = {
                    total: 0,
                    events: []
                }
            }
            mpEvents[day].events.push(ev);
            mpEvents[day].total += 1;
        }, 5);
        if (updateState) {
            return this.setState({
                mapEvents: mpEvents
            })
        }
        return mpEvents;
    } catch (e) {
        console.log(e);
        throw e;
    }
}
export {
    mapEvents,
    mapFromDb,
    eventIsOnDates,
    mapAvailableFromDb,
    calculateOvertime,
    eventHasChanged,
    processEventSave,
    buildEventData,
    mapEventsForMonthlyView
}

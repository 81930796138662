import BaseService from "./BaseService";

class UserService extends BaseService {
    getLoginImage(index) {
        return this.apiGet({
            url: "/login-background/" + index
        })
    }

    changePassword(data) {
        return this.apiPost({
            url: "/users/change-password",
            data
        });
    }
    getUser(id) {
        return this.apiGet({
            url: `/users/${id}`
        });
    }

    readNotifications(id) {
        return this.apiPost({
            url: `/users/${id}/read-notifications`
        });
    }

    resetPassword(id, data) {
        return this.apiPost({
            url: `/users/${id}/reset-password`,
            data
        });
    }

    updateTimezone(userId, data) {
        return this.apiPost({
            url: `/users/${userId}/timezone`,
            data
        });
    }
    updateWeekConf(userId, data) {
        return this.apiPost({
            url: `/users/${userId}/week-conf`,
            data
        });
    }

    registerFirebaseToken(data) {
        return this.apiPost({
            url: '/firebase-token',
            data
        })
    }

    impersonate(userId = null) {
        return this.apiGet({
            url: `/users/impersonate/${userId || 'exit'}`
        })
    }

    exitImpersonation() {
        return this.impersonate();
    }
}

export default new UserService();

import React, { Component } from "react";
import moment from "moment";
import Select from "react-select";
// import AsyncSelect from "react-select/lib/Async";

import { DateRangePicker } from "react-dates";
import ReportService from "../../../services/ReportService";
import { getWeekStartDay } from "../../Schedule/helpers/DateHelper";

export default class UsageDataFilterForm extends Component {
  constructor(props, context) {
    super(props, context);

    const {
      filterData: { locations, departments }
    } = props;

    this.state = {
      values: {
        startDate: moment().subtract(30, "days"),
        endDate: moment()
      },
      locations,
      departments,
      focusedInput: null
    };
  }

  filter = e => {
    e && e.preventDefault();

    this.props.filter({
      ...this.state.values,
      startDate: this.state.values.startDate.toISOString(),
      endDate: this.state.values.endDate.toISOString()
    });
  };

  onDateRangeFocusChange = focusedInput => {
    this.setState({
      focusedInput: !focusedInput ? null : focusedInput
    });
  };

  onDateRangeChange = ({ startDate, endDate }) => {
    const values = { ...this.state.values };
    if (startDate) {
      values.startDate = startDate;
      values.endDate = null;
    }
    if (endDate) values.endDate = endDate;
    this.setState({ values });
  };

  onSelectChange = (type, data) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, [`${type}Ids`]: data.map(d => d.value) }
      };
    });
  };

  formatOptions = data => {
    return (
      data &&
      Array.isArray(data) &&
      data.map(({ id, value, name: label }) => ({
        label,
        value: value !== undefined ? value : id
      }))
    );
  };

  search = (type, value, callback) => {
    ReportService[`search${type}s`]({ search: value })
      .then(d =>
        callback(
          this.formatOptions(
            type === "Department" ? d.data.departments : d.data
          )
        )
      )
      .catch(() => callback([]));
  };

  render() {
    const { values, focusedInput, locations, departments } = this.state;
    const { isLoading, user } = this.props;
    return (
      <form onSubmit={this.filter}>
        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Date:
          </label>
          <div className="col-md-8">
            <DateRangePicker
              firstDayOfWeek={getWeekStartDay(user)}
              startDateId="startDate"
              endDateId="endDate"
              startDate={values.startDate}
              endDate={values.endDate}
              onDatesChange={this.onDateRangeChange}
              onFocusChange={this.onDateRangeFocusChange}
              focusedInput={focusedInput}
              isOutsideRange={() => {
                return false;
              }}
              disabled={isLoading}
              small={true}
            />
          </div>
        </div>

        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Locations:
          </label>
          <div className="col-md-8">
            <Select
              name="locations"
              isMulti={true}
              onChange={l => this.onSelectChange("location", l)}
              disabled={isLoading}
              options={locations}
              getOptionLabel={o => o.name}
              placeholder="Type to search for Locations..."
            />
          </div>
        </div>

        <div className="form-group row">
          <label
            className="col-md-4 col-form-label"
            style={{ fontSize: "13px" }}
          >
            Select Departments:
          </label>
          <div className="col-md-8">
            <Select
              name="departments"
              getOptionLabel={o => o.name}
              isMulti={true}
              onChange={d => this.onSelectChange("department", d)}
              disabled={isLoading}
              options={departments}
              placeholder="Type to search for Departments..."
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 mt-3">
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary"
            >
              {isLoading ? "Generating..." : "Generate"}
            </button>
          </div>
        </div>
      </form>
    );
  }

  renderSelectOptions(options) {
    return options.map(({ name, label }) => (
      <option key={name} value={name}>
        {label}
      </option>
    ));
  }
}

import React from "react";
import { Link } from "react-router-dom";
import ToggleButton from "react-toggle-button";

// Services
import EmployeeService from "../../services/EmployeeService";
import ProfileDetails from "../../components/ProfileDetails";

// Styles
import "./assets/styles/PeopleDetails.css";
import StringHelper from "../../helpers/StringHelper";
import SettingsService from "../../services/SettingsService";
import Toast from "../../components/Blocks/Toast";
import Avatar from "../../components/Blocks/Avatar";

class PeopleDetails extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      error: null,
      success: null,
      userId: 0,
      firstName: "",
      lastName: "",
      jobTitle: "",
      code: "",
      companyName: "",
      workEmailAddress: "",
      personalEmailAddress: "",
      workPhoneNumber: "",
      homePhone: "",
      departmentDescription: "",
      empId: -1,
      isLoading: true,
      supervisorLastName: "",
      supervisorFirstName: "",
      payGroupDescription: "",
      locationDescription: "",
      lastHireDate: "",
      status: 0,
      levels: [],
      user,
      accessLevelId: 0,
      originalAccessLevelId: 0,
      accessLevelIsUpdating: false,
      salaryHourly: "",
      hourlyRate: "",
      showMyScheduleUnderMyEmployees: false
    };
  }

  fetchPeopleDetails() {
    const { id = -1 } = this.props.match.params;

    EmployeeService.fetchOneEmployee(id)
      .then(res => {
        if (res.data) {
          const profile = res.data;
          if (!profile.accessLevelId) {
            profile.accessLevelId = 0;
          }
          this.setState({
            ...profile,
            originalAccessLevelId: profile.accessLevelId,
            isLoading: false
          });
        } else {
          this.setState({
            error: (
              <div>
                <p>404, User not found!</p>
                <Link to="/app/people" className="btn btn-info">
                  Go back
                </Link>
              </div>
            ),
            isLoading: false
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          error: (
            <div>
              <p>404, User not found!</p>
              <Link to="/app/people" className="btn btn-info">
                Go back
              </Link>
            </div>
          ),
          isLoading: false
        });
      });
  }

  getAccessLevels() {
    SettingsService.getAccessLevel().then(response => {
      if (response.data) {
        const levels = response.data;
        this.setState({
          levels
        });
      }
    });
  }

  onChangeAccessLevel = event => {
    this.setState({
      accessLevelId: 1 * event.target.value
    });
  };
  updateAccessLevel = () => {
    if (this.state.accessLevelId !== this.state.originalAccessLevelId) {
      this.setState({
        accessLevelIsUpdating: true
      });
      SettingsService.setAccessLevel(
        this.state.userId,
        this.state.accessLevelId
      ).then(res => {
        this.setState({
          originalAccessLevelId: this.state.accessLevelId,
          accessLevelIsUpdating: false,
          success: res.data.message
        });
      });
    }
  };
  onChangeShowMyScheduleUnderMyEmployees = value => {
    this.setState({
      isLoading: true
    })
    EmployeeService.updateShowMyScheduleUnderMyEmployees(this.state.empId, !value)
      .then(res => {
        this.setState({
          showMyScheduleUnderMyEmployees: !value,
          isLoading: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          error: err.response && err.response.message ? err.response.message : err.message,
          isLoading: false
        });
      });

  }
  toastDismiss = () => {
    this.setState({
      error: null,
      success: null
    });
  };
  componentDidMount() {
    this.fetchPeopleDetails();
  }

  render() {
    const {
      firstName,
      lastName,
      jobTitle,
      code,
      companyName,
      workEmailAddress,
      personalEmailAddress,
      workPhoneNumber,
      homePhone,
      departmentDescription,
      supervisorLastName,
      supervisorFirstName,
      payGroupDescription,
      locationDescription,
      lastHireDate,
      status,
      isLoading,
      levels,
      user,
      accessLevelId,
      originalAccessLevelId,
      accessLevelIsUpdating,
      error,
      success,
      salaryHourly,
      hourlyRate,
      showMyScheduleUnderMyEmployees
    } = this.state;

    const errorToast = error && (
      <Toast
        title="User profile error!"
        message={error}
        dismiss={this.toastDismiss}
      />
    );
    const successToast = success && (
      <Toast
        status="success"
        title="Access level was updated successfully."
        message={success}
        dismiss={this.toastDismiss}
      />
    );
    if (isLoading) {
      return (
        <div className="container content-area">
          <section className="section">
            <div className="row mt-5">
              <div className="col">
                <p>
                  <b>Loading...</b>
                </p>
              </div>
            </div>
          </section>
        </div>
      );
    }

    return (
      <div className="container content-area">
        {errorToast}
        {successToast}
        {!error && (
          <section className="section">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/app/people" className="text-muted">
                  People
                </Link>
              </li>
              <li className="breadcrumb-item active text-" aria-current="page">
                {firstName} {lastName}
              </li>
            </ol>

            <div className="section-body">
              <div className="row mt-3">
                <div className="col-12 col-md-4">
                  <div className="card">
                    <div className="card-body box">
                      <Avatar
                        extraClass="mx-auto"
                        item={{ firstName, lastName }}
                        size={100}
                      />
                      <h2>
                        {firstName} {lastName}
                        <br />
                        <span>{jobTitle}</span>
                      </h2>
                      {user.isSuperUser ? (
                        <React.Fragment>
                          <div className="row p-3 bg-gray border-white">
                            <div className="print-row">Allow employee to schedule without approving</div>
                            <div className="pull-right">
                              <ToggleButton
                                thumbStyle={{ borderRadius: 4 }}
                                trackStyle={{
                                  borderRadius: 4
                                }}
                                colors={{
                                  activeThumb: {
                                    base: "#fff"
                                  },
                                  active: {
                                    base: "#61c08d"
                                  }
                                }}
                                value={showMyScheduleUnderMyEmployees || false}
                                onToggle={this.onChangeShowMyScheduleUnderMyEmployees}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <select
                              className="form-control form-control-sm"
                              onChange={this.onChangeAccessLevel}
                              value={accessLevelId}
                            >
                              <option value="0">-- No Access Level --</option>
                              {levels.map(l => (
                                <option key={l.id} value={l.id}>
                                  {l.name}
                                </option>
                              ))}
                            </select>
                            <div className="pull-right mt-3 w-100">
                              <button
                                disabled={
                                  accessLevelId === originalAccessLevelId ||
                                  accessLevelIsUpdating
                                }
                                onClick={this.updateAccessLevel}
                                type="button"
                                className="btn btn-info w-100"
                              >
                                {accessLevelIsUpdating
                                  ? "Updating..."
                                  : "Change Access Level"}
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-8">
                  <ProfileDetails
                    cardHeader="More Details"
                    jobTitle={jobTitle}
                    code={code}
                    companyName={companyName}
                    workEmailAddress={workEmailAddress}
                    personalEmailAddress={personalEmailAddress}
                    workPhoneNumber={workPhoneNumber}
                    homePhone={homePhone}
                    departmentDescription={departmentDescription}
                    supervisorLastName={supervisorLastName}
                    supervisorFirstName={supervisorFirstName}
                    payGroupDescription={payGroupDescription}
                    locationDescription={locationDescription}
                    lastHireDate={lastHireDate}
                    status={StringHelper.statusFormat(status)}
                    salaryHourly={salaryHourly}
                    hourlyRate={hourlyRate}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

PeopleDetails.propTypes = {};

export default PeopleDetails;

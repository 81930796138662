import BaseService from "./BaseService";

class ScheduleService extends BaseService {
  approveSchedule(data) {
    return this.apiPost({
      url: `/schedules/approve`,
      data,
    });
  }

  deleteSchedule(id) {
    return this.apiDelete({
      url: `/schedules/${id}`,
    });
  }
  deleteSchedules(ids) {
    return this.apiPost({
      url: `/schedules/delete`,
      data: {
        ids,
      },
    });
  }
  getMonthSchedules(id = "self", startDate, endDate, getAll = false) {
    const queries = `${startDate ? `?startDate=${startDate}` : ""}${
      endDate ? `&endDate=${endDate}` : ""
      }${getAll ? "&getAll=true" : ""}`;
    return this.apiGet({
      url: `/schedules/grouped/${id}${queries}`,
    });
  }
  fetchMonthSchedules(filters) {
    const query = Object.keys(filters).reduce(
      (q, f) => q + `${f}=${filters[f]}&`,
      "?"
    );

    return this.apiGet({
      url: `/schedules/grouped${query}`,
    });
  }
  fetchMonthDepartmentSchedules(filters) {
    const query = Object.keys(filters).reduce(
      (q, f) => q + `${f}=${filters[f]}&`,
      "?"
    );

    return this.apiGet({
      url: `/schedules/my-department/grouped${query}`,
    });
  }

  fetchSchedules(page, limit, startDate, endDate, filters, timeDifference) {
    const queries = `${page ? `?page=${page}` : "?page=1"}${
      limit ? `&limit=${limit}` : ""
      }${startDate ? `&startDate=${startDate}` : ""}${
      endDate ? `&endDate=${endDate}` : ""
      }${timeDifference ? `&timeDifference=${timeDifference}` : ""}`;
    return this.apiGet({
      url: `/schedules${queries}${filters}`,
    });
  }
  fetchMyDepartmentSchedules(page, limit, startDate, endDate, filters = {}) {
    return this.apiGet({
      url: `/schedules/my-department`,
      params: {
        page: typeof page !== undefined ? page : 1,
        limit,
        startDate,
        endDate,
        ...filters
      }
    });
  }
  fetchCustomSchedules(startDate, endDate, filters) {
    const params = {
      startDate,
      endDate,
      ...filters,
    };
    return this.apiGet({
      url: `/schedules/custom`,
      params,
    });
  }
  fetchSubmittedSchedules(options) {
    return this.apiGet({
      url: `/schedules/submitted?start=${options.start}&take=${options.take}`,
    });
  }

  getSchedule(id = "self", startDate, endDate) {
    const queries = `${startDate ? `?startDate=${startDate}` : ""}${
      endDate ? `&endDate=${endDate}` : ""
      }`;

    return this.apiGet({
      url: `/schedules/${id}${queries}`,
    });
  }
  getSchedulesForExport(query) {
    return this.apiGet({
      url: `/schedules/export`,
      params: query,
    });
  }
  getScheduleConfigs() {
    return this.apiGet({
      url: "/schedules/configs",
    });
  }
  getUnavailibilitySchedule(startDate, endDate) {
    const queries = `${startDate ? `?startDate=${startDate}` : ""}${
      endDate ? `&endDate=${endDate}` : ""
      }`;
    return this.apiGet({
      url: `/schedules/unavailability${queries}`,
    });
  }
  getCustomSchedule(id = "self", startDate, endDate) {
    const queries = `${startDate ? `?startDate=${startDate}` : ""}${
      endDate ? `&endDate=${endDate}` : ""
      }`;
    return this.apiGet({
      url: `/schedules/custom/${id}${queries}`,
    });
  }

  publishEvents(data) {
    return this.apiPost({
      url: `/schedules/publish`,
      data,
    });
  }

  rejectSchedule(data) {
    return this.apiPost({
      url: `/schedules/reject`,
      data,
    });
  }

  saveSchedule(data) {
    const { id, ...otherProps } = data;
    if (`${id}`.indexOf("generated") !== -1) {
      return this.apiPost({
        url: "/schedules",
        data: otherProps,
      });
    }

    return this.apiPut({
      url: `/schedules/${id}`,
      data: otherProps,
    });
  }

  submitEvents(data) {
    return this.apiPost({
      url: `/schedules/submit`,
      data,
    });
  }
  getFilters() {
    return this.apiGet({
      url: `/schedules/filters`,
    });
  }
  releaseEvents(ids, note = '') {
    return this.apiPut({
      url: "/schedules/release",
      data: {
        ids,
        note
      },
    });
  }
  pickEvents(ids, note = '') {
    return this.apiPut({
      url: "/schedules/pick-events",
      data: {
        ids,
        note
      },
    });
  }

  convertToRegularEvents(id) {
    return this.apiPut({
      url: "/schedules/to-regular",
      data: {
        id,
      },
    });
  }

  approveReleasedSchedule(data) {
    return this.apiPost({
      url: `/schedules/released/approve`,
      data,
    });
  }
  rejectReleasedSchedule(data) {
    return this.apiPost({
      url: `/schedules/released/reject`,
      data,
    });
  }
  approveUnavailabilitySchedule(data) {
    return this.apiPost({
      url: `/schedules/unavailability/approve`,
      data,
    });
  }
  rejectUnavailabilitySchedule(data) {
    return this.apiPost({
      url: `/schedules/unavailability/reject`,
      data,
    });
  }
}

export default new ScheduleService();

import React from "react";
import enGB from "date-fns/locale/en-GB"
import enUS from "date-fns/locale/en-US"
import PropTypes from "prop-types";
import Modal from "react-modal";
import customModalStyles from "./customStyle";
import DatePicker, { registerLocale } from "react-datepicker";
import EmployeeService from "../../services/EmployeeService";
import moment from "moment";
import Select from "react-select";

class ShareEmployee extends React.Component {
  state = {
    error: null,
    success: null,
    selectLocation: undefined,
    startDate: undefined,
    endDate: undefined,
    startMinDate: new Date(),
    isLoading: false,
    managers: [],
    selectedManager: [],
    startMaxDate: moment()
      .add(55, "days")
      .toDate(),
    endMinDate: moment()
      .add(1, "day")
      .toDate(),
    endMaxDate: moment()
      .add(60, "days")
      .toDate()
  };
  toastDismiss = () => {
    this.setState({
      error: null
    });
  };
  endDateValueChanged = date => {
    this.setState({
      endDate: date,
      startMaxDate: moment(date)
        .add(-1, "days")
        .toDate(),
      error: null
    });
  };
  startDateValueChanged = date => {
    this.setState({
      startDate: date,
      startMinDate: moment(date).toDate(),
      startMaxDate: moment(date)
        .add(55, "days")
        .toDate(),
      endMinDate: moment(date)
        .add(1, "day")
        .toDate(),
      endMaxDate: moment(date)
        .add(60, "days")
        .toDate(),
      error: null
    });
  };
  clickOutside = () => {
    this.refs.startPicker.cancelFocusInput();
    this.refs.startPicker.setOpen(false);
  };
  clickOutsideEndPicker = () => {
    this.refs.endPicker.cancelFocusInput();
    this.refs.endPicker.setOpen(false);
  };
  onChangeLocation = value => {
    this.setState({
      selectLocation: value ? value.value : undefined,
      error: null,
    }, () => {
      this.getLocationManagers(null)
    });
  };
  onChangeManagers = value => {
    this.setState({
      selectedManager: value ? value : [],
      error: null,
    });
  };
  getLocationManagers = (search) => {
    if (this.state.selectLocation) {
      EmployeeService.fetchLocationManagers(this.state.selectLocation, search).then((response) => {
        this.setState({
          managers: [{ value: 'all', name: 'All Managers in Location' }, ...(response.data && response.data.employees ? response.data.employees : []).map(e => {
            return {
              name: `${e.firstName} ${e.lastName}`,
              value: e.id
            }
          })]
        })
      }).catch((e) => {
        this.setState({
          managers: []
        })
      })
    }
  }
  shareEmployee = () => {
    const { selectLocation, startDate, endDate, selectedManager } = this.state;
    if (!selectLocation) {
      this.setState({
        error: "Please select a location"
      });
      return;
    }
    if (!startDate) {
      this.setState({
        error: "Please select start date"
      });
      return;
    }
    if (endDate && moment(startDate) >= moment(endDate)) {
      this.setState({
        error: "End date should be greater than start date"
      });
      return;
    }
    this.setState({ error: null, isLoading: true });
    const { empId } = this.props.user;
    EmployeeService.shareEmployee(
      selectLocation,
      empId,
      moment(this.state.startDate).format("YYYY-MM-DD"),
      this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : undefined,
      selectedManager.length === 0 || selectedManager.filter(s => s.value === 'all').length ? undefined : JSON.stringify(selectedManager.map(m => m.value))
    )
      .then(res => {
        this.props.setEmployeeAsShared(this.props.user, 2);
        this.setState({ isLoading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };
  render() {
    const { firstName, lastName, locationId } = this.props.user;
    const {
      startDate,
      endDate,
      startMinDate,
      startMaxDate,
      endMinDate,
      endMaxDate,
      isLoading,
      error,
      selectLocation,
      selectedManager,
      managers
    } = this.state;
    const { firstDayOfWeek } = this.props;
    const locations = this.props.locations.filter(l => l.value !== locationId);
    const location = selectLocation ? locations.filter(l => l.value === selectLocation) : null;
    if (firstDayOfWeek) {
      registerLocale('en-GB', enGB)
    } else {
      registerLocale('en-US', enUS)
    }
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        style={customModalStyles}
        contentLabel="Share Employee"
        shouldCloseOnOverlayClick={false}
        className="col-md-5 col-sm-12 card"
      >
        <div>
          <div className={isLoading ? "loader" : "hidden"}>
            <div id="spinner" />
          </div>

          <div className="modal-header">
            <h4>
              Share Employee:{" "}
              <span className="breadcrumb-item active">
                {firstName} {lastName}
              </span>
            </h4>
          </div>

          <div className="modal-body custom-height min-height-300">
            <div className="row">
              <div className="col-12">
                <label>Select a Location</label>
                <Select
                  className={`${error && !selectLocation ? 'error-border' : ''}`}
                  placeholder="Select a Location"
                  getOptionLabel={o => o.name}
                  onChange={this.onChangeLocation}
                  options={locations}
                  isDisabled={isLoading}
                  value={location}
                  isClearable={true}
                />
                {error && !selectLocation && (
                  <div className="w-100 mt-2 text-center text-danger">
                    {error}
                  </div>
                )}
              </div>
            </div>
            <div className="row  mt-3">
              <div className="col-12">
                <label>Will report to?</label>
                <Select
                  isMulti={true}
                  className={`${error && !selectLocation ? 'error-border' : ''}`}
                  placeholder="Will Report to?"
                  getOptionLabel={o => o.name}
                  onChange={this.onChangeManagers}
                  options={managers}
                  isDisabled={isLoading || !location}
                  value={selectedManager}
                  isClearable={true}
                />
                {error && !selectLocation && (
                  <div className="w-100 mt-2 text-center text-danger">
                    {error}
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <label>Start Date</label>
                <DatePicker
                  locale={firstDayOfWeek ? 'en-GB' : 'en-US'}
                  placeholderText="Start date"
                  selected={startDate}
                  onChange={this.startDateValueChanged}
                  minDate={startMinDate}
                  maxDate={startMaxDate}
                  dateFormat="MM/dd/yyyy"
                  ref="startPicker"
                  className={`${error && !startDate && selectLocation ? 'error-border' : ''}`}
                  onClickOutside={this.clickOutside}
                />
                {error && !startDate && selectLocation && (
                  <div className="w-100 mt-2 text-center text-danger">
                    {error}
                  </div>
                )}
              </div>
              <div className="col-6">
                <label>End Date</label>
                <DatePicker
                  locale={firstDayOfWeek ? 'en-GB' : 'en-US'}
                  placeholderText="End date"
                  selected={endDate}
                  onChange={this.endDateValueChanged}
                  minDate={endMinDate}
                  maxDate={endMaxDate}
                  dateFormat="MM/dd/yyyy"
                  ref="endPicker"
                  className={`${error && !endDate && selectLocation && startDate ? 'error-border' : ''}`}
                  onClickOutside={this.clickOutsideEndPicker}
                />
              </div>
            </div>
            <div className="row mt-3">
              {error && startDate && selectLocation && (
                <div className="w-100 mt-2 text-center text-danger">
                  {error}
                </div>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <div className="pull-right">
              <button
                className="btn btn-primary mr-2"
                onClick={this.shareEmployee}
              >
                <i className="fas fa-share mr-1" />
                Share
              </button>
              <button className="btn btn-light" onClick={this.props.closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
ShareEmployee.defaultProps = {
  closeModal: () => { },
  isOpen: false
};
ShareEmployee.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
export default ShareEmployee;

import EmployeeService from '../../../services/EmployeeService';

let timeout = null;
const loadOptions = (inputValue, callback) => {
  timeout && clearTimeout(timeout);

  timeout = setTimeout(async () => {
    const search = await EmployeeService.searchMyEmployees(inputValue);

    return callback(prepareResults(search.data));
  }, 300);
};

const prepareResults = results => {
  const preparedResults = [];

  results &&
  results.forEach(result => {
    preparedResults.push({
      value: result.id,
      label: result.name,
      ...result,
    });
  });

  return preparedResults;
};

export {
  loadOptions
};

import React, { useState, useEffect, useCallback } from "react";
import "./Assets/Styles/all-events-modal.css";
import moment from "moment";
import ScheduleService from "../../services/ScheduleService";
import Duration from "../../helpers/Duration";
import colorMapping from "../BryntumScheduler/colors";
import { SCHEDULE_MAPPED_EVENTS } from "../../containers/Schedule/Constant";

const modalWidth = 300;
const modalHeight = 300;
const margin = 20;

const AllEventsModal = ({
    position: { x: pX, y: pY },
    visible,
    date,
    selectedEvents,
    selectEvent,
    openEvent,
    openEventContextMenu,
    mapEvents,
    scheduleConfigs,
    filters,
    getNextPage,
    firstPage
}) => {
    const [{ x, y }, setPosition] = useState({ pX, pY });
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(0);
    const [isDone, setIsDone] = useState(true);

    const fetchSchedules = async (passedEvents = false) => {
        const res = await getNextPage({
            page,
            limitPerDay: 10,
            startDate: date.format("YYYY-MM-DD"),
            endDate: date.format("YYYY-MM-DD"),
            ...filters
        });
        const results = Object.values(res.data)[0];
        const upperEvents = passedEvents ? [] : events;
        const newEvents = [...upperEvents, ...mapEvents(res.data, false)];
        if (!results || !results.events || newEvents.length === results.total) {
            setIsDone(true);
        }

        setEvents(newEvents);
    };
    useEffect(() => {
        if (firstPage && firstPage.length) {
            setEvents(firstPage);
        }
    }, [firstPage])
    useEffect(() => {
        if (!date) return;
        setEvents([]);
        setIsDone(false);
        setPage(visible ? 1 : 0);
        if (visible && page === 1) fetchSchedules(true);
    }, [date, visible]);

    useEffect(() => {
        page !== 0 && visible && date && fetchSchedules();
    }, [page]);

    useEffect(() => {
        const measureVertical = window.innerHeight - modalHeight - margin;
        const measureHorizontal = window.innerWidth - modalWidth - margin;

        const newY = pY > measureVertical ? measureVertical : pY;
        const newX = pX > measureHorizontal ? measureHorizontal : pX;

        setPosition({ x: newX, y: newY });
    }, [pX, pY]);

    const onScroll = useCallback(
        e => {
            if (
                e.target.scrollTop ===
                e.target.scrollHeight - e.target.offsetHeight &&
                !isDone
            ) {
                setPage(page + 1);
            }
        },
        [page, isDone]
    );

    const renderEvent = ({ event }) => {
        let username = `${event.firstName} ${event.lastName}`;
        let sectionData = [];
        if (scheduleConfigs.sections && (scheduleConfigs.sections[event.jobId] || scheduleConfigs.sections['all']) && event.sectionId) {
            const jobSections = scheduleConfigs.sections[event.jobId] || [];
            const allSections = scheduleConfigs.sections['all'] || [];
            const sections = [...jobSections, ...allSections];
            if (!Array.isArray(event.sectionId)) {
                event.sectionId = [event.sectionId];
            }
            if (!Array.isArray(event.sectionData)) {
                event.sectionData = [];
            }
            sectionData = event.sectionData;
            const selectedSections = sections.filter(s => event.sectionId.includes(s.value));
            selectedSections.forEach(s => {
                if (!sectionData.find(sd => sd.zoneId === s.value)) {
                    sectionData.push({
                        startTime: moment(event.start).format('HH:mm'),
                        startTimeDisplay: moment(event.start).format('HH:mm A'),
                        endTime: moment(event.end).format('HH:mm'),
                        endTimeDisplay: moment(event.end).format('HH:mm A'),
                        zoneColor: s.color,
                        zoneId: s.value,
                        zone: s.text,
                        id: s.value
                    });
                }
            })
        }
        const duration = Duration.durationAsHoursAndMinsString(
            event.end,
            event.start,
            event.breaks
        );
        const start = moment(event.start);
        const end = moment(event.end);
        const breaks = event.breaks > 0 ? ` • Break ${event.breaks} min` : "";
        return (
            <div
                className="event-inner"
                style={{ width: "100%", fontSize: "0.8em" }}
                onClick={e => e.preventDefault()}
            >
                {event.name !== "Scheduled Day Off" && (
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            alignItems: "center"
                        }}
                    >
                        {`${start.format("h:mm A")} - ${end.format("h:mm A")}`}
                        &ensp;&bull;&ensp;
                        {duration}
                    </div>
                )}

                <div
                    style={{
                        display: "flex",
                        flexDirection:
                            event.name === "Scheduled Day Off"
                                ? "column"
                                : "row",
                        alignItems:
                            event.name === "Scheduled Day Off"
                                ? "flex-start"
                                : "center",
                        width: "100%",
                        textOverlow: "ellipsis"
                    }}
                >
                    <div style={{ marginRight: "5px" }}>
                        <b>{event.name}</b>
                        {breaks}
                        &ensp;<i style={{ fontSize: "1em" }} className="fas fa-map-marker-alt" />&nbsp;
                        {event.locationCode}
                    </div>
                    {event.name === SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF &&
                        event.leaveId ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexFlow: "row wrap",
                                    alignItems: "center"
                                }}
                            >
                                Generated By Leave
                            </div>
                        ) : null}
                </div>

                {Array.isArray(sectionData) && sectionData.length > 0 && (
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            alignItems: "center"
                        }}
                    >
                        {sectionData.map(s => <span className={`${s.zoneColor ? `zone ${s.zoneColor}` : ''}`}>&ensp;{s.zone} | {s.startTimeDisplay || s.startTime}-{s.endTimeDisplay || s.endTime}</span>)}
                    </div>
                )}
                <div
                    style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        alignItems: "center",
                        width: "100%",
                        textOverlow: "ellipsis"
                    }}
                >
                    <i style={{ fontSize: "1em" }} className="fas fa-user" />
                        &ensp;
                        {username}
                </div>
            </div>
        );
    };

    const renderEventWrapper = ({ event }) => {
        const { name, status, id, color: zoneColor } = event;
        const isSelected = selectedEvents.find(e => e.id === id);

        const color = status === "approved" ? colorMapping[name] : colorMapping[status];

        return (
            <div
                key={id}
                onContextMenu={e => openEventContextMenu(e, event)}
                className={`event-wrapper event-color-${color} ${zoneColor ? zoneColor : ''} ${
                    isSelected ? ` selected` : ""
                    }`}
                style={{
                    padding: "5px 7px",
                    margin: "5px",
                    marginTop: "0",
                    cursor: "pointer"
                }}
                onDoubleClick={() => openEvent(event)}
                onClick={e => {
                    // e.preventDefault();
                    selectEvent(event);
                }}
            >
                {renderEvent({ event })}
            </div>
        );
    };

    return (
        <div
            className="all-events-modal"
            style={{
                width: `${modalWidth}px`,
                height: `${modalHeight}px`,
                top: y,
                left: x,
                display: visible ? "flex" : "none"
            }}
        >
            <h4 className="title">{date && date.format("YYYY / MM / DD")}</h4>

            <div onScroll={onScroll} className="events-list">
                {events && events.map(e => renderEventWrapper({ event: e }))}
                {!isDone && (
                    <div className="events-loader">
                        <i className="fas fa-spinner fa-spin" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllEventsModal;

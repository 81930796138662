import Tooltip from "../Blocks/Tooltip";
import StringHelper from "../../helpers/StringHelper";
import React from "react";
import { TABS } from "../../containers/Schedule/Constant";
import { searchOnScheduler } from "./Modals";
import { _handleKeyDown, filterByLocation, selectMyFilter, filterByDepartment, filterByZones, filterByEmployeeRole, isManager } from "./helpers/SchedulerHelper";

function renderSubHeader() {
  const { user, config, estimation, departmentBudget, locations, scheduleConfigs, selectedFilterId, selectedLocationId } = this.state;
  const filters = scheduleConfigs.filters || [];
  return [TABS.MY_EMPLOYEES, TABS.MY_DEPARTMENT].includes(this.state.activeTab) &&
    user &&
    config.startDate &&
    config.endDate ? (
      <div className="estimation-subheader row row-color">
        <div className="">
          <div className="search-box">
            <input
              type="text"
              onKeyDown={key => this.call(_handleKeyDown, key)}
              onChange={e => {
                const { value } = e.target;
                this.setState({ searchName: value }, () => {
                  if (value === "") {
                    this.call(searchOnScheduler);
                  }
                });
              }}
              value={this.state.searchName}
              placeholder="Search with full name..."
              name="employee"
              className="select-search-box__search"
            />
            <i className="fas fa-search" />
          </div>

          {TABS.MY_EMPLOYEES === this.state.activeTab && (<select
            name="locationFilter"
            className="form-control mr-2"
            onChange={e => this.call(filterByLocation, e)}
            value={selectedLocationId}
          >
            {locations.length > 1 && <option value="">--All locations--</option>}
            {locations.map((location, key) => (
              <option key={key} value={location.value}>
                {location.name}
              </option>
            ))}
          </select>)}

          {TABS.MY_EMPLOYEES === this.state.activeTab && filters.length > 0 && (<select
            name="filters"
            className="form-control"
            onChange={e => this.call(selectMyFilter, e)}
            value={selectedFilterId}
          >
            {filters.length > 0 && <option value="">--Select a filter--</option>}
            {filters.map((filter, key) => (
              <option key={key} value={filter.id}>
                {filter.name}
              </option>
            ))}
          </select>)}
          {TABS.MY_DEPARTMENT === this.state.activeTab && (
            this.call(renderDepartmentFilters, user)
          )}
        </div>

        {TABS.MY_EMPLOYEES === this.state.activeTab && isManager(user) && (
          <div className="estimation">
            {departmentBudget > 0 && (
              <Tooltip placement="bottom" text="Department budget">
                <div className="premium-guard clickable">
                  <div className="font-14 weight-600 text-color-black">
                    <money-display amount="0">
                      <span
                        title={`$${StringHelper.formatMoney(
                          departmentBudget.toFixed(2)
                        )}`}
                      >
                        ${StringHelper.formatMoney(departmentBudget.toFixed(2))}
                      </span>
                    </money-display>
                  </div>
                  <div className="transform-uppercase weight-600 color-light-color">
                    Budget
              </div>
                </div>
              </Tooltip>
            )}

            {departmentBudget > 0 && (
              <Tooltip placement="bottom" text="Remaining budget from total">
                <div className="premium-guard clickable">
                  <div className="font-14 weight-600 text-color-black">
                    <money-display amount="0">
                      <span
                        className={
                          departmentBudget -
                            estimation.time.cost -
                            estimation.ot.cost <
                            0
                            ? "text-red"
                            : ""
                        }
                        title={`$${StringHelper.formatMoney(
                          (
                            departmentBudget -
                            estimation.time.cost -
                            estimation.ot.cost
                          ).toFixed(2)
                        )}`}
                      >
                        $
                    {StringHelper.formatMoney(
                        (
                          departmentBudget -
                          estimation.time.cost -
                          estimation.ot.cost
                        ).toFixed(2)
                      )}
                      </span>
                    </money-display>
                  </div>
                  <div className="transform-uppercase weight-600 color-light-color">
                    Rem. budget
              </div>
                </div>
              </Tooltip>
            )}

            <Tooltip placement="bottom" text="Scheduled employees from total">
              <div className="premium-guard clickable">
                <div className="font-14 weight-600 text-color-black">
                  {estimation.scheduledEmployees.length} /
              {estimation.totalEmployees}
                </div>
                <div className="transform-uppercase weight-600 color-light-color">
                  Sch. employees
            </div>
              </div>
            </Tooltip>

            <Tooltip placement="bottom" text="Regular wages">
              <div className="premium-guard clickable">
                <div className="font-14 weight-600 text-color-black">
                  <money-display amount="0">
                    <span title={`$${this.state.estimation.time.cost.toFixed(2)}`}>
                      ${this.state.estimation.time.cost.toFixed(2)}
                    </span>
                  </money-display>
                </div>
                <div className="transform-uppercase weight-600 color-light-color">
                  Reg. wages
            </div>
              </div>
            </Tooltip>

            <Tooltip placement="bottom" text="Regular hours">
              <div className="premium-guard clickable">
                <div className="font-14 weight-600 text-color-black">
                  {this.state.estimation.time.hr}h {this.state.estimation.time.min}
              min
            </div>
                <div className="transform-uppercase weight-600 color-light-color">
                  Reg. hr
            </div>
              </div>
            </Tooltip>

            <Tooltip placement="bottom" text="Overtime cost">
              <div className="premium-guard clickable">
                <div className="font-14 weight-600 text-color-black">
                  <money-display amount="0">
                    <span title={`$${this.state.estimation.ot.cost.toFixed(2)}`}>
                      ${this.state.estimation.ot.cost.toFixed(2)}
                    </span>
                  </money-display>
                </div>
                <div className="transform-uppercase weight-600 color-light-color">
                  OT cost
            </div>
              </div>
            </Tooltip>

            <Tooltip placement="bottom" text="Overtime hours">
              <div className="premium-guard clickable">
                <div className="font-14 weight-600 text-color-black">
                  {this.state.estimation.ot.hr}h {this.state.estimation.ot.min}
              min
            </div>
                <div className="transform-uppercase weight-600 color-light-color">
                  OT hr
            </div>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    ) : null;
}
function renderDepartmentFilters(user) {
  const allLocations = user.allLocations || [];
  const {
    allZones = [],
    selectedLocationId = user.locationId
  } = this.state;

  const allMyDepartmentLocations = (user.allMyDepartmentLocations || []).filter(d => d.locationId === (selectedLocationId && selectedLocationId !== '' ? parseInt(selectedLocationId) : user.locationId));

  return (
    <>
      {allLocations.length > 1 && (
        <><label className="mr-1 no-margin-bottom">Location:</label>
          <select
            name="locationFilter"
            className="form-control mr-3"
            onChange={e => this.call(filterByLocation, e)}
            defaultValue={user.locationId}
          >
            {allLocations.map((location, key) => (
              <option key={key} value={location.locationId}>
                {location.location}
              </option>
            ))}
          </select>
        </>
      )}
      {allMyDepartmentLocations.length > 0 && (
        <>
          <label className="mr-1 no-margin-bottom">Department:</label>
          <select
            name="departmentFilter"
            className="form-control  mr-3"
            onChange={e => this.call(filterByDepartment, e)}
          >
            {allMyDepartmentLocations.length > 1 && <option value="">--All departments--</option>}
            {allMyDepartmentLocations.map((dep, key) => (
              <option key={key} value={dep.departmentId}>
                {dep.department}
              </option>
            ))}
          </select>

        </>
      )}
      {allZones.length > 0 && (
        <>
          <label className="mr-1 no-margin-bottom">Zone:</label>
          <select
            name="zoneFilter"
            className="form-control mr-3"
            onChange={e => this.call(filterByZones, e)}
          >
            {allZones.length > 1 && <option value="">--All zones--</option>}
            <option value="0">--Without zone--</option>
            {allZones.map((s, key) => (
              <option key={key} value={s.value}>
                {s.text}
              </option>
            ))}
          </select>

        </>
      )}
      <label className="mr-1 no-margin-bottom">Show:</label>
      <select
        name="employeeFilter"
        className="form-control"
        onChange={e => this.call(filterByEmployeeRole, e)}
        defaultValue={user.role}
      >
        <option value="">--All--</option>
        <option value="employee">Only Employees</option>
        <option value="manager">Only Managers</option>
      </select>
    </>
  )
}
export { renderSubHeader };

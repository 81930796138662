import { Link } from "react-router-dom";
import StringHelper from "../../helpers/StringHelper";
import moment from "moment";
import React from "react";

const Schedule = ({
  notification,
  approveSchedule,
  approveUnavailabilitySchedule,
  rejectSchedule,
  rejectUnavailabilitySchedule,
  renderUpdateSchedule,
  renderDeletedSchedule,
  totalHrs,
  totalMin,
  schedules
}) => {
  return (
    <React.Fragment>
      <div className="card-header">
        <h4>Schedule Details</h4>
        {["submit", "to-regular"].includes(notification.type) &&
          notification.isResponded === 0 && (
            <div className="pull-right">
              <button
                className="btn btn-success btn-sm mr-2 notification-button"
                onClick={() => notification.type === 'submit' ? approveSchedule() : approveUnavailabilitySchedule()}
              >
                Approve
            </button>
              <button
                className="btn btn-danger btn-sm mr-2 notification-button"
                onClick={() => notification.type === 'submit' ? rejectSchedule() : rejectUnavailabilitySchedule()}
              >
                Reject
            </button>
              <Link
                to={`/app/people/${notification.employeeCode}`}
                className="btn btn-info btn-sm notification-button"
              >
                Compose
            </Link>
            </div>
          )}
        {notification.type === "submit" && (
          <div className="employee-data">
            <span className="mr-3">
              Employee:{" "}
              <b>
                {" "}
                {notification.firstName}{" "}
                {notification.lastName}{" "}
              </b>
            </span>
            <span className="mr-3">
              Total Hrs:
                    <span className="ml-2 badge badge-info">
                <i className="fas fa-clock mr-1" />
                {totalHrs}h{" "}
                {totalMin > 0
                  ? `${totalMin}min`
                  : ""}
              </span>
            </span>
          </div>
        )}
      </div>
      {notification.note ? <div className="notification-note">Notes: <b>{notification.note}</b></div> : null}
      <div className="table-responsive">
        {["delete-schedule", "update-schedule"].includes(
          notification.type
        ) ? (
            <>
              {notification.type === "update-schedule"
                ? renderUpdateSchedule()
                : renderDeletedSchedule()}
            </>
          ) : (
            <table className="table card-table table-vcenter text-nowrap mb-0">
              <thead>
                <tr className="bg-light">
                  <th className="w-1">No</th>
                  <th>Event Type</th>
                  <th>Notes</th>
                  <th>Status</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Hrs</th>
                </tr>
              </thead>
              <tbody>
                {schedules.length ? (
                  schedules.map((s, key) => (
                    <tr key={key} className="border-bottom">
                      <td>
                        <span className="text-muted">
                          {(key + 1).toString().length > 3
                            ? key + 1
                            : ("00" + (key + 1)).slice(-3)}
                        </span>
                      </td>
                      <td>{s.name}</td>
                      <td>{s.notes ? s.notes : ""}</td>
                      <td>
                        {" "}
                        {StringHelper.formatScheduleStatus(
                          s.status,
                          "schedule"
                        )}
                      </td>
                      <td>
                        {moment(s.startDate).format("LLL")}
                      </td>
                      <td>{moment(s.endDate).format("LLL")}</td>
                      <td>
                        <span className="badge badge-info">
                          <i className="fas fa-clock mr-1" />
                          {s.hrs}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                    <tr>
                      <td colSpan={5}>
                        No data for this notification
                </td>
                    </tr>
                  )}
              </tbody>
            </table>
          )}
      </div>
    </React.Fragment>
  )
};
export default Schedule;

import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Link from "react-router-dom/Link";

// Login validations schema
const Schema = Yup.object().shape({
  employeeCode: Yup.string()
    .trim()
    .required("Employee number is required"),
});


export default class ForgotPasswordForm extends Component {
  render() {
    return (
      <Formik
        initialValues={{
          employeeCode: "",
        }}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting }) => {
          await this.props.submitEmployeeNumber(values);
          setSubmitting(false);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleSubmit, handleBlur }) => (
          <form id="forgot-password" onSubmit={handleSubmit} className="card-body" tabIndex="500">
            <h5 className="mb-2">Enter your employee number</h5>
              <p className="mb-3">A confirmation code will be send to your email address</p>
            <div className="form">
              <div className="form-group">
                <input
                  type="text"
                  name="employeeCode"
                  id="employeeCode"
                  autoFocus={true}
                  className="form-control"
                  onChange={handleChange}
                  value={values.employeeCode}
                  placeholder="Employee number"
                  disabled={isSubmitting}
                />
                {errors.employeeCode && touched.employeeCode && (
                  <div className="input-validation-error">{errors.employeeCode}</div>
                )}
              </div>
            </div>
            <div className="submit mt-3 mb-3">
              <button type="submit" className="btn btn-info btn-block" disabled={isSubmitting}>
                {isSubmitting ? "Processing..." : "Submit"}
              </button>
                <div className="mt-3">
                    <Link to="/login">
                        Return to login
                    </Link>
                </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

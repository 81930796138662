import React, { useState } from "react";
import ToggleButton from "react-toggle-button";
import { Formik } from "formik";
import ReactSelect from "react-select/lib/Async";
import SelectSearch from "react-select";

// import Date Picker
import enGB from "date-fns/locale/en-GB"
import enUS from "date-fns/locale/en-US"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getWeekStartDay } from "../Schedule/helpers/DateHelper";

const toggleStyle = {
  thumb: {
    borderRadius: 2,
    height: "19px"
    // paddingLeft: '3px',
  },
  track: {
    borderRadius: 2,
    height: "27px",
    padding: "3px",
    // marginLeft: '-4px',
    width: "60px"
  },
  inactiveLabel: {
    color: "#5d5d5d"
  },
  colors: {
    active: { base: "#3454f5" },
    inactive: { base: "#e5e6e7" },
    activeThumb: "#3454f5",
    inactiveThumb: "#e5e6e7"
  }
};

// Components
const DoubleFormat = ({
  name,
  label,
  placeholder,
  values,
  handleChange,
  handleBlur,
  isSubmitting,
  min,
  max,
  step,
  key
}) => {
  return (
    <div className="form-group row" key={`${name}-${key}`}>
      <label className="col-md-4 col-form-label ">
        {label || name}:
      </label>
      <div className="col-md-8">
        <input
          type="number"
          name={name}
          placeholder={placeholder || ""}
          className="form-control input-sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name] || ""}
          disabled={isSubmitting}
          min={min}
          step={step}
        />
      </div>
    </div>
  );
};
const TextInput = ({
  name,
  label,
  placeholder,
  values,
  handleChange,
  handleBlur,
  isSubmitting,
  key
}) => {
  return (
    <div className="form-group row" key={`${name}-${key}`}>
      <label className="col-md-4 col-form-label ">
        {label || name}:
      </label>
      <div className="col-md-8">
        <input
          type="text"
          name={name}
          placeholder={placeholder || ""}
          className="form-control input-sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name] || ""}
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
};

const ColorInput = ({
  name,
  label,
  values,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  options,
  key
}) => {
  return (
    <div className="form-group row" key={`${name}-${key}`}>
      <label className="col-md-4 col-form-label ">
        {label || name}:
      </label>
      <div className="col-md-8 color-container">
        {options.map(o => (
          <button type="button" onClick={() => setFieldValue(name, o.value)} className={`color-picker ${o.value}`}>{values[name] && values[name] === o.value ? <i className="fa fa-check"></i> : null}</button>
        ))}

      </div>
    </div>
  );
};
const ReactSelectInput = ({
  name,
  label,
  placeholder,
  values,
  loadOptions,
  handleBlur,
  isSubmitting,
  setFieldValue,
  isMulti = true,
  closeMenuOnSelect = false,
  clearable = false,
  key,
  dependsOn,
  dependsOnValue
}) => {
  const [value, setValue] = useState("");
  return (
    <div className="form-group row" key={`${name}-${key}`}>
      <label className="col-md-4 col-form-label">
        {label || name}:
      </label>
      <div className="col-md-8">
        <ReactSelect
          inputValue={value}
          disabled={(dependsOn && (!dependsOnValue || dependsOnValue === null)) || isSubmitting}
          isMulti={isMulti}
          placeholder={placeholder}
          name={name}
          onChange={v => {
            setFieldValue(name, v);
          }}
          onInputChange={(inputValue, { action }) => {
            if (action !== "set-value" || !isMulti) {
              setValue(inputValue);
              return inputValue;
            }

            return value;
          }}
          clearable={clearable}
          isClerable={clearable}
          closeMenuOnSelect={closeMenuOnSelect}
          onBlur={handleBlur}
          value={values[name] || null}
          loadOptions={(search, call) => {
            dependsOn && dependsOnValue && dependsOnValue.value ?
              loadOptions(search, call, dependsOnValue.value)
              : loadOptions(search, call)
          }}
          cacheOptions
        ></ReactSelect>
      </div>
    </div>
  );
};

const SelectSearchInput = ({
  name,
  label,
  placeholder,
  values,
  options,
  handleBlur,
  isSubmitting,
  setFieldValue,
  isMulti = true,
  closeMenuOnSelect = false,
  clearable = false,
  key,
  dependsOn,
  dependsOnValue
}) => {
  const [value, setValue] = useState("");
  return (
    <div className="form-group row" key={`${name}-${key}`}>
      <label className="col-md-4 col-form-label">
        {label || name}:
      </label>
      <div className="col-md-8">
        <SelectSearch
          inputValue={value}
          disabled={(dependsOn && (!dependsOnValue || dependsOnValue === null)) || isSubmitting}
          isMulti={isMulti}
          placeholder={placeholder}
          name={name}
          onChange={v => {
            setFieldValue(name, v);
          }}
          onInputChange={(inputValue, { action }) => {
            if (action !== "set-value" || !isMulti) {
              setValue(inputValue);
              return inputValue;
            }

            return value;
          }}
          clearable={clearable}
          isClerable={clearable}
          closeMenuOnSelect={closeMenuOnSelect}
          onBlur={handleBlur}
          value={values[name] || null}
          options={options}
        ></SelectSearch>
      </div>
    </div>
  );
};
const ComboBoxInput = ({
  name,
  label,
  placeholder,
  values,
  options,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  key
}) => {
  if (options && options.length && typeof values[name] === "undefined") {
    values[name] = options[0].value;
  }
  return (
    <div className="form-group row" key={`${name}-${key}`}>
      <label className="col-md-4 col-form-label ">
        {label || name}:
      </label>
      <div className="col-md-8">
        <select
          className="form-control input-sm schedule-select"
          placeholder={placeholder}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name] || ""}
          disabled={isSubmitting}
        >
          {options.map(({ name, value }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const ToggleInput = ({
  name,
  label,
  placeholder,
  values,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  key
}) => {
  return (
    <div className="form-group row" key={`${name}-${key}`}>
      <label className="col-md-4 col-form-label ">
        {label || name}:
      </label>
      <div className="col-md-8">
        <ToggleButton
          value={values[name]}
          colors={toggleStyle.colors}
          thumbStyle={toggleStyle.thumb}
          trackStyle={toggleStyle.track}
          inactiveLabelStyle={toggleStyle.inactiveLabel}
          onToggle={() => {
            setFieldValue(name, !values[name]);
          }}
        />
      </div>
    </div>
  );
};

class CustomDatePicker extends React.Component {
  render() {
    const { value, onClick, placeholderText } = this.props;
    return (
      <input
        className="form-control form-control-sm input-sm"
        style={{ backgroundColor: "#fff", width: "100%" }}
        placeholder={placeholderText}
        type="text"
        onClick={onClick}
        value={value}
        readOnly={true}
      />
    );
  }
}

const DateInput = ({
  name,
  label,
  placeholder,
  values,
  format,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  key,
  firstDayOfWeek
}) => {
  let value = values[name];
  if (typeof value === "string") {
    // || value instanceof Date) {
    value = new Date(value);
  }
  return (
    <div className="form-group row" key={`${name}-${key}`}>
      <label className="col-md-4 col-form-label ">
        {label || name}:
      </label>
      <div className="col-md-8">
        <DatePicker
          locale={firstDayOfWeek ? 'en-GB' : 'en-US'}
          selected={value}
          onChange={pickedDt => {
            setFieldValue(name, pickedDt);
          }}
          dateFormat={format}
          customInput={<CustomDatePicker placeholderText={placeholder} />}
        />
      </div>
    </div>
  );
};

// helpers

const transformDatesToUtc = (values, fields) => {
  fields.forEach(({ name, type, sentToServer = true }) => {
    if (!sentToServer) {
      delete values[name];
    } else {
      const dt = values[name];
      if (type === "date" && dt instanceof Date) {
        values[name] = moment(dt).format('YYYY-MM-DD');
      }
    }
  });
};

export const CrudForm = ({
  mode,
  formData = {},
  fields,
  onFormSubmit,
  onCancel,
  user
}) => {
  const shownFields = fields.filter(f => f.showOnForm !== false);
  const firstDayOfWeek = getWeekStartDay(user);
  if (firstDayOfWeek) {
    registerLocale('en-GB', enGB)
  } else {
    registerLocale('en-US', enUS)
  }
  return (
    <Formik
      initialValues={formData}
      onSubmit={async (values, { setSubmitting }) => {
        transformDatesToUtc(values, fields);
        await onFormSubmit(values);
        setSubmitting(false);
      }}
      onReset={onCancel}
      enableReinitialize={true}
    >
      {({
        values,
        isSubmitting,
        handleChange,
        handleSubmit,
        handleReset,
        handleBlur,
        setFieldValue
      }) => {
        // applyDefaults(values, fields, setFieldValue);
        fields.forEach(({ name, defaultVal }) => {
          if (values[name] === undefined || values[name] === null) {
            if (typeof defaultVal !== "undefined") {
              values[name] = defaultVal;
              setFieldValue(name, defaultVal);
            }
          }
        });
        return (
          <form
            className="form-horizontal"
            onReset={handleReset}
            onSubmit={handleSubmit}
          >
            {/* <div>
              {JSON.stringify(values)}
            </div> */}

            {shownFields.map(
              ({
                name,
                label,
                placeholder,
                type,
                options,
                format,
                loadOptions,
                step,
                min,
                isMulti,
                closeMenuOnSelect = false,
                clearable,
                showIf,
                dependsOn
              }, key) => {
                const dependsOnValue = values[dependsOn] || null;
                const props = {
                  name,
                  label,
                  placeholder,
                  values,
                  options,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  setFieldValue,
                  format,
                  loadOptions,
                  step,
                  min,
                  isMulti,
                  closeMenuOnSelect,
                  clearable,
                  showIf,
                  key,
                  firstDayOfWeek,
                  dependsOn,
                  dependsOnValue
                };
                const condition = props.showIf ? props.showIf : null;
                switch (type) {
                  case "text":
                    return TextInput(props);
                  case "select":
                    return ComboBoxInput(props);
                  case "react-select":
                    return !condition || (condition && condition.prop && values[condition.prop] === condition.value) ? <ReactSelectInput {...props} /> : null;
                  case "select-search":
                    return !condition || (condition && condition.prop && values[condition.prop] === condition.value) ? <SelectSearchInput {...props} /> : null;
                  case "toggle":
                    return ToggleInput(props);
                  case "date":
                    return DateInput(props);
                  case "color":
                    return !condition || (condition && condition.prop && values[condition.prop] === condition.value) ? ColorInput(props) : null;
                  case "double":
                    return <DoubleFormat {...props} />;
                  default:
                    return TextInput(props);
                }
              }
            )}

            <div className="form-group row">
              <div className="col-12 mt-4 text-right">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary mr-2"
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </button>
                <button
                  type="reset"
                  disabled={isSubmitting}
                  className="btn btn-default"
                >
                  Cancel
              </button>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  );
};

import React from "react";
import ButtonGroup from "../Buttons/ButtonGroup";


export default class WeekConfItem extends React.Component {

  render() {
    const { name, description, onChange, weekConf = 'week' } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-8 col-xs-12 align-middle">
            <span className="lead-sm">{name}</span>
            <p className="text-muted">{description}</p>
          </div>
          <div className="col-md-6 col-sm-4 col-xs-12">
            <ButtonGroup
              style={{ width: '100%' }}
              options={['Monday', 'Sunday']}
              selected={weekConf === 'week' ? 'Sunday' : 'Monday'}
              onChange={(val) => {
                onChange(val === 'Monday' ? 'isoWeek' : 'week')
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

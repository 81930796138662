import React, { Component } from "react";
import Toast from "../../components/Blocks/Toast/Toast";
import ReportService from "../../services/ReportService";
import zipcelx from "zipcelx";

// Styles
import "./assets/styles/Reports.css";
import "./assets/styles/Export.css";
import { MYSQL_DATETIME_FORMAT } from "../Schedule/Constant";
import moment from "moment";
import EmployeeRportFilterForm from "../../components/Forms/ReportsForm/EmployeeRportFilterForm";
import EmployeeReportTable from "../../components/Tables/ReportsTables/EmployeeReportTable";
import ReactPaginate from "react-paginate";
import StringHelper from "../../helpers/StringHelper";


export default class EmployeeReportsPage extends Component {
  state = {
    isLoadingExport: false,
    isLoading: false,
    successMessage: "",
    reportData: [],
    reportType: "",
    report: "",
    startDate: "",
    endDate: "",
    initiated: false,
    total: 0,
    filters: { perPage: 10, userIds: [] },
    page: 0,
  };
  setPage = ({ selected }) => {
    if (!this.state.initiated) return;

    this.setState({ page: selected + 1, isLoading: true }, this.getData);
  };

  componentDidMount() {
    this.setState({ initiated: true });
  }
  generateReport = report => {
    this.setState(
      {
        reportData: [],
        isLoading: true,
        report: report.report.value,
        startDate: report.startDate.format("YYYY-MM-DD"),
        endDate: report.endDate.format("YYYY-MM-DD"),
        reportType: report.reportType.value,
        location: report.location ? report.location.value : null,
        department: report.department ? report.department.value : null
      },
      async () => {
        const { data } = await ReportService.generateReport(
          report.report.value,
          {
            dateRangeType: report.reportType.value,
            startDate: report.startDate
              .startOf("day")
              .format(`${MYSQL_DATETIME_FORMAT}:00`),
            endDate: report.endDate
              .endOf("day")
              .format(`${MYSQL_DATETIME_FORMAT}:00`),
            location: report.location ? report.location.value : null,
            department: report.department ? report.department.value : null
          }
        );

        this.setState({
          reportData: data,
          //successMessage: 'Report Generated',
          isLoading: false
        });
      }
    );
  };

  async getData(refresh = false, getAll = undefined, onlyData = false) {
    const { filters, page } = this.state;
    if (refresh) this.setState({ page: 1 });
    try {
      const logs = await ReportService.getEmployeeReport({
        ...filters,
        getAll,
        locations: JSON.stringify(filters.locations),
        jobs: JSON.stringify(filters.jobs),
        departments: JSON.stringify(filters.departments),
        events: JSON.stringify(filters.events),
        sectionId: JSON.stringify(filters.sectionId),
        users: JSON.stringify(filters.users),
        salaryHourly: JSON.stringify(filters.salaryHourly),
        page: refresh ? 1 : page
      });
      if (!onlyData) {
        this.setState({
          reportData: logs.data.data,
          total: Math.ceil(logs.data.totalItems / filters.perPage)
        });
      } else {
        return logs.data.data;
      }
    } catch (e) {
      console.log(e);
      if (onlyData) {
        return [];
      }
    }

    this.setState({
      isLoading: false
    });
  }

  filter = newFilters => {
    return this.setState(
      ({ filters }) => ({
        filters: { ...filters, ...newFilters },
        isLoading: true
      }),
      () => this.getData(true)
    );
  };
  export = async () => {
    this.setState({ isLoadingExport: true });
    const resources = await this.getData(false, true, true);

    let data = [
      [
        { type: "string", value: "Name" },
        { type: "string", value: "Employee Code" },
        { type: "string", value: "Schedule" },
        { type: "string", value: "Start Date" },
        { type: "string", value: "End Date" },
        { type: "string", value: "Duration" },
        { type: "string", value: "Breaks" },
        { type: "string", value: "Duration without break" },
        { type: "string", value: "Hourly Rate" },
        { type: "string", value: "Amount" },
        { type: "string", value: "Location Code" },
        { type: "string", value: "Location" },
        { type: "string", value: "State" },
        { type: "string", value: "Job Code" },
        { type: "string", value: "Job" },
        { type: "string", value: "Department Code" },
        { type: "string", value: "Department" }
      ]
    ];

    data = data.concat(
      resources.map(row => [
        {
          type: "string",
          value: row.employeeName
        },
        {
          type: "string",
          value: row.employeeCode
        },
        {
          type: "string",
          value: row.name
        },
        {
          type: "string",
          value: row.startDate
        },
        {
          type: "string",
          value: row.endDate
        },
        {
          type: "string",
          value: row.duration
        },
        {
          type: "string",
          value: row.breaks
        },
        {
          type: "string",
          value: row.durationWithouBreak
        },
        {
          type: "string",
          value: `$${StringHelper.formatMoney(row.hourlyRate|| 0)}`
        },
        {
          type: "string",
          value: `$${StringHelper.formatMoney(row.amount || 0)}`
        },
        {
          type: "string",
          value: row.locationCode
        },
        {
          type: "string",
          value: row.location
        },
        {
          type: "string",
          value: row.locationState
        },
        {
          type: "string",
          value: row.jobCode
        },
        {
          type: "string",
          value: row.job
        },
        {
          type: "string",
          value: row.departmentCode
        },
        {
          type: "string",
          value: row.department
        },
      ])
    );

    try {
      await zipcelx({
        filename: `Employee Report`,
        sheet: { data }
      });
    } catch (e) {
      console.log(e);
    }
    this.setState({ isLoadingExport: false });
  };
  render() {
    const { successMessage, isLoading, page, total, reportData, isLoadingExport } = this.state;
    const { user } = this.props;
    const successToast = successMessage ? (
      <Toast
        status="success"
        title="Reports"
        message={successMessage}
        dismiss={() => this.setState({ successMessage: null })}
      />
    ) : (
        ""
      );

    const Loader = () => <div className="text-muted my-4">Loading...</div>;
    return (
      <div className="e-panel card">
        <div className="card-header">
          <h4>Employee Reports</h4>
        </div>
        <div className="col-12">
          {successToast}
          <EmployeeRportFilterForm filter={this.filter} user={user} />
          {isLoading ? <Loader /> : <EmployeeReportTable isLoadingExport={isLoadingExport} export={this.export} user={user} resources={reportData} />}
          <div className="mb-4 d-flex justify-content-center">
            <ReactPaginate
              previousLabel={"‹"}
              nextLabel={"›"}
              breakLabel={"..."}
              breakLinkClassName={"page-link"}
              forcePage={page - 1}
              breakClassName={"page-item"}
              pageCount={total}
              initialPage={0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.setPage}
              containerClassName={"pagination mt-3 mb-0"}
              pageLinkClassName={"page-link"}
              pageClassName={"page-item"}
              activeClassName={"active"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
            />
          </div>
        </div>
      </div>
    );
  }
}

import {
  MYSQL_DATETIME_FORMAT,
  SCHEDULE_MODES,
  SCHEDULE_STATUSES,
  TABS,
  SCHEDULE_MAPPED_EVENTS
} from "../../../containers/Schedule/Constant";
import {
  fetchMonthlySchedules,
  fetchMyDepartmentSchedules,
  fetchSchedules,
  getMonthlySchedules,
  getSchedule
} from "./R_API";
import ArrayHelper from "../../../helpers/ArrayHelper";
import {
  buildEventData,
  calculateOvertime,
  eventHasChanged,
  mapEventsForMonthlyView,
  processEventSave
} from "./EventHelper";
import { saveSchedule } from "./CUD_API";
import ErrorToast from "../../BryntumScheduler/ErrorToast";
import CostCalculation from "../../../helpers/CostCalculation";
import moment from "moment";
import each from "each.js";
import { closeModal } from "../Modals";
import Breaks from "../../../containers/Schedule/Breaks";
import ScheduleService from "../../../services/ScheduleService";
import AsyncAlert from "../../../helpers/AsyncAlert";
import COLORS from "../../BryntumScheduler/colors";
import { copyObject } from "../../../helpers/Object";

const costCalculation = new CostCalculation();

function refreshSchedules(isSearch = false) {
  const { activeTab, mode } = this.state;
  if (activeTab === TABS.MY_EMPLOYEES) {
    if (mode === SCHEDULE_MODES.THIS_MONTH) {
      this.call(fetchMonthlySchedules, isSearch);
    } else {
      this.call(fetchSchedules, isSearch);
    }
  } else if (this.state.activeTab === TABS.MY_DEPARTMENT) {
    this.call(fetchMyDepartmentSchedules);
  } else {
    if (mode === SCHEDULE_MODES.THIS_MONTH) {
      this.call(getMonthlySchedules);
    } else {
      this.call(getSchedule);
    }
  }
}
function getNextPageMonthlySchedule({ startDate, endDate, ...filters }) {
  const { activeTab } = this.state;
  if (activeTab === TABS.MY_EMPLOYEES) {
    return ScheduleService.fetchMonthSchedules({ startDate, endDate, ...filters });
  } else if (this.state.activeTab === TABS.MY_DEPARTMENT) {
    return ScheduleService.fetchMonthDepartmentSchedules({ startDate, endDate, ...filters });
  } else {
    return ScheduleService.getMonthSchedules('self', startDate, endDate, true)
  }

}

function buildFilters(asObject = false) {
  const {
    searchName,
    selectedLocations,
    selectedLocationId,
    selectedTreeLocations,
    selectedRoles,
    selectedZones,
    selectedDepartments,
    selectedEventNames,
    showTree,
    displayLocationTree,
    sortByEventStartDate,
    hideTerminatedEmployees,
    locations,
    departments,
    selectedShowEmployees,
    user
  } = this.state;
  if (asObject) {
    const filters = {};
    if (searchName) {
      filters.search = searchName;
    }
    if (displayLocationTree && showTree && selectedTreeLocations.length) {
      filters.locations = JSON.stringify(selectedTreeLocations);
    } else if (selectedLocations.length) {
      filters.locations = JSON.stringify(selectedLocations.map(v => v.value));
    } else if (selectedLocationId && selectedLocationId !== "") {
      filters.locations = `[${selectedLocationId}]`;
    } else if (locations.length === 1) {
      filters.locations = `[${locations[0].value}]`;
    }
    if (selectedDepartments.length) {
      filters.departments = JSON.stringify(
        selectedDepartments.map(v => v.value)
      );
    } else if (departments.length === 1) {
      filters.departments = `[${departments[0].value}]`;
    }
    if (selectedRoles.length)
      filters.jobs = JSON.stringify(selectedRoles.map(v => v.value));
    if (selectedZones.length)
      filters.sectionId = JSON.stringify(selectedZones.map(v => v.value));
    if (selectedEventNames.length)
      filters.events = JSON.stringify(selectedEventNames.map(v => v.value));
    if (sortByEventStartDate) {
      filters.sort = "eventStartDate";
    }
    if (hideTerminatedEmployees) {
      filters.status = 'active';
    }
    if (selectedShowEmployees !== '') {
      filters.employeeRole = selectedShowEmployees === '-1' ? user.role : selectedShowEmployees;
    }
    return filters;
  } else {
    let filters = "";
    if (searchName) filters += `&search=${searchName}`;
    if (displayLocationTree && showTree && selectedTreeLocations.length) {
      filters += `&locations=${JSON.stringify(selectedTreeLocations)}`;
    } else if (selectedLocations.length) {
      filters += `&locations=${JSON.stringify(
        selectedLocations.map(v => v.value)
      )}`;
    } else if (selectedLocationId && selectedLocationId !== "") {
      filters += `&locations=[${selectedLocationId}]`;
    } else if (locations.length === 1) {
      filters += `&locations=[${locations[0].value}]`;
    }
    if (selectedDepartments.length) {
      filters += `&departments=${JSON.stringify(
        selectedDepartments.map(v => v.value)
      )}`;
    } else if (departments.length === 1) {
      filters += `&departments=[${departments[0].value}]`;
    }
    if (selectedRoles.length)
      filters += `&jobs=${JSON.stringify(selectedRoles.map(v => v.value))}`;
    if (selectedZones.length)
      filters += `&sectionId=${JSON.stringify(selectedZones.map(v => v.value))}`;
    if (selectedEventNames.length)
      filters += `&events=${JSON.stringify(
        selectedEventNames.map(v => v.value)
      )}`;
    if (sortByEventStartDate) {
      filters += `&sort=eventStartDate`;
    }
    if (hideTerminatedEmployees) {
      filters += `&status=active`;
    }
    if (selectedShowEmployees !== '') {
      filters += `&employeeRole=${selectedShowEmployees === '-1' ? user.role : selectedShowEmployees}`;
    }
    return filters;
  }
}
async function calculateMyEstimation() {
  const { selfResource, selfEvents, config, mode, rates = {}, user } = this.state;
  if (mode !== SCHEDULE_MODES.THIS_MONTH) {
    await each.concurrent(
      selfResource,
      async (resource, ind) => {
        selfResource[ind] = await calculateOvertime(
          resource,
          selfEvents,
          config,
          rates,
          user
        );
      },
      5
    );
  }
  this.setState({
    selfResource,
    eventsVersion: this.state.eventsVersion + 1,
    resourcesVersion: this.state.resourcesVersion + 1
  });
}
async function calculateEstimation() {
  const { resources, events, config, mode, rates, user } = this.state;
  const mappedEvents = await ArrayHelper.mapBy(
    events.filter(ev => ev.id !== -1 && ev.name),
    "resourceId",
    null,
    true
  );
  const estimation = JSON.parse(
    JSON.stringify(this.state.estimation.background)
  );
  estimation.totalEmployees = this.state.estimation.totalEmployees;
  estimation.background = JSON.parse(
    JSON.stringify(this.state.estimation.background)
  );
  if (mode === SCHEDULE_MODES.THIS_MONTH) {
    await each.concurrent(
      Object.values(mappedEvents),
      async evs => {
        const resource = evs[0];
        await calculateEstimationForResource(
          evs,
          resource.resourceId,
          estimation,
          resource,
          config,
          rates,
          user
        );
      },
      5
    );
  } else {
    await each.concurrent(
      resources,
      async (resource, ind) => {
        resources[ind] = await calculateEstimationForResource(
          mappedEvents[resource.id] || [],
          resource.id,
          estimation,
          resource,
          config,
          rates,
          user
        );
      },
      5
    );
  }
  this.setState({
    mappedEvents,
    eventsVersion: this.state.eventsVersion + 1,
    resourcesVersion: this.state.resourcesVersion + 1,
    resources,
    estimation,
    events
  });
}

async function calculateEstimationForResource(
  resourceEvents = [],
  resourceId,
  estimation,
  resource,
  config,
  rates,
  user
) {
  resource.hrs = {
    total: {
      hrs: 0,
      min: 0
    },
    ovt: {
      hrs: 0,
      min: 0
    },
    regularCost: 0,
    otCost: 0
  };
  if (resourceEvents.length > 0) {
    if (!estimation.scheduledEmployees.includes(resourceId)) {
      estimation.scheduledEmployees.push(resourceId);
    }
    const hasUnpublished = resourceEvents.filter(ev => ev.status === "pending");
    if (hasUnpublished.length > 0) {
      estimation.unPublishedEvents += 1;
      estimation.unPublishedEventIds = [
        ...estimation.unPublishedEventIds,
        ...hasUnpublished.map(ev => ev.id)
      ];
    }

    resource.hrs = {
      total: { hrs: 0, min: 0 },
      ovt: { hrs: 0, min: 0 },
      regularCost: 0,
      otCost: 0
    };
    costCalculation.setWeekStart(user.weekConf);
    const result = await costCalculation.calculateCost(
      config.startDate,
      config.endDate,
      resourceEvents,
      resource.locationState,
      resource.salaryHourly === "H",
      rates[resourceId] || {},
      true
    );
    const cost = result.cost;
    resource.hrs.ovt.hrs = cost.ovt.hrs;
    resource.hrs.ovt.min = cost.ovt.min;
    resource.hrs.total.hrs = cost.reg.hrs;
    resource.hrs.total.min = cost.reg.min;
    resource.hrs.regularCost = cost.reg.cost;
    resource.hrs.otCost = cost.ovt.cost;
  }
  estimation.time.hr += resource.hrs.total.hrs;
  estimation.time.min += resource.hrs.total.min;
  if (estimation.time.min >= 60) {
    estimation.time.hr += 1;
    estimation.time.min -= 60;
  }
  estimation.ot.hr += resource.hrs.ovt.hrs;
  estimation.ot.min += resource.hrs.ovt.min;
  if (estimation.ot.min >= 60) {
    estimation.ot.hr += 1;
    estimation.ot.min -= 60;
  }
  if (resource.hrs.regularCost) {
    estimation.time.cost += resource.hrs.regularCost;
  }
  if (resource.hrs.otCost) {
    estimation.ot.cost += resource.hrs.otCost;
  }
  return resource;
}

function processScheduleAfterSave(
  saveData,
  response,
  breaks,
  breakData,
  newStartDate,
  newEndDate,
  isAvailable,
  data,
  isMonthly = false
) {
  try {
    const view = [
      this.state.activeTab === TABS.MY_SHIFT
        ? isAvailable
          ? "selfAvailable"
          : "selfEvents"
        : isAvailable
          ? "available"
          : "events"
    ];
    const events = this.state[view];
    if (response.data.message) {
      new ErrorToast(`Error: ${response.data.message}`);
    } else {
      const { id } = data;
      let eventId = `${id}`.indexOf("generated") === -1 ? id : response.data.id;
      const eventData = buildEventData(
        saveData,
        eventId,
        breaks,
        breakData,
        newStartDate,
        newEndDate,
        data,
        isAvailable,
        this.state.mode,
        isMonthly
      );
      const ind = events.findIndex(p => p.id === eventId);
      if (ind >= 0) {
        events[ind] = eventData;
      } else {
        events.push(eventData);
      }
    }
    if (isMonthly) {
      this.setState({
        [view]: events,
        eventsVersion: this.state.eventsVersion + 1,
        availableEventsVersion: this.state.availableEventsVersion + 1
      });
    } else {
      this.setState(
        {
          [view]: events,
          eventsVersion: this.state.eventsVersion + 1,
          availableEventsVersion: this.state.availableEventsVersion + 1,
          isLoading: false
        },
        async () => {
          if (
            this.state.activeTab === TABS.MY_EMPLOYEES &&
            isManager(this.state.user)
          ) {
            await this.call(calculateEstimation);
          } else if (this.state.activeTab === TABS.MY_SHIFT) {
            await this.call(calculateMyEstimation);
          }
          this.setState({
            availableEventsVersion: this.state.availableEventsVersion + 1,
            eventsVersion: this.state.eventsVersion + 1
          });
        }
      );
    }
    return events;
  } catch (e) {
    console.log(e);
    this.setState({
      isLoading: false
    });
  }
}

function onEventSave(event, isAvailable = false) {
  if (
    event.eventRecord &&
    (!isAvailable ? eventHasChanged(event.eventRecord) : true)
  ) {
    if (
      !canModifyShift(
        event.eventRecord.data,
        this.state.activeTab,
        this.state.user && isManager(this.state.user)
      )
    ) {
      this.setState({
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion: this.state.availableEventsVersion + 1,
        isLoading: false
      });
      return;
    }
    this.setState({
      isLoading: true
    });
    const { resourceId } = event.eventRecord.data;
    // if (scheduleConfigs && scheduleConfigs.sections && (scheduleConfigs.sections['all'] || scheduleConfigs.sections[jobId])) {
    //   const jobSections = scheduleConfigs.sections[jobId] || [];
    //   const allSections = scheduleConfigs.sections['all'] || [];
    //   const sections = [...jobSections, ...allSections];
    //   // if (event.eventRecord.data.sectionId) {
    //   //   if (!Array.isArray(event.eventRecord.data.sectionId)) {
    //   //     event.eventRecord.data.sectionId = [event.eventRecord.data.sectionId];
    //   //   }
    //     // const section = sections.find(s => event.eventRecord.data.sectionId.includes(s.value));
    //     // if (section && section.color) {
    //     //   event.eventRecord.data.color = section.color;
    //     //   event.eventRecord.data.cls = section.color;
    //     //   event.eventRecord.data.eventColor = section.color;
    //     //   event.eventRecord.color = section.color;
    //     //   event.eventRecord.cls = section.color;
    //     //   event.eventRecord.eventColor = section.color;
    //     // } else {
    //       event.eventRecord.data.color = '';
    //       event.eventRecord.data.cls = '';
    //       event.eventRecord.data.eventColor = '';
    //       event.eventRecord.color = '';
    //       event.eventRecord.cls = '';
    //       event.eventRecord.eventColor = '';
    //     //}
    //   // } else {
    //   //   event.eventRecord.data.color = '';
    //   //   event.eventRecord.data.cls = '';
    //   //   event.eventRecord.data.eventColor = '';
    //   //   event.eventRecord.color = '';
    //   //   event.eventRecord.cls = '';
    //   //   event.eventRecord.eventColor = '';
    //   // }
    // } else {
    event.eventRecord.data.color = '';
    event.eventRecord.data.cls = '';
    event.eventRecord.data.eventColor = '';
    event.eventRecord.color = '';
    event.eventRecord.cls = '';
    event.eventRecord.eventColor = '';
    // }
    const {
      saveData,
      breaks,
      breakData,
      newStartDate,
      newEndDate
    } = processEventSave(event);
    const isAvailable = parseInt(resourceId.toString()) === 0;
    saveSchedule(saveData)
      .then(response => {
        this.call(
          processScheduleAfterSave,
          saveData,
          response,
          breaks,
          breakData,
          newStartDate,
          newEndDate,
          isAvailable,
          event.eventRecord.data,
          false
        );
      })
      .catch(() => {
        this.setState({
          eventsVersion: this.state.eventsVersion + 1,
          resourcesVersion: this.state.resourcesVersion + 1,
          availableEventsVersion: this.state.availableEventsVersion + 1,
          isLoading: false
        });
      });
  } else {
    this.setState({
      eventsVersion: this.state.eventsVersion + 1,
      resourcesVersion: this.state.resourcesVersion + 1,
      availableEventsVersion: this.state.availableEventsVersion + 1,
      isLoading: false
    });
  }
}

function onScheduleSave(event) {
  return new Promise((resolve, reject) => {
    if (event.eventRecord && eventHasChanged(event.eventRecord)) {
      this.setState({
        isLoading: true
      });
      // const scheduleConfigs = this.state.scheduleConfigs;
      // const { jobId } = event.eventRecord.data;
      // if (scheduleConfigs && scheduleConfigs.sections && (scheduleConfigs.sections['all'] || scheduleConfigs.sections[jobId])) {
      //   const jobSections = scheduleConfigs.sections[jobId] || [];
      //   const allSections = scheduleConfigs.sections['all'] || [];
      //   const sections = [...jobSections, ...allSections];
      //   if (event.eventRecord.data.sectionId) {
      //     const section = sections.find(s => s.value === event.eventRecord.data.sectionId);
      //     if (section && section.color) {
      //       event.eventRecord.data.color = section.color;
      //       event.eventRecord.data.cls = section.color;
      //       event.eventRecord.data.eventColor = section.color;
      //       event.eventRecord.color = section.color;
      //       event.eventRecord.cls = section.color;
      //       event.eventRecord.eventColor = section.color;
      //     } else {
      //       event.eventRecord.data.color = '';
      //       event.eventRecord.data.cls = '';
      //       event.eventRecord.data.eventColor = '';
      //       event.eventRecord.color = '';
      //       event.eventRecord.cls = '';
      //       event.eventRecord.eventColor = '';
      //     }
      //   } else {
      //     event.eventRecord.data.color = '';
      //     event.eventRecord.data.cls = '';
      //     event.eventRecord.data.eventColor = '';
      //     event.eventRecord.color = '';
      //     event.eventRecord.cls = '';
      //     event.eventRecord.eventColor = '';
      //   }
      // } else {
        event.eventRecord.data.color = '';
        event.eventRecord.data.cls = '';
        event.eventRecord.data.eventColor = '';
        event.eventRecord.color = '';
        event.eventRecord.cls = '';
        event.eventRecord.eventColor = '';
      // }
      const {
        saveData,
        breaks,
        breakData,
        newStartDate,
        newEndDate
      } = processEventSave(event);
      saveSchedule(saveData)
        .then(response => {
          const events = this.call(
            processScheduleAfterSave,
            saveData,
            response,
            breaks,
            breakData,
            newStartDate,
            newEndDate,
            false,
            event.eventRecord.data,
            true
          );
          mapEventsForMonthlyView(events).then(mapEvents => {
            this.setState(
              {
                isLoading: false,
                mapEvents
              },
              async () => {
                resolve();
                if (
                  this.state.activeTab === TABS.MY_EMPLOYEES &&
                  this.state.user.role === "manager"
                ) {
                  await this.call(calculateEstimation);
                } else if (this.state.activeTab === TABS.MY_SHIFT) {
                  await this.call(calculateMyEstimation);
                }
              }
            );
          });
        })
        .catch(() => {
          this.setState({
            eventsVersion: this.state.eventsVersion + 1,
            resourcesVersion: this.state.resourcesVersion + 1,
            availableEventsVersion: this.state.availableEventsVersion + 1,
            isLoading: false
          });
          reject("Something went wrong please try again");
        });
    } else {
      reject("Schedule haven't changed");
    }
  });
}

function searchOnScheduler() {
  if (TABS.MY_EMPLOYEES === this.state.activeTab) {
    if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
      this.call(fetchSchedules, true);
    } else {
      this.call(fetchMonthlySchedules, true);
    }
    this.call(closeModal);
  } else {
    this.call(fetchMyDepartmentSchedules, true);
  }
}

function _handleKeyDown(e) {
  if (e.key === "Enter") {
    this.call(searchOnScheduler);
  }
}

function saveCopyEvent(data, key, lastCopiedEvents, events) {
  const { config, selectedEvents, activeTab } = this.state;
  const {
    id,
    resourceId,
    name,
    startDate,
    endDate,
    notes,
    locationId,
    jobId,
    sectionId = [],
    sectionData = []
  } = data;
  const { breaks, breakData } = Breaks.constructBreakDate(data);
  const saveData = {
    id,
    resourceId,
    name,
    startDate: moment(startDate).format(MYSQL_DATETIME_FORMAT) + ":00",
    endDate: moment(endDate).format(MYSQL_DATETIME_FORMAT) + ":00",
    notes,
    breaks,
    breakData: JSON.stringify(breakData),
    locationId,
    jobId,
    sectionId: typeof sectionId === 'object' ? JSON.stringify(sectionId) : sectionId,
    sectionData: typeof sectionData === 'object' ? JSON.stringify(sectionData) : sectionData
  };
  if (
    activeTab === TABS.MY_SHIFT &&
    [
      SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY,
      SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF
    ].includes(name)
  ) {
    saveData.status = SCHEDULE_STATUSES.APPROVED;
    saveData.isUnavailable = 1;
  }
  return ScheduleService.saveSchedule(saveData)
    .then(response => {
      data.copyStatus = response.data.id ? 1 : 2;
      const copyEv = selectedEvents.filter(se => se.copyStatus !== 0).length;
      const totalCopyEvent = selectedEvents.length;
      let percentage = (copyEv / totalCopyEvent).toFixed(2);
      if (copyEv === totalCopyEvent) {
        percentage = (100).toFixed(2);
      }
      const state = {
        completePercentage: percentage,
        endCopy: 1 * percentage === 100
      };
      data.id = response.data.id;
      if (response.data.id) {
        let eventId =
          `${id}`.indexOf("generated") === -1 ? id : response.data.id;
        const eventData = {
          ...data,
          id: eventId,
          resourceId,
          breaks,
          breakData: JSON.stringify(breakData),
          startDate: moment(startDate).toDate(),
          endDate: moment(endDate).toDate(),
          currentDate: moment(startDate).toDate(),
          status: saveData.status ? saveData.status : SCHEDULE_STATUSES.PENDING,
          resizable: this.state.mode === SCHEDULE_MODES.TODAY
        };
        if (this.state.mode === SCHEDULE_MODES.THIS_MONTH) {
          eventData.start = moment(data.startDate).toDate();
          eventData.end = moment(data.endDate).toDate();
        }
        if (
          moment(startDate).isBetween(
            moment(config.startDate),
            moment(config.endDate),
            "days",
            "[]"
          )
        ) {
          const view = [
            this.state.activeTab === TABS.MY_SHIFT ? "selfEvents" : "events"
          ];
          const events = this.state[view];
          const ind = events.findIndex(p => p.id === eventId);
          if (ind >= 0) {
            const event = events[ind];
            eventData.status = event.status;
            events[ind] = eventData;
          } else {
            events.push(eventData);
          }
          state[view] = events;
          if (state.endCopy) {
            state.eventsVersion = this.state.eventsVersion + 1;
            state.resourcesVersion = this.state.resourcesVersion + 1;
          }
        } else {
          if (state.endCopy) {
            state.eventsVersion = this.state.eventsVersion + 1;
            state.resourcesVersion = this.state.resourcesVersion + 1;
          }
        }
        lastCopiedEvents.push(eventData);
      } else {
        data.copyMessage = response.data.message
          ? response.data.message
          : "Something goes wrong with copying shift";
      }
      selectedEvents[key] = data;
      state.selectedEvents = selectedEvents;
      events.push(data);
      this.setState(state);
    })
    .catch(() => {
      this.setState({
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion: this.state.availableEventsVersion + 1,
        isLoading: false
      });
    });
}

async function startCopyMonthlyEvent() {
  if (
    this.state.copyDate &&
    moment(this.state.copyDate).isSameOrAfter(moment(), "day")
  ) {
    let allDates = Object.keys(this.state.selectedDays).map(d => moment(d));
    const minDate = moment.min(allDates);
    const selectedEvents = [];
    let { copyToResources } = this.state;
    const copyDate = this.state.copyDate;
    Object.keys(this.state.selectedDays).forEach(d => {
      this.state.selectedDays[d].forEach(ev => {
        const days = moment(ev.startDate).diff(minDate, "days");
        ev.oldStartDate = ev.startDate;
        ev.oldEndDate = ev.endDate;
        const startDate = moment(
          `${moment(copyDate).format("YYYY-MM-DD")} ${moment(
            ev.startDate
          ).format("HH:mm")}:00`
        );
        const endDate = moment(
          `${moment(copyDate).format("YYYY-MM-DD")} ${moment(ev.endDate).format(
            "HH:mm"
          )}:00`
        );
        ev.startDate = startDate.add(days, "days").toDate();
        ev.start = startDate.add(days, "days").toDate();
        ev.endDate = endDate.add(days, "days").toDate();
        ev.end = endDate.add(days, "days").toDate();
        ev.id = `${ev.id}_generated`;
        ev.resourceName = "";
        ev.copyStatus = 0;
        if (copyToResources.length > 0) {
          copyToResources.forEach(resource => {
            const newEvent = JSON.parse(JSON.stringify(ev));
            newEvent.id = `${ev.id}_${resource.id}_generated`;
            newEvent.resourceId = resource.id;
            newEvent.firstName = resource.first_name;
            newEvent.lastName = resource.last_name;
            newEvent.resourceName = resource.name;
            newEvent.locationId = resource.location_id;
            newEvent.salaryHourly = resource.salary_hourly;
            newEvent.hourlyRate = resource.hourly_rate;
            newEvent.jobId = resource.job_id;
            newEvent.jobName = resource.job;
            newEvent.locationCode = resource.location_code;
            newEvent.locationName = resource.location;
            newEvent.primaryJobId = resource.job_id;
            newEvent.secondaryJobId = resource.secondray_job_id;
            newEvent.secondaryJob = resource.secondray_job;
            selectedEvents.push(newEvent);
          });
        } else {
          ev.resourceName = `${ev.firstName} ${ev.lastName}`;
          selectedEvents.push(ev);
        }
      });
    });
    this.setState(
      {
        isCopying: selectedEvents.length > 0,
        completePercentage: 0,
        selectedEvents
      },
      async () => {
        const { selectedEvents } = this.state;
        if (selectedEvents.length) {
          const lastCopiedEvents = [];
          const events = [];
          const savedEvents = selectedEvents.map((ev, key) => {
            return this.call(saveCopyEvent, ev, key, lastCopiedEvents, events);
          });
          await Promise.all(savedEvents).then(() => {
            this.setState({
              lastCopiedEvents,
              selectedEvents: events
            });
          });
        }
      }
    );
  } else {
    this.setState({
      copyError:
        "You haven't selected a date to copy shifts or date is invalid can't be on the past"
    });
  }
}

async function startCopyEvents() {
  if (
    this.state.copyDate &&
    moment(this.state.copyDate).isSameOrAfter(moment(), "day")
  ) {
    let allDates = Object.keys(this.state.selectedDays).map(d => moment(d));
    const minDate = moment.min(allDates);
    const selectedEvents = [];
    let { copyToResources } = this.state;
    copyToResources = copyToResources.map(el => el.value);
    let mapResources = {};
    if (this.state.activeTab === TABS.MY_SHIFT) {
      const { selfResource } = this.state;
      const resource = selfResource[0];
      mapResources[resource.id] = resource;
    } else {
      mapResources = await ArrayHelper.mapBy(this.state.resources, "id");
    }
    const copyDate = this.state.copyDate;
    Object.keys(this.state.selectedDays).forEach(d => {
      this.state.selectedDays[d].forEach(ev => {
        const days = moment(ev.startDate).diff(minDate, "days");
        ev.oldStartDate = ev.startDate;
        ev.oldEndDate = ev.endDate;
        const startDate = moment(
          `${moment(copyDate).format("YYYY-MM-DD")} ${moment(
            ev.startDate
          ).format("HH:mm")}:00`
        );
        const endDate = moment(
          `${moment(copyDate).format("YYYY-MM-DD")} ${moment(ev.endDate).format(
            "HH:mm"
          )}:00`
        );
        ev.startDate = startDate.add(days, "days").toDate();
        ev.endDate = endDate.add(days, "days").toDate();
        ev.id = `${ev.id}_generated`;
        ev.resourceName = "";
        ev.copyStatus = 0;
        if (copyToResources.length > 0) {
          copyToResources.forEach(resourceId => {
            const newEvent = JSON.parse(JSON.stringify(ev));
            newEvent.id = `${ev.id}_${resourceId}_generated`;
            newEvent.resourceId = resourceId;
            const resource = mapResources[newEvent.resourceId];
            if (resource) {
              newEvent.resourceName = resource.name;
              newEvent.locationId = resource.locationId;
              newEvent.jobId = resource.jobId;
            }
            selectedEvents.push(newEvent);
          });
        } else {
          const resource = mapResources[ev.resourceId];
          if (resource) {
            ev.resourceName = resource.name;
            ev.locationId = resource.locationId;
            ev.jobId = resource.jobId;
          }
          selectedEvents.push(ev);
        }
      });
    });
    this.setState(
      {
        isCopying: true,
        completePercentage: 0,
        selectedEvents
      },
      async () => {
        const lastCopiedEvents = [];
        const newEvents = [];
        const savedEvents = selectedEvents.map((ev, key) => {
          return this.call(saveCopyEvent, ev, key, lastCopiedEvents, newEvents);
        });
        await Promise.all(savedEvents).then(() => {
          this.setState({
            lastCopiedEvents,
            selectedEvents: newEvents
          });
        });
      }
    );
  } else {
    this.setState({
      copyError:
        "You haven't selected a date to copy shifts or date is invalid can't be on the past"
    });
  }
}

async function onDeleteAllEvents() {
  const property = [
    this.state.activeTab === TABS.MY_SHIFT ? "selfEvents" : "events"
  ];
  const events = this.state[property].filter(
    ev =>
      canModifyShift(
        ev,
        this.state.activeTab,
        this.state.user && isManager(this.state.user)
      ) &&
      ev.id &&
      ev.id !== -1
  );
  const ids = events
    .filter(ev =>
      canModifyShift(
        ev,
        this.state.activeTab,
        this.state.user && isManager(this.state.user)
      )
    )
    .map(e => e.id);
  if (ids.length) {
    const allow = await AsyncAlert.alert(
      `Delete ${ids.length} schedule${ids.length > 1 ? "s" : ""}`,
      `You are going to delete ${ids.length} schedule${ids.length > 1 ? "s" : ""
      }. Do you want to continue?`
    );
    if (allow) {
      this.setState({
        isLoading: true
      });
      ScheduleService.deleteSchedules(ids)
        .then(async () => {
          const events = (this.state[property] || []).filter(
            event => !ids.includes(event.id)
          );
          const lastCopiedEvents = (this.state.lastCopiedEvents || []).filter(
            event => !ids.includes(event.id)
          );
          if (this.state.mode === SCHEDULE_MODES.THIS_MONTH) {
            await this.call(mapEventsForMonthlyView, events);
          }
          this.setState(
            {
              [property]: events,
              available: this.state.available.filter(
                event => !ids.includes(event.id)
              ),
              lastCopiedEvents,
              eventsVersion: this.state.eventsVersion + 1,
              resourcesVersion: this.state.resourcesVersion + 1,
              availableEventsVersion: this.state.availableEventsVersion + 1,
              isLoading: false
            },
            async () => {
              if (this.state.activeTab === TABS.MY_EMPLOYEES) {
                await this.call(calculateEstimation);
              } else if (this.state.activeTab === TABS.MY_SHIFT) {
                await this.call(calculateMyEstimation);
              }
            }
          );
        })
        .catch(() => {
          this.setState({
            eventsVersion: this.state.eventsVersion + 1,
            resourcesVersion: this.state.resourcesVersion + 1,
            availableEventsVersion: this.state.availableEventsVersion + 1,
            isLoading: false
          });
        });
    }
  }
}

function submitEvents(id) {
  if (!(this.state.user && isManager(this.state.user))) {
    return;
  }
  let eventId;
  const selfEvents = this.state.selfEvents.slice();
  const hasEvents = selfEvents.filter(e => e.id === id).length > 0;
  this.setState({ isLoading: true });
  const state = {
    eventsVersion: this.state.eventsVersion + 1,
    resourcesVersion: this.state.resourcesVersion + 1
  };
  const status = canPublish(this.state.user) ? SCHEDULE_STATUSES.APPROVED : SCHEDULE_STATUSES.SUBMITTED;
  if (hasEvents) {
    eventId = id;
    state.selfEvents = this.state.selfEvents.map(event => {
      if (event.id === id)
        return {
          ...event,
          eventColor: COLORS[status],
          cls: '',
          status
        };
      return event;
    });
  } else {
    eventId = this.state.selfEvents
      .filter(event => event.status === SCHEDULE_STATUSES.PENDING)
      .map(event => event.id);
    state.selfEvents = this.state.selfEvents.map(event => {
      if (eventId.includes(event.id))
        return {
          ...event,
          eventColor: COLORS[status],
          cls: '',
          status
        };
      return event;
    });
  }
  ScheduleService.submitEvents({ scheduleId: eventId })
    .then(() => {
      state.isLoading = false;
      this.setState(state);
    })
    .catch(() => {
      this.setState({
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion: this.state.availableEventsVersion + 1,
        isLoading: false,
        selfEvents
      });
    });
}

function publishEvents(id) {
  if (!(this.state.user && this.state.user.role === "manager")) {
    return;
  }
  let eventId;
  const unPublishedEventIds = this.state.estimation.unPublishedEventIds;
  const hasEvents = unPublishedEventIds.includes(id);
  this.setState({ isLoading: true });
  const state = {
    eventsVersion: this.state.eventsVersion + 1,
    resourcesVersion: this.state.resourcesVersion + 1
  };

  if (hasEvents) {
    eventId = id;
    const mapEventByResource = {};
    let activeResourceId = 0;
    state.events = this.state.events.map(event => {
      if (!mapEventByResource[event.resourceId]) {
        mapEventByResource[event.resourceId] = [];
      }
      if (event.id === id) {
        activeResourceId = event.resourceId;
        return {
          ...event,
          eventColor: (event.color ? event.color : COLORS[event.name] || COLORS[SCHEDULE_STATUSES.APPROVED]),
          cls: event.color ? event.color : '',
          status: SCHEDULE_STATUSES.APPROVED
        };
      }
      mapEventByResource[event.resourceId].push(event.id);
      return event;
    });
    // state.estimation.unPublishedEvents -= 1;
    state.estimation = copyObject(this.state.estimation);
    const estimation = copyObject(this.state.estimation);
    state.estimation.unPublishedEventIds = estimation.unPublishedEventIds.filter(
      e => e !== eventId
    );
    if (
      !activeResourceId ||
      !mapEventByResource[activeResourceId] ||
      mapEventByResource[activeResourceId].length === 0
    ) {
      state.estimation.unPublishedEvents = estimation.unPublishedEvents - 1;
    }
  } else {
    eventId = unPublishedEventIds;
    state.events = this.state.events.map(event => {
      if (eventId.includes(event.id))
        return {
          ...event,
          eventColor: (event.color ? event.color : COLORS[event.name] || COLORS[SCHEDULE_STATUSES.APPROVED]),
          cls: event.color ? event.color : '',
          status: SCHEDULE_STATUSES.APPROVED
        };
      return event;
    });
    // state.estimation.unPublishedEventIds = [];
    state.estimation = copyObject(this.state.estimation);
    state.estimation.background.unPublishedEventIds = [];
    state.estimation.unPublishedEventIds = [];
    state.estimation.background.unPublishedEvents = 0;
    state.estimation.unPublishedEvents = 0;
  }

  ScheduleService.publishEvents({ scheduleId: eventId })
    .then(response => {
      state.isLoading = false;
      this.setState(state);
    })
    .catch(() => {
      this.setState({
        eventsVersion: this.state.eventsVersion + 1,
        resourcesVersion: this.state.resourcesVersion + 1,
        availableEventsVersion: this.state.availableEventsVersion + 1,
        isLoading: false
      });
    });
}

function handleMultiSelect(selectedEvents) {
  try {
    selectedEvents = selectedEvents.filter(ev =>
      canModifyShift(
        ev,
        this.state.activeTab,
        isManager(this.state.user)
      )
    );
    if (selectedEvents.length > 0) {
      let selectedDays = {};
      const selectedResources = [];
      selectedEvents = selectedEvents.map(ev => {
        const event = JSON.parse(JSON.stringify(ev));
        const date = moment(event.startDate || event.start).format("L");
        if (!selectedDays[date]) {
          selectedDays[date] = [];
        }
        if (
          !selectedResources.includes(event.resourceId) &&
          this.state.activeTab === TABS.MY_EMPLOYEES
        ) {
          selectedResources.push(event.resourceId);
        }
        selectedDays[date].push(event);
        return event;
      });
      const copyToResources = [];
      if (selectedResources.length === 1) {
        const resources =
          this.state.activeTab === TABS.MY_SHIFT
            ? this.state.selfResource
            : this.state.resources;
        const resource = resources.find(r => r.id === selectedResources[0]);
        if (resource) {
          copyToResources.push({
            value: resource.id,
            label: resource.name
          });
        }
      }
      this.setState({
        copyToResources,
        selectedResources,
        selectedEvents,
        selectedDays
      });
    } else {
      this.setState({
        selectedDays: {},
        selectedResources: [],
        selectedEvents: []
      });
    }
  } catch (e) {
    console.log(e);
  }
}
function filterByDepartment(e) {
  if (e.target.value) {
    const selectedDepartments = [{ value: parseInt(e.target.value) }];
    this.setState(
      {
        selectedDepartments
      },
      () => {
        if (TABS.MY_EMPLOYEES === this.state.activeTab) {
          if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
            this.call(fetchSchedules, true);
          } else {
            this.call(fetchMonthlySchedules, true);
          }
        } else if (TABS.MY_DEPARTMENT) {
          this.call(fetchMyDepartmentSchedules);
        }
      }
    );
  } else {
    this.setState(
      {
        selectedDepartments: []
      },
      () => {
        if (TABS.MY_EMPLOYEES === this.state.activeTab) {
          if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
            this.call(fetchSchedules);
          } else {
            this.call(fetchMonthlySchedules);
          }
        } else if (TABS.MY_DEPARTMENT) {
          this.call(fetchMyDepartmentSchedules);
        }
      }
    );
  }
}
function filterByZones(e) {
  if (e.target.value) {
    const selectedZones = [{ value: parseInt(e.target.value) }];
    this.setState(
      {
        selectedZones
      },
      () => {
        if (TABS.MY_EMPLOYEES === this.state.activeTab) {
          if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
            this.call(fetchSchedules, true);
          } else {
            this.call(fetchMonthlySchedules, true);
          }
        } else if (TABS.MY_DEPARTMENT) {
          this.call(fetchMyDepartmentSchedules);
        }
      }
    );
  } else {
    this.setState(
      {
        selectedZones: []
      },
      () => {
        if (TABS.MY_EMPLOYEES === this.state.activeTab) {
          if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
            this.call(fetchSchedules);
          } else {
            this.call(fetchMonthlySchedules);
          }
        } else if (TABS.MY_DEPARTMENT) {
          this.call(fetchMyDepartmentSchedules);
        }
      }
    );
  }
}
function filterByEmployeeRole(e) {
  if (e.target.value) {
    const selectedShowEmployees = e.target.value;
    this.setState(
      {
        selectedShowEmployees
      },
      () => {
        if (TABS.MY_EMPLOYEES === this.state.activeTab) {
          if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
            this.call(fetchSchedules, true);
          } else {
            this.call(fetchMonthlySchedules, true);
          }
        } else if (TABS.MY_DEPARTMENT) {
          this.call(fetchMyDepartmentSchedules);
        }
      }
    );
  } else {
    this.setState(
      {
        selectedShowEmployees: ""
      },
      () => {
        if (TABS.MY_EMPLOYEES === this.state.activeTab) {
          if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
            this.call(fetchSchedules);
          } else {
            this.call(fetchMonthlySchedules);
          }
        } else if (TABS.MY_DEPARTMENT) {
          this.call(fetchMyDepartmentSchedules);
        }
      }
    );
  }
}
function filterByLocation(e) {
  if (e.target.value) {
    const selectedLocationId = e.target.value;
    const selectedLocations = [parseInt(e.target.value)];
    this.setState(
      {
        selectedLocations,
        selectedTreeLocations: selectedLocations,
        selectedLocationId
      },
      () => {
        if (TABS.MY_EMPLOYEES === this.state.activeTab) {
          if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
            this.call(fetchSchedules, true);
          } else {
            this.call(fetchMonthlySchedules, true);
          }
        } else if (TABS.MY_DEPARTMENT) {
          this.call(fetchMyDepartmentSchedules);
        }
      }
    );
  } else {
    this.setState(
      {
        selectedLocations: [],
        selectedTreeLocations: [],
        selectedLocationId: ""
      },
      () => {
        if (TABS.MY_EMPLOYEES === this.state.activeTab) {
          if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
            this.call(fetchSchedules);
          } else {
            this.call(fetchMonthlySchedules);
          }
        } else if (TABS.MY_DEPARTMENT) {
          this.call(fetchMyDepartmentSchedules);
        }
      }
    );
  }
}
function selectMyFilter(e) {
  const state = {
    selectedZones: [],
    selectedZonesLength: 0,
    selectedRoles: [],
    selectedRolesLength: 0,
    selectedLocations: [],
    selectedTreeLocations: [],
    checked: [],
    expanded: [],
    selectedLocationsLength: 0,
    selectedEventNames: [],
    selectedEventsLength: 0,
    selectedDepartments: [],
    selectedDepartmentsLength: 0,
    searchName: "",
    selectedFilterId: '',
  };
  if (e && e.target && e.target.value) {
    const selectedFilterId = parseInt(e.target.value);
    const { scheduleConfigs, departments, roles, locations, allZones, eventNames } = this.state;
    const filter = scheduleConfigs.filters.find(f => f.id === selectedFilterId);
    if (filter) {
      state.selectedFilterId = selectedFilterId;
      if (filter.locations) {
        const locationIds = JSON.parse(filter.locations);
        state.selectedLocations = locations.filter(l => locationIds.includes(l.value));
        state.checked = locationIds;
        state.selectedTreeLocations = locationIds;
        state.selectedLocationsLength = state.selectedLocations.length;
      }
      if (filter.departments) {
        const departmentIds = JSON.parse(filter.departments);
        state.selectedDepartments = departments.filter(d => departmentIds.includes(d.value));
        state.selectedDepartmentsLength = state.selectedDepartments.length;
      }
      if (filter.events) {
        const events = JSON.parse(filter.events);
        state.selectedEventNames = eventNames.filter(e => events.includes(e.value));
        state.selectedEventsLength = state.selectedEventNames.length;
      }
      if (filter.jobs) {
        const jobIds = JSON.parse(filter.jobs);
        state.selectedRoles = roles.filter(j => jobIds.includes(j.value));
        state.selectedRolesLength = state.selectedRoles.length;
      }
      if (filter.sections) {
        const sectionIds = JSON.parse(filter.sections);
        state.selectedZones = allZones.filter(z => sectionIds.includes(z.value));
        state.selectedZonesLength = state.selectedZones.length;
      }
      this.setState(state,
        () => {
          if (TABS.MY_EMPLOYEES === this.state.activeTab) {
            if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
              this.call(fetchSchedules, true);
            } else {
              this.call(fetchMonthlySchedules, true);
            }
          } else if (TABS.MY_DEPARTMENT) {
            this.call(fetchMyDepartmentSchedules);
          }
        }
      );
    } else {
      this.setState(state,
        () => {
          if (TABS.MY_EMPLOYEES === this.state.activeTab) {
            if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
              this.call(fetchSchedules);
            } else {
              this.call(fetchMonthlySchedules);
            }
          } else if (TABS.MY_DEPARTMENT) {
            this.call(fetchMyDepartmentSchedules);
          }
        }
      );
    }
  } else {
    this.setState(state,
      () => {
        if (TABS.MY_EMPLOYEES === this.state.activeTab) {
          if (this.state.mode !== SCHEDULE_MODES.THIS_MONTH) {
            this.call(fetchSchedules);
          } else {
            this.call(fetchMonthlySchedules);
          }
        } else if (TABS.MY_DEPARTMENT) {
          this.call(fetchMyDepartmentSchedules);
        }
      }
    );
  }
}
function isShiftUnavailable(ev) {
  return (
    ev.isUnavailable === 1 ||
    [
      SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF,
      SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY
    ].includes(ev.name)
  );
}
function canModifyShift(ev, tab, isManager) {
  if (tab === TABS.MY_DEPARTMENT || ev.leaveId) {
    return false;
  }
  const allowedStatus = [
    SCHEDULE_STATUSES.APPROVED,
    SCHEDULE_STATUSES.PENDING,
    SCHEDULE_STATUSES.SUBMITTED,
    SCHEDULE_STATUSES.REJECTED
  ];
  if (tab === TABS.MY_SHIFT) {
    return (
      (isManager ? true : isShiftUnavailable(ev)) &&
      (allowedStatus.includes(ev.status) || !ev.status)
    );
  }
  return (
    isManager &&
    !isShiftUnavailable(ev) &&
    (allowedStatus.includes(ev.status) || !ev.status)
  );
}
function isManager(user) {
  return (user.role === 'manager' || canEdit(user)) ? true : false;
};
function canEdit(user) {
  return (user.userRole && user.userRole.schedule && user.userRole.schedule.mySchedule && user.userRole.schedule.mySchedule.update) ? true : false;
}
function canPublish(user) {
  return (user.canPublish || (user.userRole && user.userRole.schedule && user.userRole.schedule.mySchedule && user.userRole.schedule.mySchedule.publish)) ? true : false;
}
function viewOnly(user) {
  return (user.userRole && user.userRole.schedule && user.userRole.schedule.mySchedule ? user.userRole.schedule.mySchedule.update : (user.role === 'manager')) ? false : true;
}
export {
  getNextPageMonthlySchedule,
  refreshSchedules,
  buildFilters,
  calculateEstimation,
  onEventSave,
  processScheduleAfterSave,
  onScheduleSave,
  _handleKeyDown,
  startCopyEvents,
  startCopyMonthlyEvent,
  saveCopyEvent,
  onDeleteAllEvents,
  submitEvents,
  publishEvents,
  handleMultiSelect,
  filterByLocation,
  selectMyFilter,
  filterByDepartment,
  filterByZones,
  calculateMyEstimation,
  isShiftUnavailable,
  canModifyShift,
  isManager,
  canEdit,
  canPublish,
  viewOnly,
  filterByEmployeeRole
};

import React from "react";
import PropTypes from "prop-types";
import BryntumScheduler from "../BryntumScheduler/BryntumScheduler";
import AssignEvents from "../Modals/AssignEvents";
import ScheduleService from "../../services/ScheduleService";
class AvailableShift extends React.Component {
    state = {
        assignModal: false,
        events: []
    };
    assignEvents = (events) => {
        this.setState({
            assignModal: true,
            events
        });
    };
    closeModal = () => {
        this.setState({
            assignModal: false,
            events: []
        });
        if (this.refs.availableScheduler && this.refs.availableScheduler.schedulerEngine) {
            this.refs.availableScheduler.schedulerEngine.clearEventSelection();
        }
    };
    pickEvents = async (events) => {
        const { pickEvents = () => { } } = this.props;
        const eventIds = events.map((ev) => ev.id);
        return pickEvents(eventIds);
    };
    render() {
        const {
            resources,
            events,
            eventsVersion,
            resourcesVersion,
            startDate,
            endDate,
            viewPreset,
            scheduleMode,
            isManager,
            isMySchedule,
            submitOrPublish,
            columns,
            handleResourceContextMenu,
            onEventSave,
            onEventDelete,
            onEventMove,
            locations,
            jobs,
            mapJobs,
            mapLocations,
            allResources,
            onEventsAssign,
            readOnly,
            weekConf,
            loggedInUser
        } = this.props;
        return (
            <>
                {this.state.assignModal && (
                    <AssignEvents
                        isOpen={this.state.assignModal}
                        resources={allResources}
                        mapJobs={mapJobs}
                        jobs={jobs}
                        mapLocations={mapLocations}
                        events={this.state.events}
                        closeModal={this.closeModal}
                        onEventsAssign={onEventsAssign}
                        mode={scheduleMode}
                        isMySchedule={isMySchedule}
                    />
                )}
                <BryntumScheduler
                    comId={"availableScheduler"}
                    ref={"availableScheduler"}
                    autoHeight={true}
                    allowOverlap={true}
                    resources={resources}
                    events={events}
                    eventsVersion={eventsVersion}
                    resourcesVersion={resourcesVersion}
                    startDate={startDate}
                    endDate={endDate}
                    showJobAndLocations={true}
                    jobs={jobs}
                    mapJobs={mapJobs}
                    mapLocations={mapLocations}
                    locations={locations}
                    viewPreset={viewPreset}
                    scheduleMode={scheduleMode}
                    weekConf={weekConf || 'week'}
                    isManager={isManager}
                    loggedInUser={loggedInUser}
                    readOnly={readOnly}
                    isMySchedule={isMySchedule}
                    submitOrPublish={submitOrPublish}
                    columns={columns}
                    handleResourceContextMenu={handleResourceContextMenu}
                    isAvailable={true}
                    onEventSelectionChange={() => { }}
                    onSelectEventsChange={() => { }}
                    copyEvents={() => { }}
                    onEventSave={onEventSave}
                    onEventDelete={onEventDelete}
                    onEventMove={onEventMove}
                    assignEvents={this.assignEvents}
                    pickEvents={this.pickEvents}
                    submitEvents={() => { }}
                    notifyForEvent={() => { }}
                    publishEvents={() => { }}
                />
                <div className="mb-5" />
            </>
        );
    }
}
AvailableShift.defaultProps = {
    resources: [],
    events: [],
    jobs: [],
    locations: [],
    eventsVersion: 0,
    resourcesVersion: 0,
    startDate: null,
    endDate: null,
    viewPreset: null,
    scheduleMode: null,
    isManager: false,
    isMySchedule: false,
    submitOrPublish: "submit",
    columns: [],
    handleResourceContextMenu: () => { },
    onEventSelectionChange: () => { },
    onSelectEventsChange: () => { },
    copyEvents: () => { },
    onEventSave: () => { },
    onEventDelete: () => { },
    onEventMove: () => { },
    submitEvents: () => { },
    notifyForEvent: () => { },
    publishEvents: () => { },
};

AvailableShift.propTypes = {
    resources: PropTypes.array,
    events: PropTypes.array,
    jobs: PropTypes.array,
    locations: PropTypes.array,
    columns: PropTypes.array,
    eventsVersion: PropTypes.number,
    resourcesVersion: PropTypes.number,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    viewPreset: PropTypes.string,
    scheduleMode: PropTypes.string,
    submitOrPublish: PropTypes.string,
    isManager: PropTypes.bool,
    isMySchedule: PropTypes.bool,
    handleResourceContextMenu: PropTypes.func,
    onEventSelectionChange: PropTypes.func,
    onSelectEventsChange: PropTypes.func,
    copyEvents: PropTypes.func,
    onEventSave: PropTypes.func,
    onEventDelete: PropTypes.func,
    onEventMove: PropTypes.func,
    submitEvents: PropTypes.func,
    notifyForEvent: PropTypes.func,
    publishEvents: PropTypes.func,
};

export default AvailableShift;

import { handleResourceContextMenu } from '../helpers/Resource';
import { handleSelectionChange, notifyForEvent, onEventMove, releaseEvent } from "../helpers/EventActions";
import { handleMultiSelect, onEventSave, submitEvents, isManager, canPublish } from "../helpers/SchedulerHelper";
import { openModal } from "../Modals";
import { onEventDelete } from "../helpers/CUD_API";
import BryntumScheduler from "../../BryntumScheduler/BryntumScheduler";
import React from "react";
import { getColumns } from "../../../containers/Schedule/Columns";
import { renderLocation, renderResourceName, renderRole } from '../helpers/Renders';

function renderMyScheduleView() {
    const {
        user,
        mode,
        selfEvents,
        selfResource,
        config,
        eventsVersion,
        availableLocations,
        availableJobs,
        mapJobs,
        mapLocations,
        resourcesVersion,
        scheduleConfigs
    } = this.state;
    const getProfile = resource => {
        this.setState({
            employeeCode: resource.record.data.code,
            showEmployeeProfileModal: true
        });
    };
    const copyEvents = async resource => {
        const { id } = resource.record.data;
        const events = this.state.mappedEvents[id] || [];
        this.call(handleMultiSelect, events);
        this.call(openModal, "copyModal");
    };
    const columns = getColumns(
        user,
        (d) => this.call(renderRole, d),
        (d) => this.call(renderResourceName, d),
        (d) => this.call(renderLocation, d),
        getProfile,
        copyEvents,
        false
    );
    return (
        <BryntumScheduler
            ref={"scheduler"}
            comId={"myScheduler"}
            autoHeight={true}
            allowOverlap={false}
            jobs={availableJobs}
            mapJobs={mapJobs}
            mapLocations={mapLocations}
            locations={availableLocations}
            eventsVersion={eventsVersion}
            resourcesVersion={resourcesVersion}
            events={selfEvents || []}
            resources={selfResource || []}
            startDate={config.startDate}
            endDate={config.endDate}
            viewPreset={config.viewPreset}
            handleResourceContextMenu={(ctx) => this.call(handleResourceContextMenu, ctx)}
            columns={columns}
            useInitialAnimation="slide-from-left"
            onEventSelectionChange={(s) => this.call(handleSelectionChange, s, true)}
            onSelectEventsChange={(se) => this.call(handleMultiSelect, se, true)}
            copyEvents={() => this.call(openModal, 'copyModal')}
            onEventSave={(e, isA) => this.call(onEventSave, e, isA)}
            onEventDelete={(e, s, isM) => this.call(onEventDelete, e, s, isM)}
            onEventMove={(e) => this.call(onEventMove, e)}
            scheduleMode={mode}
            weekConf={user.weekConf || 'week'}
            isManager={isManager(user)}
            canPublish={canPublish(user)}
            loggedInUser={user}
            isMySchedule={true}
            submitOrPublish={"submit"}
            submitEvents={(e) => this.call(submitEvents, e)}
            notifyForEvent={(e) => this.call(notifyForEvent, e)}
            releaseEvent={(e) => this.call(releaseEvent, e)}
            scheduleConfigs={scheduleConfigs}
        />
    )
}

export {
    renderMyScheduleView
}

const toggleStyle = {
    thumb: {
        borderRadius: 2,
        height: '19px',
        // paddingLeft: '3px',
    },
    track: {
        borderRadius: 2,
        height: '27px',
        padding: '3px',
        marginLeft: '-4px',
        width: '60px',
    },
    inactiveLabel: {
        color: '#5d5d5d',
    },
    colors: {
        active: { base: '#3454f5' },
        inactive: { base: '#e5e6e7' },
        activeThumb: '#3454f5',
        inactiveThumb: '#e5e6e7',
    }
};
export default toggleStyle;

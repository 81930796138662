import React from "react";
import Select from "react-select";
// import Pagination from "rc-pagination";
import ReactPaginate from "react-paginate";
import "rc-pagination/assets/index.css";
import "moment/locale/en-gb";

// Services
import EmployeeService from "../../services/EmployeeService";
import userService from "../../services/UserService";

// Components
import PeopleTableRow from "../../components/Tables/PeopleTableRow/PeopleTableRow";

// Styles
import "./assets/styles/People.css";
import ShareEmployee from "../../components/Modals/ShareEmployee";
import Toast from "../../components/Blocks/Toast";
import Modal from "react-modal";
import ShareEditEmployee from "../../components/Modals/ShareEditEmployee";
import ResetPasswordModal from "../../components/Modals/ResetPasswordModal";
import StorageManager from "../../helpers/StorageManager";
import ViewUserDetailsModal from "../../components/Modals/ViewUserDetailsModal";
import ComposeMessageModal from "../../components/Modals/ComposeMessageModal";
import { getWeekStartDay } from "../../components/Schedule/helpers/DateHelper";

// const itemRender = (current, type, element) => {
//   if(type === "prev") {
//     return (
//       <button className="page-link">&laquo;</button>
//     );
//   }
//   if(type === "next") {
//     return (
//       <button className="page-link">&raquo;</button>
//     );
//   }

//   return element;
// };

class People extends React.Component {
  timeout = null;
  constructor(props) {
    super(props);
    const { user } = props
    this.state = {
      user,
      people: [],
      total_pages: 1,
      page: 1,
      totalUsers: "",
      isLoading: true,
      departmentFilter: "",
      jobsFilter: "",
      locationsFilter: "",
      departments: [],
      jobs: [],
      locations: [],
      shareLocations: [],
      search: "",
      shareModal: false,
      shareEditModal: false,
      resetPasswordModal: false,
      viewDetailsModal: false,
      composeMessageModal: false,
      employee: null,
      successMessage: null,
      error: null,
      forcePage: null
    };
  }

  searchPeople = e => {
    e.preventDefault();

    const { value } = e.target;

    this.setState(
      {
        search: value
      },
      () => {
        value.length === 0 &&
          this.setState({ page: 1, forcePage: 0 }, () =>
            this.fetchPeople(null, "searchPeople")
          );
      }
    );
  };
  submitSearch = e => {
    e && e.preventDefault();
    this.setState({ page: 1, forcePage: 0 }, () => this.fetchPeople(e));
  };
  fetchPeople = (e, resource = "") => {
    e && e.preventDefault();

    const {
      search,
      departmentFilter,
      jobsFilter,
      locationsFilter
    } = this.state;
    const filters = `&search=${search}&location=${locationsFilter}&department=${departmentFilter}&job=${jobsFilter}`;
    this.setState({ isLoading: true });
    EmployeeService.fetchEmployees(this.state.page, 20, filters)
      .then(res => {
        this.setState({
          people: res.data.data,
          total_pages: res.data.total_pages,
          total: res.data.total || 1,
          page: res.data.page || 1,
          totalUsers: res.data.total,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false
        });
      });
  };
  sendToCompose = id => {
    // this.props.history.push(`/app/people/${id}`);
    this.setState({ employee: id, composeMessageModal: true });
  };
  viewUserDetails = id => {
    this.setState({ employee: id, viewDetailsModal: true });
    // this.props.history.push(`/app/people/details/${id}`);
  };
  shareEmployee = user => {
    this.setState({
      employee: user,
      shareModal: true
    });
  };
  editShareEmployee = user => {
    this.setState({
      employee: user,
      shareEditModal: true
    });
  };
  closeModal = () => {
    this.setState({
      employee: null,
      shareModal: false,
      shareEditModal: false,
      resetPasswordModal: false,
      viewDetailsModal: false,
      composeMessageModal: false
    });
  };
  fetchAllFilters = () => {
    EmployeeService.fetchPeopleFilters(true)
      .then(res => {
        this.setState({
          locations: res.data.locations,
          shareLocations: res.data.allLocations,
          jobs: res.data.jobs,
          departments: res.data.departments
        });
      })
      .catch(err => console.log(err));
  };
  addFilter = (val, name) => {
    this.setState(
      {
        [name]: val
      },
      () => {
        this.setState({ page: 1, forcePage: 0 }, () => {
          this.fetchPeople(null, "addFilter");
        });
      }
    );
  };
  setPage = page => {
    this.setState(
      {
        people: [],
        isLoading: true,
        page: page.selected + 1,
        forcePage: page.selected
      },
      () => {
        this.fetchPeople(null, "setPage");
      }
    );
  };

  async componentDidMount() {
    //await this.fetchPeople(null, 'componentDidMount');
    this.fetchAllFilters();
    Modal.setAppElement(document.getElementById("app"));
  }
  setEmployeeAsShared = (user, share) => {
    const { people } = this.state;
    this.setState({
      people: people.map(p => {
        if (p.id === user.employeeId) {
          p.shared = share;
        }
        return p;
      }),
      succesTitle: "Share Employee",
      successMessage:
        share === 0
          ? `Share for employee: ${user.firstName} ${user.lastName}, was deleted successfully`
          : `Employee: ${user.firstName} ${user.lastName}, was shared successfully`,
      employee: null,
      shareModal: false,
      shareEditModal: false
    });
  };
  employeeWasShared = (user, share) => {
    const { people } = this.state;
    this.setState({
      people: people.map(p => {
        if (p.id === user.employeeId) {
          p.shared = share;
        }
        return p;
      }),
      error: `Share for employee: ${user.firstName} ${user.lastName}, was ${
        share === 1 ? "approved" : "rejected"
        } from manager of other location`,
      employee: null,
      shareModal: false,
      shareEditModal: false
    });
  };
  resetPassword = user => {
    this.setState({ employee: user, resetPasswordModal: true });
  };
  passwordWasReset = (status, msg) => {
    this.setState({
      succesTitle: "Reset password",
      successMessage: status
        ? msg
        : "There was a problem resetting the password"
    });
  };

  impersonate = async userId => {
    const {
      data: { token }
    } = await userService.impersonate(userId);
    StorageManager.set("token", token);
    window.location = "/app";
  };

  render() {
    const {
      people,
      total_pages,
      totalUsers,
      isLoading,
      departments,
      jobs,
      locations,
      succesTitle,
      successMessage,
      error,
      user,
      employee,
      shareEditModal,
      shareModal,
      resetPasswordModal,
      viewDetailsModal,
      composeMessageModal,
      forcePage
    } = this.state;

    const preLoader = isLoading && (
      <h6 className="text-center mt-3">
        <b>Loading...</b>
      </h6>
    );

    const noData = !isLoading && people.length === 0 && (
      <h6 className="text-center mt-3">
        <b>No data to show.</b>
      </h6>
    );
    const successToast = successMessage ? (
      <Toast
        status="success"
        title={succesTitle}
        message={successMessage}
        dismiss={() => this.setState({ successMessage: null })}
      />
    ) : (
        ""
      );
    const errorToast = error ? (
      <Toast
        title="Share Employee"
        message={error}
        dismiss={() => this.setState({ error: null })}
      />
    ) : (
        ""
      );

    const canImpersonate = !isLoading && user && user.isSuperUser === 1;

    return (
      <div className="container content-area">
        {employee && viewDetailsModal && (
          <ViewUserDetailsModal
            user={user}
            isOpen={viewDetailsModal}
            id={employee}
            closeModal={this.closeModal}
          />
        )}

        {employee && composeMessageModal && (
          <ComposeMessageModal
            isOpen={composeMessageModal}
            id={employee}
            closeModal={this.closeModal}
          />
        )}

        {employee && shareModal && (
          <ShareEmployee
            firstDayOfWeek={getWeekStartDay(user)}
            isOpen={this.state.shareModal}
            closeModal={this.closeModal}
            setEmployeeAsShared={this.setEmployeeAsShared}
            employeeWasShared={this.employeeWasShared}
            user={this.state.employee}
            locations={this.state.shareLocations}
          />
        )}
        {employee && shareEditModal && (
          <ShareEditEmployee
            isOpen={this.state.shareEditModal}
            closeModal={this.closeModal}
            setEmployeeAsShared={this.setEmployeeAsShared}
            employeeWasShared={this.employeeWasShared}
            user={this.state.employee}
            locations={this.state.shareLocations}
          />
        )}
        {employee && resetPasswordModal && (
          <ResetPasswordModal
            userId={employee.userId}
            onDone={this.passwordWasReset}
            isOpen={this.state.resetPasswordModal}
            closeModal={this.closeModal}
            employee={employee}
          />
        )}
        {successToast}
        {errorToast}
        <section className="section">
          <div className="section-body">
            <div className="row mt-4 mt-md-5">
              <div className="col-lg-12">
                <div className="e-panel card">
                  <div className="card-header">
                    <h4>People {totalUsers && `(${totalUsers})`}</h4>

                    <form onSubmit={this.submitSearch}>
                      <div className="input-group">
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          style={{ width: '200px' }}
                          onChange={this.searchPeople}
                          placeholder="Search by name or Emp Code"
                        />
                        <div className="input-group-btn">
                          <button className="btn btn-info" type="submit">
                            <i className="fas fa-search" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-body">
                    <div className="e-table">
                      <div className="table-responsive table-lg">
                        <div
                          className={
                            isLoading
                              ? "loader d-flex justify-content-center align-items-center"
                              : "hidden"
                          }
                        >
                          <div id="spinner" />
                        </div>

                        <div className="select-filters">
                          <Select
                            className="flex-1 mr-2"
                            placeholder="Select a Department to filter"
                            getOptionLabel={o => o.name}
                            onChange={v => this.addFilter(v ? v.value : '', 'departmentFilter')}
                            options={departments}
                            isDisabled={isLoading}
                            isClearable={true}
                          />
                          <Select
                            className="flex-1 mr-2"
                            placeholder="Select a Job to filter"
                            getOptionLabel={o => o.name}
                            onChange={v => this.addFilter(v ? v.value : '', 'jobsFilter')}
                            options={jobs}
                            isDisabled={isLoading}
                            isClearable={true}
                          />
                          <Select
                            className="flex-1"
                            placeholder="Select a Location to filter"
                            getOptionLabel={o => o.name}
                            onChange={v => this.addFilter(v ? v.value : '', 'locationsFilter')}
                            options={locations}
                            isDisabled={isLoading}
                            isClearable={true}
                          />
                        </div>

                        <table className="table table-striped text-nowrap">
                          <thead>
                            <tr>
                              <th className="text-center">Actions</th>
                              <th className="text-center">Emp Code</th>
                              <th className="text-center">Name</th>
                              <th className="text-center">Department</th>
                              <th className="text-center">Job Title</th>
                              <th className="text-center">Location</th>
                              <th className="text-center">Email</th>
                              <th className="text-center">Shared</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading &&
                              people.map(user => (
                                <PeopleTableRow
                                  key={user.id}
                                  onClick={this.sendToCompose}
                                  id={user.externalId}
                                  userId={user.userId}
                                  employeeId={user.id}
                                  empId={user.employeeCode}
                                  firstName={user.firstName}
                                  lastName={user.lastName}
                                  departmentCode={user.departmentCode}
                                  departmentDescription={
                                    user.departmentDescription
                                  }
                                  jobCode={user.jobCode}
                                  jobTitle={user.jobTitle}
                                  email={user.email}
                                  locationId={user.locationId}
                                  shared={user.shared}
                                  locationCode={user.locationExternalCode}
                                  locationExternalCode={
                                    user.locationDescription
                                  }
                                  viewUserDetails={this.viewUserDetails}
                                  shareEmployee={this.shareEmployee}
                                  editShareEmployee={this.editShareEmployee}
                                  resetPassword={this.resetPassword}
                                  role={
                                    this.state.user ? this.state.user.role : ""
                                  }
                                  impersonate={this.impersonate}
                                  canImpersonate={canImpersonate}
                                />
                              ))}
                          </tbody>
                        </table>
                      </div>
                      {noData}
                      {preLoader}

                      <div className="d-flex justify-content-center">
                        <ReactPaginate
                          previousLabel={"‹"}
                          nextLabel={"›"}
                          breakLabel={"..."}
                          breakLinkClassName={"page-link"}
                          forcePage={forcePage}
                          breakClassName={"page-item"}
                          pageCount={total_pages}
                          initialPage={0}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.setPage}
                          containerClassName={"pagination mt-3 mb-0"}
                          pageLinkClassName={"page-link"}
                          pageClassName={"page-item"}
                          activeClassName={"active"}
                          previousClassName={"page-item"}
                          nextClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

People.propTypes = {};

export default People;

import { startDateAndEndDateAreValid } from "../../BryntumScheduler/Configs";
import { SCHEDULE_MODES, TABS } from "../../../containers/Schedule/Constant";
import moment from "moment";
import { getWeekConfig } from "./DateHelper";
import { clearFilters } from "../Modals";
import { refreshSchedules } from "./SchedulerHelper";

function setActiveTabFromQuery() {
    const qs = new URLSearchParams(this.props.location.search);
    let activeTab = qs.get("tab");
    if (activeTab === "null") {
        activeTab = TABS.MY_SHIFT;
    }
    if (
        activeTab &&
        [TABS.MY_SHIFT, TABS.MY_EMPLOYEES, TABS.MY_DEPARTMENT].includes(activeTab)
    ) {
        this.call(setActiveTab, activeTab, true);
    } else {
        this.call(setActiveTab, TABS.MY_SHIFT, true);
    }
}
function setActiveTab(tab, fromQuery = false, resetFilters = false) {
    if (tab !== this.state.activeTab) {
        if (!tab) {
            tab = TABS.MY_SHIFT;
        }
        const { config } = this.state;
        this.setState(
            {
                activeTab: tab,
                estimation: {
                    scheduledEmployees: [],
                    scheduledEvents: [],
                    totalEmployees: 0,
                    time: {
                        hr: 0,
                        min: 0,
                        cost: 0
                    },
                    ot: {
                        hr: 0,
                        min: 0,
                        cost: 0
                    }
                }
            },
            () => {
                if (
                    !fromQuery &&
                    config.startDate &&
                    config.endDate &&
                    startDateAndEndDateAreValid(
                        config.startDate,
                        config.endDate
                    )
                ) {
                    if (resetFilters) {
                        this.call(clearFilters, true)
                    } else {
                        this.call(refreshSchedules)
                    }
                    if (this.state.mode !== SCHEDULE_MODES.DATE_RANGE) {
                        this.props.history.replace({
                            search: `?tab=${tab}&mode=${this.state.mode}`
                        });
                    } else {
                        //this.setState({mode: SCHEDULE_MODES.TODAY});
                        this.props.history.replace({
                            search: `?tab=${tab}`
                        });
                    }
                } else {
                    this.call(setModeFromQuery, resetFilters);
                }
            }
        );
    } else if (fromQuery) {
        this.call(setModeFromQuery, resetFilters);
    }
}
function setModeFromQuery(resetFilters = false) {
    const qs = new URLSearchParams(this.props.location.search);
    const mode = qs.get("mode");
    if (mode && Object.values(SCHEDULE_MODES).includes(mode)) {
        this.call(setMode, mode, resetFilters);
    } else {
        this.call(setMode, "today", resetFilters);
    }
}
function setMode(mode, resetFilters = false) {
    const { user } = this.state;
    this.setState(
        {
            config: {
                viewPreset: "hourAndDay",
                startDate: null,
                endDate: null
            }
        },
        () => {
            let sDate = moment()
                .startOf("day")
                .toDate();
            let eDate = moment()
                .endOf("day")
                .toDate();
            if (mode) {
                if (mode !== SCHEDULE_MODES.DATE_RANGE) {
                    this.props.history.replace({
                        search: `?tab=${this.state.activeTab}&mode=${mode}`
                    });
                } else {
                    this.props.history.replace({
                        search: `?tab=${this.state.activeTab}`
                    });
                }
            }
            switch (mode) {
                case SCHEDULE_MODES.TODAY:
                    return this.setState(
                        {
                            mode: SCHEDULE_MODES.TODAY,
                            eventsVersion: this.state.eventsVersion + 1,
                            resourcesVersion:
                                this.state.resourcesVersion + 1,
                            config: {
                                viewPreset: "hourAndDay",
                                startDate: sDate,
                                endDate: eDate
                            },
                            events: [],
                            selfEvents: []
                        },
                        () => {
                            if (resetFilters) {
                                this.call(clearFilters, true)
                            } else {
                                this.call(refreshSchedules)
                            }

                        }
                    );
                case SCHEDULE_MODES.THIS_WEEK:
                    sDate = moment()
                        .startOf(getWeekConfig(user))
                        .startOf("day")
                        .toDate();
                    eDate = moment()
                        .endOf(getWeekConfig(user))
                        .endOf("day")
                        .toDate();
                    return this.setState(
                        {
                            mode: SCHEDULE_MODES.THIS_WEEK,
                            eventsVersion: this.state.eventsVersion + 1,
                            resourcesVersion:
                                this.state.resourcesVersion + 1,
                            config: {
                                viewPreset: "dayAndWeek",
                                startDate: sDate,
                                endDate: eDate
                            },
                            events: [],
                            selfEvents: []
                        },
                        () => {
                            if (resetFilters) {
                                this.call(clearFilters, true)
                            } else {
                                this.call(refreshSchedules)
                            }
                        }
                    );
                case SCHEDULE_MODES.THIS_MONTH:
                    sDate = moment()
                        .startOf("month")
                        .toDate();
                    eDate = moment()
                        .endOf("month")
                        .toDate();
                    return this.setState(
                        {
                            mode: SCHEDULE_MODES.THIS_MONTH,
                            eventsVersion: this.state.eventsVersion + 1,
                            resourcesVersion:
                                this.state.resourcesVersion + 1,
                            config: {
                                viewPreset: "dayAndMonth",
                                startDate: sDate,
                                endDate: eDate
                            },
                            selfEvents: [],
                            events: [],

                        },
                        () => {
                            if (resetFilters) {
                                this.call(clearFilters, true)
                            } else {
                                this.call(refreshSchedules)
                            }
                        }
                    );
                case SCHEDULE_MODES.DATE_RANGE:
                    return this.setState({
                        events: [],
                        selfEvents: [],
                        mode: SCHEDULE_MODES.DATE_RANGE,
                        startDate: null,
                        endDate: null,
                        focusedInput: "startDate"
                    });
                default:
                    return;
            }
        }
    );
}

export {
    setActiveTab,
    setActiveTabFromQuery,
    setMode,
    setModeFromQuery
}

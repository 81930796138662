import React, { Component } from "react";
import { Formik } from "formik";
import * as moment from "moment";

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import "./../../../general/assets/styles/react-select-search-style.css";
import "react-datepicker/dist/react-datepicker.css";

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default class BudgetForm extends Component {

  render() {
    const { budget: activeBudget, yearOptions, locationOptions, departmentOptions } = this.props;
    const editMode = !!activeBudget;
    const budget = editMode ? activeBudget : {
      year: (new Date()).getFullYear(),
      locationId: locationOptions[0].value,
      departmentId: departmentOptions[0].value,
    };

    return (
      <Formik
        initialValues={budget}
        onSubmit={async (values, { setSubmitting }) => {
          await this.props.onFormSubmit(values, editMode);
          // resetForm(budget);
          setSubmitting(false);
        }}
        onReset={() => {
          this.props.onCancel();
        }}
        enableReinitialize={true}
      >
        {({ values, isSubmitting, handleChange, handleSubmit, handleReset, handleBlur, setFieldValue }) => (
          <form className="form-horizontal" onReset={handleReset} onSubmit={handleSubmit}>
            {/* {JSON.stringify(values)} */}

            <div className="form-group row">
              <label className="col-md-4 col-form-label">Location:</label>
              <div className="col-md-8">
                <select
                  className="form-control input-sm schedule-select"
                  name="locationId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.locationId || ''}
                  disabled={isSubmitting || editMode}>
                  {locationOptions.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-md-4 col-form-label">Department:</label>
              <div className="col-md-8">
                <select
                  className="form-control input-sm schedule-select"
                  name="departmentId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.departmentId || ''}
                  disabled={isSubmitting || editMode}>
                  {departmentOptions.map(({ name, value }) => <option key={value} value={value}>{name}</option>)}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-md-4 col-form-label">Year:</label>
              <div className="col-md-8">
                <select
                  className="form-control input-sm schedule-select"
                  name="year"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.year || moment(values.period).year()}
                  disabled={isSubmitting || editMode}>
                  {yearOptions.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
                </select>
              </div>
            </div>
            <div className="row form-group" style={{ marginTop: '1rem' }}>
              {MONTHS.map(month => (
                <div key={month} className="col-md-4">
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">{month} </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        name={month}
                        placeholder="No budget"
                        className="form-control input-sm"
                        onChange={(e) => setFieldValue(e.target.name, parseInt(e.target.value, 10))}
                        onBlur={handleBlur}
                        value={values[month] || ''}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="form-group row">
              <div className="col-12 mt-4 text-right">
                <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-3">
                  {!editMode && (isSubmitting ? 'Adding...' : 'Add')}
                  {editMode && (isSubmitting ? 'Updating...' : 'Update')}
                </button>
                <button type="reset" disabled={isSubmitting} className="btn btn-default">Cancel</button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

import BaseService from "./BaseService";

class AuthService extends BaseService {
    /**
     * @memberof AuthService
     * @param {String} employeeCode
     * @param {String} password
     */
    login(employeeCode = "", password = "") {
        return this.apiPost({
            url: "/authenticate",
            data: {
                employeeCode,
                password,
            },
        });
    }

    changePassword(data) {
        return this.apiPost({
            url: "/authenticate/change-password",
            data,
        });
    }

    forgotPassword(data) {
        return this.apiPost({
            url: "/authenticate/forgot-password",
            data,
        });
    }

    resetPassword(data) {
        return this.apiPut({
            url: "/authenticate/reset-password",
            data,
        });
    }

    verifyCode(data) {
        return this.apiPost({
            url: "/authenticate/verify-code",
            data,
        });
    }
}

export default new AuthService();

import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../Blocks/Tooltip/Tooltip";
import StringHelper from "../../../helpers/StringHelper";

const PeopleTableRow = props => {
    return (
        <tr>
            <td className="align-middle text-center">
                <Tooltip placement="top" text="Compose">
                    <button
                        className="btn btn-outline-warning btn-sm mr-2"
                        onClick={() => props.onClick(props.empId)}
                    >
                        <i className="fas fa-pen" />
                    </button>
                </Tooltip>
                <Tooltip placement="top" text="View">
                    <button
                        className="btn btn-outline-info btn-sm mr-2"
                        onClick={() => props.viewUserDetails(props.empId)}
                    >
                        <i className="fas fa-eye" />
                    </button>
                </Tooltip>
                {props.shared === 0 ? (
                    <Tooltip placement="top" text="Share Employee">
                        <button
                            className="btn btn-outline-success btn-sm mr-2"
                            onClick={() => props.shareEmployee(props)}
                        >
                            <i className="fas fa-share" />
                        </button>
                    </Tooltip>
                ) : props.shared === 2 || props.shared === 1 ? (
                    <Tooltip
                        placement="top"
                        text="Cancel Or Edit Share Employee"
                    >
                        <button
                            className="btn btn-outline-danger btn-sm mr-2"
                            onClick={() => props.editShareEmployee(props)}
                        >
                            <i className="fas fa-share" />
                        </button>
                    </Tooltip>
                ) : null}
                {props.role === "manager" && (
                    <React.Fragment>
                        <Tooltip placement="top" text="Reset Employee password">
                            <button
                                className="btn btn-outline-danger btn-sm mr-2"
                                onClick={() => props.resetPassword(props)}
                            >
                                <i className="fas fa-redo" />
                            </button>
                        </Tooltip>
                    </React.Fragment>
                )}
                {props.role === "manager" && (
                    <Tooltip placement="top" text="Proxy">
                        <button
                            className="btn btn-outline-primary btn-sm mr-2"
                            onClick={() => props.impersonate(props.userId)}
                        >
                            <i className="fas fa-user-shield" />
                        </button>
                    </Tooltip>
                )}
            </td>
            <td className="text-nowrap align-middle">{props.empId}</td>
            <td className="text-nowrap align-middle">
                {props.firstName} {props.lastName}
            </td>
            <td className="text-nowrap align-middle">
                {props.departmentDescription}
            </td>
            <td className="text-nowrap align-middle">{props.jobTitle}</td>
            <td className="text-nowrap align-middle">
                {props.locationExternalCode}
            </td>
            <td className="text-nowrap align-middle">{props.email}</td>
            <td className="text-nowrap align-middle">
                {StringHelper.formatEmployeeShared(props.shared)}
            </td>
        </tr>
    );
};

PeopleTableRow.defaultProps = {
    id: -1,
    firstName: "---",
    lastName: "---",
    onClick: () => alert("Provide onClick handler!!"),
    departmentDescription: "Department",
    jobTitle: "---",
    email: "---",
    locationExternalCode: "---"
};

PeopleTableRow.propTypes = {
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    departmentCode: PropTypes.string,
    departmentDescription: PropTypes.string,
    jobTitle: PropTypes.string,
    email: PropTypes.string,
    locationExternalCode: PropTypes.string
};

export default PeopleTableRow;

import BaseService from "./BaseService";

class SettingsService extends BaseService {
  getTree() {
    return this.apiGet({
      url: `/settings/locations-tree`
    });
  }
  getEmployees(query) {
    return this.apiGet({
      url: "/settings/get-employees",
      params: query
    });
  }
  createAccessLevel(data) {
    return this.apiPost({
      url: "/settings/access-level",
      data
    });
  }
  getAccessLevel(query = {}) {
    return this.apiGet({
      url: `/settings/access-level`,
      params: query
    });
  }
  getUserGroups() {
    return this.apiGet({
      url: `/settings/user-groups`
    });
  }

  getTimezoneList() {
    return this.apiGet({
      url: "/timezone"
    });
  }

  deleteAccessLevel(id) {
    return this.apiDelete({
      url: `/settings/access-level/${id}`
    });
  }
  updateAccessLevel(data) {
    const { id, ...otherProps } = data;
    return this.apiPut({
      url: `/settings/access-level/${id}`,
      data: otherProps
    });
  }
  setAccessLevel(userId, levelIds = []) {
    return this.apiPost({
      url: `/settings/employee/${userId}/update/level`,
      data: {
        levelIds
      }
    });
  }
  createUserGroup(data) {
    return this.apiPost({
      url: "/settings/user-groups",
      data
    });
  }
  updateUserGroup(data) {
    const { id, ...otherProps } = data;
    return this.apiPut({
      url: `settings/user-groups/${id}`,
      data: otherProps
    });
  }
  deleteUserGroup(id) {
    return this.apiDelete({
      url: `/settings/user-groups/${id}`
    });
  }

  getNotificationsConfig() {
    return this.apiGet({
      url: "/settings/notifications"
    });
  }

  updateNotificationsConfig(data) {
    return this.apiPut({
      url: "/settings/notifications",
      data: data
    });
  }

  getEventNotificationConfig(eventId) {
    return this.apiGet({
      url: `/settings/event/${eventId}/notification`
    });
  }
  createEventNotificationConfig(eventId, notification) {
    return this.apiPost({
      url: `/settings/event/${eventId}/notification`,
      data: notification
    });
  }
  updateEventNotificationConfig(eventId, notification) {
    return this.apiPut({
      url: `/settings/event/${eventId}/notification`,
      data: notification
    });
  }
  deleteEventNotificationConfig(eventId) {
    return this.apiDelete({
      url: `/settings/event/${eventId}/notification`
    });
  }

  getForecastingConfigs() {
    return this.apiGet({
      url: `/forecast/configs`
    });
  }

  updateForecastingConfig(id, data) {
    return this.apiPut({
      url: `/forecast/configs/${id}`,
      data
    });
  }
}

export default new SettingsService();

const getColumns = (user, renderRole, renderResourceName, renderLocation, getProfile, copyEvents, isAvailable, isMyDepartment = false) => {

    const cellMenuItems = !isMyDepartment ? [
        {
            text: 'Profile',
            hidden: user.role !== 'manager' && !isAvailable,
            icon: 'fas fa-eye',
            onItem: getProfile
        },
        {
            text: 'Copy',
            icon: 'fas fa-copy',
            name: 'copyEvents',
            hidden: user.role !== 'manager' && !isAvailable,
            onItem: copyEvents
        }
    ] : [];

    return isAvailable ? [{
        text: "Available Shifts",
        showImage: false,
        showEventCount: true,
        editor: false,
        htmlEncode: false,
        space: 5,
        field: "name",
        responsiveLevels: {
            small: { width: 120, maxWidth: 120 },
            medium: { width: 180, maxWidth: 180 },
            normal: { width: 220 },
        },
        renderer: renderResourceName,
        filterable: false,
        cellMenuItems,
    }] : [
            {
                text: "Full Name",
                showImage: true,
                showEventCount: true,
                editor: false,
                htmlEncode: false,
                space: 5,
                field: "name",
                responsiveLevels: {
                    small: { width: 120, maxWidth: 120 },
                    medium: { width: 180, maxWidth: 180 },
                    normal: { width: 220 },
                },
                renderer: renderResourceName,
                filterable: false,
                cellMenuItems,
            },
            {
                text: "Role",
                field: "role",
                htmlEncode: false,
                width: 130,
                responsiveLevels: {
                    small: { hidden: true }, //this.state.mode === SCHEDULE_MODES.TODAY || this.state.mode === SCHEDULE_MODES.THIS_WEEK },
                    medium: { width: 80, maxWidth: 80, hidden: true },//this.state.mode === SCHEDULE_MODES.TODAY || this.state.mode === SCHEDULE_MODES.THIS_WEEK },
                    normal: { hidden: true },//this.state.mode === SCHEDULE_MODES.TODAY || this.state.mode === SCHEDULE_MODES.THIS_WEEK },
                },
                renderer: renderRole,
                filterable: false
            },
            {
                text: "Location",
                field: "location",
                htmlEncode: false,
                width: 130,
                responsiveLevels: {
                    small: { hidden: true },//this.state.mode === SCHEDULE_MODES.TODAY || this.state.mode === SCHEDULE_MODES.THIS_WEEK },
                    medium: { width: 80, maxWidth: 80, hidden: true },//this.state.mode === SCHEDULE_MODES.TODAY || this.state.mode === SCHEDULE_MODES.THIS_WEEK },
                    normal: { hidden: true },//this.state.mode === SCHEDULE_MODES.TODAY || this.state.mode === SCHEDULE_MODES.THIS_WEEK },
                },
                renderer: renderLocation,
                filterable: false
            },
        ];
}

export {
    getColumns
}

import React from "react";
import "./assets/styles/Settings.css";
import AccessLevelForm from "../../components/Forms/AccessLevelForm";
import Tooltip from "../../components/Blocks/Tooltip";
import SettingsService from "../../services/SettingsService";
import DataTable from "react-data-table-component";
import Loader from "../../components/Blocks/Loader";
import { confirmAlert } from "react-confirm-alert"; // Import
export default class AccessLevel extends React.Component {
  state = {
    tree: [],
    departments: [],
    allLocations: [],
    jobs: [],
    selectedJobs: [],
    totalJobs: 0,
    filterJobs: [],
    locationError: null,
    usersError: null,
    isLoading: true,
    isLevelsLoading: true,
    isLevelsDataLoading: true,
    accessLevels: [],
    page: 1,
    totalRows: 0,
    perPage: 10,
    editMode: false,
    mapLocations: {},
    mapJobs: {},
    mapDepartments: {},
    accessLevelObj: null,
    sort: "id",
    sortDirection: "desc",
    search: undefined,
  };
  onFormSubmit = (fields) => {
    return new Promise((resolve, reject) => {
      const {
        checked,
        selectedDepartments,
        selectedJobs,
        levelName,
        editLevelId = 0,
        editMode = false,
        incEmpl,
        excEmpl,
        keepMyHierarchy,
        keepMyEmpHierarchy,
      } = fields;
      if (
        checked.length ||
        selectedDepartments.length ||
        selectedJobs.length ||
        incEmpl.length
      ) {
        if (editMode) {
          if (editLevelId !== 0) {
            const data = {
              id: editLevelId,
              name: levelName,
              locations: JSON.stringify(checked),
              departments: JSON.stringify(
                selectedDepartments.map((v) => v.value)
              ),
              jobs: JSON.stringify(selectedJobs.map((v) => v.value)),
              employees: JSON.stringify(incEmpl.map((v) => v.value)),
              employees_exclude: JSON.stringify(excEmpl.map((v) => v.value)),
              keep_my_hierarchy: keepMyEmpHierarchy,
              keep_my_employees: keepMyHierarchy,
            };
            SettingsService.updateAccessLevel(data)
              .then(() => {
                const accessLevels = this.state.accessLevels;
                const ind = accessLevels.findIndex((a) => a.id === data.id);
                if (ind >= 0) {
                  let access = accessLevels[ind];
                  const { id, ...otherData } = data;
                  access = {
                    ...access,
                    ...otherData,
                  };
                  accessLevels[ind] = access;
                } else {
                  alert("404, Access level Can't be updated!");
                }
                this.setState({ accessLevels });
                this.resetForm();
                resolve();
              })
              .catch((err) => {
                alert("An error occurred during creating access level");
                this.resetForm();
                resolve();
              });
          } else {
            alert("An error occurred while updating!");
            this.resetForm();
            resolve();
          }
        } else {
          const data = {
            name: levelName,
            locations: JSON.stringify(checked),
            departments: JSON.stringify(
              selectedDepartments.map((v) => v.value)
            ),
            jobs: JSON.stringify(selectedJobs.map((v) => v.value)),
            employees: JSON.stringify(incEmpl.map((v) => v.value)),
            employees_exclude: JSON.stringify(excEmpl.map((v) => v.value)),
            keep_my_hierarchy: keepMyEmpHierarchy,
            keep_my_employees: keepMyHierarchy,
          };
          SettingsService.createAccessLevel(data)
            .then((response) => {
              // const accessLevels = this.state.accessLevels;
              // if (response.data && response.data.id) {
              //   accessLevels.push({
              //     id: response.data.id,
              //     users: 0,
              //     can_delete: 1,
              //     ...data
              //   });
              // } else {
              //   alert("404, Access level Can't be created!");
              // }
              this.setState({ isLevelsDataLoading: true }, () => {
                this.getAccessLevels();
              });
              this.resetForm();
              resolve();
            })
            .catch((err) => {
              console.log(err);
              alert("An error occurred during creating access level");
              this.resetForm();
              resolve();
            });
        }
      } else {
        reject({
          locationError: "Can't save an empty access level",
        });
      }
    });
  };

  resetForm = () => {
    this.setState({
      editMode: false,
      accessLevelObj: null,
    });
  };
  getAccessLevels = () => {
    const { page, perPage, sort, sortDirection, search } = this.state;
    SettingsService.getAccessLevel({
      page,
      perPage,
      sort,
      sortDirection,
      search,
    })
      .then((res) => {
        if (res.data) {
          this.setState({
            accessLevels: res.data.data,
            isLevelsLoading: false,
            isLevelsDataLoading: false,
            totalRows: res.data.totalRows,
          });
        } else {
          alert("404, access levels not found!");
          this.setState({
            isLevelsLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("An error occurred during getting access levels");
        this.setState({
          isLevelsLoading: false,
        });
      });
  };
  deleteAccessLevel = (level) => {
    confirmAlert({
      title: level.users > 0 ? "WARNING" : "Delete Access Level",
      message:
        level.users > 0
          ? "This Access level is used in some users. They will miss their access if you delete it. Are you sure that you want to delete this access level?"
          : "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = level.id;
            SettingsService.deleteAccessLevel(id)
              .then((res) => {
                this.setState(
                  {
                    isLevelsDataLoading: true,
                    editMode: false,
                    accessLevelObj: null,
                  },
                  this.getAccessLevels
                );
              })
              .catch((err) => {
                console.log(err);
                this.resetForm();
                alert("An error occurred during deleting access levels");
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  editAccessLevel = (level) => {
    this.setState({
      accessLevelObj: level,
      editMode: true,
    });
  };

  componentDidMount() {
    const user = this.props.user;
    if (user && user.isSuperUser === 1) {
      this.getAccessLevels();
    }
  }
  handlePageChange = (page) => {
    this.setState(
      {
        page,
        isLevelsDataLoading: true,
      },
      () => {
        this.getAccessLevels();
      }
    );
  };
  handlePerRowsChange = (perPage, page) => {
    this.setState(
      {
        page,
        perPage,
        isLevelsDataLoading: true,
      },
      () => {
        this.getAccessLevels();
      }
    );
  };
  handleSort = (column, sortDirection) => {
    this.setState(
      {
        sort: column.selector,
        sortDirection,
        isLevelsDataLoading: true,
      },
      () => {
        this.getAccessLevels();
      }
    );
  };
  setSearch = (e) => {
    e.preventDefault();

    const { value } = e.target;

    this.setState(
      {
        search: value.length === 0 ? undefined : value,
      },
      () => {
        value.length === 0 &&
          this.setState({ isLevelsDataLoading: true, page: 1 }, () =>
            this.getAccessLevels()
          );
      }
    );
  };
  submitSearch = (e) => {
    e && e.preventDefault();
    this.setState({ isLevelsDataLoading: true, page: 1 }, () =>
      this.getAccessLevels()
    );
  };
  render() {
    const { isLevelsLoading, accessLevelObj, editMode, accessLevels } =
      this.state;
    const {
      user,
      tree,
      departments,
      jobs,
      mapLocations,
      mapJobs,
      mapDepartments,
      employees,
    } = this.props;
    const isAdmin = user && user.isSuperUser === 1;
    const renderValueRow = (props, field) => {
      const value = props[field];
      return !props.can_delete && props.name === "Super User" && !value
        ? "all"
        : JSON.parse(value || "[]").length;
    };
    const columns = [
      {
        name: "Actions",
        selector: "action",
        sortable: false,
        minWidth: "130px",
        cell: (props) => {
          return props.can_delete === 1 ? (
            <div>
              <Tooltip placement="right" text="Delete">
                <button
                  disabled={props.can_delete === 0}
                  className="btn btn-danger btn-sm mr-2"
                  onClick={() => this.deleteAccessLevel(props)}
                >
                  <i className="fas fa-trash" />
                </button>
              </Tooltip>
              <Tooltip placement="right" text="Edit">
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => this.editAccessLevel(props)}
                >
                  <i className="fas fa-pen" />
                </button>
              </Tooltip>
            </div>
          ) : (
            <div>Can't modify</div>
          );
        },
      },
      {
        name: "Name",
        selector: "name",
        minWidth: "230px",
        sortable: true,
      },
      {
        name: "Locations",
        selector: "locations",
        sortable: true,
        cell: (row) => renderValueRow(row, "locations"),
      },
      {
        name: "Departments",
        selector: "departments",
        sortable: true,
        cell: (row) => renderValueRow(row, "departments"),
      },
      {
        name: "Jobs",
        selector: "jobs",
        sortable: true,
        cell: (row) => renderValueRow(row, "jobs"),
      },
      {
        name: "Included Empl.",
        selector: "employees",
        sortable: true,
        cell: (row) => renderValueRow(row, "employees"),
      },
      {
        name: "Excluded Empl.",
        selector: "employeesExclude",
        sortable: true,
        cell: (row) => renderValueRow(row, "employees_exclude"),
      },
      {
        name: "User Empl.",
        selector: "keepMyEmployees",
        sortable: true,
        cell: (row) => (row.keep_my_employees ? "Yes" : "No"),
      },
      {
        name: "Empl. Hierarchy",
        selector: "keepMyHierarchy",
        sortable: true,
        cell: (row) => (row.keep_my_hierarchy ? "Yes" : "No"),
      },
      {
        name: "Users",
        selector: "users",
        sortable: true,
      },
    ];
    return (
      <div className="row">
        <Loader isLoading={isLevelsLoading && isAdmin} />
        <div className="card col-lg-4 mx-0 mx-lg-3 mb-4 col-12">
          <div className="card-header">
            <h4>{editMode ? "Update" : "Create new"} access level</h4>
          </div>
          <div className="card-body">
            <AccessLevelForm
              accessLevelObj={accessLevelObj}
              locationTree={tree}
              onFormSubmit={this.onFormSubmit}
              onCancel={this.resetForm}
              editMode={editMode}
              departments={departments}
              jobs={jobs}
              mapLocations={mapLocations}
              mapJobs={mapJobs}
              employees={employees}
              mapDepartments={mapDepartments}
            />
          </div>
        </div>
        <div className="card col-lg-7 col-12">
          <div className="card-header">
            <h4>
              Access levels{" "}
              {this.state.accessLevels && `(${this.state.totalRows})`}
            </h4>
          </div>
          <div className="card-body">
            <div className="e-table">
              <div className="mb-3">
                <div className="float-right">
                  <form onSubmit={this.submitSearch}>
                    <div className="input-group">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        style={{ width: "250px" }}
                        onChange={this.setSearch}
                        placeholder={"Search by name"}
                      />
                      <div className="input-group-btn">
                        <button className="btn btn-info" type="submit">
                          <i className="fas fa-search" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="table-responsive table-lg">
                <DataTable
                  columns={columns}
                  fixedHeader
                  noHeader={true}
                  data={accessLevels}
                  progressPending={this.state.isLevelsDataLoading}
                  pagination
                  paginationServer
                  highlightOnHover
                  striped
                  paginationTotalRows={this.state.totalRows}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  onSort={this.handleSort}
                  sortServer
                  customStyles={{
                    pagination: {
                      style: {
                        justifyContent: "flex-start",
                      },
                    },
                  }}
                  persistTableHead
                />
                {/* <table className="table table-bordered text-nowrap table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">Actions</th>
                      <th className="text-center">Name</th>
                      <th className="text-center">Locations</th>
                      <th className="text-center">Departments</th>
                      <th className="text-center">Jobs</th>
                      <th className="text-center">Included Empl.</th>
                      <th className="text-center">Excluded Empl.</th>
                      <th className="text-center">User Empl.</th>
                      <th className="text-center">Empl. Hierarchy</th>
                      <th className="text-center">Users</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!this.state.isLevelsLoading &&
                      this.state.accessLevels.map(level => (
                        <AccessLevelTableRow
                          key={level.id}
                          id={level.id}
                          onDelete={this.deleteAccessLevel}
                          onEdit={this.editAccessLevel}
                          name={level.name}
                          locations={level.locations}
                          departments={level.departments}
                          jobs={level.jobs}
                          users={level.users}
                          can_delete={level.can_delete}
                          employees_exclude={level.employees_exclude}
                          employees={level.employees}
                          keep_my_hierarchy={level.keep_my_hierarchy}
                          keep_my_employees={level.keep_my_employees}
                        />
                      ))}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { SCHEDULE_MAPPED_EVENTS, SCHEDULE_STATUSES, TABS } from "../../containers/Schedule/Constant";
import { canModifyShift } from "../Schedule/helpers/SchedulerHelper";

const getContextMenu = ({
    selectedEvents,
    eventData,
    readOnly,
    mySchedule,
    manager,
    addEvent,
    notifyForEvent,
    releaseEvent,
    onEventDelete,
    submitEvents,
    copyEvents,
    changeToRegular,
    publishEvents,
    makeAvailable,
    canPublish = false
}) => {
    const eventContextMenu = readOnly ? [] : [
        {
            renderIf: true,
            name: "Edit",
            id: "editEvent",
            icon: "fas fa-edit",
            onClick: () => {
                addEvent(true)
            }
        },
        {
            renderIf: true,
            name: `Delete`,
            id: 'deleteEvent',
            icon: 'fas fa-trash',
            onClick: () => {
                onEventDelete({
                    eventRecord: {
                        data: eventData
                    }
                }, selectedEvents);
            }
        }
    ];
    if (eventData && !readOnly) {
        if (mySchedule) {
            if (manager) {
                if (eventData.status === SCHEDULE_STATUSES.PENDING) {
                    eventContextMenu.push({
                        renderIf: true,
                        name: canPublish ? 'Publish' : 'Submit',
                        id: 'eventSubmit',
                        icon: 'b-fa b-fa-fw b-fa-paper-plane',
                        onClick: () => {
                            submitEvents(eventData.id)
                        }
                    })
                }
                if (eventData.status !== SCHEDULE_STATUSES.RELEASED) {
                    const copySelectedEvents = selectedEvents.filter(ev => canModifyShift(ev, TABS.MY_SHIFT, manager));
                    if (copySelectedEvents.length > 0) {
                        eventContextMenu.push({
                            renderIf: true,
                            id: 'eventCopy',
                            name: copySelectedEvents.length > 1 ? `Copy ${copySelectedEvents.length} events` : 'Copy',
                            icon: 'fas fa-copy',
                            onClick: () => {
                                copyEvents()
                            }
                        })
                    }
                }
                const selectedEventsWithoutEventsFromLeave = selectedEvents.filter(event => !event.leaveId);
                eventContextMenu.map(ec => {
                    if (ec.id === 'deleteEvent' && selectedEventsWithoutEventsFromLeave.length > 1 && !eventData.leaveId) {
                        ec.name = `Delete ${selectedEventsWithoutEventsFromLeave.length} events`;
                    } else if (ec.id === 'deleteEvent' && eventData.leaveId) {
                        ec.renderIf = false;
                    }
                    if (ec.id === 'editEvent' && (selectedEventsWithoutEventsFromLeave.length > 1 || eventData.leaveId)) {
                        ec.renderIf = false;
                    }
                    return ec;
                });
            } else {
                const events = selectedEvents.filter(ev => canModifyShift(ev, TABS.MY_SHIFT, manager));
                if (canModifyShift(eventData, TABS.MY_SHIFT, manager)) {
                    if (events.length > 0) {
                        eventContextMenu.push({
                            renderIf: true,
                            id: 'eventCopy',
                            name: events.length > 1 ? `Copy ${events.length} events` : 'Copy',
                            icon: 'fas fa-copy',
                            onClick: () => {
                                copyEvents()
                            }
                        })
                    }
                }
                eventContextMenu.map(ec => {
                    if (ec.id === 'deleteEvent' && (events.length === 0 || ![SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY, SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF].includes(eventData.name))) {
                        ec.renderIf = false;
                    }
                    if (ec.id === 'editEvent' && (events.length === 0 || events.length > 1 || ![SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY, SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF].includes(eventData.name))) {
                        ec.renderIf = false;
                    }
                    return ec;
                });
            }
            if (eventData.name === 'Regular') {
                if (eventData.status === SCHEDULE_STATUSES.APPROVED) {
                    eventContextMenu.push({
                        renderIf: true,
                        name: 'Notify Me',
                        id: 'notifyMe',
                        icon: 'b-fa b-fa-fw b-fa-paper-plane',
                        onClick() {
                            notifyForEvent({ data: eventData });
                        }
                    });
                    const events = selectedEvents.filter(ev => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR && ev.status === SCHEDULE_STATUSES.APPROVED);
                    if (events.length > 0) {
                        eventContextMenu.push({
                            renderIf: true,
                            name: events.length > 1 ? `Coverage request for ${events.length} events` : 'Coverage request',
                            id: 'releaseEvents',
                            icon: 'fas fa-unlock',
                            onClick: () => {
                                /*const {eventRecord} = event;
                                if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                                    self.schedulerEngine.selectEvent(eventRecord);
                                }
                                const {source: {selectedEvents}} = event;
                                self.props.releaseEvent(selectedEvents.filter(ev => ev.name === 'Regular' && ev.status === SCHEDULE_STATUSES.APPROVED));*/
                                releaseEvent(selectedEvents.filter(ev => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR && ev.status === SCHEDULE_STATUSES.APPROVED).map(ev => {
                                    return { data: ev }
                                }))
                            }
                        })
                    }
                }
            }
        } else if (manager && !mySchedule) {
            if (eventData.name === SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY && eventData.status !== SCHEDULE_STATUSES.TO_REGULAR) {
                eventContextMenu.push({
                    renderIf: true,
                    id: 'changeToAvailable',
                    name: 'Change to regular',
                    icon: 'fas fa-undo',
                    onClick: () => {
                        changeToRegular(eventData);
                    }
                })
                eventContextMenu.map(ev => {
                    if (ev.id !== 'changeToAvailable') {
                        ev.renderIf = false;
                    }
                    return ev;
                });
            } else {
                if (eventData.status === SCHEDULE_STATUSES.PENDING) {
                    eventContextMenu.push({
                        renderIf: true,
                        id: 'eventPublish',
                        name: 'Publish',
                        icon: 'b-fa b-fa-fw b-fa-upload',
                        onClick: () => {
                            publishEvents(eventData.id);
                        }
                    })
                }
                const events = selectedEvents.filter(ev => ev.name === SCHEDULE_MAPPED_EVENTS.REGULAR && [SCHEDULE_STATUSES.PENDING, SCHEDULE_STATUSES.APPROVED].includes(ev.status));
                if (events.length > 0) {
                    eventContextMenu.push({
                        renderIf: true,
                        name: events.length > 1 ? `Make Available ${events.length} events` : 'Make Available',
                        icon: 'fas fa-unlock',
                        id: 'makeAvailable',
                        onClick: async () => {
                            // const {eventRecord} = event;
                            // if (!self.schedulerEngine.isEventSelected(eventRecord)) {
                            //     self.schedulerEngine.selectEvent(eventRecord);
                            // }
                            // const {source: { selectedEvents}} = event;
                            makeAvailable(selectedEvents.filter(ev => ev.name === 'Regular' && [SCHEDULE_STATUSES.PENDING, SCHEDULE_STATUSES.APPROVED].includes(ev.status)).map(ev => {
                                return {
                                    ...ev,
                                    data: ev,
                                    originalData: ev
                                }
                            }))
                            // self.props.makeAvailable();
                        }
                    })
                }
                const copySelectedEvents = selectedEvents.filter(ev => ![SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY, SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF].includes(ev.name) && [SCHEDULE_STATUSES.PENDING, SCHEDULE_STATUSES.APPROVED].includes(ev.status));
                if (copySelectedEvents.length > 0 && ![SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY, SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF].includes(eventData.name)) {
                    eventContextMenu.push({
                        renderIf: true,
                        name: copySelectedEvents.length > 1 ? `Copy ${copySelectedEvents.length} events` : 'Copy',
                        icon: 'fas fa-copy',
                        id: 'copyEvents',
                        onClick: async () => {
                            copyEvents()
                        }
                    })
                }
                const deletedEvents = selectedEvents.filter(ev => ![SCHEDULE_MAPPED_EVENTS.UNAVAILABILITY, SCHEDULE_MAPPED_EVENTS.SCHEDULED_DAY_OFF].includes(ev.name) && ev.status !== SCHEDULE_STATUSES.RELEASED && !ev.leaveId);
                if (deletedEvents.length > 0) {
                    eventContextMenu.map(ev => {
                        if (ev.id === 'deleteEvent' && deletedEvents.length > 1 && !eventData.leaveId) {
                            ev.name = `Delete ${deletedEvents.length} events`;
                        } else if (ev.id === 'deleteEvent' && eventData.leaveId) {
                            ev.renderIf = false;
                        }
                        if (ev.id === 'editEvent' && deletedEvents.length > 1) {
                            ev.renderIf = false;
                        }
                        return ev;
                    });
                } else {
                    eventContextMenu.map(ev => {
                        if (ev.id === 'deleteEvent') {
                            ev.renderIf = false;
                        }
                        if (ev.id === 'editEvent') {
                            ev.renderIf = false;
                        }
                        return ev;
                    });
                }
            }
        }
    }
    return readOnly ? [] : eventContextMenu;
};
export default getContextMenu

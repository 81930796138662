import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ isLoading }) =>
  (
    <div className={isLoading ? 'loader' : 'hidden'}>
      <div id="spinner" />
    </div>
  );

Loader.defaultProps = {
  isLoading: false,
};
Loader.propTypes = {
  isLoading: PropTypes.bool,
};

export default Loader;

import React from "react";
import AuthService from "../../services/AuthService";
import { ResetPasswordForm } from "../../components/Forms/ForgotPasswordForm";
import Toast from "../../components/Blocks/Toast/Toast";
// Images
import WhiteLogo from "../../general/assets/images/arcon-white.png";
import StorageManager from "../../helpers/StorageManager";

class ResetPassword extends React.Component {
  state = {
    error: null,
    success: null
  };

  submitResetPassword = values => {
    if (values.password !== values.confirmPassword) {
      this.setState({
        error: "Password does not match!",
        success: false
      });
      return;
    }
    const confirmationToken = window.location.pathname
      .split("/")
      .slice(-1)
      .pop();
    Object.assign(values, { confirmationToken });
    return AuthService.resetPassword(values)
      .then(() => {
        this.setState(
          {
            success: true,
            openModal: true
          },
          () => {
            window.location = "/login";
          }
        );
      })
      .catch(this.showError);
  };

  showError = error => {
    console.log(error);
    this.setState({
      error:
        error.response && error.response.data
          ? error.response.data
          : "It's not possible processing your request at this moment!"
    });
  };

  toastDismiss = () => {
    this.setState({
      error: null,
      success: null
    });
  };

  componentWillMount() {
    let picture = StorageManager.get("login-picture-index");
    if (!picture) {
      picture = 1;
      StorageManager.set("login-picture-index", 2);
    } else {
      picture = parseInt(picture);
      StorageManager.set(
        "login-picture-index",
        picture === 3 ? 1 : picture + 1
      );
    }

    this.setState({ picture });
  }

  render() {
    const { error, success, picture } = this.state;

    const toastError = error && (
      <Toast
        title="Change password failed!"
        message={error}
        dismiss={this.toastDismiss}
      />
    );

    return (
      <div className={`login-section picture-${picture}`}>
        {toastError}

        <div>
          <img src={WhiteLogo} className="" alt="logo" />
          <ResetPasswordForm
            success={success}
            submitResetPassword={this.submitResetPassword}
          />
        </div>
      </div>
    );
  }
}

export default ResetPassword;

import { handleMultiSelect, onEventSave, publishEvents } from "../helpers/SchedulerHelper";
import { openModal } from "../Modals";
import { getColumns } from "../../../containers/Schedule/Columns";
import { renderLocation, renderResourceName, renderRole } from "../helpers/Renders";
import { handleResourceContextMenu } from "../helpers/Resource";
import { changeToRegular, handleSelectionChange, makeShiftsAvailable, onEventMove } from "../helpers/EventActions";
import { onEventDelete } from "../helpers/CUD_API";
import BryntumScheduler from "../../BryntumScheduler/BryntumScheduler";
import React from "react";

function renderScheduleView() {
  const {
    user,
    mode,
    events,
    resources,
    config,
    eventsVersion,
    availableLocations,
    availableJobs,
    mapJobs,
    mapLocations,
    resourcesVersion,
    scheduleConfigs
  } = this.state;
  const getProfile = resource => {
    this.setState({
      employeeCode: resource.record.data.code,
      showEmployeeProfileModal: true
    });
  };
  const copyEvents = async resource => {
    const { id } = resource.record.data;
    const events = this.state.mappedEvents[id] || [];
    this.call(handleMultiSelect, events);
    this.call(openModal, "copyModal");
  };
  const columns = getColumns(
    user,
    (d) => this.call(renderRole, d),
    (d) => this.call(renderResourceName, d),
    (d) => this.call(renderLocation, d),
    getProfile,
    copyEvents,
    false
  );
  return (
    <BryntumScheduler
      comId={"managerScheduler"}
      ref={"scheduler"}
      allowOverlap={false}
      jobs={availableJobs}
      mapJobs={mapJobs}
      mapLocations={mapLocations}
      locations={availableLocations}
      autoHeight={true}
      eventsVersion={eventsVersion}
      resourcesVersion={resourcesVersion}
      events={events.map(e => {
        // e.cls = 'no-pointer';
        return e;
      })}
      resources={resources.map(r => {
        if (r.terminatedStatus) {
          r.cls = "no-pointer"
        }
        return r;
      })}
      startDate={config.startDate}
      endDate={config.endDate}
      viewPreset={config.viewPreset}
      scheduleMode={mode}
      isManager={user.role === 'manager'}
      submitOrPublish={"publish"}
      publishEvents={(e) => this.call(publishEvents, e)}
      handleResourceContextMenu={(ctx) => this.call(handleResourceContextMenu, ctx)}
      columns={columns}
      weekConf={user.weekConf || 'week'}
      readOnly={user.role !== 'manager'}
      loggedInUser={user}
      useInitialAnimation="slide-from-left"
      onEventSelectionChange={(s) => this.call(handleSelectionChange, s)}
      onSelectEventsChange={(se) => this.call(handleMultiSelect, se)}
      copyEvents={() => this.call(openModal, 'copyModal')}
      onEventSave={(e, isA) => this.call(onEventSave, e, isA)}
      onEventDelete={(e, s, isM) => this.call(onEventDelete, e, s, isM)}
      onEventMove={(e) => this.call(onEventMove, e)}
      notifyForEvent={() => {
      }}
      makeAvailable={(e) => this.call(makeShiftsAvailable, e)}
      changeToRegular={(e) => this.call(changeToRegular, e)}
      scheduleConfigs={scheduleConfigs}
    />
  )
}

export {
  renderScheduleView
}

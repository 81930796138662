import CrudPage from "../../components/CrudPage";

import CrudService from "../../services/CrudService";

const {employees} = require("./sampleData");
const employeesService = new CrudService("employee");
const companiesService = new CrudService("companies");

export default class Employees extends CrudPage {
  componentDidMount() {
    companiesService.list().then(({ data }) => {
      this.companyOptions = data.data.map(({ id, name }) => ({
        value: id,
        name: name
      }));
    });
  }

  name = "Employee";
  enableImport = true;
  importSampleLink =
    "https://dxv8p7y79jw20.cloudfront.net/Employees+Sample.csv";

  get fields() {
    const { locations = [], departments = [], jobs = [] } = this.props;
    const locationsOptions = locations.map(({ label, value }) => ({
      name: label,
      value
    }));
    const salaryOptions = [
      { name: "Hourly", value: "H" },
      { name: "Not Hourly", value: "S" }
    ];
    const companyOptions = this.companyOptions || [];
    const payPeriodOptions = [
      { name: "", value: "B" },
      { name: "", value: "S" },
      { name: "", value: "W" }
    ];

    return [
      { name: "id", label: "ID", type: "text", showOnForm: false },
      { name: "externalId", label: "External Id", type: "text", showOnTable: false },
      { name: "supervisorExternalId", label: "Supervisor Id", type: "text", showOnTable: false },

      { name: "code", label: "Code", type: "text" },
      {
        name: "companyId",
        label: "Company",
        type: "select",
        options: companyOptions
      },
      {
        name: "locationId",
        label: "Location",
        type: "select",
        options: locationsOptions
      },
      {
        name: "departmentId",
        label: "Department",
        type: "select",
        options: departments
      },
      { name: "jobId", label: "Job", type: "select", options: jobs },
      {
        name: "secondaryJobId",
        label: "Secondary Job",
        type: "select",
        options: [{ value: 0, name: "No Secondary Job" }, ...jobs]
      },

      { name: "projectCode", label: "Project Code", type: "text" },
      {
        name: "salaryHourly",
        label: "Salary",
        type: "select",
        options: salaryOptions
      },
      { name: "hourlyRate", label: "Hourly Rate", type: "text" },
      { name: "payGroupCode", label: "Pay Group Code", type: "text" },
      {
        name: "payGroupDescription",
        label: "Pay Group Description",
        type: "text",
        showOnTable: false
      },
      {
        name: "payPeriod",
        label: "Pay Period",
        type: "select",
        options: payPeriodOptions,
        defaultVal: "B",
        showOnForm: false
      },
      {
        name: "lastHireDate",
        label: "Last Hire",
        type: "date",
        format: "MMM dd, yyyy",
        placeholder: "Select Date",
        momentFormat: "MMM DD, YYYY"
      },
      {
        name: "dateOfTermination",
        label: "Date Of Termination",
        type: "date",
        format: "MMM dd, yyyy",
        placeholder: "Select Date",
        momentFormat: "MMM DD, YYYY"
      },

      { name: "workEmailAddress", label: "Work E-mail", type: "text" },
      { name: "personaleEmailAddress", label: "Personal E-mail", type: "text" },
      { name: "workPhoneNumber", label: "Work Phone", type: "text" },
      { name: "homePhone", label: "Home Phone", type: "text" },

      {
        name: "orgLevel1Code",
        label: "Org Level 1 Code",
        type: "text",
        showOnTable: false
      },
      {
        name: "orgLevel2Code",
        label: "Org Level 2 Code",
        type: "text",
        showOnTable: false
      },
      {
        name: "orgLevel3Code",
        label: "Org Level 3 Code",
        type: "text",
        showOnTable: false
      },
      {
        name: "orgLevel4Code",
        label: "Org Level 4 Code",
        type: "text",
        showOnTable: false
      },

      {
        name: "jobChangeReasonCode",
        label: "Job Change Code",
        type: "text",
        showOnTable: false
      }
    ];
  }

  sampleData = employees;

  fetch(page) {
    return employeesService.list(page);
  }

  create(data) {
    return employeesService.create(data);
  }

  update(data) {
    return employeesService.update(data);
  }

  delete(id) {
    return employeesService.remove(id);
  }

  import(file) {
    return employeesService.importSample(file);
  }

  _makeOptions(data) {
    // eslint-disable-next-line
    data.reduce((opts, { value, label, name }) => {
      opts[value] = label || name;
    }, {});
  }
}

import React from "react";

// Services
import UserService from "../../services/UserService";

// Components
import Toast from "../../components/Blocks/Toast/Toast";
import ProfileChangePasswordForm from "../../components/Forms/ProfileChangePasswordForm/ProfileChangePasswordForm";
import EmployeeService from "../../services/EmployeeService";
import ProfileDetails from "../../components/ProfileDetails";
import StringHelper from "../../helpers/StringHelper";
import ProfilePicture from "../../components/ProfileDetails/ProfilePicture";

const AWS_CDN = "https://dxv8p7y79jw20.cloudfront.net/";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      error: null,
      success: null,
      user,
      firstName: "",
      lastName: "",
      jobTitle: "",
      code: "",
      companyName: "",
      workEmailAddress: "",
      personalEmailAddress: "",
      workPhoneNumber: "",
      homePhone: "",
      departmentDescription: "",
      empId: -1,
      isLoading: true,
      supervisorLastName: "",
      supervisorFirstName: "",
      payGroupDescription: "",
      locationDescription: "",
      lastHireDate: "",
      status: 0,
      levels: [],
      profilePic: null,
    };
  }
  fetchPeopleDetails(id) {
    EmployeeService.fetchOneEmployee(id)
      .then(res => {
        if (res.data) {
          this.setState({
            ...res.data,
            isLoading: false,
          });
        } else {
          alert("404, user not found!");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  submitProfileChangePassword = values => {
    return UserService.changePassword(values)
      .then(response => {
        this.setState({
          success: response.data.message,
        });
      })
      .catch(this.showError);
  };

  showError = error => {
    this.setState({
      error: error.response && error.response.data ? error.response.data : 'An Error Occurred',
    });
  };

  toastDismiss = () => {
    this.setState({
      error: null,
      success: null,
    });
  };

  componentDidMount() {
    this.fetchPeopleDetails('self');
  }

  render() {
    const { error, success } = this.state;

    const toastError = error && <Toast title="Change password failed!" message={error} dismiss={this.toastDismiss} />;

    const toastSuccess = success && (
      <Toast
        status="success"
        title="Successfully updated!"
        message={
          <React.Fragment>
            <p className="m-0">Your password has been updated successfully.</p>
          </React.Fragment>
        }
        dismiss={this.toastDismiss}
      />
    );

    const {
      firstName,
      lastName,
      jobTitle,
      code,
      companyName,
      workEmailAddress,
      personalEmailAddress,
      workPhoneNumber,
      homePhone,
      departmentDescription,
      supervisorLastName,
      supervisorFirstName,
      payGroupDescription,
      locationDescription,
      lastHireDate,
      status,
      isLoading,
      profilePic
    } = this.state;
    if (isLoading) {
      return (
        <div className="container content-area">
          <section className="section">
            <div className="row mt-5">
              <div className="col">
                <p>
                  <b>Loading...</b>
                </p>
              </div>
            </div>
          </section>
        </div>
      );
    }

    const profileImg = profilePic ? `${AWS_CDN}${profilePic}` : null;
    return (
      <div className="container content-area">
        {toastError}
        {toastSuccess}
        <section className="section">
          <div className="row pt-5">
            <div className="col-xl-12">
              <div className="card m-b-20">
                <ProfilePicture
                  firstName={firstName}
                  lastName={lastName}
                  jobTitle={jobTitle}
                  profilePic={profileImg}
                />
              </div>
            </div>

            <div className="col-lg-12 col-xl-5 col-md-12 col-sm-12">
              <ProfileDetails
                cardHeader="Personal Details"
                jobTitle={jobTitle}
                code={code}
                companyName={companyName}
                workEmailAddress={workEmailAddress}
                personalEmailAddress={personalEmailAddress}
                workPhoneNumber={workPhoneNumber}
                homePhone={homePhone}
                departmentDescription={departmentDescription}
                supervisorLastName={supervisorLastName}
                supervisorFirstName={supervisorFirstName}
                payGroupDescription={payGroupDescription}
                locationDescription={locationDescription}
                lastHireDate={lastHireDate}
                status={StringHelper.statusFormat(status)}
              />
            </div>

            <div className="col-lg-12 col-xl-7 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h4>Change Password</h4>
                </div>
                <div className="card-body">
                  <ProfileChangePasswordForm submitProfileChangePassword={this.submitProfileChangePassword} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Profile.propTypes = {};

export default Profile;

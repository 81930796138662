import React, { useState } from "react";
import ReactPlayer from "react-player";
import Avatar from "../../components/Blocks/Avatar";
import moment from "moment";
import ImageViewer from "react-images-viewer";

const aws_cdn = "https://dxv8p7y79jw20.cloudfront.net/";

const Attachment = ({ a }) => {
  const [imgs, setImgs] = useState([]);
  const [imageViewerOpen, setImageViewerOpen] = useState(false);

  let attachment = "";
  if (a.type === "image") {
    attachment = (
      <React.Fragment>
        <img
          onClick={() => {
            setImgs([{ src: `${aws_cdn}${a.path}` }]);
            setImageViewerOpen(true);
          }}
          src={`${aws_cdn}${a.path}`}
          className="w-100 h-100"
          alt={`attachment`}
        />
        <ImageViewer
          closeBtnTitle="Close"
          imgs={imgs}
          isOpen={imageViewerOpen}
          onClose={() => setImageViewerOpen(false)}
        />
      </React.Fragment>
    );
  } else if (a.type === "video") {
    attachment = (
      <ReactPlayer controls width={"100%"} url={`${aws_cdn}${a.path}`} />
    );
  } else if (a.type === "video/link") {
    attachment = <ReactPlayer controls width={"100%"} url={a.path} />;
  } else if (a.type === "file/pdf") {
    attachment = (
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${aws_cdn}${a.path}`}
        >
          <span className="fileIcon color-red mr-2">
            <i className="fa b-fa-file-pdf" />
          </span>
          <span className="fileName"> {a.name}</span>
        </a>
      </div>
    );
  } else if (a.type === "file/word") {
    attachment = (
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${aws_cdn}${a.path}`}
        >
          <span className="fileIcon color-blue mr-2">
            <i className="fa b-fa-file-word" />
          </span>
          <span className="fileName"> {a.name}</span>
        </a>
      </div>
    );
  } else if (a.type === "file/excel") {
    attachment = (
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${aws_cdn}${a.path}`}
        >
          <span className="fileIcon color-green mr-2">
            <i className="fa b-fa-file-excel" />
          </span>
          <span className="fileName"> {a.name}</span>
        </a>
      </div>
    );
  }
  return attachment;
};

const extractLinks = text => {
  return (text || "").replace(
    /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
    (match, space, url) => {
      var hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        space + '<a target="_blank" href="' + hyperlink + '">' + url + "</a>"
      );
    }
  );
};

function TextMessage({ text, user, attachment }) {
  return text.userId === user.id ? (
    <div className="direct-chat-msg right">
      <div className="direct-chat-info clearfix">
        <span className="direct-chat-name float-right">Me</span>
        <span className="direct-chat-timestamp float-left">
          {moment(text.createdAt).fromNow()}
        </span>
      </div>
      <Avatar extraClass="direct-chat-img" size={40} item={text} />
      <div className="direct-chat-text">
        <p
          className="word-break"
          dangerouslySetInnerHTML={{
            __html: extractLinks(text.body)
          }}
        />
        {attachment && attachment.length
          ? attachment.map((a, key) => <Attachment a={a} key={key} />)
          : null}
      </div>
    </div>
  ) : (
    <div className="direct-chat-msg">
      <div className="direct-chat-info clearfix">
        <span className="direct-chat-name float-left">
          {text.firstName} {text.lastName}
        </span>
        <span className="direct-chat-timestamp float-right">
          {moment(text.createdAt).fromNow()}
        </span>
      </div>
      <Avatar size={40} extraClass="direct-chat-img" item={text} />
      <div className="direct-chat-text">
        <p>{text.body}</p>
        {attachment && attachment.length ? (
          <>
            {attachment.map((a, key) => (
              <Attachment a={a} key={key} />
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default TextMessage;

import {startDateAndEndDateAreValid} from "../../BryntumScheduler/Configs";
import ReactPaginate from "react-paginate";
import React from "react";
import {TABS} from "../../../containers/Schedule/Constant";
import {fetchMyDepartmentSchedules, fetchSchedules} from "./R_API";
import Tooltip from "../../Blocks/Tooltip";
import moment from "moment";
function setPage(page){
    if (page.selected + 1 !== this.state.page) {
        this.setState(
            {
                isLoading: true,
                page: page.selected + 1
            },
            () => {
                if(this.state.activeTab === TABS.MY_EMPLOYEES){
                    this.call(fetchSchedules)
                } else {
                    this.call(fetchMyDepartmentSchedules)
                }
            }
        );
    }
};
function renderPagination() {
    const { total_pages, page, config } = this.state;
    return (
        <>
            {total_pages > 1 &&
            config.startDate &&
            config.endDate &&
            startDateAndEndDateAreValid(
                config.startDate,
                config.endDate
            ) ? (
                <div className="d-flex justify-content-center mb-3">
                    <ReactPaginate
                        previousLabel={"‹"}
                        nextLabel={"›"}
                        breakLabel={"..."}
                        breakLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        pageCount={total_pages}
                        initialPage={page - 1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={(p)=>this.call(setPage, p)}
                        containerClassName={"pagination mt-3 mb-0"}
                        pageLinkClassName={"page-link"}
                        pageClassName={"page-item"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        nextClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                    />
                    <br />
                </div>
            ) : null}
        </>
    );
}
function renderResourceName(data){
    const resource = data.record.data;
    let hrs = {
        total: {
            hrs: 0,
            min: 0
        },
        ovt: {
            hrs: 0,
            min: 0
        },
        regularCost: 0,
        otCost: 0
    };
    if (resource.hrs) {
        hrs = resource.hrs;
    }
    let pay = "";
    if (
        data.record.salaryHourly === "H" &&
        this.state.user &&
        this.state.user.role === "manager"
    ) {
        const total = (hrs.regularCost + hrs.otCost).toFixed(2);
        pay = ` / $${total}`;
    }
    const ovt = `${hrs.ovt.hrs > 0 ? hrs.ovt.hrs + "h" : ""}${
        hrs.ovt.min > 0 ? " " + hrs.ovt.min + "min" : ""
        }`;
    const imageUrl = data.record.avatar ? `https://dxv8p7y79jw20.cloudfront.net/${data.record.avatar}` : null;
    let avatar = '';
    if(!data.record.avatar) {
      avatar = data.record.firstName && data.record.lastName ?  `
        <div class="resource-avatar-img user-avatar" draggable="false">
          <div>${data.record.firstName ? data.record.firstName[0] : ''} ${data.record.lastName ? data.record.lastName[0]: ''}</div>
        </div>
      ` : '';
    }
    const count = data.record.events.length;
    return `
        <div class="b-resource-info">
            ${imageUrl ? `<img alt="${data.record.name}" draggable="false" src="${imageUrl}"/>` : avatar}
          <dl>
              <dt>${data.record.name ? data.record.name : `${count} event${count !== 1 ? 's' : ''}`}</dt>
              ${ data.record.name ? `
                <dd class='b-resource-role'>
                    ${hrs.total.hrs > 0 ? hrs.total.hrs + "h" : "0h"}${
                      hrs.total.min > 0 ? " " + hrs.total.min + "min" : ""
                      }
                                            ${
                      hrs.ovt.hrs > 0 || hrs.ovt.min > 0
                        ? ",<span class='overtime-event'>" + ovt + " OT</span>"
                        : ""
                      }
                    ${pay !== "" ? pay : ""}
                    ${resource.terminatedStatus ? `<span class="badge badge-danger">Terminated on ${moment(resource.terminatedDate).format('YYYY-MM-DD')}</span>` : ''}
                </dd>
              ` : ''}
              
          </dl>
        </div>
    `;
}
function renderAvailableShifts(data){
    const events = data.record.events;
    return `
        <dl>
            <dt>${events.length}</dt>
        </dl>
    `;
}
function renderRole(data){
    return `
      <dl>
          <dd>
              ${data.record.role}
          </dd>
      </dl>
    `;
}
function renderLocation(data){
    return `
      <dl>
          <dd>
              ${data.record.location}
          </dd>
      </dl>
    `;
}

export {
    renderPagination,
    renderResourceName,
    renderAvailableShifts,
    renderLocation,
    renderRole,
    setPage
}

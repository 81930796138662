import { Link } from "react-router-dom";
import StringHelper from "../../helpers/StringHelper";
import ProfileDetails from "../../components/ProfileDetails";
import React from "react";

const ShareEmployee = ({
  notification,
  approveShare,
  rejectShare,
  employeeProfile,
  startDate,
  endDate,
  shareStatus
}) => {
  const {
    firstName,
    lastName,
    jobTitle,
    code,
    companyName,
    workEmailAddress,
    personalEmailAddress,
    workPhoneNumber,
    homePhone,
    departmentDescription,
    supervisorLastName,
    supervisorFirstName,
    payGroupDescription,
    locationDescription,
    lastHireDate,
    status,
    salaryHourly,
    hourlyRate
  } = employeeProfile;
  return (
    <React.Fragment>
      <div className="card-header">
        <h4>Share Employee Details</h4>
        {notification.isResponded === 0 && (
          <div className="pull-right">
            <button
              className="btn btn-success btn-sm mr-2 notification-button"
              onClick={() => approveShare()}
            >
              Approve
            </button>
            <button
              className="btn btn-danger btn-sm mr-2 notification-button"
              onClick={() => rejectShare()}
            >
              Reject
            </button>
            <Link
              to={`/app/people/${notification.employeeCode}`}
              className="btn btn-info btn-sm notification-button"
            >
              Compose
            </Link>
          </div>
        )}
      </div>
      <div className="card-body">
        <div className="col-6 pull-left">
          <div className="card">
            <div className="card-header">
              <h4>Share Data</h4>
            </div>
            <div className="card-body">
              <p>
                <b>Employee Name: </b>{" "}
                {`${firstName} ${lastName}` || "N/A"}
              </p>
              <p>
                <b>Start Date: </b> {startDate || "N/A"}
              </p>
              <p>
                <b>End Date: </b> {endDate || "N/A"}
              </p>
              <p>
                <b>Status: </b> {StringHelper.formatScheduleStatus(shareStatus, 'share',  notification.data) || "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="col-6 pull-left">
          <ProfileDetails
            cardHeader="Employee Profile Details"
            jobTitle={jobTitle}
            code={code}
            companyName={companyName}
            workEmailAddress={workEmailAddress}
            personalEmailAddress={personalEmailAddress}
            workPhoneNumber={workPhoneNumber}
            homePhone={homePhone}
            departmentDescription={departmentDescription}
            supervisorLastName={supervisorLastName}
            supervisorFirstName={supervisorFirstName}
            payGroupDescription={payGroupDescription}
            locationDescription={locationDescription}
            lastHireDate={lastHireDate}
            status={StringHelper.statusFormat(status)}
            salaryHourly={salaryHourly}
            hourlyRate={hourlyRate}
          />
        </div>
      </div>
    </React.Fragment>
  )
};

export default ShareEmployee;

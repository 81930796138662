import BaseService from "./BaseService";

class UserFilterService extends BaseService {
  create(data) {
    return this.apiPost({
      url: "/user-filters",
      data
    });
  }
  update(id, data) {
    return this.apiPut({
      url: `/user-filters/${id}`,
      data
    });
  }
  delete(id) {
    return this.apiDelete({
      url: `/user-filters/${id}`
    });
  }
}

export default new UserFilterService();

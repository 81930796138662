import moment from "moment";
import React from "react";

const Leave = ({
  notification
}) => {
  let data = {};
  try {
    data = notification.data ? JSON.parse(notification.data) : {};
  }catch (e) {
    console.log(e);
  }
  return (
    <React.Fragment>
      <div className="card-header">
        <h4>Leave Details</h4>
      </div>
      <div className="card-body">
        <p>
          <b>Employee Name: </b>{" "}
          {`${notification.firstName} ${notification.lastName}` || "N/A"}
        </p>
        <p>
          <b>Start Date: </b> {moment(data.startDate).format("LL") || "N/A"}
        </p>
        <p>
          <b>End Date: </b> {moment(data.endDate).format("LL") || "N/A"}
        </p>
        <p>
          <b>Notes: </b> {data.note || "N/A"}
        </p>
      </div>
    </React.Fragment>
  )
};
export default Leave;

import React from "react";
import Toast from "../../components/Blocks/Toast";
import { Link } from "react-router-dom";
import NotificationService from "../../services/NotificationService";
import moment from "moment";
import StringHelper from "../../helpers/StringHelper";
import "./assets/styles/NotificationPreview.css";
import scheduleService from "../../services/ScheduleService";
import employeeService from "../../services/EmployeeService";
import Duration from "../../helpers/Duration";
import ShareEmployee from "./ShareEmployee";
import Schedule from "./Schedule";
import Leave from "./Leave";

class NotificationPreview extends React.Component {
    state = {
        error: null,
        success: null,
        toastTitle: null,
        notification: {},
        schedules: [],
        isLoading: true,
        user: {},
        totalHrs: 0,
        totalMin: 0
    };

    getNotificationDetails() {
        const { id = -1 } = this.props.match.params;
        NotificationService.fetchOneNotification(id).then(response => {
            if (response.data) {
                const { data } = response;
                let totalHrs = 0;
                let totalMin = 0;
                const notification = data.notification;
                let schedules;
                if (
                    ["delete-schedule", "update-schedule"].includes(notification.type)
                ) {
                    schedules = JSON.parse(data.schedules);
                } else {
                    schedules = data.schedules.map(s => {
                        const hours = Duration.durationAsHoursAndMinsObject(
                            s.endDate,
                            s.startDate
                        );
                        totalHrs += hours.hours;
                        totalMin += hours.minutes;
                        s.hrs = Duration.durationAsHoursAndMinsString(
                            s.endDate,
                            s.startDate
                        );
                        return s;
                    });
                }
                if (totalMin > 59) {
                    totalHrs += Math.floor(totalMin / 60);
                    totalMin += totalMin % 60;
                }

                this.setState({
                    notification,
                    schedules,
                    totalHrs,
                    totalMin,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false,
                    toastTitle: 'Notification not found',
                    error: 'This notification might be deleted or you cant access it'
                })
            }
        }).catch(e => {
            this.setState({
                isLoading: false,
                toastTitle: 'Notification not found',
                error: 'This notification might be deleted or you cant access it'
            })
        });
    }

    componentDidMount() {
        this.getNotificationDetails();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState(
                {
                    notification: {},
                    schedules: [],
                    isLoading: true
                },
                this.getNotificationDetails
            );
        }
    }

    approveSchedule = () => {
        let schedules = this.state.schedules;
        const scheduleId = schedules.map(item => item.id);
        let notification = this.state.notification;
        notification.isResponded = 1;
        schedules = schedules.map(s => {
            s.status = "approved";
            return s;
        });
        this.setState({ isLoading: true });
        scheduleService.approveSchedule({
            scheduleId,
            notificationId: [notification.id]
        }).then(() => {
            this.setState({
                notification,
                schedules,
                isLoading: false,
            });
        }).catch((err) => {
            console.log(err);
            this.setState({ isLoading: false });
        });
    };

    rejectSchedule = () => {
        let schedules = this.state.schedules;
        const scheduleId = schedules.map(item => item.id);
        let notification = this.state.notification;
        notification.isResponded = 1;
        schedules = schedules.map(s => {
            s.status = "rejected";
            return s;
        });
        this.setState({isLoading: true});

        scheduleService.rejectSchedule({
            scheduleId,
            notificationId: [notification.id]
        }).then(() => {
            this.setState({
                notification,
                schedules,
                isLoading: false,
            });
        }).catch((err) => {
            console.log(err);
            this.setState({ isLoading: false });
        });
    };
    approveShare = () => {
        const notification = this.state.notification;
        const { type, data, id } = notification;
        const share = JSON.parse(data);
        this.setState({ isLoading: true });
        employeeService
            .approveShare({ type, shareId: share.id, id })
            .then(res => {
                notification.isRead = 1;
                notification.isResponded = 1;
                this.setState({
                    notification,
                    success: res.data.message,
                    toastTitle: "Approve share success",
                    isLoading: false
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    error: err.message,
                    errorTitle: "Approve share error",
                    isLoading: false
                });
            });
    };

    rejectShare = () => {
        const notification = this.state.notification;
        const { type, data, id } = notification;
        const share = JSON.parse(data);
        this.setState({ isLoading: true });
        employeeService
            .rejectShare({ type, shareId: share.id, id })
            .then(res => {
                notification.isRead = 1;
                notification.isResponded = 1;
                this.setState({
                    notification,
                    success: res.data.message,
                    toastTitle: "Reject share success",
                    isLoading: false
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    error: err.message,
                    toastTitle: "Reject share error",
                    isLoading: false
                });
            });
    };

    approveUnavailabilitySchedule = () => {
        this.setState({
            isLoading: true
        });
        let schedules = this.state.schedules;
        const scheduleId = schedules.map(item => item.id);
        let notification = this.state.notification;
        notification.isResponded = 1;
        scheduleService.approveUnavailabilitySchedule({
            scheduleId: scheduleId[0],
            notificationId: notification.id
        }).then(() => {
            schedules = schedules.map(s => {
                s.status = "approved";
                s.name = "Regular";
                return s;
            });
            this.setState({
                notification,
                schedules,
                isLoading: false
            });
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
        });
    };

    rejectUnavailabilitySchedule = () => {
        let schedules = this.state.schedules;
        const scheduleId = schedules.map(item => item.id);
        let notification = this.state.notification;
        this.setState({
            isLoading: true
        });
        scheduleService.rejectUnavailabilitySchedule({
            scheduleId: scheduleId[0],
            notificationId: notification.id
        }).then(() => {
            notification.isResponded = 1;
            schedules = schedules.map(s => {
                s.status = "rejected";
                return s;
            });
            this.setState({
                notification,
                schedules,
                isLoading: false
            });
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
        });
    };
    render() {
        const {
            isLoading,
            notification,
            schedules,
            error,
            success,
            toastTitle
        } = this.state;
        let data = {};
        let employeeProfile = {};
        let startDate = null;
        let endDate = null;
        let shareStatus = null;
        if (notification.object === "share") {
            data = notification.data ? JSON.parse(notification.data) : {};
            employeeProfile = data.employeeProfile ? data.employeeProfile : {};
            startDate = data.startDate;
            shareStatus = data.status;
            endDate = data.endDate;
        }

        const errorToast = error && (
            <Toast
                title={toastTitle}
                message={error}
                dismiss={() => this.setState({ toastTitle: null, error: null })}
            />
        );
        const successToast = success && (
            <Toast
                status="success"
                title={toastTitle}
                message={success}
                dismiss={() => this.setState({ toastTitle: null, success: null })}
            />
        );
        if (isLoading) {
            return (
                <div className={isLoading ? "loader" : "hidden"}>
                    <div id="spinner" />
                </div>
            );
        }

        return (
            <div className="container content-area">
                {errorToast}
                {successToast}
                {!error && (
                    <section className="section">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/app/notifications/all" className="text-muted">
                                    Notifications
                                </Link>
                            </li>
                            {notification.body ? (
                                <li
                                    className="breadcrumb-item active text-"
                                    aria-current="page"
                                >
                                    {notification.body}
                                </li>
                            ) : null}
                        </ol>

                        <div className="section-body">
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="card">
                                        {notification.object === "schedule" ? (
                                            <Schedule
                                                notification={notification}
                                                approveSchedule={this.approveSchedule}
                                                approveUnavailabilitySchedule={this.approveUnavailabilitySchedule}
                                                rejectSchedule={this.rejectSchedule}
                                                rejectUnavailabilitySchedule={this.rejectUnavailabilitySchedule}
                                                renderUpdateSchedule={this.renderUpdateSchedule}
                                                renderDeletedSchedule={this.renderDeletedSchedule}
                                                totalHrs={this.state.totalHrs}
                                                totalMin={this.state.totalMin}
                                                schedules={schedules}
                                            />
                                        ) : notification.object === "share" ? (
                                            <ShareEmployee
                                                notification={notification}
                                                approveShare={this.approveShare}
                                                rejectShare={this.rejectShare}
                                                employeeProfile={employeeProfile}
                                                startDate={startDate}
                                                endDate={endDate}
                                                shareStatus={shareStatus}
                                            />
                                        ) : notification.object ? (
                                            <Leave
                                                notification={notification}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        );
    }

    renderUpdateSchedule = () => {
        const { schedules } = this.state;
        return (
            <table className="table card-table table-vcenter text-nowrap mb-0">
                <thead>
                    <tr className="bg-light">
                        <td>Props</td>
                        <td>Old Schedule</td>
                        <td>New Schedule</td>
                    </tr>
                </thead>
                <tbody>
                    <tr className="border-bottom">
                        <td>Event Type</td>
                        <td>{schedules.old.name}</td>
                        <td>{schedules.new.name}</td>
                    </tr>
                    <tr className="border-bottom">
                        <td>Start Date</td>
                        <td>{moment(schedules.old.startDate).format("LLL")}</td>
                        <td>{moment(schedules.new.startDate).format("LLL")}</td>
                    </tr>
                    <tr className="border-bottom">
                        <td>End Date</td>
                        <td>{moment(schedules.old.endDate).format("LLL")}</td>
                        <td>{moment(schedules.new.endDate).format("LLL")}</td>
                    </tr>
                    <tr className="border-bottom">
                        <td>Duration</td>
                        <td>
                            <span className="badge badge-info">
                                <i className="fas fa-clock mr-1" />
                                {Duration.durationAsHoursAndMinsString(
                                    schedules.old.endDate,
                                    schedules.old.startDate
                                )}
                            </span>
                        </td>
                        <td>
                            <span className="badge badge-info">
                                <i className="fas fa-clock mr-1" />
                                {Duration.durationAsHoursAndMinsString(
                                    schedules.new.endDate,
                                    schedules.new.startDate
                                )}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };
    renderDeletedSchedule = () => {
        const { schedules } = this.state;
        return (
            <table className="table card-table table-vcenter text-nowrap mb-0">
                <thead>
                    <tr className="bg-light">
                        <th>Event Type</th>
                        <th>Notes</th>
                        <th>Status</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Hrs</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="border-bottom">
                        <td>{schedules.name}</td>
                        <td>{schedules.note ? schedules.note : ""}</td>
                        <td>
                            {" "}
                            {StringHelper.formatScheduleStatus("delete-schedule", "schedule")}
                        </td>
                        <td>{moment(schedules.startDate).format("LLL")}</td>
                        <td>{moment(schedules.endDate).format("LLL")}</td>
                        <td>
                            <span className="badge badge-info">
                                <i className="fas fa-clock mr-1" />
                                {Duration.durationAsHoursAndMinsString(
                                    schedules.endDate,
                                    schedules.startDate
                                )}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };
}

NotificationPreview.propTypes = {};

export default NotificationPreview;

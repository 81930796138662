import React, { useState } from "react";
import Picker from "emoji-picker-react";

function EmojiPicker({ onSelectEmoji }) {
  const [open, setOpen] = useState(false);

  const onChange = (e, a) => {
    e.preventDefault();
    setOpen(false);
    onSelectEmoji(a);
  };

  return (
    <div className="emoji-picker">
      {open && (
        <div className="picker-container">
          <Picker onEmojiClick={onChange} />
        </div>
      )}
      <div className="emoji-trigger" onClick={() => setOpen(o => !o)}>
        <i className="far fa-smile" />
      </div>
    </div>
  );
}

export default EmojiPicker;

import React, { Component } from "react";
import moment from "moment";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import "./assets/Styles/style.css";
import DatePicker from "react-datepicker";
import WeekPicker from "./WeekPicker";
import { getWeekConfig, getWeekStartDay } from "../../components/Schedule/helpers/DateHelper";

const SCHEDULE_MODES = {
  TODAY: "today",
  THIS_WEEK: "this_week",
  THIS_MONTH: "this_month",
  DATE_RANGE: "date_range"
};

const START_DATE = "startDate";
const END_DATE = "endDate";

class DateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      singlePickerFocused: false,
      weekPickerFocused: false,
      monthPickerFocused: false,
      localStartDate: null,
      localEndDate: null
    };
  }

  onModeChange = event => {
    const { onModeChange } = this.props;
    const newMode = event.target.value;

    this.setState({
      focusedInput: newMode === SCHEDULE_MODES.DATE_RANGE ? START_DATE : null,
      localStartDate: null,
      localEndDate: null
    });

    onModeChange(newMode);
  };

  onDatesChange = ({ startDate, endDate }) => {
    const { focusedInput, localStartDate, localEndDate } = this.state;
    if (moment(startDate).isSame(localStartDate, 'D') && moment(endDate).isSame(localEndDate, 'D')) {
      return;
    }
    this.setState(
      {
        localStartDate: startDate,
        localEndDate: endDate,
        focusedInput: startDate && endDate ? null : focusedInput
      },
      () => {
        if (startDate && endDate && startDate.isBefore(endDate)) {
          this.props.onDatesChange({
            startDate: startDate.startOf("day"),
            endDate: endDate.endOf("day")
          });
        }
      }
    );
  };

  onDateChange = date => {
    this.setState(
      {
        weekPickerFocused: false,
        singlePickerFocused: false,
        monthPickerFocused: false
      },
      () => {
        const { mode } = this.props;
        mode === SCHEDULE_MODES.THIS_MONTH
          ? this.props.onDateChange(new moment(date))
          : this.props.onDateChange(date);
      }
    );
  };

  onFocusChange = focusedInput => {
    const { localStartDate } = this.state;

    this.setState({
      focusedInput: !localStartDate && !focusedInput ? START_DATE : focusedInput
    });
  };

  onFocusSingleChange = ({ focused }) => {
    this.setState({
      singlePickerFocused: focused
    });
  };

  onPressDate = () => {
    const { mode } = this.props;

    if (mode === SCHEDULE_MODES.TODAY)
      this.setState({ singlePickerFocused: true });
    else if (mode === SCHEDULE_MODES.THIS_WEEK)
      this.setState({ weekPickerFocused: true });
    else this.setState({ monthPickerFocused: true });
  };

  changeDate = (next = true) => {
    const { endDate, startDate } = this.props.config;

    this.props.onDateChange(
      { startDate: new moment(startDate), endDate: new moment(endDate) },
      next ? 1 : -1
    );
  };

  getDateString = () => {
    const { mode, config } = this.props;

    return mode === SCHEDULE_MODES.TODAY
      ? moment(config.startDate).format("DD MMMM YYYY")
      : mode === SCHEDULE_MODES.THIS_MONTH
        ? moment(config.startDate).format("MMMM YYYY")
        : moment(config.startDate).format("MM") ===
          moment(config.endDate).format("MM") &&
          moment(config.startDate).format("YYYY") ===
          moment(config.endDate).format("YYYY")
          ? moment(config.startDate).format("DD") +
          "-" +
          moment(config.endDate).format("DD") +
          " " +
          moment(config.startDate).format(" MMMM YYYY")
          : moment(config.startDate).format("YYYY") ===
            moment(config.endDate).format("YYYY")
            ? moment(config.startDate).format("DD MMM") +
            "-" +
            moment(config.endDate).format("DD MMM") +
            " " +
            moment(config.startDate).format(" YYYY")
            : moment(config.startDate).format("DD MMM YYYY") +
            "-" +
            moment(config.endDate).format("DD MMM YYYY");
  };

  isOutsideRange = day => {
    const { focusedInput, localStartDate } = this.state;

    if (
      focusedInput === END_DATE &&
      localStartDate &&
      moment(localStartDate).isValid()
    ) {
      return (
        moment(day).isAfter(moment(localStartDate).add(60, "days")) ||
        moment(day).isBefore(localStartDate)
      );
    }
    return false;
  };

  render() {
    let {
      mode,
      config: { startDate },
      user
    } = this.props;

    startDate = moment(startDate).isValid() ? moment(startDate) : null;

    const {
      focusedInput,
      singlePickerFocused,
      weekPickerFocused,
      monthPickerFocused,
      localStartDate,
      localEndDate
    } = this.state;

    const CancelButton = () => (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => this.setState({ focusedInput: null })}
        className="btn btn-sm btn-danger mt-2 ml-2"
      >
        Cancel
      </span>
    );

    return (
      <div
        className="forecasting-date-picker"
        style={{
          display: "flex",
          flexGrow: 0,
          flexFlow: "row nowrap",
          justifyContent: "flex-end",
          alignItems: "center"
        }}
      >
        <div>
          {mode === SCHEDULE_MODES.TODAY && (
            <SingleDatePicker
              firstDayOfWeek={getWeekStartDay(user)}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              date={moment(startDate)}
              onDateChange={this.onDateChange}
              focused={singlePickerFocused}
              isOutsideRange={() => false}
              onFocusChange={this.onFocusSingleChange}
            />
          )}

          {mode === SCHEDULE_MODES.THIS_WEEK && (
            <WeekPicker
              weekConf={getWeekConfig(user)}
              focused={weekPickerFocused}
              onDateChange={() => { }}
              onChangeFocus={({ focused }) => {
                this.setState({
                  weekPickerFocused: focused
                });
              }}
              onWeekSelect={this.onDateChange}
            />
          )}

          {mode === SCHEDULE_MODES.THIS_MONTH && (
            <DatePicker
              popperModifiers={{
                offset: { offset: "20px, 10px" }
              }}
              onClickOutside={() => {
                this.setState({ monthPickerFocused: false });
              }}
              open={monthPickerFocused}
              showMonthYearPicker
              onChange={this.onDateChange}
            />
          )}
        </div>

        <div className={mode !== SCHEDULE_MODES.DATE_RANGE ? " hidden" : ""}>
          <DateRangePicker
            firstDayOfWeek={getWeekStartDay(user)}
            hideKeyboardShortcutsPanel
            startDateId={START_DATE}
            endDateId={END_DATE}
            startDate={localStartDate}
            endDate={localEndDate}
            anchorDirection="right"
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            isOutsideRange={this.isOutsideRange}
            focusedInput={focusedInput}
            calendarInfoPosition="top"
            renderCalendarInfo={CancelButton}
            disabled={mode !== SCHEDULE_MODES.DATE_RANGE}
          />
        </div>

        <div
          onClick={this.onPressDate}
          className={
            "date-changer" +
            (mode === SCHEDULE_MODES.DATE_RANGE ? " hidden" : "")
          }
        >
          <i className="fas fa-chevron-down"></i>
          &ensp;
          {this.getDateString()}
        </div>

        <div className="actions">
          {[
            SCHEDULE_MODES.TODAY,
            SCHEDULE_MODES.THIS_WEEK,
            SCHEDULE_MODES.THIS_MONTH
          ].includes(mode) && (
              <button className="" onClick={() => this.changeDate(false)}>
                <i className="fas fa-chevron-left" />
              </button>
            )}

          <select className="" onChange={this.onModeChange} value={mode}>
            <option value={SCHEDULE_MODES.TODAY}>Day</option>
            <option value={SCHEDULE_MODES.THIS_WEEK}>Week</option>
            <option value={SCHEDULE_MODES.THIS_MONTH}>Month</option>
            <option value={SCHEDULE_MODES.DATE_RANGE}>Custom</option>
          </select>

          {[
            SCHEDULE_MODES.TODAY,
            SCHEDULE_MODES.THIS_WEEK,
            SCHEDULE_MODES.THIS_MONTH
          ].includes(mode) && (
              <button className="" onClick={this.changeDate}>
                <i className="fas fa-chevron-right" />
              </button>
            )}
        </div>
      </div>
    );
  }
}

export default DateRange;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// Styles
import "./assets/styles/HorizontalMenu.css";

export default class HorizontalMenu extends Component {
  render() {
    const { isMobile } = this.props;
    const onClose = (e) => {
      !e.target.classList.contains("horizontalMenu-list") &&
        this.props.onClose();
    };

    return (
      <div className="horizontal-main clearfix">
        <div className="horizontal-mainwrapper clearfix">
          <div className="overlapblackbg" />
          <nav className="horizontalMenu clearfix" onClick={onClose}>
            <ul className="horizontalMenu-list">
              <li aria-haspopup="false">
                <NavLink to="/app" exact activeClassName="active">
                  <i className="fas fa-comments" /> Dashboard
                </NavLink>
              </li>
              {this.props.isManager === "manager" ? (
                <li aria-haspopup="false">
                  <NavLink to="/app/gmdashboard" activeClassName="active">
                    <i className="fas fa-tachometer-alt" /> GM Dashboard
                  </NavLink>
                </li>
              ) : null}
              {(this.props.isManager === "manager" ||
                this.props.accessLevelId) &&
              !isMobile ? (
                <li aria-haspopup="false">
                  <NavLink to="/app/people" activeClassName="active">
                    <i className="fas fa-users" /> People
                  </NavLink>
                </li>
              ) : null}
              {!isMobile && (
                <li aria-haspopup="false">
                  <NavLink to="/app/schedule" activeClassName="active">
                    <i className="fas fa-calendar" /> Schedule
                  </NavLink>
                </li>
              )}
              {this.props.canViewClockIn && (
              <li aria-haspopup="false">
                <NavLink to="/app/clock-in" activeClassName="active">
                  <i className="fas fa-clock" />
                  Clock-in
                </NavLink>
              </li>
              )}
              <li aria-haspopup="false">
                <NavLink to="/app/leaves" activeClassName="active">
                  {/* <i className="fas fa-level-up-alt" /> Leaves */}
                  <i className="fas fa-plane-departure" /> Time off request
                </NavLink>
              </li>
              {this.props.isManager === "manager" ? (
                <>
                  {!isMobile && (
                    <li aria-haspopup="false">
                      <NavLink to="/app/reports" activeClassName="active">
                        <i className="fas fa-file-medical-alt" />
                        Reports
                      </NavLink>
                    </li>
                  )}
                  <li aria-haspopup="false">
                    <NavLink to="/app/forecasting" activeClassName="active">
                      <i className="fas fa-chart-area" />
                      Forecasting
                    </NavLink>
                  </li>
                </>
              ) : null}
              <li aria-haspopup="false">
                <NavLink to="/app/settings" activeClassName="active">
                  <i className="fas fa-cog" /> Settings
                </NavLink>
              </li>
              <li aria-haspopup="false">
                <NavLink to="/app/profile" activeClassName="active">
                  <i className="fas fa-user" /> Profile
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}
